/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsQCTenants extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'TenantName',
          headerName: this.vue.$t('QualityCheck.QCSchedules.TenantName'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'WeeklySites',
          headerName: this.vue.$t('QualityCheck.QCSchedules.WeeklySites'),
          width: 130,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'MonthlySites',
          headerName: this.vue.$t('QualityCheck.QCSchedules.MonthlySites'),
          width: 130,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Level1Sites',
          headerName: this.vue.$t('QualityCheck.QCSchedules.Level1Sites'),
          width: 130,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'TechnicianName',
          headerName: this.vue.$t('QualityCheck.QCSchedules.Technician'),
          width: 250,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'AnalystName',
          headerName: this.vue.$t('QualityCheck.QCSchedules.Analyst'),
          width: 250,
          editable: false,
          filter: true,
          sortable: true,
        },
      ]);
    });
  }
}
