<template>
  <div>
    <v-card-text>
      <v-row v-if="!site">
        <v-col
          sm="6"
          class="pb-1 pt-4"
        >
          <v-select
            v-model="groupingType"
            :label="$t('Analytics.SensorSelection.GroupBy')"
            :items="groupingChoice"
            item-text="name"
            hide-details
            dense
            return-object
            @change="updateGrouping()"
          />
        </v-col>
        <v-col
          sm="6"
          class="pb-1 pt-4"
        >
          <v-select
            v-if="groupingType && groupingType.value !== 1"
            v-model="attributeName"
            :label="$t('Analytics.SensorSelection.AttributeName')"
            :items="attributeNames"
            :error="!attributeName"
            dense
            @change="updateForNewAttributeName()"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          sm="6"
          class="pt-0"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-text-field
                v-model="form.searchSensorTerm"
                :label="$t('Analytics.SensorSelection.Sensor')"
                class="pt-1"
                prepend-icon="mdi-magnify"
                append-icon="mdi-arrow-right-circle"
                hide-details
                v-on="on"
                @click:append="updateSearch"
                @keyup.enter="updateSearch"
              />
            </template>
            <div style="white-space: pre;">
              {{ $t('Analytics.SensorSelection.Hint') }}
            </div>
          </v-tooltip>
        </v-col>
        <v-col
          sm="6"
          class="pt-0"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-text-field
                v-model="form.searchInstrumentTerm"
                :label="$t('Analytics.SensorSelection.Instrument')"
                class="pt-1"
                prepend-icon="mdi-magnify"
                append-icon="mdi-arrow-right-circle"
                hide-details
                v-on="on"
                @click:append="updateSearch"
                @keyup.enter="updateSearch"
              />
            </template>
            <div style="white-space: pre;">
              {{ $t('Analytics.SensorSelection.Hint') }}
            </div>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-menu
        v-if="false"
        offset-y
        max-height="260"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('Analytics.SensorSelection.ShowSelectedSensors') }}
          </v-btn>
        </template>
        <v-list v-if="form.selectedSensors.length != 0">
          <v-list-item
            v-for="(item, index) in form.selectedSensors"
            :key="index"
          >
            <v-list-item-title>{{ item.DisplayName || item.Name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-text>
    <v-virtual-scroll
      key="SensorList"
      :items="displayedItems"
      :item-height="28"
      height="260"
    >
      <template #default="{ item, index }">
        <div v-if="item.T === typeInstrument">
          <v-list-item
            v-if="item.Name"
            :key="index"
            class="sensor-search-item py-0"
            :class="groupingType.value === 1 ? 'pl-2' : 'pl-6'"
          >
            <v-list-item-action class="ma-0 pa-0">
              <v-btn
                icon
                @click="expand(item)"
              >
                <v-icon>{{ item.Expand ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-icon
              class="py-0 my-0"
              style="align-self: center;"
            >
              <v-icon>mdi-alpha-i-box-outline</v-icon>
            </v-list-item-icon>
            <v-tooltip left>
              <template #activator="{ on }">
                <v-list-item-content
                  class="ellipsis pa-0"
                  v-on="on"
                >
                  {{ item.DisplayName }}
                </v-list-item-content>
              </template>
              {{ item.DisplayName }}
            </v-tooltip>
          </v-list-item>
          <v-list-item
            v-else
            :key="index"
            class="sensor-search-item py-0"
            :class="groupingType.value === 1 ? 'pl-2' : 'pl-5'"
          >
            <v-list-item-action class="ma-0 pa-0">
              <v-btn
                text
                @click="getInstruments(item.AttributeValue)"
              >
                {{ $t('Analytics.SensorSelection.MoreInstruments') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
        <div v-else-if="item.T === typeAttribute">
          <v-list-item
            v-if="item.Name"
            :key="index"
            class="sensor-search-item py-0 pl-2"
          >
            <v-list-item-action class="ma-0 pa-0">
              <v-btn
                icon
                @click="expand(item)"
              >
                <v-icon>{{ item.Expand ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-icon
              class="py-0 my-0"
              style="align-self: center;"
            >
              <v-icon>mdi-alpha-a-box-outline</v-icon>
            </v-list-item-icon>
            <v-tooltip left>
              <template #activator="{ on }">
                <v-list-item-content
                  class="ellipsis pa-0"
                  v-on="on"
                >
                  {{ item.Name }}
                </v-list-item-content>
              </template>
              {{ item.Name }}
            </v-tooltip>
          </v-list-item>
          <v-list-item
            v-else
            :key="index"
            class="sensor-search-item py-0 pl-2"
          >
            <v-list-item-action class="ma-0 pa-0">
              <v-btn
                text
                @click="getAttributeValues()"
              >
                {{ $t('Analytics.SensorSelection.MoreAttributes') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
        <div v-else>
          <v-list-item
            v-if="item.Name"
            :key="index"
            class="sensor-search-item py-0"
            :class="groupingType.value !== 2 ? 'pl-6' : 'pl-10'"
          >
            <v-list-item-action class="py-0 my-0 pl-4 mr-1">
              <v-checkbox
                :key="item.Id"
                :input-value="item.Selected"
                class="py-0 my-0"
                @change="(value) => updateSelected(value, item)"
              />
            </v-list-item-action>
            <v-list-item-action
              v-if="!hideY2"
              class="py-0"
            >
              <v-switch
                v-model="item.Y2Axis"
                label="Y2"
                @click="selectItemWithAxisY2(item)"
              />
            </v-list-item-action>
            <v-list-item-icon
              class="py-0 my-0"
              style="align-self: center;"
            >
              <v-icon v-if="item.T == typeSensor">
                mdi-alpha-s-box-outline
              </v-icon>
              <v-icon v-if="item.T == typeVector">
                mdi-alpha-v-box-outline
              </v-icon>
            </v-list-item-icon>
            <v-tooltip left>
              <template #activator="{ on }">
                <v-list-item-content
                  class="py-0 ellipsis item-title"
                  v-on="on"
                >
                  {{ getSensorDisplayName(item) }}
                </v-list-item-content>
              </template>
              {{ getSensorDisplayName(item) }}
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-list-item-content
                  class="py-0 item-instrument-badge"
                  v-on="on"
                >
                  <v-badge
                    :content="item.InstrumentName"
                    inline
                  />
                </v-list-item-content>
              </template>
              {{ item.InstrumentName }}
            </v-tooltip>
          </v-list-item>
          <v-list-item
            v-else
            :key="index"
            class="sensor-search-item py-0"
            :class="groupingType.value !== 2 ? 'pl-6' : 'pl-10'"
          >
            <v-list-item-action class="ma-0 pa-0">
              <v-btn
                class="pl-4"
                text
                @click="getSensors(item.AttributeValue, item.InstrumentName)"
              >
                {{ $t('Analytics.SensorSelection.MoreSensors') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import importal from '@/api/importal';

export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'form.selectedSensors', event: 'models:selectedSensors' },
  ],
  props: {
    showSensors: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    showVectors: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    itemIncrement: {
      type: Number,
      default: () => 20,
      required: false,
    },
    site: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    instrumentIds: {
      type: Array,
      default: () => null,
      required: false,
    },
    hideY2: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    showVirtual: {
      type: Boolean,
      default: () => true,
      required: false,
    },
  },
  data: () => ({
    spamfree: false,
    groupingType: null,
    attributeName: null,
    attributeNames: [],
    form: {
      searchSensorTerm: '',
      searchInstrumentTerm: '',
      itemList: [],
      selectedSensors: [],
    },
  }),
  computed: {
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),

    typeAttribute: () => 'A',
    typeInstrument: () => 'I',
    typeSensor: () => 'S',
    typeVector: () => 'V',
    typeMoreAttributes: () => 'MoreA',
    typeMoreInstruments: () => 'MoreI',
    typeMoreSensors: () => 'MoreS',

    sensorCount() {
      return this.showSensors ? this.itemIncrement : 0;
    },
    vectorCount() {
      return this.showVectors ? this.itemIncrement : 0;
    },
    attributeOffset() {
      return this.form.itemList.filter((x) => x.T === this.typeAttribute && x.Name).length;
    },
    displayedItems() {
      return this.form.itemList.filter((x) => x.Show);
    },
    groupingChoice() {
      const choices = [{ value: 1, name: this.$t('Analytics.SensorSelection.Instrument') },
        { value: 2, name: this.$t('Analytics.SensorSelection.InstrumentAttributes') }];
      if (this.sensorCount > 0) {
        choices.push({ value: 3, name: this.$t('Analytics.SensorSelection.SensorAttributes') });
      }
      return choices;
    },
  },
  watch: {
    async tenantId() {
      await this.init();
    },
    async accessGroupIds(newVal, oldVal) {
      if (!this.spamfree && oldVal.join() !== newVal.join()) {
        await this.init();
      }
    },
    async showVirtual() {
      await this.init();
    },
    async instrumentIds() {
      await this.init();
    },
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    'form.selectedSensors': function w(newVal) { this.$emit('models:selectedSensors', newVal); },
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      this.spamfree = true;
      this.form.itemList = [];
      this.form.selectedSensors = [];

      if (!this.groupingType) {
        [this.groupingType] = this.groupingChoice;
      }
      await this.updateGrouping();

      this.spamfree = false;
    },
    getSensorDisplayName(item) {
      let text = item.DisplayName || item.Name;
      if (item.T === 'S' && item.Unit) {
        text += ` (${item.Unit})`;
      } else if (item.T === 'V' && item.ReadingUnit) {
        text += ` (${item.ReadingUnit})`;
      }
      return text;
    },
    sensorOffset(attributeValue, instrumentName) {
      if (this.groupingType.value === 3) {
        return this.form.itemList.filter((x) => x.T === this.typeSensor && x.Name
        && x.AttributeValue === attributeValue).length;
      }

      return this.form.itemList.filter((x) => x.T === this.typeSensor && x.Name
        && x.InstrumentName === instrumentName && x.AttributeValue === attributeValue).length;
    },
    vectorOffset(attributeValue, instrumentName) {
      return this.form.itemList.filter((x) => x.T === this.typeVector && x.Name
        && x.InstrumentName === instrumentName && x.AttributeValue === attributeValue).length;
    },
    instrumentOffset(attributeValue) {
      return this.form.itemList.filter((x) => x.T === this.typeInstrument && x.Name
        && x.AttributeValue === attributeValue).length;
    },
    async updateSearch() {
      await this.updateValues();

      if (this.attributeName) {
        if (this.attributeNames.some((x) => x === this.attributeName)) {
          await this.getAttributeValues();
        } else {
          this.attributeName = null;
        }
      }
    },
    async updateGrouping() {
      this.attributeName = null;
      await this.updateValues();
    },
    async updateValues() {
      this.form.itemList = [];
      this.attributeNames = [];

      if (this.groupingType.value === 1) {
        await this.getInstruments();
      } else {
        await this.getAttributeNames();
      }
    },
    async updateForNewAttributeName() {
      this.form.itemList = [];
      await this.getAttributeValues();
    },
    async getAttributeNames() {
      this.spamfree = true;
      if (this.groupingType.value === 2) {
        this.attributeNames = await (await importal.get('InstrumentAttributeNamesForSensor?'
          + `sc=${this.sensorCount}&vc=${this.vectorCount}`
          + `&searchSensor=${encodeURIComponent(this.form.searchSensorTerm)}`
          + `&searchInstrument=${encodeURIComponent(this.form.searchInstrumentTerm)}`)).data;
      } else {
        this.attributeNames = await (await importal.get('SensorAttributeNamesForSensor?'
          + `sc=${this.sensorCount}&searchSensor=${encodeURIComponent(this.form.searchSensorTerm)}`
          + `&searchInstrument=${encodeURIComponent(this.form.searchInstrumentTerm)}`)).data;
      }
      this.spamfree = false;
    },
    async getAttributeValues() {
      this.spamfree = true;
      let list;
      if (this.groupingType.value === 2) {
        list = await (await importal.get('InstrumentAttributeValues?'
          + `offset=${this.attributeOffset}&sc=${this.sensorCount}&vc=${this.vectorCount}`
          + `&searchInstrument=${encodeURIComponent(this.form.searchInstrumentTerm)}`
          + `&searchSensor=${encodeURIComponent(this.form.searchSensorTerm)}`
          + `&attributeName=${encodeURIComponent(this.attributeName)}`)).data;
      } else {
        list = await (await importal.get('SensorAttributeValues?'
          + `offset=${this.attributeOffset}&sc=${this.sensorCount}`
          + `&searchInstrument=${encodeURIComponent(this.form.searchInstrumentTerm)}`
          + `&searchSensor=${encodeURIComponent(this.form.searchSensorTerm)}`
          + `&attributeName=${encodeURIComponent(this.attributeName)}`)).data;
      }

      list = list.map((x) => ({
        T: x.T,
        Name: x.Value,
        Expand: false,
        Show: true,
      }));

      if (list.length >= (this.sensorCount + this.vectorCount)) {
        // Add "More" element
        list.push({
          T: this.typeAttribute,
          Expand: null,
          Show: true,
        });
      }

      this.insertNewItems(list, this.typeAttribute);
      this.spamfree = false;
    },
    async getInstruments(attributeValue) {
      this.spamfree = true;
      let list;
      if (!this.site || (this.site && this.instrumentIds.length > 0)) {
        list = await (await importal.get('InstrumentListForSensor?'
            + `siteInstrumentIds=${this.instrumentIds}`
            + `&offset=${this.instrumentOffset(attributeValue)}&sc=${this.sensorCount}&vc=${this.vectorCount}`
            + `&searchInstrument=${encodeURIComponent(this.form.searchInstrumentTerm)}`
            + `&searchSensor=${encodeURIComponent(this.form.searchSensorTerm)}`
            + `&searchInstAttrName=${encodeURIComponent(this.attributeName || '')}`
            + `&searchInstAttrValue=${encodeURIComponent(attributeValue || '')}`)).data;

        list = list.map((x) => ({
          T: x.T,
          Name: x.Name,
          DisplayName: x.DisplayName,
          AttributeValue: attributeValue,
          Expand: false,
          Show: true,
        }));

        if (list.length >= (this.sensorCount + this.vectorCount)) {
          // Add "More" element
          list.push({
            T: this.typeInstrument,
            AttributeValue: attributeValue,
            Expand: null,
            Show: true,
          });
        }

        this.insertNewItems(list, this.typeInstrument, attributeValue);
      }
      this.spamfree = false;
    },
    async getSensors(attributeValue, instrumentName) {
      this.spamfree = true;
      let list;
      if (this.groupingType.value === 3) {
        list = await (await importal.get('SensorList?'
          + `so=${this.sensorOffset(attributeValue, instrumentName)}&sc=${this.sensorCount}`
          + `&vo=0&vc=0&searchSensor=${encodeURIComponent(this.form.searchSensorTerm)}`
          + `&searchInstrument=${encodeURIComponent(this.form.searchInstrumentTerm)}`
          + `&sensorAttrName=${encodeURIComponent(this.attributeName || '')}`
          + `&sensorAttrValue=${encodeURIComponent(attributeValue || '')}`)).data;
      } else {
        list = await (await importal.get('SensorList?'
          + `so=${this.sensorOffset(attributeValue, instrumentName)}&sc=${this.sensorCount}`
          + `&vo=${this.vectorOffset(attributeValue, instrumentName)}&vc=${this.vectorCount}`
          + `&searchSensor=${encodeURIComponent(this.form.searchSensorTerm)}`
          + `&searchInstrument=${encodeURIComponent(instrumentName)}`
          + `&instrumentAttrName=${encodeURIComponent(this.attributeName || '')}`
          + `&instrumentAttrValue=${encodeURIComponent(attributeValue || '')}`)).data;
      }

      if (!this.showVirtual) {
        // hide virtual instrument sensors
        list = list.filter((x) => x.IsVirtual === false);
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const item of list) {
        item.AttributeValue = attributeValue;
        item.Expand = null;
        item.Show = true;

        item.Selected = this.form.selectedSensors.some((x) => x.T === item.T
          && x.Id === item.Id && x.Name === item.Name);
      }

      if (list.length >= (this.sensorCount + this.vectorCount)) {
        // Add "More" element
        list.push({
          T: this.typeSensor,
          InstrumentName: instrumentName,
          AttributeValue: attributeValue,
          Expand: null,
          Show: true,
        });
      }

      this.insertNewItems(list, this.typeSensor, attributeValue, instrumentName);
      this.spamfree = false;
    },
    insertNewItems(list, typeAdded, attributeValue, instrumentName) {
      let index = 0;
      if (this.form.itemList.length > 0) {
        if (!attributeValue && !instrumentName) {
          // first grouping, so insert at the end
          index = this.form.itemList.length - 1;
        } else {
          index = this.form.itemList.findLastIndex((x) => {
            if (this.groupingType.value === 1) {
              return x.Name === instrumentName || x.InstrumentName === instrumentName;
            }

            if (this.groupingType.value === 2) {
              if (typeAdded === this.typeInstrument) {
                return x.Name === attributeValue || x.AttributeValue === attributeValue;
              }
              return x.AttributeValue === attributeValue
                && (x.Name === instrumentName || x.InstrumentName === instrumentName);
            }

            return x.Name === attributeValue || x.AttributeValue === attributeValue;
          });
        }

        const insertLocation = this.form.itemList[index];
        if (insertLocation.T === typeAdded && !insertLocation.Name) {
          // Remove "More" element
          this.form.itemList.splice(index, 1);
        } else {
          index += 1;
        }
      }

      if (list.length === 0) {
        return;
      }

      if (index < this.form.itemList.length) {
        this.form.itemList.splice(index, 0, ...list);
      } else {
        this.form.itemList.push(...list);
      }
    },
    async expand(element) {
      this.spamfree = true;
      const item = element;
      item.Expand = !item.Expand;

      let nextIndex = this.form.itemList.indexOf(item) + 1;
      if (nextIndex >= this.form.itemList.length) {
        if (item.T === this.typeAttribute) {
          if (this.groupingType.value === 2) {
            await this.getInstruments(item.Name);
          } else {
            await this.getSensors(item.Name);
          }
        } else {
          await this.getSensors(item.AttributeValue, item.Name);
        }
      } else {
        let nextItem = this.form.itemList[nextIndex];
        const needElementForAttribute = item.T === this.typeAttribute
          && nextItem.AttributeValue !== item.Name;
        const needElementForInstrument = item.T === this.typeInstrument
          && nextItem.InstrumentName !== item.Name;

        if (item.Expand && needElementForAttribute) {
          if (this.groupingType.value === 2) {
            await this.getInstruments(item.Name);
          } else {
            await this.getSensors(item.Name);
          }
        } else if (item.Expand && needElementForInstrument) {
          await this.getSensors(item.AttributeValue, item.Name);
        } else {
          const attributeValue = item.T === this.typeAttribute ? item.Name : item.AttributeValue;
          const instrumentName = item.T === this.typeAttribute ? undefined : item.Name;

          while (nextItem) {
            const updateSubAttributeItem = item.T === this.typeAttribute
              && nextItem.AttributeValue === attributeValue;
            const udpateSubInstrumentItem = item.T === this.typeInstrument
              && nextItem.InstrumentName === instrumentName;

            // Show only first level of items
            const isItemToKeepHide = item.Expand && item.T === this.typeAttribute
                && this.groupingType.value === 2 && nextItem.T !== this.typeInstrument;

            if (!updateSubAttributeItem && !udpateSubInstrumentItem) {
              // Item is after the section to show
              break;
            } else if (!isItemToKeepHide) {
              nextItem.Show = item.Expand;
              if (!item.Expand && nextItem.Expand) {
                nextItem.Expand = false;
              }
            }

            nextIndex += 1;
            nextItem = nextIndex < this.form.itemList.length ? this.form.itemList[nextIndex] : null;
          }
        }
      }
      this.spamfree = false;
    },
    selectItemWithAxisY2(item) {
      if (item.Y2Axis && !item.Selected) {
        this.updateSelected(item.Y2Axis, item);
      } else if (item.Selected) {
        const sensor = this.form.selectedSensors.find((x) => x.T === item.T
          && x.Id === item.Id && x.Name === item.Name);
        sensor.Y2Axis = item.Y2Axis;
      }
    },
    updateSelected(value, item) {
      const sensor = this.form.itemList.find((x) => x.T === item.T
        && x.Id === item.Id && x.Name === item.Name);
      sensor.Selected = value;

      const selectedSensor = this.form.selectedSensors
        .find((x) => x.T === item.T && x.Id === item.Id && x.Name === item.Name);
      if (value && !selectedSensor) {
        const sensorCopy = { ...sensor };
        delete sensorCopy.AttributeValue;
        delete sensorCopy.Show;
        delete sensorCopy.Selected;
        delete sensorCopy.Expand;

        this.form.selectedSensors.push(sensorCopy);
      } else if (!value && selectedSensor) {
        const index = this.form.selectedSensors.indexOf(selectedSensor);
        if (index >= 0) {
          this.form.selectedSensors.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .ellipsis {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .sensor-search-item {
    line-height: 1.0;
  }

  .item-title {
    max-width: 380px;
    padding-right: 10px;
  }

  .item-instrument-badge {
    max-width: max-content;
  }

  .v-select {
    ::v-deep {
      .v-select__selections input {
        display: none;
      }
    }
  }

</style>
