<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <dialog-formula-edit
      v-if="showFormulaEditDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:FormulaText="editingNode.FormulaText"
      v-models:OutputSymbol="editingNode.OutputSymbol"
      v-models:OutputSymbolErrors="editingNode.OutputSymbolErrors"
      v-models:show="showFormulaEditDialog"
      :editor-mode="formulaEditDialogMode"
      @on-accept="onFormulaEditAccept"
    />
    <dialog-aggregation-control
      v-if="showAggregationControlDialog"
      v-models:InputMultiplier="aggregationControl.InputMultiplier"
      v-models:InputForEvery="aggregationControl.InputForEvery"
      v-models:InputOffset="aggregationControl.InputOffset"
      v-models:OutputMultiplier="aggregationControl.OutputMultiplier"
      v-models:OutputForEvery="aggregationControl.OutputForEvery"
      v-models:OutputOffset="aggregationControl.OutputOffset"
      v-models:show="showAggregationControlDialog"
      :aggregate-for-every2-query="AggregateForEvery2Query"
      :aggregate-for-every-items="AggregateForEveryItems"
      @on-accept="onAggregationControlAccept"
    />
    <dialog-input-time-sync
      v-if="showInputTimeSyncDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:Aggregation="editingNode.Aggregation"
      v-models:show="showInputTimeSyncDialog"
      :aggregate-by-items="AggregateByItems"
      :aggregate-by2-query="AggregateBy2Query"
      @on-accept="onInputTimeSyncAccept"
    />
    <dialog-missing-input
      v-if="showMissingInputDialog"
      v-models:formulaType="editingNode.FormulaType"
      v-models:nodeType="editingNode.nodeType"
      v-models:show="showMissingInputDialog"
      @on-accept="onMissingInputAccept"
    />
    <dialog-new-input-constant-sensor
      v-if="showInputConstantSensorDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:DefaultValue="editingNode.DefaultValue"
      v-models:show="showInputConstantSensorDialog"
      @on-accept="onNewInputConstantSensorAccept"
    />
    <dialog-new-input-direct-sensor
      v-if="showInputDirectSensorDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:Symbol="editingNode.Symbol"
      v-models:SensorRule="editingNode.SensorRule"
      v-models:MatchingProperty="editingNode.MatchingProperty"
      v-models:show="showInputDirectSensorDialog"
      @on-accept="onNewInputDirectSensorAccept"
    />
    <dialog-new-input-direct-vector
      v-if="showInputDirectVectorDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:Symbol="editingNode.Symbol"
      v-models:SensorRule="editingNode.SensorRule"
      v-models:MatchingProperty="editingNode.MatchingProperty"
      v-models:show="showInputDirectVectorDialog"
      @on-accept="onNewInputDirectVectorAccept"
    />
    <dialog-new-input-direct-sensor-attribute
      v-if="showInputDirectSensorAttributeDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:Symbol="editingNode.Symbol"
      v-models:SensorRule="editingNode.SensorRule"
      v-models:AttributeName="editingNode.AttributeName"
      v-models:DefaultValue="editingNode.DefaultValue"
      v-models:show="showInputDirectSensorAttributeDialog"
      @on-accept="onNewInputDirectSensorAttributeAccept"
    />
    <dialog-new-input-external-sensor-attribute
      v-if="showInputExternalSensorAttributeDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:Symbol="editingNode.Symbol"
      v-models:MatchingProperty="editingNode.MatchingProperty"
      v-models:InstrumentRule="editingNode.InstrumentRule"
      v-models:SensorRule="editingNode.SensorRule"
      v-models:AttributeName="editingNode.AttributeName"
      v-models:DefaultValue="editingNode.DefaultValue"
      v-models:show="showInputExternalSensorAttributeDialog"
      @on-accept="onNewInputExternalSensorAttributeAccept"
    />
    <dialog-new-input-direct-instrument-attribute
      v-if="showInputDirectInstrumentAttributeDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:Symbol="editingNode.Symbol"
      v-models:AttributeName="editingNode.AttributeName"
      v-models:DefaultValue="editingNode.DefaultValue"
      v-models:show="showInputDirectInstrumentAttributeDialog"
      @on-accept="onNewInputDirectInstrumentAttributeAccept"
    />
    <dialog-new-input-external-instrument-attribute
      v-if="showInputExternalInstrumentAttributeDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:Symbol="editingNode.Symbol"
      v-models:InstrumentRule="editingNode.InstrumentRule"
      v-models:MatchingProperty="editingNode.MatchingProperty"
      v-models:AttributeName="editingNode.AttributeName"
      v-models:DefaultValue="editingNode.DefaultValue"
      v-models:show="showInputExternalInstrumentAttributeDialog"
      @on-accept="onNewInputExternalInstrumentAttributeAccept"
    />
    <dialog-new-input-external-sensor
      v-if="showInputExternalSensorDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:Symbol="editingNode.Symbol"
      v-models:SensorRule="editingNode.SensorRule"
      v-models:MatchingProperty="editingNode.MatchingProperty"
      v-models:InstrumentRule="editingNode.InstrumentRule"
      v-models:show="showInputExternalSensorDialog"
      @on-accept="onNewInputExternalSensorAccept"
    />
    <dialog-new-input-external-vector
      v-if="showInputExternalVectorDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:Symbol="editingNode.Symbol"
      v-models:SensorRule="editingNode.SensorRule"
      v-models:MatchingProperty="editingNode.MatchingProperty"
      v-models:InstrumentRule="editingNode.InstrumentRule"
      v-models:show="showInputExternalVectorDialog"
      @on-accept="onNewInputExternalVectorAccept"
    />
    <dialog-new-output-sensor
      v-if="showNewOutputSensorDialog"
      v-models:nodeType="editingNode.nodeType"
      v-models:NameTemplate="editingNode.NameTemplate"
      v-models:show="showNewOutputSensorDialog"
      @on-accept="onNewOutputSensorAccept"
    />
    <v-toolbar
      dense
    >
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Refresh')"
        :icon-name="'mdi-refresh'"
        @click="doRefresh"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="'New Formula'"
        :icon-name="'mdi-math-integral'"
        @click="toolbarNewFormula"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="'Aggregation Setup'"
        :icon-name="'mdi-clock-check'"
        @click="toolbarEditTimeAlign"
      />
    </v-toolbar>
    <template-vi-level-svg
      :vi-data="viData"
      @node-action-click="nodeActionClick"
    />
  </div>
</template>

<script>
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import { mapGetters } from 'vuex';
import importal from '@/api/importal';
import TemplateViLevelSvg from './TemplateViLevelSvg.vue';
import DialogFormulaEdit from './components/DialogFormulaEdit.vue';
import DialogAggregationControl from './components/DialogAggregationControl.vue';
import DialogMissingInput from './components/DialogMissingInput.vue';
import DialogNewInputDirectSensor from './components/DialogNewInputDirectSensor.vue';
import DialogNewInputDirectVector from './components/DialogNewInputDirectVector.vue';
import DialogInputTimeSync from './components/DialogInputTimeSync.vue';
import DialogNewInputExternalSensor from './components/DialogNewInputExternalSensor.vue';
import DialogNewInputExternalVector from './components/DialogNewInputExternalVector.vue';
import DialogNewInputDirectInstrumentAttribute from './components/DialogNewInputDirectInstrumentAttribute.vue';
import DialogNewInputExternalInstrumentAttribute from './components/DialogNewInputExternalInstrumentAttribute.vue';
import DialogNewInputConstantSensor from './components/DialogNewInputConstantSensor.vue';
import DialogNewOutputSensor from './components/DialogNewOutputSensor.vue';
import DialogNewInputDirectSensorAttribute from './components/DialogNewInputDirectSensorAttribute.vue';
import DialogNewInputExternalSensorAttribute from './components/DialogNewInputExternalSensorAttribute.vue';

export default {
  components: {
    ToolbarButtonWithTooltip,
    TemplateViLevelSvg,
    DialogFormulaEdit,
    DialogAggregationControl,
    DialogMissingInput,
    DialogNewInputDirectSensor,
    DialogNewInputDirectVector,
    DialogInputTimeSync,
    DialogNewInputExternalSensor,
    DialogNewInputExternalVector,
    DialogNewInputDirectSensorAttribute,
    DialogNewInputExternalSensorAttribute,
    DialogNewInputDirectInstrumentAttribute,
    DialogNewInputExternalInstrumentAttribute,
    DialogNewInputConstantSensor,
    DialogNewOutputSensor,
  },
  data: () => ({
    AggregateForEveryItems: ['Do not agregate', 'Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],
    AggregateForEvery2Query: ['NONE', 'YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'],
    AggregateByItems: ['Any', 'Average', 'Min', 'Max', 'Sum'],
    AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX', 'SUM'],

    refresh: 0,
    viData: null,

    showFormulaEditDialog: false,
    formulaEditDialogMode: 'new',
    showMissingInputDialog: false,
    showInputDirectSensorDialog: false,
    showInputDirectVectorDialog: false,
    showInputExternalSensorDialog: false,
    showInputExternalVectorDialog: false,
    showInputTimeSyncDialog: false,
    showInputDirectSensorAttributeDialog: false,
    showInputExternalSensorAttributeDialog: false,
    showInputDirectInstrumentAttributeDialog: false,
    showInputExternalInstrumentAttributeDialog: false,
    showInputConstantSensorDialog: false,
    showNewOutputSensorDialog: false,
    editingNode: {
      nodeType: null,
      FormulaText: null,
      OutputSymbol: null,
      OutputAggregation: null,
      OutputSymbolErrors: [],
      Unit: null,
      FormulaId: null,

      Aggregation: null,

      AttributeName: null,
      DefaultValue: null,

      RuleId: null,
      SensorRule: null,
      Symbol: null,
      MatchingProperty: null,
      InstrumentRule: null,

      InputAggregation: null,
      InputTransform: null,
      TransformXUnit: null,
      TransformYUnit: null,

      NameTemplate: null,
      FormulaType: null,
    },

    showAggregationControlDialog: false,
    aggregationControl: {
      InputMultiplier: null,
      InputForEvery: null,
      InputOffset: null,
      OutputMultiplier: null,
      OutputForEvery: null,
      OutputOffset: null,
    },
  }),
  computed: {
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),

    VITplTemplateId() {
      return this.$route.params.id;
    },
    ViId() {
      return this.$route.params.vi;
    },
  },
  watch: {
    tenantId() {
      this.refresh += 1;
      this.readTemplate();
    },
    accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.refresh += 1;
        this.readTemplate();
      }
    },
  },
  created() {
  },
  mounted() {
    this.readTemplate();
  },
  methods: {
    doRefresh() {
      this.refresh += 1;
      this.readTemplate();
    },

    toolbarNewFormula() {
      this.editingNode.nodeType = 'formula';
      this.editingNode.FormulaText = '';
      this.editingNode.OutputSymbol = '';
      this.editingNode.OutputAggregation = 'ANY';
      this.editingNode.Unit = '';
      this.editingNode.FormulaId = crypto.randomUUID();
      this.formulaEditDialogMode = 'new';
      this.showFormulaEditDialog = true;
    },
    onFormulaEditAccept() {
      if (this.editingNode.nodeType === 'formula') {
        this.CallApiPost('ViTemplateViUpdateFormula', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          ViId: this.ViId,
          FormulaId: this.editingNode.FormulaId,
          FormulaText: this.editingNode.FormulaText,
          OutputSymbol: this.editingNode.OutputSymbol,
          OutputAggregation: this.editingNode.OutputAggregation,
          OutputUnit: this.editingNode.Unit,
        });
      }

      if (this.editingNode.nodeType === 'input_sensor_xform') {
        this.CallApiPost('ViTemplateUpdateInputTransform', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          InputTransform: this.editingNode.FormulaText,
          TransformXUnit: null,
          TransformYUnit: null,
        });
      }
    },

    toolbarEditTimeAlign() {
      if (this.viData && this.viData.VirtualInstruments.length > 0) {
        this.aggregationControl.InputForEvery = this.viData.VirtualInstruments[0]
          .InputTimeAxisScale ?? 'NONE';
        this.aggregationControl.InputMultiplier = this.viData.VirtualInstruments[0]
          .InputTimeAxisMultiplier ?? 1;
        this.aggregationControl.InputOffset = this.viData.VirtualInstruments[0]
          .InputTimeAxisOffset ?? 0;
        this.aggregationControl.OutputForEvery = this.viData.VirtualInstruments[0]
          .OutputTimeAxisScale ?? 'NONE';
        this.aggregationControl.OutputMultiplier = this.viData.VirtualInstruments[0]
          .OutputTimeAxisMultiplier ?? 1;
        this.aggregationControl.OutputOffset = this.viData.VirtualInstruments[0]
          .OutputTimeAxisOffset ?? 0;
        this.showAggregationControlDialog = true;
      }
    },
    onAggregationControlAccept(data) {
      this.CallApiPost('ViTemplateViUpdateAxisParameters', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        ViId: this.ViId,
        InputTimeAxisScale: data.InputForEvery,
        InputTimeAxisDivisor: Number(data.InputMultiplier),
        InputTimeAxisMultiplier: Number(data.InputMultiplier),
        InputTimeAxisOffset: Number(data.InputOffset),
        OutputTimeAxisScale: data.OutputForEvery,
        OutputTimeAxisDivisor: Number(data.OutputMultiplier),
        OutputTimeAxisMultiplier: Number(data.OutputMultiplier),
        OutputTimeAxisOffset: Number(data.OutputOffset),
      });
    },

    onMissingInputAccept(data) {
      this.editingNode.nodeType = data.nodeType;
      // We now have a RuleId, Symbol and NodeType set
      this.editingNode.InputAggregation = 'ANY';
      this.editingNode.InputTransform = null;
      this.editingNode.TransformXUnit = null;
      this.editingNode.TransformYUnit = null;

      if (this.editingNode.nodeType === 'input_direct_sensor'
          || this.editingNode.nodeType === 'input_direct_sensor_array') {
        this.showInputDirectSensorDialog = true;
      } else if (this.editingNode.nodeType === 'input_direct_vector') {
        this.showInputDirectVectorDialog = true;
      } else if (this.editingNode.nodeType === 'input_external_vector') {
        this.showInputExternalVectorDialog = true;
      } else if (this.editingNode.nodeType === 'input_external_sensor'
          || this.editingNode.nodeType === 'input_external_sensor_array') {
        this.showInputExternalSensorDialog = true;
      } else if (this.editingNode.nodeType === 'input_constant_sensor') {
        this.showInputConstantSensorDialog = true;
      } else if (this.editingNode.nodeType === 'input_sensor_attribute') {
        this.showInputDirectSensorAttributeDialog = true;
      } else if (this.editingNode.nodeType === 'input_external_sensor_attribute') {
        this.showInputExternalSensorAttributeDialog = true;
      } else if (this.editingNode.nodeType === 'input_direct_instrument_attribute'
          || this.editingNode.nodeType === 'input_direct_instrument_capture') {
        this.showInputDirectInstrumentAttributeDialog = true;
      } else if (this.editingNode.nodeType === 'input_external_instrument_attribute'
          || this.editingNode.nodeType === 'input_external_instrument_capture') {
        this.showInputExternalInstrumentAttributeDialog = true;
      }
    },

    onNewInputDirectSensorAccept(data) {
      if (data.nodeType === 'input_direct_sensor') {
        this.CallApiPost('ViTemplateAddDirectSensorInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          SensorRule: data.SensorRule,
          MatchingProperty: data.MatchingProperty,
          InputAggregation: this.editingNode.InputAggregation,
          InputTransform: this.editingNode.InputTransform,
          TransformXUnit: this.editingNode.TransformXUnit,
          TransformYUnit: this.editingNode.TransformYUnit,
        });
      }
      if (data.nodeType === 'input_direct_sensor_array') {
        this.CallApiPost('ViTemplateAddDirectSensorArrayInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          SensorRule: data.SensorRule,
          MatchingProperty: data.MatchingProperty,
          InputAggregation: this.editingNode.InputAggregation,
          InputTransform: this.editingNode.InputTransform,
          TransformXUnit: this.editingNode.TransformXUnit,
          TransformYUnit: this.editingNode.TransformYUnit,
        });
      }
    },

    onNewInputDirectVectorAccept(data) {
      if (data.nodeType === 'input_direct_vector') {
        this.CallApiPost('ViTemplateAddDirectVectorInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          SensorRule: data.SensorRule,
          MatchingProperty: data.MatchingProperty,
          InputAggregation: this.editingNode.InputAggregation,
          InputTransform: this.editingNode.InputTransform,
          TransformXUnit: this.editingNode.TransformXUnit,
          TransformYUnit: this.editingNode.TransformYUnit,
        });
      }
    },

    onNewInputExternalSensorAccept(data) {
      if (data.nodeType === 'input_external_sensor') {
        this.CallApiPost('ViTemplateAddExternalSensorInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          SensorRule: data.SensorRule,
          MatchingProperty: data.MatchingProperty,
          InstrumentRule: data.InstrumentRule,
          InputAggregation: this.editingNode.InputAggregation,
          InputTransform: this.editingNode.InputTransform,
          TransformXUnit: this.editingNode.TransformXUnit,
          TransformYUnit: this.editingNode.TransformYUnit,
        });
      }
      if (data.nodeType === 'input_external_sensor_array') {
        this.CallApiPost('ViTemplateAddExternalSensorArrayInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          SensorRule: data.SensorRule,
          MatchingProperty: data.MatchingProperty,
          InstrumentRule: data.InstrumentRule,
          InputAggregation: this.editingNode.InputAggregation,
          InputTransform: this.editingNode.InputTransform,
          TransformXUnit: this.editingNode.TransformXUnit,
          TransformYUnit: this.editingNode.TransformYUnit,
        });
      }
    },

    onNewInputExternalVectorAccept(data) {
      if (data.nodeType === 'input_external_vector') {
        this.CallApiPost('ViTemplateAddExternalVectorInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          SensorRule: data.SensorRule,
          MatchingProperty: data.MatchingProperty,
          InstrumentRule: data.InstrumentRule,
          InputAggregation: this.editingNode.InputAggregation,
          InputTransform: this.editingNode.InputTransform,
          TransformXUnit: this.editingNode.TransformXUnit,
          TransformYUnit: this.editingNode.TransformYUnit,
        });
      }
    },

    onNewInputDirectSensorAttributeAccept(data) {
      this.CallApiPost('ViTemplateAddSensorAttributeInput', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        RuleId: this.editingNode.RuleId,
        Symbol: this.editingNode.Symbol,
        SensorRule: data.SensorRule,
        AttributeName: data.AttributeName,
        DefaultValue: data.DefaultValue ? Number(data.DefaultValue) : null,
        Unit: null,
      });
    },

    onNewInputExternalSensorAttributeAccept(data) {
      this.CallApiPost('ViTemplateAddSensorAttributeInput', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        RuleId: this.editingNode.RuleId,
        Symbol: this.editingNode.Symbol,
        InstrumentRule: data.InstrumentRule,
        MatchingProperty: data.MatchingProperty,
        SensorRule: data.SensorRule,
        AttributeName: data.AttributeName,
        DefaultValue: data.DefaultValue ? Number(data.DefaultValue) : null,
        Unit: null,
      });
    },

    onNewInputDirectInstrumentAttributeAccept(data) {
      if (this.editingNode.nodeType === 'input_direct_instrument_attribute') {
        this.CallApiPost('ViTemplateAddDirectInstrumentAttributeInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          AttributeName: data.AttributeName,
          DefaultValue: data.DefaultValue ? Number(data.DefaultValue) : null,
          Unit: null,
        });
      }
      if (this.editingNode.nodeType === 'input_direct_instrument_capture') {
        this.CallApiPost('ViTemplateAddDirectInstrumentCaptureInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          AttributeName: data.AttributeName,
          DefaultValue: data.DefaultValue ? Number(data.DefaultValue) : null,
          Unit: null,
        });
      }
    },

    onNewInputExternalInstrumentAttributeAccept(data) {
      if (this.editingNode.nodeType === 'input_external_instrument_attribute') {
        this.CallApiPost('ViTemplateAddExternalInstrumentAttributeInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          InstrumentRule: data.InstrumentRule,
          MatchingProperty: data.MatchingProperty,
          AttributeName: data.AttributeName,
          DefaultValue: data.DefaultValue ? Number(data.DefaultValue) : null,
          Unit: null,
        });
      }
      if (this.editingNode.nodeType === 'input_external_instrument_capture') {
        this.CallApiPost('ViTemplateAddExternalInstrumentCaptureInput', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Symbol: this.editingNode.Symbol,
          InstrumentRule: data.InstrumentRule,
          MatchingProperty: data.MatchingProperty,
          AttributeName: data.AttributeName,
          DefaultValue: data.DefaultValue ? Number(data.DefaultValue) : null,
          Unit: null,
        });
      }
    },

    onNewInputConstantSensorAccept(data) {
      this.CallApiPost('ViTemplateAddConstantSensorInput', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        RuleId: this.editingNode.RuleId,
        Symbol: this.editingNode.Symbol,
        DefaultValue: data.DefaultValue ? Number(data.DefaultValue) : null,
        Unit: null,
      });
    },

    onNewOutputSensorAccept(data) {
      this.CallApiPost('ViTemplateAddSensorOutput', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        RuleId: this.editingNode.RuleId,
        Symbol: this.editingNode.Symbol,
        NameTemplate: data.NameTemplate,
      });
    },

    onInputTimeSyncAccept(data) {
      if (this.editingNode.nodeType === 'input_time_sync') {
        this.CallApiPost('ViTemplateUpdateTimeSync', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: this.editingNode.RuleId,
          Aggregation: data.Aggregation,
        });
      }

      if (this.editingNode.nodeType === 'output_time_sync') {
        this.CallApiPost('ViTemplateViUpdateFormula', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          ViId: this.ViId,
          FormulaId: this.editingNode.FormulaId,
          FormulaText: this.editingNode.FormulaText,
          OutputSymbol: this.editingNode.OutputSymbol,
          OutputAggregation: this.editingNode.Aggregation,
          OutputUnit: this.editingNode.Unit,
        });
      }
    },

    showTemplate(templateContents) {
      if (templateContents.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const [templateContent] = templateContents;
        this.viData = {
          VirtualInstruments: templateContent.Content.VirtualInstruments.filter((x) => x.Id === this.ViId),
          InputRules: templateContent.Content.InputRules,
          OutputRules: templateContent.Content.OutputRules,
          Bindings: templateContent.Bindings,
        };
      }
    },

    readTemplate() {
      this.CallApiGet(`ViTemplateList?tenantId=${this.tenantId}&VITplTemplateId=${this.VITplTemplateId}`);
    },

    nodeActionClick(actionType, node) {
      if (actionType === 'delete' && node.nodeType === 'formula') {
        this.CallApiPost('ViTemplateViRemoveFormula', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          ViId: this.ViId,
          FormulaId: node.FormulaId,
        });
      }

      if (actionType === 'delete' && node.nodeType === 'input_sensor_xform') {
        this.CallApiPost('ViTemplateUpdateInputTransform', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: node.Id,
          InputTransform: null,
          TransformXUnit: null,
          TransformYUnit: null,
        });
      }

      /* Delete input rule */
      if (actionType === 'delete'
          && (node.nodeType === 'input_direct_sensor'
          || node.nodeType === 'input_direct_vector'
          || node.nodeType === 'input_external_sensor'
          || node.nodeType === 'input_external_vector'
          || node.nodeType === 'input_direct_sensor_array'
          || node.nodeType === 'input_external_sensor_array'
          || node.nodeType === 'input_sensor_attribute'
          || node.nodeType === 'input_external_sensor_attribute'
          || node.nodeType === 'input_direct_instrument_attribute'
          || node.nodeType === 'input_external_instrument_attribute'
          || node.nodeType === 'input_direct_instrument_capture'
          || node.nodeType === 'input_external_instrument_capture'
          || node.nodeType === 'input_constant_sensor')) {
        this.CallApiPost('ViTemplateRemoveInputRule', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: node.Id,
        });
      }

      /* Delete output rule */
      if (actionType === 'delete'
          && node.nodeType === 'output_sensor') {
        this.CallApiPost('ViTemplateRemoveOutputRule', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          RuleId: node.Id,
        });
      }

      if (actionType === 'edit' && node.nodeType === 'missing_input') {
        this.editingNode.FormulaType = node.FormulaType;
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = crypto.randomUUID();
        this.editingNode.Symbol = node.Name;
        this.showMissingInputDialog = true;
      }

      if (actionType === 'edit' && node.nodeType === 'missing_output') {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = crypto.randomUUID();
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.NameTemplate = null;
        this.showNewOutputSensorDialog = true;
      }

      if (actionType === 'edit' && node.nodeType === 'formula') {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.SensorRule = node.SensorRule;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.FormulaText = node.FormulaText;
        this.editingNode.FormulaId = node.FormulaId;
        this.editingNode.OutputSymbol = node.OutputSymbol;
        this.editingNode.OutputAggregation = node.OutputAggregation;
        this.editingNode.Unit = node.OutputUnit;
        this.formulaEditDialogMode = 'edit';
        this.showFormulaEditDialog = true;
      }

      if (actionType === 'edit' && node.nodeType === 'input_sensor_xform') {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.SensorRule = node.SensorRule;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.FormulaText = node.InputTransform;
        this.formulaEditDialogMode = 'edit';
        this.showFormulaEditDialog = true;
      }

      if (actionType === 'edit' && node.nodeType === 'input_time_sync') {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.$InputNode.Id;
        this.editingNode.Aggregation = node.Aggregation;
        this.showInputTimeSyncDialog = true;
      }

      if (actionType === 'edit' && node.nodeType === 'output_time_sync') {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.$OutputNode.Id;
        this.editingNode.Aggregation = node.$FormulaNode.OutputAggregation;
        this.editingNode.FormulaId = node.$FormulaNode.FormulaId;
        this.editingNode.FormulaText = node.$FormulaNode.FormulaText;
        this.editingNode.OutputSymbol = node.$FormulaNode.OutputSymbol;
        this.editingNode.Unit = node.$FormulaNode.Unit;
        this.showInputTimeSyncDialog = true;
      }

      if (actionType === 'edit'
          && (node.nodeType === 'input_direct_sensor'
              || node.nodeType === 'input_direct_sensor_array'
          )) {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.SensorRule = node.SensorRule;
        this.editingNode.MatchingProperty = node.AttributeName;
        this.editingNode.InputAggregation = node.InputAggregation;
        this.editingNode.InputTransform = node.InputTransform;
        this.editingNode.TransformXUnit = node.TransformXUnit;
        this.editingNode.TransformYUnit = node.TransformYUnit;
        this.showInputDirectSensorDialog = true;
      }
      if (actionType === 'edit'
          && (node.nodeType === 'input_direct_vector'
          )) {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.SensorRule = node.SensorRule;
        this.editingNode.MatchingProperty = node.AttributeName;
        this.editingNode.InputAggregation = node.InputAggregation;
        this.editingNode.InputTransform = node.InputTransform;
        this.editingNode.TransformXUnit = node.TransformXUnit;
        this.editingNode.TransformYUnit = node.TransformYUnit;
        this.showInputDirectVectorDialog = true;
      }

      if (actionType === 'edit' && node.nodeType === 'input_constant_sensor') {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.DefaultValue = node.ConstantDefaultValue;
        this.showInputConstantSensorDialog = true;
      }

      if (actionType === 'edit' && (node.nodeType === 'input_external_vector'
      )) {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.SensorRule = node.SensorRule;
        this.editingNode.MatchingProperty = node.AttributeName;
        this.editingNode.InstrumentRule = node.InstrumentRule;
        this.editingNode.InputAggregation = node.InputAggregation;
        this.editingNode.InputTransform = node.InputTransform;
        this.editingNode.TransformXUnit = node.TransformXUnit;
        this.editingNode.TransformYUnit = node.TransformYUnit;
        this.showInputExternalVectorDialog = true;
      }

      if (actionType === 'edit' && (node.nodeType === 'input_external_sensor'
            || node.nodeType === 'input_external_sensor_array'
      )) {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.SensorRule = node.SensorRule;
        this.editingNode.MatchingProperty = node.AttributeName;
        this.editingNode.InstrumentRule = node.InstrumentRule;
        this.editingNode.InputAggregation = node.InputAggregation;
        this.editingNode.InputTransform = node.InputTransform;
        this.editingNode.TransformXUnit = node.TransformXUnit;
        this.editingNode.TransformYUnit = node.TransformYUnit;
        this.showInputExternalSensorDialog = true;
      }
      if (actionType === 'edit' && node.nodeType === 'input_sensor_attribute') {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.SensorRule = node.SensorRule;
        this.editingNode.AttributeName = node.AttributeName;
        this.editingNode.DefaultValue = node.ConstantDefaultValue;
        this.showInputDirectSensorAttributeDialog = true;
      }
      if (actionType === 'edit' && node.nodeType === 'input_external_sensor_attribute') {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.MatchingProperty = node.InstrumentAttributeName;
        this.editingNode.InstrumentRule = node.InstrumentRule;
        this.editingNode.SensorRule = node.SensorRule;
        this.editingNode.AttributeName = node.AttributeName;
        this.editingNode.DefaultValue = node.ConstantDefaultValue;
        this.showInputExternalSensorAttributeDialog = true;
      }
      if (actionType === 'edit' && (node.nodeType === 'input_direct_instrument_attribute'
          || node.nodeType === 'input_direct_instrument_capture')) {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.AttributeName = node.InstrumentAttributeName;
        this.editingNode.DefaultValue = node.ConstantDefaultValue;
        this.showInputDirectInstrumentAttributeDialog = true;
      }
      if (actionType === 'edit' && (node.nodeType === 'input_external_instrument_attribute'
          || node.nodeType === 'input_external_instrument_capture')) {
        this.editingNode.nodeType = node.nodeType;
        this.editingNode.RuleId = node.Id;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.InstrumentRule = node.InstrumentRule;
        this.editingNode.InstrumentAttributeName = node.InstrumentAttributeName;
        this.editingNode.MatchingProperty = node.AttributeName;
        this.editingNode.DefaultValue = node.ConstantDefaultValue;
        this.showInputExternalInstrumentAttributeDialog = true;
      }

      if (actionType === 'input-transform') {
        this.editingNode.nodeType = 'input_sensor_xform';
        this.editingNode.RuleId = node.Id;
        this.editingNode.SensorRule = node.SensorRule;
        this.editingNode.Symbol = node.Symbol;
        this.editingNode.FormulaText = node.InputTransform;
        this.formulaEditDialogMode = 'edit';
        this.showFormulaEditDialog = true;
      }
    },

    async CallApiPost(endpoint, obj) {
      await this.$PleaseWaitOneAsync(
        importal.post(endpoint, obj)
          .then((resp) => {
            this.showTemplate(resp.data);
          })
          .catch((error) => {
            this.ShowError(error);
          }),
      );
    },
    async CallApiGet(endpoint) {
      await this.$PleaseWaitOneAsync(
        importal.get(endpoint)
          .then((resp) => {
            this.showTemplate(resp.data);
          })
          .catch((error) => {
            this.ShowError(error);
          }),
      );
    },
    ShowError(error) {
      const { data } = error.response;
      this.$root.$confirm(`${data.exception}\n${data.stacktrace}`, true, {
        title: `${error.response.status}: ${error.response.statusText}`,
      });
    },
  },
};
</script>

<style lang="scss">
</style>
