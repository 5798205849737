<template>
  <v-container fluid>
    <v-card-title>
      <v-tooltip
        v-if="$auth.roles.includes('Web.Site.DataTrace.Create')"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            :disabled="form.selectedSensors.length == 0"
            v-bind="attrs"
            v-on="on"
            @click="addFlags"
          >
            <i class="v-icon mdi mdi-flag-variant-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.DataTrace.AddFlags') }} </span>
      </v-tooltip>
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="viewFlags"
          >
            <i class="v-icon mdi mdi-flag-variant" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.DataTrace.ViewFlags') }} </span>
      </v-tooltip>
    </v-card-title>
    <QuickSensorView
      v-models:selectedSensors="form.selectedSensors"
      :site="true"
      :instruments="instruments"
    />

    <FlagSetsEditModal
      v-model="addFlag"
    />

    <FlagSetsModal
      v-model="viewFlag"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import QuickSensorView from '@/analytics/QuickSensorView.vue';
import FlagSetsEditModal from './FlagSetsEditModal.vue';
import FlagSetsModal from './FlagSetsModal.vue';

export default {
  components: {
    FlagSetsEditModal,
    FlagSetsModal,
    QuickSensorView,
  },
  data: () => ({
    form: {
      selectedSensors: [],
    },
    addFlag: false,
    viewFlag: false,
  }),
  computed: {
    ...mapState('site/sites', ['site']),
    ...mapState('site/dataTrace', ['instruments']),
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async addFlags() {
      await this.$store.dispatch('site/dataTrace/setSelectedSensor', this.form.selectedSensors);
      this.addFlag = true;
    },
    async viewFlags() {
      await this.$store.dispatch('site/dataTrace/getFlagSets');
      this.viewFlag = true;
    },
  },
};
</script>

<style lang="scss">
</style>
