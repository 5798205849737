import * as monaco from 'monaco-editor';

function initialize() {
  monaco.languages.register({ id: 'TemplateSetScriptLanguage-1.0' });
  monaco.languages.setMonarchTokensProvider('TemplateSetScriptLanguage-1.0', {
    defaultToken: 'invalid',
    ignoreCase: true,
    keywords: [
      'activate', 'template', 'when', 'if', 'is', 'active', 'sensor', 'name',
      'match', 'from', 'instrument', 'true', 'false',
    ],

    delimiterKeywords: [
      'then', 'else', 'end',
    ],

    operators: [
      'or', 'and', 'xor', 'not',
    ],

    symbols: /[=><!~?:&|+\-*/^%]+/,

    escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

    tokenizer: {
      root: [
        [/\b[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}\b/, 'number'],
        [/[a-z_$][\w$]*/, {
          cases: {
            '@keywords': 'keyword',
            '@delimiterKeywords': 'delimiter',
            '@operators': 'keyword',
          },
        }],

        { include: '@whitespace' },
        [/[{}()[\]]/, '@brackets'],

        [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],
      ],

      string: [
        [/[^\\"]+/, 'string'],
        [/@escapes/, 'string.escape'],
        [/\\./, 'string.escape.invalid'],
        [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }],
      ],

      whitespace: [
        [/#.*$/, 'comment'],
      ],
    },
  });

  function ReadCurrentLineUpToCaret(model, position) {
    return model.getValueInRange({
      startLineNumber: position.lineNumber, startColumn: 0, endLineNumber: position.lineNumber, endColumn: position.column,
    });
  }

  function ReadPreviousLine(model, lineNumber) {
    if (lineNumber > 1) {
      return model.getLineContent(lineNumber - 1);
    }
    return '';
  }

  function GetActiveBlocks(model, numberOfLines) {
    const stack = [];
    let lineNumber = 1;
    while (lineNumber <= numberOfLines) {
      // Get the line ignoring any comments
      const line = model.getLineContent(lineNumber).split('#')[0].toUpperCase();
      if (line.includes('THEN')) {
        stack.push('THEN');
      } else if (line.includes('ELSE')) {
        stack.push('ELSE');
      } else if (line.includes('END')) {
        let popValue = '';
        while (popValue !== 'THEN' && popValue != null) {
          popValue = stack.pop();
        }
      }
      lineNumber += 1;
    }
    return stack;
  }

  // Register a completion item provider for the new language
  monaco.languages.registerCompletionItemProvider('TemplateSetScriptLanguage-1.0', {
    triggerCharacters: [' '],
    provideCompletionItems: (model, position) => {
      let suggestions = [];

      const line = ReadCurrentLineUpToCaret(model, position);
      const previousLine = ReadPreviousLine(model, position.lineNumber).toUpperCase();

      const words = line.trim().split(' ');
      const currentWord = words[words.length - 1].toUpperCase();
      const booleanOperators = ['OR', 'AND', 'XOR', 'NOT'];

      const activeBlocks = GetActiveBlocks(model, position.lineNumber);
      const inUnclosedThen = activeBlocks.includes('THEN');
      const inUnclosedElse = activeBlocks.includes('ELSE');

      const matchNameIgnoreCase = function matchNameIgnoreCase(keyword) {
        return line.trim().toUpperCase().startsWith(keyword.toUpperCase());
      };

      const inConditionalExpression = line.toUpperCase().includes('WHEN ') || line.toUpperCase().includes('IF ');

      if (line.includes('#')) {
        // We're in a comment, so don't provide any suggestions.
        // Note that you'll still see spelling suggestion prompts based off of other words that have been used.
        suggestions = [];
        return { suggestions };
      }

      suggestions = [
        {
          label: 'ACTIVATE',
          detail: 'Activate a template',
          kind: monaco.languages.CompletionItemKind.Operator,
          insertText: 'ACTIVATE ',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'ACTIVATE [TEMPLATE] x [WHEN <CONDITIONAL EXPR>]\n\nActivate template with id x. Optional canditional expression may be included.',
        },
        {
          label: 'IF',
          detail: 'Verify a condition',
          kind: monaco.languages.CompletionItemKind.Operator,
          insertText: 'IF ',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'IF <CONDITIONAL EXPR> THEN <STATEMENT> [ELSE <STATEMENT>] END\n\nExecutes the statement if the condition is true, otherwise exucutes optional else statement.',
        },
      ];

      if (inUnclosedThen) {
        suggestions.push({
          label: 'END',
          detail: 'End if statement',
          insertText: 'END ',
        });
        if (!inUnclosedElse) {
          suggestions.push({
            label: 'ELSE',
            detail: 'Begin block to be executed when IF statement evalutes to false',
            insertText: 'ELSE ',
          });
        }
      }

      if (previousLine.includes('IF ') && !previousLine.includes('THEN')) {
        if (currentWord !== 'THEN') {
          suggestions = [{
            label: 'THEN',
            detail: 'Begin block to be executed when IF statement evaluates to true',
            insertText: 'THEN ',
          }];
        } else {
          suggestions = [];
        }
      }

      if (matchNameIgnoreCase('ACTIVATE')) {
        if (currentWord === 'ACTIVATE') {
          suggestions = [{
            label: 'TEMPLATE',
            detail: '(Optional) Template keyword',
            insertText: 'TEMPLATE ',
          },
          {
            label: '<GUID>',
            detail: 'Template ID',
            insertText: '',
          }];
        } else if (currentWord === 'TEMPLATE') {
          suggestions = [{
            label: '<GUID>',
            detail: 'Template ID',
            insertText: '',
          }];
        } else {
          suggestions = [{
            label: 'WHEN',
            detail: '(Optional) When <conditional expr>',
            insertText: 'WHEN ',
          },
          ];
        }
      }

      if (currentWord === 'WHEN' || currentWord === 'IF' || booleanOperators.includes(currentWord)) {
        suggestions = [
          {
            label: 'TEMPLATE',
            detail: 'Template activation test',
            documentation: 'Template <GUID> [IS] [NOT] ACTIVE',
            insertText: 'TEMPLATE ',
          },
          {
            label: 'SENSOR NAME',
            detail: 'Sensor exists',
            documentation: 'SENSOR NAME [MATCH] "<pattern>" FROM INSTRUMENT "<papttern>"',
            insertText: 'SENSOR NAME ',
          },
        ];
      } else if (inConditionalExpression) {
        if (currentWord === 'SENSOR') {
          suggestions = [{
            label: 'NAME',
            detail: '',
            insertText: 'NAME ',
          }];
        } else if (currentWord === 'INSTRUMENT' || currentWord === 'MATCH' || currentWord === 'NAME') {
          suggestions = [{
            label: '"<pattern>"',
            detail: 'Pattern to match',
            insertText: '""',
          }];
          if (currentWord === 'NAME') {
            suggestions.push({
              label: 'MATCH',
              detail: '(Optional) Match keyword',
              insertText: 'MATCH ',
            });
          }
        } else {
          const previousWord = words[words.length - 2].toUpperCase();
          if (currentWord.startsWith('"') && previousWord !== 'INSTRUMENT') {
            suggestions = [{
              label: 'FROM INSTRUMENT',
              detail: 'FROM INSTRUMENT key words',
              insertText: 'FROM INSTRUMENT ',
            }];
          } else if (currentWord === 'FROM') {
            suggestions = [{
              label: 'INSTRUMENT',
              detail: 'INSTRUMENT key word',
              insertText: 'INSTRUMENT ',
            }];
          } else if (currentWord === 'INSTRUMENT') {
            suggestions = [{
              label: '"<pattern>"',
              detail: 'Pattern to match',
              insertText: '""',
            }];
          } else if (currentWord === 'TEMPLATE') {
            suggestions = [{
              label: '<GUID>',
              detail: 'Template ID',
              insertText: '',
            }];
          } else if (previousWord === 'TEMPLATE') {
            suggestions = [
              {
                label: 'IS ACTIVE',
                detail: 'Used to check if template is active',
                insertText: 'IS ACTIVE ',
              },
              {
                label: 'IS NOT ACTIVE',
                detail: 'Used to check if template is inactive',
                insertText: 'IS NOT ACTIVE ',
              },
            ];
          } else if (currentWord === 'IS') {
            suggestions = [
              {
                label: 'ACTIVE',
                detail: 'Used to check if template is active',
                insertText: 'IS ',
              },
              {
                label: 'NOT ACTIVE',
                detail: 'Used to check if template is inactive',
                insertText: 'NOT ACTIVE ',
              },
            ];
          } else if (currentWord === 'NOT') {
            suggestions = [
              {
                label: 'ACTIVE',
                detail: 'Check if template is inactive',
                insertText: 'IS ',
              },
            ];
          } else if (!booleanOperators.includes(currentWord)) {
            suggestions = [
              {
                label: 'OR',
                detail: '(Optional) Boolean or',
                insertText: 'OR ',
              },
              {
                label: 'AND',
                detail: '(Optional) Boolean and',
                insertText: 'AND ',
              },
              {
                label: 'NOT',
                detail: '(Optional) Boolean not',
                insertText: 'NOT ',
              },
              {
                label: 'XOR',
                detail: '(Optional) Boolean xor',
                insertText: 'XOR ',
              },
            ];
          } else {
            suggestions = [];
          }
        }
      }

      return { suggestions };
    },
  });
}

export default {
  initialize,
};
