import api from '@/api/importal';

const state = {
  users: [],
  accessGroups: [],
  identity: null,
};

const actions = {
  async list({ commit }) {
    await api.get('OpUserList')
      .then((response) => response.data)
      .then((data) => {
        commit('users', data);
      })
      .catch(() => { });
  },
  async accessGroupList({ commit }) {
    const response = await api.get('OpAccessGroupList');
    commit('accessGroups', response.data);
  },
  async authenticate({ commit }) {
    const response = await api.get('UserGetInfo');
    commit('identity', response.data);
  },
};

const mutations = {
  users(state, payload) {
    state.users = payload;
  },
  accessGroups(state, payload) {
    state.accessGroups = payload;
  },
  identity(state, payload) {
    state.identity = payload;
  },
};

const getters = {
  identity(state) {
    return state.identity;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
