<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <DataGrid
      :refresh="refresh"
      :grid-source="sources.DataSource"
      :show-new-button="$auth.roles.includes('Web.Sensor.Create')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataGrid from '@/components/ServerSideDataGrid.vue';
import DataSource from './gridsources/gsvirtualinst';

export default {
  name: 'VirtualInstrumentList',
  components: {
    DataGrid,
  },
  data: () => ({
    refresh: 0,
    sources: {
      DataSource: null,
    },
  }),
  computed: {
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),
  },
  watch: {
    async tenantId() {
      this.refresh += 1;
    },
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.refresh += 1;
      }
    },
  },
  created() {
    this.sources.DataSource = new DataSource(this);
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>
