/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';
import datehandling from '@/components/datehandling';

export default class GsAlertStatus extends ServerSideDataGridGsClientBase {
  constructor(cb) {
    super();
    this.callback = cb;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'AlertName',
          headerName: i18n.t('Alert.Manage.AlertList.SensorAlertName'),
          width: 200,
          resizable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'Type',
          headerName: i18n.t('Alert.Manage.AlertList.Type'),
          width: 150,
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.typeCellRenderer.bind(this),
        },
        {
          field: 'StateFlags',
          headerName: i18n.t('Alert.Manage.AlertList.State'),
          width: 230,
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.stateFlagCellRenderer.bind(this),
        },
        {
          field: 'StateTimeStamp',
          headerName: i18n.t('Alert.Manage.AlertList.StateTimeStamp'),
          width: 140,
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.dateCellRendererFromUTC.bind(this),
        },
        {
          field: 'ViolationValue',
          headerName: i18n.t('Alert.Manage.AlertList.ViolationValue'),
          width: 200,
          resizable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'ViolationTimeStamp',
          headerName: i18n.t('Alert.Manage.AlertList.ViolationTimeStamp'),
          width: 160,
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.dateCellRendererFromUTC.bind(this),
        },
        {
          field: 'Description',
          headerName: i18n.t('Alert.Manage.AlertList.Description'),
          cellStyle: { 'white-space': 'pre-wrap' },
          width: 500,
          resizable: true,
          sortable: true,
          filter: true,
          autoHeight: true,
        },
      ]);
    });
  }

  dateCellRendererFromUTC(params) {
    return params.value
      ? datehandling.formatForDisplay(datehandling.zonedTimeToUtc(params.value))
      : '';
  }

  stateFlagCellRenderer(params) {
    return this.callback.getStateFlag(params.value);
  }

  typeCellRenderer(params) {
    return params.value ? i18n.t(`Alert.AlertStatus.FunctionIdTypes.${params.value}`) : '';
  }
}
