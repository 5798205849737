/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class OrganizationalUnitUsersSource extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
    this.height = '77%';
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          maxWidth: 30,
          resizable: false,
          cellStyle: { padding: 0 },
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            icon: 'mdi-account-minus',
            action: i18n.t('Remove'),
            confirm: (event) => (`${i18n.t('Settings.AccessControl.ManageOrganizationalUnits.User')} "${event.data.UserName}"`),
            clicked: (event) => this.apiOpOrgUnitUserRemove(event.api, event.data),
          },
        },
        {
          field: 'UserName', flex: 12, headerName: i18n.t('Settings.AccessControl.ManageOrganizationalUnits.UserName'),
        },
        {
          field: 'UserId', headerName: i18n.t('Settings.AccessControl.ManageOrganizationalUnits.UserId'),
        },
      ]);
    });
  }

  // eslint-disable-next-line no-unused-vars
  async apiOpOrgUnitUserRemove(api, data) {
    const resp = await importal
      .post('OpOrgUnitUserRemove', {
        OrganisationalUnitId: this.vue.selection.unit.OrganisationalUnitId,
        UserId: data.UserId,
      });

    api.setRowData(resp.data);

    this.vue.availableUsersList.push({ UserName: data.UserName, UserId: data.UserId });
    this.vue.availableUsersList.sort((a, b) => a.UserName.localeCompare(b.UserName));
  }
}
