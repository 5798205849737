import store from '@/store';

function findUnitName(unitList, toFind) {
  const found = unitList.find((y) => y.Symbol === toFind);
  if (found && found.Name) {
    return found.Name;
  }
  return null;
}

const actions = {
  jsonWidgetSettings(state, payload) {
    const query = payload.widgetSettings;
    const { visualisationSubType } = payload;
    const type = visualisationSubType.startsWith('sensor+') ? 'S' : 'V';
    const { units } = payload;

    const symbolFromSelection = function symbolFromSelection(x, i) {
      return `${x.T.toLowerCase()}${x.Id}${i}`;
    };

    const placeholders = store.getters['dashboardPlaceholders/placeholders'];
    const selectedPlaceholderSensors = store.getters['dashboardPlaceholders/selectedPlaceholderSensors'];
    const placeholderSensors = [];
    if (query.user.selectedPlaceholders && query.user.selectedPlaceholders.length > 0) {
      query.user.selectedPlaceholders.forEach((x) => {
        const item = selectedPlaceholderSensors.find((y) => y.placeholderId === x.Id);
        if (item) {
          item.selectedItem.forEach((selectedPH) => {
            if (selectedPH.T === type) {
              const item2 = {};
              item2.placeholderId = item.placeholderId;
              item2.selectedItem = selectedPH;
              item2.selectedItem.Y2Axis = x.Y2Axis;
              item2.selectedItem.Aggregation = x.Aggregation;
              placeholderSensors.push(item2);
            } else if (selectedPH.T === 'I') {
              if (selectedPH.sensorList
                && selectedPH.sensorList.length > 0
                && selectedPH.selected
              ) {
                selectedPH.sensorList.forEach((sensorInList) => {
                  const item2 = {};
                  item2.placeholderId = item.placeholderId;
                  item2.selectedItem = sensorInList;
                  item2.selectedItem.Y2Axis = x.Y2Axis;
                  item2.selectedItem.Aggregation = x.Aggregation;
                  placeholderSensors.push(item2);
                });
              }
            } else if (selectedPH.T === 'A') {
              if (selectedPH.sensorList
                && selectedPH.sensorList.length > 0
                && selectedPH.selected
              ) {
                selectedPH.sensorList.forEach((sensorInList) => {
                  const item2 = {};
                  item2.placeholderId = item.placeholderId;
                  item2.selectedItem = sensorInList;
                  item2.selectedItem.Y2Axis = x.Y2Axis;
                  item2.selectedItem.Aggregation = x.Aggregation;
                  placeholderSensors.push(item2);
                });
              }
            }
          });
        }
      });
    }

    // placeholderSensors.forEach((selectedPlaceholder) => {
    //   const matchingPlaceholder = placeholders.find(
    //     (placeholder) => placeholder.PlaceholderId === selectedPlaceholder.placeholderId,
    //   );

    //   if (matchingPlaceholder) {
    //     // Add sensors from the matching placeholder to showAlarm
    //     if (query.visualization.showAlarmThresholds) {
    //       if (query.visualization.showAlarm) {
    //         query.visualization.showAlarm = query.visualization.showAlarm.concat(
    //           matchingPlaceholder.JsonData.SelectedItem
    //             .filter((sensor) => sensor.T === 'S')
    //             .map((sensor) => ({
    //               T: sensor.T,
    //               Id: sensor.Id,
    //               style: { width: 3 },
    //             })),
    //         );
    //       } else {
    //         query.visualization.showAlarm = matchingPlaceholder.JsonData.SelectedItem
    //           .filter((sensor) => sensor.T === 'S')
    //           .map((sensor) => ({
    //             T: sensor.T,
    //             Id: sensor.Id,
    //             style: { width: 3 },
    //           }));
    //       }
    //     }
    //   }
    // });

    let sensors = query.user.selectedSensors
      .map((x, i) => {
        const item = x;
        item.Unit = (typeof item.Unit === 'string' || item.Unit instanceof String) ? item.Unit : null;
        item.ReadingUnit = (typeof item.ReadingUnit === 'string' || item.ReadingUnit instanceof String) ? item.ReadingUnit : null;
        const unit = item.T === 'S' ? item.Unit : item.ReadingUnit;

        return {
          sensor: x,
          alias: x.Alias || `${x.InstrumentName}.${x.Name} (${x.Aggregation || 'AVG'}${unit ? `, ${unit}` : ''})`,
          symbol: symbolFromSelection(x, i),
        };
      });
    sensors = sensors.concat(placeholderSensors
      .map((x, i) => {
        const item = x.selectedItem;
        item.Unit = (typeof item.Unit === 'string' || item.Unit instanceof String) ? item.Unit : null;
        item.ReadingUnit = (typeof item.ReadingUnit === 'string' || item.ReadingUnit instanceof String) ? item.ReadingUnit : null;
        const alias = placeholders.find((y) => y.PlaceholderId === x.placeholderId).JsonData.Alias;

        return {
          sensor: x.selectedItem,
          alias: store.getters['dashboardPlaceholders/sensorAlias'](alias, x.selectedItem),
          symbol: symbolFromSelection(x.selectedItem, sensors.length + i),
        };
      }));

    if (query.visualization.showAlarmThresholds) {
      query.visualization.showAlarm = sensors
        .filter((x) => x.sensor.T === 'S')
        .map((x) => ({
          T: x.sensor.T,
          Id: x.sensor.Id,
          style: { width: 3 },
        }));
      placeholderSensors.forEach((selectedPlaceholder) => {
        const matchingPlaceholder = placeholders.find(
          (placeholder) => placeholder.PlaceholderId === selectedPlaceholder.placeholderId,
        );
        if (matchingPlaceholder) {
          query.visualization.showAlarm = query.visualization.showAlarm.concat(
            matchingPlaceholder.JsonData.SelectedItem
              .filter((sensor) => sensor.T === 'S')
              .map((sensor) => ({
                T: sensor.T,
                Id: sensor.Id,
                style: { width: 3 },
              })),
          );
        }
      });
    }

    query.data.formulas = sensors
      .filter((x) => x.sensor.T === 'S')
      .map((x) => ({
        symbol: `${x.symbol}`,
        formula_text: `=${x.symbol};`,
        output_aggregation: x.sensor.Aggregation || 'AVG',
        unit: x.sensor.Unit ? findUnitName(units, x.sensor.Unit) : null,
      }));

    if (visualisationSubType.startsWith('vector+fourier+')) {
      query.data.formulas = query.data.formulas.concat(sensors
        .filter((x) => x.sensor.T === 'V')
        .map((x) => {
          const vector = {
            symbol: `${x.symbol}`,
            formula_text: `=VRealFft(VDimRescale(${x.symbol},${1.0 / Math.max(0.00001, query.user.selectedSampleRate)}));`,
            output_aggregation: x.sensor.Aggregation || 'AVG',
            unit: x.sensor.ReadingUnit ? findUnitName(units, x.sensor.ReadingUnit) : null,
          };
          return vector;
        }));
    } else {
      query.data.formulas = query.data.formulas.concat(sensors
        .filter((x) => x.sensor.T === 'V')
        .map((x) => {
          const vector = {
            symbol: `${x.symbol}`,
            formula_text: `=${x.symbol};`,
            output_aggregation: x.sensor.Aggregation || 'AVG',
            unit: x.sensor.ReadingUnit ? findUnitName(units, x.sensor.ReadingUnit) : null,
          };
          return vector;
        }));
    }

    query.data.sensor_selection = (sensors
      .filter((x) => x.sensor.T === 'S')
      .map((x) => ({
        symbol: `${x.symbol}`,
        sensor_id: x.sensor.Id,
        input_aggregation: x.sensor.Aggregation || 'AVG',
        unit: x.sensor.Unit ? findUnitName(units, x.sensor.Unit) : null,
      }))
    );

    query.data.vector_selection = (sensors
      .filter((x) => x.sensor.T === 'V')
      .map((x) => ({
        symbol: `${x.symbol}`,
        vector_sensor_id: x.sensor.Id,
        vector_name: x.sensor.Name,
        input_aggregation: x.sensor.Aggregation || 'AVG',
        unit: x.sensor.ReadingUnit ? findUnitName(units, x.sensor.ReadingUnit) : null,
      }))
    );

    query.visualization.graphOptions.x_axis = [];
    query.visualization.graphOptions.y_axis = [];
    query.visualization.graphOptions.groupby = [];
    if (visualisationSubType.startsWith('vector+scatter+') || visualisationSubType.startsWith('vector+fourier+')) {
      query.visualization.graphOptions.x_axis = [
        (sensors.filter((x) => x.sensor.T === 'V')
          .map((x) => (JSON.parse(`{"${x.symbol}": "index"}`)))[0]),
      ];
      query.visualization.graphOptions.groupby = [{
        ts: 'value',
      }];
    } else if (visualisationSubType.startsWith('vector+sbs_vertical+scatter+')) {
      query.visualization.graphOptions.x_axis = (sensors
        .filter((x) => x.sensor.T === 'V')
        .map((x) => (JSON.parse(`{"${x.symbol}": "value"}`)))
      );
      query.visualization.graphOptions.y_axis = (sensors
        .filter((x) => x.sensor.T === 'V')
        .map((x) => (JSON.parse(`{"${x.symbol}": "index"}`)))
      );
      query.visualization.graphOptions.groupby = [{
        ts: 'value',
      }];
      if (payload.widgetSettings
        && payload.widgetSettings.visualization
        && payload.widgetSettings.visualization.graphOptions
        && payload.widgetSettings.visualization.graphOptions.sbs) {
        // eslint-disable-next-line max-len
        query.visualization.graphOptions.sbs = payload.widgetSettings.visualization.graphOptions.sbs;
      } else {
        query.visualization.graphOptions.sbs = {
          rows: 1,
          cols: 2,
          shared_yaxes: true,
          shared_xaxes: false,
          timestamp_format: '%Y-%m-%d',
        };
      }
      query.visualization.graphOptions.sortby = 'y';
    } else {
      query.visualization.graphOptions.x_axis = [{
        ts: 'value',
      }];
    }

    const isSideBySidePlot = visualisationSubType.startsWith('vector+sbs_vertical+')
       || visualisationSubType.startsWith('vector+sbs_horizontal+')
       || visualisationSubType.startsWith('sensor+sbs_vertical+')
       || visualisationSubType.startsWith('sensor+sbs_horizontal+');
    if (!isSideBySidePlot) {
      query.visualization.graphOptions.y_axis = query.visualization.graphOptions.y_axis
        .concat(sensors
          .filter((x) => x.sensor.T === 'S')
          .map((x) => ({
            p: !x.sensor.Y2Axis,
            v: (JSON.parse(`{"${x.symbol}": "value"}`)),
          }))
          .filter((x) => x.p).map((x) => x.v));

      query.visualization.graphOptions.y_axis = query.visualization.graphOptions.y_axis
        .concat(sensors
          .filter((x) => x.sensor.T === 'V')
          .map((x) => ({
            p: !x.sensor.Y2Axis,
            v: (JSON.parse(`{"${x.symbol}": "value"}`)),
          }))
          .filter((x) => x.p).map((x) => x.v));

      query.visualization.graphOptions.y2_axis = []
        .concat(sensors
          .filter((x) => x.sensor.T === 'S')
          .map((x) => ({
            p: x.sensor.Y2Axis,
            v: (JSON.parse(`{"${x.symbol}": "value"}`)),
          }))
          .filter((x) => x.p).map((x) => x.v));

      query.visualization.graphOptions.y2_axis = (query.visualization.graphOptions.y2_axis || [])
        .concat(sensors
          .filter((x) => x.sensor.T === 'V')
          .map((x) => ({
            p: x.sensor.Y2Axis,
            v: (JSON.parse(`{"${x.symbol}": "value"}`)),
          }))
          .filter((x) => x.p).map((x) => x.v));

      if (query.visualization.graphOptions.y2_axis.length === 0) {
        query.visualization.graphOptions.y2_axis = undefined;
      }
    }

    query.visualization.alias = sensors.map((x) => (JSON.parse(`{"${x.symbol}": "${x.alias}"}`)));
  },
};

export default {
  namespaced: true,
  actions,
};
