<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.QCTaskTypes"
      :show-new-button="$auth.roles.includes('Web.QCTaskType.Create')"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="qcTaskTypes"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import QCTaskTypeList from '../gridsources/gsQCTaskTypes';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        QCTaskTypes: null,
      },
      toolbarButtons: [],
    };
  },
  computed: {
    ...mapState('qualityCheck/qcTaskTypes', ['qcTaskTypes']),
  },
  created() {
    this.sources.QCTaskTypes = new QCTaskTypeList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.QCTaskType.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('New'),
        icon: 'mdi-plus',
        enabled: true,
        click: () => { this.createNew(); },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'QualityCheck.QCTaskTypes.Create' });
    },
    refreshList() {
      this.$store.dispatch('qualityCheck/qcTaskTypes/list');
    },
  },
};
</script>
