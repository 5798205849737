/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsAccessGroups extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          maxWidth: 30,
          resizable: false,
          cellStyle: { padding: 0 },
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => this.onEditButtonClick(event.api, event.data),
          },
        },
        {
          maxWidth: 30,
          resizable: false,
          cellStyle: { padding: 0 },
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (`${i18n.t('Settings.AccessControl.ManageAccessGroups.AccessGroup')} "${event.data.Name}"`),
            clicked: (event) => this.apiOpAccessGroupRemove(event.api, event.data),
          },
        },
        {
          field: 'AccessGroupId', maxWidth: 100, headerName: i18n.t('Settings.AccessControl.ManageAccessGroups.GroupId'),
        },
        {
          field: 'Name', headerName: i18n.t('Settings.AccessControl.ManageAccessGroups.AccessGroupName'),
        },
        {
          field: 'TenantName', headerName: i18n.t('Settings.AccessControl.ManageAccessGroups.TenantName'),
        },
        {
          field: 'TenantId', minWidth: 250, headerName: i18n.t('Settings.AccessControl.ManageAccessGroups.TenantId'),
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    return {
      getRows: (params) => {
        importal.get('OpAccessGroupList')
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition(data) {
    return new Promise((resolve) => {
      importal.get('OpTenantList')
        .then((resp) => {
          resolve({
            Model: {
              Name: data ? data.Name : null,
              Tenant: data ? data.Tenant : null,
            },
            Schema: {
              Name: { label: i18n.t('Settings.AccessControl.ManageAccessGroups.AccessGroupName'), type: 'text', flex: 12 },
              Tenant: {
                label: i18n.t('Settings.AccessControl.ManageAccessGroups.Tenant'),
                type: 'select',
                returnObject: true,
                itemText: 'Name',
                items: resp.data,
                flex: 12,
              },
            },
          });
        });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    importal
      .post('OpAccessGroupNew', {
        TenantId: data.Tenant.TenantId,
        Name: data.Name,
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async onEditButtonClick(api, data) {
    const d = await this.editItemFormDefinition(data, api);
    this.vue.editPopup.Model = d.Model;
    this.vue.editPopup.Schema = d.Schema;
    this.vue.editPopup.Show = true;
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async editItemFormDefinition(data, api) {
    return new Promise((resolve) => {
      resolve({
        Model: {
          Api: api,
          AccessGroupId: data.AccessGroupId,
          Name: data.Name,
        },
        Schema: {
          AccessGroupId: {
            label: i18n.t('Settings.AccessControl.ManageAccessGroups.AccessGroupId'), type: 'text', disabled: true, flex: 12,
          },
          Name: { label: i18n.t('Settings.AccessControl.ManageAccessGroups.AccessGroupName'), type: 'text', flex: 12 },
        },
      });
    });
  }

  async apiOpOrgAccessGroupRename(data) {
    const resp = await importal
      .post('OpAccessGroupRename', {
        AccessGroupId: data.AccessGroupId,
        Name: data.Name,
      });
    data.Api.setRowData(resp.data);
  }

  async apiOpAccessGroupRemove(api, data) {
    const resp = await importal
      .post('OpAccessGroupRemove', {
        AccessGroupId: data.AccessGroupId,
      });
    api.setRowData(resp.data);
  }

  validateValues(data) {
    let errormessage = '';
    if (!data.Name || data.Name.length === 0) {
      errormessage = errormessage.concat(`${i18n.t('Validation.RequiredNamedField',
        { Field: i18n.t('Settings.AccessControl.ManageAccessGroups.AccessGroupName') })}. `);
    }

    if (!data.AccessGroupId && !data.Tenant) {
      errormessage = errormessage.concat(`${i18n.t('Validation.RequiredNamedField',
        { Field: i18n.t('Settings.AccessControl.ManageAccessGroups.Tenant') })}. `);
    }

    return errormessage;
  }
}
