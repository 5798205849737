/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsRemovedcEquipment extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          filter: true,
          sortable: true,
          width: 100,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.PeNumber'),
          valueGetter: (params) => `${String(params.data.Id).padStart(5, '0')}`,
        },
        {
          field: 'StorageName',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.StorageName'),
        },
      ]);
    });
  }
}
