<template>
    <v-row justify="center">
        <v-dialog
          v-model="show"
          scrollable
          persistent
          max-width="510px"
        >
          <v-card>
            <v-card-text>
             <v-form
          ref="form"
          lazy-validation
        >
          <v-select
        v-model="formData.QCMeasurementId"
        :label="$t('QualityCheck.QCSectionMeasurements.Name')"
        :items="measurementValues"
        :rules="[v => !!v || $t('Validation.Required')]"
        item-text="Name"
        item-value="Id"
        :readonly="readMode & measurementId!=null"
        id="SectionMeasurement"
        />
      </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
              <v-btn
              id="btnAddEditMeasurement"
              v-if="$auth.roles.includes('Web.QCInspectionTemplate.Manage')"
              text
              @click="submit"
            >
              {{ $t('Accept') }}
            </v-btn>
            <v-btn
            id="btnCloseMeasurementForm"
              text
             @click.stop="show=false"
          >
           {{ $t('Cancel') }}
        </v-btn>
       </v-card-actions>
       </v-card>
     </v-dialog>
   </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    measurementId: {
      type: Number,
      required: false,
      default: null,
    },
    readMode: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
    };
  },
  computed: {
    ...mapState('qualityCheck/qcInspectionTemplates', ['qcInspectionTemplate']),
    ...mapState('qualityCheck/qcSectionMeasurements', ['measurementValues', 'sectionMeasurement']),
  },
  watch: {
    async measurementId() {
      await this.getMeasurement();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    await this.$store.dispatch('qualityCheck/qcSectionMeasurements/measurementValuesList', this.measurementId);
    if (this.measurementId && this.measurementId != null) { // View or Update mode
      await this.getMeasurement();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    async getMeasurement() {
      await this.$store.dispatch('qualityCheck/qcSectionMeasurements/get', { measurementId: this.measurementId });
      this.formData = this.$clone(this.sectionMeasurement);
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'QualityCheck.QCInspectionTemplates.Sections.Measurements' });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.measurementId) {
          await this.$store.dispatch('qualityCheck/qcSectionMeasurements/update', this.formData);
        } else {
          await this.$store.dispatch('qualityCheck/qcSectionMeasurements/create', this.formData);
        }

        this.navigateToList();
      }
    },
  },
};
</script>
