import store from '@/store';

function findUnitName(unitList, toFind) {
  const found = unitList.find((y) => y.Symbol === toFind);
  if (found && found.Name) {
    return found.Name;
  }
  return null;
}

const actions = {
  jsonWidgetSettings(state, payload) {
    const query = payload.widgetSettings;
    const { visualisationSubType } = payload;
    const type = visualisationSubType.startsWith('sensor+') ? 'S' : 'V';
    const { units } = payload;

    const symbolFromSelection = function symbolFromSelection(x, i) {
      return `${x.T.toLowerCase()}${x.Id}${i}`;
    };

    const symbolFromSelectionX = function symbolFromSelectionX(x, i) {
      return `${x.T.toLowerCase()}${x.Id}${i}`;
    };

    const symbolFromSelectionY = function symbolFromSelectionY(x, i, j) {
      const newi = i + j;
      return `${x.T.toLowerCase()}${x.Id}${newi}`;
    };

    const selectedPlaceholderSensors = store.getters['dashboardPlaceholders/selectedPlaceholderSensors'];
    const placeholderSensorsX = [];
    if (query.user.selectedPlaceholdersX && query.user.selectedPlaceholdersX.length > 0) {
      query.user.selectedPlaceholdersX.forEach((x) => {
        const item = selectedPlaceholderSensors.find((y) => y.placeholderId === x.Id);
        if (item && item.selectedItem) {
          item.selectedItem.forEach((selectedPH) => {
            if (selectedPH.T === type) {
              const item2 = {};
              item2.placeholderId = item.placeholderId;
              item2.selectedItem = selectedPH;
              item2.selectedItem.Y2Axis = x.Y2Axis;
              item2.selectedItem.Aggregation = x.Aggregation;
              placeholderSensorsX.push(item2);
            } else if (selectedPH.T === 'I') {
              if (selectedPH.sensorList && selectedPH.sensorList.length > 0) {
                selectedPH.sensorList.forEach((sensorInList) => {
                  const item2 = {};
                  item2.placeholderId = item.placeholderId;
                  item2.selectedItem = sensorInList;
                  item2.selectedItem.Y2Axis = x.Y2Axis;
                  item2.selectedItem.Aggregation = x.Aggregation;
                  placeholderSensorsX.push(item2);
                });
              }
            } else if (selectedPH.T === 'A') {
              if (selectedPH.sensorList
                && selectedPH.sensorList.length > 0
                && selectedPH.selected
              ) {
                selectedPH.sensorList.forEach((sensorInList) => {
                  const item2 = {};
                  item2.placeholderId = item.placeholderId;
                  item2.selectedItem = sensorInList;
                  item2.selectedItem.Y2Axis = x.Y2Axis;
                  item2.selectedItem.Aggregation = x.Aggregation;
                  placeholderSensorsX.push(item2);
                });
              }
            }
          });
        }
      });
    }
    const placeholderSensorsY = [];
    if (query.user.selectedPlaceholdersY && query.user.selectedPlaceholdersY.length > 0) {
      query.user.selectedPlaceholdersY.forEach((x) => {
        const item = selectedPlaceholderSensors.find((y) => y.placeholderId === x.Id);
        if (item) {
          item.selectedItem.forEach((selectedPH) => {
            if (selectedPH.T === type) {
              const item2 = {};
              item2.placeholderId = item.placeholderId;
              item2.selectedItem = selectedPH;
              item2.selectedItem.Y2Axis = x.Y2Axis;
              item2.selectedItem.Aggregation = x.Aggregation;
              placeholderSensorsY.push(item2);
            } else if (selectedPH.T === 'I') {
              if (selectedPH.sensorList
                && selectedPH.sensorList.length > 0
                && selectedPH.selected
              ) {
                selectedPH.sensorList.forEach((sensorInList) => {
                  const item2 = {};
                  item2.placeholderId = item.placeholderId;
                  item2.selectedItem = sensorInList;
                  item2.selectedItem.Y2Axis = x.Y2Axis;
                  item2.selectedItem.Aggregation = x.Aggregation;
                  placeholderSensorsY.push(item2);
                });
              }
            } else if (selectedPH.T === 'A') {
              if (selectedPH.sensorList
                && selectedPH.sensorList.length > 0
                && selectedPH.selected
              ) {
                selectedPH.sensorList.forEach((sensorInList) => {
                  const item2 = {};
                  item2.placeholderId = item.placeholderId;
                  item2.selectedItem = sensorInList;
                  item2.selectedItem.Y2Axis = x.Y2Axis;
                  item2.selectedItem.Aggregation = x.Aggregation;
                  placeholderSensorsY.push(item2);
                });
              }
            }
          });
        }
      });
    }

    const sensorsX = query.user.selectedSensorsForX
      .map((x) => {
        const item = x;
        item.Unit = (typeof item.Unit === 'string' || item.Unit instanceof String) ? item.Unit : null;
        item.ReadingUnit = (typeof item.ReadingUnit === 'string' || item.ReadingUnit instanceof String) ? item.ReadingUnit : null;
        const unit = x.T === type ? x.Unit : x.ReadingUnit;

        return ({
          sensor: x,
          alias: x.Alias || `${x.InstrumentName}.${x.Name} (${x.Aggregation || 'AVG'}${unit ? `, ${unit}` : ''})`,
        });
      });
    const sensorsY = query.user.selectedSensorsForY
      .map((x) => {
        const item = x;
        item.Unit = (typeof item.Unit === 'string' || item.Unit instanceof String) ? item.Unit : null;
        item.ReadingUnit = (typeof item.ReadingUnit === 'string' || item.ReadingUnit instanceof String) ? item.ReadingUnit : null;
        const unit = x.T === type ? x.Unit : x.ReadingUnit;

        return ({
          sensor: x,
          alias: x.Alias || `${x.InstrumentName}.${x.Name} (${x.Aggregation || 'AVG'}${unit ? `, ${unit}` : ''})`,
        });
      });

    const placeholders = store.getters['dashboardPlaceholders/placeholders'];
    for (let i = 0; i < placeholderSensorsX.length; i += 1) {
      const placeholderSensorX = placeholderSensorsX[i];
      const aliasX = placeholders.find((x) => x.PlaceholderId === placeholderSensorX.placeholderId)
        .JsonData.Alias;
      sensorsX.push({
        sensor: placeholderSensorX.selectedItem,
        alias: store.getters['dashboardPlaceholders/sensorAlias'](aliasX, placeholderSensorX.selectedItem),
      });
    }

    for (let i = 0; i < placeholderSensorsY.length; i += 1) {
      const placeholderSensorY = placeholderSensorsY[i];
      const aliasY = placeholders.find((x) => x.PlaceholderId === placeholderSensorY.placeholderId)
        .JsonData.Alias;
      sensorsY.push({
        sensor: placeholderSensorY.selectedItem,
        alias: store.getters['dashboardPlaceholders/sensorAlias'](aliasY, placeholderSensorY.selectedItem),
      });
    }

    query.visualization.graphOptions.x_axis = sensorsX
      .filter((x) => x.sensor.T === type)
      .map((x, i) => (JSON.parse(`{"${symbolFromSelectionX(x.sensor, i)}": "value"}`)));
    query.visualization.graphOptions.y_axis = sensorsY
      .filter((x) => x.sensor.T === type)
      .map((x, i) => (JSON.parse(`{"${symbolFromSelectionY(x.sensor, i, sensorsX.length)}": "value"}`)));

    query.data.formulas = sensorsX.concat(sensorsY)
      .filter((x) => x.sensor.T === type)
      .map((x, i) => ({
        symbol: symbolFromSelection(x.sensor, i),
        formula_text: `=${symbolFromSelection(x.sensor, i)};`,
        output_aggregation: x.sensor.Aggregation || 'AVG',
        unit: x.sensor.Unit ? findUnitName(units, x.sensor.Unit) : null,
      }));

    query.data.sensor_selection = sensorsX.concat(sensorsY)
      .filter((x) => x.sensor.T === type)
      .map((x, i) => ({
        symbol: symbolFromSelection(x.sensor, i),
        sensor_id: x.sensor.Id,
        input_aggregation: x.sensor.Aggregation || 'AVG',
        unit: x.sensor.Unit ? findUnitName(units, x.sensor.Unit) : null,
      }));

    query.visualization.graphOptions.y2_axis = sensorsY
      .filter((x) => x.sensor.T === type)
      .map((x, i) => ({
        p: x.sensor.Y2Axis,
        v: (JSON.parse(`{"${symbolFromSelection(x.sensor, i)}": "value"}`)),
      }))
      .filter((x) => x.p).map((x) => x.v);

    if (query.visualization.graphOptions.y2_axis.length === 0) {
      query.visualization.graphOptions.y2_axis = undefined;
    }

    query.visualization.alias = sensorsX.concat(sensorsY)
      .filter((x) => x.sensor.T === type)
      .map((x, i) => (JSON.parse(`{"${symbolFromSelection(x.sensor, i)}": "${x.alias}"}`)));

    if (query.visualization.showAlarm && query.visualization.showAlarm.length > 0) {
      query.visualization.showAlarm = sensorsX.concat(sensorsY)
        .filter((x) => x.T === type)
        .map((x) => ({
          T: x.sensor.T,
          Id: x.sensor.Id,
          style: { width: 3 },
        }));
    }
  },
};

export default {
  namespaced: true,
  actions,
};
