<template>
  <section class="menu">
    <v-menu
      v-for="(m, idx) in menu"
      :key="idx"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <a
          v-bind="attrs"
          v-on="on"
          v-t="`Customers.Sites.JumpToMenu.${m.text}`"
        />
        <span class="divider px-1" v-if="idx !== 0">|</span>
      </template>
      <v-list>
        <v-list-item
          v-for="(i, iidx) in m.items"
          :key="iidx"
        >
          <v-list-item-title
            v-t="`Customers.Sites.JumpToMenu.${i}`"
            @click="goto(i)"
          />
        </v-list-item>
      </v-list>
    </v-menu>
  </section>
</template>

<script>
export default {
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menu: [{
        text: 'SiteInfo',
        items: [
          'DetailsAndTimeline',
          // 'EquipmentConfiguration',
          // 'FilesAndPhotos',
        ],
      }],
    };
  },
  methods: {
    goto(name) {
      this.$router.push({
        name: `Customers.Sites.${name}`,
        params: {
          id: this.site.SiteGuid,
        },
      });
    },
  },
};
</script>

<style scoped>
  .menu {
    display: inline;
  }

  a {
    text-decoration: underline;
  }

  .v-list-item {
    cursor: pointer;
  }
</style>
