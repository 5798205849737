<template>
  <v-card
    style="height: 100%; overflow: hidden;"
  >
    <v-card-text
      v-if="toggleTitle || !displayDateTime"
      style="text-align: center; width: 100%;"
    >
      {{ displayName }}
    </v-card-text>
    <v-card-text
      v-if="!toggleTitle && displayDateTime"
      style="text-align: center; width: 100%;"
    >
      {{ displayDateTime }}
    </v-card-text>

    <v-card-text
      v-if="!hasData"
    >
      $t{{ 'NoData' }}
    </v-card-text>
    <div
      v-if="hasData"
      ref="displayValueEl"
      :style="displayValueStyle"
    >
      {{ displayValueText }}
    </div>
  </v-card>
</template>
<script>
import importal from '@/api/importal';
import datehandling from '@/components/datehandling';
import DashboardStackVueWidgetAdapter from '../DashboardStackVueWidgetAdapter';

function getTextMetrics(text, font) {
  // re-use canvas object for better performance
  const canvas = getTextMetrics.canvas || (getTextMetrics.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics;
}

function getTextWidth(text, font) {
  return getTextMetrics(text, font).width;
}
function getTextHeight(text, font) {
  const metrics = getTextMetrics(text, font);
  return metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
}

function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFontSize(el = document.body, fixedFontSize = null) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';
  return `${fontWeight} ${fixedFontSize || fontSize} ${fontFamily}`;
}

export default {
  name: 'DataTableVueWidget',
  components: {
  },
  props: {
    widgetSettings: {
      type: Object,
      default: () => {},
      required: true,
    },
    widgetAdapter: {
      type: DashboardStackVueWidgetAdapter,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      editModeFlag: false, // Directly mutated by the adapter
      toggleTitle: false,
      widgetSize: null, // Directly mutated by the adapter

      hasData: false,
      displayValueText: '2614.000',
      displayName: '???',
      displayDateTime: null,

      maxFontSize: 500,
      minFontSize: 8,
    };
  },
  computed: {
    elementHeight() {
      return getTextHeight(
        this.displayValueText,
        getCanvasFontSize(this.$refs.displayValueEl),
      ) + 50;
    },
    fontSize() {
      const fontHeight = Math.max(
        Math.min(
          (7.0 * (this.widgetSize.h - 54)) / getTextHeight(
            this.displayValueText,
            getCanvasFontSize(this.$refs.displayValueEl, 12),
          ),
          parseFloat(this.maxFontSize),
        ),
        parseFloat(this.minFontSize),
      );
      const fontWidth = (10.0 * (this.widgetSize.w - 50)) / getTextWidth(
        this.displayValueText,
        getCanvasFontSize(this.$refs.displayValueEl, 12),
      );
      return Math.min(fontHeight, fontWidth);
    },
    displayValueStyle() {
      return `text-align: center; width: 100%; font-size: ${this.fontSize}px;`;
    },
  },
  watch: {
    toggleTitle: {
      handler() {
        setTimeout(() => {
          this.toggleTitle = !this.toggleTitle;
        }, 5000);
      },
      immediate: true,
    },
  },
  i18n: {
    messages: {
      en: {
        NoData: 'No Data',
      },
      fr: {
        NoData: 'Aucune donnée',
      },
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    /*
     {
  "TableName": "",
  "Columns": [
    {
      "ColumnName": "ts",
      "DataType": "System.DateTime",
      "MaxLength": -1
    },
    {
      "ColumnName": "s324",
      "DataType": "System.Double",
      "MaxLength": -1
    }
  ],
  "Rows": [
    [
      "2022-04-24T20:00:00",
      2613.1950666666667
    ]
  ]
} */
    async init() {
      // eslint-disable-next-line no-unused-vars
      this.displayName = this.widgetSettings.user.mainTitle
        || this.findAlias(this.widgetSettings.data.sensor_selection[0].symbol);
      const data = await (await importal.post('SensorLastValue', this.widgetSettings.data)).data;
      if (data) {
        this.displayValueText = Number(data.Rows[0][1])
          .toFixed(Number(this.widgetSettings.user.precisionDigits || 3));
        if (this.widgetSettings.user.unitTitle) {
          this.displayValueText = `${this.displayValueText} [${this.widgetSettings.user.unitTitle}]`;
        }
        // eslint-disable-next-line prefer-destructuring
        this.displayDateTime = datehandling.formatForDisplay(data.Rows[0][0]);
        this.hasData = true;
      } else {
        this.hasData = false;
      }
    },

    findAliasCategory(symbol) {
      const found = this.widgetSettings.visualization.alias.find((x) => x[symbol] !== undefined);
      if (found) {
        return found[symbol].split('.');
      }
      return ['', symbol];
    },

    findAlias(symbol) {
      const found = this.widgetSettings.visualization.alias.find((x) => x[symbol] !== undefined);
      if (found) {
        return found[symbol];
      }
      return symbol;
    },
  },
};
</script>
