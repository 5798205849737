import * as XLSX from 'xlsx/xlsx.mjs';
import importal from '@/api/importal';
import fileDownload from 'js-file-download';

export async function onSensorQueryDebug(widget) {
  let settings = null;
  try {
    settings = JSON.parse(widget.WidgetSettings);
  } catch (e) {
    return;
  }
  if (settings.user && settings.user.timezone) {
    settings.data.timezone = settings.user.timezone; // copy time zone info
  }

  const data = await (await importal.post('SensorQueryDebug', settings.data)).data;

  fileDownload(data, `query-export-${widget.DashboardsWidgetId}.kql`, 'text/kql');
}

export async function onExportExcel(widget) {
  let settings = null;
  try {
    settings = JSON.parse(widget.WidgetSettings);
  } catch (e) {
    return;
  }
  if (settings.user && settings.user.timezone) {
    settings.data.timezone = settings.user.timezone; // copy time zone info
  }

  const data = await (await importal.post('SensorQueryExcel', settings.data)).data;

  function findAlias(symbol) {
    const found = settings.visualization.alias.find((x) => x[symbol] !== undefined);
    if (found) {
      return found[symbol];
    }
    return symbol;
  }

  const workbook = XLSX.utils.book_new();
  const aoa = [[]];
  let isVector = false;
  data.Rows.forEach((row) => {
    if (!isVector) {
      const outRow = [];
      for (let colIdx = 0; colIdx < row.length; colIdx += 1) {
        const col = data.Columns[colIdx];
        const item = row[colIdx];
        isVector = settings.data.vector_selection.find((x) => x.symbol === col.ColumnName);
        if (aoa.length === 1 && !isVector) {
          aoa[0].push(findAlias(col.ColumnName));
        }
        if (!isVector) {
          outRow.push(item);
        }
      }
      if (!isVector) {
        aoa.push(outRow);
      }
    }
  });
  let worksheet;
  if (isVector) {
    let instId;
    if (settings.user.selectedSensors) {
      instId = settings.user.selectedSensors[0].InstrumentId;
    } else {
      // placeholder
      instId = settings.user.selectedPlaceholders[0].InstrumentId;
    }
    const attributes = await (
      await importal.get(`DevInstrumentAttributeList?InstrumentId=${instId}`)
    ).data;

    let vectorAxis = ' ';
    if (attributes) {
      const found = attributes.find((x) => x.Name === 'VectorAxis');
      if (found) {
        vectorAxis = found.ValueStr;
      }
    }
    const aoaVec = [[]];

    // Loop through each row in the data
    data.Rows.forEach((row) => {
      for (let rowIdx = 0; rowIdx < row[1].value.length; rowIdx += 1) {
        const outRow = [];
        // If it's the first row, populate the header row in aoaVec
        if (aoaVec.length === 1) {
          // Loop through each column in the data
          data.Columns.forEach((col, colIdx) => {
            // Add column name or index if it's the first column
            aoaVec[0].push(findAlias(col.ColumnName));
            if (colIdx === 0) {
              aoaVec[0].push(`${vectorAxis}`);
            }
          });
        }
        // Loop through each column in the data
        data.Columns.forEach((col, colIdx) => {
          // If it's the first column, add timestamp
          // eslint-disable-next-line
          if (colIdx === 0) {
            outRow.push(row[0]); // Add timestamp
          } else if (colIdx === 1) { // If it's the second column
            const { _si, _so, value } = row[colIdx]; // Destructure _si, _so, and value
            // Calculate and add the incremented value and actual value
            outRow.push(_si * rowIdx + _so, value[rowIdx]);
          } else {
            // If it's any other column, just add the value
            outRow.push(row[colIdx].value[rowIdx]);
          }
        });
        aoaVec.push(outRow); // Add the row to aoaVec
      }
    });
    worksheet = XLSX.utils.aoa_to_sheet(aoaVec, { dateNF: 'yyyy/mm/dd hh:mm:ss' });
  } else {
    worksheet = XLSX.utils.aoa_to_sheet(aoa, { dateNF: 'yyyy/mm/dd hh:mm:ss' });
  }
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
  XLSX.writeFile(workbook, 'Export.xlsx', {});
}
