<template>
  <v-card
    v-if="editorActive"
  >
    <!-- SENSOR SELECTION STEP -->
    <v-card-title
      v-if="showSensorStep"
    >
      [Attribute] Select Sensor.
    </v-card-title>
    <v-card-text
      v-if="showSensorStep"
    >
      <v-row>
        <v-col sm="6">
          <v-text-field
            v-model="form.searchSensorTerm"
            hint="Use * wildcard to specify *ending or *contains*, default to starting."
            label="sensor"
            prepend-icon="mdi-magnify"
            append-icon="mdi-arrow-right-circle"
            @click:append="searchSensor"
            @keyup.enter="searchSensor"
          />
        </v-col>
        <v-col sm="6">
          <v-text-field
            v-model="form.searchInstrumentTerm"
            hint="Use * wildcard to specify *ending or *contains*, default to starting."
            label="instrument"
            prepend-icon="mdi-magnify"
            append-icon="mdi-arrow-right-circle"
            @click:append="searchSensor"
            @keyup.enter="searchSensor"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-virtual-scroll
      v-if="showSensorStep"
      :key="form.itemList.Id"
      :items="form.itemList"
      :item-height="28"
      height="260"
      @scroll.native="scrolling"
    >
      <template #default="{ item }">
        <v-list-item
          :key="item.Id"
          class="sensor-search-item pt-0 pb-0"
          style="min-height: 24px;"
          @click="selectSensor(item)"
        >
          <v-list-item-icon
            class="pt-0 pb-0 mt-0 mb-0"
          >
            <v-icon v-if="item.T == 'S'">
              mdi-alpha-s-box-outline
            </v-icon>
            <v-icon v-if="item.T == 'V'">
              mdi-alpha-v-box-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            {{ item.DisplayName || item.Name }}
          </v-list-item-content>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            <v-badge
              :content="item.InstrumentName"
              inline
            />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>

    <!-- ATTRIBUTE SELECTION -->
    <v-card-title
      v-if="showAttributeStep"
    >
      [Attribute] Select Attribute.
    </v-card-title>
    <v-virtual-scroll
      v-if="showAttributeStep"
      :key="form.attributeList.Id"
      :items="form.attributeList"
      :item-height="28"
      height="260"
    >
      <template #default="{ item }">
        <v-list-item
          :key="item.Id"
          class="sensor-search-item pt-0 pb-0"
          style="min-height: 24px;"
          @click="selectAttribute(item)"
        >
          <v-list-item-icon
            class="pt-0 pb-0 mt-0 mb-0"
          >
            <v-icon v-if="item.T == 'S'">
              mdi-alpha-s-box-outline
            </v-icon>
            <v-icon v-if="item.T == 'V'">
              mdi-alpha-v-box-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            {{ item.DisplayName || item.Name }}
          </v-list-item-content>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            <v-badge
              :content="item.ValueFloat"
              inline
            />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>

    <!-- Additional input -->
    <v-card-title
      v-if="showAdditionalInputStep"
    >
      [Attribute] Additional information.
    </v-card-title>
    <v-card-text
      v-if="showAdditionalInputStep"
    >
      <v-text-field
        v-model="editor.DefaultValue"
        label="Default Value"
        type="number"
        dense
      />
    </v-card-text>

    <slot />
  </v-card>
</template>

<script>
import importal from '@/api/importal';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default {
  components: {
  },
  props: {
    init: {
      type: Object,
      default: () => {},
      required: true,
    },
    itemIncrement: {
      type: Number,
      default: () => 20,
      required: false,
    },
  },
  data: () => ({
    editor: {
      editorMode: null,
      node: {
        ViSensorAttributeInputId: null,
        nodeType: null,
        SensorId: null,
        Symbol: null,
        DefaultValue: null,
        AttributeName: null,
        Unit: null,
      },
      ViSensorAttributeInputId: null,
      SensorId: null,
      Symbol: null,
      DefaultValue: null,
      AttributeName: null,
      Unit: null,
    },
    form: {
      searchSensorTerm: '',
      searchInstrumentTerm: '',
      itemList: [],
      SensorName: null,
      InstrumentName: null,
      attributeList: [],

      SensorId: null,
      AttributeName: null,
      DefaultValue: null,
    },
    pickerTextFieldProps: {
      dense: true,
      clearable: true,
      class: 'col-4',
      style: 'font-size: 80%;',
    },
    pickerDateProps: {
      locale: 'en-ca',
    },
    pickerTimeProps: {
      format: '24hr',
    },
  }),
  computed: {
    editorActive() {
      return this.editor && this.editor.show && this.editor.node && (this.editor.node.nodeType === 'sensor_attribute_input');
    },
    showSensors() {
      return this.editor.node.nodeType === 'sensor_attribute_input';
    },
    sensorCount() {
      if (this.showSensors) {
        return this.itemIncrement.toString();
      }
      return '0';
    },
    sensorOffset() {
      return this.form.itemList.filter((x) => x.T === 'S').length.toString();
    },
    vectorCount() {
      return '0';
    },
    vectorOffset() {
      return this.form.itemList.filter((x) => x.T === 'V').length.toString();
    },

    showSensorStep() {
      return !this.form.SensorId;
    },
    showAttributeStep() {
      return this.form.SensorId && !this.form.AttributeName;
    },
    showAdditionalInputStep() {
      return this.form.SensorId && this.form.AttributeName;
    },

  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.initialize();
      }
    },
    editor() {
      if (this.editorActive) {
        this.$emit('editor-update', this.editor);
      }
    },
  },
  async created() {
    this.scrolling = debounce(this.scrolling);
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.editor = this.init;
      this.editor.Symbol = this.editor.node.Symbol ?? this.editor.node.InputSymbol;
      this.editor.SensorId = this.editor.node.SensorId;
      this.editor.ViSensorAttributeInputId = this.editor.node.ViSensorAttributeInputId;
      this.editor.DefaultValue = this.editor.node.DefaultValue;
      this.editor.AttributeName = this.editor.node.AttributeName;
      this.editor.Unit = this.editor.node.Unit;

      this.form.SensorId = this.editor.SensorId;
      this.form.AttributeName = this.editor.AttributeName;
      this.form.DefaultValue = this.editor.DefaultValue;

      const query = `SensorList?so=0&sc=${this.sensorCount}&vo=0&vc=${this.vectorCount}`
        + `&searchSensor=${this.form.searchSensorTerm}`
        + `&searchInstrument=${this.form.searchInstrumentTerm}`;
      this.form.itemList = await (await importal.get(query)).data;
    },
    async scrolling(event) {
      const element = event.currentTarget || event.target;
      if (element && element.scrollHeight - element.scrollTop === element.clientHeight) {
        // this.$emit('scroll-end');
        await this.showMore();
      }
    },
    async showMore() {
      this.spamfree = true;
      const newItems = await (await importal.get(`SensorList?so=${this.sensorOffset}&sc=${this.sensorCount}`
        + `&vo=${this.vectorOffset}&vc=${this.vectorCount}`
        + `&searchSensor=${this.form.searchSensorTerm}`
        + `&searchInstrument=${this.form.searchInstrumentTerm}`)).data;
      while (newItems.length > 0) {
        this.form.itemList.push(newItems[0]);
        newItems.shift();
      }
      this.spamfree = false;
    },
    async searchSensor() {
      this.setListContent(
        this.form.itemList,
        await (await importal.get(`SensorList?so=0&sc=${this.sensorCount}`
        + `&vo=0&vc=${this.vectorCount}`
        + `&searchSensor=${this.form.searchSensorTerm}`
        + `&searchInstrument=${this.form.searchInstrumentTerm}`)).data,
      );
    },
    async selectSensor(item) {
      if (item.T === 'S') {
        this.editor.SensorId = item.Id;
        this.form.SensorId = item.Id;
        // this.$emit('editor-update', this.editor);
        await this.listAttributes();
      }
    },

    async listAttributes() {
      this.setListContent(
        this.form.attributeList,
        await (await importal.get(`DevSensorAttributeList?SensorId=${this.form.SensorId}`)).data,
      );
    },
    selectAttribute(item) {
      this.editor.AttributeName = item.Name;
      this.form.AttributeName = item.Name;
    },

    setListContent(lst, content) {
      lst.splice(0, lst.length);
      lst.push(...content);
    },
  },
};
</script>

<style>

</style>
