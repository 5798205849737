<template>
  <div class="my-2">
    <label
      class="mr-1"
    >
      {{ $t(`Customers.Sites.DetailsAndTimeline.${label}`, labelParams) }}:
    </label>
    {{ value }}
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    labelParams: {
      type: Object,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
}
</style>
