import api from '@/api/importal';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  dashboards: [],
  selectedDashboard: null,
};

const actions = {
  async list({ commit }) {
    return withWait({ commit }, () => api.get('DashboardList'))
      .then((resp) => commit('dashboards', resp.data));
  },
  async create({ commit }, payload) {
    return withWait({ commit }, () => api.post('DashboardNew', payload))
      .then((resp) => commit('dashboards', resp.data));
  },
  async save({ commit }, payload) {
    return withWait({ commit }, () => api.post('DashboardUpdate', payload))
      .then((resp) => commit('dashboards', resp.data));
  },
  async home({ commit }, payload) {
    return withWait({ commit }, () => api.post('DashboardHome', payload))
      .then((resp) => commit('dashboards', resp.data));
  },
  async remove({ commit }, payload) {
    return withWait({ commit }, () => api.delete('DashboardRemove', { data: payload }))
      .then((resp) => commit('dashboards', resp.data));
  },
  async shareWithGroup({ commit }, payload) {
    return withWait({ commit }, () => api.post('DashboardShareWithGroup', payload))
      .then((resp) => commit('dashboards', resp.data));
  },
  async shareWithUser({ commit }, payload) {
    return withWait({ commit }, () => api.post('DashboardShareWithUser', payload))
      .then((resp) => commit('dashboards', resp.data));
  },
  async select({ commit }, payload) {
    commit('selectedDashboard', payload);
  },
};

const mutations = {
  dashboards(state, payload) {
    state.dashboards = payload;
  },
  selectedDashboard(state, payload) {
    state.selectedDashboard = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
