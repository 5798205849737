<template>
  <v-card-text
    v-if="show"
    class="pb-0 pt-0"
  >
    <v-select
      v-model="form.type"
      :items="typeOfAxis.map(label => $t('Dashboard.VisualizationSetting.'.concat(label)))"
      dense
    />
    <v-card-text
      v-if="form.type===$t('Dashboard.VisualizationSetting.Manual')"
    >
      <v-row
        v-if="showx"
      >
        <v-col sm="4">
          <v-text-field
            v-model="form.xmin"
            :label="$t('Dashboard.VisualizationSetting.xmin')"
            :class="{ 'shaking': shakeX }"
            type="number"
            dense
          />
        </v-col>
        <v-col sm="4">
          <v-text-field
            v-model="form.xmax"
            :label="$t('Dashboard.VisualizationSetting.xmax')"
            :class="{ 'shaking': shakeX }"
            type="number"
            dense
          />
        </v-col>
        <v-col sm="4">
          <v-checkbox
            v-model="form.logx"
            :label="$t('Dashboard.VisualizationSetting.logx')"
            :class="{ 'shaking': shakeX }"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4">
          <v-text-field
            v-model="form.ymin"
            :label="$t('Dashboard.VisualizationSetting.ymin')"
            :class="{ 'shaking': shakeY }"
            type="number"
            dense
          />
        </v-col>
        <v-col sm="4">
          <v-text-field
            v-model="form.ymax"
            :label="$t('Dashboard.VisualizationSetting.ymax')"
            :class="{ 'shaking': shakeY }"
            type="number"
            dense
          />
        </v-col>
        <v-col sm="4">
          <v-checkbox
            v-model="form.logy"
            :label="$t('Dashboard.VisualizationSetting.logy')"
            :class="{ 'shaking': shakeY }"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="showy2"
      >
        <v-col sm="4">
          <v-text-field
            v-model="form.y2min"
            :label="$t('Dashboard.VisualizationSetting.y2min')"
            :class="{ 'shaking': shakeY2 }"
            type="number"
            dense
          />
        </v-col>
        <v-col sm="4">
          <v-text-field
            v-model="form.y2max"
            :label="$t('Dashboard.VisualizationSetting.y2max')"
            :class="{ 'shaking': shakeY2 }"
            type="number"
            dense
          />
        </v-col>
        <v-col sm="4">
          <v-checkbox
            v-model="form.logy2"
            :label="$t('Dashboard.VisualizationSetting.logy2')"
            :class="{ 'shaking': shakeY2 }"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
  models: [
    { data: 'form.type', event: 'models:typeOfAxis' },
    { data: 'form.xmin', event: 'models:xmin' },
    { data: 'form.xmax', event: 'models:xmax' },
    { data: 'form.logx', event: 'models:logx' },
    { data: 'form.ymin', event: 'models:ymin' },
    { data: 'form.ymax', event: 'models:ymax' },
    { data: 'form.logy', event: 'models:logy' },
    { data: 'form.y2min', event: 'models:y2min' },
    { data: 'form.y2max', event: 'models:y2max' },
    { data: 'form.logy2', event: 'models:logy2' },
  ],
  props: {
    show: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
    showx: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
    showy2: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
    shakeX: Boolean,
    shakeY: Boolean,
    shakeY2: Boolean,
  },
  data: () => ({
    typeOfAxis: ['Manual', 'Auto'],

    form: {
      xmin: '',
      xmax: '',
      ymin: '',
      ymax: '',
      y2min: '',
      y2max: '',
      logx: false,
      logy: false,
      logy2: false,
      type: 'Auto',
    },
    manual: false,
    auto: true,
  }),
  computed: {
    ...mapGetters('app', ['pleaseWait', 'timezone']),
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    'form.type': function w(newVal) { this.$emit('models:typeOfAxis', newVal); },
    'form.xmin': function w(newVal) { this.$emit('models:xmin', newVal); },
    'form.xmax': function w(newVal) { this.$emit('models:xmax', newVal); },
    'form.ymin': function w(newVal) { this.$emit('models:ymin', newVal); },
    'form.ymax': function w(newVal) { this.$emit('models:ymax', newVal); },
    'form.y2min': function w(newVal) { this.$emit('models:y2min', newVal); },
    'form.y2max': function w(newVal) { this.$emit('models:y2max', newVal); },
    'form.logx': function w(newVal) { this.$emit('models:logx', newVal); },
    'form.logy': function w(newVal) { this.$emit('models:logy', newVal); },
    'form.logy2': function w(newVal) { this.$emit('models:logy2', newVal); },
  },
  methods: {
    removeSelectedSensor(item) {
      this.form.selectedSensors.splice(this.form.selectedSensors.indexOf(item), 1);
    },
  },
  i18n: {
    messages: {
      en: {
        AggregateBy: 'Aggregate by',
      },
      fr: {
        AggregateBy: 'Agrégé par',
      },
    },
  },
};
</script>

<style scoped>
.shaking {
  animation: shake 1s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
