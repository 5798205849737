<template>
  <div
    v-if="$auth.roles.includes('Web.Tenant.Manage')"
    style="height: calc(100vh - 95px); width: 100%"
  >
    <ServerSideDataGrid
      :grid-source="sources.TenantsSource"
      :show-new-button="$auth.roles.includes('Web.Tenant.Create')"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="tenants"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import TenantsSource from '../gridsources/gstenants';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        TenantsSource: null,
      },
      toolbarButtons: [],
    };
  },
  computed: {
    ...mapState('settings/tenants', ['tenants']),
  },
  created() {
    this.sources.TenantsSource = new TenantsSource(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.Tenant.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('New'),
        icon: 'mdi-plus',
        enabled: true,
        click: () => { this.createNew(); },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'Settings.Tenants.Create' });
    },
    async refreshList() {
      await this.$store.dispatch('settings/tenants/list');
    },
  },
};
</script>
