import api from '@/api/importal';

const state = {
  equipmentModel: {
    ModelId: null,
    EquipmentType: null,
    ManufacturerName: null,
    ModelName: null,
    ShortName: null,
    TowerHeight: null,
    BoomLength: null,
    TBoom: null,
    PostHeight: null,
    TowerModelId: null,
    LatticeTower: null,
    LoggerType: null,
    SensorType: null,
    OutputType: null,
    OffsetFromTopOfPost: null,
    WindvaneDeadband: null,
  },
  equipmentModels: [],
  towerModels: null,
  nameAlreadyExists: false,
};

const actions = {
  async get({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`GetModelById?modelId=${payload.id}`);
    commit('equipmentModel', response.data);
    if (response.data.EquipmentType.EquipmentTypeId === 5) {
      await dispatch('equipment/equipments/getSensorType', null, { root: true });
    }
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('ModelList');
    commit('equipmentModels', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async save({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('ModelSave', {
      ModelId: (payload.ModelId != null ? payload.ModelId : 0),
      EquipmentTypeId: payload.EquipmentType.EquipmentTypeId,
      ManufacturerName: payload.ManufacturerName,
      ModelName: payload.ModelName,
      ShortName: payload.ShortName,
      TowerHeight: payload.TowerHeight,
      BoomLength: payload.BoomLength,
      TBoom: (payload.TBoom != null ? payload.TBoom : false),
      PostHeight: payload.PostHeight,
      TowerModelId: payload.TowerModelId,
      LatticeTower: payload.LatticeTower,
      LoggerType: payload.LoggerType,
      SensorType: payload.SensorType,
      OutputType: payload.OutputType,
      OffsetFromTopOfPost: payload.OffsetFromTopOfPost,
      WindvaneDeadband: payload.WindvaneDeadband,
    });
    commit('app/pleaseWait', false, { root: true });
    await commit('init');
    await dispatch('list');
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`ModelRemove?id=${payload.id}`);
    commit('equipmentModel', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async getTowerModels({ state, commit }) {
    if (state.towerModels == null) {
      commit('app/pleaseWait', true, { root: true });
      const response = await api.get('TowerModelList');
      commit('app/pleaseWait', false, { root: true });
      commit('towerModels', response.data);
    }
  },

  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('nameAlreadyExists', false);
    const response = await api.get(`ModelsValidateName?id=${payload.ModelId != null ? payload.ModelId : 0}&name=${payload.ModelName}`);
    if (response.data != null) {
      commit('nameAlreadyExists', true);
    }
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  setModel(state, model) {
    state.model = model;
  },
  equipmentModel(state, payload) {
    state.equipmentModel = payload;
  },
  equipmentModels(state, payload) {
    state.equipmentModels = payload;
  },
  towerModels(state, payload) {
    state.towerModels = payload;
  },
  init(state) {
    state.towerModels = null;
    state.equipmentModel.ModelId = null;
    state.equipmentModel.EquipmentType = null;
    state.equipmentModel.ManufacturerName = null;
    state.equipmentModel.ModelName = null;
    state.equipmentModel.ShortName = null;
    state.equipmentModel.TowerHeight = null;
    state.equipmentModel.BoomLength = null;
    state.equipmentModel.TBoom = null;
    state.equipmentModel.PostHeight = null;
    state.equipmentModel.TowerModelId = null;
    state.equipmentModel.LatticeTower = null;
    state.equipmentModel.LoggerType = null;
    state.equipmentModel.SensorType = null;
    state.equipmentModel.OutputType = null;
    state.equipmentModel.OffsetFromTopOfPost = null;
    state.equipmentModel.WindvaneDeadband = null;
  },
  nameAlreadyExists(state, payload) {
    state.nameAlreadyExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
