/* eslint-disable no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import config from '../../config';
import i18n from '../../plugins/i18n';
import axiosRetry from 'axios-retry';

const api = axios.create({
  baseURL: config.API.RENDER.BASE_URL + config.API.RENDER.ENDPOINT,
});

api.interceptors.request.use(async (request) => {
  // TODO: This app may use a ApiToken one day
  // const apiToken = await Vue.prototype.$auth.GetApiToken();
  const apiToken = await Vue.prototype.$auth.GetToken();

  request.headers['Access-Control-Allow-Origin'] = '*';
  // By-passing token - TODO need to implement CRUD for users and permissions;
  if (apiToken) {
    request.headers.Authorization = `Bearer ${apiToken.idToken}`;
  }
  request.headers.Accept = 'application/json';
  request.headers['Content-Type'] = 'application/json';
  request.headers['Accept-Language'] = `${i18n.locale}-US`;

  return request;
});

api.interceptors.response.use((response) => response);

// Force retry on network error
axiosRetry(api, {
  retries: 3,
  retryCondition: (error) => {
    if (error && error.message && error.message === 'Network Error') {
      return true;
    }
    return axiosRetry.isNetworkOrIdempotentRequestError(error);
  },
});

export default api;
