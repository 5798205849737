import api from '@/api/importal';

const state = {
  cellularAccountProvider: null,
  cellularAccountProviders: [],
  providernameAlredyExists: false,
};

const actions = {
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('CellularAccountProvidersCreate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`CellularAccountProvidersGet?id=${payload.id}`);
    commit('cellularAccountProvider', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('CellularAccountProvidersList');
    commit('cellularAccountProviders', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`CellularAccountProvidersRemove?id=${payload.id}`);
    commit('cellularAccountProvider', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('CellularAccountProvidersUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('providernameAlredyExists', false);
    const response = await api.get(`CellularAccountProvidersValidate?id=${payload.CellularAccountProviderId != null ? payload.CellularAccountProviderId : 0}&name=${payload.ProviderName}`);
    if (response.data != null) {
      commit('providernameAlredyExists', true);
    }
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  cellularAccountProvider(state, payload) {
    state.cellularAccountProvider = payload;
  },
  cellularAccountProviders(state, payload) {
    state.cellularAccountProviders = payload;
  },
  providernameAlredyExists(state, payload) {
    state.providernameAlredyExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
