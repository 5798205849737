<template>
  <div
    class="secondary row"
    style="height:85px;padding:0px;margin-left:0px;"
  >
    <div class="col col-2">
      <v-select
        ref="selectClient"
        v-model="formData.AccessGroupId"
        :label="$t('QualityCheck.QCTasks.Tasks.TenantName')"
        :items="clients"
        item-text="Name"
        item-value="Id"
      />
    </div>
    <div class="col col-2">
      <v-select
        ref="selectSite"
        v-model="formData.SiteId"
        :label="$t('QualityCheck.QCTasks.Tasks.MonitoringSite')"
        :items="monitoringSites"
        item-text="Name"
        item-value="Id"
      />
    </div>
    <div class="col col-2">
      <v-select
        ref="selectStatus"
        v-model="formData.StatusId"
        :items="status"
        label="Status"
        item-text="Name"
        item-value="Id"
      />
    </div>
    <div class="col col-2">
      <v-select
        ref="selectTaskType"
        v-model="formData.QCTaskTypeId"
        :label="$t('QualityCheck.QCTasks.Tasks.TaskType')"
        :items="qcTaskTypes"
        item-text="Name"
        item-value="TaskTypeId"
      />
    </div>
    <div class="col col-2">
      <v-select
        ref="selectAssignedTo"
        v-model="formData.AssignedToUserId"
        :label="$t('QualityCheck.QCTasks.Tasks.AssignedTo')"
        :items="users"
        item-text="DisplayName"
        item-value="UserId"
      />
    </div>
    <div class="col col-2">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            style="margin-top:13px;min-width:40px;padding:0px;"
            v-bind="attrs"
            v-on="on"
            @click="search();"
          >
            <i class="v-icon mdi mdi-magnify" />
          </v-btn>
        </template>
        <span>{{ $t('Search') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            style="margin-top:13px;margin-left:9px;min-width:55px;padding:0px;"
            v-bind="attrs"
            v-on="on"
            @click="clearFilters();"
          >
            <i class="v-icon mdi mdi-backspace" />
          </v-btn>
        </template>
        <span>{{ $t('ClearFilters') }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="$auth.roles.includes('Web.QCTask.Create')"
        bottom
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-btn
            style="margin-top:13px;margin-left:9px;min-width:40px;padding:0px;"
            v-bind="attrs"
            v-on="on"
            @click="createNew();"
          >
            <i class="v-icon mdi mdi-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('AddNew') }} </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      formData: {},
      status: [
        {
          Id: 0,
          Name: this.$root.$i18n.t('QualityCheck.QCTasks.Status.Uncompleted'),
        },
        {
          Id: 1,
          Name: this.$root.$i18n.t('QualityCheck.QCTasks.Status.Completed'),
        },
        {
          Id: 2,
          Name: this.$root.$i18n.t('QualityCheck.QCTasks.Status.All'),
        },
      ],
    };
  },
  computed: {
    ...mapState('qualityCheck/qcTasks', ['qcTaskTypes', 'monitoringSites', 'users', 'clients', 'filters']),
  },
  methods: {

    clearFilters() {
      this.$refs.selectClient.reset();
      this.$refs.selectSite.reset();
      this.$refs.selectAssignedTo.reset();
      this.$refs.selectTaskType.reset();
      this.formData.StatusId = 2;
    },

    async search() {
      if (this.formData.AccessGroupId === undefined) {
        this.formData.AccessGroupId = null;
      }
      if (this.formData.SiteId === undefined) {
        this.formData.SiteId = null;
      }
      if (this.formData.QCTaskTypeId === undefined) {
        this.formData.QCTaskTypeId = null;
      }
      if (this.formData.AssignedToUserId === undefined) {
        this.formData.AssignedToUserId = null;
      }
      if (this.formData.StatusId === undefined) {
        this.formData.StatusId = 2;
      }
      await this.$store.dispatch('qualityCheck/qcTasks/setFilters', this.formData);
      await this.$store.dispatch('qualityCheck/qcTasks/filterList');
    },

    createNew() {
      this.$router.push({ name: 'QualityCheck.QCTasks.Create' });
    },

  },
};
</script>
