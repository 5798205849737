/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsQCAssignations extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'DisplayName',
          headerName: this.vue.$t('QualityCheck.QCSchedules.Name'),
          width: 350,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Initials',
          headerName: this.vue.$t('QualityCheck.QCSchedules.Initials'),
          width: 150,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'TechnicianSites',
          headerName: this.vue.$t('QualityCheck.QCSchedules.AssignedAsTechnician'),
          width: 210,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'AnalystSites',
          headerName: this.vue.$t('QualityCheck.QCSchedules.AssignedAsAnalyst'),
          width: 210,
          editable: false,
          filter: true,
          sortable: true,
        },
      ]);
    });
  }
}
