<template>
  <div>
    <v-select
      v-model="model"
      :items="items"
      item-value="DashboardId"
      item-text="Name"
      :return-object="true"
      :label="$t('Dashboard.Menu.SelectDashboard')"
      dense
      hide-details
      class="dashboard-select mt-4"
      @input="selectDashboard"
    >
      <template #selection="{ item }">
        <v-text-field
          v-model="item.Name"
          readonly
          flat
          background-color="transparent"
          hide-details="auto"
          dense
          solo
          class="full-width"
        >
          <v-icon
            v-if="item.isDefault"
            slot="append"
          >
            mdi-home
          </v-icon>
          <v-icon
            v-if="item.isShared"
            slot="append"
          >
            mdi-account-multiple
          </v-icon>
          <v-icon
            v-if="!$auth.roles.includes('Web.Dashboard.Modify')"
            slot="append"
          >
            {{ item.DisplayAccessGroupId ? 'mdi-lock' : 'mdi-lock-open-variant' }}
          </v-icon>
        </v-text-field>
      </template>
      <template
        slot="item"
        slot-scope="data"
      >
        <span class="cb-item">{{ data.item.Name }}</span><v-spacer />
        <v-icon v-if="data.item.isDefault">
          mdi-home
        </v-icon>
        <v-icon v-if="data.item.isShared">
          mdi-account-multiple
        </v-icon>
        <v-icon>
          {{ data.item.DisplayAccessGroupId ? 'mdi-lock' : 'mdi-lock-open-variant' }}
        </v-icon>
      </template>
      <template #append-outer>
        <Form
          v-model="NameForm.Show"
          :model="NameForm.Model"
          :schema="NameForm.Schema"
          @on-accept="onNameFormAccept"
        />
        <Form
          v-model="AccessGroupForm.Show"
          :model="AccessGroupForm.Model"
          :schema="AccessGroupForm.Schema"
          @on-accept="onAccessGroupFormAccept"
        />
        <v-tooltip
          v-if="selDashboard.dashboardId"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              class="cursor-pointer d-none d-md-flex"
              v-on="on"
              @click="defineHomeboard"
            >
              mdi-home
            </v-icon>
          </template>
          <span>{{ $t('Dashboard.Menu.DefineHomeDashboard') }}</span>
        </v-tooltip>
        <v-tooltip
          v-if="selDashboard.dashboardId && $auth.roles.includes('Web.Dashboard.Modify')"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              class="cursor-pointer d-none d-md-flex"
              v-on="on"
              @click="onRenameClick"
            >
              mdi-form-textbox
            </v-icon>
          </template>
          <span>{{ $t('Dashboard.Menu.EditDashboardName') }}</span>
        </v-tooltip>
        <v-tooltip
          v-if="selDashboard.dashboardId && $auth.roles.includes('Web.Dashboard.Modify')"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              class="cursor-pointer d-none d-md-flex pl-1"
              v-on="on"
              @click="onAccessGroupClick"
            >
              {{ model && model.DisplayAccessGroupId ? 'mdi-lock' : 'mdi-lock-open-variant' }}
            </v-icon>
          </template>
          <div style="white-space: pre;">
            <span>{{ $t('Dashboard.Menu.DisplayAccessGroup') }}</span>
            <span v-if="model && model.DisplayAccessGroupId">{{ `\n${$t('Selected')}: ${model.DisplayAccessGroupName}` }}</span>
          </div>
        </v-tooltip>
        <v-tooltip
          v-if="selDashboard.dashboardId && $auth.roles.includes('Web.Dashboard.ModifyTenantHomeDashboard')"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              class="cursor-pointer d-none d-md-flex"
              v-on="on"
              @click="onTenantHomedash"
            >
              mdi-home-assistant
            </v-icon>
          </template>
          <span>{{ $t('Dashboard.Menu.DefineHomeDashboardTenant') }}</span>
        </v-tooltip>
      </template>
    </v-select>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          {{ $t('Dashboard.Menu.DashboardChangeNotification') }}
        </v-card-title>
        <v-card-text>
          {{ $t('Dashboard.Menu.ChangedTenantHome') }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">
            {{ $t('Dashboard.Menu.OK') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/components/AutoFormPopup.vue';
import api from '@/api/importal';

export default {
  name: 'DashboardSelect',
  components: {
    Form,
  },
  props: {
    selDashboard: {
      type: Object,
      default: () => null,
    },
    dashItems: {
      type: Array,
      default: () => null,
    },
    editModeFlag: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      model: null,
      showHomeIcon: false,
      items: [],
      stackMenuInterface: null,
      cellHeight: 15, // Number of pixels of vertical snap for widgets,
      accessGroupList: [],
      tenantList: [],
      dialog: false,
      NameForm: {
        Model: {
          Name: null,
        },
        Schema: {
          Name: { label: this.$t('Dashboard.Menu.DashboardName'), type: 'text', flex: 12 },
        },
        Show: false,
      },
      AccessGroupForm: {
        Model: {
          AccessGroup: null,
        },
        Schema: {
          AccessGroup: {
            label: this.$t('Dashboard.Menu.AccessGroupName'),
            type: 'select',
            returnObject: true,
            itemText: 'AccessGroupName',
            items: [],
            flex: 12,
          },
        },
        Show: false,
      },
    };
  },
  computed: {
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),
    ...mapGetters('user', ['identity']),

    selectedDefault() {
      return true;
    },
  },
  watch: {
    async selDashboard(val) {
      this.model = {};
      this.model.DashboardId = val.dashboardId;
      this.model.Name = val.name;
      this.model.DisplayAccessGroupId = val.displayAccessGroupId;
      this.model.DisplayAccessGroupName = val.displayAccessGroupName;
    },
    async dashItems(val) {
      this.items = [];
      this.items = val;
      this.items.forEach((item, index) => {
        if (this.identity.user.HomepageDashboard) {
          this.items[index].isDefault = (item.DashboardId === this.identity.user.HomepageDashboard);
        } else {
          this.items[index].isDefault = (index === 0);
        }
      });
    },
    tenantId() {
      this.updateAccessGroups();
    },
    accessGroupIds(newVal, oldVal) {
      if (!this.spamfree && oldVal.join() !== newVal.join()) {
        this.updateAccessGroups();
      }
    },
  },
  async created() {
    this.updateAccessGroups();
  },
  async mounted() {
    this.items = this.dashItems;
    this.items.forEach((item, index) => {
      if (this.identity.user.HomepageDashboard) {
        this.items[index].isDefault = (item.DashboardId === this.identity.user.HomepageDashboard);
      } else {
        this.items[index].isDefault = (index === 0);
      }
    });

    if (this.identity.user.HomepageDashboard) {
      this.model = {};
      const homeDash = this.items.find(
        (x) => x.DashboardId === this.identity.user.HomepageDashboard,
      );
      if (homeDash) {
        this.model.DashboardId = homeDash.DashboardId;
        this.model.Name = homeDash.Name;
        this.model.DisplayAccessGroupId = homeDash.DisplayAccessGroupId;
        this.model.DisplayAccessGroupName = homeDash.DisplayAccessGroupName;
      } else {
        this.model = this.dashItems && this.dashItems.length > 0 ? this.dashItems[0] : null;
      }
    } else if (this.selDashboard && this.selDashboard.dashboardId) {
      this.model = {};
      this.model.DashboardId = this.selDashboard.dashboardId;
      this.model.Name = this.selDashboard.name;
      this.model.DisplayAccessGroupId = this.selDashboard.displayAccessGroupId;
      this.model.DisplayAccessGroupName = this.selDashboard.displayAccessGroupName;
    } else {
      this.model = this.dashItems && this.dashItems.length > 0 ? this.dashItems[0] : null;
    }

    this.showHomeIcon = true;
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async onTenantHomedash() {
      await api.post('TenantHomepageUpdate', {
        DashboardId: this.selDashboard.dashboardId,
        TenantId: this.tenantId,
      })
        .catch((err) => {
          if (err.response.data.exception) {
            this.showError(err.response.data.exception);
          }
        });

      this.dialog = true;
    },
    async defineHomeboard() {
      await api.post('UserHomepageUpdate', {
        DashboardId: this.selDashboard.dashboardId,
        UserId: this.identity.user.UserId,
      })
        .catch((err) => {
          if (err.response.data.exception) {
            this.showError(err.response.data.exception);
          }
        });

      this.items = this.dashItems;
      this.items.forEach((item, index) => {
        this.items[index].isDefault = (item.DashboardId === this.selDashboard.dashboardId);
      });

      this.showHomeIcon = true;

      this.$auth.info.user.HomepageDashboard = this.selDashboard.dashboardId;
      this.$store.commit('user/identity', this.$auth.info);
    },
    onRenameClick() {
      this.NameForm.Model.Name = this.model.Name;
      this.NameForm.Show = true;
      this.NameForm.Schema.Name.label = this.$t('Dashboard.Menu.DashboardName');
    },
    onNameFormAccept() {
      this.model.Name = this.NameForm.Model.Name;
      this.$emit('on-update', this.model, this.NameForm.Model.Name);
    },
    selectDashboard() {
      this.showHomeIcon = this.model.isDefault;
      this.$emit('on-tab-select', this.model);
    },
    onAccessGroupClick() {
      this.AccessGroupForm.Show = true;
      this.AccessGroupForm.Schema.AccessGroup.label = this.$t('Dashboard.Menu.AccessGroupName');
      this.AccessGroupForm.Model.AccessGroup = this.accessGroupList
        .find((x) => x.AccessGroupId === this.model.DisplayAccessGroupId);

      this.AccessGroupForm.Schema.AccessGroup.items = [...this.accessGroupList];
      if (this.model.DisplayAccessGroupId) {
        this.AccessGroupForm.Schema.AccessGroup.items.unshift({ AccessGroupName: `(${this.$t('Remove')})`, AccessGroupId: null });
      }
    },
    onAccessGroupFormAccept() {
      this.model.DisplayAccessGroupId = this.AccessGroupForm.Model.AccessGroup.AccessGroupId;
      this.model.DisplayAccessGroupName = this.AccessGroupForm.Model.AccessGroup.AccessGroupName;
      this.$emit('on-update', this.model, this.model.Name);
    },
    updateAccessGroups() {
      if (this.identity) {
        if (this.accessGroupIds.length > 0) {
          this.accessGroupList = this.identity.listaccessgroupinfo
            .filter((x) => this.accessGroupIds.some((y) => x.AccessGroupId === y))
            .map((x) => ({ AccessGroupName: x.AccessGroupName, AccessGroupId: x.AccessGroupId }));
        } else if (this.tenantId) {
          this.accessGroupList = this.identity.listaccessgroupinfo
            .filter((x) => x.TenantId === this.tenantId)
            .map((x) => ({ AccessGroupName: x.AccessGroupName, AccessGroupId: x.AccessGroupId }));
        }
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-select {
  z-index: 99;
  .v-select__selections {
    max-height: 26px;
  }
  .v-input__control {
    min-height: 26px !important;
  }
}
.selected-dash-item {
  font-weight: 600;
  padding-right: 5px;
}

</style>
