<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="100%"
  >
    <v-card v-if="siteConfiguration">
      <v-form
        ref="form"
        lazy-validation
      >
        <p class="pt-5 pl-5">
          {{ site.Name }}
        </p>
        <p
          class="pl-5"
          style="font-size: 75%;"
        >
          {{ $t('WindServer.Sites.SiteConfigurations.PeriodBegin') }}
          {{ formattedStartDate }}
        </p>
        <v-tabs
          v-model="selectedTab"
          fixed-tabs
          style="border-width:0px;"
        >
          <v-tab key="equipment">
            <div>
              {{ $t('WindServer.Sites.SiteConfigurations.Tabs.Equipment') }}
            </div>
          </v-tab>
          <v-tab
            v-if="siteConfiguration.RemovedEquipment.length !== 0"
          >
            <slot>
              {{ $t('WindServer.Sites.SiteConfigurations.Tabs.RemovedEquipment') }}
            </slot>
          </v-tab>
          <v-tab
            v-for="item in siteConfiguration.Loggers"
            :key="item.Id"
            @click="setLogger(item.Id)"
          >
            LOGGER PE#{{ item.Id.padStart(5, '0') }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="selectedTab"
          style="width: 100%;"
        >
          <v-tab-item>
            <div class="d-flex">
              <div class="flex-grow-1">
                <v-card-title>
                  {{ $t('WindServer.Sites.SiteConfigurations.Tower') }}
                  <v-tooltip
                    v-if="siteConfiguration.Tower.length === 0"
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="showTowerModal = true;"
                      >
                        <i class="v-icon mdi mdi-plus" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('New') }} </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteTower"
                      >
                        <i class="v-icon mdi mdi-delete-forever" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('WindServer.Sites.SiteConfigurations.DeleteTower') }} </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <div style="height: 100px; width: 100%">
                      <ServerSideDataGrid
                        :grid-source="sources.Tower"
                        :row-data-init="siteConfiguration.Tower"
                        :pagination="false"
                      />
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-title>
                  {{ $t('WindServer.Sites.SiteConfigurations.Booms') }}
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="showBoomModal = true;"
                      >
                        <i class="v-icon mdi mdi-plus" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('New') }} </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteEquipment(1)"
                      >
                        <i class="v-icon mdi mdi-delete-forever" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('WindServer.Sites.SiteConfigurations.DeleteBooms') }} </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <div style="height: 200px; width: 100%">
                      <ServerSideDataGrid
                        ref="gridBooms"
                        :grid-source="sources.Booms"
                        :row-data-init="siteConfiguration.Booms"
                        enable-selection="multiple"
                        :pagination="false"
                      />
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-title>
                  {{ $t('WindServer.Sites.SiteConfigurations.Sensors') }}
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="showSensorModal = true;"
                      >
                        <i class="v-icon mdi mdi-plus" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('New') }} </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteEquipment(5)"
                      >
                        <i class="v-icon mdi mdi-delete-forever" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('WindServer.Sites.SiteConfigurations.DeleteSensors') }} </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <div style="height: 200px; width: 100%">
                      <ServerSideDataGrid
                        ref="gridSensors"
                        :grid-source="sources.Sensors"
                        :row-data-init="siteConfiguration.Sensors"
                        enable-selection="multiple"
                        :pagination="false"
                      />
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-title>
                  {{ $t('WindServer.Sites.SiteConfigurations.Modems') }}
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="showModemModal = true;"
                      >
                        <i class="v-icon mdi mdi-plus" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('New') }} </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteEquipment(2)"
                      >
                        <i class="v-icon mdi mdi-delete-forever" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('WindServer.Sites.SiteConfigurations.DeleteModems') }} </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <div style="height: 100px; width: 100%">
                      <ServerSideDataGrid
                        ref="gridModems"
                        :grid-source="sources.Modems"
                        :row-data-init="siteConfiguration.Modems"
                        enable-selection="multiple"
                        :pagination="false"
                      />
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-title>
                  {{ $t('WindServer.Sites.SiteConfigurations.Loggers') }}
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="showLoggerModal = true;"
                      >
                        <i class="v-icon mdi mdi-plus" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('New') }} </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteEquipment(4)"
                      >
                        <i class="v-icon mdi mdi-delete-forever" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('WindServer.Sites.SiteConfigurations.DeleteLoggers') }} </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <div style="height: 100px; width: 100%">
                      <ServerSideDataGrid
                        ref="gridLoggers"
                        :grid-source="sources.Loggers"
                        :row-data-init="siteConfiguration.Loggers"
                        enable-selection="multiple"
                        :pagination="false"
                      />
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-title>
                  {{ $t('WindServer.Sites.SiteConfigurations.Anchors') }}
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="showAnchorModal = true;"
                      >
                        <i class="v-icon mdi mdi-plus" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('New') }} </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteEquipment(7)"
                      >
                        <i class="v-icon mdi mdi-delete-forever" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('WindServer.Sites.SiteConfigurations.DeleteAnchors') }} </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <div style="height: 100px; width: 100%">
                      <ServerSideDataGrid
                        ref="gridAnchors"
                        :grid-source="sources.Anchors"
                        :row-data-init="siteConfiguration.Anchors"
                        enable-selection="multiple"
                        :pagination="false"
                      />
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-title>
                  {{ $t('WindServer.Sites.SiteConfigurations.Other') }}
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="showOtherModal = true;"
                      >
                        <i class="v-icon mdi mdi-plus" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('New') }} </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="float-right ml-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteEquipment(0)"
                      >
                        <i class="v-icon mdi mdi-delete-forever" />
                      </v-btn>
                    </template>
                    <span>  {{ $t('WindServer.Sites.SiteConfigurations.DeleteOther') }} </span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <div style="height: 200px; width: 100%">
                      <ServerSideDataGrid
                        ref="gridOther"
                        :grid-source="sources.Other"
                        :row-data-init="siteConfiguration.Other"
                        enable-selection="multiple"
                        :pagination="false"
                      />
                    </div>
                  </v-container>
                </v-card-text>
              </div>
              <ConfigurationDiagram
                class="flex-grow-0"
                style="width: 400px;"
              />
            </div>
          </v-tab-item>
          <v-tab-item
            v-if="siteConfiguration.RemovedEquipment.length !== 0"
          >
            <v-card-title>
              {{ $t('WindServer.Sites.SiteConfigurations.Tabs.RemovedEquipment') }}
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <div style="height: 60vh; width: 100%">
                  <ServerSideDataGrid
                    ref="gridRemoved"
                    :grid-source="sources.Removed"
                    :row-data-init="siteConfiguration.RemovedEquipment"
                    :pagination="false"
                  />
                </div>
              </v-container>
            </v-card-text>
          </v-tab-item>
          <v-tab-item
            v-for="item in siteConfiguration.Loggers"
            :key="item.Id"
          >
            <v-card flat>
              <LoggerConfigurationList :id="item.Id" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <TowerModalForm
      v-model="showTowerModal"
    />
    <BoomModalForm
      v-model="showBoomModal"
    />
    <SensorModalForm
      v-model="showSensorModal"
    />
    <ModemModalForm
      v-model="showModemModal"
    />
    <OtherModalForm
      v-model="showLoggerModal"
      :type="4"
    />
    <AnchorModalForm
      v-model="showAnchorModal"
    />
    <OtherModalForm
      v-model="showOtherModal"
      :type="0"
    />
    <DestinationModalForm
      v-model="showDestinationModal"
    />
    <DataImportFormModal />
    <ConfirmDialog
      v-model="showConfirm"
      :message="$t('WindServer.Sites.SiteConfigurations.ConfirmCloseFlags')"
      :title="$t('WindServer.Sites.SiteConfigurations.CloseFlags')"
      @on-accept="closeFlags"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import i18n from '@/plugins/i18n';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import TowerList from '../gridsources/gsTower';
import BoomsList from '../gridsources/gsBooms';
import SensorsList from '../gridsources/gsSensors';
import ModemsList from '../gridsources/gsModems';
import LoggersList from '../gridsources/gsLoggers';
import AnchorsList from '../gridsources/gsAnchors';
import OtherList from '../gridsources/gsOther';
import RemovedEquipmentList from '../gridsources/gsRemovedEquipment';
import TowerModalForm from './TowerModalForm.vue';
import BoomModalForm from './BoomModalForm.vue';
import SensorModalForm from './SensorModalForm.vue';
import ModemModalForm from './ModemModalForm.vue';
import OtherModalForm from './OtherModalForm.vue';
import AnchorModalForm from './AnchorModalForm.vue';
import DestinationModalForm from './DestinationModalForm.vue';
import LoggerConfigurationList from '../../loggerConfigurations/components/LoggerConfigurationList.vue';
import DataImportFormModal from '../../loggerConfigurations/components/DataImportFormModal.vue';
import ConfigurationDiagram from './diagrams/ConfigurationDiagram.vue';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

export default {
  components: {
    ServerSideDataGrid,
    ConfirmDialog,
    TowerModalForm,
    BoomModalForm,
    SensorModalForm,
    ModemModalForm,
    OtherModalForm,
    AnchorModalForm,
    DestinationModalForm,
    LoggerConfigurationList,
    DataImportFormModal,
    ConfigurationDiagram,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    startdate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
      sources: {
        Tower: null,
        Booms: null,
        Sensors: null,
        Modems: null,
        Loggers: null,
        Anchors: null,
        Other: null,
        Removed: null,
      },
      showTowerModal: false,
      showBoomModal: false,
      showSensorModal: false,
      showModemModal: false,
      showLoggerModal: false,
      showAnchorModal: false,
      showOtherModal: false,
      showDestinationModal: false,
      selectedTab: null,
      showConfirm: false,
    };
  },
  computed: {
    ...mapState('site/siteConfigurations', ['siteConfiguration', 'saveSucceeded', 'isDirty']),
    ...mapState('site/sites', ['site']),
    ...mapState('site/dataTrace', ['openFlags']),
    dateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    formattedStartDate() {
      return this.siteConfiguration ? format(this.siteConfiguration.StartingDate, this.dateTimeFormat) : '';
    },
  },
  watch: {
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  async created() {
    await this.$store.dispatch('site/siteConfigurations/getViewModel', {
      StartDate: this.startdate,
      TenantId: this.site.Owner,
    });
    await this.$store.dispatch('site/siteConfigurations/getEquipmentModel');
    await this.$store.dispatch('site/loggerConfigurations/init');
    await this.$store.dispatch('site/loggerConfigurations/setConfiguration', this.siteConfiguration);
    this.sources.Tower = new TowerList(this);
    this.sources.Booms = new BoomsList(this);
    this.sources.Sensors = new SensorsList(this);
    this.sources.Modems = new ModemsList(this);
    this.sources.Loggers = new LoggersList(this);
    this.sources.Anchors = new AnchorsList(this);
    this.sources.Other = new OtherList(this);
    this.sources.Removed = new RemovedEquipmentList(this);
    this.show = true;
  },
  methods: {
    preventNav(event) {
      if (!this.isDirty) return event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
      return '';
    },
    async navigateToList() {
      this.$router.push({ name: 'Sites.SiteConfigurations' });
    },

    async submit() {
      if (this.$route.name === 'Sites.SiteConfigurations.CreateModel') {
        await this.$store.dispatch('site/dataTrace/checkOpenFlags', {
          SiteId: this.siteConfiguration.SiteId,
          StartingDate: this.siteConfiguration.StartingDate,
        });
        if (this.openFlags) {
          this.showConfirm = true;
        } else {
          this.saveConfig();
        }
      } else {
        this.saveConfig();
      }
    },
    async saveConfig() {
      await this.$store.dispatch('site/siteConfigurations/setSiteConfiguration', this.siteConfiguration);
      await this.$store.dispatch('site/siteConfigurations/save');
      if (this.saveSucceeded) {
        this.navigateToList();
      }
    },
    closeFlags() {
      this.siteConfiguration.CloseFlags = true;
      this.saveConfig();
    },
    async editTower(payload) {
      await this.$store.dispatch('site/siteConfigurations/editEquipment', payload);
      this.showTowerModal = true;
    },
    async editBoom(payload) {
      await this.$store.dispatch('site/siteConfigurations/editEquipment', payload);
      this.showBoomModal = true;
    },
    async editAnchor(payload) {
      await this.$store.dispatch('site/siteConfigurations/editEquipment', payload);
      this.showAnchorModal = true;
    },
    async editLogger(payload) {
      await this.$store.dispatch('site/siteConfigurations/editEquipment', payload);
      this.showLoggerModal = true;
    },
    async editModem(payload) {
      await this.$store.dispatch('site/siteConfigurations/editEquipment', payload);
      this.showModemModal = true;
    },
    async editSensor(payload) {
      await this.$store.dispatch('site/siteConfigurations/editEquipment', payload);
      this.showSensorModal = true;
    },
    async editOther(payload) {
      await this.$store.dispatch('site/siteConfigurations/editEquipment', payload);
      this.showOtherModal = true;
    },
    async deleteTower() {
      if (this.siteConfiguration.Tower.length !== 0) {
        const selectedEquipment = [{
          EquipmentType: 6,
          Id: this.siteConfiguration.Tower[0].Id,
        }];
        await this.$store.dispatch('site/siteConfigurations/deleteEquipment', selectedEquipment);
        if (!this.siteConfiguration.Tower[0].IsNew) {
          this.showDestinationModal = true;
        } else {
          await this.$store.dispatch('site/siteConfigurations/delete');
        }
      }
    },
    async deleteEquipment(EquipmentType) {
      let selectedRows = [];
      switch (EquipmentType) {
        case 0: // Other
          if (this.$refs.gridOther.gridApi.getSelectedRows().length > 0) {
            selectedRows = this.$refs.gridOther.gridApi.getSelectedRows();
          }
          break;
        case 1: // Booms
          if (this.$refs.gridBooms.gridApi.getSelectedRows().length > 0) {
            selectedRows = this.$refs.gridBooms.gridApi.getSelectedRows();
          }
          break;
        case 2: // Modems
          if (this.$refs.gridModems.gridApi.getSelectedRows().length > 0) {
            selectedRows = this.$refs.gridModems.gridApi.getSelectedRows();
          }
          break;
        case 4: // Loggers
          if (this.$refs.gridLoggers.gridApi.getSelectedRows().length > 0) {
            selectedRows = this.$refs.gridLoggers.gridApi.getSelectedRows();
          }
          break;
        case 5: // Sensors
          if (this.$refs.gridSensors.gridApi.getSelectedRows().length > 0) {
            selectedRows = this.$refs.gridSensors.gridApi.getSelectedRows();
          }
          break;
        case 7: // Anchors
          if (this.$refs.gridAnchors.gridApi.getSelectedRows().length > 0) {
            selectedRows = this.$refs.gridAnchors.gridApi.getSelectedRows();
          }
          break;
        default:
          break;
      }
      if (selectedRows.length > 0) {
        await this.delete(selectedRows, EquipmentType);
      }
    },

    async delete(selectedRows, EquipmentType) {
      let show = false;
      const selectedEquipment = [];
      selectedRows.forEach((row) => {
        selectedEquipment.push({
          EquipmentType,
          Id: row.Id,
        });
        if (!row.IsNew && EquipmentType !== 7) {
          show = true;
        }
      });
      await this.$store.dispatch('site/siteConfigurations/deleteEquipment', selectedEquipment);
      if (show) {
        this.showDestinationModal = true;
      } else {
        await this.$store.dispatch('site/siteConfigurations/delete');
      }
    },
    setLogger(id) {
      const logger = this.siteConfiguration.Loggers.filter(
        (x) => x.Id === id,
      )[0];
      this.$store.dispatch('site/loggerConfigurations/setLogger', logger);
    },
  },
};
</script>

<style>
.redBackgroundCell
{
  background-color: crimson;
}
</style>
