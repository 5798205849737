<template>
  <span>
    {{ getI18NPropName(props.PropName) }}
    {{ $t('WindServer.Sites.SiteConfigurations.Details.Changed') }}
    {{ $t('WindServer.Sites.SiteConfigurations.Details.From') }}
    {{ props.oldValue }}
    {{ $t('WindServer.Sites.SiteConfigurations.Details.To') }}
    {{ props.newValue }};
  </span>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  props: {
    props: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    getI18NPropName(propName) {
      return i18n.t('WindServer.Sites.SiteConfigurations.Details.'.concat(propName));
    },
  },
};
</script>
