import dashboard from './stores/dashboard';
import dashboardPlaceholders from './stores/dashboardPlaceholders';
import dashboardWidget from './stores/dashboardWidget';
import instrument from './stores/instrument';
import mediaFeed from './stores/mediaFeed';
import subscribe from './stores/subscribe';
import map from './stores/map';
import visualisationSettingsCard from './stores/widget/visualisationSettingsCard';
import visualisationSettingsCrossPlotlyGraph from './stores/widget/visualisationSettingsCrossPlotlyGraph';
import visualisationSettingsPlotlyGraph from './stores/widget/visualisationSettingsPlotlyGraph';

export default {
  modules: {
    dashboard,
    dashboardPlaceholders,
    dashboardWidget,
    instrument,
    mediaFeed,
    subscribe,
    map,
    visualisationSettingsCard,
    visualisationSettingsCrossPlotlyGraph,
    visualisationSettingsPlotlyGraph,
  },
};
