/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsBooms extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.Site.ConfigurationPeriods.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 100,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.Site.ConfigurationPeriods.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.editBoom(event.data);
            },
          },
        },
        {
          filter: true,
          sortable: true,
          width: 100,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.PeNumber'),
          valueGetter: (params) => `${params.data.PeNumber ? String(params.data.PeNumber).padStart(5, '0') : 'Auto'}`,
        },
        {
          field: 'SerialNumber',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.SerialNumber'),
        },
        {
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.Model'),
          valueGetter: (params) => params.data.ManufacturerName.concat(' ').concat(params.data.ModelName),
        },
        {
          field: 'Height',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.Height'),
        },
        {
          field: 'Orientation',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.Orientation'),
        },
        {
          field: 'Heading',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.HeadingFromTower'),
        },
      ]);
    });
  }
}
