<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    max-width="1000"
    min-height="1100"
  >
    <v-card>
      <v-card-title class="pa-3">
        <p class="mb-0 mt-0">
          {{ `${$t('Dashboard.MediaWidget.FilesPhoto')}` }}
          {{ ` : ${feedInfos.date}` }}
        </p>
      </v-card-title>
      <div
        v-if="selectedImages.length == 0"
        style="height: 600px;"
      >
        <div class="loader" />
      </div>
      <section
        v-else
        style="overflow:hidden;"
      >
        <v-row no-gutters>
          <v-col cols="auto">
            <v-card-text class="pb-0">
              {{ `${$t('Dashboard.MediaWidget.Files')} :` }}
            </v-card-text>
          </v-col>
          <v-col cols="auto">
            <v-card-actions>
              <v-btn
                :disabled="!hasPrevious"
                text
                @click="getPreviousImages"
              >
                <v-icon>mdi-arrow-left</v-icon>
                {{ $t('Previous') }}
              </v-btn>
              <v-btn
                :disabled="!hasNext"
                text
                @click="getNextImages"
              >
                <v-icon>mdi-arrow-right</v-icon>
                {{ $t('Next') }}
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col
            cols="auto"
            class="mt-2 ml-3"
          >
            <v-select
              v-model="time"
              :items="options"
              item-text="name"
              dense
              return-object
              hide-details="true"
              @input="timestampSelected"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            v-for="image in selectedImages"
            :key="image.index"
          >
            <PictureFeedImage
              :infos="image"
              :widget-settings-data="widgetSettingsData"
              @blob="value => image.blob = value"
            />
          </v-col>
        </v-row>
      </section>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="$emit('back', ({date: feedInfos.date, index: selectedFirstIndex}))"
        >
          {{ $t('Back') }}
        </v-btn>
        <v-btn
          text
          @click="show=false"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PictureFeedImage from './PictureFeedImage.vue';

export default {
  components: {
    PictureFeedImage,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: true,
    },
    widgetSettingsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedImages: [],
      selectedLastIndex: -1,
      selectedFirstIndex: 0,
      options: [],
      time: null,
    };
  },
  computed: {
    ...mapState('mediaFeed', ['feedInfos', 'availableDates']),
    ...mapGetters('mediaFeed', ['timestampOptions']),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    hasPreviousToday() {
      return this.selectedFirstIndex > 0;
    },
    hasPrevious() {
      return this.hasPreviousToday || this.feedInfos.index > 0;
    },
    hasNextToday() {
      return this.selectedLastIndex + 1 < this.feedInfos.infos.length;
    },
    hasNext() {
      return this.hasNextToday || this.feedInfos.index + 1 < this.availableDates.length;
    },
    getLastIndexSelected() {
      return Math.min(this.selectedFirstIndex + 3, this.feedInfos.infos.length - 1);
    },
  },
  watch: {
    selectedItem: {
      async handler(newVal) {
        if (newVal && newVal.date) {
          this.selectedFirstIndex = newVal.index;
          await this.updateOptions();
          this.selectedLastIndex = this.getLastIndexSelected;
          this.updateSelectedImages();
        }
      },
      immediate: true,
    },
  },
  methods: {
    updateSelectedImages() {
      this.selectedImages = [];
      this.time = this.options[this.selectedFirstIndex];

      const list = [];
      for (let i = this.selectedFirstIndex; i <= this.selectedLastIndex; i += 1) {
        list.push(this.feedInfos.infos[i]);
      }
      this.selectedImages = list;
    },
    async getPreviousImages() {
      if (!this.hasPreviousToday) {
        await this.updateOptions(this.availableDates[this.feedInfos.index - 1]);
        this.selectedFirstIndex = this.feedInfos.infos.length;
      }

      this.selectedLastIndex = this.selectedFirstIndex - 1;
      this.selectedFirstIndex = Math.max(0, this.selectedFirstIndex - 4);
      this.updateSelectedImages();
    },
    async getNextImages() {
      if (!this.hasNextToday) {
        await this.updateOptions(this.availableDates[this.feedInfos.index + 1]);
        this.selectedLastIndex = -1;
      }

      this.selectedFirstIndex = this.selectedLastIndex + 1;
      this.selectedLastIndex = this.getLastIndexSelected;
      this.updateSelectedImages();
    },
    timestampSelected() {
      this.selectedFirstIndex = this.time.index;
      this.selectedLastIndex = this.getLastIndexSelected;
      this.updateSelectedImages();
    },
    async updateOptions(newDate) {
      if (newDate) {
        await this.$store.dispatch('mediaFeed/getFullInfosForDay', {
          date: newDate,
          widgetSettingsData: this.widgetSettingsData,
        });
      }

      this.options = this.timestampOptions(this.feedInfos.infos);
      this.time = this.options[this.selectedFirstIndex];
    },
  },
};
</script>
