/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import i18n from '@/plugins/i18n';
import importal from '@/api/importal';
import datehandling from '@/components/datehandling';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class SensorIngressSource extends ServerSideDataGridGsClientBase {
  constructor(vue, cb) {
    super();
    this.vue = vue;
    this.callback = cb;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'SensorId',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.SensorId'),
          width: 100,
          hide: this.vue.readmode === true,
          resizable: true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (`"${event.data.InputInstrumentName}.${event.data.InputSensorName}"`),
            clicked: (event) => {
              this.apiDevGatewayIngressSensorRemove(event.api, event.data);
            },
          },
        },
        {
          field: 'InputInstrumentName',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.InputInstrumentName'),
          width: 180,
          editable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'InputSensorName',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.InputSensorName'),
          width: 180,
          editable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'OutputInstrumentName',
          width: 180,
          headerName: i18n.t('Devices.Gateway.Edit_Headers.OutputInstrumentName'),
          sortable: true,
          filter: true,
        },
        {
          field: 'OutputSensorName',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.OutputSensorName'),
          width: 180,
          sortable: true,
          filter: true,
          hide: this.vue.readmode === true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.callback.EditOutputSensor(event.api, event.data);
            },
          },
        },
        {
          field: 'VendorUnitName',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.VendorUnitName'),
          filter: true,
          sortable: true,
        },
        {
          field: 'VendorSerialNumber',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.VendorSerialNumber'),
          filter: true,
          sortable: true,
        },
        // { field: 'DataUnit', width: 100 },
        // { field: 'DataType', width: 100 },
        {
          field: 'LastSeenTs',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.LastSeenTs'),
          width: 200,
          valueFormatter: (params) => datehandling.formatForDisplay(params.value),
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue, callback } = this;
    return {
      getRows: function GetRows(params) {
        importal.get(`DevGatewayIngressSensorList?DataGatewayId=${vue.$route.params.id}`)
          .then((resp) => {
            callback.cbDevGatewayIngressSensorList(resp.data);
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // Called when an editable column is changed
  // The function name must match the data Model
  // MAY call successCallback if the API call returns the table data
  onChange() {
    const { callback } = this;
    return {
      InputInstrumentName(event, successCallback, failCallback) {
        importal.post('DevGatewayIngressSensorInstrumentRename',
          {
            DataGatewayId: event.data.DataGatewayId,
            InstrumentName: event.oldValue,
            NewInstrumentName: event.newValue,
          })
          .then((resp) => {
            callback.cbDevGatewayIngressSensorList(resp.data);
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
      InputSensorName(event, successCallback, failCallback) {
        importal.post('DevGatewayIngressSensorRename',
          {
            DataGatewayId: event.data.DataGatewayId,
            InstrumentName: event.data.InputInstrumentName,
            SensorName: event.oldValue,
            NewSensorName: event.newValue,
          })
          .then((resp) => {
            callback.cbDevGatewayIngressSensorList(resp.data);
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    return new Promise((resolve) => {
      resolve({
        Model: {
          DataGatewayId: this.vue.$route.params.id,
          InstrumentName: null,
          SensorName: null,
        },
        Schema: {
          DataGatewayId: { label: i18n.t('Devices.Gateway.AddNew.GatewayId'), type: 'text', disabled: true },
          InstrumentName: {
            label: i18n.t('Devices.Gateway.AddNew.InstrumentName'), type: 'text', flex: 12,
          },
          SensorName: {
            label: i18n.t('Devices.Gateway.AddNew.SensorName'), type: 'text', flex: 12,
          },
        },
      });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    const { callback } = this;
    importal
      .post('DevGatewayIngressSensorNew', {
        DataGatewayId: data.DataGatewayId,
        InstrumentName: data.InstrumentName,
        SensorName: data.SensorName,
      })
      .then((resp) => {
        callback.cbDevGatewayIngressSensorList(resp.data);
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async apiDevGatewayIngressSensorRemove(api, data) {
    const { callback } = this;
    return importal
      .post('DevGatewayIngressSensorRemove', {
        DataGatewayId: data.DataGatewayId,
        InstrumentName: data.InputInstrumentName,
        SensorName: data.InputSensorName,
      })
      .then((resp) => {
        callback.cbDevGatewayIngressSensorList(resp.data);
        api.setRowData(resp.data);
      });
  }
}
