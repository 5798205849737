export default [
  {
    isWindServer: true,
    label: 'Customers.Sites.Title',
    icon: 'mdi mdi-windsock',
    to: { name: 'Customers.Sites' },
    title: 'Customers.Sites.PageTitle',
    isVisible: (vue) => vue.$auth.roles.includes('Web.CustomerSite.View') || vue.$auth.roles.includes('Web.CustomerSite.ViewAll'),
  },
];
