export default [
  {
    isWindServer: false,
    label: 'Worker.Title',
    icon: 'mdi-account-multiple',
    title: 'Worker.Title',
    items: [
      {
        label: 'Worker.Workers.Title',
        to: { name: 'Worker.Workers.List' },
        title: 'Worker.Workers.Title',
      },
      {
        label: 'Worker.Modem.Title',
        to: { name: 'Worker.Modem.List' },
        title: 'Worker.Modem.Title',
      },
    ],
  },
];
