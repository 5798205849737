/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsInstrumentAttributes extends ServerSideDataGridGsClientBase {
  constructor(vue, cb) {
    super();
    this.vue = vue;
    this.callback = cb;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    const { vue } = this;

    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: 'Edit',
          hide: this.vue.logger.PeNumber != null,
          width: 80,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            confirm: (event) => (`Attribute "${event.data.Name}"`),
            clicked: (event) => {
              this.EditInstrumentAttribute(event.data);
            },
          },
        },
        {
          field: '',
          headerName: 'Add',
          hide: this.vue.logger.PeNumber != null,
          width: 80,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            icon: 'mdi-plus',
            confirm: (event) => (`Attribute "${event.data.Name}"`),
            clicked: (event) => {
              this.CloneInstrumentAttribute(event.data);
            },
          },
        },
        {
          field: 'InstrumentAttributeId',
          headerName: i18n.t('Instruments.Id'),
          width: 80,
          resizable: false,
        },
        {
          field: 'Name',
          width: 200,
          filter: true,
          sortable: true,
        },
        {
          field: 'ValueStr',
          width: 260,
          filter: true,
          sortable: true,
        },
        {
          field: 'ValueFloat',
          width: 120,
          filter: true,
          sortable: true,
        },
        {
          field: 'ValueInt',
          width: 120,
          filter: true,
          sortable: true,
        },
        {
          field: 'ValidFromTs',
          width: 180,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
        },
        {
          field: 'ValidToTs',
          width: 180,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
        },
        {
          headerName: vue.$auth.roles.includes('Web.Sensor.Create') ? 'Remove' : 'Id',
          field: 'InstrumentAttributeId',
          resizable: true,
          hide: this.vue.logger.PeNumber != null,
          width: 120,
          cellRenderer: vue.$auth.roles.includes('Web.Sensor.Create') ? 'DeleteButtonIdCellRenderer' : null,
          cellRendererParams: {
            confirm: (event) => (`Attribute "${event.data.Name}"`),
            clicked: (event) => {
              this.apiDevInstrumentAttributeRemove(event.api, event.data);
            },
          },
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue } = this;
    return {
      getRows: function GetRows(params) {
        importal.get(`DevInstrumentAttributeList?InstrumentId=${vue.$route.params.id}`)
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // Called when an editable column is changed
  // The function name must match the data Model
  // MAY call successCallback if the API call returns the table data
  onChange() {
    return {
      Name(event, successCallback, failCallback) {
        importal
          .post('DevSensorRename', {
            SensorId: event.data.SensorId,
            Name: event.data.Name,
          })
          .then((resp) => {
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve({
        Model: {
          InstrumentId: Number(vue.$route.params.id),
          Name: null,
          Value: null,
          StartDate: null,
        },
        Schema: {
          InstrumentId: {
            label: 'Instrument Id',
            type: 'text',
            disabled: true,
          },
          Name: {
            label: 'Name', type: 'text', flex: 12,
          },
          Value: {
            label: 'Value', type: 'text', flex: 12,
          },
          StartDate: {
            label: 'Start Date', type: 'DateTimePickerForm', flex: 12,
          },
        },
      });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    const { ValueFloat, ValueInt, ValueStr } = this.ParseAttributeValues(data.Value);
    importal
      .post('DevInstrumentAttributeNew', {
        InstrumentId: data.InstrumentId,
        Name: data.Name,
        ValueFloat,
        ValueInt,
        ValueStr,
        StartDate: data.StartDate,
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async apiDevInstrumentAttributeRemove(api, data) {
    const resp = await importal
      .post('DevInstrumentAttributeRemove ', {
        InstrumentAttributeId: data.InstrumentAttributeId,
      });
    api.setRowData(resp.data);
  }

  EditInstrumentAttribute(data) {
    this.callback.onEdit(data);
  }

  CloneInstrumentAttribute(data) {
    this.callback.onClone(data);
  }

  ParseAttributeValues(valuestr) {
    let parsedInt = parseInt(valuestr, 10);
    let parsedFloat = parseFloat(valuestr);
    if (!Number.isNaN(parsedFloat)) {
      if (Number.isNaN(parsedInt)) {
        parsedInt = null; // Float-only
      }
    } else if (!Number.isNaN(parsedInt)) {
      parsedFloat = parsedInt;
    } else {
      parsedInt = null;
      parsedFloat = null;
    }
    return {
      ValueFloat: parsedFloat,
      ValueInt: parsedInt,
      ValueStr: valuestr,
    };
  }
}
