import Vue from 'vue';
import * as Msal from '@azure/msal-browser';
import api from '@/api/importal';

let instance;

export const getInstance = () => instance;

export const useMSAL = (options) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        msalInstance: null,
        isAuthenticated: false,
        user: undefined,
        error: null,
        info: null,
      };
    },
    computed: {
      roles: function roles() {
        if (this.info && this.info.listrolename.length) {
          return this.info.listrolename;
        }
        return [];
      },
    },
    async created() {
      function loggerCallback(logLevel, message) {
        this.error = message;
      }

      const msalConfig = {
        auth: {
          clientId: options.clientId,
          authority: options.authority,
          knownAuthorities: [options.authority],
          redirectUri: options.redirectUri,
          postLogoutRedirectUri: options.postLogoutRedirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
        system: {
          logger: {
            loggerCallback,
            piiLoggingEnabled: true,
            logLevel: Msal.LogLevel.Verbose,
          },
        },
      };

      this.msalInstance = new Msal.PublicClientApplication(
        msalConfig,
      );

      await this.msalInstance.handleRedirectPromise().then((loginResponse) => {
        if (loginResponse) {
          this.user = loginResponse.account;
          this.msalInstance.setActiveAccount(loginResponse.account);
          api.get('/UserGetInfo').then((infoResponse) => {
            this.info = infoResponse.data;
            this.isAuthenticated = true;
          });
        } else {
          const accounts = this.msalInstance.getAllAccounts();
          if (accounts.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.user = accounts[0];
            this.msalInstance.setActiveAccount(accounts[0]);
            api.get('/UserGetInfo').then((infoResponse) => {
              this.info = infoResponse.data;
              this.isAuthenticated = true;
            });
          } else {
            this.isAuthenticated = false;
            this.loginWithRedirect();
          }
        }
      }).catch((error) => {
        this.error = error;
        this.isAuthenticated = false;
        if (String(error).indexOf('AADB2C90118') >= 0) {
          this.msalInstance.loginRedirect({
            authority: options.passwordReset,
          });
        } else if (String(error).indexOf('AADB2C90088') >= 0) {
          this.msalInstance.logoutRedirect();
        } else {
          this.loginWithRedirect();
        }
      });
    },
    methods: {
      loginWithRedirect() {
        const request = {
          prompt: 'select_account',
          scopes: [options.userScope],
        };
        return this.msalInstance.loginRedirect(request);
      },
      logoutWithRedirect() {
        return this.msalInstance.logoutRedirect();
      },
      login() {
        this.msalInstance.loginPopup()
          .then((loginResponse) => {
            this.user = loginResponse.account;
            api.get('/UserGetInfo').then((infoResponse) => {
              this.info = infoResponse.data;
              this.isAuthenticated = true;
            });
          }).catch((error) => {
            this.error = error;
          });
      },
      logout() {
        return this.msalInstance.logoutPopup();
      },
      async acquireTokenSilent() {
        const loginRequest = {
          scopes: [options.userScope],
        };

        return this.msalInstance.acquireTokenSilent(loginRequest).then((tokenResponse) => {
          // Do something with the tokenResponse
          this.user = tokenResponse.account;
          return tokenResponse;
        }).catch(async (error) => {
          if (error instanceof Msal.InteractionRequiredAuthError) {
            this.isAuthenticated = false;
            this.loginWithRedirect();
          }
          return null;
        }).catch((error) => {
          this.isAuthenticated = false;
          this.error = error;
        });
      },
      async GetToken() {
        return this.acquireTokenSilent();
      },
      async acquireApiTokenSilent() {
        const loginRequest = {
          scopes: [options.apiScope],
        };

        return this.msalInstance.acquireTokenSilent(loginRequest).then((tokenResponse) => {
          // Do something with the tokenResponse
          this.user = tokenResponse.account;
          return tokenResponse;
        }).catch(async (error) => {
          if (error instanceof Msal.InteractionRequiredAuthError) {
            this.isAuthenticated = false;
            this.loginWithRedirect();
          }
          return null;
        }).catch((error) => {
          this.isAuthenticated = false;
          this.error = error;
        });
      },
      async GetApiToken() {
        return this.acquireApiTokenSilent();
      },
    },
  });

  return instance;
};

export default {
  // eslint-disable-next-line no-shadow
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$auth = useMSAL(options);
  },
};
