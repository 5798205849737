import i18n from '@/plugins/i18n';
import api from '@/api/importal';
import generateColumns from './generateColumns';

const state = {
  logger: null,
  loggers: [],
  channel: null,
  peNumber: null,
  dataFileImport: null,
  codeAlreadyExists: null,
  unitsOfMeasure: [],
  statisticTypes: [],
  propertyTypes: [],
  timeStamps: [],
  fileTypes: [],
  columnTypes: [],
  propertyFunctionTypes: [],
  channelNumber: null,
  dataFileNumber: null,
  siteConfiguration: null,
  defaultDataFileImport: null,
  showDataImportModal: false,
  timeAdjustment: null,
  textReplacement: null,
  dataImportConfig: null,
  channels: [],
  heights: [],
  height: null,
  measurementPoints: [],
  measurementPoint: null,
  emailImportRule: null,
  symDRVersions: [],
};

const actions = {
  async init({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const units = await api.get('UnitsOfMeasureList');
    const unitsTranslated = [];
    units.data.forEach((unit) => {
      let name = unit.Name;
      if (name.indexOf('*') >= 0) {
        name = name.substring(0, name.indexOf('*') + 1);
      }
      unitsTranslated.push({
        UnitOfMeasureId: unit.UnitOfMeasureId,
        Name: unit.Name,
        NameI18n: i18n.t('WindServer.Sites.LoggerConfiguration.UnitsOfMeasure.'.concat(name)),
      });
    });
    commit('unitsOfMeasure', unitsTranslated);

    const statisticTypes = await api.get('LoggerStatisticTypeList');
    const statisticTypesTranslated = [];
    statisticTypes.data.forEach((type) => {
      statisticTypesTranslated.push({
        StatisticTypeId: type.StatisticTypeId,
        Name: i18n.t('WindServer.Sites.LoggerConfiguration.StatisticTypes.'.concat(type.Code)),
        Code: type.Code,
      });
    });
    commit('statisticTypes', statisticTypesTranslated);

    const propertyTypes = await api.get('LoggerPropertyTypeList');
    const propertyTypesTranslated = [];
    propertyTypes.data.forEach((type) => {
      propertyTypesTranslated.push({
        PropertyTypeId: type.PropertyTypeId,
        Name: i18n.t('WindServer.Sites.LoggerConfiguration.PropertyTypes.'.concat(type.Code)),
        Code: type.Code,
      });
    });
    commit('propertyTypes', propertyTypesTranslated);

    const timeStamps = await api.get('LoggerTimeStampList');
    const timeStampsTranslated = [];
    timeStamps.data.forEach((timeStamp) => {
      timeStampsTranslated.push({
        TimeStampId: timeStamp.TimeStampId,
        Name: i18n.t('WindServer.Sites.LoggerConfiguration.TimeStamps.'.concat(timeStamp.Name)),
      });
    });
    commit('timeStamps', timeStampsTranslated);

    const fileTypes = await api.get('LoggerFileTypeList');
    const fileTypesTranslated = [];
    fileTypes.data.forEach((type) => {
      fileTypesTranslated.push({
        FileTypeId: type.FileTypeId,
        Name: i18n.t('WindServer.Sites.LoggerConfiguration.FileTypes.'.concat(type.Name)),
      });
    });
    commit('fileTypes', fileTypesTranslated);

    const columnTypes = await api.get('LoggerColumnTypeList');
    const columnTypesTranslated = [];
    columnTypes.data.forEach((type) => {
      columnTypesTranslated.push({
        ColumnTypeId: type.ColumnTypeId,
        Name: type.Name,
        NameI18n: i18n.t('WindServer.Sites.LoggerConfiguration.ColumnTypes.'.concat(type.Name)),

      });
    });
    commit('columnTypes', columnTypesTranslated);

    const propertyFunctionTypes = await api.get('LoggerPropertyFunctionTypeList');
    const propertyFunctionTypesTranslated = [];
    propertyFunctionTypes.data.forEach((type) => {
      propertyFunctionTypesTranslated.push({
        PropertyFunction: type.PropertyFunctionId,
        Name: i18n.t('WindServer.Sites.LoggerConfiguration.PropertyFunctionTypes.'.concat(type.Name)),
      });
    });
    commit('propertyFunctionTypes', propertyFunctionTypesTranslated);

    const symDRVersions = await api.get('SymDRVersionList');
    commit('symDRVersions', symDRVersions.data);

    commit('init');
    commit('app/pleaseWait', false, { root: true });
  },

  async createChannel({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const unitOfMeasure = state.unitsOfMeasure.filter(
      (x) => x.UnitOfMeasureId === payload.UnitOfMeasureId,
    )[0];
    state.logger.Channels.push({
      LoggerPeNumber: !state.logger.IsNew ? Number(state.peNumber) : null,
      LoggerId: state.peNumber,
      AveragingInterval: payload.AveragingInterval,
      ChannelCalibrationId: state.channelNumber - 1,
      ChannelCode: payload.ChannelCode,
      Label: payload.Label,
      ChannelOffset: payload.ChannelOffset,
      ChannelSlope: payload.ChannelSlope,
      DistanceToTower: payload.DistanceToTower,
      HeadingFromTower: payload.HeadingFromTower,
      Height: payload.Height,
      PeNumber: payload.PeNumber,
      SensorId: payload.SensorId,
      SensorMountId: payload.SensorMountId,
      Sensor: payload.SensorMountId || payload.SensorId ? payload.Sensor : null,
      UnitOfMeasureId: payload.UnitOfMeasureId,
      UnitOfMeasure: unitOfMeasure.Name,
      IsNew: true,
    });
    commit('channelNumber', state.channelNumber - 1);
    commit('editLogger');
    commit('app/pleaseWait', false, { root: true });
  },

  async getChannel({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('channel', state.logger.Channels.filter(
      (x) => x.ChannelCalibrationId === payload.channelCalibrationId,
    ));
    commit('app/pleaseWait', false, { root: true });
  },

  async updateChannel({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const idx = state.logger.Channels.findIndex(
      (b) => b.ChannelCalibrationId === payload.ChannelCalibrationId,
    );
    const unitOfMeasure = state.unitsOfMeasure.filter(
      (x) => x.UnitOfMeasureId === payload.UnitOfMeasureId,
    )[0];
    state.logger.Channels.splice(idx, 1, {
      LoggerPeNumber: !state.logger.IsNew ? Number(state.peNumber) : null,
      LoggerId: state.peNumber,
      ChannelCalibrationId: payload.ChannelCalibrationId,
      AveragingInterval: payload.AveragingInterval,
      ChannelCode: payload.ChannelCode,
      Label: payload.Label,
      ChannelOffset: payload.ChannelOffset,
      ChannelSlope: payload.ChannelSlope,
      DistanceToTower: payload.DistanceToTower,
      HeadingFromTower: payload.HeadingFromTower,
      Height: payload.Height,
      PeNumber: payload.PeNumber,
      SensorId: payload.SensorId,
      SensorMountId: payload.SensorMountId,
      Sensor: payload.SensorMountId || payload.SensorId ? payload.Sensor : null,
      UnitOfMeasureId: payload.UnitOfMeasureId,
      UnitOfMeasure: unitOfMeasure.Name,
      IsNew: payload.IsNew,
      IsDirty: payload.IsNew ? false : payload.IsDirty,
    });
    commit('editLogger');
    commit('app/pleaseWait', false, { root: true });
  },

  async deleteChannels({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    payload.forEach((x) => {
      const idx = state.logger.Channels.findIndex(
        (y) => y.ChannelCalibrationId === x.ChannelCalibrationId,
      );
      if (x.ChannelCalibrationId > 0) {
        state.logger.DeleteCalibrationIds.push(x.ChannelCalibrationId);
      }
      state.logger.DataFileImportSettings.forEach((dataFile) => {
        if (dataFile.DataImportConfigs) {
          dataFile.DataImportConfigs.forEach((col) => {
            if (col.LoggerChannel
              && col.LoggerChannel.ChannelCalibration.ChannelCalibrationId
              === x.ChannelCalibrationId) {
              const c = col;
              c.LoggerChannel = null;
              c.DistanceToTower = null;
              c.HeadingFromTower = null;
              c.Height = null;
              c.AveragingInterval = null;
              const i = dataFile.DataImportConfigs.findIndex(
                (y) => y.DataImportConfigurationId === col.DataImportConfigurationId,
              );
              dataFile.DataImportConfigs.splice(i, 1, c);
            }
          });
        }
      });

      state.logger.Channels.splice(idx, 1);
    });
    commit('editLogger');
    commit('app/pleaseWait', false, { root: true });
  },

  channel({ commit }, payload) {
    commit('channel', payload);
  },

  async setConfiguration({ commit }, payload) {
    commit('siteConfiguration', payload);
    commit('loggers', payload.Loggers);
  },

  async setLogger({ commit }, payload) {
    commit('logger', payload);
    commit('peNumber', payload.Id);
  },

  async getDataFileImport({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.dataFileImportSettingId,
    )[0];
    commit('dataFileImport', data);
    commit('app/pleaseWait', false, { root: true });
  },
  async createDataFileImport({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    state.logger.DataFileImportSettings.push({
      DataFileImportSettingId: state.dataFileNumber - 1,
      StartingDate: payload.StartingDate,
      EndingDate: payload.EndingDate,
      PeNumber: state.logger.PeNumber,
      Details: payload.Details,
      TimeStampId: payload.TimeStampId,
      FileTypeId: payload.FileTypeId,
      DateTimeDataFormat: payload.DateTimeDataFormat,
      Delimiter: payload.Delimiter,
      SkipLines: payload.SkipLines,
      LinesPerRecord: payload.LinesPerRecord,
      IntervalLength: payload.IntervalLength,
      RowPattern: payload.RowPattern,
      MissingDataValues: payload.MissingDataValues,
      FileTag: payload.FileTag,
      TimeAdjustments: [],
      TextReplacements: [],
      DataImportConfigs: [],
      EmailImportRules: [],
      IsNew: true,
    });
    commit('dataFileNumber', state.dataFileNumber - 1);
    commit('editLogger');
    commit('app/pleaseWait', false, { root: true });
  },

  async updateDataFileImport({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const idx = state.logger.DataFileImportSettings.findIndex(
      (b) => b.DataFileImportSettingId === payload.DataFileImportSettingId,
    );
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
    )[0];
    state.logger.DataFileImportSettings.splice(idx, 1, {
      DataFileImportSettingId: payload.DataFileImportSettingId,
      DataGatewayId: payload.DataGatewayId,
      StartingDate: payload.StartingDate,
      EndingDate: payload.EndingDate,
      PeNumber: state.logger.PeNumber,
      Details: payload.Details,
      TimeStampId: payload.TimeStampId,
      DateTimeDataFormat: payload.DateTimeDataFormat,
      FileTypeId: payload.FileTypeId,
      Delimiter: payload.Delimiter,
      SkipLines: payload.SkipLines,
      LinesPerRecord: payload.LinesPerRecord,
      IntervalLength: payload.IntervalLength,
      RowPattern: payload.RowPattern,
      MissingDataValues: payload.MissingDataValues,
      FileTag: payload.FileTag,
      TimeAdjustments: data.TimeAdjustments,
      TextReplacements: data.TextReplacements,
      DataImportConfigs: data.DataImportConfigs,
      EmailImportRules: data.EmailImportRules,
      IsNew: payload.IsNew,
      IsDirty: payload.IsNew ? false : payload.IsDirty,
    });
    commit('editLogger');
    commit('app/pleaseWait', false, { root: true });
  },

  async removeDataFileImport({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const idx = state.logger.DataFileImportSettings.findIndex((x) => x.DataFileImportSettingId
      === payload.id);
    const dataFile = state.logger.DataFileImportSettings[idx];
    state.logger.DataFileImportSettings.splice(idx, 1);
    state.logger.IsDirty = !state.logger.IsNew;
    if (!dataFile.IsNew) {
      if (!state.logger.DeleteDataFiles) {
        state.logger.DeleteDataFiles = [];
      }
      state.logger.DeleteDataFiles.push(payload.id);
    }
    commit('app/pleaseWait', false, { root: true });
  },

  async endDataFileImport({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const idx = state.logger.DataFileImportSettings.findIndex((x) => x.DataFileImportSettingId
      === payload.id);
    const dataFile = state.logger.DataFileImportSettings[idx];
    state.logger.DataFileImportSettings.splice(idx, 1);
    state.logger.IsDirty = !state.logger.IsNew;
    if (!dataFile.IsNew) {
      if (!state.logger.EndDataFiles) {
        state.logger.EndDataFiles = [];
      }
      state.logger.EndDataFiles.push(payload.id);
    }
    commit('app/pleaseWait', false, { root: true });
  },

  async replaceDataFileImport({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const idx = state.logger.DataFileImportSettings.findIndex((x) => x.DataFileImportSettingId
      === payload.id);
    const dataFile = state.logger.DataFileImportSettings[idx];
    state.logger.DataFileImportSettings.splice(idx, 1);
    state.logger.IsDirty = !state.logger.IsNew;
    if (!dataFile.IsNew) {
      if (!state.logger.ReplaceDataFiles) {
        state.logger.ReplaceDataFiles = [];
      }
      state.logger.ReplaceDataFiles.push(payload.id);
    }
    commit('app/pleaseWait', false, { root: true });
  },

  dataFileImport({ commit }, payload) {
    commit('dataFileImport', payload);
    commit('showDataImportModal', true);
  },

  async getDefaultDataFileImport({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    commit('dataFileImportSetting');
    commit('app/pleaseWait', false, { root: true });
  },
  addData({ commit }) {
    commit('showDataImportModal', true);
  },
  closeData({ commit }) {
    commit('showDataImportModal', false);
  },

  async createTimeAdjustment({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
    )[0];
    state.logger.IsDirty = !state.logger.IsNew;
    data.IsDirty = !data.IsNew;
    const t = payload;
    t.TimeAdjustmentId = state.dataFileNumber - 1;
    t.IsNew = true;
    data.TimeAdjustments.push(t);
    commit('dataFileNumber', state.dataFileNumber - 1);
    commit('app/pleaseWait', false, { root: true });
  },

  async updateTimeAdjustment({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
    )[0];
    state.logger.IsDirty = !state.logger.IsNew;
    data.IsDirty = !data.IsNew;
    const t = payload;
    t.IsDirty = !t.IsNew;
    const idx = data.TimeAdjustments.findIndex(
      (x) => x.TimeAdjustmentId === t.TimeAdjustmentId,
    );
    data.TimeAdjustments.splice(idx, 1, t);
    commit('app/pleaseWait', false, { root: true });
  },

  async timeAdjustment({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('timeAdjustment', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async deleteTimeAdjustment({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    payload.forEach((x) => {
      const data = state.logger.DataFileImportSettings.filter(
        (y) => y.DataFileImportSettingId === x.DataFileImportSettingId,
      )[0];
      state.logger.IsDirty = !state.logger.IsNew;
      data.IsDirty = !data.IsNew;
      const idx = data.TimeAdjustments.findIndex(
        (y) => y.TimeAdjustmentId === x.TimeAdjustmentId,
      );
      const t = data.TimeAdjustments[idx];
      if (!t.IsNew) {
        if (!data.DeleteTimeAdjustments) {
          data.DeleteTimeAdjustments = [];
        }
        data.DeleteTimeAdjustments.push(t.TimeAdjustmentId);
      }
      data.TimeAdjustments.splice(idx, 1);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async createTextReplacement({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
    )[0];
    state.logger.IsDirty = !state.logger.IsNew;
    data.IsDirty = !data.IsNew;
    const t = payload;
    t.TextReplacementId = state.dataFileNumber - 1;
    t.IsNew = true;
    data.TextReplacements.push(t);
    commit('dataFileNumber', state.dataFileNumber - 1);
    commit('app/pleaseWait', false, { root: true });
  },

  async updateTextReplacement({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
    )[0];
    state.logger.IsDirty = !state.logger.IsNew;
    data.IsDirty = !data.IsNew;
    const t = payload;
    t.IsDirty = !t.IsNew;
    const idx = data.TextReplacements.findIndex(
      (x) => x.TextReplacementId === t.TextReplacementId,
    );
    data.TextReplacements.splice(idx, 1, t);
    commit('app/pleaseWait', false, { root: true });
  },

  async textReplacement({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('textReplacement', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async deleteTextReplacement({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    payload.forEach((x) => {
      const data = state.logger.DataFileImportSettings.filter(
        (y) => y.DataFileImportSettingId === x.DataFileImportSettingId,
      )[0];
      state.logger.IsDirty = !state.logger.IsNew;
      data.IsDirty = !data.IsNew;
      const idx = data.TextReplacements.findIndex(
        (y) => y.TextReplacementId === x.TextReplacementId,
      );
      const t = data.TextReplacements[idx];
      if (!t.IsNew) {
        if (!data.DeleteTimeAdjustments) {
          data.DeleteTimeAdjustments = [];
        }
        data.DeleteTextReplacements.push(t.TextReplacementId);
      }
      data.TextReplacements.splice(idx, 1);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async createDataImportConfig({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
    )[0];
    state.logger.IsDirty = !state.logger.IsNew;
    data.IsDirty = !data.IsNew;
    const t = payload;
    t.DataImportConfigurationId = state.dataFileNumber - 1;
    t.IsNew = true;
    data.DataImportConfigs.push(t);
    commit('dataFileNumber', state.dataFileNumber - 1);
    commit('app/pleaseWait', false, { root: true });
  },

  async updateDataImportConfig({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
    )[0];
    state.logger.IsDirty = !state.logger.IsNew;
    data.IsDirty = !data.IsNew;
    const t = payload;
    t.IsDirty = !t.IsNew;
    const idx = data.DataImportConfigs.findIndex(
      (x) => x.DataImportConfigurationId === payload.DataImportConfigurationId,
    );
    data.DataImportConfigs.splice(idx, 1, t);
    commit('app/pleaseWait', false, { root: true });
  },

  async dataImportConfig({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('dataImportConfig', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async deleteDataImportConfig({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    payload.forEach((x) => {
      const data = state.logger.DataFileImportSettings.filter(
        (y) => y.DataFileImportSettingId === x.DataFileImportSettingId,
      )[0];
      state.logger.IsDirty = !state.logger.IsNew;
      data.IsDirty = !data.IsNew;
      const idx = data.DataImportConfigs.findIndex(
        (y) => y.DataImportConfigurationId === x.DataImportConfigurationId,
      );
      const d = data.DataImportConfigs[idx];
      if (!d.IsNew) {
        if (!data.DeleteImportConfigs) {
          data.DeleteImportConfigs = [];
        }
        data.DeleteImportConfigs.push(d.DataImportConfigurationId);
      }
      data.DataImportConfigs.splice(idx, 1);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async createHeight({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const t = payload;
    t.HeightId = state.dataFileNumber - 1;
    state.heights.push(t);
    commit('dataFileNumber', state.dataFileNumber - 1);
    commit('app/pleaseWait', false, { root: true });
  },

  async updateHeight({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const idx = state.heights.findIndex(
      (x) => x.HeightId === payload.HeightId,
    );
    state.heights.splice(idx, 1, payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async height({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('height', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async deleteHeight({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    payload.forEach((x) => {
      const idx = state.heights.findIndex(
        (y) => y.HeightId === x.HeightId,
      );
      state.heights.splice(idx, 1);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async createMeasurementPoint({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const t = payload;
    t.MeasurementPointId = state.dataFileNumber - 1;
    state.measurementPoints.push(t);
    commit('dataFileNumber', state.dataFileNumber - 1);
    commit('app/pleaseWait', false, { root: true });
  },

  async updateMeasurementPoint({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const idx = state.measurementPoints.findIndex(
      (x) => x.MeasurementPointId === payload.MeasurementPointId,
    );
    state.measurementPoints.splice(idx, 1, payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async measurementPoint({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('measurementPoint', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async deleteMeasurementPoints({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    payload.forEach((x) => {
      const idx = state.measurementPoints.findIndex(
        (y) => y.MeasurementPointId === x.MeasurementPointId,
      );
      state.measurementPoints.splice(idx, 1);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async channels({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('channels', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async cleanGenerateColumns({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    commit('cleanGenerateColumns');
    commit('app/pleaseWait', false, { root: true });
  },

  async generateColumns({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('generateColumns', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async buildAutoConfig({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const bodyFormData = new FormData();
    bodyFormData.append('channelNumber', state.channelNumber);
    bodyFormData.append('dataFileNumber', state.dataFileNumber);
    bodyFormData.append('file_format', payload.SelectedFileParser);
    payload.files.forEach((f) => {
      bodyFormData.append('files[]', f);
    });

    await api.post('SiteConfigBuildAutoConfig', bodyFormData)
      .then((resp) => {
        commit('channelNumber', resp.data.channelId - 1);
        commit('dataFileNumber', resp.data.dataFileId - 1);
        const data = state.logger.DataFileImportSettings.filter(
          (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
        )[0];
        generateColumns.generateByFile(
          payload.DataFileImportSettingId, data, resp.data, state.logger,
        );
        state.logger.IsDirty = !state.logger.IsNew;
        data.IsDirty = !data.IsNew;
        commit('app/pleaseWait', false, { root: true });
      })
      .catch(() => {
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async createEmailImportRule({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
    )[0];
    state.logger.IsDirty = !state.logger.IsNew;
    data.IsDirty = !data.IsNew;
    const m = payload;
    m.EmailImportRuleId = state.dataFileNumber - 1;
    m.IsNew = true;
    data.EmailImportRules.push(m);
    commit('dataFileNumber', state.dataFileNumber - 1);
    commit('app/pleaseWait', false, { root: true });
  },

  async updateEmailImportRule({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.DataFileImportSettingId,
    )[0];
    state.logger.IsDirty = !state.logger.IsNew;
    data.IsDirty = !data.IsNew;
    const m = payload;
    m.IsDirty = !m.IsNew;

    const idx = data.EmailImportRules.findIndex(
      (x) => x.EmailImportRuleId === m.EmailImportRuleId,
    );
    data.EmailImportRules.splice(idx, 1, m);
    commit('app/pleaseWait', false, { root: true });
  },

  async deleteEmailImportRule({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    payload.forEach((x) => {
      const data = state.logger.DataFileImportSettings.filter(
        (y) => y.DataFileImportSettingId === x.DataFileImportSettingId,
      )[0];
      state.logger.IsDirty = !state.logger.IsNew;
      data.IsDirty = !data.IsNew;
      const idx = data.EmailImportRules.findIndex(
        (y) => y.EmailImportRuleId === x.EmailImportRuleId,
      );
      const m = data.EmailImportRules[idx];
      if (!m.IsNew) {
        if (!data.DeleteEmailImportRules) {
          data.DeleteEmailImportRules = [];
        }
        data.DeleteEmailImportRules.push(m.EmailImportRuleId);
      }
      data.EmailImportRules.splice(idx, 1);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async emailImportRule({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('emailImportRule', payload);
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {

  siteConfiguration(state, payload) {
    state.siteConfiguration = payload;
  },

  loggers(state, payload) {
    state.loggers = payload;
  },

  logger(state, payload) {
    state.logger = payload;
  },
  peNumber(state, payload) {
    state.peNumber = payload;
  },
  channel(state, payload) {
    state.channel = payload;
  },
  channelNumber(state, payload) {
    state.channelNumber = payload;
  },
  dataFileNumber(state, payload) {
    state.dataFileNumber = payload;
  },
  loggerId(state, payload) {
    state.loggerId = payload;
  },
  init(state) {
    state.channelNumber = 0;
    state.dataFileNumber = 0;
    state.channel = null;
  },
  unitsOfMeasure(state, payload) {
    state.unitsOfMeasure = payload;
  },
  statisticTypes(state, payload) {
    state.statisticTypes = payload;
  },
  propertyTypes(state, payload) {
    state.propertyTypes = payload;
  },
  timeStamps(state, payload) {
    state.timeStamps = payload;
  },
  fileTypes(state, payload) {
    state.fileTypes = payload;
  },
  columnTypes(state, payload) {
    state.columnTypes = payload;
  },
  propertyFunctionTypes(state, payload) {
    state.propertyFunctionTypes = payload;
  },
  codeAlreadyExists(state, payload) {
    state.codeAlreadyExists = payload;
  },
  editLogger(state) {
    state.logger.IsDirty = !state.logger.IsNew;
  },

  dataFileImport(state, payload) {
    state.dataFileImport = payload;
  },
  showDataImportModal(state, payload) {
    state.showDataImportModal = payload;
  },

  dataFileImportSetting(state) {
    state.defaultDataFileImport = {
      StartingDate: state.siteConfiguration.StartingDate,
      EndingDate: state.siteConfiguration.EndingDate,
      PeNumber: state.logger ? state.logger.PeNumber : null,
      Details: 'New configuration',
      TimeStampId: 2,
      FileTypeId: 1,
      Delimiter: ',',
      SkipLines: 0,
      LinesPerRecord: 1,
      IntervalLength: 600,
      MissingDataValues: '',
      IsNew: true,
    };
  },
  timeAdjustment(state, payload) {
    state.timeAdjustment = payload;
  },
  textReplacement(state, payload) {
    state.textReplacement = payload;
  },
  dataImportConfig(state, payload) {
    state.dataImportConfig = payload;
  },
  height(state, payload) {
    state.height = payload;
  },
  measurementPoint(state, payload) {
    state.measurementPoint = payload;
  },
  cleanGenerateColumns(state) {
    state.channels = [];
    state.heights = [];
    state.height = null;
    state.measurementPoints = [];
    state.measurementPoint = null;
  },
  generateColumns(state, payload) {
    const data = state.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === payload.id,
    )[0];
    state.logger.IsDirty = !state.logger.IsNew;
    data.IsDirty = !data.IsNew;
    let i = 0;
    if (payload.genBy.Id === 'Channel') {
      generateColumns.generateByChannels(state.dataFileNumber, data,
        state.channels, state.measurementPoints);
      i = state.channels.length * state.measurementPoints.length;
    } else {
      generateColumns.generateByHeights(state.dataFileNumber, data,
        state.heights, state.measurementPoints,
        state.channelNumber, state.logger);
      i = state.heights.length * state.measurementPoints.length;
      state.channelNumber -= i;
    }
    state.dataFileNumber -= i;
  },
  channels(state, payload) {
    state.channels = payload;
  },
  emailImportRule(state, payload) {
    state.emailImportRule = payload;
  },
  symDRVersions(state, payload) {
    state.symDRVersions = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
