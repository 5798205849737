<template>
  <v-dialog
    v-model="show"
    persistent
    width="600"
  >
    <v-card tile>
      <v-toolbar>
        <v-toolbar-title>{{ $t('SetNewLocation') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          v-model="latitude"
          type="number"
          label="Latitude"
        />
        <v-text-field
          v-model="longitude"
          type="number"
          label="Longitude"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="submitClick"
        >
          {{ $t('Submit') }}
        </v-btn>
        <v-btn
          text
          @click="show = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
  },
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'latitude', event: 'models:lat' },
    { data: 'longitude', event: 'models:lon' },
  ],
  props: {
  },
  emits: ['click:submit'],
  data: () => ({
    latitude: 0.0,
    longitude: 0.0,
    show: false,
  }),
  i18n: {
    messages: {
      en: {
        SetNewLocation: 'Set a New Location',
        Submit: 'Submit',
        Close: 'Close',
      },
      fr: {
        SetNewLocation: 'Définir un nouvel emplacement',
        Submit: 'Soumettre',
        Close: 'Fermer',
      },
    },
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) { this.$emit('models:show', newVal); },
    longitude: function w(newVal) { this.$emit('models:lon', newVal); },
    latitude: function w(newVal) { this.$emit('models:lat', newVal); },
  },
  mounted() {
  },
  methods: {
    submitClick() {
      this.show = false;
      this.$emit('click:submit', {
        latitude: this.latitude,
        longitude: this.longitude,
      });
    },
  },
};
</script>

<style>

</style>
