<template>
  <v-card>
    <v-dialog
      v-model="show"
      transition="dialog-bottom-transition"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="pa-3 mb-3">
          {{ $t(buttonText) }}
        </v-card-title>
        <v-list dense>
          <v-tooltip
            v-for="placeholder in placeholdersChoices"
            :key="placeholder.PlaceholderId"
            bottom
          >
            <template #activator="{ on }">
              <v-list-item
                style="min-height: 30px;"
                v-on="on"
              >
                <v-checkbox
                  :key="placeholder.PlaceholderId"
                  :input-value="placeholder.Selected"
                  :disabled="maxItems !== -1 && nbCheckboxSelected >= maxItems && !placeholder.Selected"
                  class="my-0"
                  dense
                  hide-details
                  @change="value => placeholder.Selected = value"
                />
                <v-list-item-title class="pt-2">
                  {{ placeholder.Name }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <div style="white-space: pre;">
              {{ tooltip(placeholder) }}
            </div>
          </v-tooltip>
        </v-list>
        <v-card-actions class="justify-end pt-4 ">
          <v-btn
            text
            @click="add"
          >
            {{ $t('Add') }}
          </v-btn>
          <v-btn
            text
            @click="show = false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      block
      :disabled="placeholdersChoices.length === 0 || (maxItems !== -1 && form.selectedPlaceholders.length >= maxItems) || cardDisable"
      @click="show = true"
    >
      <v-icon>mdi-memory</v-icon>
      {{ $t(buttonText) }}
    </v-btn>
    <v-list dense>
      <v-list-item
        v-for="(placeholder, index) in form.selectedPlaceholders"
        :key="index"
        style="min-height: 28px; max-height: 28px;"
      >
        <v-list-item-icon
          class="ml-0 mr-1 mt-0"
          style="cursor:pointer;"
          @click="changeYAxis(placeholder)"
        >
          <v-icon :color="placeholder.Y2Axis ? 'cyan' : 'white'">
            {{ isSensor ? 'mdi-alpha-s-box-outline' : 'mdi-alpha-v-box-outline' }}
          </v-icon>
        </v-list-item-icon>
        <v-select
          v-model="placeholder.Aggregation"
          :items="AggregateBy2Query"
          dense
          class="mr-3"
          style="max-width: 70px; font-size: 70%;"
          @change="emitChange()"
        />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-list-item-title v-on="on">
              {{ placeholder.Name }}
            </v-list-item-title>
          </template>
          <div style="white-space: pre;">
            {{ tooltip(placeholder) }}
          </div>
        </v-tooltip>
        <v-list-item-action class="ml-0 mr-1">
          <v-btn
            small
            @click="remove(placeholder)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'PlaceholderSelection',
  props: {
    selectedPlaceholders: {
      type: Array,
      default: () => [],
      required: false,
    },
    buttonText: {
      type: String,
      default: 'Dashboard.Placeholder.AddPlaceholders',
      required: false,
    },
    maxItems: {
      type: Number,
      default: -1,
      required: false,
    },
    isSensor: {
      type: Boolean,
      default: true,
      required: false,
    },
    hideY2: {
      type: Boolean,
      default: () => (false),
      required: false,
    },
    cardDisable: {
      type: Boolean,
      default: () => (false),
      required: false,
    },
  },
  data: () => ({
    AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX', 'SUM'],
    show: false,
    placeholdersChoices: [],
    form: {
      selectedPlaceholders: [],
    },
  }),
  computed: {
    ...mapState('dashboardPlaceholders', ['placeholders']),
    ...mapGetters('dashboardPlaceholders', ['defaultName']),

    nbCheckboxSelected() {
      if (!this.placeholdersChoices) {
        return 0;
      }
      return this.placeholdersChoices.filter((x) => x.Selected).length;
    },
  },
  watch: {
    selectedPlaceholders() {
      this.initializeChoiceAndSelected();
    },
    show() {
      this.placeholdersChoices.forEach((x) => {
        const placeholder = x;
        placeholder.Selected = false;
      });
    },
  },
  created() {
    this.initializeChoiceAndSelected();
  },
  methods: {
    initializeChoiceAndSelected() {
      if (this.placeholdersChoices.length === 0) {
        this.placeholdersChoices = this.placeholders.map((x) => {
          const placeholder = JSON.parse(JSON.stringify(x));
          placeholder.Name = x.JsonData.Name || this.defaultName(x);
          placeholder.Selected = false;
          return placeholder;
        });
      }

      if (this.selectedPlaceholders.length !== this.form.selectedPlaceholders.length) {
        this.form.selectedPlaceholders = [];
        this.selectedPlaceholders.forEach((x) => {
          let placeholder = this.placeholdersChoices.find((y) => x.Id === y.PlaceholderId);
          if (placeholder) {
            placeholder = JSON.parse(JSON.stringify(placeholder));
            placeholder.Y2Axis = x.Y2Axis || false;
            placeholder.Aggregation = x.Aggregation || 'AVG';
            this.form.selectedPlaceholders.push(placeholder);
          }
        });

        if (this.selectedPlaceholders.length !== this.form.selectedPlaceholders.length) {
          // placeholders was removed
          this.emitChange();
        }
      }
    },
    remove(placeholder) {
      const index = this.form.selectedPlaceholders.indexOf(placeholder);
      this.form.selectedPlaceholders.splice(index, 1);

      this.emitChange();
    },
    add() {
      this.placeholdersChoices
        .filter((x) => x.Selected)
        .forEach((x) => {
          const placeholder = JSON.parse(JSON.stringify(x));
          placeholder.Y2Axis = x.Y2Axis || false;
          placeholder.Aggregation = x.Aggregation || 'AVG';
          this.form.selectedPlaceholders.push(placeholder);
        });

      this.show = false;
      this.emitChange();
    },
    tooltip(placeholder) {
      return `${this.$t('Dashboard.Placeholder.RuleType')}: ${placeholder.JsonData.RuleType}`
        + `\n ${this.$t('Dashboard.Placeholder.ValueType')}: ${placeholder.JsonData.ValueType}`
        + `\n ${this.$t('Dashboard.Placeholder.Value')}: ${placeholder.JsonData.Value}`;
    },
    emitChange() {
      this.$emit('change', this.form.selectedPlaceholders.map((x) => ({
        Id: x.PlaceholderId,
        Y2Axis: x.Y2Axis,
        Aggregation: x.Aggregation,
      })));
    },
    changeYAxis(item) {
      if (!this.hideY2) {
        const placeholder = item;
        placeholder.Y2Axis = !placeholder.Y2Axis;
        this.emitChange();
      }
    },
    changeAggregation(item, value) {
      const placeholder = item;
      placeholder.Aggregation = value;
      this.emitChange();
    },
  },
};
</script>

<style>
</style>
