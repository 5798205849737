<template>
  <div>
    <div
      v-if="site.IsRetired"
      class="pb-2"
      v-t="'Customers.Sites.Retired'"
    />
    <div>
      Status and notifications goes here!
    </div>
  </div>
</template>

<script>
export default {
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
};
</script>
