import api from '@/api/importal';

const state = {
  landOwnerPhone: null,
  landOwnerPhones: [],
  landOwnerId: null,
  phoneTypes: null,
};

const actions = {
  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`LandOwnerPhonesList?landOwnerId=${state.landOwnerId}`);
    commit('landOwnerPhones', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('LandOwnerPhonesCreate', {
      Number: payload.Number,
      LandOwnerPhoneId: null,
      PhoneTypeId: Number(payload.PhoneTypeId.PhoneTypeId),
      LandOwnerId: Number(state.landOwnerId),
    });
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`LandOwnerPhonesGet?id=${payload.phoneId}`);
    commit('landOwnerPhone', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async update({ commit, dispatch }, payload) {
    let typeId = Number(payload.PhoneTypeId);
    if (Number.isNaN(typeId)) typeId = Number(payload.PhoneTypeId.PhoneTypeId);
    commit('app/pleaseWait', true, { root: true });
    await api.put('LandOwnerPhonesUpdate', {
      Number: payload.Number,
      PhoneTypeId: typeId,
      LandOwnerId: Number(payload.LandOwnerId),
      LandOwnerPhoneId: Number(payload.LandOwnerPhoneId),
    });
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`LandOwnerPhonesRemove?id=${payload.id}`);
    commit('landOwnerPhone', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async setLandOwnerId({ commit }, payload) {
    commit('landOwnerId', payload);
  },
  async setPhoneTypes({ commit }, payload) {
    commit('phoneTypes', payload);
  },
};

const mutations = {
  landOwnerPhones(state, payload) {
    state.landOwnerPhones = payload;
  },
  landOwnerPhone(state, payload) {
    state.landOwnerPhone = payload;
  },
  landOwnerId(state, payload) {
    state.landOwnerId = payload;
  },
  phoneTypes(state, payload) {
    state.phoneTypes = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
