<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <div class="row">
              <v-text-field
                v-model="formData.Name"
                :label="$t('WindServer.Sites.LoggerConfiguration.Columns.Name')"
                :rules="requiredRule"
                :maxlength="50"
                class="col col-9"
              />
              <v-select
                v-model="formData.ColumnType"
                :label="$t('WindServer.Sites.LoggerConfiguration.Columns.ColumnType')"
                :items="columnTypes"
                :rules="requiredRule"
                item-text="NameI18n"
                item-value="ColumnTypeId"
                return-object
                class="col col-3"
                @change="changeColumnType"
              />
            </div>
            <div class="row">
              <v-select
                v-if="isData"
                v-model="formData.LoggerChannel"
                :label="$t('WindServer.Sites.LoggerConfiguration.Columns.Channel')"
                :items="logger.Channels"
                :rules="requiredRule"
                item-text="ChannelCode"
                item-value="ChannelCalibrationId"
                return-object
                class="col col-4"
                @change="changeChannel"
              />
              <v-select
                v-if="isData"
                v-model="formData.PropertyType"
                :label="$t('WindServer.Sites.LoggerConfiguration.Columns.Property')"
                :items="propertyTypes"
                :rules="requiredRule"
                item-text="Name"
                item-value="PropertyTypeId"
                return-object
                class="col col-4"
              />
              <v-select
                v-if="isData"
                v-model="formData.StatisticType"
                :label="$t('WindServer.Sites.LoggerConfiguration.Columns.Statistic')"
                :items="statisticTypes"
                :rules="requiredRule"
                item-text="Name"
                item-value="StatisticTypeId"
                return-object
                class="col col-4"
              />
            </div>
            <div class="row">
              <v-text-field
                v-if="isData"
                v-model="formData.Height"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Height')"
                :readonly="true"
                class="col col-6"
              />
              <v-text-field
                v-if="isData"
                v-model="formData.HeadingFromTower"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.HeadingFromTower')"
                :readonly="true"
                class="col col-6"
              />
            </div>
            <div class="row">
              <v-text-field
                v-if="isData"
                v-model="formData.DistanceToTower"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.DistanceToTower')"
                :readonly="true"
                class="col col-6"
              />
              <v-text-field
                v-if="isData"
                v-model="formData.AveragingInterval"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.AveragingInterval')"
                :readonly="true"
                class="col col-6"
              />
            </div>
            <div class="row">
              <v-select
                v-if="isData"
                v-model="formData.UnitOfMeasure"
                :label="$t('WindServer.Sites.LoggerConfiguration.Columns.UnitOfMeasure')"
                :items="unitsOfMeasure"
                :rules="requiredRule"
                item-text="NameI18n"
                item-value="UnitOfMeasureId"
                return-object
                class="col"
              />
              <v-textarea
                v-if="isTimeStamp"
                v-model="formData.TimestampFormat"
                :label="$t('WindServer.Sites.LoggerConfiguration.Columns.TimestampFormat')"
                :rules="requiredRule"
                :maxlength="50"
                class="col"
              />
            </div>
            <div
              v-if="fixed"
              class="row"
            >
              <v-text-field
                v-model="formData.StartIndex"
                :label="$t('WindServer.Sites.LoggerConfiguration.Columns.StartIndex')"
                :rules="numberRule"
                type="number"
                class="col col-6"
              />
              <v-text-field
                v-model="formData.ColumnWidth"
                :label="$t('WindServer.Sites.LoggerConfiguration.Columns.ColumnWidth')"
                :rules="numberRule"
                type="number"
                class="col col-6"
              />
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    fixed: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      numberRule: [(v) => (!Number.isNaN(parseFloat(v))) || this.$t('Validation.Required')],
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['logger', 'dataImportConfig', 'columnTypes', 'propertyTypes', 'statisticTypes', 'unitsOfMeasure']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$store.dispatch('site/loggerConfigurations/dataImportConfig', null);
        this.$emit('input', value);
      },
    },
    isIgnore() {
      return this.formData.ColumnType != null
      && this.formData.ColumnType.ColumnTypeId === 2;
    },
    isData() {
      return this.formData.ColumnType != null
      && this.formData.ColumnType.ColumnTypeId === 1;
    },
    isTimeStamp() {
      return this.formData.ColumnType != null
      && this.formData.ColumnType.ColumnTypeId === 3;
    },
  },
  methods: {
    async setFormData() {
      if (this.dataImportConfig) {
        this.formData = JSON.parse(JSON.stringify(this.dataImportConfig));
      } else { // Create mode
        this.formData = {
          DataFileImportSettingId: this.id,
        };
      }
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (this.fixed) {
            this.formData.StartIndex = Number(this.formData.StartIndex);
            this.formData.ColumnWidth = Number(this.formData.ColumnWidth);
          }
          if (this.dataImportConfig) {
            await this.$store.dispatch('site/loggerConfigurations/updateDataImportConfig', this.formData);
          } else {
            await this.$store.dispatch('site/loggerConfigurations/createDataImportConfig', this.formData);
          }
          this.close();
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/loggerConfigurations/dataImportConfig', null);
      this.$refs.form.reset();
    },
    changeColumnType() {
      if (this.isIgnore || this.isTimeStamp) {
        this.formData.LoggerChannel = null;
        this.formData.PropertyType = null;
        this.formData.StatisticType = null;
        this.formData.UnitOfMeasure = null;
        this.formData.Height = null;
        this.formData.HeadingFromTower = null;
        this.formData.DistanceToTower = null;
        this.formData.AveragingInterval = null;
        if (this.isIgnore) {
          this.formData.TimestampFormat = null;
        }
      }
    },
    changeChannel() {
      this.formData.LoggerChannel.ChannelCalibration = {
        ChannelCalibrationId: this.formData.LoggerChannel.ChannelCalibrationId,
        Height: this.formData.LoggerChannel.Height,
        HeadingFromTower: this.formData.LoggerChannel.HeadingFromTower,
        DistanceToTower: this.formData.LoggerChannel.DistanceToTower,
        AveragingInterval: this.formData.LoggerChannel.AveragingInterval,
      };
      this.formData.Height = this.formData.LoggerChannel.Height;
      this.formData.HeadingFromTower = this.formData.LoggerChannel.HeadingFromTower;
      this.formData.DistanceToTower = this.formData.LoggerChannel.DistanceToTower;
      this.formData.AveragingInterval = this.formData.LoggerChannel.AveragingInterval;
    },
  },
};
</script>
