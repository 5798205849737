/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsPEnumbers extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'IsReserved',
          headerName: this.vue.$t('Equipments.PENumbers.Reserved'),
          filter: true,
          sortable: true,
          editable: false,
          width: 115,
          cellRenderer: (params) => `${params.value ? '<i class="v-icon mdi mdi-check theme--dark"></i>' : ''}`,
        },
        {
          headerName: this.vue.$t('Equipments.PENumbers.PeNumber'),
          filter: true,
          sortable: true,
          editable: false,
          width: 100,
          valueGetter: (params) => String(params.data.PeNumber).padStart(5, '0'),
        },
        {
          field: 'ReservedDate',
          headerName: this.vue.$t('Equipments.PENumbers.ReservedDate'),
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          field: 'Name',
          headerName: this.vue.$t('Equipments.PENumbers.ReservedBy'),
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          field: 'ReservationDetails',
          headerName: this.vue.$t('Equipments.PENumbers.DetailsCol'),
          filter: true,
          sortable: true,
          editable: false,
        },
      ]);
    });
  }
}
