<template>
  <div>
    <subscribe-setting
      v-if="OpenSetting"
      :sensor-list="EditedSensor"
      :edit-sensor="true"
      @close-subscribe="OpenSetting = false; init()"
    />
    <v-card
      v-if="SeeInfoDialog"
      class="right-sidebar"
      tile
      outlined
      elevation="10"
    >
      <v-card-title>
        {{ Info.Name }}
      </v-card-title>
      <v-spacer />
      <v-card-subtitle class="text-subtitle-2">
        Type
      </v-card-subtitle>
      <v-card-text>
        {{ Info.Type }}
      </v-card-text>
      <v-spacer />
      <v-card-subtitle class="text-subtitle-2">
        {{ Info.Subtitle }}
      </v-card-subtitle>
      <v-card-text>
        {{ Info.TypeInfo }}
      </v-card-text>
      <v-spacer />
      <v-card-subtitle class="text-subtitle-2">
        {{ Info.SecondNomSubtitle }}
      </v-card-subtitle>
      <v-card-text>
        {{ Info.SecondNomTypeInfo }}
      </v-card-text>
      <v-spacer />
      <v-card-actions>
        <v-btn
          text
          @click="CloseInfo()"
        >
          {{ $t('Dashboard.SubscribeMenu.Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      v-if="OpenSetting === false && SeeInfoDialog === false"
      class="right-sidebar"
      tile
      outlined
      elevation="10"
    >
      <v-card-title>
        {{ $t('Dashboard.SubscribeMenu.SubscribeMenu') }}
      </v-card-title>
      <v-spacer />
      <v-list
        dense
        shaped
      >
        <v-list-item-group
          v-model="SelectedAlert"
        >
          <v-list-item
            v-for="(alert, i) in AlertList"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="alert.Name" />
              <v-list-item-subtitle v-text="alert.SensorId" />
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="SelectedAlert == i"
                icon
                @click="DeleteAlert()"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-btn
                v-if="SelectedAlert == i"
                icon
                @click="SeeInfo()"
              >
                <v-icon>
                  mdi-information
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions>
        <v-btn
          v-if="SelectedAlert != null"
          text
          @click="Edit()"
        >
          {{ $t('Dashboard.SubscribeMenu.Edit') }}
        </v-btn>
        <v-btn
          text
          @click="$emit('close-subscribe-menu')"
        >
          {{ $t('Dashboard.SubscribeMenu.Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import importal from '@/api/importal';
import SubscribeSetting from './subscription/SubscribeSetting.vue';

export default {
  name: 'SubscribeSettingPanel',
  components: {
    SubscribeSetting,
  },
  props: {
  },
  data() {
    return {
      Info: {
        Name: null,
        Type: null,
        TypeInfo: null,
        Subtitle: null,
        SecondNomTypeInfo: null,
        SecondNomSubtitle: null,
      },
      Scale: [
        { Name: 'Months', Scale: 43800 },
        { Name: 'Weeks', Scale: 10080 },
        { Name: 'Days', Scale: 1440 },
        { Name: 'Hours', Scale: 60 },
        { Name: 'Minutes', Scale: 1 },
      ],
      SeeInfoDialog: false,
      EditedSensor: null,
      AlertList: [],
      SelectedAlert: null,
      OpenSetting: false,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.AlertList = await (await importal.get('AlertBasicList')).data;
    },
    Edit() {
      this.EditedSensor = this.AlertList[this.SelectedAlert];
      this.OpenSetting = true;
    },
    async DeleteAlert() {
      await importal.post('AlertBasicRemove', {
        BasicAlertConfigId: this.AlertList[this.SelectedAlert].BasicAlertConfigId,
      });
      this.init();
    },
    SeeInfo() {
      const Sensor = this.AlertList[this.SelectedAlert];
      this.SeeInfoDialog = true;
      this.Info.Name = Sensor.Name;
      if (Sensor.IsScheduled) {
        this.Info.Type = 'Scheduled';
        this.Info.Subtitle = 'Every';
        this.Info.TypeInfo = this.GetEditFrequency();
      } else if (Sensor.ValueDelayHours != null) {
        this.Info.Type = 'Missing Value';
        this.Info.Subtitle = 'Missing for';
        this.Info.TypeInfo = this.GetEditMissingCheck();
      } else {
        this.Info.Type = 'Nominality';
        if (Sensor.ValueLt != null) {
          this.Info.Subtitle = 'Value lesser than';
          this.Info.TypeInfo = Sensor.ValueLt;
        } if (Sensor.ValueLteq != null) {
          this.Info.Subtitle = 'Value lesser or equal to';
          this.Info.TypeInfo = Sensor.ValueLteq;
        } if (Sensor.ValueGt != null) {
          if (this.Info.TypeInfo == null) {
            this.Info.Subtitle = 'Value greater than';
            this.Info.TypeInfo = Sensor.ValueGt;
          } else {
            this.Info.SecondNomTypeInfo = Sensor.ValueGt;
            this.Info.SecondNomSubtitle = 'and Value greater than';
          }
        } if (Sensor.ValueGteq != null) {
          if (this.Info.TypeInfo == null) {
            this.Info.Subtitle = 'Value greater or equal to';
            this.Info.TypeInfo = Sensor.ValueGteq;
          } else {
            this.Info.SecondNomTypeInfo = Sensor.ValueGteq;
            this.Info.SecondNomSubtitle = 'and Value greater or equal to';
          }
        }
      }
    },
    GetEditMissingCheck() {
      const frequencyHours = this.AlertList[this.SelectedAlert].ValueDelayHours;
      for (let i = 0; i < this.Scale.length; i += 1) {
        if (frequencyHours % (this.Scale[i].Scale / 60) === 0) {
          const Value = frequencyHours / (this.Scale[i].Scale / 60);

          const Scaling = this.$t(`Dashboard.SubscribeMenu.${this.Scale[i].Name}`);
          const ReturnValue = `${Value} ${Scaling}`;
          return ReturnValue;
        }
      }
      const hourName = this.$t('Dashboard.SubscribeMenu.Hours');
      return `${frequencyHours} ${hourName}`;
    },
    GetEditFrequency() {
      const frequencyminutes = this.AlertList[this.SelectedAlert].ValidationRateMinutes;
      for (let i = 0; i < this.Scale.length; i += 1) {
        if (frequencyminutes % this.Scale[i].Scale === 0) {
          const Value = frequencyminutes / this.Scale[i].Scale;

          const Scaling = this.$t(this.Scale[i].Name);
          const ReturnValue = `${Value} ${Scaling}`;
          return ReturnValue;
        }
      }
      const minuteName = this.$t('Dashboard.SubscribeMenu.Minutes');
      return `${frequencyminutes} ${minuteName}`;
    },
    CloseInfo() {
      this.SeeInfoDialog = false;
      this.Info.Name = null;
      this.Info.Type = null;
      this.Info.TypeInfo = null;
      this.Info.Subtitle = null;
      this.Info.SecondNomTypeInfo = null;
      this.Info.SecondNomSubtitle = null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .right-sidebar {
    position: absolute;
    top: 49px;
    right: 10px;
    width: 400px;
    z-index: 9999;
  }
</style>
