<template>
  <div class="overflow-auto">
    <Field
      label="InstallationDate"
      :value="formatDateTime(site.InstallationDate)"
    />
    <Field
      label="PeriodOfRecord"
      :value="`${formatDateTime(site.DataStartDate)} - ${formatDateTime(site.DataEndDate)}`"
    />
    <Field
      v-if="site.RetirementDate"
      label="RetirementDate"
      :value="formatDateTime(site.RetirementDate)"
    />
    <v-divider />
    <Field
      label="Municipality"
      :value="site.Municipality"
    />
    <Field
      label="ProvinceState"
      :value="site.ProvinceStateName"
    />
    <Field
      label="Country"
      :value="site.CountryName"
    />
    <Field
      label="LandTypeDescription"
      :value="site.LandTypeDescription"
    />
    <Field
      label="ShelterBoxLockCode"
      :value="site.ShelterBoxLockCode"
    />
    <Field
      label="MagneticDeclination"
      :value="site.MagneticDeclination"
    />
    <Field
      label="Fenced"
      :value="site.IsFenced ? $t('Customers.Sites.DetailsAndTimeline.Yes') : $t('Customers.Sites.DetailsAndTimeline.No')"
    />
    <Field
      label="SiteNumber"
      :value="site.Number"
    />
    <Field
      label="TimeZone"
      :value="site.TimeZoneName"
    />
    <v-divider />
    <Field
      label="UTM"
      :label-params="site"
    >
      <span v-t="'Customers.Sites.DetailsAndTimeline.Zone'" />:{{ site.UTMGridZone }}
      <span v-t="'Customers.Sites.DetailsAndTimeline.UTMEasting'" />:{{ site.UTMEasting }}
      <span v-t="'Customers.Sites.DetailsAndTimeline.UTMNorthing'" />:{{ site.UTMNorthing }}
    </Field>
    <Field
      label="Latitude"
      :value="site.Latitude"
    />
    <Field
      label="Longitude"
      :value="site.Longitude"
    />
    <Field
      label="Elevation"
      :value="`${site.Elevation} m`"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import datehandling from '@/components/datehandling';
import Field from './SiteDetailsField.vue';

export default {
  components: {
    Field,
  },
  computed: {
    ...mapState('customers/sites', ['site']),
  },
  methods: {
    formatDateTime(date) {
      return datehandling.formatForDisplay(date);
    },
  },
};
</script>
