import QCProblemTypeList from './components/QCProblemTypeList.vue';
import QCProblemTypeFormModal from './components/QCProblemTypeFormModal.vue';

export default [
  {
    path: '/QualityCheck/QCProblemTypes',
    name: 'QualityCheck.QCProblemTypes.List',
    component: QCProblemTypeList,
    children: [
      {
        path: '/QualityCheck/QCProblemTypes/Create',
        name: 'QualityCheck.QCProblemTypes.Create',
        component: QCProblemTypeFormModal,
      },
      {
        path: '/QualityCheck/QCProblemTypes/:id',
        name: 'QualityCheck.QCProblemTypes.Update',
        component: QCProblemTypeFormModal,
        props: true,
      },
    ],
  },
];
