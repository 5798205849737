/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import DashboardStackWidgetBase from './DashboardStackWidgetBase';

export default class DashboardStackVueWidgetAdapter extends DashboardStackWidgetBase {
  constructor(grid, widget, iface, componentClass) {
    super(grid, widget, iface);
    this.componentClass = componentClass;
    this.componentInstance = null;
    this.onWidgetMenuItemClickRegister = [];
  }

  unmountWidget() {
    super.unmountWidget();
    if (this.componentInstance) {
      this.onWidgetMenuItemClickRegister = [];
      this.componentInstance.$destroy();
      this.componentInstance = null;
    }
  }

  editModeFlagChanged() {
    super.editModeFlagChanged();
    if (this.componentInstance && this.componentInstance.$data.editModeFlag !== undefined) {
      this.componentInstance.$data.editModeFlag = (this.interface.editModeFlag());
    }
  }

  async displayWidget() {
    if (this.componentInstance) {
      this.onWidgetMenuItemClickRegister = [];
      this.componentInstance.$destroy();
      this.componentInstance = null;
    }

    let settings = null;
    try {
      settings = JSON.parse(this.widget.WidgetSettings);
    } catch (e) {
      return;
    }

    const node = this.createWidgetNode();
    this.mapid = `${this.widget.DashboardsWidgetId}`;
    const div = `<div id="${this.mapid}" style="height: 100%; z-index: 1;"></div>`;

    this.createWidgetContent(div, ''/* additionalMenus */);

    const Cls = Vue.extend(this.componentClass);
    this.componentInstance = new Cls({
      parent: this.interface.getVueParent(),
      propsData: {
        widgetSettings: settings,
        widgetAdapter: this,
      },
    });
    this.componentInstance.$mount(`[id='${this.mapid}']`);

    this.widgetClickListeners();
    this.waitForWidgetNode(node);
    if (this.componentInstance && this.componentInstance.$data.widgetSize !== undefined) {
      const el = this.getGridStackElement();
      this.componentInstance.$data.widgetSize = {
        w: el.clientWidth,
        h: el.clientHeight,
      };
    }

    if (this.componentInstance && this.componentInstance.$data.editModeFlag !== undefined) {
      this.componentInstance.$data.editModeFlag = (this.interface.editModeFlag());
    }
  }

  resizeToContainer(gridstackNode) {
    const rect = {
      w: gridstackNode.el.clientWidth,
      h: gridstackNode.el.clientHeight,
    };
    if (this.componentInstance && this.componentInstance.$data.widgetSize !== undefined) {
      this.componentInstance.$data.widgetSize = rect;
    }
  }

  selectedInstrumentsChanged(event) {
  }

  registerMenuItemCallback(action, cb) {
    this.onWidgetMenuItemClickRegister.push({
      action,
      cb,
    });
  }

  onWidgetMenuItemClick(e) {
    const menuButton = e.currentTarget;
    const { action } = menuButton.dataset;
    this.onWidgetMenuItemClickRegister.forEach((item) => {
      if (item.action === action) {
        item.cb(e);
      }
    });
    super.onWidgetMenuItemClick(e);
  }
}
