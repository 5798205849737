import TenantList from './components/TenantList.vue';
import TenantFormModal from './components/TenantFormModal.vue';

export default [
  {
    path: '/Settings/Tenants',
    name: 'Settings.Tenants.List',
    component: TenantList,
    children: [
      {
        path: '/Settings/Tenants/Create',
        name: 'Settings.Tenants.Create',
        component: TenantFormModal,
      },
      {
        path: '/Settings/Tenants/:id',
        name: 'Settings.Tenants.Update',
        component: TenantFormModal,
        props: true,
      },
    ],
  },
];
