import tenants from './tenants/tenants.store';
import users from './users/users.store';

export default {
  namespaced: true,
  modules: {
    tenants,
    users,
  },
};
