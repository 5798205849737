<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.configs"
      :show-new-button="false"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="configs"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import WorkerList from '../gridsources/gsworkerconfigs';

export default {
  components: {
    ServerSideDataGrid,
  },
  props: {
    workerid: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      sources: {
        configs: null,
      },
      toolbarButtons: [],
      showConfirm: false,
      editModel: null,
    };
  },
  computed: {
    ...mapState('worker/workerconfigs', ['configs']),
  },
  created() {
    this.sources.configs = new WorkerList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.WorkerConfiguration.Create')) {
      this.toolbarButtons = [
        {
          tooltip: this.$t('New'),
          icon: 'mdi-plus',
          enabled: true,
          click: () => { this.createNew(); },
        },
      ];
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    refreshList() {
      this.$store.dispatch('worker/workerconfigs/list', this.workerid);
    },
    createNew() {
      this.$router.push({ name: 'Worker.WorkerConfigs.Create', params: { workerid: this.workerid } });
    },
  },
};
</script>
