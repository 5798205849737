<template>
  <div>
    <v-card-title>
      {{ $t('WindServer.Sites.DataTrace.FlagPeriods') }}
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="addFlagPeriods = true;"
          >
            <i class="v-icon mdi mdi-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.DataTrace.Add') }} </span>
      </v-tooltip>
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="addIntervals = true"
          >
            <i class="v-icon mdi mdi-flag-variant-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.DataTrace.AddIntervals') }} </span>
      </v-tooltip>
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="deleteFlagPeriods()"
          >
            <i class="v-icon mdi mdi-delete-forever" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.DataTrace.Delete') }} </span>
      </v-tooltip>
    </v-card-title>
    <v-card-text style="height: 200px;">
      <ServerSideDataGrid
        ref="gridFlag"
        :grid-source="sources.FlagPeriods"
        :row-data-init="flagPeriods"
        :pagination="false"
        enable-selection="multiple"
      />
    </v-card-text>
    <FlagPeriodsFormModal
      v-model="addFlagPeriods"
    />
    <AddIntervalsModal
      v-model="addIntervals"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import FlagPeriodsList from '../gridsources/gsFlagPeriods';
import FlagPeriodsFormModal from './FlagPeriodsFormModal.vue';
import AddIntervalsModal from './AddIntervalsModal.vue';

export default {
  components: {
    ServerSideDataGrid,
    FlagPeriodsFormModal,
    AddIntervalsModal,
  },
  props: {
  },
  data() {
    return {
      sources: {
        FlagPeriods: null,
      },
      addFlagPeriods: false,
      addIntervals: false,
    };
  },
  computed: {
    ...mapState('site/dataTrace', ['flagPeriods', 'sensors']),
  },
  created() {
    this.sources.FlagPeriods = new FlagPeriodsList(this);
  },
  methods: {
    async editFlagPeriod(payload) {
      await this.$store.dispatch('site/dataTrace/flagPeriod', payload);
      this.addFlagPeriods = true;
    },
    async deleteFlagPeriods() {
      let selectedRows = [];
      if (this.$refs.gridFlag.gridApi.getSelectedRows().length > 0) {
        selectedRows = this.$refs.gridFlag.gridApi.getSelectedRows();
      }
      if (selectedRows.length > 0) {
        await this.$store.dispatch('site/dataTrace/deleteFlagPeriods', selectedRows);
      }
    },
  },
};
</script>
