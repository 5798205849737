<template>
  <div style="height: calc(100vh - 120px); width: 100%;">
    <ServerSideDataGrid
      :grid-source="sources.QCSectionQuestions"
      :show-new-button="$auth.roles.includes('Web.QCInspectionTemplate.Create')"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="sectionQuestions"
      id="lstSectionQuestions"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import QCSectionQuestionList from '../gridsources/gsQCSectionQuestions';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        QCSectionQuestions: null,
      },
      toolbarButtons: [],
    };
  },
  computed: {
    ...mapState('qualityCheck/qcSectionQuestions', ['sectionQuestions']),
  },
  created() {
    this.sources.QCSectionQuestions = new QCSectionQuestionList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.QCInspectionTemplate.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('New'),
        icon: 'mdi-plus',
        enabled: true,
        click: () => { this.createNew(); },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'QualityCheck.QCInspectionTemplates.Sections.Questions.Create' });
    },
    refreshList() {
      this.$store.dispatch('qualityCheck/qcSectionQuestions/list');
    },
  },
};
</script>
