import QCTaskTypeList from './components/QCTaskTypeList.vue';
import QCTaskTypeFormModal from './components/QCTaskTypeFormModal.vue';

export default [
  {
    path: '/QualityCheck/QCTaskTypes',
    name: 'QualityCheck.QCTaskTypes.List',
    component: QCTaskTypeList,
    children: [
      {
        path: '/QualityCheck/QCTaskTypes/Create',
        name: 'QualityCheck.QCTaskTypes.Create',
        component: QCTaskTypeFormModal,
      },
      {
        path: '/QualityCheck/QCTaskTypes/:id',
        name: 'QualityCheck.QCTaskTypes.Update',
        component: QCTaskTypeFormModal,
        props: true,
      },
    ],
  },
];
