<template>
  <div
    class="lang-dropdown"
    style="margin: 0px 30px;"
  >
    <v-tooltip
      bottom
    >
      <template #activator="{ on, attrs }">
        <div
          multiple
          v-on="on"
        >
          <v-select
            v-model="timezone"
            :items="timezoneArray.map((x) => ({ text: x.split('/')[1].replace('_', ' '), value: x}))"
            v-bind="attrs"
            class="cursor-pointer"
            style="width: 180px"
            single-line
            v-on="on"
          />
        </div>
      </template>
      <span>{{ $t('Header.SwitchTimezone') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timezoneArray: [
        'America/St_Johns',
        'America/Halifax',
        'America/Glace_Bay',
        'America/Moncton',
        'America/Goose_Bay',
        'America/Blanc-Sablon',
        'America/Montreal',
        'America/Toronto',
        'America/Nipigon',
        'America/Thunder_Bay',
        'America/Iqaluit',
        'America/Pangnirtung',
        'America/Resolute',
        'America/Atikokan',
        'America/Rankin_Inlet',
        'America/Winnipeg',
        'America/Rainy_River',
        'America/Regina',
        'America/Swift_Current',
        'America/Edmonton',
        'America/Cambridge_Bay',
        'America/Yellowknife',
        'America/Inuvik',
        'America/Creston',
        'America/Dawson_Creek',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Dawson',
        'Pacific/Auckland',
        'Etc/UTC',
      ],
      timezone: 'America/Toronto',
    };
  },
  watch: {
    timezone(val) {
      this.$store.commit('app/timezone', val);
    },
  },
  mounted() {
    this.timezoneArray.sort();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.timezoneArray.filter((x) => x === timezone)) {
      this.timezone = timezone;
      this.$store.commit('app/timezone', timezone);
    }
  },
};
</script>

<style lang="scss">
</style>
