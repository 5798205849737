import landOwners from './landOwners/landOwners.store';
import landOwnerEmails from './landOwnerEmails/landOwnerEmails.store';
import landOwnerPhones from './landOwnerPhones/landOwnerPhones.store';

export default {
  namespaced: true,
  modules: {
    landOwners,
    landOwnerEmails,
    landOwnerPhones,
  },
};
