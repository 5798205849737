<template>
  <span
    v-if="!params.show || params.show(params)"
  >
    <ConfirmDialog
      v-model="showConfirm"
      :title="$t('ConfirmDeletion')"
      :message="confirmMessage"
      @on-accept="onAccept"
    />
    <v-btn
      icon
      x-small
      style="vertical-align: baseline;"
      @click="btnClickedHandler()"
      @keyup.enter="btnClickedHandler()"
      tabindex="0"
    >
      <v-icon>{{ iconSelector(params) }}</v-icon>
    </v-btn>{{ params.value }}
  </span>
</template>

<script>
import i18n from '@/plugins/i18n';
import ConfirmDialog from './ConfirmDialog.vue';

export default {
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      showConfirm: false,
      confirmMessage: null,
    };
  },
  methods: {
    iconSelector(params) {
      if (params.icon && String(params.icon).startsWith('mdi-')) {
        return params.icon;
      }

      return 'mdi-delete-forever';
    },
    btnClickedHandler() {
      if (this.params.confirm) {
        const action = this.params.action ?? i18n.t('Delete');
        this.confirmMessage = this.$t('ConfirmDeletionMessage', {
          Action: action.toLowerCase(),
          Name: this.params.confirm(this.params),
        });
      } else {
        this.confirmMessage = this.$t('ConfirmDeletionDefaultMessage');
      }
      this.showConfirm = true;
    },
    onAccept() {
      this.params.clicked(this.params);
    },
  },
};
</script>
