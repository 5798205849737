<template>
  <v-card-actions>
    <v-btn
      :disabled="pleaseWait"
      class="mr-4"
      @click="cancel"
    >
      {{ $t('Cancel') }}
    </v-btn>
    <v-btn
      v-if="showBack"
      :disabled="pleaseWait"
      @click="back"
    >
      {{ $t('Back') }}
    </v-btn>
    <v-tooltip
      bottom
      :disabled="!nextButtonTooltip || !confirmDisabled"
    >
      <template #activator="{ on }">
        <div v-on="on">
          <v-btn
            v-if="showNext"
            :disabled="pleaseWait || confirmDisabled"
            @click="next"
          >
            {{ $t('Next') }}
          </v-btn>
        </div>
      </template>
      <div style="white-space: pre;">
        {{ nextButtonTooltip }}
      </div>
    </v-tooltip>
    <v-btn
      v-if="showSave"
      :disabled="pleaseWait || confirmDisabled"
      @click="save"
    >
      {{ $t('Save') }}
    </v-btn>
  </v-card-actions>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    showBack: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
    showNext: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
    showSave: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
    confirmDisabled: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
    nextButtonTooltip: {
      type: String,
      default: null,
      required: false,
    },
  },
  emits: ['button:cancel', 'button:back', 'button:next', 'button:save'],
  computed: {
    ...mapGetters('app', ['pleaseWait', 'timezone']),
  },
  methods: {
    cancel() {
      this.$emit('button:cancel');
    },
    back() {
      this.$emit('button:back');
    },
    next() {
      this.$emit('button:next');
    },
    save() {
      this.$emit('button:save');
    },
  },
  i18n: {
    messages: {
      en: {
        Cancel: 'Cancel',
        Back: 'Back',
        Next: 'Next',
        Save: 'Save',
      },
      fr: {
        Cancel: 'Annuler',
        Back: 'Précédent',
        Next: 'Suivant',
        Save: 'Sauvegarder',
      },
    },
  },
};
</script>

<style>

</style>
