<template>
  <v-card style="width: 450px; height: 300px">
    <v-toolbar dense>
      <v-btn
        icon
        @click="downloadFile"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-card-text>{{ title }}</v-card-text>
    </v-toolbar>
    <div v-if="!isLoaded">
      <div class="loader" />
    </div>
    <div
      v-else-if="!infos.blob || infos.blob.size === 0"
      class="graphnodata"
    />
    <v-img
      v-else
      class="ml-3"
      max-width="450"
      min-height="300"
      :src="image"
      @click="openFullscreenImage(image)"
    />
  </v-card>
</template>

<script>
import fileDownload from 'js-file-download';
import importal from '@/api/importal';
import datehandling from '@/components/datehandling';

export default {
  props: {
    infos: {
      type: Object,
      required: true,
    },
    widgetSettingsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: null,
      title: null,
      isLoaded: false,
    };
  },
  watch: {
    infos: {
      async handler(newVal) {
        if (newVal) {
          this.isLoaded = false;
          this.title = datehandling.formatTimeOnlyForDisplay(this.infos.ts);
          await this.load();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async load() {
      this.image = null;
      let imageBlob = this.infos.blob;
      if (!imageBlob) {
        imageBlob = await (await importal.get(
          `FileFeedDownload?feed_id=${this.infos.feedId}&path=${encodeURIComponent(this.infos.path)}`, {
            responseType: 'blob',
            data: this.widgetSettingsData,
          },
        )).data;
        imageBlob = imageBlob.slice(0, imageBlob.size, this.infos.mime_type);

        this.$emit('blob', imageBlob);
      }

      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      reader.onloadend = () => {
        this.image = reader.result;
      };
      this.isLoaded = true;
    },
    openFullscreenImage(image) {
      this.$store.dispatch('app/openFullScreenImage', image);
    },
    downloadFile() {
      fileDownload(this.infos.blob, this.infos.path.split('/').pop(), this.infos.mime_type);
    },
  },
};
</script>
