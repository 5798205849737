<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="750"
  >
    <v-card style="width:750px">
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-card-text>
          <v-row class="align-self-start">
            <v-col>
              <v-tabs
                v-model="activeTab"
                fixed-tabs
                style="border-width:0px;"
              >
                <v-tab key="information">
                  <div :class="{'v-application error--text': isInformationError }">
                    {{ $t('Settings.LandOwner.Tabs.Information') }}
                  </div>
                </v-tab>
                <v-tab
                  v-if="id == null ? false : true"
                  key="emails"
                >
                  <slot>
                    {{ $t('Settings.LandOwner.Tabs.Emails') }}
                  </slot>
                </v-tab>
                <v-tab
                  v-if="id == null ? false : true"
                  key="phones"
                >
                  <slot>
                    {{ $t('Settings.LandOwner.Tabs.Phones') }}
                  </slot>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-container>
            <v-tabs-items
              v-model="activeTab"
              style="width: 100%;"
            >
              <v-tab-item
                key="information"
              >
                <v-row>
                  <v-card style="width:700px">
                    <v-card-text>
                      <v-container fluid>
                        <v-text-field
                          v-model="formData.Fullname"
                          :label="$t('Settings.LandOwner.Columns.Fullname')"
                          :rules="[v => !!v || $t('Validation.Required')]"
                          :error-messages="errorMessage"
                          @input="cleanError"
                        />
                        <v-text-field
                          v-model="formData.Address"
                          :label="$t('Settings.LandOwner.Columns.Address')"
                        />
                        <v-textarea
                          v-model="formData.Note"
                          :label="$t('Settings.LandOwner.Columns.Note')"
                          rows="3"
                        />
                        <v-checkbox
                          v-model="formData.IsOwner"
                          :label="$t('Settings.LandOwner.Columns.IsOwner')"
                        />
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-row>
              </v-tab-item>
              <v-tab-item
                key="emails"
                style="height:490px; widows: 600px;"
              >
                <LandOwnerEmailList v-if="id == null ? false : true" />
              </v-tab-item>
              <v-tab-item
                key="phones"
                style="height:490px; widows: 600px;"
              >
                <LandOwnerPhoneList v-if="id == null ? false : true" />
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <ConfirmDialog
      v-model="showValidate"
      :message="$t('Equipments.Equipments.Edit.ValidateMessage')"
      :title="$t('Equipments.Equipments.Edit.ApplyChanges')"
      :no-cancel="true"
      @on-accept="showValidate = false"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import LandOwnerEmailList from '../../landOwnerEmails/components/LandOwnerEmailList.vue';
import LandOwnerPhoneList from '../../landOwnerPhones/components/LandOwnerPhoneList.vue';

export default {
  components: {
    LandOwnerEmailList,
    LandOwnerPhoneList,
    ConfirmDialog,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        Fullname: '',
      },
      show: false,
      errorMessage: '',
      activeTab: null,
      isInformationError: false,
      showValidate: false,
      readMode: !this.$auth.roles.includes('Web.LandOwner.Manage'),
    };
  },
  computed: {
    ...mapState('landOwner/landOwners', ['landOwner', 'nameAlreadyExists']),
  },
  watch: {
    async id() {
      await this.getLandOwner();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.id && this.id != null) {
      await this.getLandOwner();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    async getLandOwner() {
      await this.$store.dispatch('landOwner/landOwnerEmails/setLandOwnerId', this.id);
      await this.$store.dispatch('landOwner/landOwnerPhones/setLandOwnerId', this.id);
      await this.$store.dispatch('landOwner/landOwners/get', { id: this.id });
      this.formData = { ...this.landOwner };
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'LandOwners.List' });
    },
    async submit() {
      this.isInformationError = false;
      if (this.$refs.form.validate()) {
        if (await this.validateName()) {
          if (this.id) {
            await this.$store.dispatch('landOwner/landOwners/update', this.formData);
          } else {
            await this.$store.dispatch('landOwner/landOwners/create', this.formData);
          }

          this.navigateToList();
        }
      }
      this.isInformationError = true;
      this.showValidate = true;
    },
    async validateName() {
      await this.$store.dispatch('landOwner/landOwners/validate', this.formData);
      if (this.nameAlreadyExists) {
        this.errorMessage = i18n.t('Settings.LandOwner.LandOwnerNameExist');
      } else {
        this.errorMessage = '';
        return true;
      }
      return false;
    },
    cleanError() {
      this.errorMessage = '';
    },
  },
};
</script>
