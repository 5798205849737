<template>
  <div>
    <StatusContent :site="params.data" />
  </div>
</template>

<script>
import StatusContent from './StatusContent.vue';

export default {
  components: {
    StatusContent,
  },
};
</script>
