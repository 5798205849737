<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <v-row>
              <div
                class="col-3"
              >
                <datetime-picker
                  v-model="formData.StartDate"
                  :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.StartDate')"
                  :date-picker-props="pickerDateProps"
                  :time-picker-props="pickerTimeProps"
                  :has-clear-button="false"
                  :rules="requiredRule"
                />
              </div>
              <div
                class="col-3"
              >
                <datetime-picker
                  v-model="formData.EndDate"
                  :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.EndDate')"
                  :date-picker-props="pickerDateProps"
                  :time-picker-props="pickerTimeProps"
                  :has-clear-button="true"
                  class="col-3"
                />
              </div>
              <v-checkbox
                v-model="formData.Enabled"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.Enabled')"
                class="col-6"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="formData.SubjectLinePattern"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.SubjectLinePattern')"
                :rules="requiredRule"
                :maxlength="255"
                class="col-6"
              />
              <v-checkbox
                v-model="formData.MatchToSiteLoggerSerialNumber"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.MatchToSiteLogger')"
                class="col-6"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="formData.AttachmentFileNamePattern"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.AttachmentFilenamePattern')"
                :rules="requiredRule"
                :maxlength="255"
                class="col-6"
              />
              <v-checkbox
                v-model="formData.IgnoreLoggerDeploymentDates"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.IgnoreLoggerDeploymentDates')"
                class="col-6"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="formData.SiteNumberPattern"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.SiteNumber')"
                :rules="requiredRule"
                :maxlength="255"
                class="col-3"
              />
              <v-text-field
                v-model="formData.SerialNumberPattern"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.SerialNumber')"
                :rules="requiredRule"
                :maxlength="255"
                class="col-3"
              />
              <v-text-field
                v-model="formData.RecordDatePattern"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.RecordDatePattern')"
                :maxlength="255"
                class="col-3"
              />
              <v-select
                ref="SymDRVersion"
                v-model="formData.SymDrVersionId"
                :items="symDRVersions"
                item-text="Name"
                item-value="SymDRVersionId"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.SymDRVersion')"
                class="col-3"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="formData.CopyRawToFtpFolder"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.CopyRawToFTPFolder')"
                :maxlength="255"
                class="col-4"
              />
              <v-text-field
                v-model="formData.CopyTextToFtpFolder"
                :label="$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.CopyTextToFTPFolder')"
                :maxlength="255"
                class="col-4"
              />
              <div class="col-4">
                <v-card-actions class="justify-end">
                  <v-btn
                    text
                    @click="submit"
                  >
                    {{ $t('Accept') }}
                  </v-btn>
                  <v-btn
                    text
                    @click.stop="close"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                </v-card-actions>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import DatetimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      formData: {
        DataFileImportSettingId: this.id,
        StartDate: this.startDate,
        SymDrVersionId: 4,
        Enabled: true,
      },
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['emailImportRule', 'symDRVersions']),
  },
  created() {
    if (this.emailImportRule) {
      this.formData = { ...this.emailImportRule };
    }
  },
  methods: {
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (this.emailImportRule) {
            await this.$store.dispatch('site/loggerConfigurations/updateEmailImportRule', this.formData);
          } else {
            await this.$store.dispatch('site/loggerConfigurations/createEmailImportRule', this.formData);
          }
          this.close();
        }
      });
    },
    close() {
      this.$emit('close');
      this.$store.dispatch('site/loggerConfigurations/emailImportRule', null);
    },
  },
};
</script>
