// import i18n from '@/plugins/i18n';
import api from '@/api/importal';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  workers: [],
  worker: null,
  token: null,
};

const actions = {
  async list({ commit }) {
    return withWait({ commit }, () => api.get('WorkerApiWorkerList'))
      .then((resp) => commit('setRowData', resp.data));
  },
  async create({ commit }, payload) {
    return withWait({ commit }, () => api.post('WorkerApiWorkerCreate', payload))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async remove({ commit }, { id }) {
    return withWait({ commit }, () => api.post('WorkerApiWorkerRemove', {
      WorkerId: id,
    }))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async update({ commit }, payload) {
    return withWait({ commit }, () => api.post('WorkerApiWorkerUpdate', payload))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async get({ state, commit }, id) {
    if (state.workers.length > 0) {
      commit('setData', state.workers.find((x) => x.WorkerId === id));
    }
    return withWait({ commit }, () => api.get('WorkerApiWorkerList'))
      .then((resp) => commit('setRowData', resp.data))
      .then(() => commit('setData', state.workers.find((x) => x.WorkerId === id)));
  },
  async gettoken({ commit }, id) {
    return withWait({ commit }, () => api.get(`WorkerApiGenerateToken?W=${id}`))
      .then((resp) => commit('setToken', resp.data));
  },
};

const mutations = {
  setRowData(state, payload) {
    state.workers = payload;
  },
  mergeRowData(state, payload) {
    state.workers = payload;
  },
  setData(state, payload) {
    state.worker = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
