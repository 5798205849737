<template>
  <v-card
    v-if="editorActive"
  >
    <v-card-title
      v-if="editor.node.nodeType === 'formula'"
    >
      {{ $t('EditFormula') }}
    </v-card-title>
    <v-card-title
      v-if="editor.node.nodeType === 'input_sensor_xform'"
    >
      {{ $t('EditInputTransform') }}
    </v-card-title>
    <v-card-text>
      {{ $t('HitHelp') }}
      {{ $t('SeeForDetails') }}
    </v-card-text>
    <v-card-text>
      <v-text-field
        v-if="editor.node.nodeType === 'formula'"
        v-model="editor.OutputSymbol"
        :label="$t('OutputSymbol')"
        :error="editor.OutputSymbolErrors.length > 0"
        :error-messages="editor.OutputSymbolErrors"
        :readonly="editor.editorMode !== 'new'"
      />
    </v-card-text>
    <v-card-text
      style="padding-top: 20px;"
    >
      <formulae-monaco-editor
        v-model="editor.FormulaText"
        height="300px"
      />
    </v-card-text>
    <slot />
  </v-card>
</template>

<script>
import FormulaeMonacoEditor from '@/components/monaco/FormulaeMonacoEditor.vue';

export default {
  components: {
    FormulaeMonacoEditor,
  },
  props: {
    init: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    editor: {
      editorMode: null,
      node: null,
      show: false,
      FormulaText: '',
      OutputSymbol: '',
      OutputSymbolErrors: [],
    },
  }),
  computed: {
    editorActive() {
      return this.editor && this.editor.show && this.editor.node && (this.editor.node.nodeType === 'formula' || this.editor.node.nodeType === 'input_sensor_xform');
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.initialize();
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.editor = this.init;
    },
  },
  i18n: {
    messages: {
      en: {
        EditFormula: 'Edit formula',
        EditInputTransform: 'Edit input transform',
        HitHelp: 'Hit [CTRL-SPACE] for help',
        SeeForDetails: 'See DSN-0001_FormulaCompier for details.',
        OutputSymbol: 'Output Symbol',
      },
      fr: {
        EditFormula: 'Éditer la formule',
        EditInputTransform: 'Éditer la transformation entrée',
        HitHelp: 'Peser [CTRL-SPACE] pour aide',
        SeeForDetails: 'Voir DSN-0001_FormulaCompier pour les détails.',
        OutputSymbol: 'Symbole de sortie',
      },
    },
  },
};
</script>

<style>

</style>
