<template>
  <span>{{ Count }} / {{ CountNull }}</span>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    Count() {
      if (this.params.value) {
        return this.params.value
          .filter((x) => x)
          .length;
      }
      return 0;
    },
    CountNull() {
      if (this.params.value) {
        return this.params.value
          .length;
      }
      return 0;
    },
  },
  methods: {
  },
};
</script>
