<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="50vw"
  >
    <v-card style="display: flex; flex-direction: column; height: 100%;">
      <v-card-title>
        <span class="text-h5">{{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Share') }}</span>
      </v-card-title>
      <v-card-subtitle>
        {{ $t('Alert.Manage.AlertList.MultipleShare') }}
      </v-card-subtitle>
      <div style="display: flex; justify-content: space-between; flex: 1;">
        <div style="height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 4px; margin: auto;">
          <v-list dense>
            <v-list-item
              v-for="(user, index) in filteredUsers"
              :key="user.UserId"
              @click="toggleNoShared(index)"
              :class="{ 'selected-item': isNoSharedSelected(index) }"
            >
              <template v-slot:default="{ }">
                <v-icon left>
                  {{ user.icon }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ user.UserName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.group }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list>
        </div>

        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; margin: auto;">
          <v-btn
            icon
            @click="sendToShare()"
            :disabled="selectedNoShared.length === 0"
          >
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            icon
            @click="sendToNoShare()"
            :disabled="selectedShared.length === 0"
          >
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-btn>
        </div>

        <div style="height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 4px; margin: auto">
          <v-list dense>
            <v-list-item
              v-for="(user, index) in sharedWith"
              :key="user.UserId"
              @click="toggleShared(index)"
              :class="{ 'selected-item': isSharedSelected(index) }"
            >
              <template v-slot:default="{ }">
                <v-icon left>
                  {{ user.icon }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ user.UserName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.group }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list>
        </div>
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="onShare"
        >
          {{ $t('Submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
// import importal from '@/api/importal';

export default {
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    sensors: {
      type: Array,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      selectedNoShared: [],
      filteredUsers: [],
      searchInput: null,
      sharedWith: [],
      selectedShared: [],
      sharedUsersList: [],
      sharedAGList: [],
    };
  },
  computed: {
    ...mapState('user', ['users']),
    ...mapState('user', ['accessGroups']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    searchInput(val) {
      this.filteredUsers = this.searchUserGroup(val);
    },
  },
  async created() {
    this.$store.commit('app/pleaseWait', true);
    await this.$store.dispatch('user/list');
    await this.$store.dispatch('user/accessGroupList');
    this.$store.commit('app/pleaseWait', false);
    this.getSharedUsers(this.sensors);
    this.getFilteredUsers();
  },
  methods: {
    toggleNoShared(index) {
      if (this.selectedNoShared.includes(index)) {
        this.selectedNoShared = this.selectedNoShared.filter((i) => i !== index);
      } else {
        this.selectedNoShared.push(index);
      }
    },
    toggleShared(index) {
      if (this.selectedShared.includes(index)) {
        this.selectedShared = this.selectedShared.filter((i) => i !== index);
      } else {
        this.selectedShared.push(index);
      }
    },
    isNoSharedSelected(index) {
      return this.selectedNoShared.includes(index);
    },

    isSharedSelected(index) {
      return this.selectedShared.includes(index);
    },
    sendToShare() {
      if (this.selectedNoShared.length > 0) {
        const selectedUsers = this.selectedNoShared.map((index) => this.filteredUsers[index]);
        this.sharedWith.push(...selectedUsers);
        selectedUsers.forEach((x) => {
          if (x.icon === 'mdi-account-outline') {
            this.sharedUsersList.push(x);
          } else {
            this.sharedAGList.push(x);
          }
        });
        this.filteredUsers = this.filteredUsers.filter(
          (_, index) => !this.selectedNoShared.includes(index),
        );
        this.selectedNoShared = [];
      }
    },
    sendToNoShare() {
      if (this.selectedShared.length > 0) {
        const nonStarredSelected = this.selectedShared.filter((index) => {
          const selectedItem = this.sharedWith[index];
          return selectedItem.icon !== 'mdi-star';
        });

        const removedElements = this.sharedWith.filter(
          (_, index) => nonStarredSelected.includes(index),
        );

        this.sharedWith = this.sharedWith.filter((_, index) => !nonStarredSelected.includes(index));

        this.sharedUsersList = this.sharedUsersList.filter(
          (item) => !removedElements.some(
            (removedItem) => removedItem.UserId === item.UserId && removedItem.UserName === item.UserName,
          ),
        );

        this.sharedAGList = this.sharedAGList.filter(
          (item) => !removedElements.some(
            (removedItem) => removedItem.UserId === item.UserId && removedItem.UserName === item.UserName,
          ),
        );

        this.getFilteredUsers();
        this.selectedShared = [];
      }
    },
    getSharedUsers(AlertList) {
      const ulistAG = [];
      const ulistUsers = [];
      let flag1AG = true;
      let flag1Users = true;

      // Do for users
      AlertList.forEach((d) => {
        if (d.sharedUsers && d.sharedUsers.length > 0) {
          const ulistUsers2 = [];
          d.sharedUsers.forEach((u) => {
            const match = this.users.find((a) => a.UserId === u.id);
            if (match) {
              if (u.id === d.UserId) {
                ulistUsers2.push({
                  UserName: match.UserName, UserId: match.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-star',
                });
              } else {
                ulistUsers2.push({
                  UserName: match.UserName, UserId: match.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-account-outline',
                });
              }
            }
          });

          if (flag1Users) {
            flag1Users = false;
            ulistUsers.push(...ulistUsers2);
          } else {
            const elementsToRemove = [];
            ulistUsers.forEach((element, index) => {
              const existsInUlist2 = ulistUsers2.findIndex(
                (ulist2Element) => ulist2Element.UserId === element.UserId && ulist2Element.group === element.group,
              );

              if (existsInUlist2 === -1) {
                elementsToRemove.push(index);
              }
            });

            // Remove elements from ulist in reverse order to avoid index shifting
            let i = elementsToRemove.length - 1;
            while (i >= 0) {
              ulistUsers.splice(elementsToRemove[i], 1);
              i -= 1; // Decrement the index manually
            }

            // Add new elements from ulist2 to ulist
            ulistUsers2.forEach((element) => {
              const existsInUlist = ulistUsers.findIndex(
                (ulistElement) => ulistElement.UserId === element.UserId && ulistElement.group === element.group,
              );

              if (existsInUlist === -1 && element.icon === 'mdi-star') {
                ulistUsers.push(element);
              } else if (element.icon === 'mdi-star' && ulistUsers[existsInUlist].icon !== 'mdi-star') {
                ulistUsers[existsInUlist].icon = 'mdi-star';
              }
            });
          }
        } else {
          const ulistUsers2 = [];
          const rightUser = this.users.find((a) => a.UserId === d.UserId);
          if (rightUser) {
            ulistUsers2.push({
              UserName: rightUser.UserName, UserId: rightUser.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-star',
            });
          }

          if (flag1Users) {
            flag1Users = false;
            ulistUsers.push(...ulistUsers2);
          } else {
            const elementsToRemove = [];
            ulistUsers.forEach((element, index) => {
              const existsInUlist2 = ulistUsers2.findIndex(
                (ulist2Element) => ulist2Element.UserId === element.UserId && ulist2Element.group === element.group,
              );

              if (existsInUlist2 === -1) {
                elementsToRemove.push(index);
              }
            });

            // Remove elements from ulist in reverse order to avoid index shifting
            let i = elementsToRemove.length - 1;
            while (i >= 0) {
              ulistUsers.splice(elementsToRemove[i], 1);
              i -= 1; // Decrement the index manually
            }

            // Add new elements from ulist2 to ulist
            ulistUsers2.forEach((element) => {
              const existsInUlist = ulistUsers.findIndex(
                (ulistElement) => ulistElement.UserId === element.UserId && ulistElement.group === element.group,
              );

              if (existsInUlist === -1 && element.icon === 'mdi-star') {
                ulistUsers.push(element);
              } else if (element.icon === 'mdi-star' && ulistUsers[existsInUlist].icon !== 'mdi-star') {
                ulistUsers[existsInUlist].icon = 'mdi-star';
              }
            });
          }
        }
      });

      // Do for AG
      AlertList.forEach((d) => {
        if (d.sharedAccessGroups && d.sharedAccessGroups.length > 0) {
          const ulistAG2 = [];
          d.sharedAccessGroups.forEach((u) => {
            const match = this.accessGroups.find((a) => +a.AccessGroupId === +u.id);
            if (match) {
              ulistAG2.push({
                UserName: match.Name, UserId: match.AccessGroupId, group: this.$root.$i18n.t('Dashboard.Share.AccessGroup'), icon: 'mdi-account-group-outline',
              });
            }
          });

          if (flag1AG) {
            flag1AG = false;
            ulistAG.push(...ulistAG2);
          } else {
            const elementsToRemove = [];
            ulistAG.forEach((element, index) => {
              const existsInUlist2 = ulistAG2.findIndex(
                (ulist2Element) => ulist2Element.UserId === element.UserId && ulist2Element.group === element.group,
              );

              if (existsInUlist2 === -1) {
                elementsToRemove.push(index);
              }
            });

            // Remove elements from ulist in reverse order to avoid index shifting
            let i = elementsToRemove.length - 1;
            while (i >= 0) {
              ulistAG.splice(elementsToRemove[i], 1);
              i -= 1; // Decrement the index manually
            }

            // Add new elements from ulist2 to ulist
            ulistAG2.forEach((element) => {
              const existsInUlist = ulistAG.findIndex(
                (ulistElement) => ulistElement.UserId === element.UserId && ulistElement.group === element.group,
              );

              if (existsInUlist === -1 && element.icon === 'mdi-star') {
                ulistAG.push(element);
              } else if (element.icon === 'mdi-star' && ulistAG[existsInUlist].icon !== 'mdi-star') {
                ulistAG[existsInUlist].icon = 'mdi-star';
              }
            });
          }
        } else {
          const ulistAG2 = [];
          if (flag1AG) {
            flag1AG = false;
            ulistAG.push(...ulistAG2);
          } else {
            const elementsToRemove = [];
            ulistAG.forEach((element, index) => {
              const existsInUlist2 = ulistAG2.findIndex(
                (ulist2Element) => ulist2Element.UserId === element.UserId && ulist2Element.group === element.group,
              );

              if (existsInUlist2 === -1) {
                elementsToRemove.push(index);
              }
            });

            // Remove elements from ulist in reverse order to avoid index shifting
            let i = elementsToRemove.length - 1;
            while (i >= 0) {
              ulistAG.splice(elementsToRemove[i], 1);
              i -= 1; // Decrement the index manually
            }

            // Add new elements from ulist2 to ulist
            ulistAG2.forEach((element) => {
              const existsInUlist = ulistAG.findIndex(
                (ulistElement) => ulistElement.UserId === element.UserId && ulistElement.group === element.group,
              );

              if (existsInUlist === -1 && element.icon === 'mdi-star') {
                ulistAG.push(element);
              } else if (element.icon === 'mdi-star' && ulistAG[existsInUlist].icon !== 'mdi-star') {
                ulistAG[existsInUlist].icon = 'mdi-star';
              }
            });
          }
        }
      });

      // Final push
      const ulist = [];
      ulistUsers.forEach((u) => {
        ulist.push(u);
        this.sharedUsersList.push(u);
      });
      ulistAG.forEach((ag) => {
        ulist.push(ag);
        this.sharedAGList.push(ag);
      });

      this.sharedWith = ulist;
    },

    getFilteredUsers() {
      const list = [];
      if (this.sharedWith.length > 0) {
        this.users.forEach((u1) => {
          let alreadyShared = false;
          this.sharedWith.forEach((u2) => {
            // Check if both u1.UserId and u2.UserId are strings or convert them to strings
            const u1UserId = typeof u1.UserId === 'string' ? u1.UserId : String(u1.UserId);
            const u2UserId = typeof u2.UserId === 'string' ? u2.UserId : String(u2.UserId);
            if (u1UserId === u2UserId) {
              alreadyShared = true;
            }
          });
          if (!alreadyShared) {
            list.push({
              UserName: u1.UserName, UserId: u1.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-account-outline',
            });
          }
        });
        this.accessGroups.forEach((g1) => {
          let alreadyShared = false;
          this.sharedWith.forEach((g2) => {
            if (g1.AccessGroupId === g2.UserId) {
              alreadyShared = true;
            }
          });
          if (!alreadyShared) {
            list.push({
              UserName: g1.Name, UserId: g1.AccessGroupId, group: this.$root.$i18n.t('Dashboard.Share.AccessGroup'), icon: 'mdi-account-group-outline',
            });
          }
        });
      }
      this.filteredUsers = list;
    },
    searchUserGroup(search) {
      if (!search || search.length === 0) { return ''; }
      const list = [];
      const us = this.users.filter((u) => u.UserName.toLowerCase().includes(search.toLowerCase()));
      const gs = this.accessGroups.filter(
        (a) => a.Name.toLowerCase().includes(search.toLowerCase()),
      );
      if (us && us.length > 0) {
        list.push({ header: this.$root.$i18n.t('Dashboard.Share.Users') });
        us.forEach((u) => {
          list.push({
            UserName: u.UserName, UserId: u.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-account-outline',
          });
        });
      }
      if (gs && gs.length > 0) {
        list.push({ header: this.$root.$i18n.t('Dashboard.Share.AccessGroups') });
        gs.forEach((g) => {
          list.push({
            UserName: g.Name, UserId: g.AccessGroupId, group: this.$root.$i18n.t('Dashboard.Share.AccessGroup'), icon: 'mdi-account-group-outline',
          });
        });
      }
      return list;
    },
    async refresh(close) {
      this.$store.commit('app/pleaseWait', true);
      setTimeout(async () => {
        // await this.$store.dispatch('dashboard/list');
        this.$store.commit('app/pleaseWait', false);
        if (close) {
          this.show = false;
        }
      }, 2000);
    },
    async withOverlayAsync(body) {
      this.$store.commit('app/pleaseWait', true);
      await body()
        .finally(() => { this.$store.commit('app/pleaseWait', false); });
    },
    async onShare() {
      const AlertList = this.sensors;
      const AllAlertsResp = [];
      await Promise.all(AlertList.map(async (alert) => {
        const origUsers = alert.sharedUsers;
        await this.doShare(origUsers, false, alert, AllAlertsResp);
        const origAG = alert.sharedAccessGroups;
        await this.doShare(origAG, true, alert, AllAlertsResp);
      }));
      this.refresh(true);
      this.$emit('close-alert-share', AllAlertsResp);
    },

    async doShare(origUsers, isGroup, alert, AllAlertsResp) {
      let diff = null;
      let response;
      if (origUsers && origUsers.length > 0) {
        if (this.sharedWith.length === 0) {
          diff = origUsers;
        }
        if (isGroup) {
          diff = origUsers.filter(
            (user) => !this.sharedAGList.some((sharedItem) => String(sharedItem.UserId) === String(user.id)),
          );
        } else {
          diff = origUsers.filter(
            (user) => !this.sharedUsersList.some((sharedItem) => String(sharedItem.UserId) === String(user.id)),
          );
        }
        if (diff && diff.length > 0) {
          await Promise.all(diff.map(async (d) => {
            if (isGroup) {
              // response = await (await importal.post('AlertBasicShareWithGroup', {
              response = await this.$store.dispatch('alert/shareWithGroup', {
                AlertId: alert.BasicAlertConfigId,
                AccessGroupId: +d.id,
                IsShared: false,
              });
            } else {
              // response = await (await importal.post('AlertBasicShareWithUser', {
              response = await this.$store.dispatch('alert/shareWithUser', {
                AlertId: alert.BasicAlertConfigId,
                UserId: d.id,
                IsShared: false,
              });
            }
          }));
        }
      }
      if (isGroup) {
        await Promise.all(this.sharedWith.map(async (u) => {
          if (u.group === this.$root.$i18n.t('Dashboard.Share.AccessGroup')) {
            // response = await (await importal.post('AlertBasicShareWithGroup', {
            response = await this.$store.dispatch('alert/shareWithGroup', {
              AlertId: alert.BasicAlertConfigId,
              AccessGroupId: +u.UserId,
              IsShared: true,
            });
          }
        }));
      } else {
        await Promise.all(this.sharedWith.map(async (u) => {
          if (u.group !== this.$root.$i18n.t('Dashboard.Share.AccessGroup')) {
            // response = await (await importal.post('AlertBasicShareWithUser', {
            response = await this.$store.dispatch('alert/shareWithUser', {
              AlertId: alert.BasicAlertConfigId,
              UserId: u.UserId,
              IsShared: true,
            });
          }
        }));
      }
      AllAlertsResp.push(await response);
    },

    async groupShare(groupId, share, BACId) {
      // await importal.post('AlertBasicShareWithGroup', {
      await this.$store.dispatch('alert/shareWithGroup', {
        AlertId: BACId,
        AccessGroupId: +groupId,
        IsShared: share,
      });
    },
    async userShare(userId, share, BACId) {
      // await importal.post('AlertBasicShareWithUser', {
      await this.$store.dispatch('alert/shareWithUser', {
        AlertId: BACId,
        UserId: userId,
        IsShared: share,
      });
    },
    onCancel() {
      this.show = false;
      this.$emit('close-alert-share');
    },
  },
};
</script>

<style>
.selected-item {
  background-color: #d3d3d336;
}
</style>
