<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width=""
    @click:outside="onClickOutside"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-tabs
          v-model="activeTab"
          fixed-tabs
          style="border-width:0px;"
        >
          <v-tab key="information">
            <div :class="{'v-application error--text': isInformationError }">
              {{ $t('WindServer.Sites.Tabs.Information') }}
            </div>
          </v-tab>
          <v-tab
            v-if="id == null ? false : true"
            key="landowners"
          >
            <slot>
              {{ $t('WindServer.Sites.Tabs.LandOwners') }}
            </slot>
          </v-tab>
          <v-tab
            v-if="id == null ? false : true"
            key="notes"
          >
            <slot>
              {{ $t('WindServer.Sites.Tabs.Notes') }}
            </slot>
          </v-tab>
          <v-tab
            v-if="id == null ? false : true"
            key="maintenance"
          >
            <slot>
              {{ $t('WindServer.Sites.Tabs.MaintenanceRecords') }}
            </slot>
          </v-tab>
          <v-tab
            v-if="id == null ? false : true"
            key="attachements"
          >
            <slot>
              {{ $t('WindServer.Sites.Tabs.Attachments') }}
            </slot>
          </v-tab>
          <v-tab
            v-if="id == null ? false : true"
            key="siteConfigurations"
          >
            <slot>
              {{ $t('WindServer.Sites.Tabs.SiteConfigurations') }}
            </slot>
          </v-tab>
          <v-tab
            v-if="id == null || !$auth.roles.includes('Web.Site.DataTrace.Manage') ? false : true"
            key="dataTrace"
            @click="onClickDataTrace"
          >
            <slot>
              {{ $t('WindServer.Sites.Tabs.DataTrace') }}
            </slot>
          </v-tab>
          <v-tabs-items
            v-model="activeTab"
            style="width: 100%;"
          >
            <v-tab-item
              key="information"
            >
              <v-row
                style="padding:35px;padding-top:57px;"
              >
                <v-card-text>
                  <v-container fluid>
                    <v-row
                      style="margin:0px;"
                    >
                      <div
                        class="col col-7"
                        style="padding:0px;"
                      >
                        <div class="row">
                          <v-select
                            ref="ownerSelector"
                            v-model="formData.Owner"
                            :label="$t('WindServer.Sites.Properties.Owner')"
                            :items="tenants"
                            :rules="[v => !!v || $t('Validation.Required')]"
                            item-text="Name"
                            item-value="TenantId"
                            class="col-6"
                          />
                          <v-text-field
                            ref="txtName"
                            v-model="formData.Name"
                            :label="$t('WindServer.Sites.Properties.Name')"
                            class="col-6"
                            :rules="[v => !!v || $t('Validation.Required')]"
                          />
                        </div>
                        <div class="row">
                          <v-select
                            ref="siteTypeSelector"
                            v-model="formData.SiteType"
                            :label="$t('WindServer.Sites.Properties.SiteType')"
                            :items="siteTypes"
                            :rules="[v => !!v || $t('Validation.Required')]"
                            item-text="NameI18N"
                            item-value="SiteTypeId"
                            class="col-6"
                          />
                          <v-text-field
                            v-if="!isSiteStorageType"
                            ref="txtLandType"
                            v-model="formData.LandType"
                            :label="$t('WindServer.Sites.Properties.LandType')"
                            :rules="[v => !!v || $t('Validation.Required')]"
                            class="col-6"
                          />
                        </div>
                        <div
                          v-if="isSiteStorageType"
                          class="row"
                        >
                          <v-select
                            id="countrySelector"
                            ref="countrySelector"
                            v-model="formData.Country"
                            :label="$t('WindServer.Sites.Properties.Country')"
                            :items="countries"
                            :rules="[v => !!v || $t('Validation.Required')]"
                            item-text="Name"
                            item-value="CountryId"
                            class="col-4"
                            @change="onCountrySelected()"
                          />
                          <v-select
                            ref="provinceSelector"
                            v-model="formData.Province"
                            :label="$t('WindServer.Sites.Properties.Province')"
                            :items="provincesStatesByCountry"
                            :rules="[v => !!v || $t('Validation.Required')]"
                            item-text="Name"
                            item-value="ProvinceStateId"
                            class="col-4"
                          />
                          <v-select
                            v-model="formData.TimeZone"
                            :rules="[v => !!v || $t('Validation.Required')]"
                            :label="$t('WindServer.Sites.Properties.TimeZone')"
                            :items="timezones"
                            required
                            item-text="Name"
                            item-value="TimeZoneId"
                            class="col-4"
                          />
                        </div>
                        <div
                          v-if="isSiteStorageType"
                          class="row"
                        >
                          <v-textarea
                            v-model="formData.Details"
                            :label="$t('WindServer.Sites.Properties.Details')"
                            :rules="[v => !!v || $t('Validation.Required')]"
                            class="col-12"
                          />
                        </div>
                        <div v-if="!isSiteStorageType">
                          <div class="row">
                            <v-text-field
                              ref="txtNumber"
                              v-model="formData.Number"
                              type="number"
                              :label="$t('WindServer.Sites.Properties.Number')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-6"
                            />
                            <v-text-field
                              ref="txtNnemonic"
                              v-model="formData.Mnemonic"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              :label="$t('WindServer.Sites.Properties.Mnemonic')"
                              class="col-6"
                            />
                          </div>
                          <div class="row">
                            <v-select
                              id="countrySelector"
                              ref="countrySelector"
                              v-model="formData.Country"
                              :label="$t('WindServer.Sites.Properties.Country')"
                              :items="countries"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              item-text="Name"
                              item-value="CountryId"
                              class="col-6"
                              @change="onCountrySelected()"
                            />
                            <v-select
                              ref="provinceSelector"
                              v-model="formData.Province"
                              :label="$t('WindServer.Sites.Properties.Province')"
                              :items="provincesStatesByCountry"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              item-text="Name"
                              item-value="ProvinceStateId"
                              class="col-6"
                            />
                          </div>
                          <div class="row">
                            <v-text-field
                              v-model="formData.Municipality"
                              :label="$t('WindServer.Sites.Properties.Municipality')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-6"
                              type="Text"
                            />
                            <v-text-field
                              ref="txtLandLocation"
                              v-model="formData.LandLocation"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              :label="$t('WindServer.Sites.Properties.LandLocation')"
                              class="col-6"
                            />
                          </div>
                          <div class="row">
                            <v-text-field
                              ref="txtElevation"
                              v-model="formData.Elevation"
                              min="-500"
                              max="10000"
                              oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;if(Number(this.value) < Number(this.min)) this.value = this.min;"
                              type="number"
                              :label="$t('WindServer.Sites.Properties.Elevation')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-4"
                            />
                            <v-text-field
                              ref="txtDefaultAirDensity"
                              v-model="formData.DefaultAirDensity"
                              type="number"
                              :label="$t('WindServer.Sites.Properties.DefaultAirDensity')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-4"
                              min="0.00"
                              max="10.00"
                              oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;if(Number(this.value) < Number(this.min)) this.value = this.min;"
                              onfocusout="this.value = parseFloat(this.value).toFixed(2);"
                            />
                            <v-select
                              ref="geodeticDatumSelector"
                              v-model="formData.GeodeticDatum"
                              :label="$t('WindServer.Sites.Properties.GeodeticDatum')"
                              :items="geodeticDatums"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              item-text="Name"
                              item-value="GeodeticDatumId"
                              class="col-4"
                            />
                          </div>
                          <div class="row">
                            <v-text-field
                              ref="txtUTMGridZone"
                              v-model="formData.UTMGridZone"
                              :label="$t('WindServer.Sites.Properties.UTMGridZone')"
                              class="col-4"
                              :rules="[v => !!v || $t('Validation.Required')]"
                            />
                            <v-text-field
                              ref="txtUTMEasting"
                              v-model="formData.UTMEasting"
                              type="number"
                              :label="$t('WindServer.Sites.Properties.UTMEasting')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-4"
                            />
                            <v-text-field
                              v-model="formData.UTMNorthing"
                              type="number"
                              :label="$t('WindServer.Sites.Properties.UTMNorthing')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-4"
                            />
                          </div>
                          <div class="row">
                            <v-text-field
                              ref="txtLatitude"
                              v-model="formData.Latitude"
                              type="number"
                              min="-90"
                              max="90"
                              oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;if(Number(this.value) < Number(this.min)) this.value = this.min;"
                              :label="$t('WindServer.Sites.Properties.Latitude')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-4"
                            />
                            <v-text-field
                              ref="txtLongitude"
                              v-model="formData.Longitude"
                              type="number"
                              min="-180"
                              max="180"
                              oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;if(Number(this.value) < Number(this.min)) this.value = this.min;"
                              :label="$t('WindServer.Sites.Properties.Longitude')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-4"
                            />
                            <v-text-field
                              v-model="formData.MagneticDeclination"
                              type="number"
                              min="0"
                              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
                              :label="$t('WindServer.Sites.Properties.MagneticDeclination')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-4"
                            />
                          </div>
                          <div class="row">
                            <v-select
                              v-model="formData.TimeZone"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              :label="$t('WindServer.Sites.Properties.TimeZone')"
                              :items="timezones"
                              required
                              item-text="Name"
                              item-value="TimeZoneId"
                              class="col-4"
                            />
                            <v-text-field
                              ref="txtShelterBoxLockCode"
                              v-model="formData.ShelterBoxLockCode"
                              :label="$t('WindServer.Sites.Properties.ShelterBoxLockCode')"
                              :maxlength="25"
                              class="col-4"
                            />
                            <v-text-field
                              v-model="formData.DataDeliveryDelay"
                              type="number"
                              :label="$t('WindServer.Sites.Properties.DataDeliveryDelay')"
                              :rules="[v => !!v || $t('Validation.Required')]"
                              class="col-4"
                            />
                          </div>
                          <div class="row">
                            <v-col
                              cols="4"
                            >
                              <datetime-picker
                                v-model="formData.InstallationDate"
                                :label="$t('WindServer.Sites.Properties.InstallationDate')"
                                :date-picker-props="pickerDateProps"
                                :time-picker-props="pickerTimeProps"
                                :has-clear-button="false"
                                :rules="[v => !!v || $t('Validation.Required')]"
                              />
                            </v-col>
                            <v-col
                              cols="4"
                            >
                              <datetime-picker
                                v-model="formData.RetirementDate"
                                :label="$t('WindServer.Sites.Properties.RetirementDate')"
                                :date-picker-props="pickerDateProps"
                                :time-picker-props="pickerTimeProps"
                                :has-clear-button="false"
                              />
                            </v-col>
                            <v-col
                              cols="4"
                            >
                              <datetime-picker
                                v-model="formData.ImportDisabledAsOf"
                                :label="$t('WindServer.Sites.Properties.ImportDisabledAsOf')"
                                :date-picker-props="pickerDateProps"
                                :time-picker-props="pickerTimeProps"
                                :has-clear-button="false"
                              />
                            </v-col>
                          </div>
                          <div class="row">
                            <v-checkbox
                              v-model="formData.IsFenced"
                              class="col-4"
                              :label="$t('WindServer.Sites.Properties.IsFenced')"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col col-5"
                        style="padding:0px;padding-left: 20px;"
                      >
                        <div
                          class="row"
                          style="height: 882px;margin:0px;background-color: darkgray;"
                        >
                          <l-map
                            ref="myMap"
                            style="height: 100%; width: 100%;"
                            :zoom="zoom"
                            :center="center"
                            @ready="onMapReady()"
                          >
                            <l-tile-layer
                              :url="url"
                              :attribution="attribution"
                              :options="{ className: 'map-tiles' }"
                            />
                            <l-marker
                              ref="mark"
                              :lat-lng="center"
                              :icon="markerIcon"
                            />
                          </l-map>
                        </div>
                      </div>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-row>
            </v-tab-item>
            <v-tab-item
              key="landowners"
              class="site-modal"
            >
              <SiteLandOwnerList />
            </v-tab-item>
            <v-tab-item
              key="notes"
              class="site-modal"
            >
              <SiteNoteList />
            </v-tab-item>
            <v-tab-item
              key="maintenance"
              class="site-modal"
            >
              <SiteNoteList maintenance-records />
            </v-tab-item>
            <v-tab-item
              key="attachments"
              class="site-modal"
            >
              <SiteAttachmentList />
            </v-tab-item>
            <v-tab-item
              key="siteConfigurations"
              class="site-modal"
            >
              <SiteConfigurationsList />
            </v-tab-item>
            <v-tab-item
              v-if="id == null || !$auth.roles.includes('Web.Site.DataTrace.Manage') ? false : true"
              key="dataTrace"
              class="site-modal"
            >
              <DataTrace />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode && activeTab == 0"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            v-if="!readMode && activeTab == 0"
            text
            @click.stop="onClickCancel"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <ConfirmDialog
      v-model="showValidate"
      :message="$t('ValidateMessage')"
      :title="$t('ApplyChanges')"
      :no-cancel="true"
      @on-accept="showValidate = false"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import L from 'leaflet';
import DatetimePicker from '@/components/DatetimePicker.vue';
import i18n from '@/plugins/i18n';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import SiteNoteList from '../../siteNotes/components/SiteNoteList.vue';
import SiteAttachmentList from '../../siteAttachments/components/SiteAttachmentList.vue';
import SiteConfigurationsList from '../../siteConfigurations/components/SiteConfigurationsList.vue';
import SiteLandOwnerList from '../../siteLandOwners/components/SiteLandOwnerList.vue';
import DataTrace from '../../dataTrace/components/DataTrace.vue';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    SiteNoteList,
    SiteConfigurationsList,
    ConfirmDialog,
    SiteAttachmentList,
    SiteLandOwnerList,
    DatetimePicker,
    DataTrace,
  },
  props: {
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 7,
      bounds: null,
      TabValue: 'Information',
      activeTab: null,
      isInformationError: false,
      showValidate: false,
      dtInstall: false,
      dtRetired: false,
      dtImport: false,
      readMode: !this.$auth.roles.includes('Web.Site.Manage'),

      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  computed: {
    ...mapState('site/sites', ['site', 'tenants', 'timezones', 'saveSucceeded', 'siteTypes', 'geodeticDatums', 'countries', 'provincesStatesByCountry']),
    center() {
      let lat = 0;
      let lon = 0;
      if (this.formData.Latitude !== undefined && this.formData.Latitude !== null) {
        lat = parseFloat(this.formData.Latitude);
      }
      if (this.formData.Longitude !== undefined && this.formData.Longitude !== null) {
        lon = parseFloat(this.formData.Longitude);
      }
      return [lat, lon];
    },
    markerIcon() {
      return new L.DivIcon(this.markerOptions);
    },
    markerOptions() {
      return {
        html: '<i class="mdi mdi-map-marker mdi-48px" style="color:crimson;"></i>',
        iconSize: [48, 48],
        iconAnchor: [24, 48],
        popupAnchor: [0, -24],
        className: '',
      };
    },
    isIdaSiteGuid() {
      // Is this.id a site Guid
      return this.isGuid(this.id);
    },
    isSiteStorageType() {
      const siteStorageType = 4; // see SiteTypes table
      return (this.formData.SiteType === siteStorageType);
    },
  },
  watch: {
    async id() {
      await this.getSite();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.id && this.id != null) {
      await this.getSite();
    } else {
      // Need to null the site because previous site updated or created
      // interfer with
      await this.$store.dispatch('site/sites/setSite', null);
      this.show = true;
    }
    await this.$store.dispatch('site/sites/getSiteType');
    await this.$store.dispatch('site/siteAttachments/setSelectedFolderId', 0);
    await this.$store.dispatch('site/siteAttachments/setNestedFolders', []);
    await this.$store.dispatch('site/siteAttachments/setSelectedFolderName', i18n.t('WindServer.Sites.Attachments.TreeViewRootLabel'));
  },
  methods: {
    async getSite() {
      await this.$store.dispatch('site/siteNotes/setSiteId', this.id);
      await this.$store.dispatch('site/siteAttachments/setSiteId', this.id);
      if (this.isIdaSiteGuid) {
        await this.$store.dispatch('site/sites/get', { siteGuid: this.id });
      } else {
        await this.$store.dispatch('site/sites/get', { siteId: this.id });
      }

      this.formData = { ...this.site };
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'Sites.List' });
    },
    async submit() {
      this.isInformationError = false;
      if (this.$refs.form.validate()) {
        if (this.id) {
          await this.$store.dispatch('site/sites/update', this.formData);
        } else {
          await this.$store.dispatch('site/sites/create', this.formData);
        }
        if (this.saveSucceeded) {
          this.navigateToList();
        }
      } else {
        this.isInformationError = true;
        this.showValidate = true;
      }
    },
    onCountrySelected() {
      this.$store.dispatch('site/sites/setProvincesStatesByCountry', this.formData.Country);
    },
    onMapReady() {
      this.map = this.$refs.myMap.mapObject;
      setTimeout(() => { this.map.invalidateSize(true); }, 100);
    },
    tabChange(changeto) {
      this.TabValue = changeto;
    },
    isGuid(value) {
      const isGuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
      return isGuidRegex.test(value);
    },
    onClickOutside() {
      this.closeDialog();
    },
    onClickCancel() {
      this.closeDialog();
    },
    closeDialog() {
      this.show = false;
    },
    async onClickDataTrace() {
      await this.$store.dispatch('site/dataTrace/init', this.id);
    },
  },
};
</script>

<style>
.site-modal {
  height: 78vh;
}
</style>
