<template>
  <v-container
    fluid
    fill-height
  >
    <v-toolbar dense>
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Refresh')"
        :icon-name="'mdi-refresh'"
        @click="RefreshTables"
      />
    </v-toolbar>
    <v-row class="align-self-start">
      <v-col>
        <v-tabs
          v-model="selectedTab"
        >
          <v-tab
            v-if="$auth.roles.includes('Web.Tenant.Manage')"
          >
            {{ $t('Settings.AccessControl.Header.ManageRoles') }}
          </v-tab>
          <v-tab
            v-if="$auth.roles.includes('Web.AccessGroup.Manage')"
          >
            {{ $t('Settings.AccessControl.Header.GrantRevokeRoles') }}
          </v-tab>
          <v-tab
            v-if="$auth.roles.includes('Web.AccessGroup.Manage')"
          >
            {{ $t('Settings.AccessControl.Header.ManageAccessGroups') }}
          </v-tab>
          <v-tab
            v-if="$auth.roles.includes('Web.AccessGroup.Manage')"
          >
            {{ $t('Settings.AccessControl.Header.GrantRevokeAccessGroups') }}
          </v-tab>
          <v-tab
            v-if="$auth.roles.includes('Web.AccessGroup.Manage')"
          >
            {{ $t('Settings.AccessControl.Header.ManageOrganizationalUnits') }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items
      v-model="selectedTab"
      style="height: calc(100vh - 120px); width: 100%;"
    >
      <ManageRolesTabItem
        :refresh="Refresh"
      />
      <GrantRolesTabItem
        :refresh="Refresh"
      />
      <ManageAccessGroupsTabItem
        :refresh="Refresh"
      />
      <GrantAccessGroupsTabItem
        :refresh="Refresh"
      />
      <ManageOrganizationalUnitsTabItem
        :refresh="Refresh"
      />
    </v-tabs-items>
  </v-container>
</template>

<script>
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import ManageRolesTabItem from './components/ManageRolesTabItem.vue';
import ManageAccessGroupsTabItem from './components/ManageAccessGroupsTabItem.vue';
import ManageOrganizationalUnitsTabItem from './components/ManageOrganizationalUnitsTabItem.vue';
import GrantAccessGroupsTabItem from './components/GrantAccessGroupsTabItem.vue';
import GrantRolesTabItem from './components/GrantRolesTabItem.vue';

export default {
  name: 'SettingList',
  components: {
    ManageRolesTabItem,
    ManageAccessGroupsTabItem,
    ManageOrganizationalUnitsTabItem,
    GrantAccessGroupsTabItem,
    GrantRolesTabItem,
    ToolbarButtonWithTooltip,
  },
  data: () => ({
    sources: {
    },
    selectedTab: null,
    Refresh: 0,
  }),
  methods: {
    RefreshTables() {
      this.Refresh += 1;
    },
  },
};
</script>

<style lang="scss">
</style>
