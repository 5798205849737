<template>
  <div class="lang-dropdown">
    <v-tooltip
      bottom
    >
      <template #activator="{ on, attrs }">
        <div
          multiple
          v-on="on"
        >
          <v-select
            v-model="currentLocale"
            :items="languageArray.map((x) => ({ text: x.toUpperCase(), value: x}))"
            v-bind="attrs"
            class="cursor-pointer"
            style="width: 57px"
            single-line
            hide-details
            v-on="on"
          />
        </div>
      </template>
      <span>{{ $t('Header.SwitchLanguage') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { languages } from '@/plugins/i18n';

export default {
  data() {
    return {
      currentLocale: this.$i18n.locale,
      languageArray: languages,
    };
  },
  watch: {
    currentLocale(newValue) {
      this.$store.dispatch('app/changeLocale', newValue);
    },
  },
};
</script>

<style lang="scss">
.lang-dropdown {
  font-size: 1rem;
  font-weight: 600;
  div.v-input {
    div.v-input__control {
      div.v-text-field__details {
        display: none;
      }
      div.v-input__slot::before {
        border: none;
      }
      div.v-input__slot {
        margin: 0;
      }
    }
  }
}
</style>
