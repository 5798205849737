<template>
  <v-card
    v-if="editorActive"
  >
    <v-card-title>{{ $t('SelectInputSensor.') }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col sm="6">
          <v-text-field
            v-model="form.searchSensorTerm"
            hint="Use * wildcard to specify *ending or *contains*, default to starting."
            :label="$t('sensor')"
            prepend-icon="mdi-magnify"
            append-icon="mdi-arrow-right-circle"
            @click:append="searchSensor"
            @keyup.enter="searchSensor"
          />
        </v-col>
        <v-col sm="6">
          <v-text-field
            v-model="form.searchInstrumentTerm"
            hint="Use * wildcard to specify *ending or *contains*, default to starting."
            label="instrument"
            prepend-icon="mdi-magnify"
            append-icon="mdi-arrow-right-circle"
            @click:append="searchSensor"
            @keyup.enter="searchSensor"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-virtual-scroll
      :key="form.itemList.Id"
      :items="form.itemList"
      :item-height="28"
      height="260"
      @scroll.native="scrolling"
    >
      <template #default="{ item }">
        <v-list-item
          :key="item.Id"
          class="sensor-search-item pt-0 pb-0"
          style="min-height: 24px;"
          @click="selectSensor(item)"
        >
          <v-list-item-icon
            class="pt-0 pb-0 mt-0 mb-0"
          >
            <v-icon v-if="item.T == 'S'">
              mdi-alpha-s-box-outline
            </v-icon>
            <v-icon v-if="item.T == 'V'">
              mdi-alpha-v-box-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            {{ item.DisplayName || item.Name }}
          </v-list-item-content>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            <v-badge
              :content="item.InstrumentName"
              inline
            />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
    <slot />
  </v-card>
</template>

<script>
import importal from '@/api/importal';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default {
  components: {
  },
  props: {
    init: {
      type: Object,
      default: () => {},
      required: true,
    },
    itemIncrement: {
      type: Number,
      default: () => 20,
      required: false,
    },
    initialSearch: {
      type: String,
      default: () => (''),
      required: false,
    },
  },
  data: () => ({
    editor: {
      editorMode: null,
      node: {
        SensorId: null,
        SensorName: null,
        InstrumentName: null,
        nodeType: null,
      },
    },
    form: {
      searchSensorTerm: '',
      searchInstrumentTerm: '',
      itemList: [],
      selectedSensors: [],
    },
  }),
  computed: {
    editorActive() {
      return this.editor && this.editor.show && this.editor.node && (this.editor.node.nodeType === 'input_sensor' || this.editor.node.nodeType === 'input_vector');
    },
    showSensors() {
      return this.editor.node.nodeType === 'input_sensor';
    },
    showVectors() {
      return this.editor.node.nodeType === 'input_vector';
    },
    sensorCount() {
      if (this.showSensors) {
        return this.itemIncrement.toString();
      }
      return '0';
    },
    sensorOffset() {
      return this.form.itemList.filter((x) => x.T === 'S').length.toString();
    },
    vectorCount() {
      if (this.showVectors) {
        return this.itemIncrement.toString();
      }
      return '0';
    },
    vectorOffset() {
      return this.form.itemList.filter((x) => x.T === 'V').length.toString();
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.initialize();
      }
    },
    editor() {
      if (this.editorActive) {
        this.$emit('editor-update', this.editor);
      }
    },
  },
  async created() {
    this.scrolling = debounce(this.scrolling);
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.editor = this.init;
      this.form.searchTerm = this.initialSearch;
      const query = `SensorList?so=0&sc=${this.sensorCount}&vo=0&vc=${this.vectorCount}`
        + `&searchSensor=${this.form.searchSensorTerm}`
        + `&searchInstrument=${this.form.searchInstrumentTerm}`;
      this.form.itemList = await (await importal.get(query)).data;
    },
    async scrolling(event) {
      const element = event.currentTarget || event.target;
      if (element && element.scrollHeight - element.scrollTop === element.clientHeight) {
        // this.$emit('scroll-end');
        await this.showMore();
      }
    },
    async showMore() {
      this.spamfree = true;
      const newItems = await (await importal.get(`SensorList?so=${this.sensorOffset}&sc=${this.sensorCount}`
        + `&vo=${this.vectorOffset}&vc=${this.vectorCount}`
        + `&searchSensor=${this.form.searchSensorTerm}`
        + `&searchInstrument=${this.form.searchInstrumentTerm}`)).data;
      while (newItems.length > 0) {
        this.form.itemList.push(newItems[0]);
        newItems.shift();
      }
      this.spamfree = false;
    },
    async searchSensor() {
      this.form.itemList = [];
      this.form.itemList = await (await importal.get(`SensorList?so=0&sc=${this.sensorCount}`
        + `&vo=0&vc=${this.vectorCount}`
        + `&searchSensor=${this.form.searchSensorTerm}`
        + `&searchInstrument=${this.form.searchInstrumentTerm}`)).data;
    },
    selectSensor(item) {
      if (item.T === 'S') {
        this.editor.node.SensorId = item.Id;
        this.editor.node.SensorName = item.Name;
        this.editor.node.InstrumentName = item.InstrumentName;
        this.$emit('editor-update', this.editor);
      } else if (item.T === 'V') {
        this.editor.node.VectorSensorId = item.Id;
        this.editor.node.VectorName = item.Name;
        this.editor.node.InstrumentName = item.InstrumentName;
        this.$emit('editor-update', this.editor);
      }
    },
  },
  i18n: {
    messages: {
      en: {
        SelectInputSensor: 'Select Input Sensor',
        sensor: 'sensor',
      },
      fr: {
        SelectInputSensor: 's�lectionner le capteur entr�e',
        sensor: 'capteur',
      },
    },
  },
};
</script>

<style>

</style>
