/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsUserGrantRo extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          maxWidth: 30,
          resizable: false,
          cellStyle: { padding: 0 },
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            icon: 'mdi-cog',
            noConfirm: true,
            clicked: (event) => this.onEditButton(event.data),
          },
        },
        {
          field: 'UserName', minWidth: 200, headerName: i18n.t('Settings.AccessControl.GrantRevokeRoles.UserName'),
        },
        {
          field: 'UserId', headerName: i18n.t('Settings.AccessControl.GrantRevokeRoles.UserId'),
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    return {
      getRows: (params) => {
        importal.get('OpUserList')
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  onEditButton(data) {
    this.vue.selection.user = data;
  }
}
