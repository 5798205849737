import api from '@/api/importal';

const state = {
  anchorType: null,
  anchorTypes: [],
  nameAlreadyExists: false,
};

const actions = {
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('AnchorTypesCreate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`AnchorTypesGet?id=${payload.id}`);
    commit('anchorType', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('AnchorTypesList');
    commit('anchorTypes', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`AnchorTypesRemove?id=${payload.id}`);
    commit('anchorType', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('AnchorTypesUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('nameAlreadyExists', false);
    const response = await api.get(`AnchorTypesValidate?id=${payload.AnchorTypeId != null ? payload.AnchorTypeId : 0}&name=${payload.TypeName}`);
    if (response.data != null) {
      commit('nameAlreadyExists', true);
    }
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  anchorType(state, payload) {
    state.anchorType = payload;
  },
  anchorTypes(state, payload) {
    state.anchorTypes = payload;
  },
  nameAlreadyExists(state, payload) {
    state.nameAlreadyExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
