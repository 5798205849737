import api from '@/api/importal';

const state = {
  weekOf: null,
  qcAssignations: [],
  qcTenants: [],
  qcTenantsAllCount: null,
  qcTenantsAssignedCount: null,
  qcTenantsRemainingCount: null,
  qcAnalysts: [],
  qcTechnicians: [],
  selectedTenantIds: [],
  selectedTenant: '',
  selectedTenantAnalystId: null,
  selectedTenantTechnicianId: null,
};

const actions = {

  async techniciansList({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('QCScheduleTechniciansList');
    commit('qcTechnicians', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async analystsList({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('QCScheduleAnalystsList');
    commit('qcAnalysts', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async assignationsList({ commit }) {
    if (state.weekOf != null) {
      commit('app/pleaseWait', true, { root: true });
      const response = await api.get(`QCScheduleAssignationsList?weekOf=${state.weekOf}`);
      commit('qcAssignations', response.data);
      commit('app/pleaseWait', false, { root: true });
    }
  },

  async tenantsList({ commit }) {
    if (state.weekOf != null) {
      commit('app/pleaseWait', true, { root: true });
      const response = await api.get(`QCScheduleTenantsList?weekOf=${state.weekOf}`);
      commit('qcTenants', response.data);
      commit('app/pleaseWait', false, { root: true });
    }
  },

  async assignation({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCScheduleAssignationsUpdate', {
      WeekOf: state.weekOf,
      AnalystId: payload.Analyst,
      TechnicianId: payload.Technician,
      AccessGroupIds: state.selectedTenantIds,
    });
    commit('app/pleaseWait', false, { root: true });
    await dispatch('tenantsList');
  },

  setWeekOf({ commit }, payload) {
    commit('weekOf', payload);
  },

  setSelectedTenantIds({ commit }, payload) {
    commit('selectedTenantIds', payload);
  },

  setSelectedTenant({ commit }, payload) {
    commit('selectedTenant', payload);
  },
  setSelectedTenantAnalystId({ commit }, payload) {
    commit('selectedTenantAnalystId', payload);
  },
  setSelectedTenantTechnicianId({ commit }, payload) {
    commit('selectedTenantTechnicianId', payload);
  },
};

const mutations = {
  weekOf(state, payload) {
    state.weekOf = payload;
  },
  qcAnalysts(state, payload) {
    state.qcAnalysts = payload;
  },
  qcTechnicians(state, payload) {
    state.qcTechnicians = payload;
  },
  qcAssignations(state, payload) {
    state.qcAssignations = payload;
  },
  qcTenants(state, payload) {
    state.qcTenants = payload;
    state.qcTenantsAllCount = payload.length;
    const remaining = payload.filter((p) => p.AnalystId === null && p.TechnicianId === null);
    state.qcTenantsRemainingCount = remaining.length;
    state.qcTenantsAssignedCount = payload.length - remaining.length;
  },
  selectedTenantIds(state, payload) {
    state.selectedTenantIds = payload;
  },
  selectedTenant(state, payload) {
    state.selectedTenant = payload;
  },
  selectedTenantAnalystId(state, payload) {
    state.selectedTenantAnalystId = payload;
  },
  selectedTenantTechnicianId(state, payload) {
    state.selectedTenantTechnicianId = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
