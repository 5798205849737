import api from '@/api/importal';

const state = {
  qcInspectionTemplate: null,
  qcInspectionTemplates: [],
  filters: {},
  nameExists: false,
};

const actions = {
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCInspectionTemplatesCreate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCInspectionTemplatesGet?id=${payload.id}`);
    commit('qcInspectionTemplate', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.post('QCInspectionTemplatesList', state.filters);
    commit('qcInspectionTemplates', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`QCInspectionTemplatesRemove?id=${payload.id}`);
    commit('qcInspectionTemplate', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('QCInspactionTemplatesUpdate', {
      QCInspectionTemplateId: payload.QCInspectionTemplateId,
      Name: payload.Name,
    });
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('nameExists', false);
    const response = await api.get(`QCInspectionTemplatesValidate?id=${state.qcInspectionTemplate == null ? null : state.qcInspectionTemplate.QCInspectionTemplateId}&name=${payload.Name}`);
    commit('nameExists', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  setFilters({ commit }, payload) {
    commit('filters', payload);
  },

  setQcInspectionTemplate({ commit }, payload) {
    commit('qcInspectionTemplate', payload);
  },
};

const mutations = {
  qcInspectionTemplate(state, payload) {
    state.qcInspectionTemplate = payload;
  },
  qcInspectionTemplates(state, payload) {
    state.qcInspectionTemplates = payload;
  },
  filters(state, payload) {
    state.filters = payload;
  },
  nameExists(state, payload) {
    state.nameExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
