<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text class="pt-3">
          <v-text-field
            v-model="formData.channelCode"
            :label="$t('Equipments.Equipments.Edit.ChannelCode')"
            :autofocus="true"
            :rules="[v => !!v || $t('Validation.Required')]"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="onCancel"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.formData = null;
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$emit('on-accept', this.formData.channelCode);
        this.formData.channelCode = null;
        this.$refs.form.reset();
      }
    },
    onCancel() {
      this.$emit('input', false);
      this.formData.channelCode = null;
      this.$refs.form.reset();
    },
  },
};
</script>
