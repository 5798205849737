<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-card-text>
          <v-container fluid>
            <v-select
              v-model="formData.QCTaskTypeId"
              :label="$t('QualityCheck.QCTasks.Tasks.TaskType')"
              :items="qcTaskTypes"
              :rules="[v => !!v || $t('Validation.Required')]"
              item-text="Name"
              item-value="TaskTypeId"
            />
            <v-select
              v-model="formData.SiteId"
              :label="$t('QualityCheck.QCTasks.Tasks.MonitoringSite')"
              :items="monitoringSites"
              :rules="[v => !!v || $t('Validation.Required')]"
              item-text="Name"
              item-value="Id"
            />
            <v-select
              v-model="formData.AssignedToUserId"
              :label="$t('QualityCheck.QCTasks.Tasks.AssignedTo')"
              :items="users"
              :rules="[v => !!v || $t('Validation.Required')]"
              item-text="DisplayName"
              item-value="UserId"
            />
            <v-select
              v-model="formData.QCInspectionTemplateId"
              :label="$t('QualityCheck.QCTasks.Tasks.InspectionTemplate')"
              :items="qcInspectionTemplates"
              :rules="[v => !!v || $t('Validation.Required')]"
              item-text="Name"
              item-value="Id"
            />
            <v-checkbox
              v-model="recurring"
              :label="$t('QualityCheck.QCTasks.Tasks.Recurring')"
              type="checkbox"
            />
            <v-select
              ref="recurrence"
              v-if="this.recurring"
              v-model="formData.QCTaskRecurrenceId"
              :label="$t('QualityCheck.QCTasks.Tasks.Recurrence')"
              :items="qcTaskRecurrences"
              :rules="[recurrenceValidation]"
              item-text="Name"
              item-value="Id"
            />
            <div class="row">
              <v-col
                cols="6"
              >
                <datetime-picker
                  v-if="!readMode"
                  v-model="formData.DueDate"
                  :label="$t('QualityCheck.QCTasks.Tasks.DueDate')"
                  :date-picker-props="pickerDateProps"
                  :time-picker-props="pickerTimeProps"
                  :has-clear-button="false"
                  :rules="[v => !!v || $t('Validation.Required')]"
                />
                <v-text-field
                  v-else
                  v-model="formData.DueDate"
                  :label="$t('QualityCheck.QCTasks.Tasks.DueDate')"
                  :rules="[v => !!v || $t('Validation.Required')]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <datetime-picker
                  v-if="!readMode"
                  v-model="formData.CompletedOn"
                  :label="$t('QualityCheck.QCTasks.Tasks.CompletedOn')"
                  :date-picker-props="pickerDateProps"
                  :time-picker-props="pickerTimeProps"
                  :has-clear-button="false"
                />
                <v-text-field
                  v-else
                  v-model="formData.CompletedOn"
                  :label="$t('QualityCheck.QCTasks.Tasks.CompletedOn')"
                  v-bind="attrs"
                  v-on="on"
                />
              </v-col>
            </div>
            <v-textarea
              v-model="formData.Details"
              :label="$t('QualityCheck.QCTasks.Tasks.Details')"
              rows="5"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import DatetimePicker from '@/components/DatetimePicker.vue';
import i18n from '@/plugins/i18n';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      readMode: !this.$auth.roles.includes('Web.QCTask.Create'),
      recurring: false,
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  computed: {
    ...mapState('qualityCheck/qcTasks', ['qcTask', 'qcTaskTypes', 'monitoringSites', 'users', 'qcInspectionTemplates', 'qcTaskRecurrences']),
    ...mapState('app', ['locale']),
  },
  watch: {
    async id() {
      await this.getTask();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
    recurring(newValue) {
      if (!newValue) {
        this.formData.QCTaskRecurrenceId = null;
        this.$refs.recurrence.validate();
      }
    },
  },
  async created() {
    if (this.id && this.id != null) { // Update mode
      await this.getTask();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    async getTask() {
      await this.$store.dispatch('qualityCheck/qcTasks/get', { id: this.id });
      this.formData = { ...this.qcTask };
      if (this.qcTask.QCTaskRecurrenceId) {
        this.recurring = true;
      } else {
        this.recurring = false;
        this.formData.QCTaskRecurrenceId = null;
      }
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'QualityCheck.QCTasks.List' });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.id) {
          await this.$store.dispatch('qualityCheck/qcTasks/update', this.formData);
        } else {
          await this.$store.dispatch('qualityCheck/qcTasks/create', this.formData);
        }

        this.navigateToList();
      }
    },
    recurrenceValidation(value) {
      if (!!this.recurring && value) {
        return true;
      }
      return this.$t('Validation.Required');
    },
  },
};
</script>
