<template>
  <v-dialog
    v-model="show"
    max-width="700"
    @keydown="handleKeyDown"
  >
    <v-card>
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form
            ref="form"
            lazy-validation
            @keydown="handleKeyDown"
          >
            <v-textarea
              :model="model"
              :schema="schema"
              @input="handleInput"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="onAccept"
        >
          {{ $t('Accept') }}
        </v-btn>
        <v-btn
          text
          @click.stop="show=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertAckPopup',
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
  },
  methods: {
    onAccept() {
      if (this.$refs.form.validate()) {
        this.show = false;
        this.$emit('on-accept', this.model);
      }
    },
    handleInput() {
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.onAccept();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        this.show = false;
      }
    },
  },
  i18n: {
    messages: {
      en: {
        Accept: 'Accept',
        Cancel: 'Cancel',
      },
      fr: {
        Accept: 'Accepter',
        Cancel: 'Annuler',
      },
    },
  },
};
</script>
