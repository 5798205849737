<template>
    <v-row justify="center">
      <v-dialog
        v-model="show"
        scrollable
        persistent
        max-width="1100px"
      >
        <v-card>
          <v-card-text style="height: 71vh;padding:0px;">
            <v-tabs
      v-model="activeTab"
      fixed-tabs
      style="border-width:0px;"
    >
      <v-tab key="information">
        <slot>
          {{ $t('QualityCheck.QCInspectionTemplates.Informations') }}
        </slot>
      </v-tab>
      <v-tab
      v-if="id == null ? false : true"
        key="sections"
      >
        <slot>
            {{ $t('QualityCheck.QCInspectionTemplates.Sections') }}
        </slot>
      </v-tab>
            <v-tab-item
        key="information"
        style="padding: 15px;padding-top: 30px;"
      >
      <v-form
        ref="form"
        lazy-validation
      >
      <v-text-field
              v-model="formData.Name"
              :label="$t('QualityCheck.QCInspectionTemplates.Name')"
              :rules="mandatoryRule"
              :readonly="readMode & id!=null"
              class='col col-6'
              :error-messages="errorMessage"
              @input="cleanError"
              id="txtInspectionName"
            />
    </v-form>
      </v-tab-item>
      <v-tab-item
        key="sections"
        style=""
      >
      <TemplateSectionList />
      </v-tab-item>
       </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
          <v-btn
            v-if="$auth.roles.includes('Web.QCInspectionTemplate.Manage')"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import TemplateSectionList from '../../qcTemplateSections/components/QCTemplateSectionList.vue';

export default {
  components: {
    TemplateSectionList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    readMode: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      mandatoryRule: null,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('qualityCheck/qcInspectionTemplates', ['qcInspectionTemplate', 'nameExists']),
    ...mapState('qualityCheck/qcTemplateSections', ['templateId']),
  },
  watch: {
    async id() {
      await this.getType();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.id && this.id != null) { // View or Update mode
      await this.$store.dispatch('qualityCheck/qcTemplateSections/setTemplateId', this.id);
      await this.getTemplate();
    } else { // Create mode
      await this.$store.dispatch('qualityCheck/qcTemplateSections/setQcInspectionTemplate', null);
      this.show = true;
    }
  },
  methods: {
    async getTemplate() {
      await this.$store.dispatch('qualityCheck/qcInspectionTemplates/get', { id: this.id });
      this.formData = this.$clone(this.qcInspectionTemplate);
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'QualityCheck.QCInspectionTemplates.List' });
    },
    async submit() {
      this.mandatoryRule = [(v) => !!v || this.$t('Validation.Required')];
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (await this.validate()) {
            if (this.id) {
              await this.$store.dispatch('qualityCheck/qcInspectionTemplates/update', this.formData);
            } else {
              await this.$store.dispatch('qualityCheck/qcInspectionTemplates/create', this.formData);
            }

            this.navigateToList();
          }
        }
      });
    },

    cleanError() {
      this.mandatoryRule = '';
      this.errorMessage = '';
    },

    async validate() {
      await this.$store.dispatch('qualityCheck/qcInspectionTemplates/validate', this.formData);
      if (this.nameExists) {
        this.errorMessage = i18n.t('QualityCheck.QCInspectionTemplates.NameExists');
      } else {
        this.errorMessage = '';
        return true;
      }
      return false;
    },

  },
};
</script>
