/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsModels extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.Equipment.Model.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 80,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.Equipment.Model.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'Equipment.Models.Update',
                params: {
                  id: event.data.ModelId,
                },
              });
            },
          },
        },
        {
          headerName: this.vue.$t('Equipments.Models.Columns.EquipmentType'),
          filter: true,
          sortable: true,
          editable: false,
          valueGetter: (params) => this.vue.$t('Equipments.EquipmentType.'.concat(params.data.EquipmentTypeName)),
        },
        {
          headerName: this.vue.$t('Equipments.Models.Columns.ManufacturerName'),
          field: 'ManufacturerName',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Equipments.Models.Columns.ModelName'),
          field: 'ModelName',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 80,
          resizable: true,
          hide: !this.vue.$auth.roles.includes('Web.Equipment.Model.Manage'),
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            show: (event) => event.data.Removable,
            confirm: (event) => (this.vue.$t('Equipments.Models.DeleteName', { Name: event.data.ModelName })),
            clicked: async (event) => {
              await this.vue.$store.dispatch('equipment/models/remove', { id: event.data.ModelId });
            },
          },
        },
      ]);
    });
  }
}
