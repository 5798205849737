import api from '@/api/importal';

const state = {
  locations: [],
  site: {},
  sites: [],
  tenants: [],
};

const actions = {
  async getByGuid({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`CustomerSitesGetByGuid?id=${payload}`);
    commit('site', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async getFilters({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('CustomerSitesGetFilters');
    commit('locations', response.data.Locations);
    commit('tenants', response.data.Tenants);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.post('CustomerSitesList', payload);
    commit('sites', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  locations(state, payload) {
    state.locations = payload;
  },
  site(state, payload) {
    state.site = payload;
  },
  sites(state, payload) {
    state.sites = payload;
  },
  tenants(state, payload) {
    state.tenants = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
