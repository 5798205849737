<template>
  <div>
    <v-card-title>
      {{ $t('WindServer.Sites.LoggerConfiguration.Channels') }}
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="showChannelModal = true;"
          >
            <i class="v-icon mdi mdi-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('New') }} </span>
      </v-tooltip>
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="deleteChannels()"
          >
            <i class="v-icon mdi mdi-delete-forever" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Delete') }} </span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-container
        fluid
        style="height: 350px;"
      >
        <ServerSideDataGrid
          ref="gridLogger"
          :grid-source="sources.LoggerConfigurations"
          :row-data-init="loggerData"
          :pagination="false"
          enable-selection="multiple"
        />
      </v-container>
    </v-card-text>
    <v-card-title>
      {{ $t('WindServer.Sites.LoggerConfiguration.DataImportSettings.Section') }}
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="addData()"
          >
            <i class="v-icon mdi mdi-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('New') }} </span>
      </v-tooltip>
    </v-card-title>
    <v-expansion-panels focusable>
      <DataImportList
        v-for="(item, i) in formData.DataFileImportSettings"
        :key="i"
        :item="item"
      />
    </v-expansion-panels>
    <ChannelFormModal
      v-model="showChannelModal"
      :pe="id"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import LoggerConfigurationList from '../gridsources/gsLoggerConfigurations';
import DataImportList from './DataImportList.vue';
import ChannelFormModal from './ChannelFormModal.vue';

export default {
  components: {
    ServerSideDataGrid,
    DataImportList,
    ChannelFormModal,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      sources: {
        LoggerConfigurations: null,
      },
      formData: {},
      loggerData: [],
      showChannelModal: false,
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['logger', 'siteConfiguration']),
  },
  created() {
    this.sources.LoggerConfigurations = new LoggerConfigurationList(this);
    this.formData = this.logger;
    this.loggerData = this.formData.Channels;
  },
  methods: {
    async addData() {
      await this.$store.dispatch('site/loggerConfigurations/addData');
    },
    async editChannel(payload) {
      await this.$store.dispatch('site/loggerConfigurations/getChannel', { channelCalibrationId: payload.ChannelCalibrationId });
      this.showChannelModal = true;
    },
    async deleteChannels() {
      let selectedRows = [];
      if (this.$refs.gridLogger.gridApi.getSelectedRows().length > 0) {
        selectedRows = this.$refs.gridLogger.gridApi.getSelectedRows();
      }
      if (selectedRows.length > 0) {
        await this.$store.dispatch('site/loggerConfigurations/deleteChannels', selectedRows);
      }
    },
  },
};
</script>

<style>
.ag-invisible
{
  width: 0px;
  margin-left: -11px;
}
</style>
