/* eslint-disable class-methods-use-this */
import i18n from '@/plugins/i18n';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

function getSensorTypeI18N(params) {
  if (params.data.SensorType != null) {
    return i18n.t('Equipments.SensorType.'.concat(params.data.SensorType));
  }
  return '';
}

export default class GsModels extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.Equipment.Management.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 80,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.Equipment.Management.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'Equipment.Equipments.Update',
                params: {
                  id: event.data.PeNumber,
                },
              });
            },
          },
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.PeNumber'),
          filter: true,
          sortable: true,
          editable: false,
          width: 100,
          valueGetter: (params) => String(params.data.PeNumber).padStart(5, '0'),
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.EquipmentType'),
          filter: true,
          sortable: true,
          editable: false,
          valueGetter: (params) => this.vue.$t('Equipments.EquipmentType.'.concat(params.data.EquipmentTypeName)),
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.SensorType'),
          filter: true,
          sortable: true,
          editable: false,
          valueGetter: (params) => getSensorTypeI18N(params),
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.ManufacturerName'),
          field: 'ManufacturerName',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.ModelName'),
          field: 'ModelName',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.SerialNumber'),
          filter: true,
          sortable: true,
          editable: false,
          width: 100,
          field: 'SerialNumber',
        },
      ]);
    });
  }
}
