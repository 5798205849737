/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsTextReplacements extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$t('Edit'),
          width: 80,
          resizable: true,
          hide: !this.vue.isEditable,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.editTextReplacement(event.data);
            },
          },
        },
        {
          field: 'Find',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.TextReplacements.Find'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'ReplaceWith',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.TextReplacements.ReplaceWith'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
      ]);
    });
  }
}
