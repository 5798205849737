<template>
  <div>{{ formattedDatetime }}</div>
</template>

<script>
import { format, parse } from 'date-fns';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

export default {
  components: {
  },
  data: () => ({
    initialized: false,
    selectedDateTime: null,
    textFieldProps: {
      style: 'font-size: 90%; max-height: 26px;',
      dense: true,
      flat: true,
      solo: true,
      // height: 20,
      // 'max-height': 20,
      'hide-details': true,
      class: 'aggrid-input-in-cell',
    },
  }),
  computed: {
    dateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    formattedDatetime() {
      return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : '';
    },
  },
  beforeMount() {
    this.init(this.params.value);
  },
  methods: {
    init(datetime) {
      if (datetime instanceof Date) {
        this.selectedDatetime = datetime;
      } else if (typeof datetime === 'string' || datetime instanceof String) {
        // see https://stackoverflow.com/a/9436948
        this.selectedDatetime = parse(datetime, this.dateTimeFormat, new Date());
      }
    },
    refresh(params) {
      this.params = params;
      this.init(this.params.value);
    },
    onInput(val) {
      this.params.setValue(val);
    },
  },
};
</script>

<style lang="scss">
  .aggrid-input-in-cell > .v-input__control {
    min-height: 20px !important;
  }
</style>
