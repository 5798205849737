import api from '@/api/importal';

const state = {
  landOwner: null,
  landOwners: [],
  phoneTypes: null,
  nameAlreadyExists: false,
};

const actions = {
  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const phoneTypes = await api.get('PhoneTypesList');
    commit('phoneTypes', phoneTypes.data);
    const response = await api.get('LandOwnersList');
    commit('landOwners', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('LandOwnersCreate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`LandOwnersGet?id=${payload.id}`);
    commit('landOwner', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('LandOwnersUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`LandOwnersValidate?id=${payload.LandOwnerId != null ? payload.LandOwnerId : 0}&name=${payload.Fullname}`);
    commit('nameAlreadyExists', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  landOwners(state, payload) {
    state.landOwners = payload;
  },
  landOwner(state, payload) {
    state.landOwner = payload;
  },
  phoneTypes(state, payload) {
    state.phoneTypes = payload;
  },
  nameAlreadyExists(state, payload) {
    state.nameAlreadyExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
