<template>
  <v-container
    fluid
    style="height: Calc(100% - 30px); width: 100%; overflow-x: auto"
  >
    <v-row justify="justify-start">
      <div
        v-for="attachement in SiteFiles"
        :key="attachement.SiteAttachmentId"
        style="padding: 5px; max-width: 120px; cursor: pointer"
        @click="downloadFile(attachement.SiteAttachmentId, attachement.Name)"
      >
        <svg-icon
          type="mdi"
          :path="thumbnailSelector(attachement.Name)"
          size="100"
        />
        <div
          class="filename"
          style="text-align: left; font-size: 14px"
        >
          {{ attachement.Name }}
        </div>
      </div>
    </v-row>
    <div
      v-if="SiteFiles.length === 0"
      class="noAttachments"
    >
      {{ $t('WindServer.Sites.Attachments.NoAttachments') }}
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import SvgIcon from '@jamescoyle/vue-icon';
import {
  mdiFileOutline,
  mdiFilePdfBox,
  mdiFileWordBox,
  mdiFileExcelOutline,
  mdiXml,
  mdiZipBoxOutline,
  mdiDatabaseOutline,
  mdiFileSettingsOutline,
  mdiPresentation,
  mdiTextBoxOutline,
  mdiFileDelimitedOutline,
  mdiWeb,
  mdiVideoOutline,
  mdiEmailOutline,
} from '@mdi/js';

const managedFileExtensions = {
  default: mdiFileOutline,
  pdf: mdiFilePdfBox,
  doc: mdiFileWordBox,
  docx: mdiFileWordBox,
  xls: mdiFileExcelOutline,
  xlsx: mdiFileExcelOutline,
  xlsm: mdiFileExcelOutline,
  xlsb: mdiFileExcelOutline,
  xml: mdiXml,
  zip: mdiZipBoxOutline,
  rar: mdiZipBoxOutline,
  mdb: mdiDatabaseOutline,
  accdb: mdiDatabaseOutline,
  ini: mdiFileSettingsOutline,
  ppt: mdiPresentation,
  pptx: mdiPresentation,
  pps: mdiPresentation,
  ppsx: mdiPresentation,
  txt: mdiTextBoxOutline,
  csv: mdiFileDelimitedOutline,
  htm: mdiWeb,
  html: mdiWeb,
  avi: mdiVideoOutline,
  mov: mdiVideoOutline,
  eml: mdiEmailOutline,
};

const getMdi = (fileExt) => managedFileExtensions[fileExt.toLowerCase()]
                            ?? managedFileExtensions.default;

export default {
  constructor(vue) {
    this.vue = vue;
  },
  components: {
    SvgIcon,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState('site/siteAttachments', ['selectedFolderFiles']),
    SiteFiles() {
      return this.selectedFolderFiles;
    },
  },
  watch: {},
  created() {},
  methods: {
    thumbnailSelector(filename) {
      const filenameExtension = filename.split('.').pop();
      const thumbnailMdi = getMdi(filenameExtension);

      return thumbnailMdi;
    },
    async downloadFile(siteAttachmentId, name) {
      this.$store.dispatch('site/siteAttachments/download', {
        id: siteAttachmentId,
        name,
      });
    },
  },
};
</script>

<style scoped>
.filename {
  white-space: wrap;
}
.noAttachments {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
