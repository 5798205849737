import InstrumentList from '@/devices/instruments/InstrumentList.vue';
import InstrumentEdit from '@/devices/instruments/InstrumentEdit.vue';

export default [
  {
    path: '/devices',
    name: 'Devices',
    component: InstrumentList,
  },
  {
    path: '/devices/instrument',
    name: 'Devices.Instrument',
    component: InstrumentList,
  },
  {
    path: '/devices/instrument/list/:id',
    name: 'InstrumentList',
    component: InstrumentEdit,
  },
];
