<template>
  <v-dialog
    ref="dialog"
    v-model="modal2"
    :return-value.sync="time"
    persistent
    width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-time-picker
      v-if="modal2"
      v-model="time"
      format="24hr"
      v-bind="timePickerProps"
      full-width
    >
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save('24:00')"
      >
        24:00
      </v-btn>
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="modal2 = false"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(time)"
      >
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    timePickerProps: {
      type: Object,
      default: () => {},
    },
    textFieldProps: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    time: null,
    modal2: false,
  }),
  watch: {
    time(value) {
      this.$emit('input', value);
    },
  },
  mounted() {
    this.time = this.$attrs.value;
  },
  i18n: {
    messages: {
      en: {
        Cancel: 'Cancel',
      },
      fr: {
        Cancel: 'Annuler',
      },
    },
  },
};
</script>

<style>

</style>
