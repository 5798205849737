<template>
    <div>
    <h2 class="mb-2">Summary</h2>
    <p class="mb-1"><strong>Inspection Status : </strong> -</p>
    <p class="mb-1"><strong>Traces : </strong> -</p>
    <p class="mb-1"><strong>TowerShodows : </strong> -</p>
    <p class="mb-1"><strong>StdWnd : </strong> -</p>
    <p class="mb-1"><strong>Turbulence Intensities : </strong> -</p>
    <h3 class="mb-2">Notes :
      <v-tooltip
        v-if="$auth.roles.includes('Web.QCTask.Create')"
        bottom
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-btn
            style="min-width:40px;padding:0px;"
            v-bind="attrs"
            v-on="on"
            @click="createNew();"
          >
            <i class="v-icon mdi mdi-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('AddNew') }} </span>
      </v-tooltip>
    </h3>
    <div style="height: 230px; width: 100%; background-color: black;">
      <ServerSideDataGrid
        :grid-source="sources.InspectionNotes"
        :row-data-init="notes"
      />
    </div>
    <router-view />
</div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import InspectionNotesList from './gridsources/gsInspectionNotes';

export default {
  components: {
    ServerSideDataGrid,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      sources: {
        InspectionNotes: null,
      },
    };
  },
  computed: {
    ...mapState('qualityCheck/qcInspectionNotes', ['notes']),
  },
  async created() {
    this.sources.InspectionNotes = new InspectionNotesList(this);
    await this.$store.dispatch('qualityCheck/qcInspectionNotes/list', this.id);
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'QualityCheck.QCInspections.Notes.Create' });
    },
  },
};
</script>
