import QCProblemTypesRoutes from './qcProblemTypes/qcProblemTypes.routes';
import QCTaskTypesRoutes from './qcTaskTypes/qcTaskTypes.routes';
import QCTasksRoutes from './qcTasks/qcTasks.routes';
import QCInspectionTemplates from './qcInspectionTemplates/qcInspectionTemplates.routes';
import QCSchedulesRoutes from './qcSchedules/qcSchedules.routes';
import QCInspections from './qcInspections/qcInspections.routes';

export default [
  ...QCProblemTypesRoutes,
  ...QCTaskTypesRoutes,
  ...QCTasksRoutes,
  ...QCInspectionTemplates,
  ...QCSchedulesRoutes,
  ...QCInspections,
];
