import store from '@/store';

const actions = {
  jsonWidgetSettings(state, payload) {
    const query = payload.widgetSettings;
    const { visualisationSubType } = payload;
    const type = visualisationSubType.startsWith('sensor+') ? 'S' : 'V';

    const selectedPlaceholderSensors = store.getters['dashboardPlaceholders/selectedPlaceholderSensors'];
    const placeholderSensors = [];
    if (query.user.selectedPlaceholders && query.user.selectedPlaceholders.length > 0) {
      query.user.selectedPlaceholders.forEach((x) => {
        const item = selectedPlaceholderSensors.find((y) => y.placeholderId === x.Id);
        if (item) {
          item.selectedItem.forEach((selectedPH) => {
            if (selectedPH.T === type) {
              const item2 = {};
              item2.placeholderId = item.placeholderId;
              item2.selectedItem = selectedPH;
              item2.selectedItem.Y2Axis = x.Y2Axis;
              item2.selectedItem.Aggregation = x.Aggregation;
              placeholderSensors.push(item2);
            } else if (selectedPH.T === 'I') {
              if (selectedPH.sensorList
                && selectedPH.sensorList.length > 0
                && selectedPH.selected
              ) {
                selectedPH.sensorList.forEach((sensorInList) => {
                  const item2 = {};
                  item2.placeholderId = item.placeholderId;
                  item2.selectedItem = sensorInList;
                  item2.selectedItem.Y2Axis = x.Y2Axis;
                  item2.selectedItem.Aggregation = x.Aggregation;
                  placeholderSensors.push(item2);
                });
              }
            } else if (selectedPH.T === 'A') {
              if (selectedPH.sensorList
                && selectedPH.sensorList.length > 0
                && selectedPH.selected
              ) {
                selectedPH.sensorList.forEach((sensorInList) => {
                  const item2 = {};
                  item2.placeholderId = item.placeholderId;
                  item2.selectedItem = sensorInList;
                  item2.selectedItem.Y2Axis = x.Y2Axis;
                  item2.selectedItem.Aggregation = x.Aggregation;
                  placeholderSensors.push(item2);
                });
              }
            }
          });
        }
      });
    }

    const sensors = placeholderSensors.length > 0
      ? placeholderSensors.map((x, i) => {
        const item = x.selectedItem;
        item.Unit = (typeof item.Unit === 'string' || item.Unit instanceof String) ? item.Unit : null;
        item.ReadingUnit = (typeof item.ReadingUnit === 'string' || item.ReadingUnit instanceof String) ? item.ReadingUnit : null;
        const alias = store.getters['dashboardPlaceholders/placeholders']
          .find((y) => y.PlaceholderId === x.placeholderId).JsonData.Alias;

        return {
          sensor: x.selectedItem,
          alias: store.getters['dashboardPlaceholders/sensorAlias'](alias, x.selectedItem),
          symbol: type === 'S' ? `s${x.selectedItem.Id}` : `v${x.selectedItem.Id}${i}`,
        };
      })
      : query.user.selectedSensors
        .filter((x) => x.T === type)
        .map((x, i) => {
          const item = x;
          item.Unit = (typeof item.Unit === 'string' || item.Unit instanceof String) ? item.Unit : null;
          item.ReadingUnit = (typeof item.ReadingUnit === 'string' || item.ReadingUnit instanceof String) ? item.ReadingUnit : null;
          item.Y2Axis = false;

          return {
            sensor: x,
            alias: x.Alias || store.getters['dashboardPlaceholders/sensorAlias']('', x),
            symbol: x.T === 'S' ? `s${x.Id}` : `v${x.Id}${i}`,
          };
        });

    query.data.formulas = sensors.map((x) => ({
      symbol: x.symbol,
      formula_text: `=${x.symbol};`,
      output_aggregation: x.sensor.Aggregation || 'AVG',
    }));

    query.data.sensor_selection = sensors
      .filter((x) => x.sensor.T === 'S')
      .map((x) => ({
        symbol: x.symbol,
        sensor_id: x.sensor.Id,
        input_aggregation: x.sensor.Aggregation || 'AVG',
      }));

    query.data.vector_selection = sensors
      .filter((x) => x.T === 'V')
      .map((x) => ({
        symbol: x.symbol,
        vector_sensor_id: x.sensor.Id,
        vector_name: x.sensor.Name,
        input_aggregation: x.sensor.Aggregation || 'AVG',
      }));

    query.visualization.graphOptions.y_axis = sensors.map((x) => (JSON.parse(`{"${x.symbol}": "value"}`)));
    query.visualization.alias = sensors.map((x) => (JSON.parse(`{"${x.symbol}": "${x.alias}"}`)));
  },
};

export default {
  namespaced: true,
  actions,
};
