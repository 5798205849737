<template>
  <v-date-picker
    v-model="selectedDateIso"
    full-width
    :picker-date.sync="pickerDate"
    :events="arrayEvents"
    @input="selectDayOfData"
    @click:month="monthChanged"
  />
</template>

<script>
import importal from '@/api/importal';
import {
  format, addDays, addMonths, parseISO, formatISO,
} from 'date-fns';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  model: {
    prop: 'datetime',
    event: 'input',
  },
  props: {
    datetime: {
      type: [Date, String],
      required: false,
      default: () => new Date(Date.now()),
    },
    sensorList: {
      type: Array,
      required: false,
      default: () => [],
    },
    vectorList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    arrayEvents: null,
    selectedDateIso: '',
    pickerDate: null,
    queryCache: {},
  }),
  computed: {
  },
  watch: {
    pickerDate(pDate) {
      this.queryAvailability(pDate);
      this.queryAvailability(this.addMonth(pDate, 1));
      this.queryAvailability(this.addMonth(pDate, -1));
    },
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.datetime instanceof String) {
        this.selectedDateIso = this.datetime;
      } else {
        this.selectedDateIso = format(this.datetime || Date.now(), DEFAULT_DATE_FORMAT);
      }
      this.queryAvailability(this.pickerDate);
      this.queryAvailability(this.addMonth(this.pickerDate, 1));
      this.queryAvailability(this.addMonth(this.pickerDate, -1));
    },
    addMonth(pDate, nmonth) {
      const pp = parseISO(pDate);
      return format(
        addMonths(
          new Date(pp.getFullYear(), pp.getMonth() + 1, 0),
          nmonth,
        ),
        'yyyy-MM',
      );
    },
    firstDay(pDate) {
      const pp = parseISO(pDate);
      return new Date(pp.getFullYear(), pp.getMonth(), 1);
    },
    lastDay(pDate) {
      const pp = parseISO(pDate);
      return addDays(
        new Date(pp.getFullYear(), pp.getMonth() + 1, 0),
        1,
      );
    },
    buildAvailQuery(pDate) {
      return `B=${formatISO(this.firstDay(pDate)).substring(0, 10)}&E=${formatISO(this.lastDay(pDate)).substring(0, 10)}${
        this.sensorList
          .filter(onlyUnique)
          .map((x) => `&S=${x}`)
          .join('')
      }${this.vectorList
        .filter(onlyUnique)
        .map((x) => `&V=${x[0]},${encodeURIComponent(x[1])}`)
        .join('')}`;
    },
    queryAvailability(pDate) {
      const query = this.buildAvailQuery(pDate);
      if (this.queryCache[query] && this.queryCache[query][pDate]) {
        if (pDate === this.pickerDate) {
          this.arrayEvents = this.queryCache[query][pDate];
        }
      } else {
        this.cacheAvailability(query, pDate);
      }
    },
    cacheAvailability(query, pDate) {
      importal.get(`SensorAvailabilityInfo?${query}`)
        .then((resp) => resp.data)
        .then((data) => {
          this.queryCache[query] = this.queryCache[query] || {};
          // eslint-disable-next-line no-multi-assign
          this.queryCache[query][pDate] = (
            data.sensors
              ? data.sensors.Rows.map((x) => format(x[1], DEFAULT_DATE_FORMAT))
              : [])
            .concat(
              (data.vectors
                ? data.vectors.Rows.map((x) => format(x[2], DEFAULT_DATE_FORMAT))
                : []),
            ).filter(onlyUnique);
          if (pDate === this.pickerDate) {
            this.arrayEvents = this.queryCache[query][pDate];
          }
        });
    },
    selectDayOfData() {
      this.$emit('input', this.selectedDateIso);
    },
    monthChanged() {
    },
  },
};
</script>

<style>

</style>
