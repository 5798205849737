/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsTenants extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve([
        {
          field: 'VITplTemplateId',
          headerName: 'Edit',
          width: 120,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked(params) {
              vue.$router.push(`/vitpl/${params.data.VITplTemplateId}`);
            },
          },
        },
        {
          field: 'Name',
          editable: true,
          sortable: true,
        },
        {
          field: 'Description',
          editable: true,
          sortable: true,
        },
        {
          field: 'ValidFromTs',
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
          },
          sortable: false,
          resizable: true,
        },
        {
          field: 'ValidToTs',
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
          },
          sortable: false,
          resizable: true,
        },
        {
          field: 'VITplTemplateId',
          headerName: 'Remove',
          width: 120,
          resizable: true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (`Template "${event.data.Name}"`),
            clicked: (event) => {
              this.apiViTemplateRemove(event.api, event.data);
            },
          },
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue } = this;
    return {
      getRows: function GetRows(params) {
        importal.get(`ViTemplateList?tenantId=${vue.tenantId}`)
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // Called when an editable column is changed
  // The function name must match the data Model
  // MAY call successCallback if the API call returns the table data
  onChange() {
    return {
      Name(event, successCallback, failCallback) {
        importal
          .post('ViTemplateUpdateMeta', {
            VITplTemplateId: event.data.VITplTemplateId,
            Name: event.data.Name,
            Description: event.data.Description,
            ValidFromTs: event.data.ValidFromTs,
            ValidToTs: event.data.ValidToTs,
          })
          .then((resp) => {
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
      Description(event, successCallback, failCallback) {
        importal
          .post('ViTemplateUpdateMeta', {
            VITplTemplateId: event.data.VITplTemplateId,
            Name: event.data.Name,
            Description: event.data.Description,
            ValidFromTs: event.data.ValidFromTs,
            ValidToTs: event.data.ValidToTs,
          })
          .then((resp) => {
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
      ValidFromTs(event, successCallback, failCallback) {
        if (event.newValue !== event.oldValue) {
          importal
            .post('ViTemplateUpdateMeta', {
              VITplTemplateId: event.data.VITplTemplateId,
              Name: event.data.Name,
              Description: event.data.Description,
              ValidFromTs: event.data.ValidFromTs,
              ValidToTs: event.data.ValidToTs,
            })
            .then((resp) => {
              successCallback(resp.data, resp.data.length);
            })
            .catch(() => {
              failCallback();
            });
        }
      },
      ValidToTs(event, successCallback, failCallback) {
        if (event.newValue !== event.oldValue) {
          importal
            .post('ViTemplateUpdateMeta', {
              VITplTemplateId: event.data.VITplTemplateId,
              Name: event.data.Name,
              Description: event.data.Description,
              ValidFromTs: event.data.ValidFromTs,
              ValidToTs: event.data.ValidToTs,
            })
            .then((resp) => {
              successCallback(resp.data, resp.data.length);
            })
            .catch(() => {
              failCallback();
            });
        }
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve({
        Model: {
          TenantId: vue.tenantId,
          Name: null,
          Description: null,
        },
        Schema: {
          TenantId: {
            label: 'TenantId',
            type: 'text',
            flex: 12,
            readonly: true,
            disabled: true,
          },
          Name: {
            label: 'Name', type: 'text', flex: 12, required: true,
          },
          Description: { label: 'Description', type: 'text', flex: 12 },
        },
      });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    importal
      .post('ViTemplateNew', {
        TenantId: data.TenantId,
        Name: data.Name,
        Description: data.Description || '',
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async apiViTemplateRemove(api, data) {
    const resp = await importal
      .post('ViTemplateRemove', {
        VITplTemplateId: data.VITplTemplateId,
      });
    api.setRowData(resp.data);
  }
}
