import i18n from '@/plugins/i18n';
import api from '@/api/importal';

const state = {
  weekOf: '',
  assignationList: [],
  inspections: [],
  inspectionsGroupedByTenant: [],
  selectedAssignation: '',
  countVerified: null,
  countSubmitted: null,
  countIncomplete: null,
  showInspectionGeneration: false,
  sites: [],
  selectedSection: null,
};

const actions = {

  async getWeekOf({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('QCInspectionsWeekOfGet');
    commit('weekOf', response.data.toISOString().split('T')[0].replaceAll('-', '/'));
    commit('app/pleaseWait', false, { root: true });
  },

  async getAssignationList({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('QCInspectionsAssignationsGet');
    const assignations = [];
    assignations.push({
      Id: 'Me',
      Name: i18n.t('QualityCheck.QCInspections.Assignations.Me'),
    });
    assignations.push({
      Id: 'Everyone',
      Name: i18n.t('QualityCheck.QCInspections.Assignations.Everyone'),
    });
    assignations.push({
      Id: 'Nobody',
      Name: i18n.t('QualityCheck.QCInspections.Assignations.Nobody'),
    });
    if (response.data.length > 0) {
      response.data.forEach((assignation) => {
        assignations.push({
          Id: assignation.Id,
          Name: assignation.Initials,
        });
      });
    }
    commit('selectedAssignation', 'Me');
    commit('assignationList', assignations);
    commit('app/pleaseWait', false, { root: true });
  },

  async getInspections({ commit }, assignation) {
    commit('app/pleaseWait', true, { root: true });
    commit('selectedAssignation', assignation);
    const response = await api.get(`QCInspectionsList?assignation=${assignation}`);
    commit('inspections', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async updateInspection({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCInspectionsUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async sitesList({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('QCInspectionsSitesList');
    commit('sites', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async generateInspections({ commit }, siteIds) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCInspectionsGenerate', siteIds);
    commit('app/pleaseWait', false, { root: true });
  },
  async verifyAllNonSuspicous({ commit, dispatch }) {
    commit('app/pleaseWait', true, { root: true });
    await api.get('QCInspectionsVerifyAllNonSuspicous');
    commit('app/pleaseWait', false, { root: true });
    await dispatch('getInspections');
  },
  setShowInspectionGeneration({ commit }, payload) {
    commit('showInspectionGeneration', payload);
  },
  setSelectedSection({ commit }, payload) {
    commit('selectedSection', payload);
  },
};

const mutations = {
  weekOf(state, payload) {
    state.weekOf = payload;
  },
  assignationList(state, payload) {
    state.assignationList = payload;
  },
  inspections(state, payload) {
    state.inspections = [];
    const tenants = [...new Set(payload.map((item) => item.Tenant))];
    let sites = [];
    let tenantSites = [];
    tenants.forEach((tenant) => {
      sites = payload.filter((p) => p.Tenant === tenant);
      tenantSites = [];
      sites.forEach((site) => {
        tenantSites.push({
          siteId: site.SiteId,
          name: `${site.SiteName} (${site.SiteNumber})`,
          periodStart: site.PeriodStart.toISOString().split('T')[0].replaceAll('-', '/'),
          periodEnd: site.PeriodEnd.toISOString().split('T')[0].replaceAll('-', '/'),
          technician: site.Technician,
          analyst: site.Analyst,
          result: i18n.t(`QualityCheck.QCInspections.Results.${site.Result}`),
          status: i18n.t(`QualityCheck.QCInspections.Status.${site.Status}`),
          inspectionId: site.InspectionId,
          inspected: site.Inspected,
          verified: site.Verified,
        });
      });
      state.inspections.push({
        id: tenant,
        client: tenant,
        sitesDue: sites.filter((x) => x.IsDue === true).length,
        inspected: sites.filter((x) => x.Inspected === true).length,
        verified: sites.filter((x) => x.Status === 'Verified').length,
        submitted: sites.filter((x) => x.Status === 'Submitted').length,
        incomplete: sites.filter((x) => x.Status === 'Incomplete').length,
        sites: tenantSites,
      });
    });
    state.countVerified = payload.filter((x) => x.Status === 'Verified').length;
    state.countSubmitted = payload.filter((x) => x.Status === 'Submitted').length;
    state.countIncomplete = payload.filter((x) => x.Status === 'Incomplete').length;
  },

  selectedAssignation(state, payload) {
    state.selectedAssignation = payload;
  },
  showInspectionGeneration(state, payload) {
    state.showInspectionGeneration = payload;
  },
  sites(state, payload) {
    state.sites = payload;
  },
  selectedSection(state, payload) {
    state.selectedSection = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
