<template>
  <div
    class="scrollable-content"
  >
    <v-card-title class="px-3 pt-3 pb-0">
      <p class="my-0">
        {{ $t(graphText) }}
      </p>
    </v-card-title>
    <v-card-title
      class="pa-3 mb-3"
    >
      <p class="mb-0 mt-0">
        {{ $t('Dashboard.VisualizationSetting.VisualizationSetting') }}
      </p>
    </v-card-title>
    <sensor-selector
      v-if="showSensorSelectorDialogForX"
      v-model="showSensorSelectorDialogForX"
      :hide-y2="isCrossplot"
      :show-sensors="showSensors"
      :show-vectors="showVectors"
      @sensors-added="addSensorsInX($event)"
    />
    <sensor-selector
      v-if="showSensorSelectorDialogForY"
      v-model="showSensorSelectorDialogForY"
      :hide-y2="isCrossplot"
      :show-sensors="showSensors"
      :show-vectors="showVectors"
      @sensors-added="addSensorsInY($event)"
    />
    <edit-sensor-modal
      v-if="showEditSensor"
      v-model="showEditSensor"
      :sensor="editedSensor"
      :unit-list="units"
      @save="saveSensor"
    />

    <div v-if="showSensorSelection">
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleSubTitles') }}
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-text-field
          v-model="form.mainTitle"
          placeholder="Main title"
          dense
        />
        <v-text-field
          v-model="form.subTitle[0]"
          placeholder="(A) Subtitle"
          dense
        />
        <v-text-field
          v-model="form.subTitle[1]"
          placeholder="(B) Subtitle"
          dense
        />
      </v-card-text>

      <div v-if="showSensorSelection">
        <v-subheader class="ma-0">
          {{ $t('Dashboard.Placeholder.TitleSelect') }}
        </v-subheader>
        <placeholder-selection
          :selected-placeholders="form.selectedPlaceholdersX"
          button-text="Dashboard.Placeholder.AddPlaceholdersX"
          :is-sensor="showSensors"
          :hide-y2="isCrossplot"
          class="pb-3"
          @change="value => form.selectedPlaceholdersX = value"
        />
        <placeholder-selection
          :selected-placeholders="form.selectedPlaceholdersY"
          button-text="Dashboard.Placeholder.AddPlaceholdersY"
          :is-sensor="showSensors"
          :hide-y2="isCrossplot"
          class="pb-3"
          @change="value => form.selectedPlaceholdersY = value"
        />

        <v-subheader class="ma-0">
          {{ $t('Dashboard.VisualizationSetting.TitleSelectSensors') }}
        </v-subheader>
        <v-card-text class="pb-0 pt-0">
          <v-btn
            block
            @click="openAddSensorDialogX"
          >
            <v-icon>mdi-memory</v-icon>
            {{ showSensors ? $t('Dashboard.AddXSensors') : $t('Dashboard.AddXVectors') }}
          </v-btn>
          <selected-sensor-scroll
            v-models:selectedSensors="form.selectedSensorsForX"
            :hide-y2="isCrossplot"
            :show="showSensorSelection"
            style="max-height: 130px"
            @edit="editSensor"
          />
          <v-btn
            block
            @click="openAddSensorDialogY"
          >
            <v-icon>mdi-memory</v-icon>
            {{ showSensors ? $t('Dashboard.AddYSensors') : $t('Dashboard.AddYVectors') }}
          </v-btn>
          <selected-sensor-scroll
            v-models:selectedSensors="form.selectedSensorsForY"
            :hide-y2="isCrossplot"
            :show="showSensorSelection"
            style="max-height: 130px"
            @edit="editSensor"
          />
        </v-card-text>
      </div>
    </div>
    <div v-if="showTimeRangeSelection">
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleTimeRange') }}
      </v-subheader>
      <time-range-card
        v-models:periodicity="form.periodicity"
        v-models:dateRangeFrom="form.dateRangeFrom"
        v-models:dateRangeTo="form.dateRangeTo"
        v-models:lastnScale="form.lastnScale"
        v-models:lastnPeriod="form.lastnPeriod"
        v-models:dateList="form.dateList"
        :show="showTimeRangeSelection"
        :sensor-id-list="sensorIdList"
        :vector-id-list="vectorIdList"
        :selected-sensors="form.selectedSensorsForX.concat(form.selectedSensorsForY)"
      />
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitlePeriodAggregation') }}
      </v-subheader>
      <period-aggregation-card
        v-models:aggregateMultiplier="form.aggregateMultiplier"
        v-models:aggregateForEvery="form.aggregateForEvery"
        v-models:aggregateUseTimeZone="form.aggregateUseTimeZone"
        :show="showTimeRangeSelection"
      />
      <v-card-text
        v-if="needsSampleRate"
        class="pb-0 pt-0"
      >
        <v-row>
          <v-col sm="12">
            <v-text-field
              v-model="form.sampleRate"
              :label="$t('Dashboard.VisualizationSetting.SampleRate')"
              type="number"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pb-0 pt-0">
        <v-checkbox
          v-model="form.showAlarmThresholds"
          :label="$t('Dashboard.VisualizationSetting.TitleShowAlarmThr')"
        />
      </v-card-text>
      <v-card-text class="pb-0 pt-0">
        <v-checkbox
          v-if="false"
          v-model="form.showLabel"
          :label="$t('Dashboard.VisualizationSetting.ShowLabel')"
          :title="$t('Dashboard.VisualizationSetting.TooltipShowLabel')"
        />
        <v-text-field
          v-model="form.decimalCases"
          :label="$t('Dashboard.VisualizationSetting.DecimalCases')"
          type="number"
          oninput="if (this.value && this.value < 1) this.value = 0;"
          dense
        />
      </v-card-text>
    </div>
    <v-subheader
      v-if="showTimeRangeSelection"
      class="ma-0"
    >
      {{ $t('Dashboard.VisualizationSetting.AxisEdit') }}
    </v-subheader>
    <edit-axis-card
      v-models:xmin="form.xmin"
      v-models:xmax="form.xmax"
      v-models:logx="form.logx"
      v-models:ymin="form.ymin"
      v-models:ymax="form.ymax"
      v-models:logy="form.logy"
      v-models:y2min="form.y2min"
      v-models:y2max="form.y2max"
      v-models:logy2="form.logy2"
      v-models:typeOfAxis="form.type"
      :show="showTimeRangeSelection"
      :showx="true"
      :showy2="false"
      :shake-x="shakeX"
      :shake-y="shakeY"
      :shake-y2="shakeY2"
    />
    <wizard-action-buttons-card
      :show-back="showBackButton"
      :show-next="showNextButton"
      :show-save="showSaveButton"
      :confirm-disabled="confirmDisabled"
      :next-button-tooltip="$t('Dashboard.VisualizationSetting.CrossPlottlySensorsTooltip')"
      @button:cancel="cancel()"
      @button:back="back()"
      @button:next="next()"
      @button:save="save()"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import importal from '@/api/importal';
import UtcDateTimeCellRenderer from '@/components/UtcDateTimeCellRenderer.vue';
import datehandling from '@/components/datehandling';
import SensorSelector from '@/dashboard/components/SensorSelector.vue';
import TimeRangeCard from '@/dashboard/components/graphform/TimeRangeCard.vue';
import PeriodAggregationCard from '@/dashboard/components/graphform/PeriodAggregationCard.vue';
import EditAxisCard from '@/dashboard/components/graphform/EditAxisCard.vue';
import SelectedSensorScroll from '@/dashboard/components/graphform/SelectedSensorScroll.vue';
import WizardActionButtonsCard from '@/dashboard/components/graphform/WizardActionButtonsCard.vue';
import EditSensorModal from '@/dashboard/components/graphform/EditSensorModal.vue';
import PlaceholderSelection from '@/dashboard/components/placeholder/PlaceholderSelection.vue';

export default {
  components: {
    SensorSelector,
    // eslint-disable-next-line vue/no-unused-components
    UtcDateTimeCellRenderer,
    TimeRangeCard,
    PeriodAggregationCard,
    SelectedSensorScroll,
    WizardActionButtonsCard,
    EditSensorModal,
    EditAxisCard,
    PlaceholderSelection,
  },
  props: {
    visualisation: {
      type: String,
      default: () => null,
      required: true,
    },
    graphType: {
      type: String,
      default: () => null,
      required: true,
    },
    graphStyle: {
      type: String,
      default: () => null,
      required: true,
    },
    graphText: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  data: () => ({
    LastPicker: ['Years', 'Months', 'Days', 'Hours', 'Minutes'],
    LastPicker2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE'],
    AggregateByItems: ['Any', 'Average', 'Min', 'Max', 'Sum'],
    AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX', 'SUM'],
    AggregateForEveryItems: ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],
    AggregateForEvery2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'],
    menuStepCounter: 0,
    showEditSensor: false,
    editedSensor: null,
    units: [],
    model: {
      widgetId: null,
      enabled: true,
      visible: true,
      axisWidth: 4,
      axisHeight: 200,
      axisX: 0,
      axisY: 0,
      axisLock: false,
    },
    form: {
      selectedSensorsForX: [],
      selectedSensorsForY: [],
      selectedPlaceholdersX: [],
      selectedPlaceholdersY: [],
      graphType: null,
      periodicity: 'last_n',
      dateRangeFrom: null,
      dateRangeTo: null,
      lastnScale: 'Hours',
      lastnPeriod: 2,
      dateList: [],
      aggregateBy: 'Any',
      aggregateMultiplier: 1,
      aggregateForEvery: 'Hours',
      aggregateUseTimeZone: true,
      sampleRate: 20.0, // Hz
      showAlarmThresholds: false,
      showLabel: false,
      decimalCases: 6,

      mainTitle: null,
      subTitle: [null, null],
      xmin: '',
      xmax: '',
      ymin: '',
      ymax: '',
      y2min: '',
      y2max: '',
      logx: false,
      logy: false,
      logy2: false,
      type: 'Auto',
    },
    dateRangeMenu: false,
    showSensorSelectorDialogForX: false,
    showSensorSelectorDialogForY: false,
    hasY2Axis: false,
    shakeX: false,
    shakeY: false,
    shakeY2: false,
  }),
  computed: {
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapGetters('app', ['pleaseWait', 'timezone']),

    sensorIdList() {
      return this.form.selectedSensorsForX.concat(this.form.selectedSensorsForY)
        .filter((x) => x.T === 'S')
        .map((x) => x.Id);
    },
    vectorIdList() {
      return this.form.selectedSensorsForX.concat(this.form.selectedSensorsForY)
        .filter((x) => x.T === 'V')
        .map((x) => ([x.Id, x.Name]));
    },

    confirmDisabled() {
      if (this.showSensorSelection) {
        // If one X is selected and at least one Y is selected
        // If several X are selected, then the same number of Y have to be selected
        // Placeholder are considered as selectedSensor

        const nbSensorX = this.form.selectedSensorsForX.length
          + this.form.selectedPlaceholdersX.length;
        const nbSensorY = this.form.selectedSensorsForY.length
          + this.form.selectedPlaceholdersY.length;

        if (nbSensorX === 0) {
          return true;
        }

        if (nbSensorX === 1) {
          return nbSensorY === 0;
        }

        return nbSensorX !== nbSensorY;
      }

      if (this.showTimeRangeSelection) {
        if (this.form.periodicity === 'last_n') {
          return !(this.form.lastnScale && this.form.lastnPeriod);
        }
        if (this.form.periodicity === 'time_range') {
          return !(this.form.dateRangeFrom && this.form.dateRangeTo);
        }
        if (this.form.periodicity === 'time_list') {
          return !(this.form.dateList && this.form.dateList.length > 0);
        }
      }

      return true;
    },
    showNextButton() {
      return this.menuStepCounter < 1;
    },
    showBackButton() {
      return this.menuStepCounter > 0;
    },
    showSaveButton() {
      return !this.showNextButton;
    },
    showTimeRangeSelection() {
      return this.menuStepCounter === 1;
    },
    needsSampleRate() {
      return this.visualisation.startsWith('vector+fourier+');
    },
    showSensorSelection() {
      return this.menuStepCounter === 0;
    },

    isSideBySidePlot() {
      return this.visualisation.startsWith('vector+sbs_vertical+')
       || this.visualisation.startsWith('vector+sbs_horizontal+')
       || this.visualisation.startsWith('sensor+sbs_vertical+')
       || this.visualisation.startsWith('sensor+sbs_horizontal+');
    },
    showSensors() {
      return this.visualisation.startsWith('sensor+');
    },
    showVectors() {
      return this.visualisation.startsWith('vector+');
    },
    isNew() {
      return this.activeWidget === undefined;
    },
    isCrossplot() {
      return true;
    },
    stringDateRange() {
      return [this.form.dateRangeFrom, this.form.dateRangeTo];
    },
    dateRangeStart() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[0];
    },
    dateRangeEnd() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[dateRange.length - 1];
    },
    jsonWidgetSettings() {
      const query = {
        user: {
          timezone: this.timezone,
        },
        data: {
          formulas: [],
          input_filter: [],
          input_time_axis: {
            round_to: {
              scale: 'HOUR',
              multiplier: 1,
              divisor: 1,
            },
          },
          output_time_axis: {
            round_to: {
              scale: 'HOUR',
              multiplier: 1,
              divisor: 1,
            },
          },
          sensor_selection: [],
          vector_selection: [],
          timezone: this.timezone,
          output_in_local: false,
        },
        visualization: {
          alias: [],
          graphType: this.graphType,
          graphStyle: this.graphStyle,
          graphOptions: {
            x_axis: [],
            y_axis: [],
            groupby: [],
          },
          crossplot: true,
          theme: {
            hovermode: 'closest',
          },
        },
      };

      query.user.selectedSensorsForX = this.form.selectedSensorsForX;
      query.user.selectedSensorsForY = this.form.selectedSensorsForY;
      query.user.selectedPlaceholdersX = this.form.selectedPlaceholdersX;
      query.user.selectedPlaceholdersY = this.form.selectedPlaceholdersY;

      if (this.form.showAlarmThresholds) {
        query.visualization.showAlarm = this.form.selectedSensorsForX.concat(this.form.selectedSensorsForY)
          .filter((x) => x.T === 'S')
          .map((x) => ({
            T: x.T,
            Id: x.Id,
            style: { width: 3 },
          }));
      }

      this.$store.dispatch('visualisationSettingsCrossPlotlyGraph/jsonWidgetSettings', {
        widgetSettings: query,
        visualisationSubType: this.visualisation,
        units: this.units,
      });

      query.visualization.graphOptions.groupby.push({
        ts: 'value',
      });

      query.data.input_time_axis.round_to.scale = (this.form.aggregateUseTimeZone ? 'TZ' : '') + this.AggregateForEvery2Query[
        this.AggregateForEveryItems.indexOf(this.form.aggregateForEvery)
      ];
      query.data.input_time_axis.round_to.multiplier = Number(this.form.aggregateMultiplier);
      query.data.input_time_axis.round_to.divisor = Number(this.form.aggregateMultiplier);

      query.data.output_time_axis.round_to.scale = 'NONE';
      query.data.output_time_axis.round_to.multiplier = 1;
      query.data.output_time_axis.round_to.divisor = 1;

      if (this.form.periodicity === 'last_n') {
        query.data.input_filter.push({
          last_n: {
            scale: this.LastPicker2Query[this.LastPicker.indexOf(this.form.lastnScale)],
            period: Number(this.form.lastnPeriod),
          },
        });
      } else if (this.form.periodicity === 'time_range') {
        query.data.input_filter.push({
          time_range: {
            start: datehandling.formatForApi(this.dateRangeStart),
            end: datehandling.formatForApi(this.dateRangeEnd),
          },
        });
      } else if (this.form.periodicity === 'time_list') {
        query.data.input_filter.push({
          time_list: {
            dates: this.form.dateList.map((x) => datehandling.formatForApi(x)),
          },
        });
      }

      if (this.form.mainTitle) {
        query.visualization.graphOptions.title = this.form.mainTitle;
      }

      if (this.form.subTitle[0]
          || this.form.subTitle[1]) {
        query.visualization.graphOptions.subplot_titles = this.form.subTitle;
      }

      if (this.form.type !== this.$t('Dashboard.VisualizationSetting.Auto')) {
        query.visualization.graphOptions.log = {};
        if (this.form.logx) {
          query.visualization.graphOptions.log.logx = 'true';
        }
        if (this.form.logy) {
          query.visualization.graphOptions.log.logy = 'true';
        }
        if (this.form.logy2) {
          query.visualization.graphOptions.log.logy2 = 'true';
        }

        query.visualization.graphOptions.max_min = {};
        query.visualization.graphOptions.max_min.xmin = {};
        query.visualization.graphOptions.max_min.ymin = {};
        query.visualization.graphOptions.max_min.y2min = {};
        query.visualization.graphOptions.max_min.xmax = {};
        query.visualization.graphOptions.max_min.ymax = {};
        query.visualization.graphOptions.max_min.y2max = {};
        if (this.form.xmin != null || this.form.xmin !== '') {
          query.visualization.graphOptions.max_min.xmin = this.form.xmin;
        }
        if (this.form.ymin != null || this.form.ymin !== '') {
          query.visualization.graphOptions.max_min.ymin = this.form.ymin;
        }
        if (this.form.y2min != null || this.form.y2min !== '') {
          query.visualization.graphOptions.max_min.y2min = this.form.y2min;
        }
        if (this.form.xmax != null || this.form.xmax !== '') {
          query.visualization.graphOptions.max_min.xmax = this.form.xmax;
        }
        if (this.form.ymax != null || this.form.ymax !== '') {
          query.visualization.graphOptions.max_min.ymax = this.form.ymax;
        }
        if (this.form.y2max != null || this.form.y2max !== '') {
          query.visualization.graphOptions.max_min.y2max = this.form.y2max;
        }
      }

      if (this.form.decimalCases) {
        query.visualization.graphOptions.decimalCases = this.form.decimalCases;
      }

      if (this.form.showLabel) {
        // eslint-disable-next-line
        query.visualization.hoverTemplate = `%{text}<br>x: %{x:.${this.form.decimalCases}f}<br>y: %{y:.${this.form.decimalCases}f}`; 
      }

      if (this.activeWidget) {
        if (JSON.parse(this.activeWidget.WidgetSettings).visualization.referenceLine) {
          const wsettings = JSON.parse(this.activeWidget.WidgetSettings);
          const referenceline = wsettings.visualization.referenceLine;
          query.visualization.referenceLine = referenceline;
        }
      }

      if (this.form.aggregateUseTimeZone) {
        query.data.output_in_local = true;
      }

      return JSON.stringify(query);
    },
  },
  watch: {
    activeWidget() {
      this.init();
    },
    editModeFlag(val) {
      if (!val) {
        this.clearWidgetSettings();
      }
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      if (this.units.length === 0) {
        this.units = await (await importal.get('DevUnitList')).data;
      }

      this.clearWidgetSettings();

      // Update sensor units
      if (this.units.length === 0) {
        this.units = await (await importal.get('DevUnitList')).data;
      }

      if (!this.isNew) {
        await this.loadWidgetSettings();
      }
    },

    // Menu NAV
    next() {
      this.menuStepCounter += 1;
    },
    back() {
      this.menuStepCounter -= 1;
    },

    addSensorsInX(array) {
      if (array.length > 0) {
        array.forEach((n) => {
          const sensor = { ...n };
          if (!sensor.Unit) {
            delete sensor.Unit;
          }
          if (!sensor.ReadingUnit) {
            delete sensor.ReadingUnit;
          }
          if (!sensor.SamplingUnit) {
            delete sensor.SamplingUnit;
          }

          this.form.selectedSensorsForX.push(sensor);
        });
      }
    },
    addSensorsInY(array) {
      if (array.length > 0) {
        array.forEach((n) => {
          const sensor = { ...n };
          if (!sensor.Unit) {
            delete sensor.Unit;
          }
          if (!sensor.ReadingUnit) {
            delete sensor.ReadingUnit;
          }
          if (!sensor.SamplingUnit) {
            delete sensor.SamplingUnit;
          }
          this.form.selectedSensorsForY.push(sensor);
        });
      }
    },
    loadSensors(array, obj, type) {
      const xaxis = obj.visualization.graphOptions.x_axis;
      const yaxis = obj.visualization.graphOptions.y_axis;
      for (let i = 0; i < array.length; i += 1) {
        if (xaxis.find((d) => d[array[i].symbol] !== undefined)) {
          const item = array[i];
          let name = item.symbol;
          if (obj.visualization.alias) {
            const zz = obj.visualization.alias.find((d) => d[item.symbol] !== undefined);
            if (zz) {
              name = zz[item.symbol];
            }
          }
          if (type === 'S') {
            const sensor = {
              T: type,
              Id: item.sensor_id,
              InstrumentName: name,
              Unit: item.unit,
            };
            this.form.selectedSensorsForX.push(sensor);
          } else if (type === 'V') {
            const vector = {
              T: type,
              Id: item.vector_sensor_id,
              Name: item.vector_name,
              ReadingUnit: item.readingUnit,
              SamplingUnit: item.samplingUnit,
            };
            this.form.selectedSensorsForX.push(vector);
          }
        }
        if (yaxis.find((d) => d[array[i].symbol] !== undefined)) {
          const item = array[i];
          let name = item.symbol;
          if (obj.visualization.alias) {
            const zz = obj.visualization.alias.find((d) => d[item.symbol] !== undefined);
            if (zz) {
              name = zz[item.symbol];
            }
          }
          if (type === 'S') {
            const sensor = {
              T: type,
              Id: item.sensor_id,
              InstrumentName: name,
            };
            if (item.unit) {
              sensor.Unit = item.unit;
            }
            this.form.selectedSensorsForY.push(sensor);
          } else if (type === 'V') {
            const vector = {
              T: type,
              Id: item.vector_sensor_id,
              Name: item.vector_name,
            };
            if (item.readingUnit) {
              vector.ReadingUnit = item.readingUnit;
            }
            if (item.samplingUnit) {
              vector.SamplingUnit = item.samplingUnit;
            }
            this.form.selectedSensorsForY.push(vector);
          }
        }
      }
    },
    clearWidgetSettings() {
      this.form.selectedSensorsForX = [];
      this.form.selectedSensorsForY = [];
      this.form.periodicity = 'last_n';
      this.form.dateRangeFrom = null;
      this.form.dateRangeTo = null;
      this.form.lastnScale = 'Hours';
      this.form.lastnPeriod = 2;
      this.form.aggregateBy = 'Any';
      this.form.aggregateMultiplier = 1;
      this.form.aggregateForEvery = 'Hours';
      this.form.mainTitle = null;
      this.form.subTitle = [null, null];
      this.form.xmin = '';
      this.form.xmax = '';
      this.form.ymin = '';
      this.form.ymax = '';
      this.form.y2min = '';
      this.form.y2max = '';
      this.form.logx = false;
      this.form.logy = false;
      this.form.logy2 = false;
      this.form.type = this.$t('Dashboard.VisualizationSetting.Auto');
      this.form.showLabel = false;
      this.form.decimalCases = 6;
    },
    async loadWidgetSettings() {
      const widgetSettings = datehandling.parseObjectFromApi(
        JSON.parse(this.activeWidget.WidgetSettings),
      );

      this.form.selectedPlaceholdersX = widgetSettings.user.selectedPlaceholdersX || [];
      this.form.selectedPlaceholdersY = widgetSettings.user.selectedPlaceholdersY || [];
      if (widgetSettings.user.selectedSensorsForX) {
        this.form.selectedSensorsForX = widgetSettings.user.selectedSensorsForX;
        this.form.selectedSensorsForY = widgetSettings.user.selectedSensorsForY;
      } else {
        this.form.selectedSensorsForX = [];
        this.form.selectedSensorsForY = [];
        this.loadSensors(widgetSettings.data.sensor_selection, widgetSettings, 'S');
        // this.loadSensors(widgetSettings.data.vector_selection, widgetSettings, 'V');
        for (let i = 0; i < this.form.selectedSensorsForX.length; i += 1) {
          const ss = this.form.selectedSensorsForX[i];
          if (ss.T === 'S') {
            ss.Aggregation = widgetSettings.data.sensor_selection
              .find((x) => ss.Id === x.sensor_id)
              .input_aggregation;
          } else if (ss.T === 'V') {
            ss.Aggregation = widgetSettings.data.vector_selection
              .find((x) => ss.Id === x.vector_sensor_id && ss.Name === x.vector_name)
              .input_aggregation;
          }
        }
        for (let i = 0; i < this.form.selectedSensorsForY.length; i += 1) {
          const ss = this.form.selectedSensorsForY[i];
          if (ss.T === 'S') {
            ss.Aggregation = widgetSettings.data.sensor_selection
              .find((x) => ss.Id === x.sensor_id)
              .input_aggregation;
          } else if (ss.T === 'V') {
            ss.Aggregation = widgetSettings.data.vector_selection
              .find((x) => ss.Id === x.vector_sensor_id && ss.Name === x.vector_name)
              .input_aggregation;
          }
        }
      }

      const filter = widgetSettings.data.input_filter[0];
      if (filter) {
        if (filter.time_range) {
          this.form.periodicity = 'time_range';
          this.form.dateRangeFrom = filter.time_range.start;
          this.form.dateRangeTo = filter.time_range.end;
        } else if (filter.last_n) {
          this.form.periodicity = 'last_n';
          this.form.lastnScale = this.LastPicker[
            this.LastPicker2Query.indexOf(filter.last_n.scale)
          ];
          this.form.lastnPeriod = filter.last_n.period;
        } else if (filter.time_list) {
          this.form.periodicity = 'time_list';
          this.form.dateList = filter.time_list.dates;
        }
      }

      const inputTimeAxis = widgetSettings.data.input_time_axis;
      this.form.aggregateForEvery = this.AggregateForEveryItems[
        this.AggregateForEvery2Query.indexOf(inputTimeAxis.round_to.scale.replace('TZ', ''))
      ];
      this.form.aggregateUseTimeZone = inputTimeAxis.round_to.scale.startsWith('TZ');
      if (!this.form.aggregateForEvery) {
        this.form.aggregateForEvery = this.AggregateForEveryItems[
          this.AggregateForEvery2Query
            .indexOf(widgetSettings.data.output_time_axis.round_to.scale.replace('TZ', ''))
        ];
        this.form.aggregateUseTimeZone = widgetSettings.data.output_time_axis.round_to.scale.startsWith('TZ');
      }
      if (!this.form.aggregateUseTimeZone) {
        // if it is false, it could be an old widget settings
        if (widgetSettings.data.output_in_local === undefined
          && widgetSettings.data.timezone === undefined) {
          // old widgetSettings
          this.form.aggregateUseTimeZone = true;
        }
      }
      this.form.aggregateMultiplier = widgetSettings.data.input_time_axis.round_to.multiplier;

      if (widgetSettings.visualization.graphOptions) {
        if (widgetSettings.visualization.graphOptions.title) {
          this.form.mainTitle = widgetSettings.visualization.graphOptions.title;
        }
        if (widgetSettings.visualization.graphOptions.subplot_titles) {
          this.form.subTitle = widgetSettings.visualization.graphOptions.subplot_titles
            .map((x) => x);
        }
        if (widgetSettings.visualization.graphOptions.sbs
            && widgetSettings.visualization.graphOptions.sbs.subplot_titles) {
          this.form.subTitle = widgetSettings.visualization.graphOptions.sbs.subplot_titles
            .map((x) => x);
        }
        if (widgetSettings.visualization.graphOptions.log) {
          if (widgetSettings.visualization.graphOptions.log.logx) {
            this.form.logx = true;
            this.form.type = this.$t('Dashboard.VisualizationSetting.Manual');
          }
          if (widgetSettings.visualization.graphOptions.log.logy) {
            this.form.logy = true;
            this.form.type = this.$t('Dashboard.VisualizationSetting.Manual');
          }
          if (widgetSettings.visualization.graphOptions.log.logy2) {
            this.form.logy2 = true;
            this.form.type = this.$t('Dashboard.VisualizationSetting.Manual');
          }
        }
        if (widgetSettings.visualization.graphOptions.max_min) {
          if (widgetSettings.visualization.graphOptions.max_min.xmin) {
            this.form.xmin = widgetSettings.visualization.graphOptions.max_min.xmin;
            this.form.type = this.$t('Dashboard.VisualizationSetting.Manual');
          }
          if (widgetSettings.visualization.graphOptions.max_min.xmax) {
            this.form.xmax = widgetSettings.visualization.graphOptions.max_min.xmax;
            this.form.type = this.$t('Dashboard.VisualizationSetting.Manual');
          }
          if (widgetSettings.visualization.graphOptions.max_min.ymin) {
            this.form.ymin = widgetSettings.visualization.graphOptions.max_min.ymin;
            this.form.type = this.$t('Dashboard.VisualizationSetting.Manual');
          }
          if (widgetSettings.visualization.graphOptions.max_min.ymax) {
            this.form.ymax = widgetSettings.visualization.graphOptions.max_min.ymax;
            this.form.type = this.$t('Dashboard.VisualizationSetting.Manual');
          }
          if (widgetSettings.visualization.graphOptions.max_min.y2min) {
            this.form.y2min = widgetSettings.visualization.graphOptions.max_min.y2min;
            this.form.type = this.$t('Dashboard.VisualizationSetting.Manual');
          }
          if (widgetSettings.visualization.graphOptions.max_min.y2max) {
            this.form.y2max = widgetSettings.visualization.graphOptions.max_min.y2max;
            this.form.type = this.$t('Dashboard.VisualizationSetting.Manual');
          }
        }
        if (widgetSettings.visualization.graphOptions.decimalCases) {
          this.form.decimalCases = widgetSettings.visualization.graphOptions.decimalCases;
        }
      }

      if (widgetSettings.visualization.hoverTemplate) {
        this.form.showLabel = true;
      }

      if (this.needsSampleRate) {
        this.form.sampleRate = widgetSettings.user.selectedSampleRate;
      }

      this.form.showAlarmThresholds = !!widgetSettings.visualization.showAlarm
        && widgetSettings.visualization.showAlarm.length > 0;

      // Update sensor units
      if (this.units.length === 0) {
        this.units = await (await importal.get('DevUnitList')).data;
      }

      const sensors = this.form.selectedSensorsForX.concat(this.form.selectedSensorsForY)
        .filter((x) => x.T === 'S');
      if (sensors.length > 0) {
        const queryParams = sensors.map((x) => `SensorId=${x.Id}`).join('&');
        const data = await (await importal.get(`DevSensorList?${queryParams}`)).data;

        sensors.forEach((x) => {
          const dataSensor = data.find((y) => y.SensorId === x.Id);
          const selectedSensor = x;

          if (dataSensor.Unit) {
            const dataUnit = this.units.find((y) => y.Symbol === dataSensor.Unit);
            const selectedUnit = this.units.find((y) => y.Symbol === selectedSensor.Unit);
            if (!selectedSensor.Unit || selectedUnit.UnitType !== dataUnit.UnitType) {
              selectedSensor.Unit = dataUnit.Symbol;
            }
          } else if (selectedSensor.Unit) {
            delete selectedSensor.Unit;
          }
        });
      }
    },
    openAddSensorDialogX() {
      this.showSensorSelectorDialogForX = true;
    },
    openAddSensorDialogY() {
      this.showSensorSelectorDialogForY = true;
    },
    cancel() {
      this.$emit('cancel', this.isNew);
    },
    save() {
      if (
        (!this.form.logx || (this.form.logx && ((!(this.form.xmin) || this.form.xmin > 0) && (!(this.form.xmax) || this.form.xmax > 0))))
        && (!this.form.logy || (this.form.logy && ((!(this.form.ymin) || this.form.ymin > 0) && (!(this.form.ymax) || this.form.ymax > 0))))
        && (!this.form.logy2 || (this.form.logy2 && ((!(this.form.y2min) || this.form.y2min > 0) && (!(this.form.y2max) || this.form.y2max > 0))))
      ) {
        if (this.isNew) {
          this.create();
        } else {
          this.saveWidgetSettings();
        }
      } else {
        if (this.form.logx && (this.form.xmin <= 0 || this.form.xmax <= 0)) {
          this.shakeX = true;
          setTimeout(() => {
            this.shakeX = false; // Reset the flag
          }, 1000);
        }
        if (this.form.logy && (this.form.ymin <= 0 || this.form.ymax <= 0)) {
          this.shakeY = true;
          setTimeout(() => {
            this.shakeY = false; // Reset the flag
          }, 1000);
        }
        if (this.form.logy2 && (this.form.y2min <= 0 || this.form.y2max <= 0)) {
          this.shakeY2 = true;
          setTimeout(() => {
            this.shakeY2 = false; // Reset the flag
          }, 1000);
        }
      }
    },
    create() {
      this.$emit('create', {
        DashboardsWidgetId: this.activeWidgetId,
        DatasourceSettings: '', // TODO check if this property is useful, it seems that WidgetSettings do it all
        WidgetSettings: this.jsonWidgetSettings,
        WidgetId: this.model.widgetId,
        Enabled: this.model.enabled,
        Visible: this.model.visible,
        AxisHeight: this.model.axisHeight,
        AxisWidth: this.model.axisWidth,
        AxisY: this.model.axisY,
        AxisX: this.model.axisX,
        AxisLock: this.model.axisLock,
      });
    },
    saveWidgetSettings() {
      this.$emit('save', {
        DashboardsWidgetId: this.activeWidgetId,
        WidgetSettings: this.jsonWidgetSettings,
      });
    },
    async editSensor(item) {
      this.editedSensor = item;
      this.showEditSensor = true;
    },
    saveSensor(newValues) {
      if (newValues.alias.length > 0) {
        this.editedSensor.Alias = newValues.alias;
      } else {
        delete this.editedSensor.Alias;
      }

      if (this.editedSensor.T === 'S') {
        if (newValues.unit) {
          this.editedSensor.Unit = newValues.unit.Symbol;
        }
      } else {
        if (newValues.readingUnit) {
          this.editedSensor.ReadingUnit = newValues.readingUnit.Symbol;
        }

        if (newValues.samplingUnit) {
          this.editedSensor.SamplingUnit = newValues.samplingUnit.Symbol;
        }
      }

      this.editedSensor = null;
    },
  },
};
</script>

<style>
.scrollable-content {
  max-height: 700px; /* Adjust the maximum height as needed */
  overflow-y: auto; /* This will add a vertical scrollbar when content overflows */
}
</style>
