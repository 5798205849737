import ModelList from './components/ModelList.vue';
import ModelFormModal from './components/ModelFormModal.vue';

export default [
  {
    path: '/Equipment/Models',
    name: 'Equipment.Models.List',
    component: ModelList,
    children: [
      {
        path: '/Equipment/Models/Create',
        name: 'Equipment.Models.Create',
        component: ModelFormModal,
      },
      {
        path: '/Equipment/Models/:id',
        name: 'Equipment.Models.Update',
        component: ModelFormModal,
        props: true,
      },
    ],
  },
];
