<template>
  <v-card
    v-if="editorActive"
  >
    <v-card-title>{{ $t('SelectOutputSensor') }}</v-card-title>
    <v-virtual-scroll
      :key="form.itemList.Id"
      :items="form.itemList"
      :item-height="28"
      height="260"
    >
      <template #default="{ item }">
        <v-list-item
          :key="item.Id"
          class="sensor-search-item pt-0 pb-0"
          style="min-height: 24px;"
          @click="selectSensor(item)"
        >
          <v-list-item-icon
            class="pt-0 pb-0 mt-0 mb-0"
          >
            <v-icon v-if="item.T == 'S'">
              mdi-alpha-s-box-outline
            </v-icon>
            <v-icon v-if="item.T == 'V'">
              mdi-alpha-v-box-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            {{ item.DisplayName || item.Name }}
          </v-list-item-content>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            <v-badge
              :content="item.InstrumentName"
              inline
            />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
    <slot />
  </v-card>
</template>

<script>
import importal from '@/api/importal';

export default {
  components: {
  },
  props: {
    init: {
      type: Object,
      default: () => {},
      required: true,
    },
    virtualInstrumentId: {
      type: Number,
      default: () => 0,
      required: false,
    },
  },
  data: () => ({
    editor: {
      editorMode: null,
      node: {
        SensorId: null,
        SensorName: null,
        InstrumentName: null,
        nodeType: null,
      },
    },
    form: {
      searchTerm: '',
      itemList: [],
    },
  }),
  computed: {
    editorActive() {
      return this.editor && this.editor.show && this.editor.node && (this.editor.node.nodeType === 'output_sensor' || this.editor.node.nodeType === 'output_vector');
    },
    showSensors() {
      return this.editor.node.nodeType === 'output_sensor';
    },
    showVectors() {
      return this.editor.node.nodeType === 'output_vector';
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.initialize();
      }
    },
    editor() {
      if (this.editorActive) {
        this.$emit('editor-update', this.editor);
      }
    },
  },
  async created() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.editor = this.init;
      if (this.showSensors) {
        const query = `ViOutputSensorCandidateList?VirtualInstrumentId=${this.virtualInstrumentId}`;
        this.form.itemList = await (await importal.get(query)).data
          .map((x) => ({
            T: 'S',
            Id: x.SensorId,
            Name: x.SensorName,
            InstrumentName: x.InstrumentName,
          }));
      } else if (this.showVectors) {
        const query = `ViOutputVectorCandidateList?VirtualInstrumentId=${this.virtualInstrumentId}`;
        this.form.itemList = await (await importal.get(query)).data
          .map((x) => ({
            T: 'V',
            Id: x.VectorSensorId,
            Name: x.VectorName,
            InstrumentName: x.InstrumentName,
          }));
      }
    },
    selectSensor(item) {
      if (this.editor.node.nodeType === 'output_sensor') {
        this.editor.node.SensorId = item.Id;
        this.editor.node.SensorName = item.Name;
        this.editor.node.InstrumentName = item.InstrumentName;
        this.$emit('editor-update', this.editor);
      } else if (this.editor.node.nodeType === 'output_vector') {
        this.editor.node.VectorSensorId = item.Id;
        this.editor.node.VectorName = item.Name;
        this.editor.node.InstrumentName = item.InstrumentName;
        this.$emit('editor-update', this.editor);
      }
    },
  },
  i18n: {
    messages: {
      en: {
        SelectOutputSensor: 'Select output sensor',
      },
      fr: {
        SelectOutputSensor: 'S�lectionner le capteur de sortie',
      },
    },
  },
};
</script>

<style>

</style>
