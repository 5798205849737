/* eslint-disable no-bitwise */
<template>
  <v-card
    class="right-sidebar"
    tile
    outlined
    elevation="10"
  >
    <v-card-title>
      {{ $t('Dashboard.ReferenceMenu.ReferenceSettings') }}
    </v-card-title>

    <!-- COMMON: SELECT SENSOR  -->
    <v-card-subtitle
      class="text-subtitle-2"
    >
      {{ $t('Dashboard.ReferenceMenu.SelectLine') }}
    </v-card-subtitle>
    <v-list
        nav
        dense
      >
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        multiple
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
        >
        <v-list-item-content>
          <v-list-item-title
            v-text="item.text"
          />
        </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider />
    <v-card-actions>
      <v-btn
        text
        @click="$emit('close-reference')"
      >
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Cancel') }}
      </v-btn>
      <v-btn
        text
        @click="SaveChoices()"
      >
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    widgetobj: {
      type: Object,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: [],
      items: [
        { text: 'OSMRE' },
        { text: 'USBM RI8507 - Plaster mm' },
        { text: 'USBM RI8507 - Plaster in' },
        { text: 'USBM RI8507 - Drywall mm' },
        { text: 'USBM RI8507 - Drywall in' },
        { text: 'CENTRUS - VT-1 - Review' },
        { text: 'CENTRUS - VT-2 - Review' },
        { text: 'CENTRUS - VT-1 - Alert' },
        { text: 'CENTRUS - VT-2 - Alert' },
      ],
    };
  },
  watch: {
    activeWidget() {
      this.init();
    },
  },
  computed: {
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      const widSet = JSON.parse(this.widgetobj.WidgetSettings);
      this.selectedItem = widSet.visualization.referenceLine.map((str) => {
        const index = this.items.findIndex((item) => item.text === str);
        return index !== -1 ? index : null;
      });
    },

    async SaveChoices() {
      const widSet = JSON.parse(this.widgetobj.WidgetSettings);
      if (this.selectedItem.length > 0) {
        widSet.visualization.referenceLine = this.selectedItem.map(
          (index) => this.items[index].text,
        );
      } else {
        widSet.visualization.referenceLine = {};
      }
      const updatedWidgetSettings = JSON.stringify(widSet);
      this.$emit('save', {
        DashboardsWidgetId: this.widgetobj.DashboardsWidgetId,
        WidgetSettings: updatedWidgetSettings,
      });
      this.$emit('close-reference');
    },
  },
};
</script>

<style lang="scss" scoped>
  .right-sidebar {
    position: absolute;
    top: 49px;
    right: 10px;
    width: 400px;
    z-index: 9999;
  }
</style>
