import WorkersList from './components/WorkerConfigurationsList.vue';
import WorkersFormModal from './components/WorkerConfigurationsFormModal.vue';

export default [
  {
    path: '/Worker/WorkerConfigs/:workerid',
    name: 'Worker.WorkerConfigs.List',
    component: WorkersList,
    props: true,
    children: [
      {
        path: '/Worker/WorkerConfigs/:workerid/Create',
        name: 'Worker.WorkerConfigs.Create',
        component: WorkersFormModal,
        props: true,
      },
      {
        path: '/Worker/WorkerConfigs/:workerid/:id',
        name: 'Worker.WorkerConfigs.Update',
        component: WorkersFormModal,
        props: true,
      },
    ],
  },
];
