import SiteFolderModal from '@/site/siteAttachments/components/SiteFolderModal.vue';
import FolderRename from '@/site/siteAttachments/components/FolderRenameModal.vue';
import SiteFileModal from '@/site/siteAttachments/components/SiteFileModal.vue';
import SiteAttachmentList from '@/site/siteAttachments/components/SiteAttachmentList.vue';
import SiteDetailsAndTimelineModal from './components/SiteDetailsAndTimelineModal.vue';
import SitesList from './components/SitesList.vue';

export default [
  {
    path: '/customers/sites',
    name: 'Customers.Sites',
    component: SitesList,
    children: [
      {
        path: '/customers/sites/:id/details',
        name: 'Customers.Sites.DetailsAndTimeline',
        component: SiteDetailsAndTimelineModal,
        props: true,
      },
    ],
  },
  {
    path: '/customers/sites/:id/details',
    name: 'Sites.Attachments',
    component: SiteAttachmentList,
    props: true,
    children: [
      {
        path: '/customers/sites/:id/Attachments/Create',
        name: 'Customers.Attachments.Create',
        component: SiteFolderModal,
      },
      {
        path: '/customers/sites/:id/Attachments/Upload',
        name: 'Customers.Attachments.Upload',
        component: SiteFileModal,
      },
      {
        path: '/customers/sites/:id/Attachments/Rename',
        name: 'Customers.Attachments.Rename',
        component: FolderRename,
      },
    ],
  },
];
