import api from '@/api/importal';

const state = {
  qcTaskType: null,
  qcTaskTypes: [],
  nameAlreadyExists: false,
};

const actions = {
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCTaskTypesCreate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCTaskTypesGet?id=${payload.id}`);
    commit('qcTaskType', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('QCTaskTypesList');
    commit('qcTaskTypes', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`QCTaskTypesRemove?id=${payload.id}`);
    commit('qcTaskType', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('QCTaskTypesUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('nameAlreadyExists', false);
    const response = await api.get(`QCTaskTypesValidate?id=${payload.TaskTypeId != null ? payload.TaskTypeId : 0}&name=${payload.Name}`);
    if (response.data != null) {
      commit('nameAlreadyExists', true);
    }
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  qcTaskType(state, payload) {
    state.qcTaskType = payload;
  },
  qcTaskTypes(state, payload) {
    state.qcTaskTypes = payload;
  },
  nameAlreadyExists(state, payload) {
    state.nameAlreadyExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
