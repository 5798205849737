/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsAttributes extends ServerSideDataGridGsClientBase {
  constructor(vue, cb) {
    super();
    this.vue = vue;
    this.callback = cb;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'FileFeedId',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.Edit'),
          width: 300,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          hide: this.vue.readmode === true,
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.callback.onEdit(event.api, event.data);
            },
          },
        },
        {
          field: 'Name', width: 300, headerName: i18n.t('Devices.Gateway.Edit_Headers.Name'),
        },
        {
          field: 'MimeType', width: 300, headerName: i18n.t('Devices.Gateway.Edit_Headers.MimeType'),
        },
        {
          field: '',
          headerName: 'Delete',
          width: 100,
          resizable: true,
          hide: this.vue.readmode === true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            confirm: (event) => (`attribute "${event.data.Name}"`),
            clicked: (event) => {
              this.apiDevGatewayFileFeedRemove(event.api, event.data);
            },
          },
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue } = this;
    return {
      getRows: function GetRows(params) {
        importal.get(`DevGatewayFileFeedList?DataGatewayId=${vue.$route.params.id}`)
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve({
        Model: {
          DataGateWayId: vue.$route.params.id,
          Name: null,
          MimeType: null,
        },
        Schema: {
          DataGateWayId: {
            label: i18n.t('Devices.Gateway.AddNew.GatewayId'),
            type: 'text',
            disabled: true,
          },
          Name: {
            label: i18n.t('Devices.Gateway.AddNew.Name'),
            type: 'text',
            flex: 12,
          },
          MimeType: {
            label: i18n.t('Devices.Gateway.AddNew.MimeType'),
            type: 'select',
            flex: 12,
            items: [
              { value: 'image/jpeg', text: i18n.t('Devices.Gateway.AddNew.Picture') },
              { value: 'application/pdf', text: i18n.t('Devices.Gateway.AddNew.PDF') },
              { value: 'audio/wav', text: i18n.t('Devices.Gateway.AddNew.Audio') },
              { value: 'application/ldbin', text: i18n.t('Devices.Gateway.AddNew.LDBin') },
              { value: 'video/mp4', text: i18n.t('Devices.Gateway.AddNew.Video') },
              { value: 'application/text-comment', text: i18n.t('Devices.Gateway.AddNew.Comments') },
            ],
          },
        },
      });
    });
  }

  async updateItemFormDefinition(data) {
    return new Promise((resolve) => {
      resolve({
        Model: {
          FileFeedId: data.FileFeedId,
          Name: data.Name,
        },
        Schema: {
          FileFeedId: {
            label: i18n.t('Devices.Gateway.EditItem.FeedId'),
            type: 'text',
            flex: 12,
            disabled: true,
          },
          Name: {
            label: i18n.t('Devices.Gateway.EditItem.Name'),
            type: 'text',
            flex: 12,
          },
        },
      });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    importal
      .post('DevGatewayFileFeedNew', {
        DataGateWayId: data.DataGateWayId,
        Name: data.Name,
        MimeType: data.MimeType,
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async apiDevGatewayFileFeedRename(api, data) {
    await importal.post('DevGatewayFileFeedRename',
      {
        FileFeedId: data.FileFeedId,
        Name: data.Name,
      })
      .then((resp) => {
        api.setRowData(resp.data);
      });
  }

  async apiDevGatewayFileFeedRemove(api, data) {
    await importal.post('DevGatewayFileFeedRemove', {
      FileFeedId: data.FileFeedId,
    })
      .then((resp) => {
        api.setRowData(resp.data);
      });
  }
}
