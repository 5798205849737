/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsTenants extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve([
        {
          field: 'VirtualInstrumentId',
          headerName: i18n.t('VirtualInstrument.Header.Edit'),
          width: 120,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            icon: (event) => (event.data.IsManagedByTemplate ? 'mdi-eye' : 'mdi-pencil'),
            clicked(params) {
              vue.$router.push(`/vi/${params.data.VirtualInstrumentId}`);
            },
          },
        },
        {
          field: 'InstrumentName',
          headerName: i18n.t('VirtualInstrument.Header.InstrumentName'),
          filter: true,
        },
        {
          field: 'Name',
          headerName: i18n.t('VirtualInstrument.Header.Name'),
          editable: (event) => !event.data.IsManagedByTemplate,
          filter: true,
        },
        {
          field: 'ValidFromTs',
          headerName: i18n.t('VirtualInstrument.Header.ValidFromTs'),
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
            editable: (event) => !event.data.IsManagedByTemplate,
          },
          filter: 'agDateColumnFilter',
        },
        {
          field: 'ValidToTs',
          headerName: i18n.t('VirtualInstrument.Header.ValidToTs'),
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
            editable: (event) => !event.data.IsManagedByTemplate,
          },
          filter: 'agDateColumnFilter',
        },
        {
          field: 'AccessGroupName',
          headerName: i18n.t('VirtualInstrument.Header.AccessGroupName'),
          width: 300,
          filter: true,
        },
        {
          field: 'VirtualInstrumentId',
          headerName: i18n.t('VirtualInstrument.Header.Remove'),
          width: 120,
          resizable: true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            show: (event) => !event.data.IsManagedByTemplate,
            confirm: (event) => (`VI "${event.data.Name}"`),
            clicked: (event) => {
              this.apiViVirtualInstrumentRemove(event.api, event.data);
            },
          },
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue } = this;
    return {
      getRows: function GetRows(params) {
        if (vue.$route.params.id) {
          importal.get(`ViVirtualInstrumentList?InstrumentId=${vue.$route.params.id}`)
            .then((resp) => {
              params.successCallback(resp.data, resp.data.length);
            })
            .catch(() => {
              params.failCallback();
            });
        } else {
          importal.get('ViVirtualInstrumentList')
            .then((resp) => {
              params.successCallback(resp.data, resp.data.length);
            })
            .catch(() => {
              params.failCallback();
            });
        }
      },
    };
  }

  // Called when an editable column is changed
  // The function name must match the data Model
  // MAY call successCallback if the API call returns the table data
  onChange() {
    return {
      Name(event, successCallback, failCallback) {
        importal
          .post('ViVirtualInstrumentUpdate', {
            VirtualInstrumentId: event.data.VirtualInstrumentId,
            Name: event.data.Name,
            ValidFromTs: event.data.ValidFromTs,
            ValidToTs: event.data.ValidToTs,
          })
          .then((resp) => {
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
      ValidFromTs(event, successCallback, failCallback) {
        if (event.newValue !== event.oldValue) {
          importal
            .post('ViVirtualInstrumentUpdate', {
              VirtualInstrumentId: event.data.VirtualInstrumentId,
              Name: event.data.Name,
              ValidFromTs: event.data.ValidFromTs,
              ValidToTs: event.data.ValidToTs,
            })
            .then((resp) => {
              successCallback(resp.data, resp.data.length);
            })
            .catch(() => {
              failCallback();
            });
        }
      },
      ValidToTs(event, successCallback, failCallback) {
        if (event.newValue !== event.oldValue) {
          importal
            .post('ViVirtualInstrumentUpdate', {
              VirtualInstrumentId: event.data.VirtualInstrumentId,
              Name: event.data.Name,
              ValidFromTs: event.data.ValidFromTs,
              ValidToTs: event.data.ValidToTs,
            })
            .then((resp) => {
              successCallback(resp.data, resp.data.length);
            })
            .catch(() => {
              failCallback();
            });
        }
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    return new Promise((resolve) => {
      importal.get('DevInstrumentList')
        .then((resp) => {
          resolve({
            Model: {
              Instrument: null,
              Name: null,
            },
            Schema: {
              Instrument: {
                label: 'Instrument',
                type: 'select',
                returnObject: true,
                itemText: 'Name',
                items: resp.data,
                flex: 12,
              },
              Name: { label: i18n.t('VirtualInstrument.DefaultName'), type: 'text', flex: 12 },
            },
          });
        });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    importal
      .post('ViVirtualInstrumentNew', {
        InstrumentId: data.Instrument.InstrumentId,
        Name: data.Name,
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async apiViVirtualInstrumentRemove(api, data) {
    const resp = await importal
      .post('ViVirtualInstrumentRemove', {
        VirtualInstrumentId: data.VirtualInstrumentId,
      });
    api.setRowData(resp.data);
  }
}
