<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar
        dark
        :color="options.color"
        dense
        flat
      >
        <v-toolbar-title class="white--text">
          {{ options.title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4"
      >
        {{ message }}
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn
          v-if="!noCancel"
          color="grey"
          text
          @click.native="cancel"
        >
          {{ options.cancelText }}
        </v-btn>
        <v-btn
          text
          @click.native="agree"
        >
          {{ options.okText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    noCancel: false,
    options: {
      title: 'Confirm',
      okText: 'OK',
      cancelText: 'Cancel',
      color: null,
      width: 600,
      zIndex: 200,
    },
  }),
  methods: {
    open(message, noCancel = false, options = null) {
      this.dialog = true;
      this.message = message;
      this.noCancel = noCancel;
      if (options) {
        this.options = Object.assign(this.options, options);
      } else {
        this.options.title = this.$root.$i18n.t('ConfirmDeletion');
        this.options.cancelText = this.$root.$i18n.t('Cancel');
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

<style>

</style>
