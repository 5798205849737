<template>
  <v-container fluid>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ item.Details }}</v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-form
          :readonly="true"
        >
          <DataImportForm
            :item="item"
          />
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import DataImportForm from './DataImportForm.vue';

export default {
  components: {
    DataImportForm,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['loggerConfiguration', 'timeStamps', 'fileTypes']),
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
h2 {
  padding:15px;
  padding-left: 0px;
}
h3 {
  padding:15px;
  padding-left: 0px;
}
h4 {
  padding:15px;
  padding-top:0px;
}
</style>
