<template>
  <v-tooltip :disabled="!tooltip" bottom>
    <template #activator="{ on }">
      <v-text-field
        v-model="model"
        :label="label"
        class="pt-2 mx-1"
        :style="{ width: width }"
        prepend-icon="mdi-magnify"
        append-icon="mdi-arrow-right-circle"
        hide-details
        v-on="on"
        @click:append="onSearched(model)"
        @keyup.enter="onSearched(model)"
      />
    </template>
    <div style="white-space: pre;">
      {{ tooltip }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'SearchTextField',
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: undefined,
    },
    onSearched: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: null,
    };
  },
  created() {
    this.model = this.value;
  },
  watch: {
    value() {
      this.model = this.value;
    },
  },
};
</script>
