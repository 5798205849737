/* eslint-disable no-underscore-dangle */
import L from 'leaflet';

export default L.Handler.extend({
  initialize(map) {
    this._map = map;
    this._container = map._container;
    this._pane = map._panes.overlayPane;
    this._handlers = [];
  },

  addHooks() {
    L.DomEvent.on(this._container, 'mousedown', this._onMouseDown, this);
  },

  removeHooks() {
    L.DomEvent.off(this._container, 'mousedown', this._onMouseDown);
  },

  _documentRegisterHandler(event, handler, context) {
    const f = {
      event,
      handler: (e) => { handler.apply(context, [e]); },
    };
    document.addEventListener(f.event, f.handler);
    // L.DomEvent
    //  .on(document, f.event, f.handler, context);
    this._handlers.push(f);
  },

  _documentClearHandlers() {
    this._handlers.forEach((f) => {
      // L.DomEvent.off(document, f.event, f.handler);
      document.removeEventListener(f.event, f.handler);
    });
    this._handlers = [];
  },

  _onMouseDown(e) {
    if ((!e.shiftKey && !e.ctrlKey) || ((e.which !== 1) && (e.button !== 1))) { return false; }

    L.DomUtil.disableTextSelection();

    this._startLayerPoint = this._map.mouseEventToLayerPoint(e);

    this._box = L.DomUtil.create('div', 'leaflet-zoom-box', this._pane);
    L.DomUtil.setPosition(this._box, this._startLayerPoint);

    // TODO refactor: move cursor to styles
    this._container.style.cursor = 'crosshair';

    // L.DomEvent
    //   .on(document, 'mousemove', this._onMouseMove, this)
    //   .on(document, 'mouseup', this._onMouseUp, this)
    //   .on(document, 'keydown', this._onKeyDown, this);
    this._documentRegisterHandler('mousemove', this._onMouseMove, this);
    this._documentRegisterHandler('mouseup', this._onMouseUp, this);
    this._documentRegisterHandler('keydown', this._onKeyDown, this);
    L.DomEvent.preventDefault(e);

    this.selectMode = e.shiftKey && !e.ctrlKey;
    this.zoomMode = e.shiftKey && e.ctrlKey;
    if (this.selectMode) {
      this._map.fire('boxselectstart');
    } else {
      this._map.fire('boxzoomstart');
    }

    return true;
  },

  _onMouseMove(e) {
    const startPoint = this._startLayerPoint;
    const box = this._box;

    const layerPoint = this._map.mouseEventToLayerPoint(e);
    const offset = layerPoint.subtract(startPoint);

    const newPos = new L.Point(
      Math.min(layerPoint.x, startPoint.x),
      Math.min(layerPoint.y, startPoint.y),
    );

    L.DomUtil.setPosition(box, newPos);

    // TODO refactor: remove hardcoded 4 pixels
    box.style.width = `${Math.max(0, Math.abs(offset.x) - 4)}px`;
    box.style.height = `${Math.max(0, Math.abs(offset.y) - 4)}px`;
  },

  _finish() {
    if (this._pane.contains(this._box)) {
      this._pane.removeChild(this._box);
    }
    this._container.style.cursor = '';

    L.DomUtil.enableTextSelection();

    this._documentClearHandlers();
    // L.DomEvent
    //   .off(document, 'mousemove', this._onMouseMove)
    //   .off(document, 'mouseup', this._onMouseUp)
    //   .off(document, 'keydown', this._onKeyDown);
  },

  _onMouseUp(e) {
    this._finish();

    const map = this._map;
    const layerPoint = map.mouseEventToLayerPoint(e);

    if (this._startLayerPoint.equals(layerPoint)) { return; }

    const bounds = new L.LatLngBounds(
      map.layerPointToLatLng(this._startLayerPoint),
      map.layerPointToLatLng(layerPoint),
    );

    if (this.selectMode) {
      const contained = [];
      this._map.eachLayer((l) => {
        if (l instanceof L.Marker && bounds.contains(l.getLatLng())) {
          contained.push(l);
        }
      });
      map.fire('boxselectend', {
        boxSelectBounds: bounds,
        containedMarkers: contained,
      });
    } else {
      map.fitBounds(bounds);
      map.fire('boxzoomend', {
        boxZoomBounds: bounds,
      });
    }
  },

  _onKeyDown(e) {
    if (e.keyCode === 27) {
      this._finish();
    }
  },
});
