import LandOwnerList from './landOwners/components/LandOwnerList.vue';
import LandOwnerFormModal from './landOwners/components/LandOwnerFormModal.vue';
import LandOwnerEmailList from './landOwnerEmails/components/LandOwnerEmailList.vue';
import LandOwnerEmailFormModal from './landOwnerEmails/components/LandOwnerEmailFormModal.vue';
import LandOwnerPhoneList from './landOwnerPhones/components/LandOwnerPhoneList.vue';
import LandOwnerPhoneFormModal from './landOwnerPhones/components/LandOwnerPhoneFormModal.vue';

export default [
  {
    path: '/Settings/LandOwners',
    name: 'LandOwners.List',
    component: LandOwnerList,
    children: [
      {
        path: '/Settings/LandOwners/Create',
        name: 'LandOwners.Create',
        component: LandOwnerFormModal,
      },
      {
        path: '/Settings/LandOwners/:id',
        name: 'LandOwners.Update',
        component: LandOwnerFormModal,
        props: true,
        children: [
          {
            path: '/Settings/LandOwners/:id/Emails/',
            name: 'LandOwners.Emails',
            component: LandOwnerEmailList,
          },
          {
            path: '/Settings/LandOwners/:id/Emails/Create',
            name: 'LandOwners.Emails.Create',
            component: LandOwnerEmailFormModal,
          },
          {
            path: '/Settings/LandOwners/:id/Emails/:emailId',
            name: 'LandOwners.Emails.Update',
            component: LandOwnerEmailFormModal,
            props: true,
          },
          {
            path: '/Settings/LandOwners/:id/Phones/',
            name: 'LandOwners.Phones',
            component: LandOwnerPhoneList,
          },
          {
            path: '/Settings/LandOwners/:id/Phones/Create',
            name: 'LandOwners.Phones.Create',
            component: LandOwnerPhoneFormModal,
          },
          {
            path: '/Settings/LandOwners/:id/Phones/:phoneId',
            name: 'LandOwners.Phones.Update',
            component: LandOwnerPhoneFormModal,
            props: true,
          },
        ],
      },
    ],
  },
];
