/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import i18n from '@/plugins/i18n';
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsSensors extends ServerSideDataGridGsClientBase {
  constructor(vue, cb) {
    super();
    this.vue = vue;
    this.callback = cb;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    const { vue } = this;

    return new Promise((resolve) => {
      resolve([
        {
          width: 30,
          resizable: false,
          cellStyle: { padding: 0 },
          hide: this.vue.logger.PeNumber != null,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            confirm: (event) => (`Sensor "${event.data.Name}"`),
            clicked: (event) => {
              this.editSensor(event.data);
            },
          },
        },
        { field: 'SensorId', width: 80, headerName: i18n.t('Instruments.Id') },
        { field: 'Name', editable: true, headerName: i18n.t('Instruments.Name') },
        { field: 'AccessGroupName', width: 420, headerName: i18n.t('Instruments.AccessGroupName') },
        { field: 'Unit', width: 100, headerName: i18n.t('Instruments.Unit') },
        {
          width: 30,
          resizable: false,
          cellStyle: { padding: 0 },
          hide: this.vue.logger.PeNumber != null,
          cellRenderer: vue.$auth.roles.includes('Web.Sensor.Create') ? 'DeleteButtonIdCellRenderer' : null,
          cellRendererParams: {
            confirm: (event) => (`Sensor "${event.data.Name}"`),
            clicked: (event) => {
              this.apiDevSensorRemove(event.api, event.data);
            },
          },
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue } = this;
    return {
      getRows: function GetRows(params) {
        importal.get(`DevSensorList?InstrumentId=${vue.$route.params.id}`)
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // Called when an editable column is changed
  // The function name must match the data Model
  // MAY call successCallback if the API call returns the table data
  onChange() {
    return {
      Name(event, successCallback, failCallback) {
        importal
          .post('DevSensorRename', {
            SensorId: event.data.SensorId,
            Name: event.data.Name,
          })
          .then((resp) => {
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve({
        Model: {
          InstrumentId: Number(vue.$route.params.id),
          Name: null,
        },
        Schema: {
          InstrumentId: { label: i18n.t('Instruments.InstrumentId'), type: 'text', disabled: true },
          Name: {
            label: i18n.t('Instruments.SensorName'), type: 'text', flex: 12,
          },
        },
      });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    importal
      .post('DevSensorNew', {
        InstrumentId: data.InstrumentId,
        Name: data.Name,
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async apiDevSensorRemove(api, data) {
    const resp = await importal
      .post('DevSensorRemove ', {
        SensorId: data.SensorId,
      });
    api.setRowData(resp.data);
    this.vue.onLengthChanged(resp.data.length);
  }

  editSensor(data) {
    this.vue.$router.push({ name: 'SensorEdit', params: { id: data.SensorId } });
  }
}
