<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-card-text>
          <v-container fluid>
            <DateTimePicker
              v-if="!readMode"
              v-model="formData.Date"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              :has-clear-button="false"
              :label="$t('QualityCheck.QCInspectionNotes.Date')"
              :rules="[v => !!v || $t('Validation.Required')]"
            />
            <v-text-field
              v-else
              v-model="formData.Date"
              readonly
              :label="$t('QualityCheck.QCInspectionNotes.Date')"
            />
            <v-textarea
              v-model="formData.Note"
              :label="$t('QualityCheck.QCInspectionNotes.Note')"
              :rules="[v => !!v || $t('Validation.Required')]"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line
import { mapState } from 'vuex';
import datehandling from '@/components/datehandling';
import i18n from '@/plugins/i18n';
import DateTimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DateTimePicker,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    noteId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        QCInspectionId: parseInt(this.id, 10),
        Date: new Date(),
      },
      show: false,
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
      readMode: !this.$auth.roles.includes('Web.QCInspection.Manage'),
    };
  },
  computed: {
    ...mapState('qualityCheck/qcInspectionNotes', ['note']),
  },
  watch: {
    async noteId() {
      await this.getInspectionNote();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.noteId && this.noteId != null) {
      await this.getInspectionNote();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    async getInspectionNote() {
      await this.$store.dispatch('qualityCheck/qcInspectionNotes/get', { noteId: this.noteId });
      this.note.Date = datehandling.zonedTimeToUtc(this.note.Date);
      this.formData = { ...this.note };
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'QualityCheck.QCInspections.Summary' });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.noteId) {
          await this.$store.dispatch('qualityCheck/qcInspectionNotes/update', this.formData);
        } else {
          await this.$store.dispatch('qualityCheck/qcInspectionNotes/create', this.formData);
        }
        this.navigateToList();
      }
    },
  },
};
</script>
