/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsGateway extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: i18n.t('Devices.Header_Gateways.Edit'),
          width: 80,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            confirm: (event) => (`Instrument "${event.data.Name}"`),
            clicked: (event) => {
              this.EditGateway(event.data);
            },
          },
        },
        {
          field: 'DataGatewayId',
          headerName: i18n.t('Devices.Header_Gateways.DataGatewayId'),
          width: 300,
          resizable: true,
        },
        {
          field: 'Name',
          headerName: i18n.t('Devices.Header_Gateways.Name'),
          width: 300,
          editable: true,
          filter: true,
          sortable: true,
        },
        {
          field: 'ProtocolCd',
          headerName: i18n.t('Devices.Header_Gateways.ProtocolCd'),
          editable: true,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['adhoc', 'ftp', 'sftp', 'envfrance', 'envcanada', 'findmespot', 'quantaq', 'elevenx', 'environet', 'sepa', 'hobolink', 'mqtt', 'kiwis'], // MAX 10 chars
          },
        },
        {
          field: 'AccessGroupName',
          headerName: i18n.t('Devices.Header_Gateways.AccessGroupName'),
          width: 300,
          filter: true,
          sortable: true,
        },
        {
          field: '',
          headerName: i18n.t('Devices.Header_Gateways.Delete'),
          width: 80,
          resizable: true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (`Instrument "${event.data.Name}"`),
            clicked: (event) => {
              this.vue.$store.dispatch('devices/gateways/remove', event.data.DataGatewayId);
            },
          },
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  /*
  dataSource() {
    return {
      getRows: (params) => {
        importal.get('DevGatewayList')
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  } */

  // Called when an editable column is changed
  // The function name must match the data Model
  // MAY call successCallback if the API call returns the table data
  onChange() {
    const { $store } = this.vue;
    return {
      Name(event) {
        $store.dispatch('devices/gateways/rename', {
          DataGatewayId: event.data.DataGatewayId,
          Name: event.data.Name,
        });
      },
      ProtocolCd(event) {
        $store.dispatch('devices/gateways/updateProtocolCd', {
          DataGatewayId: event.data.DataGatewayId,
          ProtocolCd: event.data.ProtocolCd,
        });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    const { $store } = this.vue;
    return new Promise((resolve) => {
      $store.dispatch('devices/gateways/accessGroupList')
        .then(() => {
          resolve({
            Model: {
              AccessGroup: null,
              Name: null,
            },
            Schema: {
              AccessGroup: {
                label: i18n.t('Devices.Gateway.AddNew.AccessGroup'),
                type: 'select',
                returnObject: true,
                itemText: 'AccessGroupName',
                items: $store.state.devices.gateways.accessgroups,
                flex: 12,
              },
              Name: { label: i18n.t('Devices.Gateway.AddNew.GatewayName'), type: 'text', flex: 12 },
            },
          });
        });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data) {
    this.vue.$store.dispatch('devices/gateways/create', {
      AccessGroupId: data.AccessGroup.AccessGroupId,
      Name: data.Name,
    });
  }

  async EditGateway(data) {
    await this.vue.$store.dispatch('devices/gateways/gatewayReadmode', data.DataGatewayId);
    this.vue.$router.push(`/devices/gateway/${data.DataGatewayId}`);
  }
}
