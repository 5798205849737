/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import i18n from '@/plugins/i18n';
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsMails extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: i18n.t('Settings.MailList.Header.Edit'),
          width: 80,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            confirm: (event) => (`Mail List "${event.data.Name}"`),
            clicked: (event) => {
              this.vue.Edit(event.data);
            },
          },
        },
        {
          field: 'MailListUid', minWidth: 300, resizable: true, headerName: i18n.t('Settings.MailList.Header.MailListUid'),
        },
        {
          field: 'Name', width: 180, headerName: i18n.t('Settings.MailList.Header.Name'),
        },
        {
          field: 'AccessGroupName', minWidth: 300, headerName: i18n.t('Settings.MailList.Header.AccessGroupName'),
        },
        {
          field: '',
          headerName: i18n.t('Settings.MailList.Header.Delete'),
          width: 80,
          resizable: true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (`Mail List "${event.data.Name}"`),
            clicked: (event) => {
              this.DeleteMailList(event.api, event.data);
            },
          },
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    return {
      getRows: (params) => {
        importal.get('AlertMailListList')
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // Called when an editable column is changed
  // The function name must match the data Model
  // MAY call successCallback if the API call returns the table data
  onChange() {
    return {

    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    return new Promise((resolve) => {
      importal.get('AccessGroupList')
        .then((resp) => {
          resolve({
            Model: {
              MailId: crypto.randomUUID(),
              Name: null,
              AccessGroupId: null,
            },
            Schema: {
              MailId: {
                label: i18n.t('Settings.MailList.MailListId'), type: 'text', disabled: true, flex: 12,
              },
              Name: { label: i18n.t('Settings.MailList.Header.Name'), type: 'text', flex: 12 },
              AccessGroupId: {
                label: i18n.t('Settings.MailList.AccessGroup'),
                type: 'select',
                returnObject: true,
                itemText: 'AccessGroupName',
                items: resp.data,
                flex: 12,
              },
            },
          });
        });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    importal
      .post('AlertMailListNew', {
        MailListUid: data.MailId,
        AccessGroupId: data.AccessGroupId.AccessGroupId,
        Name: data.Name,
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async DeleteMailList(api, data) {
    const { vue } = this;
    vue.$store.commit('app/pleaseWait', true);
    await importal
      .post('AlertMailListRemove', {
        MailListUid: data.MailListUid,
      })
      .catch((err) => {
        if (err.response.data.exception) {
          // this.showError(err.response.data.exception);
        }
        vue.$store.commit('app/pleaseWait', false);
      })
      .then((resp) => {
        api.setRowData(resp.data);
        vue.$store.commit('app/pleaseWait', false);
      });
  }
}
