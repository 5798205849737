<template>
  <div style="height: 100%; width: 100%">
    <v-container
      fluid
      fill-height
    >
      <v-row class="align-self-start">
        <v-col>
          <v-tabs v-model="selectedTab">
            <v-tab key="details">
              {{ $t('WindServer.Sites.Attachments.Details') }}
            </v-tab>
            <v-tab key="thumbnails">
              {{ $t('WindServer.Sites.Attachments.Thumbnails') }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-tabs-items
        v-model="selectedTab"
        style="height: 100%; width: 100%"
      >
        <v-tab-item
          key="details"
          style="height: 70%"
        >
          <SiteFilesList />
        </v-tab-item>
        <v-tab-item
          key="thumbnails"
          style="height: 70%"
        >
          <SiteFilesThumbnails />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <router-view />
  </div>
</template>
<script>
import SiteFilesThumbnails from './SiteFilesThumbnails.vue';
import SiteFilesList from './SiteFilesList.vue';

export default {
  components: {
    SiteFilesThumbnails,
    SiteFilesList,
  },
  data() {
    return {
      selectedTab: null,
    };
  },
};
</script>
