<template>
  <span>{{ formatted }}</span>
</template>

<script>
import datehandling from '@/components/datehandling';

export default {
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    formatted() {
      return datehandling.formatForDisplay(this.params.value);
    },
  },
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params);
    },
  },
};
</script>
