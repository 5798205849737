<template>
  <v-simple-table
    dense
    fixed-header
    height="100%"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left name" v-t="'Customers.Sites.DetailsAndTimeline.FullName'" />
          <th class="text-left address" v-t="'Customers.Sites.DetailsAndTimeline.Address'" />
          <th class="text-left phones" v-t="'Customers.Sites.DetailsAndTimeline.Phones'" />
          <th class="text-left emails" v-t="'Customers.Sites.DetailsAndTimeline.Emails'" />
          <th class="text-left note" v-t="'Customers.Sites.DetailsAndTimeline.Note'" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(owner, idx) in site.LandOwners"
          :key="idx"
        >
          <td class="name">{{ owner.Fullname }}</td>
          <td class="address">{{ owner.Address }}</td>
          <td class="phones">
            <div
              v-for="(phone, pIdx) in owner.Phones"
              :key="pIdx">
              <v-icon small>{{ getPhoneIcon(phone) }}</v-icon> <a :href="`tel:${phone.Number}`">{{ phone.Number }}</a>
            </div>
          </td>
          <td class="emails">
            <div
              v-for="(email, eIdx) in owner.Emails"
              :key="eIdx">
              <a :href="`mailto:${email}`">{{ email }}</a>
            </div>
          </td>
          <td class="note">{{ owner.Note }}</td>
        </tr>
        <tr v-if="site.LandOwners.length == 0">
          <td colspan="5" class="text-center" v-t="'Customers.Sites.DetailsAndTimeline.NoDataToDisplay'" />
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('customers/sites', ['site']),
  },
  methods: {
    getPhoneIcon(phone) {
      switch (phone.PhoneTypeId) {
        case 1:
          return 'mdi-domain';
        case 2:
          return 'mdi-cellphone-basic';
        case 3:
          return 'mdi-fax';
        default:
          return 'mdi-home';
      }
    },
  },
};
</script>

<style scoped>
  tr {
    vertical-align: top;
  }

  td {
    padding: 12px !important;
  }

  .address {
    width: 350px;
  }

  .name, .phones, .emails {
    width: 200px;
  }
</style>
