<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title>
          {{ $t('WindServer.Sites.DataTrace.ConvertTimestamps') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="formData.Interval"
            :label="$t('WindServer.Sites.DataTrace.Intervals')"
            :rules="numberRule"
            @change="onChange"
          />
          <v-container
            fluid
            class="row"
          >
            <v-card class="col col-5">
              <v-card-text>
                <v-textarea
                  v-model="formData.Timestamps"
                  clearable
                  :autofocus="true"
                  :label="$t('WindServer.Sites.DataTrace.Timestamps')"
                  rows="10"
                  @input="onChange"
                  @click:clear="clearTimestamps()"
                />
              </v-card-text>
            </v-card>
            <v-card class="col col-7">
              <v-card-text>
                <v-textarea
                  v-model="formattedDateRages"
                  :label="$t('WindServer.Sites.DataTrace.DateRanges')"
                  rows="10"
                  :readonly="true"
                />
              </v-card-text>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import datehandling from '@/components/datehandling';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        Interval: 600,
        Timestamps: null,
      },
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      numberRule: [(v) => (!Number.isNaN(parseFloat(v))) || this.$t('Validation.Required')],
    };
  },
  computed: {
    ...mapState('site/dataTrace', ['dateRanges', 'parseError']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    formattedDateRages() {
      let d = '';
      if (this.dateRanges) {
        this.dateRanges.forEach((x) => {
          d += datehandling.formatForDisplay(x.FlagStart);
          d += i18n.t('WindServer.Sites.DataTrace.To');
          d += datehandling.formatForDisplay(x.FlagEnd);
          d += '\n';
        });
      }
      return d;
    },
  },
  methods: {
    async setFormData() {
      this.formData = {
        Interval: 600,
        Timestamps: null,
      };
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          await this.$store.dispatch('site/dataTrace/intervals', this.formData);
          this.close();
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/dataTrace/intervals', null);
    },
    clearTimestamps() {
      this.formData.Timestamps = null;
      this.formData.DateRanges = null;
    },
    async onChange() {
      this.formData.Interval = Number(this.formData.Interval);
      await this.$store.dispatch('site/dataTrace/parseTimestamps', this.formData);
    },
  },
};
</script>
