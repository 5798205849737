/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsScriptTemplates extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve([
        {
          field: 'VITplTemplateId',
          headerName: this.vue.$t('Edit'),
          width: 120,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked(params) {
              vue.$router.push(`/vitpl/${params.data.VITplTemplateId}`);
            },
          },
        },
        {
          field: 'Name',
          headerName: this.vue.$t('VirtualInstrument.Header.Name'),
          editable: true,
          sortable: true,
        },
        {
          field: 'Description',
          headerName: this.vue.$t('VirtualInstrument.Header.Description'),
          editable: true,
          sortable: true,
        },
        {
          field: 'ValidFromTs',
          headerName: this.vue.$t('VirtualInstrument.Header.ValidFromTs'),
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
          },
          sortable: false,
          resizable: true,
        },
        {
          field: 'ValidToTs',
          headerName: this.vue.$t('VirtualInstrument.Header.ValidToTs'),
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
          },
          sortable: false,
          resizable: true,
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue } = this;
    return {
      getRows: function GetRows(params) {
        importal.get(`TplsScriptTemplateList?tenantId=${vue.tenantId}&tplsScriptId=${vue.$route.params.id}`)
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }
}
