<template>
  <div>
    <v-dialog
      v-model="form.show"
      persistent
      transition="dialog-bottom-transition"
      content-class="editor-dialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title>{{ $t('ReplicationControl') }}</v-card-title>
        <v-card-text
          v-if="ForceEditReplicationNumber"
          class="pb-0 pt-0"
        >
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="ReplicationNumber"
                :label="$t('NumberOfClones')"
                type="number"
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-if="!ForceEditReplicationNumber"
          class="pb-0 pt-0"
        >
          <v-row>
            <v-col sm="11">
              <v-select
                v-model="SelectedParameter"
                :items="ParameterSelectorItems"
                :label="$t('ParameterSet')"
              />
            </v-col>
            <v-col sm="1">
              <toolbar-button-with-tooltip
                :tooltip-text="$t('ChangeReplicationNumber')"
                icon-name="mdi-sheep"
                @click="onEditReplicationNumber"
              />
              <toolbar-button-with-tooltip
                :tooltip-text="$t('PasteFromClipboard')"
                icon-name="mdi-clipboard"
                :disabled="pasteButtonDisabled"
                @click="onClipboard"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <ag-grid-vue
                v-if="SelectedParameter !== null"
                style="width: 100%; height: 400px;"
                class="ag-theme-balham-dark"
                edit-type="fullRow"
                :column-defs="columnDefs"
                :row-data="rowData"
                :row-selection="'single'"
                @grid-ready="onGridReady"
                @cell-value-changed="onCellValueChanged"
                @row-double-clicked="onRowDoubleClicked"
              />
              <!-- @selection-changed="onGridSelectionChanged" -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="ForceEditReplicationNumber"
          class="justify-end"
        >
          <v-btn
            text
            @click.stop="onAccept"
          >
            {{ $t('Clone') }}
          </v-btn>
          <v-btn
            text
            @click.stop="form.show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-if="!(ForceEditReplicationNumber)"
          class="justify-end"
        >
          <v-btn
            ve
            text
            :disabled="applyButtonDisabled"
            @click.stop="onApply"
          >
            {{ $t('Apply') }}
          </v-btn>
          <v-btn
            text
            @click.stop="onClose"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editor.show"
      transition="dialog-bottom-transition"
      content-class="editor-dialog"
      max-width="800"
    >
      <form-piece-input-sensor-binding
        :init="editor"
        :initial-search="SensorInitialSearch"
        @editor-update="onSensorBindingUpdate"
      />
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import importal from '@/api/importal';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { AgGridVue } from 'ag-grid-vue';
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import datehandling from '@/components/datehandling';
import FormPieceInputSensorBinding from './FormPieceInputSensorBinding.vue';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function onlyUniqueSensorConstants(value, index, self) {
  return self.findIndex((y) => y.InstrumentName === value.InstrumentName
                  && y.Symbol === value.Symbol
                  && y.ValidFromTs.getTime() === value.ValidFromTs.getTime()
                  && y.ValidToTs?.getTime() === value.ValidToTs?.getTime()) === index;
}

const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true });

function dateFormatter(params) {
  if (params.value) {
    return datehandling.formatForDisplay(params.value);
  }
  return '';
}

export default {
  components: {
    ToolbarButtonWithTooltip,
    AgGridVue,
    FormPieceInputSensorBinding,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    replicationGroupId: {
      type: String,
      default: () => (null),
    },
    viData: {
      type: Object,
      default: () => (null),
    },
  },
  data: () => ({
    columnDefs: null,
    rowData: null,
    gridApi: null,
    columnApi: null,
    ReplicationNumber: 1,
    ForceEditReplicationNumber: false,
    GroupInfo: null,
    SelectedFormula: null,
    form: {
      show: false,
    },
    editor: {
      editorMode: null,
      node: null,
      show: false,
      FormulaText: '',
      OutputSymbol: '',
      OutputSymbolErrors: [],
    },
    SensorInitialSearch: '',
    SelectedParameter: null,
    ParameterSelectorItems: [
      { text: i18n.t('VirtualInstrument.EditOptions.ReplicationGroup.ConstantSensor'), value: 'CS' },
      { text: i18n.t('VirtualInstrument.EditOptions.ReplicationGroup.InputSensor'), value: 'IS' },
      // { text: 'Input Vector', value: 'IV' }, // TODO: Implement this!
      // { text: 'Constant Vector', value: 'CV' }, // TODO: Implement this!
      // { text: 'Baseline Sensor', value: 'BS' }, // TODO: Implement this!
      // { text: 'Baseline Vector', value: 'BV' }, // TODO: Implement this!
    ],
    ItemsToSave: [],
  }),
  computed: {
    applyButtonDisabled() {
      return this.ItemsToSave.length === 0;
    },
    pasteButtonDisabled() {
      return !(this.SelectedParameter === 'CS');
    },
  },
  watch: {
    async value(val) {
      this.form.show = val;
      if (this.form.show) {
        await this.initialize();
      }
    },
    'form.show': function show() {
      if (!this.form.show) {
        this.$emit('input', this.form.show);
      }
    },
    SelectedParameter(ev) {
      if (ev !== null && this.form.show) {
        this.$nextTick(async () => this.fillGrid(ev));
      }
    },
  },
  async mounted() {
    await this.initialize();
  },
  i18n: {
    messages: {
      en: {
        Cancel: 'Cancel',
        Close: 'Close',
        ParameterSet: 'Parameter Set',
        ChangeReplicationNumber: 'Change replication number',
        PasteFromClipboard: 'Paste from clipboard',
        Clone: 'Clone',
        Apply: 'Apply',
        ReplicationControl: 'Replication control',
      },
      fr: {
        Cancel: 'Annuler',
        Close: 'Fermer',
        ParameterSet: 'Paramètres',
        ChangeReplicationNumber: 'Changer le nombre de réplication',
        PasteFromClipboard: 'Coller du presse-papiers',
        Clone: 'Cloner',
        Apply: 'Appliquer',
        ReplicationControl: 'Contrôle de réplication',
      },
    },
  },
  methods: {
    async initialize() {
      this.ForceEditReplicationNumber = false;
      if (!this.SelectedParameter) {
        // eslint-disable-next-line prefer-destructuring
        this.SelectedParameter = this.ParameterSelectorItems[0].value;
      }
      this.ItemsToSave = [];
      this.$store.commit('app/pleaseWait', true);
      await this.fillGrid(this.SelectedParameter)
        .then(() => {
          this.$store.commit('app/pleaseWait', false);
        })
        .catch(() => {
          this.$store.commit('app/pleaseWait', false);
        });
    },
    onAccept() {
      if (this.ForceEditReplicationNumber) {
        this.$emit('on-accept', {
          ReplicationNumber: this.ReplicationNumber,
        });
      }
    },
    onClose() {
      this.form.show = false;
      this.$emit('on-close');
    },
    onRowDoubleClicked() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      if (selectedData.length > 0) {
        switch (this.SelectedParameter) {
          case 'IS':
            this.editor.node = {
              VirtualInstrumentId: selectedData[0].$info.VirtualInstrumentId,
              SensorId: selectedData[0].SensorId,
              Symbol: selectedData[0].Symbol,
              InputAggregation: selectedData[0].InputAggregation,
              InputTransform: selectedData[0].InputTransform,
              TransformXUnit: selectedData[0].TransformXUnit,
              TransformYUnit: selectedData[0].TransformYUnit,
              SensorName: selectedData[0].SensorName,
              InstrumentName: selectedData[0].InstrumentName,
              nodeType: 'input_sensor',
            };
            this.SensorInitialSearch = selectedData[0].$master.input_sensors
              .find((x) => x.Symbol === selectedData[0].Symbol)
              .SensorName;
            this.editor.show = true;
            break;
          default:
            break;
        }
      }
    },
    async onSensorBindingUpdate(ev) {
      const { node } = ev;
      if (node.SensorId) {
        this.$store.commit('app/pleaseWait', true);
        await importal.post('ViInputSensorUpdate', {
          VirtualInstrumentId: node.VirtualInstrumentId,
          SensorId: node.SensorId,
          Symbol: node.Symbol,
          InputAggregation: node.InputAggregation,
          InputTransform: node.InputTransform,
          TransformXUnit: node.TransformXUnit,
          TransformYUnit: node.TransformYUnit,
          CanUpdate: true,
          AttemptAutoWiring: true,
        });
        this.editor.show = false;
        await this.fillGrid(this.SelectedParameter);
        this.$store.commit('app/pleaseWait', false);
      }
    },
    // Add edited values to the Apply Buffer
    addCellValueToApplyBuffer(data, fields) {
      switch (this.SelectedParameter) {
        case 'CS':
          {
            const master = data.$master;
            const info = data.$info;

            for (let i = 0; i < fields.length; i += 1) {
              const symbol = fields[i];
              const m = master.sensor_constants
                .find((y) => y.Symbol === symbol
                  && y.ValidFromTs.getTime() === data.ValidFromTs.getTime()
                  && y.ValidToTs?.getTime() === data.ValidToTs?.getTime());
              const s = info.sensor_constants
                .find((y) => y.Symbol === symbol
                  && y.ValidFromTs.getTime() === data.ValidFromTs.getTime()
                  && y.ValidToTs?.getTime() === data.ValidToTs?.getTime());
              if (s) {
                if (data[s.Symbol] && String(data[s.Symbol]).length > 0) {
                  const kk = `ViInputSensorConstantUpdate-${info.VirtualInstrumentId}-${s.Symbol}-${s.ValidFromTs}-${s.ValidToTs}`;
                  this.ItemsToSave = this.ItemsToSave.filter((x) => x[0] !== kk);
                  this.ItemsToSave.push([kk,
                    () => importal.post('ViInputSensorConstantUpdate', {
                      VirtualInstrumentId: info.VirtualInstrumentId,
                      ConstantId: s.ConstantId,
                      Value: Number(data[s.Symbol]),
                      Symbol: s.Symbol,
                      ValidFromTs: s.ValidFromTs,
                      ValidToTs: s.ValidToTs,
                      Unit: s.Unit,
                      CanUpdate: true,
                    })]);
                } else {
                  const kk = `ViInputSensorConstantRemove-${info.VirtualInstrumentId}-${s.ConstantId}`;
                  this.ItemsToSave = this.ItemsToSave.filter((x) => x[0] !== kk);
                  this.ItemsToSave.push([kk,
                    () => importal.post('ViInputSensorConstantRemove', {
                      VirtualInstrumentId: info.VirtualInstrumentId,
                      ConstantId: s.ConstantId,
                    })]);
                }
              } else {
                const kk = `ViInputSensorConstantUpdate-${info.VirtualInstrumentId}-${s.Symbol}-${s.ValidFromTs}-${s.ValidToTs}`;
                this.ItemsToSave = this.ItemsToSave.filter((x) => x[0] !== kk);
                this.ItemsToSave.push([kk,
                  () => importal.post('ViInputSensorConstantUpdate', {
                    VirtualInstrumentId: info.VirtualInstrumentId,
                    Value: Number(data[m.Symbol]),
                    Symbol: m.Symbol,
                    ValidFromTs: m.ValidFromTs,
                    ValidToTs: m.ValidToTs,
                    Unit: m.Unit,
                    CanUpdate: true,
                  })]);
              }
            }
          }
          break;
        default:
          break;
      }
    },
    // Make sure that edition is complete and rows are saved
    commitEdition() {
      const editingCells = this.gridApi.getEditingCells();
      this.gridApi.stopEditing();
      this.gridApi.forEachNode((rowNode) => {
        const editingCell = editingCells.filter((x) => x.rowIndex === rowNode.rowIndex);
        if (editingCell.length > 0) {
          this.addCellValueToApplyBuffer(
            rowNode.data,
            editingCell.map((x) => x.column.getColDef().field),
          );
        }
      });
    },
    // A cell changed
    async onCellValueChanged(event) {
      const { data } = event;
      const fields = [event.colDef.field];
      this.addCellValueToApplyBuffer(data, fields);
    },
    // Process the Apply Buffer i.e: ItemsToSave that is an array of function returning Promises
    async onApply() {
      if (this.ItemsToSave && this.ItemsToSave.length > 0) {
        this.ItemsToSave.reverse(); // Reverse the list

        const itemsHash = {};
        const itemsFiltered = [];
        for (let i = 0; i < this.ItemsToSave.length; i += 1) {
          const item = this.ItemsToSave[i];
          if (!itemsHash[item[0]]) {
            itemsFiltered.push(item[1]);
            itemsHash[item[0]] = true;
          }
        }

        this.$store.commit('app/pleaseWait', true);
        this.commitEdition();
        this.$nextTick(async () => {
          await Promise.all(itemsFiltered.map((x) => x()))
            .then(async () => {
              this.ItemsToSave = [];
              await this.fillGrid(this.SelectedParameter)
                .then(() => {
                  this.$store.commit('app/pleaseWait', false);
                })
                .catch(() => {
                  this.$store.commit('app/pleaseWait', false);
                });
            })
            .catch(async () => {
              await this.fillGrid(this.SelectedParameter)
                .then(() => {
                  this.$store.commit('app/pleaseWait', false);
                })
                .catch(() => {
                  this.$store.commit('app/pleaseWait', false);
                });
            });
        });
      }
    },
    onClipboard() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const editableFields = this.gridApi.columnModel.columnDefs
        .filter((x) => x.editable).map((x) => x.field);
      if (editableFields.length > 0) {
        navigator.clipboard.readText()
          .then((data) => {
            const cols = data.split('\n')
              .map((x) => x.split('\t').map((y) => Number(y.replace(',', '.'))));
            if (cols.length >= 1 && editableFields.length === cols[0].length) {
              const itemsToUpdate = [];
              for (let j = 0; j < editableFields.length; j += 1) {
                const colName = editableFields[j];
                this.gridApi.forEachNodeAfterFilterAndSort((rowNode, rowIndex) => {
                  if (rowIndex === selectedNodes[0].rowIndex) {
                    const toChange = rowNode.data;
                    toChange[colName] = cols[0][j];
                    itemsToUpdate.push(toChange);
                  }
                });
              }
              this.gridApi.applyTransaction({ update: itemsToUpdate });
              itemsToUpdate.map((x) => this.addCellValueToApplyBuffer(x, editableFields));
            }
          });
      }
    },
    onEditReplicationNumber() {
      this.ForceEditReplicationNumber = true;
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    async fillGrid(selectedParameter) {
      if (this.replicationGroupId !== null) {
        this.$store.commit('app/pleaseWait', true);
        await importal
          .get(`ViVirtualInstrumentInfo?ReplicationGroupId=${this.replicationGroupId}`)
          .then((x) => x.data)
          .then((x) => {
            this.GroupInfo = x;
            this.fillGridInternal(selectedParameter);
            this.$store.commit('app/pleaseWait', false);
          });
      } else {
        this.GroupInfo = null;
        this.fillGridInternal(selectedParameter);
      }
    },
    fillGridInternal(selectedParameter) {
      if (true || this.replicationGroupId !== null) {
        let master = null;
        if (this.GroupInfo === null) {
          master = this.viData;
        } else if (this.GroupInfo instanceof Array) {
          master = this.GroupInfo
            .find((x) => Number(x.ReplicationGroupIndex) === 1);
        } else {
          master = this.GroupInfo;
        }
        this.ReplicationNumber = this.GroupInfo?.length ?? 1;

        this.rowData = [];
        this.columnDefs = [];
        switch (selectedParameter) {
          case 'IS':
            {
              const baseCols = [
                {
                  field: 'vi',
                  headerName: i18n.t('VirtualInstrument.EditOptions.ReplicationGroup.VI'),
                  sortable: false,
                  resizable: true,
                  suppressMovable: true,
                },
                {
                  field: 'Symbol',
                  headerName: i18n.t('VirtualInstrument.EditOptions.ReplicationGroup.Symbol'),
                  sortable: false,
                  resizable: true,
                  suppressMovable: true,
                },
                {
                  field: 'InstrumentName',
                  headerName: i18n.t('VirtualInstrument.EditOptions.ReplicationGroup.InstrumentName'),
                  sortable: false,
                  resizable: true,
                  suppressMovable: true,
                },
                {
                  field: 'SensorName',
                  headerName: i18n.t('VirtualInstrument.EditOptions.ReplicationGroup.SensorName'),
                  sortable: false,
                  resizable: true,
                  suppressMovable: true,
                },
              ];
              this.columnDefs = baseCols;
              let groupInfo = this.GroupInfo ?? [master];
              if (!(groupInfo instanceof Array)) {
                groupInfo = [groupInfo];
              }
              const rowSource = groupInfo
                .map((x) => master.input_sensors.map((m) => ({
                  $master: master,
                  $info: x,
                  vi: x.InstrumentName,
                  Symbol: m.Symbol,
                  InputAggregation: m.InputAggregation,
                  InputTransform: m.InputTransform,
                  TransformXUnit: m.TransformXUnit,
                  TransformYUnit: m.TransformYUnit,
                  SensorId: x.input_sensors
                    .find((y) => y.Symbol === m.Symbol)
                    ?.SensorId,
                  SensorName: x.input_sensors
                    .find((y) => y.Symbol === m.Symbol)
                    ?.SensorName,
                  InstrumentName: x.input_sensors
                    .find((y) => y.Symbol === m.Symbol)
                    ?.InstrumentName,
                })))
                .flat(1);
              this.rowData = rowSource;
            }
            break;
          case 'CS':
            {
              const baseCols = [
                {
                  field: 'InstrumentName',
                  headerName: i18n.t('VirtualInstrument.EditOptions.ReplicationGroup.InstrumentName'),
                  sortable: false,
                  resizable: true,
                  suppressMovable: true,
                },
                {
                  field: 'ValidFromTs',
                  headerName: i18n.t('VirtualInstrument.EditOptions.ReplicationGroup.From'),
                  sortable: false,
                  resizable: true,
                  valueFormatter: dateFormatter,
                  width: 150,
                  suppressMovable: true,
                },
                {
                  field: 'ValidToTs',
                  headerName: i18n.t('VirtualInstrument.EditOptions.ReplicationGroup.To'),
                  sortable: false,
                  resizable: true,
                  valueFormatter: dateFormatter,
                  suppressMovable: true,
                },
              ];
              this.columnDefs = this.columnDefs.concat(
                baseCols,
                master.sensor_constants
                  .map((x) => (x.Symbol))
                  .filter(onlyUnique)
                  .sort(sortAlphaNum)
                  .map((x) => ({
                    field: x,
                    headerName: x,
                    sortable: false,
                    resizable: true,
                    width: 80,
                    editable: true,
                    suppressMovable: true,
                  })),
              );
              let groupInfo = this.GroupInfo ?? [master];
              if (!(groupInfo instanceof Array)) {
                groupInfo = [groupInfo];
              }
              const rowSource = groupInfo
                .map((x) => [].concat(
                  master.sensor_constants.map((m) => ({
                    $master: master,
                    $info: x,
                    InstrumentName: x.InstrumentName,
                    ValidFromTs: m.ValidFromTs,
                    ValidToTs: m.ValidToTs,
                    Symbol: m.Symbol,
                    Value: x.sensor_constants
                      .find((y) => y.Symbol === m.Symbol
                          && y.ValidFromTs.getTime() === m.ValidFromTs.getTime()
                          && y.ValidToTs?.getTime() === m.ValidToTs?.getTime())
                      ?.Value,
                  })),
                  x.sensor_constants.map((m) => ({
                    $master: master,
                    $info: x,
                    InstrumentName: x.InstrumentName,
                    ValidFromTs: m.ValidFromTs,
                    ValidToTs: m.ValidToTs,
                    Symbol: m.Symbol,
                    Value: x.sensor_constants
                      .find((y) => y.Symbol === m.Symbol
                          && y.ValidFromTs.getTime() === m.ValidFromTs.getTime()
                          && y.ValidToTs?.getTime() === m.ValidToTs?.getTime())
                      ?.Value,
                  })),
                ))
                .flat(1)
                .filter(onlyUniqueSensorConstants);

              for (let i = 0; i < rowSource.length; i += 1) {
                const s = rowSource[i];
                let row = this.rowData
                  .find((x) => x.InstrumentName === s.InstrumentName
                    && x.ValidFromTs.getTime() === s.ValidFromTs.getTime()
                    && x.ValidToTs?.getTime() === s.ValidToTs?.getTime());
                if (!row) {
                  row = {
                    $master: s.$master,
                    $info: s.$info,
                    InstrumentName: s.InstrumentName,
                    ValidFromTs: s.ValidFromTs,
                    ValidToTs: s.ValidToTs,
                  };
                  this.rowData.push(row);
                }
                row[s.Symbol] = s.Value;
              }
            }
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>

<style>

</style>
