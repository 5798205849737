<template>
  <div class="d-flex flex-column fill-height">
    <SitesToolbar :viewMode.sync="viewMode" @refresh="refresh(searchOptions)" />
    <div class="d-flex flex-grow-1">
      <div class="flex-grow-1">
        <ListView v-show="viewMode === 0" />
        <MapView
          v-if="mapInitialized || viewMode === 1"
          v-show="mapInitialized && viewMode === 1"
        />
      </div>
      <SitesListFilters @update="o => refresh(o)"/>
    </div>
    <router-view />
  </div>
</template>

<script>
import ListView from './list/ListView.vue';
import MapView from './list/MapView.vue';
import SitesListFilters from './list/SearchOptions.vue';
import SitesToolbar from './list/SitesToolbar.vue';

export default {
  components: {
    ListView,
    MapView,
    SitesListFilters,
    SitesToolbar,
  },
  data() {
    return {
      searchOptions: {},
      viewMode: 0,
      mapInitialized: false,
    };
  },
  watch: {
    viewMode(newValue) {
      if (newValue === 1 && !this.mapInitialized) {
        this.mapInitialized = true;
      }
    },
  },
  created() {
    this.refresh(this.searchOptions);
  },
  methods: {
    refresh(options) {
      this.$store.dispatch('customers/sites/list', options);
      this.searchOptions = options;
    },
  },
};
</script>
