<template>
  <span
    v-if="!params.show || params.show(params)"
  >
    <ConfirmDialog
      v-model="showConfirm"
      :message="confirmMessage"
      @on-accept="onAccept"
    />
    <v-btn
      icon
      x-small
      style="vertical-align: baseline;"
      @click="btnClickedHandler()"
    >
      <v-icon
        v-if="params.data.LockStatus === 2"
        style="color:crimson;"
      >mdi-lock</v-icon>
      <v-icon v-if="params.data.LockStatus === 1">mdi-lock</v-icon>
      <v-icon v-if="params.data.LockStatus === 0">mdi-lock-open-outline</v-icon>
    </v-btn>
    {{ params.value }}
  </span>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      showConfirm: false,
      confirmMessage: null,
    };
  },
  computed: {
  },
  methods: {
    async btnClickedHandler() {
      await this.$store.dispatch('site/siteAttachments/updateLock', this.params.data.SiteAttachmentId);
    },
    onAccept() {
      this.params.clicked(this.params);
    },
  },
};
</script>
