<template>
  <a
    class="line-cell"
    @click="handleClick($event)"
  >
    {{ params.value }}
  </a>
</template>

<script>
export default {
  // props: ['params'],
  methods: {
    handleClick() {
      this.$store.dispatch('dashboard/select', this.params.data);
    },
  },
};
</script>

<style scoped>
.line-cell {
  text-decoration: none;
  color: white;
}
</style>
