<template>
  <div>
    <mdi-icon-picker-dialog
      v-if="showIconDialog"
      v-models:show="showIconDialog"
      v-models:icon="form.selectedIcon"
      @click:submit="icon => form.selectedIcon = icon"
    />

    <v-card-title class="px-3 pt-3 pb-0">
      <p class="my-0">
        {{ $t(graphText) }}
      </p>
    </v-card-title>
    <v-card-title class="px-3 pt-0 pb-3 mb-3">
      <p class="my-0">
        {{ $t('Dashboard.VisualizationSetting.VisualizationSetting') }}
      </p>
    </v-card-title>

    <div v-if="showFeedSelection">
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleSubTitles') }}
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-text-field
          v-model="form.mainTitle"
          :placeholder="$t('Dashboard.VisualizationSetting.MainTitle')"
          dense
        />
      </v-card-text>

      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleMediaType') }}
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-select
          v-model="form.selectedMediaType"
          :items="MediaTypeList"
          dense
          @change="updateFeedList()"
        />
      </v-card-text>
      <div
        v-if="form.selectedMediaType !== 'Picture'"
        class="d-flex flex-row justify-start align-center px-2"
      >
        <v-col
          cols="auto"
          class="px-2"
        >
          {{ $t('Dashboard.VisualizationSetting.Icon') }}:
        </v-col>
        <v-icon large>
          {{ form.selectedIcon }}
        </v-icon>
        <v-spacer style="max-width: 70px;" />
        <v-btn
          icon
          @click="showIconDialog = true"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>

      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleFeeds') }}
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-select
          v-model="form.selectedFeed"
          :items="feedList"
          label=" "
          dense
          return-object
        >
          <template
            slot="selection"
            slot-scope="data"
          >
            {{ data.item.GatewayName }} - {{ data.item.Name }}
          </template>
          <template
            slot="item"
            slot-scope="data"
          >
            {{ data.item.GatewayName }} - {{ data.item.Name }}
          </template>
        </v-select>
      </v-card-text>
    </div>

    <div v-if="showTimeRangeSelection">
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleTimeRange') }}
      </v-subheader>
      <time-range-card
        v-models:periodicity="form.periodicity"
        v-models:dateRangeFrom="form.dateRangeFrom"
        v-models:dateRangeTo="form.dateRangeTo"
        v-models:lastnScale="form.lastnScale"
        v-models:lastnPeriod="form.lastnPeriod"
        v-models:dateList="form.dateList"
        :show="showTimeRangeSelection"
        :show-range="(true)"
        :show-record="(false)"
        :sensor-id-list="([])"
        :vector-id-list="([])"
        :selected-sensors="form.selectedSensors"
      />
    </div>

    <wizard-action-buttons-card
      :show-back="showBackButton"
      :show-next="showNextButton"
      :show-save="showSaveButton"
      :confirm-disabled="confirmDisabled"
      @button:cancel="cancel()"
      @button:back="back()"
      @button:next="next()"
      @button:save="save()"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import importal from '@/api/importal';
import datehandling from '@/components/datehandling';
import WizardActionButtonsCard from '@/dashboard/components/graphform/WizardActionButtonsCard.vue';
import MdiIconPickerDialog from '@/devices/instruments/MdiIconPickerDialog.vue';
import TimeRangeCard from '../graphform/TimeRangeCard.vue';

export default {
  components: {
    WizardActionButtonsCard,
    TimeRangeCard,
    MdiIconPickerDialog,
  },
  props: {
    visualisation: {
      type: String,
      default: () => null,
      required: true,
    },
    graphText: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  data: () => ({
    LastPicker: ['Years', 'Months', 'Days', 'Hours', 'Minutes'],
    LastPicker2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE'],
    MediaTypeList: ['Picture', 'PDF', 'Audio', 'Video', 'Comment'/* , 'Other' */],

    menuStepCounter: 0,
    model: {
      widgetId: null,
      enabled: true,
      visible: true,
      axisWidth: 4,
      axisHeight: 200,
      axisX: 0,
      axisY: 0,
      axisLock: false,
    },

    showIconDialog: false,
    feedList: [],
    form: {
      selectedMediaType: 'Picture',
      selectedIcon: null,
      selectedFeed: null,

      mainTitle: null,

      periodicity: 'last_n',
      dateRangeFrom: null,
      dateRangeTo: null,
      lastnScale: 'Hours',
      lastnPeriod: 2,
      dateList: [],
      selectedSensors: [],
    },
  }),
  computed: {
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapGetters('app', ['pleaseWait', 'timezone']),

    confirmDisabled() {
      if (this.showFeedSelection && this.form.selectedFeed) {
        return false;
      }

      if (this.showTimeRangeSelection) {
        if (this.form.periodicity === 'last_n') {
          return !(this.form.lastnScale && this.form.lastnPeriod);
        }
        if (this.form.periodicity === 'time_range') {
          return !(this.form.dateRangeFrom && this.form.dateRangeTo);
        }
      }

      return true;
    },
    showNextButton() {
      return this.menuStepCounter < 1;
    },
    showBackButton() {
      return this.menuStepCounter > 0;
    },
    showSaveButton() {
      return !this.showNextButton;
    },
    showFeedSelection() {
      return this.menuStepCounter === 0;
    },
    showTimeRangeSelection() {
      return this.menuStepCounter === 1;
    },

    showSensors() {
      return this.visualisation.startsWith('sensor+');
    },
    showVectors() {
      return this.visualisation.startsWith('vector+');
    },
    isNew() {
      return this.activeWidget === undefined;
    },
    stringDateRange() {
      return [this.form.dateRangeFrom, this.form.dateRangeTo];
    },
    dateRangeStart() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[0];
    },
    dateRangeEnd() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[dateRange.length - 1];
    },
    jsonWidgetSettings() {
      const query = {
        user: {
          mainTitle: this.form.mainTitle ?? this.form.selectedFeed.Name,
          selectedMediaType: this.form.selectedMediaType,
          selectedFeed: this.form.selectedFeed,
          selectedIcon: this.form.selectedIcon,
        },
        data: {
          feeds: [
            { feed_id: this.form.selectedFeed.FileFeedId, mime_type: this.form.selectedFeed.MimeType },
          ],
          input_filter: [],
        },
      };

      if (this.form.selectedFeed.MimeType === 'application/ldbin') {
        query.data.feeds.push({
          feed_id: this.form.selectedFeed.FileFeedId,
          mime_type: 'audio/wav',
        });
      }

      if (this.form.periodicity === 'last_n') {
        query.data.input_filter.push({
          last_n: {
            scale: this.LastPicker2Query[this.LastPicker.indexOf(this.form.lastnScale)],
            period: Number(this.form.lastnPeriod),
          },
        });
      } else if (this.form.periodicity === 'time_range') {
        query.data.input_filter.push({
          time_range: {
            start: datehandling.formatForApi(this.dateRangeStart),
            end: datehandling.formatForApi(this.dateRangeEnd),
          },
        });
      }

      // Update date range with global config vs widget config
      this.$store.dispatch('dashboardWidget/updateDateRangeAndAggregation', {
        widgetSettings: query,
        visualisationSubType: this.visualisation,
      });

      return JSON.stringify(query);
    },
  },
  watch: {
    activeWidget() {
      this.init();
    },
    editModeFlag(val) {
      if (!val) {
        this.clearWidgetSettings();
      }
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      this.clearWidgetSettings();
      if (!this.isNew) {
        this.loadWidgetSettings();
      }
      this.setFeedList();
    },
    async updateFeedList() {
      this.form.selectedFeed = null;
      if (this.form.selectedMediaType === 'PDF') {
        this.form.selectedIcon = 'mdi-file-pdf-box';
      } else if (this.form.selectedMediaType === 'Comment') {
        this.form.selectedIcon = 'mdi-comment';
      } else if (this.form.selectedMediaType === 'Picture') {
        this.form.selectedIcon = 'mdi-camera';
      } else if (this.form.selectedMediaType === 'Audio') {
        this.form.selectedIcon = 'mdi-music-box';
      } else if (this.form.selectedMediaType === 'Video') {
        this.form.selectedIcon = 'mdi-video-box';
      } else if (this.form.selectedMediaType === 'Other') {
        this.form.selectedIcon = 'mdi-file';
      } else {
        this.form.selectedIcon = null;
      }

      this.setFeedList();
    },
    async setFeedList() {
      this.feedList = await (await importal.get('WidgetFileFeedList?'
        + `mediaType=${this.form.selectedMediaType}`)).data;
    },

    // Menu NAV
    next() {
      this.menuStepCounter += 1;
    },
    back() {
      this.menuStepCounter -= 1;
    },

    clearWidgetSettings() {
    },
    loadWidgetSettings() {
      const widgetSettings = datehandling.parseObjectFromApi(
        JSON.parse(this.activeWidget.WidgetSettings),
      );

      const filter = widgetSettings.user.input_filter
        ? widgetSettings.user.input_filter[0]
        : widgetSettings.data.input_filter[0];
      if (filter) {
        if (filter.time_range) {
          this.form.periodicity = 'time_range';
          this.form.dateRangeFrom = filter.time_range.start;
          this.form.dateRangeTo = filter.time_range.end;
        } else if (filter.last_n) {
          this.form.periodicity = 'last_n';
          this.form.lastnScale = this.LastPicker[
            this.LastPicker2Query.indexOf(filter.last_n.scale)
          ];
          this.form.lastnPeriod = filter.last_n.period;
        }
      }

      this.form.mainTitle = widgetSettings.user.mainTitle || null;
      this.form.selectedMediaType = widgetSettings.user.selectedMediaType || 'Picture';
      this.form.selectedFeed = widgetSettings.user.selectedFeed || null;
      this.form.selectedIcon = widgetSettings.user.selectedIcon;
    },
    cancel() {
      this.$emit('cancel', this.isNew);
    },
    save() {
      if (this.isNew) {
        this.create();
      } else {
        this.saveWidgetSettings();
      }
    },
    create() {
      this.$emit('create', {
        DashboardsWidgetId: this.activeWidgetId,
        DatasourceSettings: '', // TODO check if this property is useful, it seems that WidgetSettings do it all
        WidgetSettings: this.jsonWidgetSettings,
        WidgetId: this.model.widgetId,
        Enabled: this.model.enabled,
        Visible: this.model.visible,
        AxisHeight: this.model.axisHeight,
        AxisWidth: this.model.axisWidth,
        AxisY: this.model.axisY,
        AxisX: this.model.axisX,
        AxisLock: this.model.axisLock,
      });
    },
    saveWidgetSettings() {
      this.$emit('save', {
        DashboardsWidgetId: this.activeWidgetId,
        WidgetSettings: this.jsonWidgetSettings,
      });
    },
  },
};
</script>

<style>
</style>
