<template>
  <v-container fluid>
    <v-row class="fill-height">
      <v-col
        lg="9"
        class="fill-height pa-0"
      >
        <dashboard-stack-single-widget-host
          :settings="mapSettings"
        />
      </v-col>
      <v-col
        lg="3"
        class="fill-height pa-0"
      >
        <dashboard-stack-single-widget-host
          :settings="instrumentListSettings"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardStackSingleWidgetHost from '@/dashboard/components/DashboardStackSingleWidgetHost.vue';

export default {
  components: {
    DashboardStackSingleWidgetHost,
  },
  data: () => ({
    form: {
    },
  }),
  computed: {
    ...mapGetters('app', ['pleaseWait', 'timezone']),

    mapSettings() {
      return this.buildMapSettings();
    },
    instrumentListSettings() {
      return this.buildInstrumentListSettings();
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    buildMapSettings() {
      const query = {
        user: {
          timezone: this.timezone,
          visualisationType: 'leaflet',
        },
        data: {
        },
        visualization: {
        },
      };
      return query;
    },
    buildInstrumentListSettings() {
      const query = {
        user: {
          timezone: this.timezone,
          visualisationType: 'table+instrument',
        },
        data: {
        },
        visualization: {
        },
      };
      return query;
    },
  },
};
</script>

<style lang="scss">
</style>
