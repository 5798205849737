<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <div class="row">
              <v-text-field
                v-model="formData.ChannelCode"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Name')"
                :rules="requiredRule"
                class="col col-3"
                :error-messages="`${errorMessage}${errorCodeExists}`"
                @input="cleanError"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.Label"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Label')"
                :maxlength="50"
                class="col col-3"
                @change="onChange"
              />
              <v-select
                v-model="formData.Sensor"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Sensor')"
                :items="sensors"
                :rules="requiredRule"
                class="col col-6"
                :item-text="item => item.ModelName
                  + (item.PeNumber === null || item.PeNumber === undefined ? '' : ', PE# '+ item.PeNumber)
                  + (item.SerialNumber === null || item.SerialNumber === undefined ? '' : ', S/N ' + item.SerialNumber)
                  + (item.MountId === null || item.IsNew ? '' : ', ' + item.MountId)"
                item-value="Id"
                return-object
                @focus="cleanError"
                @change="onChange"
              />
            </div>
            <div class="row">
              <v-text-field
                v-model="formData.ChannelSlope"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.ChannelSlope')"
                :rules="numberRule"
                type="number"
                class="col col-3"
                :error-messages="errorMessage"
                @input="cleanError"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.ChannelOffset"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.ChannelOffset')"
                :rules="numberRule"
                type="number"
                class="col col-3"
                :error-messages="errorMessage"
                @input="cleanError"
                @change="onChange"
              />
              <v-select
                v-model="formData.UnitOfMeasureId"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.UnitOfMeasure')"
                :items="unitsOfMeasure"
                :rules="requiredRule"
                class="col col-6"
                item-text="NameI18n"
                item-value="UnitOfMeasureId"
                @focus="cleanError"
                @change="onChange"
              />
            </div>
            <div class="row">
              <v-text-field
                v-model="formData.Height"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Height')"
                :rules="numberRule"
                type="number"
                class="col col-6"
                :error-messages="errorMessage"
                @input="cleanError"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.HeadingFromTower"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.HeadingFromTower')"
                :rules="numberRule"
                type="number"
                class="col col-6"
                :error-messages="errorMessage"
                @input="cleanError"
                @change="onChange"
              />
            </div>
            <div class="row">
              <v-text-field
                v-model="formData.DistanceToTower"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.DistanceToTower')"
                :rules="numberRule"
                type="number"
                class="col col-6"
                :error-messages="errorMessage"
                @input="cleanError"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.AveragingInterval"
                :label="$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.AveragingInterval')"
                :rules="numberRule"
                type="number"
                class="col col-6"
                :error-messages="errorMessage"
                @input="cleanError"
                @change="onChange"
              />
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    pe: {
      type: String,
      required: false,
      default: null,
    },
    channelCalibrationId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        IsDirty: false,
      },
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      numberRule: [(v) => (!Number.isNaN(parseFloat(v))) || this.$t('Validation.Required')],
      errorMessage: '',
      errorCodeExists: '',
      sensors: null,
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['unitsOfMeasure', 'peNumber', 'channel', 'codeAlreadyExists', 'siteConfiguration']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {
          IsDirty: false,
        };
        this.$store.dispatch('site/loggerConfigurations/channel', null);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async setFormData() {
      if (this.channel) {
        this.formData = JSON.parse(JSON.stringify(this.channel[0]));
        if (!this.formData.Sensor) {
          this.formData.Sensor = {
            ModelName: i18n.t('WindServer.Sites.LoggerConfiguration.UnknownSensor'),
            PeNumber: null,
            SerialNumber: null,
            MountId: null,
            Id: -9999,
          };
        }
        this.formData.IsDirty = false;
      } else { // Create mode
        this.formData = {
          IsDirty: false,
        };
      }
      this.sensors = JSON.parse(JSON.stringify(this.siteConfiguration.Sensors));
      this.sensors.splice(0, 0, {
        ModelName: i18n.t('WindServer.Sites.LoggerConfiguration.UnknownSensor'),
        PeNumber: null,
        SerialNumber: null,
        MountId: null,
        Id: -9999,
      });
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          await this.$store.dispatch('site/siteConfigurations/setDirty', this.formData.IsDirty);
          this.formData.PeNumber = this.formData.Sensor ? this.formData.Sensor.PeNumber : null;
          if (this.formData.Sensor) {
            if (this.formData.Sensor.IsNew) {
              this.formData.SensorMountId = null;
              this.formData.SensorId = this.formData.Sensor.Id;
            } else {
              this.formData.SensorMountId = this.formData.Sensor.EquipmentMountId;
            }
          } else {
            this.formData.SensorMountId = null;
          }
          this.formData.ChannelSlope = Number(this.formData.ChannelSlope);
          this.formData.ChannelOffset = Number(this.formData.ChannelOffset);
          this.formData.Height = Number(this.formData.Height);
          this.formData.HeadingFromTower = Number(this.formData.HeadingFromTower);
          this.formData.DistanceToTower = Number(this.formData.DistanceToTower);
          this.formData.AveragingInterval = Number(this.formData.AveragingInterval);
          if (this.channel) {
            this.formData.ChannelCalibrationId = Number(this.formData.ChannelCalibrationId);
            await this.$store.dispatch('site/loggerConfigurations/updateChannel', this.formData);
          } else {
            await this.$store.dispatch('site/loggerConfigurations/createChannel', this.formData);
          }
          this.close();
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/loggerConfigurations/channel', null);
      this.$refs.form.reset();
    },
    cleanError() {
      this.errorMessage = '';
      this.errorCodeExists = '';
    },
    onChange() {
      this.formData.IsDirty = true;
    },
  },
};
</script>
