<template>
  <div>
    <v-card-subtitle
      class="text-subtitle-2"
    >
      {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.ChooseWhenToTriggerTheAlert') }}
    </v-card-subtitle>
    <v-card-text>
      <v-select
        v-model="localNominalityTrigger"
        :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.ComparatorType')"
        :items="nominalityTriggerType"
        item-text="Name"
        item-value="Type"
        return-object
        dense
      />
      &nbsp;
      &nbsp;
      <v-text-field
        v-model="localNominalityTriggerValue"
        :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Value')"
        type="number"
        dense
      />
      &nbsp;
      &nbsp;
      <!-- ADDING A CHECKBOX TO ANOTHER COMPARATOR -->
      <v-checkbox
        v-model="localAnotherComparatorCheckbox"
        :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.AddAnotherComparator')"
      />
      &nbsp;
      &nbsp;
      <div v-if="localAnotherComparatorCheckbox">
        &nbsp;
        &nbsp;
        <!-- IF THE BOX IS CHECKED, SHOW THE OTHER -->
        <!-- CREATE ANOTHER COMPARATOR AND ONLY SHOW THE SECOND NOMINALITY OPTIONS -->
        <v-select
          v-model="localMoreNominalityTrigger"
          :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.ComparatorType')"
          :items="secondNominalityTriggerType"
          item-text="Name"
          item-value="Type"
          return-object
          dense
        />
        &nbsp;
        &nbsp;
        <v-text-field
          v-model="localMoreNominalityTriggerValue"
          :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Value')"
          type="number"
          dense
        />
      </div>
      <!-- COLOR PICKER, ALWAYS ACTIVE -->
      <div>
        <!-- IF THE CHECKBOX IS TRUE THEN IT SHOULD BE ALERT AREA INSTEAD OF LINE -->
        <label v-if="!localAnotherComparatorCheckbox"> {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.AlertLineColor') }}</label>
        <label v-if="localAnotherComparatorCheckbox"> {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.AlertAreaColor') }}</label>
        <v-container>
          <v-row v-if="localAnotherComparatorCheckbox">
            <v-col class="d-flex justify-center">
              <v-color-picker
                v-model="localPickColor"
                hide-canvas
                hide-mode-switch
              />
            </v-col>
          </v-row>
          <v-row v-if="!localAnotherComparatorCheckbox">
            <v-col class="d-flex justify-center">
              <v-color-picker
                v-model="localPickColorLine"
                hide-canvas
                hide-mode-switch
              />
            </v-col>
          </v-row>
        </v-container>
        <div>
          <label>
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.TypeOfLine') }}
          </label>
        </div>
        &nbsp;
        <v-radio-group
          v-model="localSelectedLine"
          row
        >
          <v-radio
            v-for="item in alertLineTypeList"
            :key="item.Name"
            :label="item.Name"
            :value="item.Value"
          />
        </v-radio-group>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    nominalityTriggerType: { type: Array, default: () => null },
    secondNominalityTriggerType: { type: Array, default: () => null },
    alertLineTypeList: { type: Array, default: () => null },
    nominalityTrigger: { type: Object, default: () => null },
    nominalityTriggerValue: { type: Number, default: () => null },
    anotherComparatorCheckbox: { type: Boolean, default: () => null },
    moreNominalityTrigger: { type: Object, default: () => null },
    moreNominalityTriggerValue: { type: Number, default: () => null },
    selectedLine: { type: String, default: () => null },
    pickColorLine: { type: String, default: () => null },
    pickColor: { type: String, default: () => null },
  },
  data() {
    return {
      localNominalityTrigger: this.nominalityTrigger,
      localNominalityTriggerValue: this.nominalityTriggerValue,
      localAnotherComparatorCheckbox: this.anotherComparatorCheckbox,
      localMoreNominalityTrigger: this.moreNominalityTrigger,
      localMoreNominalityTriggerValue: this.moreNominalityTriggerValue,
      localSelectedLine: this.selectedLine,
      localPickColorLine: this.pickColorLine,
      localPickColor: this.pickColor,
    };
  },
  watch: {
    localNominalityTrigger(newValue) {
      this.$emit('update:nominalityTrigger', newValue);
    },
    localNominalityTriggerValue(newValue) {
      this.$emit('update:nominalityTriggerValue', newValue);
    },
    localAnotherComparatorCheckbox(newValue) {
      this.$emit('update:AnotherComparatorCheckbox', newValue);
    },
    localMoreNominalityTrigger(newValue) {
      this.$emit('update:moreNominalityTrigger', newValue);
    },
    localMoreNominalityTriggerValue(newValue) {
      this.$emit('update:moreNominalityTriggerValue', newValue);
    },
    localSelectedLine(newValue) {
      this.$emit('update:selectedLine', newValue);
    },
    localPickColorLine(newValue) {
      this.$emit('update:pickColorLine', newValue);
    },
    localPickColor(newValue) {
      this.$emit('update:pickColor', newValue);
    },
  },
};
</script>

<style scoped>
</style>
