<template>
  <div style="height: 100%; width: 100%">
    <server-side-data-grid
      :grid-source="sources.VectorIngressSource"
      :show-new-button="$auth.roles.includes('Web.Tenant.Create') && readmode === false"
      :refresh="refresh"
    />
    <v-dialog
      v-model="ShowChangeVectorDialog"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title>
          {{ $t('AssociateInputVectorWithOutput') }}
        </v-card-title>
        <v-card-text>
          <v-overflow-btn
            v-model="OutputInstrument"
            class="my-2"
            :items="OutputInstrumentList"
            :loading="!OutputInstrumentList"
            :label="$t('SelectAnInstrument')"
            item-text="Name"
            return-object
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="ChangeVectorDialogUpdating"
            @click="InstrumentRemove"
          >
            {{ $t('Remove') }}
          </v-btn>
          <v-btn
            :disabled="!OutputInstrument.InstrumentId || ChangeVectorDialogUpdating"
            @click="InstrumentChosen"
          >
            {{ $t('Submit') }}
          </v-btn>
          <v-btn
            :disabled="ChangeVectorDialogUpdating"
            @click="ShowChangeVectorDialog = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import importal from '@/api/importal';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import VectorIngressSource from './gridsources/gsvectoringress';

export default {
  name: 'DeviceList',
  components: {
    ServerSideDataGrid,
  },
  props: {
    refresh: {
      type: Number,
      default: () => (0),
    },
  },
  data: () => ({
    selectedVector: null,
    sources: {
      VectorIngressSource: null,
    },
    ShowChangeVectorDialog: false,
    ChangeVectorDialogUpdating: false,
    ChangeVectorDialogEvent: null,
    OutputInstrumentList: null,
    OutputInstrument: {
      Name: null,
      InstrumentId: null,
    },
  }),
  async mounted() {
    await this.init();
  },
  computed: {
    ...mapState('devices/gateways', ['readmode']),
  },
  created() {
    this.sources.VectorIngressSource = new VectorIngressSource(this, {
      EditOutputVector: (api, event) => this.EditOutputVector(api, event),
      cbDevGatewayIngressVectorList: (data) => this.cbDevGatewayIngressVectorList(data),
    });
  },
  methods: {
    async init() {
      await importal.get(`DevInstrumentList?DataGatewayId=${this.$route.params.id}`)
        .then((result) => result.data)
        .then((data) => {
          this.OutputInstrumentList = data;
        });
    },
    cbDevGatewayIngressVectorList() {

    },
    EditOutputVector(api, event) {
      this.ChangeVectorDialogEvent = { api, event };
      this.ShowChangeVectorDialog = true;
      this.ChangeVectorDialogUpdating = false;
    },
    InstrumentRemove() {
      const { api, event } = this.ChangeVectorDialogEvent;
      this.ChangeVectorDialogUpdating = true;
      importal.post('DevGatewayIngressVectorUpdate', {
        DataGatewayId: event.DataGatewayId,
        InstrumentName: event.InputInstrumentName,
        InstrumentId: null,
        VectorizationType: 'VectorizeDoubleSensors',
        VectorizationName: event.VectorizationName,
        VectorizationAxis: event.VectorizationAxis,
      })
        .then((result) => result.data)
        .then((data) => {
          this.ShowChangeVectorDialog = false;
          api.setRowData(data);
          this.cbDevGatewayIngressVectorList(data);
        });
    },
    InstrumentChosen() {
      const { api, event } = this.ChangeVectorDialogEvent;
      this.ChangeVectorDialogUpdating = true;
      importal.post('DevGatewayIngressVectorUpdate', {
        DataGatewayId: event.DataGatewayId,
        InstrumentName: event.InputInstrumentName,
        InstrumentId: this.OutputInstrument.InstrumentId,
        VectorizationType: 'VectorizeDoubleSensors',
        VectorizationName: event.VectorizationName,
        VectorizationAxis: event.VectorizationAxis,
      })
        .then((result) => result.data)
        .then((data) => {
          this.ShowChangeVectorDialog = false;
          api.setRowData(data);
          this.cbDevGatewayIngressVectorList(data);
        });
    },
  },
  i18n: {
    messages: {
      en: {
        AssociateInputVectorWithOutput: 'Associate Input Vector With Output',
        SelectAnInstrument: 'Select an Instrument',
        SelectAVector: 'Select a Vector',
        Close: 'Close',
        Submit: 'Submit',
        Remove: 'Remove',
      },
      fr: {
        AssociateInputVectorWithOutput: 'Associer un vecteur entrée à la sortie',
        SelectAnInstrument: 'Sélectionner un instrument',
        SelectAVector: 'Sélectionner un vecteur',
        Close: 'Fermer',
        Submit: 'Soumettre',
        Remove: 'Retirer',
      },
    },
  },
};
</script>

<style lang="scss">
</style>
