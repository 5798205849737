<template>
  <div>
    <div
      :id="id"
      ref="diagram"
      class="diagram text-center overflow-auto"
    />
    <EquipmentModal
      :show.sync="showModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import EquipmentModal from './EquipmentModal.vue';

export default {
  components: {
    EquipmentModal,
  },
  props: {
    infoBalloons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: uuidv4(),
      showModal: false,
    };
  },
  computed: {
    ...mapState('site/siteConfigurations', ['equipmentModel', 'siteConfiguration']),
  },
  watch: {
    equipmentModel() {
      this.draw();
    },
    siteConfiguration: {
      handler() {
        this.draw();
      },
      deep: true,
    },
  },
  mounted() {
    this.draw();
  },
  methods: {
    draw() {
      if (this.equipmentModel == null
        || this.siteConfiguration == null) return;

      const period = window.ConfigDiagramModel.ConfigDiagramModelFactory
        .createDiagramModel(this);

      if (period != null) {
        let diagram;

        switch (period.DiagramType) {
          case 'Tower':
            diagram = new window.ConfigurationDiagram
              .TowerDiagram(this, period, this.id, { infoBalloons: this.infoBalloons });
            break;
          case 'RemoteSensing':
            diagram = new window.ConfigurationDiagram
              .RemoteSensingDiagram(this, period, this.id);
            break;
          default:
            throw new Error('Diagram type not implemented!');
        }

        this.$refs.diagram.setHTML('');
        diagram?.draw();
      }
    },

    // eslint-disable-next-line
    onEquipmentClick(peNumber) {
      // we need to display PE info on a popup
      // will be done in US #22238, #22501
      // Note: Get PE Details before showing the dialog.
      this.showModal = true;
    },
  },
};
</script>

<style scoped>
  .diagram {
    background-color: white;
  }
</style>
