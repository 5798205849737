<template>
  <div class="main-dash-container">
    <div
      class="pa-0 dash-container"
    >
      <!-- Dashboard -->
      <DashboardStack
        :edit-mode-flag="editModeFlag"
        :dashboard.sync="model"
        :cell-height="cellHeight"
        @interface="getDashboardStackInterface"
      />
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-fab-transition>
            <v-btn
              v-show="editModeFlag"
              v-bind="attrs"
              absolute
              bottom
              right
              fab
              class="add-widget-btn"
              @click="menuAddNewWidget"
              v-on="on"
            >
              <v-icon>mdi-view-grid-plus-outline</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <span>{{ $t('Dashboard.Menu.AddNewWidget') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DashboardStack from '@/dashboard/components/DashboardStack.vue';

export default {
  components: {
    DashboardStack,
  },
  data: () => ({
    model: {
      name: null,
      dashboardId: null,
      isTemplate: true,
      users: null,
    },
    editModeFlag: true,
    stackMenuInterface: null,
    cellHeight: 15, // Number of pixels of vertical snap for widgets
    dashboardsInit: [{
      DashboardId: '70de9093-a849-43b2-96ea-8993604198d9',
      Name: 'Analytics Dashboard',
      IsTemplate: false,
      sharedUsers: [],
      isShared: false,
      isSharedByUser: false,
      isSharedByAccessGroup: false,
      isMyDashboard: true,
    }],
    dashboardWidgetsInit: [],
  }),
  computed: {
    ...mapState('dashboard', ['dashboards']),
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapState('instrument', ['selectedInstruments']),
    hasDashboards() {
      return this.dashboards.length > 0;
    },
    selDashboard() {
      return this.model;
    },
  },
  created() {
  },
  mounted() {
    this.$root.$on('dashboard-refresh', this.refreshDashboard);
    this.$store.commit('app/pleaseWait', true);
    this.$store.commit('dashboard/dashboards', this.dashboardsInit);
    this.$store.commit('dashboardWidget/dashboardWidgets', this.dashboardWidgetsInit);

    if (this.dashboards && this.dashboards.length > 0) {
      this.changeTab(this.dashboards[0]);
    }
    this.$store.commit('app/pleaseWait', false);
  },
  methods: {
    getDashboardStackInterface(i) {
      this.stackMenuInterface = i;
    },

    async changeTab(d) {
      this.model = {};
      this.model.dashboardId = d.DashboardId;
      this.model.isTemplate = d.IsTemplate;
      this.model.name = this.dashboards
        .filter((dashboard) => dashboard.DashboardId === d.DashboardId)[0]?.Name;
      this.model.users = d.sharedUsers.filter((s) => {
        if (s.id.indexOf('.') >= 0) {
          return s.id.split('.')[1] !== this.$auth.user.localAccountId;
        }
        return s.id !== this.$auth.user.localAccountId;
      });
    },

    async withOverlayAsync(body) {
      this.$store.commit('app/pleaseWait', true);
      await body()
        .finally(() => { this.$store.commit('app/pleaseWait', false); });
    },

    // #region Dashboard and Widget CRUD logic
    async addNewDashboard() {
      await this.withOverlayAsync(async () => {
        const newDashboardId = crypto.randomUUID();
        this.model = {};
        this.model.dashboardId = newDashboardId;
        this.model.isTemplate = false;
        this.model.name = this.dashboards
          .filter((dashboard) => dashboard.DashboardId === newDashboardId)[0]?.Name;
        setTimeout(() => {
          this.menuAddNewWidget();
        }, 500);
      });
    },
    // #endregion

    menuToggleEditMode() {
      this.editModeFlag = !this.editModeFlag;
    },
    menuAddNewWidget() {
      if (this.stackMenuInterface) {
        this.stackMenuInterface.menuAddNewWidget();
      }
    },

    async refreshDashboard() {
      if (this.editModeFlag) { return; }
      if (this.stackMenuInterface) {
        await this.withOverlayAsync(async () => {
          await this.stackMenuInterface.menuRefreshDashboard();
        });
      }
    },
  },
};
</script>

<style lang="scss">
  // @import "@/styles/_includes.scss";

.main-dash-container {
  // background-image: url('@/assets/wspOverlay.png'),
  //   linear-gradient(126.13deg, #333E48 -3.75%, #3B4955 76.61%);
  // background: linear-gradient(126.13deg, #333E48 -3.75%, #3B4955 76.61%);
  background: transparent !important;
}
.dash-toolbar {
  // background: linear-gradient(126.13deg, #2A333B -3.75%, #3C4954 76.61%);
  background: rgba(0, 0, 0, 0.25) !important;
  // background: transparent !important;
  .v-toolbar__content, .v-toolbar__extension {
    padding-right: 0;
  }
}
  .v-tab {
    text-transform: none !important;
  }

  .grid-stack {
    margin-bottom: 0;
  }

  .grid-stack-item,
  .widget {
    border: 1px solid;
    // @include theme-style(border-color, border-color, important);
    border-radius: 10px;
  }

  .grid-stack-item:hover,
  .widget:hover {
    border: 1px solid;
    // @include theme-style(border-color, grey-darken-1, important);
    // @include theme-style(background, grey-lighten-4, important);
    cursor: pointer;
  }

  .active-widget {
    border: 1px solid;
    // @include theme-style(border-color, primary-fg, important);
    cursor: pointer;
  }

  .widget {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .new-widget {
    border: 2px dashed;
  }

  .new-widget:hover {
    border: 3px dashed;
  }

  .graphnodata {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" id="footer-sample-full" width="120px" height="120px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16" class="iconify iconify--charm"><g fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M8.25 14.25h-5.5V1.75h10.5v6.5"></path><path d="M14.25 10.75l-3.5 3.5m-5-6.5h4.5m-4.5 3h1.5m-1.5-6h4.5m.5 6l3.5 3.5"></path></g></svg>');
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    z-index: 1;
    background-repeat: no-repeat;
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #ff00006c; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    animation: spin 2s linear infinite;
  }

  .widgetLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #ff00006c; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: -38px 0 0 -38px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .widget-toolbar {
    position: absolute;
    left: 3px;
    top: 3px;
    // z-index: 100;
  }
  .widget-button-menu:hover {
    color: red;
  }
  .add-widget-btn {
    background: #03AB9A !important;
  }

</style>
