<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="currentSite"
              readonly
              :label="$t('WindServer.Sites.Columns.Site')"
            />
            <DateTimePicker
              v-if="!readMode"
              v-model="formData.CreationTs"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              :has-clear-button="false"
              :label="$t('WindServer.Sites.Notes.Columns.DateTime')"
              :rules="[v => !!v || $t('Validation.Required')]"
            />
            <v-text-field
              v-else
              v-model="formData.CreationTs"
              readonly
              :label="$t('WindServer.Sites.Notes.Columns.DateTime')"
            />
            <v-text-field
              v-if="!formData.IsMaintenanceRecord"
              readonly
              :label="$t('Dashboard.Share.User')"
              :value="$auth.user.name"
            />
            <v-text-field
              v-if="formData.IsMaintenanceRecord"
              v-model="formData.TechnicianName"
              :label="$t('WindServer.Sites.Notes.Columns.Technician')"
              :rules="[v => !!v || $t('Validation.Required')]"
            />
            <v-textarea
              v-model="formData.Note"
              :label="$t('WindServer.Sites.Notes.Columns.Note')"
              :rules="[v => !!v || $t('Validation.Required')]"
            />
            <v-checkbox
              v-model="formData.IsInternal"
              :label="$t('WindServer.Sites.Notes.Columns.IsInternal')"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import DateTimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DateTimePicker,
  },
  props: {
    noteId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    const isMaintenanceRecord = this.$route.name === 'Sites.Notes.CreateMaintenanceRecord';
    return {
      formData: {
        CreationTs: new Date(),
        IsMaintenanceRecord: isMaintenanceRecord,
        TechnicianName: null,
      },
      show: false,
      readMode: !this.$auth.roles.includes('Web.Site.Manage'),
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  computed: {
    ...mapState('site/siteNotes', ['siteNote']),
    ...mapState('site/sites', ['site']),

    currentSite() {
      return `${this.site.Name} (${this.site.SiteId})`;
    },
  },
  watch: {
    async noteId() {
      await this.getSiteNote();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.noteId && this.noteId != null) {
      await this.getSiteNote();
    } else {
      this.show = true;
    }
  },
  methods: {

    async getSiteNote() {
      await this.$store.dispatch('site/siteNotes/setNoteId', { noteId: this.noteId });
      await this.$store.dispatch('site/siteNotes/get', { noteId: this.noteId });
      this.formData = this.$clone(this.siteNote);
      this.formData.CreationTs = this.siteNote.CreationTs;
      this.show = true;
    },

    async navigateToList() {
      this.$router.push({ name: 'Sites.Notes' });
    },

    async submit() {
      if (this.$refs.form.validate()) {
        if (typeof this.formData.TechnicianName === 'object') {
          delete this.formData.TechnicianName;
        }

        if (this.noteId) {
          await this.$store.dispatch('site/siteNotes/update', this.formData);
        } else {
          await this.$store.dispatch('site/siteNotes/create', this.formData);
        }
        this.navigateToList();
      }
    },
  },
};
</script>
