<template>
  <div>
    <media-feed-date-picker
      v-if="showDatePicker"
      v-model="showDatePicker"
      :selected-item="selectedItem"
      :widget-settings-data="widgetSettings.data"
      @select="(value) => openModal(value)"
    />
    <div v-if="showModal">
      <picture-feed-modal
        v-if="showPicture"
        v-model="showPicture"
        :selected-item="selectedItem"
        :widget-settings-data="widgetSettings.data"
        @back="(value) => openDatePicker(value)"
      />
      <pdf-feed-modal
        v-if="showPdf"
        v-model="showPdf"
        :selected-item="selectedItem"
        :widget-settings-data="widgetSettings.data"
        @back="(value) => openDatePicker(value)"
      />
      <audio-feed-modal
        v-if="showAudio"
        v-model="showAudio"
        :selected-item="selectedItem"
        :widget-settings-data="widgetSettings.data"
        @back="(value) => openDatePicker(value)"
      />
      <video-feed-modal
        v-if="showVideo"
        v-model="showVideo"
        :selected-item="selectedItem"
        :widget-settings-data="widgetSettings.data"
        @back="(value) => openDatePicker(value)"
      />
    </div>
    <media-feed-list-picker
      v-if="showListPicker"
      v-model="showListPicker"
      :selected-item="selectedItem"
      :widget-settings-data="widgetSettings.data"
      @select="(value) => openModalList(value)"
    />
    <div v-if="showModalList">
      <comment-feed-modal
        v-if="showComment"
        v-model="showComment"
        :selected-item="selectedItem"
        :widget-settings-data="widgetSettings.data"
        @back="(value) => openListPicker(value)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MediaFeedDatePicker from './MediaFeedDatePicker.vue';
import MediaFeedListPicker from './MediaFeedListPicker.vue';
import PictureFeedModal from './PictureFeedModal.vue';
import PdfFeedModal from './PdfFeedModal.vue';
import AudioFeedModal from './AudioFeedModal.vue';
import VideoFeedModal from './VideoFeedModal.vue';
import CommentFeedModal from './CommentFeedModal.vue';

export default {
  components: {
    MediaFeedDatePicker,
    MediaFeedListPicker,
    PictureFeedModal,
    PdfFeedModal,
    AudioFeedModal,
    VideoFeedModal,
    CommentFeedModal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    widgetSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDatePicker: false,
      showListPicker: false,
      showPicture: false,
      showPdf: false,
      showAudio: false,
      showVideo: false,
      showComment: false,
      selectedItem: {},
    };
  },
  computed: {
    ...mapState('mediaFeed', ['availableDates']),

    showModal() {
      return this.showPicture
      || this.showPdf
      || this.showAudio
      || this.showVideo;
    },
    showModalList() {
      return this.showComment;
    },
    isPictureType() {
      return !this.widgetSettings.user.selectedMediaType
        || this.widgetSettings.user.selectedMediaType === 'Picture';
    },
    isAudioType() {
      return !this.widgetSettings.user.selectedMediaType
        || this.widgetSettings.user.selectedMediaType === 'Audio';
    },
    isVideoType() {
      return !this.widgetSettings.user.selectedMediaType
        || this.widgetSettings.user.selectedMediaType === 'Video';
    },
    isCommentType() {
      return !this.widgetSettings.user.selectedMediaType
        || this.widgetSettings.user.selectedMediaType === 'Comment';
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        if (this.isCommentType) {
          this.initListPicker();
        } else {
          this.initDatePicker();
        }
      }
    },
    showModal(newVal) {
      if (!newVal && !this.showDatePicker) {
        this.reset();
      }
    },
    showDatePicker(newVal) {
      if (!newVal && !this.showModal) {
        this.reset();
      }
    },
    showModalList(newVal) {
      if (!newVal && !this.showListPicker) {
        this.reset();
      }
    },
    showListPicker(newVal) {
      if (!newVal && !this.showModalList) {
        this.reset();
      }
    },
  },
  methods: {
    async initDatePicker() {
      this.showDatePicker = true;

      this.$store.commit('mediaFeed/init');
      await this.$store.dispatch('mediaFeed/getDailyInfos', this.widgetSettings.data);
      this.openDatePicker({ date: this.availableDates[this.availableDates.length - 1], index: 0 });
    },
    async initListPicker() {
      this.showListPicker = true;

      this.$store.commit('mediaFeed/init');
      await this.$store.dispatch('mediaFeed/getDailyInfos', this.widgetSettings.data);
      this.openListPicker({ date: this.availableDates[this.availableDates.length - 1], index: 0 });
    },
    openDatePicker(item) {
      this.showDatePicker = true;
      this.showPicture = false;
      this.showPdf = false;
      this.showVideo = false;
      this.showAudio = false;
      this.showComment = false;
      this.selectedItem = item;
    },
    openListPicker(item) {
      this.showListPicker = true;
      this.showPicture = false;
      this.showPdf = false;
      this.showVideo = false;
      this.showAudio = false;
      this.showComment = false;
      this.selectedItem = item;
    },
    openModal(item) {
      this.selectedItem = item;
      if (this.isPictureType) {
        this.showPicture = true;
      } else if (this.isAudioType) {
        this.showAudio = true;
      } else if (this.isVideoType) {
        this.showVideo = true;
      } else {
        this.showPdf = true;
      }
      this.showDatePicker = false;
      this.showListPicker = false;
    },
    openModalList(item) {
      this.selectedItem = item;
      this.showComment = true;
      this.showListPicker = false;
      this.showDatePicker = false;
    },
    reset() {
      this.$store.commit('mediaFeed/init');
      this.showDatePicker = false;
      this.showListPicker = false;
      this.showPicture = false;
      this.showPdf = false;
      this.showAudio = false;
      this.showVideo = false;
      this.showComment = false;

      this.selectedItem = {};
      this.$emit('close');
    },
  },
};
</script>
