<template>
  <v-dialog
    v-model="show"
    max-width="600"
    @keydown="handleKeyDown"
  >
    <v-card ref="popupCard">
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <div v-for="(value, key) in model" :key="key" class="flex-row">
            <div class="key">{{ key }}:</div>
            <div class="value">{{ value }}</div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="show=false"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AutoInfoPopup',
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
  },
  methods: {
    handleInput() {
    },
    handleKeyDown(event) {
      if (event.key === 'Enter' || event.key === 'Escape') {
        event.preventDefault();
        this.show = false;
      }
    },
  },
};
</script>

<style scoped>
.flex-row {
  display: flex;
  margin-bottom: 10px;
}
.key {
  width: 160px;
  font-weight: bold;
}
.value {
  flex: 1;
}
</style>
