<template>
    <div class="secondary" style="height:450px; padding: 25px;">
        <table><tr><td>
        <v-select style="width: 350px; margin-right:25px"
              v-model="logger"
              :label="$t('Instruments.Logger.Title')"
              :items="loggers"
              item-text="Description"
              item-value="PeNumber"
              ref="loggerPeNumber"
            />
        </td>
    <td>
            <v-btn
            text
            @click="save"
          >
            {{ $t('Save') }}
          </v-btn>
        </td></tr></table>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('devices/instruments', ['loggers', 'logger']),
  },
  methods: {
    async save() {
      if (typeof this.$refs.loggerPeNumber.lazyValue !== 'object') {
        await this.$store.dispatch('devices/instruments/update', this.$refs.loggerPeNumber.lazyValue);
      }
    },
  },
};
</script>
