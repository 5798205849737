<template>
  <v-card
    v-if="editorActive"
  >
    <v-card-title>{{ $t('ScalarConstantParameters') }}</v-card-title>
    <v-card-subtitle>
      &nbsp;
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <div
          class="col-3"
        >
          {{ $t('Range') }}
        </div>
          &nbsp;
          &nbsp;
        <datetime-picker
          v-model="editor.ValidFromTs"
          :label="$t('From')"
          :text-field-props="pickerTextFieldProps"
          :date-picker-props="pickerDateProps"
          :time-picker-props="pickerTimeProps"
        />
          &nbsp;
          &nbsp;
        <datetime-picker
          v-model="editor.ValidToTs"
          :label="$t('To')"
          :text-field-props="pickerTextFieldProps"
          :date-picker-props="pickerDateProps"
          :time-picker-props="pickerTimeProps"
        />
        <v-text-field
          v-model="editor.Value"
          :label="$t('Value')"
          type="number"
          dense
        />
      </v-row>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script>
import DatetimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    init: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    editor: {
      node: {
        Value: null,
        ValidFromTs: null,
        ValidToTs: null,
        Unit: null,
      },
      Value: null,
      ValidFromTs: null,
      ValidToTs: null,
      Unit: null,
    },
    pickerTextFieldProps: {
      dense: true,
      clearable: true,
      class: 'col-4',
      style: 'font-size: 80%;',
    },
    pickerDateProps: {
      locale: 'en-ca',
    },
    pickerTimeProps: {
      format: '24hr',
    },
  }),
  computed: {
    editorActive() {
      return this.editor && this.editor.show && this.editor.node && this.editor.node.nodeType === 'sensor_constant';
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.initialize();
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.editor = this.init;
      this.editor.Value = this.editor.node.Value;
      this.editor.ValidFromTs = this.editor.node.ValidFromTs;
      this.editor.ValidToTs = this.editor.node.ValidToTs;
    },
  },
  i18n: {
    messages: {
      en: {
        ScalarConstantParameters: 'Scalar constant parameters',
        Range: 'Range',
        From: 'From',
        To: 'To',
        Value: 'Value',
      },
      fr: {
        ScalarConstantParameters: 'Paramètres scalaires constants',
        Range: 'Étendue',
        From: 'De',
        To: 'À',
        Value: 'Valeur',
      },
    },
  },
};
</script>

<style>

</style>
