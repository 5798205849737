<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="950"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <div class="flex">
            <v-select
              v-model="formData.GenBy"
              :label="$t('WindServer.Sites.LoggerConfiguration.GenerateData')"
              :items="genByOption"
              item-text="Name"
              item-value="Id"
              return-object
            />
          </div>
          <div
            v-if="formData.GenBy.Id != 'DataFile'"
            class="row"
          >
            <ChannelsList v-if="formData.GenBy.Id =='Channel'" />
            <HeightsList
              v-else
              :id="id"
            />
            <MeasurementPointsList :id="id" />
          </div>
          <div v-else>
            <v-select
              v-model="formData.SelectedFileParser"
              :items="FileParserTypes"
              :label="$t('WindServer.Sites.LoggerConfiguration.FileParser')"
              :rules="requiredRule"
              item-text="Label"
              item-value="Value"
              dense
            />
            <v-file-input
              v-model="formData.files"
              color="deep-purple accent-4"
              counter
              :label="$t('WindServer.Sites.LoggerConfiguration.FileInput')"
              multiple
              placeholder="Select your files"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"
            >
              <template #selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-3 mx-2"
                >
                  +{{ formData.files.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import ChannelsList from './ChannelsList.vue';
import MeasurementPointsList from './MeasurementPointsList.vue';
import HeightsList from './HeightsList.vue';

export default {
  components: {
    ChannelsList,
    MeasurementPointsList,
    HeightsList,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      genByOption: [
        {
          Id: 'Channel',
          Name: i18n.t('WindServer.Sites.LoggerConfiguration.GenBy.Channel'),
        },
        {
          Id: 'Height',
          Name: i18n.t('WindServer.Sites.LoggerConfiguration.GenBy.Height'),
        },
        {
          Id: 'DataFile',
          Name: i18n.t('WindServer.Sites.LoggerConfiguration.GenBy.DataFile'),
        },
      ],
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      FileParserTypes: [
        { Label: 'CampbellSci TOA5 Format', Value: 'TextFileParserFactory.CampbellSciDatFile' },
        { Label: 'RST Inclinometer', Value: 'TextFileParserFactory.RSTInclinometerDataFile' },
        { Label: 'Load Sensing', Value: 'TextFileParserFactory.LoadSensingDataFile' },
        { Label: 'Geokon Inclinometer', Value: 'TextFileParserFactory.GeokonInclinometerDataFile' },
        { Label: 'Geomos Total Station', Value: 'TextFileParserFactory.GeomosTotalStationDataFile' },
        { Label: 'GeoSense Inclinometer', Value: 'TextFileParserFactory.GeoSenseInclinometerDataFile' },
        { Label: 'Digitilt RPP Inclinometer', Value: 'TextFileParserFactory.DigitiltInclinometerDataFile' },
        { Label: 'Glötzl Inclinometer', Value: 'TextFileParserFactory.GlotzlInclinometerDataFile' },
        { Label: 'DurhamGeo DigiPro DUX Inclinometer', Value: 'TextFileParserFactory.DurhamGeoInclinometerDataFile' },
        // { Label: 'SInGE neutral format', Value: 'TextFileParserFactory.SingeNeutralCsv' },
        // { Label: 'Headerless CSV', Value: 'TextFileParserFactory.HeaderLessCsv' },
        { Label: 'Sisgeo H-Level', Value: 'TextFileParserFactory.SisgeoHLevel' },
        { Label: 'Symphonie Pro (NRG)', Value: 'TextFileParserFactory.SymphonieProNRG' },
        { Label: 'In Situ VuLink (CSV)', Value: 'TextFileParserFactory.VuLink' },
        { Label: 'Instantel Thor CSV', Value: 'TextFileParserFactory.Instantel' },
        { Label: 'Compacted custom readings', Value: 'TextFileParserFactory.CompactedCustomReadings' },
        { Label: 'BMP North East Elev.', Value: 'TextFileParserFactory.BmpNorthEastElevation' },
        { Label: 'Windserver Export (CSV)', Value: 'TextFileParserFactory.WindServerExport' },
        { Label: 'Trimble T4D (CSV)', Value: 'TextFileParserFactory.TrimbleT4D' },
        { Label: 'Environnement Canada', Value: 'TextFileParserFactory.CanadaWeatherClientCsv' },
        { Label: 'France Government - Weather', Value: 'TextFileParserFactory.EnvFranceWeatherClientCsv' },
        { Label: 'USEP Survey Monitoring', Value: 'TextFileParserFactory.AtriumToronto' },
        { Label: 'Geoverra AMTS', Value: 'TextFileParserFactory.GeoverraTotalStationDataFile' },
        { Label: 'KiWiS (CSV)', Value: 'TextFileParserFactory.Kiwis' },
      ],
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['columnTypes']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.setFormData();
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async setFormData() {
      this.formData = {
        DataFileImportSettingId: this.id,
        GenBy: {
          Id: 'Channel',
        },
        files: [],
      };
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (this.formData.GenBy.Id === 'DataFile') {
            await this.buildAutoConfig();
          } else {
            await this.$store.dispatch('site/loggerConfigurations/generateColumns', {
              id: this.id,
              genBy: this.formData.GenBy,
            });
          }
          this.close();
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.setFormData();
      this.$store.dispatch('site/loggerConfigurations/cleanGenerateColumns');
    },
    async buildAutoConfig() {
      await this.$store.dispatch('site/loggerConfigurations/buildAutoConfig', this.formData);
    },
  },
};
</script>
