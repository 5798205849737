// Styles
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

// Vuetify
import Vue from 'vue';
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
} from 'vuetify/lib';
import {
  Ripple,
  Intersect,
  Touch,
  Resize,
  ClickOutside,
} from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  components: {
    VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect,
  },
  directives: {
    Ripple, Intersect, Touch, Resize, ClickOutside,
  },
});

export default new Vuetify({
  theme: {
    dark: true,
    options: { customProperties: true },
  },
  icons: {
    iconfont: 'mdi',
  },
});
