<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <datetime-picker
              v-model="formData.FlagStart"
              :label="$t('WindServer.Sites.DataTrace.StartingTimestamp')"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              :has-clear-button="false"
              :rules="requiredRule"
            />
            <datetime-picker
              v-model="formData.FlagEnd"
              :label="$t('WindServer.Sites.DataTrace.EndingTimestamp')"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              :has-clear-button="true"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import DatetimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  computed: {
    ...mapState('site/dataTrace', ['flagPeriod']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$store.dispatch('site/dataTrace/flagPeriod', null);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async setFormData() {
      if (this.flagPeriod) {
        this.formData = { ...this.flagPeriod };
      } else { // Create mode
        const dt = new Date();
        this.formData = {
          FlagStart: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - 1, 0, 0, 0),
          FlagEnd: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - 1, 23, 59, 59),
        };
      }
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (this.flagPeriod) {
            await this.$store.dispatch('site/dataTrace/updateFlagPeriod', this.formData);
          } else {
            await this.$store.dispatch('site/dataTrace/createFlagPeriod', this.formData);
          }
          this.close();
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/dataTrace/flagPeriod', null);
    },
  },
};
</script>
