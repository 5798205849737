/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsSites extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.Site.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 80,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.Site.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'Sites.Update',
                params: {
                  id: event.data.SiteId,
                },
              });
            },
          },
        },
        {
          field: 'Client',
          headerName: this.vue.$t('WindServer.Sites.Columns.Tenant'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Site',
          headerName: this.vue.$t('WindServer.Sites.Columns.Site'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Location',
          headerName: this.vue.$t('WindServer.Sites.Columns.Location'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Installed',
          headerName: this.vue.$t('WindServer.Sites.Columns.Installed'),
          width: 200,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatDate,
        },
        {
          field: 'DataStartDate',
          headerName: this.vue.$t('WindServer.Sites.Columns.DataStartDate'),
          width: 200,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatDate,
        },
        {
          field: 'DataEndDate',
          headerName: this.vue.$t('WindServer.Sites.Columns.DataEndDate'),
          width: 200,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatDate,
        },
        {
          field: 'Retired',
          headerName: this.vue.$t('WindServer.Sites.Columns.Retired'),
          width: 200,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatDate,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 80,
          resizable: true,
          hide: !this.vue.$auth.roles.includes('Web.Site.Delete'),
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            show: (event) => event.data.Removable,
            confirm: (event) => (this.vue.$t('WindServer.Sites.Columns.Site', { Name: event.data.Site })),
            clicked: async (event) => {
              await this.vue.$store.dispatch('site/sites/remove', { id: event.data.SiteId });
            },
          },
        },
      ]);
    });
  }

  formatDate(params) {
    switch (params.colDef.field) {
      case 'Retired':
        if (params.data.Retired != null) {
          return params.data.Retired.toISOString().split('T')[0];
        }
        return '';

      case 'Installed':
        if (params.data.Installed != null) {
          return params.data.Installed.toISOString().split('T')[0];
        }
        return '';

      case 'DataStartDate':
        if (params.data.DataStartDate != null) {
          return params.data.DataStartDate.toISOString().split('T')[0];
        }
        return '';

      case 'DataEndDate':
        if (params.data.DataEndDate != null) {
          return params.data.DataEndDate.toISOString().split('T')[0];
        }
        return '';

      default:
        return '';
    }
  }
}
