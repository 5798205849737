<template>
  <div
    class="secondary row"
    style="height:85px;padding:0px;margin-left:12px;margin-right: 12px;"
  >
    <div class="col col-4">
      <datetime-picker
        ref="selectDate"
        v-model="selectDate"
        :label="$t('WindServer.Sites.DataTrace.SelectDate')"
        :date-picker-props="pickerDateProps"
        :time-picker-props="pickerTimeProps"
        :has-clear-button="true"
      />
    </div>
    <div class="col col-4">
      <v-select
        ref="selectSensor"
        v-model="formData.sensor"
        clearable
        :label="$t('WindServer.Sites.DataTrace.Sensor')"
        :items="sensors"
        item-text="Name"
        item-value="SensorId"
        :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
        @change="v => onSensorChange(v)"
      />
    </div>
    <div class="col col-4">
      <v-checkbox
        ref="openSets"
        v-model="formData.openSets"
        :label="$t('WindServer.Sites.DataTrace.OpenSets')"
        type="checkbox"
        @change="v => onOpenSetChange(v)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import DatetimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DatetimePicker,
  },
  data() {
    return {
      formData: {},
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
      options: {},
      selectDate: null,
    };
  },
  computed: {
    ...mapState('site/dataTrace', ['sensors']),
  },
  watch: {
    selectDate() {
      this.options.SelectDate = this.selectDate;
      this.update();
    },
  },
  methods: {
    onSensorChange(value) {
      this.options.SensorId = value;
      this.update();
    },
    onOpenSetChange(value) {
      this.options.OpenSet = value;
      this.update();
    },
    update() {
      this.$emit('update', this.options);
    },
    close() {
      this.selectDate = null;
      this.$refs.selectDate.clearHandler();
      this.options = {};
      this.formData = {};
    },
  },
};
</script>
