<template>
  <div class="fill-height">
    <ServerSideDataGrid
      :grid-source="sources.LandOwners"
      :show-new-button="$auth.roles.includes('WindServer.Site.Create')"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="landOwners.filter((x) => x.IsSelected)"
    />
    <LandOwnerSelectorModal
      :show.sync="showSelector"
      @select="ids => select(ids)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import LandOwnerSelectorModal from './LandOwnerSelectorModal.vue';
import LandOwnerList from '../gridsources/gsLandOwners';

export default {
  components: {
    ServerSideDataGrid,
    LandOwnerSelectorModal,
  },
  data() {
    return {
      sources: {
        LandOwners: null,
      },
      showSelector: false,
      toolbarButtons: [],
    };
  },
  computed: {
    ...mapState('site/sites', ['site']),
    ...mapState('site/siteLandOwners', ['landOwners']),
  },
  async created() {
    this.sources.LandOwners = new LandOwnerList(this, false);
    await this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.LandOwner.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('New'),
        icon: 'mdi-plus',
        enabled: true,
        click: () => { this.showSelector = true; },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    async select(ids) {
      await this.$store.dispatch('site/siteLandOwners/create', {
        SiteId: this.site.SiteId,
        LandOwnerIds: ids,
      });
    },
    async refreshList() {
      await this.$store.dispatch('site/siteLandOwners/list', this.site.SiteId);
    },
    async remove(owner) {
      await this.$store.dispatch('site/siteLandOwners/delete', {
        SiteId: this.site.SiteId,
        LandOwnerId: owner.LandOwnerId,
      });
    },
  },
};
</script>

<style>
.ag-invisible
{
  width: 0px;
  margin-left: -11px;
}
</style>
