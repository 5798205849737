import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const useAppInsights = (options) => {
  const appInsights = new ApplicationInsights({ config: options });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
  return appInsights;
};

export default {
  // eslint-disable-next-line no-shadow
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$appInsights = useAppInsights(options);
  },
};
