<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    width="340"
  >
    <v-card>
      <v-card-title class="pa-3 mb-3">
        <p class="mb-0 mt-0">
          {{ getDateTimeString }}
        </p>
      </v-card-title>
      <div
        v-if="!feedInfos"
        style="height: 500px;"
      >
        <div class="loader" />
      </div>
      <v-card-text
        v-else
        align="center"
      >
        <v-date-picker
          :value="date"
          :min="dateMin"
          :max="dateMax"
          :allowed-dates="(value) => availableDates.includes(value)"
          @input="value => updateOptions(value)"
        />
        <v-card-text
          align="left"
          class="pl-0"
        >
          {{ $t('Dashboard.MediaWidget.AvailableTimes') }}
        </v-card-text>
        <v-select
          v-model="time"
          :items="options"
          item-text="name"
          dense
          return-object
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="grey lighten-1"
          text
          @click="show = false"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="$emit('select', ({date: new Date(date), index: time.index}))"
        >
          {{ $t('Next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: true,
    },
    widgetSettingsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      date: '',
      time: null,
      options: [],
    };
  },
  computed: {
    ...mapState('mediaFeed', ['feedInfos', 'availableDates']),
    ...mapGetters('mediaFeed', ['timestampOptions']),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    getDateTimeString() {
      return !this.time ? '' : `${this.date} ${this.time.name}`;
    },
    dateMin() {
      return this.availableDates.length === 0 ? '' : this.availableDates[0];
    },
    dateMax() {
      return this.availableDates.length === 0 ? '' : this.availableDates[this.availableDates.length - 1];
    },
  },
  watch: {
    selectedItem: {
      async handler(newVal) {
        if (newVal && newVal.date) {
          await this.updateOptions(newVal.date);
          this.time = this.options[newVal.index];
        }
      },
      immediate: true,
    },
  },
  methods: {
    async updateOptions(newDate) {
      if (newDate === this.date) {
        return;
      }

      this.date = newDate;
      this.$store.commit('mediaFeed/feedInfos', null);
      await this.$store.dispatch('mediaFeed/getFullInfosForDay', {
        date: this.date,
        widgetSettingsData: this.widgetSettingsData,
      });
      this.options = this.timestampOptions(this.feedInfos.infos);
      [this.time] = this.options;
    },
  },
};
</script>
