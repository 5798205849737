<template>
  <ag-grid-vue
    style="width: 100%; height: 100%; border-radius: 12px; overflow: hidden;"
    class="ag-theme-balham-dark"
    :column-defs="columnDefs"
    :row-data="rowData"
    :default-col-def="defaultColDef"
    @grid-ready="onGridReady"
  />
</template>

<script>
import { mapState } from 'vuex';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { AgGridVue } from 'ag-grid-vue';
import DashboardLinkRenderer from './DashboardLinkRenderer.vue';
import DashboardStackVueWidgetAdapter from '../DashboardStackVueWidgetAdapter';

export default {
  name: 'DashboardListVueWidget',
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    DashboardLinkRenderer,
  },
  props: {
    widgetSettings: {
      type: Object,
      default: () => {},
      required: true,
    },
    widgetAdapter: {
      type: DashboardStackVueWidgetAdapter,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: 'Dashboard',
          field: 'Name',
          cellRenderer: 'DashboardLinkRenderer',
        },
      ],
      rowData: [],
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        sortable: true,
        filter: true,
      },
    };
  },
  computed: {
    ...mapState('dashboard', ['dashboards']),
  },
  mounted() {
  },
  methods: {
    init() {
      this.rowData = this.dashboards;
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.init();
    },
  },
};
</script>
