/* eslint-disable no-bitwise */
<template>
  <div>
    <v-card-subtitle
      class="text-subtitle-2"
    >
      {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.SelectSensor') }}
    </v-card-subtitle>
    <v-card-text>
      <v-list
        v-if="sensorList.length > 0"
        dense
        shaped
        class="sensor-list"
      >
        <v-list-item-group
          v-model="localSelectedSensor"
          color="red"
        >
          <v-list-item
            v-for="(item, i) in sensorList"
            :key="i"
            :value="item"
          >
            <v-list-item-icon class="mr-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-tooltip bottom>
              <template #activator="{on}">
                <v-list-item-content>
                  <v-list-item-title v-on="on">
                    {{ `${item.InstrumentName}.${item.Name}` }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <div style="white-space: pre;">
                {{ $t('Dashboard.Instrument') }}: {{ item.InstrumentName }}
                {{ `\n${$t('Dashboard.Name')}` }}: {{ item.Name }}
              </div>
            </v-tooltip>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-text
        v-if="sensorList.length === 0"
        class="red--text"
      >
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.NoSensorMessage') }}
      </v-card-text>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    sensorList: {
      type: Array,
      required: true,
    },
    selectedSensor: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      localSelectedSensor: this.selectedSensor,
    };
  },
  watch: {
    localSelectedSensor(newValue) {
      this.$emit('update:selectedSensor', newValue);
    },
  },
};
</script>

<style scoped>
.sensor-list {
  max-height: 300px;
  overflow-y: auto;
}
</style>
