/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';
import importal from '@/api/importal';

export default class GsModels extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          headerName: this.vue.$t('Worker.Modem.ModemId'),
          field: 'Uid',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.WorkerConfigurations.Columns.Name'),
          field: 'Name',
          filter: true,
          sortable: true,
          editable: true,
        },
        {
          headerName: this.vue.$t('Worker.Modem.LastSeen'),
          field: 'LastSeenTs',
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
        },
        {
          field: 'Firmware',
          headerName: i18n.t('Worker.Modem.Firmware'),
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.firmwareVersionRenderer.bind(this),
        },
        {
          field: 'WanIp',
          headerName: i18n.t('Worker.Modem.WanIp'),
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.wanIpRenderer.bind(this),
        },
        {
          field: 'BridgeIp',
          headerName: i18n.t('Worker.Modem.BridgeIp'),
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.bridgeIpRenderer.bind(this),
        },
        {
          field: 'OsRelease',
          headerName: i18n.t('Worker.Modem.OsRelease'),
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.osReleaseRenderer.bind(this),
        },
        {
          field: 'Imei',
          headerName: i18n.t('Worker.Modem.Imei'),
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.imeiRenderer.bind(this),
        },
        {
          field: 'Journal',
          headerName: i18n.t('Alert.Manage.AlertList.Actions'),
          resizable: true,
          width: 120,

          cellRenderer: (params) => {
            const container = document.createElement('div');

            const infoIcon = document.createElement('i');
            infoIcon.className = 'v-icon mdi mdi-account-switch';
            infoIcon.title = i18n.t('Worker.Modem.ChangeAG');
            infoIcon.style.marginRight = '10px';
            infoIcon.style.cursor = 'pointer';
            infoIcon.addEventListener('click', () => {
              this.vue.changeAG(params.data);
            });

            const journalIcon = document.createElement('i');
            journalIcon.className = 'v-icon mdi mdi-book';
            journalIcon.title = i18n.t('Worker.Modem.SeeJournal');
            journalIcon.style.marginRight = '10px';
            journalIcon.style.cursor = 'pointer';
            journalIcon.addEventListener('click', () => {
              this.vue.onSeeJournal(params.data);
            });

            container.appendChild(infoIcon);
            container.appendChild(journalIcon);
            return container;
          },
        },
      ]);
    });
  }

  onChange() {
    return {
      Name(event, successCallback, failCallback) {
        importal
          .post('ModemApiModemRename', {
            ModemId: event.data.Uid,
            Name: event.data.Name,
          })
          .then((resp) => {
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
    };
  }

  firmwareVersionRenderer(params) {
    const firmwareVersion = params.data.CommEquipmentAttributes.find((attr) => attr.Name === 'FirmwareVersion');
    if (firmwareVersion) {
      return firmwareVersion.ValueStr;
    }
    return '';
  }

  wanIpRenderer(params) {
    const firmwareVersion = params.data.CommEquipmentAttributes.find((attr) => attr.Name === 'WanIp');
    if (firmwareVersion) {
      return firmwareVersion.ValueStr;
    }
    return '';
  }

  bridgeIpRenderer(params) {
    const firmwareVersion = params.data.CommEquipmentAttributes.find((attr) => attr.Name === 'BridgeIp');
    if (firmwareVersion) {
      return firmwareVersion.ValueStr;
    }
    return '';
  }

  osReleaseRenderer(params) {
    const firmwareVersion = params.data.CommEquipmentAttributes.find((attr) => attr.Name === 'OsRelease');
    if (firmwareVersion) {
      return firmwareVersion.ValueStr;
    }
    return '';
  }

  imeiRenderer(params) {
    const firmwareVersion = params.data.CommEquipmentAttributes.find((attr) => attr.Name === 'Imei');
    if (firmwareVersion) {
      return firmwareVersion.ValueStr;
    }
    return '';
  }
}
