<template>
  <ag-grid-vue
    style="width: 100%; height: 100%; border-radius: 12px; overflow: hidden;"
    class="ag-theme-balham-dark"
    :column-defs="columnDefs"
    :row-data="rowData"
    @grid-ready="onGridReady"
  />
</template>

<script>
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { AgGridVue } from 'ag-grid-vue';
import * as XLSX from 'xlsx/xlsx.mjs';
import UtcDateTimeCellRenderer from '@/components/UtcDateTimeCellRenderer.vue';
import importal from '@/api/importal';
import DashboardStackVueWidgetAdapter from '../DashboardStackVueWidgetAdapter';

export default {
  name: 'DataTableVueWidget',
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    UtcDateTimeCellRenderer,
  },
  props: {
    widgetSettings: {
      type: Object,
      default: () => {},
      required: true,
    },
    widgetAdapter: {
      type: DashboardStackVueWidgetAdapter,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      editModeFlag: false, // Directly mutated by the adapter
      widgetSize: null, // Directly mutated by the adapter
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    /*
     {
  "TableName": "SensorQuery-b056c01e-ae57-4b3d-b4a3-39e5572d0296",
  "Columns": [
    {
      "ColumnName": "ts",
      "DataType": "System.DateTime",
      "MaxLength": -1
    },
    {
      "ColumnName": "s249",
      "DataType": "System.Double",
      "MaxLength": -1
    },
    {
      "ColumnName": "s250",
      "DataType": "System.Double",
      "MaxLength": -1
    }
  ],
  "Rows": [
    [
      "2022-02-18T17:34:00",
      2599.503,
      2598.757
    ],
    [
      "2022-02-18T17:36:00",
      2599.504,
      2598.812
    ], */
    async init() {
      const data = await (await importal.post('SensorQuery', this.widgetSettings.data)).data;

      this.columnDefs = [];

      data.Columns.forEach((col) => {
        const defChild = {
          field: col.ColumnName,
          headerName: this.findAliasCategory(col.ColumnName)[1],
          sortable: true,
          resizable: true,
        };

        if (col.ColumnName === 'ts') {
          defChild.cellRenderer = 'UtcDateTimeCellRenderer';
        } else {
          defChild.valueFormatter = (params) => {
            if (params.data[col.ColumnName]) {
              params.data[col.ColumnName].toFixed(2);
            }
          };
        }

        const coldef = this.columnDefs
          .find((x) => x.headerName === this.findAliasCategory(col.ColumnName)[0]);
        if (coldef) {
          coldef.children.push(defChild);
        } else {
          this.columnDefs.push({
            headerName: this.findAliasCategory(col.ColumnName)[0],
            children: [defChild],
          });
        }
      });

      const thisData = [];
      for (let i = 0; i < data.Rows.length; i += 1) {
        const obj = {};
        let discard = true;
        for (let j = 0; j < data.Columns.length; j += 1) {
          obj[data.Columns[j].ColumnName] = data.Rows[i][j];
          if (j > 0 && data.Rows[i][j]) {
            discard = false;
          }
        }
        if (!discard) {
          thisData.push(obj);
        }
      }
      this.rowData = thisData;

      // this.autoSizeAll(true); does nothing ?
      this.widgetAdapter.addWidgetMenuItem('export_excel', 'Export XLSX', true);
      const $this = this;
      this.widgetAdapter.registerMenuItemCallback('export_excel', () => {
        $this.onExportExcel(data);
      });
    },
    onExportExcel(data) {
      const workbook = XLSX.utils.book_new();
      const aoa = [];
      {
        const row = [];
        data.Columns.forEach((col) => {
          row.push(this.findAlias(col.ColumnName));
        });
        aoa.push(row);
      }
      data.Rows.forEach((row) => {
        aoa.push(row);
      });
      const worksheet = XLSX.utils.aoa_to_sheet(aoa, {});
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
      XLSX.writeFile(workbook, 'Export.xlsx', {});
    },
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.init();
    },

    findAliasCategory(symbol) {
      const found = this.widgetSettings.visualization.alias.find((x) => x[symbol] !== undefined);
      if (found) {
        return found[symbol].split('.');
      }
      return ['', symbol];
    },

    findAlias(symbol) {
      const found = this.widgetSettings.visualization.alias.find((x) => x[symbol] !== undefined);
      if (found) {
        return found[symbol];
      }
      return symbol;
    },
  },
};
</script>
