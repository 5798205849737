<template>
  <v-dialog v-model="dialog" persistent scrollable width="100%">
    <v-card style="height: Calc(100vh - 130px)">
      <v-card-title style="padding: 0px">
        <v-tabs v-model="tab" style="border-width: 0px">
          <v-tabs-slider />
          <v-tab :key="traces">
            {{ $t("QualityCheck.QCInspections.Actions.Traces") }}
          </v-tab>
          <v-tab :key="towerShadow">
            {{ $t("QualityCheck.QCInspections.Actions.TowerShadow") }}
          </v-tab>
          <v-tab :key="stdWnd">
            {{ $t("QualityCheck.QCInspections.Actions.StdWd") }}
          </v-tab>
          <v-tab :key="turbulenceIntensity">
            {{ $t("QualityCheck.QCInspections.Actions.TurbulenceIntensity") }}
          </v-tab>
          <v-tab :key="summary">
            {{ $t("QualityCheck.QCInspections.Actions.Summary") }}
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-divider />
      <v-card-text style="height: 100vh; padding: 20px; padding-top: 0px">
        <router-view />
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex">
        <v-btn
         :disabled="tab === 0"
          text
          @click="previousTab()"
        >
          <v-icon
            right
            dark
          >
            mdi-chevron-left
          </v-icon>
          {{ $t('Previous' ) }}
        </v-btn>
        <v-btn
          :disabled="tab === 4"
          text
          @click="nextTab()"
        >
          {{ $t('Next' ) }}
          <v-icon
            right
            dark
          >
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-btn
          v-t="'Close'"
          class="ml-auto"
          text
          @click="closeInspection()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      dialog: true,
      tab: null,
    };
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState('qualityCheck/qcInspections', ['selectedSection']),
  },
  watch: {
    tab(newValue) {
      if (newValue === 0) {
        this.$router.push({
          name: 'QualityCheck.QCInspections.Traces',
          params: {
            id: this.id,
          },
        });
      } else if (newValue === 1) {
        this.$router.push({
          name: 'QualityCheck.QCInspections.TowerShadow',
          params: {
            id: this.id,
          },
        });
      } else if (newValue === 2) {
        this.$router.push({
          name: 'QualityCheck.QCInspections.StdWnd',
          params: {
            id: this.id,
          },
        });
      } else if (newValue === 3) {
        this.$router.push({
          name: 'QualityCheck.QCInspections.TurbulenceIntensity',
          params: {
            id: this.id,
          },
        });
      } else if (newValue === 4) {
        this.$router.push({
          name: 'QualityCheck.QCInspections.Summary',
          params: {
            id: this.id,
          },
        });
      }
    },
  },

  created() {
    this.tab = this.selectedSection;
  },

  methods: {
    previousTab() {
      if (this.tab > 0) {
        this.tab -= 1;
      }
    },
    nextTab() {
      if (this.tab < 4) {
        this.tab += 1;
      }
    },
    closeInspection() {
      this.$router.push({
        name: 'QualityCheck.QCInspections.List',
      });
      this.dialog = false;
    },
  },
};
</script>
