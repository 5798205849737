<template>
  <div style="height: 440px; width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.LandOwnerPhones"
      :show-new-button="$auth.roles.includes('Web.LandOwner.Create')"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="landOwnerPhones"
    />
    <router-view />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import LandOwnerPhoneList from '../gridsources/gsLandOwnerPhones';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        LandOwnerPhones: null,
      },
      toolbarButtons: [],
    };
  },
  computed: {
    ...mapState('landOwner/landOwnerPhones', ['landOwnerPhones']),
  },
  created() {
    this.sources.LandOwnerPhones = new LandOwnerPhoneList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.LandOwner.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('New'),
        icon: 'mdi-plus',
        enabled: true,
        click: () => { this.createNew(); },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'LandOwners.Phones.Create' });
    },
    refreshList() {
      this.$store.dispatch('landOwner/landOwnerPhones/list');
    },
  },
};
</script>

 <style>
 .ag-invisible
 {
   width: 0px;
   margin-left: -11px;
 }
 </style>
