<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <!-- <ServerSideDataGrid
      :show-new-button="$auth.roles.includes('Web.Dashboard.ModifyAlert')"
      :grid-source="sources.GsAlert"
      :refresh="refresh"
      :row-data-init="rowdata"
      :toolbar-buttons="toolbarButtons"
      enable-selection="multiple"
      @selection-changed="onSelect"
      @onNewButtonClicked="addNewAlert"
    /> -->
    <ServerSideDataGrid
      :show-new-button="$auth.roles.includes('Web.Dashboard.ModifyAlert')"
      :grid-source="sources.GsAlert"
      :refresh="refresh"
      :row-data-init="displayedRows"
      :toolbar-buttons="toolbarButtons"
      :toolbar-fields="toolbarFields"
      enable-selection="multiple"
      @selection-changed="onSelect"
      @onNewButtonClicked="addNewAlert"
    />
    <MultipleAlertShare
      v-if="showShareMultiplePopup"
      v-model="showShareMultiplePopup"
      :sensors="selectedRows"
      @close-alert-share="onClosePopup"
    />
    <AlertShare
      v-if="showSharePopup"
      v-model="showSharePopup"
      :sensor="selectedAlert"
      @close-alert-share="onClosePopup"
    />
    <AlertMigratePopup
      v-if="mergePopup"
      v-model="mergePopup"
      :dropdown-items="selectedRows"
      :selected-items="selectedRows"
      @on-accept="onMergePopupAccept"
    />
    <SubscribeSetting
      v-if="showEditPopup"
      :sensor-list="selectedAlert"
      :edit-sensor="false"
      :edit-config="!newAlert"
      :new-alert="newAlert"
      @close-subscribe="onEditAlert"
      @close-subscribe-from-table="onRefresh"
    />
    <ConfirmDialog
      v-model="showDeletePopup"
      :title="$t('ConfirmDeletion')"
      @on-accept="deleteMultipleAlerts()"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import importal from '@/api/importal';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import GsAlert from './gridsources/gsalert';
import MultipleAlertShare from './AlertShareMultiple.vue';
import AlertShare from './AlertShare.vue';
import SubscribeSetting from '../../../dashboard/components/subscription/SubscribeSetting.vue';
import ConfirmDialog from '../../../components/ConfirmDialog.vue';
import AlertMigratePopup from './AlertMigratePopup.vue';
import FieldTypesEnum from '@/components/FieldTypesEnum';

function NotNull(x) {
  return x !== null && x !== undefined;
}

export default {
  name: 'AlertList',
  components: {
    ServerSideDataGrid,
    MultipleAlertShare,
    AlertShare,
    SubscribeSetting,
    ConfirmDialog,
    AlertMigratePopup,
  },
  data() {
    return {
      sources: {
        GsAlert: null,
      },
      Info: {
        Name: null,
        Type: null,
        TypeInfo: null,
        Subtitle: null,
        SecondNomTypeInfo: null,
        SecondNomSubtitle: null,
        SensorName: null,
        InstrumentName: null,
        WeekDays: null,
      },
      DayAbbrList: [
        'Alert.Manage.AlertList.Sunday',
        'Alert.Manage.AlertList.Monday',
        'Alert.Manage.AlertList.Tuesday',
        'Alert.Manage.AlertList.Wednesday',
        'Alert.Manage.AlertList.Thursday',
        'Alert.Manage.AlertList.Friday',
        'Alert.Manage.AlertList.Saturday',
      ],
      Scale: [{ Name: 'Months', Scale: 43800 }, { Name: 'Weeks', Scale: 10080 }, { Name: 'Days', Scale: 1440 }, { Name: 'Hours', Scale: 60 }, { Name: 'Minutes', Scale: 1 }],
      Types: [
        {
          name: this.$t('Alert.Manage.AlertList.Nominal'),
          isNominal: true,
          isScheduled: false,
          iconName: 'mdi-numeric',
        },
        {
          name: this.$t('Alert.Manage.AlertList.MissingData'),
          isNominal: false,
          isScheduled: false,
          iconName: 'mdi-help-circle-outline',
        },
        {
          name: this.$t('Alert.Manage.AlertList.Scheduled'),
          isNominal: false,
          isScheduled: true,
          iconName: 'mdi-calendar-month',
        },
      ],
      Statuses: [
        {
          name: this.$t('Alert.Manage.AlertList.Active'),
          isActive: true,
          iconName: 'mdi-alert',
          iconColor: 'red',
        },
        {
          name: this.$t('Alert.Manage.AlertList.Inactive'),
          isActive: false,
          iconName: 'mdi-check-circle',
          iconColor: 'green',
        },
      ],

      refresh: 0,
      toolbarButtons: [],
      toolbarFields: [],
      selectedRows: [],
      selectedAlert: null,
      showEditPopup: false,
      showSharePopup: false,
      showShareMultiplePopup: false,
      showDeletePopup: false,
      selectedType: null,
      selectedStatus: null,
      mergePopup: false,
      newAlert: false,

      searchInstrument: null,
      searchSensor: null,
    };
  },
  computed: {
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),
    ...mapState('alert', ['rowdata']),
    ...mapState('dashboard', ['dashboards']),
    ...mapState('user', ['identity']),
    ...mapState('subscribe', ['mailLists']),

    setButtonEnabled() {
      return this.selectedRows.length > 0;
    },
    displayedRows() {
      return this.rowdata.filter((item) => (!this.selectedType
            || (item.isNominal === this.selectedType.isNominal && item.isScheduled === this.selectedType.isScheduled))
          && (!this.selectedStatus || item.isActive === this.selectedStatus.isActive));
    },
    displayedTypes() {
      const types = this.Types;
      types.sort((a, b) => a.name.localeCompare(b.name));
      return types;
    },
  },
  watch: {
    async tenantId() {
      this.onRefresh();
    },
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.onRefresh();
      }
    },
  },
  async created() {
    this.$store.dispatch('dashboard/list');
    this.$store.dispatch('subscribe/init', this.dashboards);
    this.sources.GsAlert = new GsAlert(this, {
      onEdit: (data) => this.onEdit(data),
    });
  },
  async mounted() {
    this.searchInstrument = this.$route.params.searchInstrument;
    this.searchSensor = this.$route.params.searchSensor;

    await this.getAlertList();

    this.toolbarButtons = [
      {
        tooltip: this.$t('Refresh'),
        icon: 'mdi-refresh',
        enabled: true,
        click: () => { this.onRefresh(); },
      },
      {
        tooltip: this.$t('Alert.Manage.AlertList.Share'),
        icon: 'mdi-account-multiple',
        enabled: this.setButtonEnabled,
        click: () => { this.onShareAlerts(); },
      },
      {
        tooltip: this.$t('Alert.Manage.AlertList.Migrate'),
        icon: 'mdi-call-merge',
        enabled: this.setButtonEnabled,
        click: () => { this.onMergeAlerts(); },
      },
      {
        tooltip: this.$t('Alert.Manage.AlertList.Delete'),
        icon: 'mdi-delete',
        enabled: this.setButtonEnabled,
        click: () => { this.onDeleteAlerts(); },
      },
    ];

    this.toolbarFields = [
      {
        fieldType: FieldTypesEnum.SearchTextField,
        value: this.searchInstrument,
        label: this.$t('Alert.Manage.AlertList.InstrumentName'),
        tooltip: this.$t('Analytics.SensorSelection.Hint'),
        width: '200px',
        onSearched: (value) => { this.updateInstrumentSearch(value); },
      },
      {
        fieldType: FieldTypesEnum.SearchTextField,
        value: this.searchSensor,
        label: this.$t('Alert.Manage.AlertList.SensorName'),
        tooltip: this.$t('Analytics.SensorSelection.Hint'),
        width: '200px',
        onSearched: (value) => { this.updateSensorSearch(value); },
      },
      {
        fieldType: FieldTypesEnum.SelectField,
        value: this.selectedType,
        label: this.$t('Alert.Manage.AlertList.Type'),
        items: this.displayedTypes,
        itemText: 'name',
        width: '200px',
        onChanged: (value) => { this.selectedType = value; },
      },
      {
        fieldType: FieldTypesEnum.SelectField,
        value: this.selectedStatus,
        label: this.$t('Alert.Manage.AlertList.State'),
        items: this.Statuses,
        itemText: 'name',
        width: '200px',
        onChanged: (value) => { this.selectedStatus = value; },
      },
    ];
  },
  methods: {
    async getAlertList() {
      await this.$store.dispatch(
        'alert/list',
        { searchInstrument: this.searchInstrument, searchSensor: this.searchSensor },
      );

      let route = { name: 'Alert.Manage' };
      if (this.searchInstrument && this.searchSensor) {
        route = {
          name: 'Alert.Manage.SearchInstrumentSensor',
          params: { searchInstrument: this.searchInstrument, searchSensor: this.searchSensor },
        };
      } else if (this.searchInstrument) {
        route = {
          name: 'Alert.Manage.SearchInstrument',
          params: { searchInstrument: this.searchInstrument },
        };
      } else if (this.searchSensor) {
        route = {
          name: 'Alert.Manage.SearchSensor',
          params: { searchSensor: this.searchSensor },
        };
      }

      if (this.$route.name !== route.name) {
        this.$router.push(route);
      }
    },
    async onEdit(alert) {
      const resp = await (await importal.get(`AlertBasicList?Id=${alert.BasicAlertConfigId}`)).data[0];
      this.selectedAlert = {
        ...alert,
        IsScheduled: alert.isScheduled,
        SensorId: resp.SensorId,
        ValueDelayHours: resp.ValueDelayHours,
        ValueGt: resp.ValueGt,
        ValueGteq: resp.ValueGteq,
        ValueLt: resp.ValueLt,
        ValueLteq: resp.ValueLteq,
        ViolationMessage: resp.ViolationMessage,
        ViolationTs: resp.ViolationTs,
        ViolationMessageTs: resp.ViolationMessageTs,
        WidgetAuthorization: resp.WidgetAuthorization,
        WidgetSettings: resp.WidgetSettings,
        WidgetType: resp.WidgetType,
        InstrumentName: resp.InstrumentName,
        SensorName: resp.SensorName,
        StateTimeStamp: resp.StateTimeStamp,
        StateValue: resp.StateValue,
      };
      this.showEditPopup = true;
      this.newAlert = false;
    },
    async onMergeAlerts() {
      this.mergePopup = true;
    },
    async onMergePopupAccept(mergeTo) {
      this.mergePopup = false;

      // Create an array of promises for all dispatch calls
      const mergePromises = this.selectedRows
        .filter((alert) => alert.BasicAlertConfigId !== mergeTo.BasicAlertConfigId) // filter only the different IDs
        .map(
          (alert) => this.$store.dispatch('alert/merge', {
            MigrateFrom: alert.BasicAlertConfigId,
            MigrateTo: mergeTo.BasicAlertConfigId,
          }),
        );

      Promise.all(mergePromises).then(() => this.onRefresh());
    },

    quantityActive(item) {
      let active = 0;
      item.BasicAlertConfigSensors.forEach((sensor) => {
        // eslint-disable-next-line no-bitwise
        if (sensor.StateFlag & 0x001) {
          active += 1;
        }
      });
      return active;
    },
    quantityAlerts(item) {
      return item.BasicAlertConfigSensors.length;
    },
    lastViolation(item) {
      let lastestViolation = null;
      item.BasicAlertConfigSensors.forEach((sensor) => {
        if (sensor.ViolationTs) {
          if (lastestViolation) {
            const date1 = new Date(lastestViolation);
            const date2 = new Date(sensor.ViolationTs);
            if (date2 > date1) {
              lastestViolation = sensor.ViolationTs;
            }
          } else {
            lastestViolation = sensor.ViolationTs;
          }
        }
      });
      return lastestViolation;
    },
    onSelect(e) {
      const { api } = e;
      this.selectedRows = api.getSelectedRows();
      [1, 2, 3].forEach((x) => {
        this.toolbarButtons[x].enabled = this.setButtonEnabled;
      });
    },
    onRefresh() {
      this.refresh += 1;
      this.$store.dispatch('dashboard/list');
      this.$store.dispatch('subscribe/init', this.dashboards);
      this.$store.dispatch('alert/list');
      this.onClosePopup();
    },
    onClosePopup() {
      this.setToolBarButtonsDisabled();
      this.selectedRows = [];
      this.showEditPopup = false;
      this.showDeletePopup = false;
      this.showSharePopup = false;
      this.showShareMultiplePopup = false;
    },
    setToolBarButtonsDisabled() {
      [1, 2, 3].forEach((x) => {
        this.toolbarButtons[x].enabled = 0;
      });
    },
    addNewAlert() {
      this.newAlert = true;
      this.showEditPopup = true;
    },
    getEditMissingCheck(alert) {
      const frequencyHours = alert.ValueDelayHours;
      for (let i = 0; i < this.Scale.length; i += 1) {
        if (frequencyHours % (this.Scale[i].Scale / 60) === 0) {
          const Scaling = this.Scale[i].Name;
          const ScalingTranslate = 'Alert.Manage.AlertList.'.concat(Scaling);
          const Value = frequencyHours / (this.Scale[i].Scale / 60);
          const ReturnValue = `${Value} ${this.$t(ScalingTranslate)}`;
          return ReturnValue;
        }
      }
      return `${frequencyHours} ${this.$t('Alert.Manage.AlertList.Hours')}`;
    },
    getEditFrequency(alert) {
      const frequencyHours = alert.ValueDelayHours;
      for (let i = 0; i < this.Scale.length; i += 1) {
        if (frequencyHours % (this.Scale[i].Scale / 60) === 0) {
          const Scaling = this.Scale[i].Name;
          const ScalingTranslate = 'Alert.Manage.AlertList.'.concat(Scaling);
          const Value = frequencyHours / (this.Scale[i].Scale / 60);
          const ReturnValue = `${Value} ${this.$t(ScalingTranslate)}`;
          return ReturnValue;
        }
      }
      return `${frequencyHours} ${this.$t('Alert.Manage.AlertList.Hours')}`;
    },
    isNominal(item) {
      if (
        NotNull(item.ValueLt)
        || NotNull(item.ValueLteq)
        || NotNull(item.ValueGt)
        || NotNull(item.ValueGteq)
      ) {
        return true;
      }
      return false;
    },
    isMissingData(item) {
      if (
        NotNull(item.ValueLt)
        || NotNull(item.ValueLteq)
        || NotNull(item.ValueGt)
        || NotNull(item.ValueGteq)
      ) {
        return false;
      }
      if (item.IsScheduled) {
        return false;
      }
      return true;
    },
    onShareAlerts() {
      if (this.selectedRows.length === 1) {
        [this.selectedAlert] = this.selectedRows;
        this.showSharePopup = true;
      } else {
        this.showShareMultiplePopup = true;
      }
    },
    onEditAlert(alert) {
      if (alert) {
        this.$store.dispatch('alert/refreshEdit', alert);
      }
      this.showEditPopup = false;
    },
    onMuteAlerts() {
      this.selectedRows.forEach((alert) => {
        this.muteAlert(alert);
      });
    },
    async muteAlert(item) {
      await this.$store.dispatch('alert/mute', {
        BasicAlertConfigId: item.BasicAlertConfigId,
        StateFlag: item.StateFlag,
        StateName: 'muted',
        UserId: this.identity.user.UserId,
      });
    },
    showAckPopup() {
      this.popupack.Title = this.$t('Alert.Manage.AlertList.AckTitle');
      this.popupack.Model = {
        Message: null,
      };
      this.popupack.Schema = {
        Message: {
          label: this.$t('Alert.Manage.AlertList.AckMessage'),
          placeholder: this.$t('Alert.Manage.AlertList.WriteMessageOptional'),
          type: 'text',
          flex: 12,
        },
      };
      this.popupack.Show = true;
    },
    onAckPopupAccept(msg) {
      this.selectedRows.forEach((alert) => {
        this.selectedAlert = alert;
        this.acceptAck(msg);
      });
    },
    async acceptAck(msg) {
      await this.$store.dispatch('alert/ack', {
        BasicAlertConfigId: this.selectedAlert.BasicAlertConfigId,
        StateFlag: this.selectedAlert.StateFlag,
        StateName: 'acked',
        Message: JSON.stringify(msg),
        UserId: this.identity.user.UserId,
      });
    },
    onDeleteAlerts() {
      this.showDeletePopup = true;
    },
    deleteMultipleAlerts() {
      this.selectedRows.forEach((alert) => {
        this.selectedAlert = alert;
        this.deleteAlert();
      });
    },
    async deleteAlert() {
      await this.$store.dispatch('alert/deleteConfig', {
        BasicAlertConfigId: this.selectedAlert.BasicAlertConfigId,
      });
    },
    async updateInstrumentSearch(value) {
      this.searchInstrument = value;
      await this.getAlertList();
    },
    async updateSensorSearch(value) {
      this.searchSensor = value;
      await this.getAlertList();
    },
  },
};
</script>
