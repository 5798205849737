import api from '@/api/importal';

const state = {
  tenant: null,
  tenants: [],
  projectManagers: [],
};

const actions = {
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('OpTenantNew', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`OpTenantGet?id=${payload.id}`);
    commit('tenant', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const tenants = await api.get('OpTenantList');
    commit('tenants', tenants.data);

    const projectManagers = await api.post('OpSearchUserList', {
      RoleName: 'Web.ProjectManager',
    });

    commit('projectManagers', projectManagers.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`OpTenantRemove?id=${payload.id}`);
    commit('tenant', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('OpTenantUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
};

const mutations = {
  tenant(state, payload) {
    state.tenant = payload;
  },
  tenants(state, payload) {
    state.tenants = payload;
  },
  projectManagers(state, payload) {
    state.projectManagers = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
