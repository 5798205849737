<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>Aggregation control.</v-card-title>
      <v-subheader class="ma-0">
        Input Aggregation
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-row>
          <v-col sm="5">
            <v-text-field
              v-model="InputMultiplier"
              label="Aggregate by"
              type="number"
              dense
            />
          </v-col>
          <v-col sm="6">
            <v-select
              v-model="InputForEvery"
              :items="AggregationDropdownItems"
              item-text="text"
              item-value="value"
              label=" "
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-text-field
              v-model="InputOffset"
              label="Offset by"
              type="number"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-subheader class="ma-0">
        Output Aggregation
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-row>
          <v-col sm="5">
            <v-text-field
              v-model="OutputMultiplier"
              label="Aggregate by"
              type="number"
              dense
            />
          </v-col>
          <v-col sm="6">
            <v-select
              v-model="OutputForEvery"
              :items="AggregationDropdownItems"
              item-text="text"
              item-value="value"
              label=" "
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-text-field
              v-model="OutputOffset"
              label="Offset by"
              type="number"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="show=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          text
          @click.stop="onAccept"
        >
          {{ $t('Accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'InputMultiplier', event: 'models:InputMultiplier' },
    { data: 'InputForEvery', event: 'models:InputForEvery' },
    { data: 'InputOffset', event: 'models:InputOffset' },
    { data: 'OutputMultiplier', event: 'models:OutputMultiplier' },
    { data: 'OutputForEvery', event: 'models:OutputForEvery' },
    { data: 'OutputOffset', event: 'models:OutputOffset' },
  ],
  props: {
    aggregateForEveryItems: { type: Array, required: true },
    aggregateForEvery2Query: { type: Array, required: true },
  },
  data: () => ({
    show: false,
    InputMultiplier: 1,
    InputForEvery: 'NONE',
    InputOffset: 0,
    OutputMultiplier: 1,
    OutputForEvery: 'NONE',
    OutputOffset: 0,
  }),
  computed: {
    AggregationDropdownItems() {
      const rc = [];
      for (let i = 0; i < this.aggregateForEvery2Query.length; i += 1) {
        rc.push({
          text: this.aggregateForEveryItems[i],
          value: this.aggregateForEvery2Query[i],
        });
      }
      return rc;
    },
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) { this.$emit('models:show', newVal); },
    InputMultiplier: function w(newVal) { this.$emit('models:InputMultiplier', newVal); },
    InputForEvery: function w(newVal) { this.$emit('models:InputForEvery', newVal); },
    InputOffset: function w(newVal) { this.$emit('models:InputOffset', newVal); },
    OutputMultiplier: function w(newVal) { this.$emit('models:OutputMultiplier', newVal); },
    OutputForEvery: function w(newVal) { this.$emit('models:OutputForEvery', newVal); },
    OutputOffset: function w(newVal) { this.$emit('models:OutputOffset', newVal); },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
    },
    onAccept() {
      this.$emit('on-accept', {
        InputMultiplier: this.InputMultiplier,
        InputForEvery: this.InputForEvery,
        InputOffset: this.InputOffset,
        OutputMultiplier: this.OutputMultiplier,
        OutputForEvery: this.OutputForEvery,
        OutputOffset: this.OutputOffset,
      });
      this.show = false;
    },
  },
};
</script>

<style>

</style>
