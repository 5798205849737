<template>
    <div class="secondary row" style="height:85px;padding:0px;margin-left:0px;">
      <div class="col col-3">
        <v-text-field id="txtSearchName"
              v-model="formData.Name"
              :label="$t('QualityCheck.QCInspectionTemplates.Name')"
            />
      </div>
      <div class="col">
          <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn style="margin-top:13px;min-width:40px;padding:0px;"
            v-bind="attrs"
            v-on="on"
            id="btnSearchInspection"
            @click="search();"
          >
         <i class="v-icon mdi mdi-magnify"></i>
          </v-btn>
        </template>
        <span>{{ $t('Search') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
         <v-btn style="margin-top:13px;margin-left:9px;min-width:55px;padding:0px;"
           v-bind="attrs"
           v-on="on"
           @click="clearFilters();"
         >
         <i class="v-icon mdi mdi-backspace"></i>
         </v-btn>
       </template>
       <span>{{ $t('ClearFilters') }}</span>
     </v-tooltip>
     <v-tooltip bottom>
       <template v-slot:activator="{ on, attrs }">
         <v-btn style="margin-top:13px;margin-left:9px;min-width:40px;padding:0px;"
         v-if="$auth.roles.includes('Web.QCInspectionTemplate.Create')"
           v-bind="attrs"
           v-on="on"
           @click="createNew();"
         >
         <i class="v-icon mdi mdi-plus"></i>
         </v-btn>
       </template>
       <span>  {{ $t('AddNew') }} </span>
     </v-tooltip>
     </div>
   </div>
 </template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    ...mapState('qualityCheck/qcInspectionTemplates', ['filters']),
  },
  methods: {

    clearFilters() {
      this.formData.Name = '';
    },

    async search() {
      await this.$store.dispatch('qualityCheck/qcInspectionTemplates/setFilters', this.formData);
      await this.$store.dispatch('qualityCheck/qcInspectionTemplates/list');
    },

    createNew() {
      this.$router.push({ name: 'QualityCheck.QCInspectionTemplates.Create' });
    },

  },
};
</script>
