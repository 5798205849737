import api from '@/api/importal';

const state = {
  qcProblemType: null,
  qcProblemTypes: [],
  descriptionAlreadyExists: false,
};

const actions = {
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCProblemTypesCreate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCProblemTypesGet?id=${payload.id}`);
    commit('qcProblemType', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('QCProblemTypesList');
    commit('qcProblemTypes', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`QCProblemTypesRemove?id=${payload.id}`);
    commit('qcProblemType', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('QCProblemTypesUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('descriptionAlreadyExists', false);
    const response = await api.get(`QCProblemTypesValidate?id=${payload.ProblemTypeId != null ? payload.ProblemTypeId : 0}&description=${payload.Description}`);
    if (response.data != null) {
      commit('descriptionAlreadyExists', true);
    }
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  qcProblemType(state, payload) {
    state.qcProblemType = payload;
  },
  qcProblemTypes(state, payload) {
    state.qcProblemTypes = payload;
  },
  descriptionAlreadyExists(state, payload) {
    state.descriptionAlreadyExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
