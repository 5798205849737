export default [
  {
    label: 'VirtualInstrument.Title',
    icon: 'mdi-calculator',
    to: { name: 'VirtualInstrument.List' },
    title: 'VirtualInstrument.PageTitle',
    items: [
      {
        label: 'VirtualInstrument.List.Title',
        to: { name: 'VirtualInstrument.List' },
        title: 'VirtualInstrument.List.PageTitle',
      },
      {
        label: 'VirtualInstrument.Template.Title',
        to: { name: 'VirtualInstrumentTemplate.List' },
        title: 'VirtualInstrument.Template.PageTitle',
      },
      {
        label: 'VirtualInstrument.TemplateSets.Title',
        to: { name: 'VirtualInstrumentTemplateSet.List' },
        title: 'VirtualInstrument.TemplateSets.PageTitle',
      },
    ],
  },
];
