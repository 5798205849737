<template>
  <span
    v-if="!params.show || params.show(params)"
  >
    <ConfirmDialog
      v-model="showConfirm"
      :message="confirmMessage"
      @on-accept="onAccept"
    />
    <v-btn
      icon
      x-small
      style="vertical-align: baseline;"
      @click="btnClickedHandler()"
    >
      <i :title="params.tooltip" :class="`v-icon mdi ${iconSelector(params)}`" />
    </v-btn>
    {{ params.value }}
  </span>
</template>

<script>
import ConfirmDialog from './ConfirmDialog.vue';

export default {
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      showConfirm: false,
      confirmMessage: null,
    };
  },
  computed: {
  },
  methods: {
    iconSelector(params) {
      if (typeof params.icon === 'function') {
        return params.icon(params);
      }

      if (params.icon && String(params.icon).startsWith('mdi-')) {
        return params.icon;
      }

      return 'mdi-pencil';
    },
    btnClickedHandler() {
      if (!this.params.noConfirm) {
        if (this.params.confirm) {
          this.confirmMessage = `Are you sure you want to edit ${this.params.confirm(this.params)}?`;
        } else {
          this.confirmMessage = 'Are you sure?';
        }
        this.showConfirm = true;
      } else {
        this.onAccept();
      }
    },
    onAccept() {
      this.params.clicked(this.params);
    },
  },
};
</script>
