/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsMapManagement extends ServerSideDataGridGsClientBase {
  constructor(cb) {
    super();
    this.callback = cb;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          resizable: true,
          width: 50,
          pinned: 'left',
          cellRenderer: (event) => {
            const editIcon = document.createElement('i');
            editIcon.className = 'v-icon mdi mdi-pencil';
            editIcon.title = i18n.t('Edit');
            editIcon.style.marginRight = '10px';
            editIcon.style.cursor = 'pointer';
            editIcon.addEventListener('click', () => {
              this.callback.onEditMap(event.data);
            });

            const container = document.createElement('div');
            container.appendChild(editIcon);
            return container;
          },
        },
        {
          field: 'Name',
          headerName: i18n.t('Analytics.MapManagement.Name'),
          pinned: 'left',
          resizable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'Group',
          headerName: i18n.t('Analytics.MapManagement.Group'),
          resizable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'AccessGroup.AccessGroupName',
          headerName: i18n.t('Analytics.MapManagement.AccessGroup'),
          width: 300,
          resizable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'ImageName',
          headerName: i18n.t('Analytics.MapManagement.Image'),
          width: 300,
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            tooltip: `${i18n.t('Edit')} ${i18n.t('Analytics.MapManagement.Image')}`,
            clicked: (event) => {
              this.callback.onEditImage(event.data);
            },
          },
        },
        {
          resizable: true,
          width: 50,
          cellRenderer: (event) => {
            const deleteIcon = document.createElement('i');
            deleteIcon.className = 'v-icon mdi mdi-delete-forever';
            deleteIcon.title = i18n.t('Delete');
            deleteIcon.style.marginRight = '10px';
            deleteIcon.style.cursor = 'pointer';
            deleteIcon.addEventListener('click', () => {
              this.callback.onDeleteMap(event.data);
            });

            const container = document.createElement('div');
            container.appendChild(deleteIcon);
            return container;
          },
        },
      ]);
    });
  }

  onNewItem() {
    if (this.callback) {
      this.callback.onNewMap();
    }
  }

  newItemFormDefinition() {
    if (this.callback) {
      this.callback.onNewMap();
    }
    return null;
  }
}
