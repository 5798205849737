import api from '@/api/importal';
import datehandling from '@/components/datehandling';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  rowdata: [],
  mapRowdata: [],
  query: null,
  table: null,
};

const actions = {
  async change({ commit }, payload) {
    commit('updateSelected', payload);
  },
  async updateQuery({ commit }, payload) {
    commit('updateQuery', payload);
  },

  async sensorQuery({ commit }) {
    if (state.query.data.input_filter
      && state.query.data.input_filter[0]
      && state.query.data.input_filter[0].time_range) {
      // if time_range, change to UTC
      state.query.data.input_filter[0].time_range.end = datehandling.utcToZonedTime(
        state.query.data.input_filter[0].time_range.end,
      );
      state.query.data.input_filter[0].time_range.start = datehandling.utcToZonedTime(
        state.query.data.input_filter[0].time_range.start,
      );
    }
    return withWait({ commit }, () => api.post('SensorQuery', state.query.data))
      .then((resp) => commit('setRowData', resp.data));
  },

  async removeValues({ commit }, payload) {
    return withWait({ commit }, () => api.post('DevValidatingRemoveValue', payload))
      .then(() => commit('updateRowData', payload));
  },

  async mapList({ commit }) {
    return withWait({ commit }, () => api.get('MapBaseLayerList'))
      .then((resp) => {
        commit('setMapRowData', resp.data);
      });
  },
  async mapCreate({ commit }, payload) {
    const params = `name=${payload.Name}&group=${payload.Group}`
      + `&accessGroupId=${payload.AccessGroupId}&imageAspect=${payload.ImageAspect}`;

    return withWait({ commit }, () => api.post(`MapBaseLayerNew?${params}`, payload.File))
      .then((resp) => commit('setMapRowData', resp.data));
  },
  async mapUpdate({ commit }, payload) {
    return withWait({ commit }, () => api.post('MapBaseLayerUpdate', payload))
      .then((resp) => commit('setMapRowData', resp.data));
  },
  async mapRemove({ commit }, mapId) {
    return withWait({ commit }, () => api.delete(`MapBaseLayerRemove?id=${mapId}`))
      .then((resp) => commit('setMapRowData', resp.data));
  },
  async uploadImage({ commit }, payload) {
    const params = `baseLayerId=${payload.BaseLayerId}&aspect=${payload.ImageAspect}`;

    return withWait({ commit }, () => api.post(`MapBaseLayerUpload?${params}`, payload.File))
      .then((resp) => commit('setMapRowData', resp.data));
  },
};

const mutations = {
  updateSelected(state, payload) {
    state.rowdata = payload;
  },

  updateQuery(state, payload) {
    state.query = payload;
  },

  setRowData(state, payload) {
    state.table = payload;
  },

  updateRowData(state, rowData) {
    rowData.DateTimes.forEach((time) => {
      const index = state.table.Rows.findIndex((row) => row[0] === time);
      if (index !== -1) {
        for (let i = 1; i < state.table.Rows[index].length; i += 1) {
          state.table.Rows[index][i] = null;
        }
      }
    });
  },

  setMapRowData(state, payload) {
    state.mapRowdata = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
