export default [
  {
    label: 'Devices.Title',
    icon: 'mdi-cellphone-wireless',
    title: 'Devices.PageTitle',
    items: [{
      label: 'Devices.Instruments.Title',
      to: { name: 'Devices.Instrument' },
      title: 'Devices.Instruments.PageTitle',
    },
    {
      label: 'Devices.Gateway.Title',
      to: { name: 'Devices.Gateway' },
      title: 'Devices.Gateway.PageTitle',
    },
    {
      label: 'WindServer.Sites.Title',
      to: { name: 'Sites.List' },
      title: 'WindServer.Sites.PageTitle',
      isWindServer: true,
    }],
  },
];
