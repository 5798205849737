<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <DataGrid
      :grid-source="sources.GsGateWay"
      :show-new-button="$auth.roles.includes('Web.Gateway.Create')"
      :row-data-init="rowdata"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DataGrid from '@/components/ServerSideDataGrid.vue';
import GsGateWay from './gridsources/gsgateway';

export default {
  name: 'DeviceList',
  components: {
    DataGrid,
  },
  data: () => ({
    sources: {
      GsGateWay: null,
    },
  }),
  computed: {
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),
    ...mapState('devices/gateways', ['rowdata']),
  },
  watch: {
    async tenantId() {
      this.$store.dispatch('devices/gateways/list');
    },
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.$store.dispatch('devices/gateways/list');
      }
    },
  },
  created() {
    this.sources.GsGateWay = new GsGateWay(this);
    this.$store.dispatch('devices/gateways/list');
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>
