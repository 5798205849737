<template>
  <v-dialog
    v-model="show"
    persistent
    width="400"
  >
    <v-card tile>
      <v-toolbar>
        <v-toolbar-title>
          {{ $t('PickIcon') }}
        </v-toolbar-title>
        <v-spacer />
        <v-app-bar-nav-icon>
          <v-icon
            x-large
            dense
          >
            {{ selectedIcon }}
          </v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-card-text>
        <mdi-icon-picker
          v-model="selectedIcon"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="submitClick"
        >
          {{ $t('Submit') }}
        </v-btn>
        <v-btn
          text
          @click="show = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MdiIconPicker from '@/components/MdiIconPicker.vue';

export default {
  components: {
    MdiIconPicker,
  },
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'icon', event: 'models:icon' },
  ],
  props: {
  },
  emits: ['click:submit'],
  data: () => ({
    show: false,
    selectedIcon: '',
    icon: '',
  }),
  i18n: {
    messages: {
      en: {
        PickIcon: 'Pick an Icon',
        Submit: 'Submit',
        Close: 'Close',
      },
      fr: {
        PickIcon: 'Choisir un icône',
        Submit: 'Soumettre',
        Close: 'Fermer',
      },
    },
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) {
      if (newVal) {
        this.dialogShown();
      }
      this.$emit('models:show', newVal);
    },
    icon: function w(newVal) {
      this.selectedIcon = newVal;
      this.$emit('models:icon', newVal);
    },
  },
  mounted() {
    this.selectedIcon = this.icon;
  },
  methods: {
    submitClick() {
      this.icon = this.selectedIcon;
      this.show = false;
      this.$emit('click:submit', this.icon);
    },
    dialogShown() {
      this.selectedIcon = this.icon;
    },
  },
};
</script>

<style>

</style>
