<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-container fluid>
          <p>
            {{ $t('WindServer.Sites.DataTrace.ShiftDate') }}
          </p>
          <div>
            <v-card-text>
              <v-text-field
                ref="shiftInMinutes"
                v-model="formData.ShiftInMinutes"
                :label="$t('WindServer.Sites.DataTrace.ShiftMinutes')"
                :rules="numberRule"
                :autofocus="true"
                type="number"
              />
              <datetime-picker
                v-model="formData.StartDate"
                :label="$t('WindServer.Sites.DataTrace.FromDate')"
                :date-picker-props="pickerDateProps"
                :time-picker-props="pickerTimeProps"
                :has-clear-button="false"
                :rules="requiredRule"
              />
              <datetime-picker
                v-model="formData.EndDate"
                :label="$t('WindServer.Sites.DataTrace.ToDate')"
                :date-picker-props="pickerDateProps"
                :time-picker-props="pickerTimeProps"
                :has-clear-button="false"
                :rules="requiredRule"
              />
            </v-card-text>
          </div>
        </v-container>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from '@/plugins/i18n';
import DatetimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    flag: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      formData: {},
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      numberRule: [(v) => (!Number.isNaN(parseFloat(v)) && Number(v) !== 0) || this.$t('Validation.Required')],
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  computed: {
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$emit('input', value);
      },
    },
  },
  created() {
  },
  methods: {
    setFormData() {
      const dt = new Date();
      this.formData = {
        StartDate: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - 1, 0, 0, 0),
        EndDate: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - 1, 23, 59, 59),
        ShiftInMinutes: 0,
      };
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          this.formData.ShiftInMinutes = Number(this.formData.ShiftInMinutes);
          await this.$store.dispatch('site/dataTrace/shiftFlags', this.formData);
          this.$emit('show-modal', true);
          this.close();
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.$emit('show-modal', false);
      this.$store.dispatch('site/dataTrace/initFlags', null);
      this.formData = {};
      this.$refs.shiftInMinutes.reset();
    },
  },
};
</script>
