<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="pa-3 mb-3">
        {{ title }}
      </v-card-title>
      <v-row no-gutters>
        <v-col cols="auto">
          <v-card-text class="pb-0">
            {{ `${$t('Dashboard.EditSensor.Alias')} :` }}
          </v-card-text>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.alias"
            class="py-0"
            hide-details
          />
        </v-col>
        <v-col
          cols="auto"
          class="pr-2"
        >
          <v-btn
            icon
            small
            @click="form.alias = ''"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-tooltip
        bottom
        :disabled="form.unit !== null"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-select
              v-if="sensor.T === 'S'"
              v-model="form.unit"
              :label="$t('Dashboard.EditSensor.Unit')"
              :items="units"
              :disabled="!form.unit"
              item-text="Symbol"
              class="ma-4 py-2"
              hide-details
              dense
              return-object
            />
          </div>
        </template>
        {{ noUnitText }}
      </v-tooltip>
      <v-tooltip
        bottom
        :disabled="form.readingUnit !== null"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-select
              v-if="sensor.T === 'V'"
              v-model="form.readingUnit"
              :label="$t('Dashboard.EditSensor.ReadingUnit')"
              :items="readingUnits"
              :disabled="!form.readingUnit"
              item-text="Symbol"
              class="ma-4 py-2"
              hide-details
              dense
              return-object
            />
          </div>
        </template>
        {{ noUnitText }}
      </v-tooltip>
      <v-tooltip
        bottom
        :disabled="form.samplingUnit !== null"
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-select
              v-if="sensor.T === 'V'"
              v-model="form.samplingUnit"
              :label="$t('Dashboard.EditSensor.SamplingUnit')"
              :items="samplingUnits"
              :disabled="!form.samplingUnit"
              item-text="Symbol"
              class="ma-4 py-2"
              hide-details
              dense
              return-object
            />
          </div>
        </template>
        {{ noUnitText }}
      </v-tooltip>
      <v-card-actions class="justify-end pt-4 ">
        <v-btn
          text
          @click="show=false"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
          text
          :disabled="isLoading || saveDisabled"
          @click="save"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    sensor: {
      type: Object,
      required: true,
    },
    unitList: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    units: [],
    readingUnits: [],
    samplingUnits: [],
    form: {
      alias: '',
      unit: null,
      readingUnit: null,
      samplingUnit: null,
    },
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    title() {
      const type = this.sensor.T === 'S'
        ? this.$t('Dashboard.Sensor') : this.$t('Dashboard.Vector');
      return this.$t('Dashboard.EditSensor.Title', { type });
    },
    saveDisabled() {
      if (this.sensor.T === 'S') {
        const formUnitName = this.form.unit ? this.form.unit.Symbol : null;
        return this.form.alias === (this.sensor.Alias || '')
          && formUnitName === (this.sensor.Unit || null);
      }

      const formReadingUnitName = this.form.readingUnit ? this.form.readingUnit.Symbol : null;
      const formSamplingUnitName = this.form.samplingUnit ? this.form.samplingUnit.Symbol : null;

      return this.form.alias === (this.sensor.Alias || '')
        && formReadingUnitName === (this.sensor.ReadingUnit || null)
        && formSamplingUnitName === (this.sensor.SamplingUnit || null);
    },
    noUnitText() {
      const name = this.$t('Devices.Title');
      return this.$t('Dashboard.EditSensor.NoUnit', { name });
    },
  },
  async created() {
    this.isLoading = true;
    this.form.alias = this.sensor.Alias || '';

    if (this.sensor.T === 'S') {
      if (this.sensor.Unit) {
        this.form.unit = this.unitList.find((x) => x.Symbol === this.sensor.Unit);
        if (this.form.unit) {
          this.units = this.unitList.filter((x) => x.UnitType === this.form.unit.UnitType);
        } else {
          this.units = this.unitList;
        }
      }
    } else {
      if (this.sensor.ReadingUnit) {
        this.form.readingUnit = this.unitList.find((x) => x.Symbol === this.sensor.ReadingUnit);
        if (this.form.readingUnit) {
          this.readingUnits = this.unitList
            .filter((x) => x.UnitType === this.form.readingUnit.UnitType);
        } else {
          this.readingUnits = this.unitList;
        }
      }

      if (this.sensor.SamplingUnit) {
        this.form.samplingUnit = this.unitList.find((x) => x.Symbol === this.sensor.SamplingUnit);
        if (this.form.samplingUnit) {
          this.samplingUnits = this.unitList
            .filter((x) => x.UnitType === this.form.samplingUnit.UnitType);
        } else {
          this.samplingUnits = this.unitList;
        }
      }
    }
    this.isLoading = false;
  },
  methods: {
    save() {
      this.show = false;
      this.$emit('save', this.form);
    },
  },
};
</script>
