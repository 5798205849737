<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    offset-y
    allow-overflow
    max-width="600"
    max-height="600"
  >
    <template #activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-icon
            class="px-1"
            :disabled="(isCreate && placeholders.length >= 5) || (!isCreate && placeholders.length == 0)"
            v-bind="{ ...attrsMenu, ...attrsTooltip }"
            v-on="{ ...onMenu, ...onTooltip }"
            @click="show=true"
          >
            {{ isCreate ? 'mdi-plus' : 'mdi-pencil' }}
          </v-icon>
        </template>
        {{ titleMenu }}
      </v-tooltip>
    </template>
    <v-card>
      <ConfirmDialog
        v-model="dialog.show"
        :title="dialog.title"
        :message="dialog.message"
        :no-cancel="dialog.noCancel"
        @on-accept="dialog.acceptCallback()"
      />
      <v-card-title>{{ titleMenu }}</v-card-title>
      <v-card class="px-4">
        <v-row
          v-if="!isCreate"
          class="no-gutters"
        >
          <v-col sm="3">
            <v-card-text class="pa-0 pt-3">
              {{ $t('Dashboard.Placeholder.TitleToolbar') }}:
            </v-card-text>
          </v-col>
          <v-col sm="9">
            <v-select
              v-model="selectedPlaceholderChoice"
              :items="placeholderChoices"
              item-text="Name"
              class="pt-0"
              hide-details
              return-object
            />
          </v-col>
        </v-row>
        <v-divider class="pt-8" />
        <v-tooltip bottom>
          <template #activator="{ on: on1 }">
            <v-text-field
              v-model="form.JsonData.Name"
              :label="$t('Dashboard.Placeholder.PlaceholderName')"
              :disabled="isEditAndItemNotSelected"
              hide-details
              v-on="on1"
              @input="updateSaveDisabled()"
            />
          </template>
          {{ $t('Dashboard.Placeholder.PlaceholderNameTooltip') }}
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on: on2 }">
            <v-text-field
              v-model="form.JsonData.Alias"
              :label="$t('Dashboard.Placeholder.Alias')"
              :disabled="isEditAndItemNotSelected"
              hide-details
              v-on="on2"
              @input="updateSaveDisabled()"
            />
          </template>
          <div style="white-space: pre;">
            {{ $t('Dashboard.Placeholder.AliasTooltip', { alias:getDefaultAlias }) }}
          </div>
        </v-tooltip>
        <v-row>
          <v-col>
            <v-select
              v-model="form.JsonData.DropdownType"
              :items="dropdownTypeItems"
              :label="$t('Dashboard.Placeholder.DropdownType')"
              item-text="Text"
              :disabled="isEditAndItemNotSelected"
              hide-details
              return-object
              @change="updateAttributeList();"
            />
          </v-col>
          <v-col
            v-if="isAttributeDropdown"
          >
            <v-select
              v-model="form.JsonData.DropdownAttributeName"
              :items="dropdownAttributeItems"
              :label="$t('Dashboard.Placeholder.Name')"
              item-text="Text"
              :disabled="isEditAndItemNotSelected"
              hide-details
              @change="updateSaveDisabled()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-title>
              <span> {{ $t('Dashboard.Placeholder.FirstRuleSettings') }}</span>
            </v-card-title>
          </v-col>
          <v-col>
            <v-card-actions
              v-if="numberOfRules > 1"
              class="justify-end"
            >
              <v-btn
                @click="removeRule1"
              >
                <v-icon>
                  mdi-minus
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-select
          v-model="form.JsonData.RuleType"
          :items="ruleTypeItems"
          :label="$t('Dashboard.Placeholder.RuleType')"
          item-text="Text"
          :disabled="isEditAndItemNotSelected"
          hide-details
          return-object
          @change="onRuleTypeChange()"
        />
        <v-row>
          <v-col :cols="isAttributeValue ? 3 : 6">
            <v-select
              v-model="form.JsonData.ValueType"
              :items="valueTypeDisplayedItems"
              item-text="Text"
              :label="$t('Dashboard.Placeholder.ValueType')"
              :disabled="isEditAndItemNotSelected || !form.JsonData.RuleType"
              hide-details
              return-object
              @change="onValueTypeChange()"
            />
          </v-col>
          <v-col v-if="isAttributeValue">
            <v-row>
              <v-col>
                <v-select
                  v-model="form.JsonData.ValueName"
                  :items="attributesNames"
                  :label="$t('Dashboard.Placeholder.Name')"
                  hide-details
                  return-object
                  @change="updateSaveDisabled()"
                />
              </v-col>
              <v-col>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on: on6 }">
                    <v-text-field
                      v-model="form.JsonData.Value"
                      :label="$t('Dashboard.Placeholder.Value')"
                      :disabled="isEditAndItemNotSelected || !form.JsonData.ValueType"
                      class="pt-3"
                      hide-details
                      v-on="on6"
                      @input="updateSaveDisabled()"
                    />
                  </template>
                  <div style="white-space: pre;">
                    {{ $t('Analytics.SensorSelection.Hint') }}
                  </div>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else>
            <v-select
              v-if="isUnitValue"
              v-model="form.JsonData.Value"
              :items="units"
              item-text="Text"
              :label="$t('Dashboard.Placeholder.Value')"
              hide-details
              return-object
              @change="updateSaveDisabled()"
            />
            <v-tooltip
              v-else
              bottom
            >
              <template #activator="{ on: on3 }">
                <v-text-field
                  v-model="form.JsonData.Value"
                  :label="$t('Dashboard.Placeholder.Value')"
                  :disabled="isEditAndItemNotSelected || !form.JsonData.ValueType"
                  class="pt-3"
                  hide-details
                  v-on="on3"
                  @input="updateSaveDisabled()"
                />
              </template>
              <div style="white-space: pre;">
                {{ $t('Analytics.SensorSelection.Hint') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row
          v-if="numberOfRules >= 2"
        >
          <v-col>
            <v-card-title>
              <span> {{ $t('Dashboard.Placeholder.SecondRuleSettings') }}</span>
            </v-card-title>
          </v-col>
          <v-col>
            <v-card-actions
              v-if="numberOfRules > 1"
              class="justify-end"
            >
              <v-btn
                @click="removeRule2"
              >
                <v-icon>
                  mdi-minus
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-select
          v-if="numberOfRules >= 2"
          v-model="form.JsonData.RuleType2"
          :items="ruleTypeItems2"
          :label="$t('Dashboard.Placeholder.RuleType')"
          item-text="Text"
          :disabled="isEditAndItemNotSelected"
          hide-details
          return-object
          @change="onRuleTypeChange2()"
        />
        <v-row
          v-if="numberOfRules >= 2"
        >
          <v-col :cols="isAttributeValue2 ? 3 : 6">
            <v-select
              v-model="form.JsonData.ValueType2"
              :items="valueTypeDisplayedItems2"
              item-text="Text"
              :label="$t('Dashboard.Placeholder.ValueType')"
              :disabled="isEditAndItemNotSelected || !form.JsonData.RuleType2"
              hide-details
              return-object
              @change="onValueTypeChange2()"
            />
          </v-col>
          <v-col v-if="isAttributeValue2">
            <v-row>
              <v-col>
                <v-select
                  v-model="form.JsonData.ValueName2"
                  :items="attributesNames2"
                  :label="$t('Dashboard.Placeholder.Name')"
                  hide-details
                  return-object
                  @change="updateSaveDisabled()"
                />
              </v-col>
              <v-col>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on: on7 }">
                    <v-text-field
                      v-model="form.JsonData.Value2"
                      :label="$t('Dashboard.Placeholder.Value')"
                      :disabled="isEditAndItemNotSelected || !form.JsonData.ValueType2"
                      class="pt-3"
                      hide-details
                      v-on="on7"
                      @input="updateSaveDisabled()"
                    />
                  </template>
                  <div style="white-space: pre;">
                    {{ $t('Analytics.SensorSelection.Hint') }}
                  </div>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else>
            <v-select
              v-if="isUnitValue2"
              v-model="form.JsonData.Value2"
              :items="units"
              item-text="Text"
              :label="$t('Dashboard.Placeholder.Value')"
              hide-details
              return-object
              @change="updateSaveDisabled()"
            />
            <v-tooltip
              v-else
              bottom
            >
              <template #activator="{ on: on4 }">
                <v-text-field
                  v-model="form.JsonData.Value2"
                  :label="$t('Dashboard.Placeholder.Value')"
                  :disabled="isEditAndItemNotSelected || !form.JsonData.ValueType2"
                  class="pt-3"
                  hide-details
                  v-on="on4"
                  @input="updateSaveDisabled()"
                />
              </template>
              <div style="white-space: pre;">
                {{ $t('Analytics.SensorSelection.Hint') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row
          v-if="numberOfRules === 3"
        >
          <v-col>
            <v-card-title>
              <span> {{ $t('Dashboard.Placeholder.ThirdRuleSettings') }}</span>
            </v-card-title>
          </v-col>
          <v-col>
            <v-card-actions
              v-if="numberOfRules > 2"
              class="justify-end"
            >
              <v-btn
                @click="removeRule3"
              >
                <v-icon>
                  mdi-minus
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-select
          v-if="numberOfRules === 3"
          v-model="form.JsonData.RuleType3"
          :items="ruleTypeItems3"
          :label="$t('Dashboard.Placeholder.RuleType')"
          item-text="Text"
          :disabled="isEditAndItemNotSelected"
          hide-details
          return-object
          @change="onRuleTypeChange3()"
        />
        <v-row
          v-if="numberOfRules === 3"
        >
          <v-col :cols="isAttributeValue3 ? 3 : 6">
            <v-select
              v-model="form.JsonData.ValueType3"
              :items="valueTypeDisplayedItems3"
              item-text="Text"
              :label="$t('Dashboard.Placeholder.ValueType')"
              :disabled="isEditAndItemNotSelected || !form.JsonData.RuleType3"
              hide-details
              return-object
              @change="onValueTypeChange3()"
            />
          </v-col>
          <v-col v-if="isAttributeValue3">
            <v-row>
              <v-col>
                <v-select
                  v-model="form.JsonData.ValueName3"
                  :items="attributesNames3"
                  :label="$t('Dashboard.Placeholder.Name')"
                  hide-details
                  return-object
                  @change="updateSaveDisabled()"
                />
              </v-col>
              <v-col>
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on: on8 }">
                    <v-text-field
                      v-model="form.JsonData.Value3"
                      :label="$t('Dashboard.Placeholder.Value')"
                      :disabled="isEditAndItemNotSelected || !form.JsonData.ValueType3"
                      class="pt-3"
                      hide-details
                      v-on="on8"
                      @input="updateSaveDisabled()"
                    />
                  </template>
                  <div style="white-space: pre;">
                    {{ $t('Analytics.SensorSelection.Hint') }}
                  </div>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else>
            <v-select
              v-if="isUnitValue3"
              v-model="form.JsonData.Value3"
              :items="units"
              item-text="Text"
              :label="$t('Dashboard.Placeholder.Value')"
              hide-details
              return-object
              @change="updateSaveDisabled()"
            />
            <v-tooltip
              v-else
              bottom
            >
              <template #activator="{ on: on5 }">
                <v-text-field
                  v-model="form.JsonData.Value3"
                  :label="$t('Dashboard.Placeholder.Value')"
                  :disabled="isEditAndItemNotSelected || !form.JsonData.ValueType3"
                  class="pt-3"
                  hide-details
                  v-on="on5"
                  @input="updateSaveDisabled()"
                />
              </template>
              <div style="white-space: pre;">
                {{ $t('Analytics.SensorSelection.Hint') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card-actions
          v-if="numberOfRules < 3"
          class="justify-end"
        >
          <v-btn
            text
            @click="addRule"
          >
            {{ $t('Dashboard.Placeholder.AddRule') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card-actions class="justify-end pt-5">
        <v-btn
          text
          :disabled="saveDisabled"
          @click="onSave"
        >
          {{ $t('Accept') }}
        </v-btn>
        <v-btn
          text
          @click="resetWidget"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          v-if="!isCreate"
          text
          :disabled="!selectedPlaceholder"
          @click="showDeleteWarning"
        >
          {{ $t('Delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import api from '@/api/importal';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  name: 'PlaceholderMenu',
  components: {
    ConfirmDialog,
  },
  props: {
    isCreate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    show: false,
    saveDisabled: true,
    on: true,
    selectedPlaceholderChoice: null,
    placeholderChoices: [],
    selectedPlaceholder: null,
    ruleTypeItems: [],
    ruleTypeItems2: [],
    ruleTypeItems3: [],
    valueTypeItems: [],
    dropdownTypeItems: [],
    valueTypeDisplayedItems: [],
    valueTypeDisplayedItems2: [],
    valueTypeDisplayedItems3: [],
    numberOfRules: 1,
    dropdownAttributeItems: [],

    units: [],
    instAttrNames: [],
    sensorAttrNames: [],
    attributesNames: [],
    instAttrNames2: [],
    sensorAttrNames2: [],
    attributesNames2: [],
    instAttrNames3: [],
    sensorAttrNames3: [],
    attributesNames3: [],

    form: {
      DashboardId: null,
      PlaceholderId: null,
      Order: null,
      JsonData: {
        Name: '',
        Alias: '',
        RuleType: null,
        RuleType2: null,
        RuleType3: null,
        ValueType: null,
        ValueType2: null,
        ValueType3: null,
        ValueName: null,
        Value: null,
        ValueName2: null,
        Value2: null,
        ValueName3: null,
        Value3: null,
        DropdownType: null,
        DropdownAttributeName: null,
      },
    },
    dialog: {
      show: false,
      message: '',
      title: '',
      noCancel: false,
      acceptCallback: null,
    },
  }),
  computed: {
    ...mapState('devices/instruments/units', ['categories']),
    ...mapState('dashboard', ['selectedDashboard']),
    ...mapState('dashboardPlaceholders', ['placeholders']),
    ...mapGetters('dashboardPlaceholders', ['defaultName', 'defaultAlias']),

    isUnitValue() {
      return this.form.JsonData.ValueType
        && (this.form.JsonData.ValueType.Name === 'Unit' || this.form.JsonData.ValueType.Name === 'ReadingUnit');
    },
    isUnitValue2() {
      return this.form.JsonData.ValueType2
        && (this.form.JsonData.ValueType2.Name === 'Unit' || this.form.JsonData.ValueType2.Name === 'ReadingUnit');
    },
    isUnitValue3() {
      return this.form.JsonData.ValueType3
        && (this.form.JsonData.ValueType3.Name === 'Unit' || this.form.JsonData.ValueType3.Name === 'ReadingUnit');
    },
    isAttributeValue() {
      return this.form.JsonData.ValueType && this.form.JsonData.ValueType.Name === 'Attribute';
    },
    isAttributeValue2() {
      return this.form.JsonData.ValueType2 && this.form.JsonData.ValueType2.Name === 'Attribute';
    },
    isAttributeValue3() {
      return this.form.JsonData.ValueType3 && this.form.JsonData.ValueType3.Name === 'Attribute';
    },
    titleMenu() {
      return this.$t('Dashboard.Placeholder.TitleMenu',
        { action: this.isCreate ? this.$t('Create') : this.$t('Edit') });
    },
    isEditAndItemNotSelected() {
      return !this.isCreate && !this.selectedPlaceholder;
    },
    getDefaultAlias() {
      return this.defaultAlias(true);
    },
    isAttributeDropdown() {
      return (
        (this.form.JsonData.DropdownType === 'Instrument attributes')
        || (this.form.JsonData.DropdownType === 'Sensor attributes')
      );
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.form = {
          DashboardId: this.selectedDashboard.DashboardId,
          PlaceholderId: null,
          Order: null,
          JsonData: {
            Name: '',
            Alias: '',
            RuleType: null,
            RuleType2: null,
            RuleType3: null,
            ValueType: null,
            ValueType2: null,
            ValueType3: null,
            ValueName: null,
            Value: null,
            ValueName2: null,
            Value2: null,
            ValueName3: null,
            Value3: null,
            DropdownType: null,
            DropdownAttributeName: null,
          },
        };

        if (!this.isCreate) {
          this.placeholderChoices = this.placeholders.map((x) => ({
            Id: x.PlaceholderId,
            Name: x.JsonData.Name ? x.JsonData.Name : this.defaultName(x),
          }));
        }

        this.selectedPlaceholder = null;
        this.selectedPlaceholderChoice = null;
        this.saveDisabled = true;
      }
    },
    async selectedPlaceholderChoice(newVal) {
      if (newVal) {
        this.selectedPlaceholder = this.placeholders.find((x) => x.PlaceholderId === newVal.Id);
        this.form = JSON.parse(JSON.stringify(this.selectedPlaceholder));

        this.updateAttributeList();

        // 1
        this.form.JsonData.RuleType = this.ruleTypeItems
          .find((x) => x.Name === this.selectedPlaceholder.JsonData.RuleType);
        this.form.JsonData.ValueType = this.valueTypeItems
          .find((x) => x.Name === this.selectedPlaceholder.JsonData.ValueType);
        this.form.JsonData.ValueName = this.selectedPlaceholder.JsonData.ValueName;
        this.form.JsonData.Value = this.isUnitValue
          ? this.units.find((x) => x.Name === this.selectedPlaceholder.JsonData.Value)
          : this.selectedPlaceholder.JsonData.Value;

        this.valueTypeDisplayedItems = this.getValueTypeDisplayedItems();
        if (this.form.JsonData.ValueType.Name === 'Attribute') {
          this.attributesNames = this.form.JsonData.RuleType.Name === 'Instrument'
            ? this.instAttrNames
            : this.sensorAttrNames;
        }

        // 2
        if (this.selectedPlaceholder.JsonData.RuleType2) {
          this.form.JsonData.RuleType2 = this.ruleTypeItems2
            .find((x) => x.Name === this.selectedPlaceholder.JsonData.RuleType2);
          this.form.JsonData.ValueType2 = this.valueTypeItems
            .find((x) => x.Name === this.selectedPlaceholder.JsonData.ValueType2);
          this.form.JsonData.ValueName2 = this.selectedPlaceholder.JsonData.ValueName2;
          this.form.JsonData.Value2 = this.isUnitValue2
            ? this.units.find((x) => x.Name === this.selectedPlaceholder.JsonData.Value2)
            : this.selectedPlaceholder.JsonData.Value2;

          this.valueTypeDisplayedItems2 = this.getValueTypeDisplayedItems2();
          if (this.form.JsonData.ValueType2.Name === 'Attribute') {
            this.attributesNames2 = this.form.JsonData.RuleType2.Name === 'Instrument'
              ? this.instAttrNames
              : this.sensorAttrNames;
          }

          this.addRule();
        }

        // 3
        if (this.selectedPlaceholder.JsonData.RuleType3) {
          this.form.JsonData.RuleType3 = this.ruleTypeItems3
            .find((x) => x.Name === this.selectedPlaceholder.JsonData.RuleType3);
          this.form.JsonData.ValueType3 = this.valueTypeItems
            .find((x) => x.Name === this.selectedPlaceholder.JsonData.ValueType3);
          this.form.JsonData.ValueName3 = this.selectedPlaceholder.JsonData.ValueName3;
          this.form.JsonData.Value3 = this.isUnitValue3
            ? this.units.find((x) => x.Name === this.selectedPlaceholder.JsonData.Value3)
            : this.selectedPlaceholder.JsonData.Value3;

          this.valueTypeDisplayedItems3 = this.getValueTypeDisplayedItems3();
          if (this.form.JsonData.ValueType3.Name === 'Attribute') {
            this.attributesNames3 = this.form.JsonData.RuleType3.Name === 'Instrument'
              ? this.instAttrNames
              : this.sensorAttrNames;
          }

          this.addRule();
        }
      }
    },
  },
  async created() {
    await this.$store.dispatch('devices/instruments/units/unitList');
    this.units = this.categories;
    this.instAttrNames = await (await api.get('InstrumentAttributeNamesForSensor?sc=100&vc=100')).data;
    this.sensorAttrNames = await (await api.get('SensorAttributeNamesForSensor?sc=100')).data;

    this.ruleTypeItems = [
      { Name: 'Instrument', Text: this.$t('Dashboard.Placeholder.Instrument') },
      { Name: 'Sensor', Text: this.$t('Dashboard.Placeholder.Sensor') },
      { Name: 'Vector', Text: this.$t('Dashboard.Placeholder.Vector') },
    ];

    this.ruleTypeItems2 = [
      { Name: 'Instrument', Text: this.$t('Dashboard.Placeholder.Instrument') },
      { Name: 'Sensor', Text: this.$t('Dashboard.Placeholder.Sensor') },
      { Name: 'Vector', Text: this.$t('Dashboard.Placeholder.Vector') },
    ];

    this.ruleTypeItems3 = [
      { Name: 'Instrument', Text: this.$t('Dashboard.Placeholder.Instrument') },
      { Name: 'Sensor', Text: this.$t('Dashboard.Placeholder.Sensor') },
      { Name: 'Vector', Text: this.$t('Dashboard.Placeholder.Vector') },
    ];

    this.dropdownTypeItems = [
      { Name: 'Instrument', Text: this.$t('Dashboard.Placeholder.Instrument') },
      { Name: 'Sensor or Vector', Text: this.$t('Dashboard.Placeholder.SensorVector') },
      { Name: 'Instrument attributes', Text: this.$t('Dashboard.Placeholder.InstrumentAttributes') },
      { Name: 'Sensor attributes', Text: this.$t('Dashboard.Placeholder.SensorAttributes') },
    ];

    this.valueTypeItems = [
      { Name: 'Name', Text: this.$t('Dashboard.Placeholder.Name') },
      { Name: 'Unit', Text: this.$t('Dashboard.Placeholder.Unit') },
      { Name: 'ReadingUnit', Text: this.$t('Dashboard.Placeholder.ReadingUnit') },
      { Name: 'Attribute', Text: this.$t('Dashboard.Placeholder.Attribute') },
    ];
  },
  methods: {
    resetWidget() {
      this.show = false;
      this.saveDisabled = true;
      this.selectedPlaceholderChoice = null;
      this.placeholderChoices = [];
      this.selectedPlaceholder = null;
      this.numberOfRules = 1;
      this.form.DashboardId = null;
      this.form.PlaceholderId = null;
      this.form.Order = null;
      this.form.JsonData.Name = '';
      this.form.JsonData.Alias = '';
      this.form.JsonData.RuleType = null;
      this.form.JsonData.RuleType2 = null;
      this.form.JsonData.RuleType3 = null;
      this.form.JsonData.ValueType = null;
      this.form.JsonData.ValueType2 = null;
      this.form.JsonData.ValueType3 = null;
      this.form.JsonData.ValueName = null;
      this.form.JsonData.Value = null;
      this.form.JsonData.ValueName2 = null;
      this.form.JsonData.Value2 = null;
      this.form.JsonData.ValueName3 = null;
      this.form.JsonData.Value3 = null;
      this.form.JsonData.DropdownType = null;
      this.form.JsonData.DropdownAttributeName = null;
    },
    addRule() {
      this.numberOfRules += 1;
    },
    removeRule1() {
      // Change all the values from 2 to 1
      this.instAttrNames = this.instAttrNames2;
      this.sensorAttrNames = this.sensorAttrNames2;
      this.attributesNames = this.attributesNames2;
      this.valueTypeDisplayedItems = this.valueTypeDisplayedItems2;

      this.form.JsonData.RuleType = this.form.JsonData.RuleType2;
      this.form.JsonData.ValueType = this.form.JsonData.ValueType2;
      this.form.JsonData.ValueName = this.form.JsonData.ValueName2;
      this.form.JsonData.Value = this.form.JsonData.Value2;

      this.removeRule2();
      this.updateSaveDisabled();
    },
    removeRule2() {
      if (this.numberOfRules === 3) {
        // Change all the values from 3 to 2
        this.instAttrNames2 = this.instAttrNames3;
        this.sensorAttrNames2 = this.sensorAttrNames3;
        this.attributesNames2 = this.attributesNames3;
        this.valueTypeDisplayedItems2 = this.valueTypeDisplayedItems3;

        this.form.JsonData.RuleType2 = this.form.JsonData.RuleType3;
        this.form.JsonData.ValueType2 = this.form.JsonData.ValueType3;
        this.form.JsonData.ValueName2 = this.form.JsonData.ValueName3;
        this.form.JsonData.Value2 = this.form.JsonData.Value3;

        this.removeRule3();
      } else {
        // Remove2
        this.instAttrNames2 = [];
        this.sensorAttrNames2 = [];
        this.attributesNames2 = [];
        this.valueTypeDisplayedItems2 = [];

        this.form.JsonData.RuleType2 = null;
        this.form.JsonData.ValueType2 = null;
        this.form.JsonData.ValueName2 = null;
        this.form.JsonData.Value2 = null;

        this.numberOfRules -= 1;
      }
      this.updateSaveDisabled();
    },
    removeRule3() {
      // Remove 3
      this.instAttrNames3 = [];
      this.sensorAttrNames3 = [];
      this.attributesNames3 = [];
      this.valueTypeDisplayedItems3 = [];

      this.form.JsonData.RuleType3 = null;
      this.form.JsonData.ValueType3 = null;
      this.form.JsonData.ValueName3 = null;
      this.form.JsonData.Value3 = null;

      this.numberOfRules -= 1;
      this.updateSaveDisabled();
    },
    async onSave() {
      const placeholder = JSON.parse(JSON.stringify(this.form));
      placeholder.JsonData.RuleType = this.form.JsonData.RuleType.Name;
      placeholder.JsonData.ValueType = this.form.JsonData.ValueType.Name;
      placeholder.JsonData.ValueName = this.form.JsonData.ValueName;
      placeholder.JsonData.Value = this.isUnitValue
        ? this.form.JsonData.Value.Name
        : this.form.JsonData.Value;

      let cond2 = false;
      if (this.form.JsonData.RuleType2) {
        placeholder.JsonData.RuleType2 = this.form.JsonData.RuleType2.Name;
        placeholder.JsonData.ValueType2 = this.form.JsonData.ValueType2.Name;
        placeholder.JsonData.ValueName2 = this.form.JsonData.ValueName2;
        placeholder.JsonData.Value2 = this.isUnitValue2
          ? this.form.JsonData.Value2.Name
          : this.form.JsonData.Value2;
        cond2 = true;
      }

      let cond3 = false;
      if (this.form.JsonData.RuleType3) {
        placeholder.JsonData.RuleType3 = this.form.JsonData.RuleType3.Name;
        placeholder.JsonData.ValueType3 = this.form.JsonData.ValueType3.Name;
        placeholder.JsonData.ValueName3 = this.form.JsonData.ValueName3;
        placeholder.JsonData.Value3 = this.isUnitValue3
          ? this.form.JsonData.Value3.Name
          : this.form.JsonData.Value3;
        cond3 = true;
      }
      let isError = false;
      this.placeholders.forEach((x) => {
        // Validate for unique name and unique rule
        if (x.Order !== placeholder.Order) {
          if (x.JsonData.Name === placeholder.JsonData.Name) {
            isError = true;
          }

          if (x.JsonData.RuleType === placeholder.JsonData.RuleType
              && x.JsonData.ValueType === placeholder.JsonData.ValueType
              && x.JsonData.ValueName === placeholder.JsonData.ValueName
              && x.JsonData.Value === placeholder.JsonData.Value
              // Validate for 2
              && (cond2 && x.JsonData.RuleType2 === placeholder.JsonData.RuleType2
              && x.JsonData.ValueType2 === placeholder.JsonData.ValueType2
              && x.JsonData.ValueName2 === placeholder.JsonData.ValueName2
              && x.JsonData.Value2 === placeholder.JsonData.Value2)
              // Validate for 3
              && (cond3 && x.JsonData.RuleType3 === placeholder.JsonData.RuleType3
              && x.JsonData.ValueType3 === placeholder.JsonData.ValueType3
              && x.JsonData.ValueName3 === placeholder.JsonData.ValueName3
              && x.JsonData.Value3 === placeholder.JsonData.Value3)) {
            isError = true;
          }
        }
      });

      if (isError) {
        this.showValuesWarning(this.$t('Dashboard.Placeholder.ValueWarning'));
        return;
      }

      if (this.isCreate) {
        placeholder.PlaceholderId = crypto.randomUUID();
        placeholder.Order = this.placeholders.length;
        await this.$store.dispatch('dashboardPlaceholders/create', placeholder);
      } else {
        await this.$store.dispatch('dashboardPlaceholders/update', placeholder);
      }

      this.show = false;
    },
    showValuesWarning(message) {
      this.dialog.title = this.$t('Settings.Dialog.Warning');
      this.dialog.message = message;
      this.dialog.noCancel = true;
      this.dialog.acceptCallback = () => {};
      this.dialog.show = true;
    },
    showDeleteWarning() {
      this.dialog.title = this.$t('Settings.Dialog.Warning');
      this.dialog.message = this.$t('ConfirmDeletionMessage', {
        Action: this.$t('Delete'),
        Name: this.selectedPlaceholder.JsonData.Name,
      });
      this.dialog.noCancel = false;
      this.dialog.acceptCallback = () => this.onDelete();
      this.dialog.show = true;
    },
    async onDelete() {
      this.show = false;
      await this.$store.dispatch('dashboardPlaceholders/remove', {
        DashboardId: this.form.DashboardId,
        PlaceholderId: this.form.PlaceholderId,
      });
      this.resetWidget();
    },
    onRuleTypeChange() {
      this.form.JsonData.ValueType = null;
      this.form.JsonData.ValueName = null;
      this.form.JsonData.Value = null;
      this.saveDisabled = true;

      this.valueTypeDisplayedItems = this.getValueTypeDisplayedItems();
    },
    onRuleTypeChange2() {
      this.form.JsonData.ValueType2 = null;
      this.form.JsonData.ValueName2 = null;
      this.form.JsonData.Value2 = null;
      this.saveDisabled = true;

      this.valueTypeDisplayedItems2 = this.getValueTypeDisplayedItems2();
    },
    onRuleTypeChange3() {
      this.form.JsonData.ValueType3 = null;
      this.form.JsonData.ValueName3 = null;
      this.form.JsonData.Value3 = null;
      this.saveDisabled = true;

      this.valueTypeDisplayedItems3 = this.getValueTypeDisplayedItems3();
    },
    getValueTypeDisplayedItems() {
      if (this.form.JsonData.RuleType.Name === 'Instrument') {
        return this.valueTypeItems.filter((x) => x.Name === 'Name' || x.Name === 'Attribute');
      }

      if (this.form.JsonData.RuleType.Name === 'Sensor') {
        return this.valueTypeItems.filter((x) => x.Name === 'Name' || x.Name === 'Unit'
          || x.Name === 'Attribute');
      }

      // Else RuleType === 'Vector'
      return this.valueTypeItems.filter((x) => x.Name === 'Name' || x.Name === 'ReadingUnit');
    },
    getValueTypeDisplayedItems2() {
      if (this.form.JsonData.RuleType2.Name === 'Instrument') {
        return this.valueTypeItems.filter((x) => x.Name === 'Name' || x.Name === 'Attribute');
      }

      if (this.form.JsonData.RuleType2.Name === 'Sensor') {
        return this.valueTypeItems.filter((x) => x.Name === 'Name' || x.Name === 'Unit'
          || x.Name === 'Attribute');
      }

      // Else RuleType === 'Vector'
      return this.valueTypeItems.filter((x) => x.Name === 'Name' || x.Name === 'ReadingUnit');
    },
    getValueTypeDisplayedItems3() {
      if (this.form.JsonData.RuleType3.Name === 'Instrument') {
        return this.valueTypeItems.filter((x) => x.Name === 'Name' || x.Name === 'Attribute');
      }

      if (this.form.JsonData.RuleType3.Name === 'Sensor') {
        return this.valueTypeItems.filter((x) => x.Name === 'Name' || x.Name === 'Unit'
          || x.Name === 'Attribute');
      }

      // Else RuleType === 'Vector'
      return this.valueTypeItems.filter((x) => x.Name === 'Name' || x.Name === 'ReadingUnit');
    },
    async onValueTypeChange() {
      this.form.JsonData.Value = null;
      this.form.JsonData.ValueName = null;
      this.saveDisabled = true;

      if (this.form.JsonData.ValueType.Name === 'Attribute') {
        this.attributesNames = this.form.JsonData.RuleType.Name === 'Instrument'
          ? this.instAttrNames
          : this.sensorAttrNames;
      }
    },
    async onValueTypeChange2() {
      this.form.JsonData.Value2 = null;
      this.form.JsonData.ValueName2 = null;
      this.saveDisabled = true;

      if (this.form.JsonData.ValueType2.Name === 'Attribute') {
        this.attributesNames2 = this.form.JsonData.RuleType2.Name === 'Instrument'
          ? this.instAttrNames
          : this.sensorAttrNames;
      }
    },
    async onValueTypeChange3() {
      this.form.JsonData.Value3 = null;
      this.form.JsonData.ValueName3 = null;
      this.saveDisabled = true;

      if (this.form.JsonData.ValueType3.Name === 'Attribute') {
        this.attributesNames3 = this.form.JsonData.RuleType3.Name === 'Instrument'
          ? this.instAttrNames
          : this.sensorAttrNames;
      }
    },
    updateAttributeList() {
      if (this.form.JsonData.DropdownType === 'Instrument attributes') {
        this.dropdownAttributeItems = this.instAttrNames;
      } else if (this.form.JsonData.DropdownType === 'Sensor attributes') {
        this.dropdownAttributeItems = this.sensorAttrNames;
      }
      this.updateSaveDisabled();
    },
    updateSaveDisabled() {
      if (this.isCreate) {
        this.saveDisabled = !this.isValueFilled();
      } else if (!this.isValueFilled()) {
        this.saveDisabled = true;
      } else {
        // 1
        const ruleType = this.form.JsonData.RuleType ? this.form.JsonData.RuleType.Name : null;
        const valueType = this.form.JsonData.ValueType ? this.form.JsonData.ValueType.Name : null;
        const value = this.isUnitValue ? this.form.JsonData.Value.Name : this.form.JsonData.Value;
        // 2
        let ruleType2;
        let valueType2;
        let value2;
        if (this.form.JsonData.RuleType2) {
          ruleType2 = this.form.JsonData.RuleType2 ? this.form.JsonData.RuleType2.Name : null;
          valueType2 = this.form.JsonData.ValueType2
            ? this.form.JsonData.ValueType2.Name : null;
          value2 = this.isUnitValue2
            ? this.form.JsonData.Value2.Name : this.form.JsonData.Value2;
        }
        // 3
        let ruleType3;
        let valueType3;
        let value3;
        if (this.form.JsonData.RuleType3) {
          ruleType3 = this.form.JsonData.RuleType3 ? this.form.JsonData.RuleType3.Name : null;
          valueType3 = this.form.JsonData.ValueType3
            ? this.form.JsonData.ValueType3.Name : null;
          value3 = this.isUnitValue3
            ? this.form.JsonData.Value3.Name : this.form.JsonData.Value3;
        }

        let cond1 = true;
        if (this.form.JsonData.RuleType
          && !(ruleType === this.selectedPlaceholder.JsonData.RuleType
          && valueType === this.selectedPlaceholder.JsonData.ValueType
          && this.form.JsonData.ValueName === this.selectedPlaceholder.JsonData.ValueName
          && value === this.selectedPlaceholder.JsonData.Value)) {
          cond1 = false;
        }

        let cond2 = true;
        // If there is a rule2 or else if there WAS a rule2
        if (this.form.JsonData.RuleType2
          && !(ruleType2 === this.selectedPlaceholder.JsonData.RuleType2
          && valueType2 === this.selectedPlaceholder.JsonData.ValueType2
          && value2 === this.selectedPlaceholder.JsonData.Value2
          && this.form.JsonData.ValueName2 === this.selectedPlaceholder.JsonData.ValueName2)) {
          cond2 = false;
        } else if (this.selectedPlaceholder.JsonData.RuleType2 && !(this.form.JsonData.RuleType2)) {
          cond2 = false;
        }

        let cond3 = true;
        // If there is a rule3 or else if there WAS a rule3
        if (this.form.JsonData.RuleType3
          && !(ruleType3 === this.selectedPlaceholder.JsonData.RuleType3
          && valueType3 === this.selectedPlaceholder.JsonData.ValueType3
          && value3 === this.selectedPlaceholder.JsonData.Value3
          && this.form.JsonData.ValueName3 === this.selectedPlaceholder.JsonData.ValueName3)) {
          cond3 = false;
        } else if (this.selectedPlaceholder.JsonData.RuleType3 && !(this.form.JsonData.RuleType3)) {
          cond3 = false;
        }

        let dropcond = false;
        if ((this.form.JsonData.DropdownType === 'Instrument attributes')
          || (this.form.JsonData.DropdownType === 'Sensor attributes')) {
          dropcond = true;
          if (this.form.JsonData.DropdownAttributeName) {
            dropcond = false;
          }
        }

        this.saveDisabled = this.form.JsonData.Name === this.selectedPlaceholder.JsonData.Name
          && this.form.JsonData.DropdownType === this.selectedPlaceholder.JsonData.DropdownType
          && dropcond
          && this.form.JsonData.Alias === this.selectedPlaceholder.JsonData.Alias
          // 1
          && cond1
          // 2
          && cond2
          // 3
          && cond3;
      }
    },
    isValueFilled() {
      // 1
      const cond1 = this.isAttributeValue
        ? this.form.JsonData.ValueName && this.form.JsonData.Value
        : this.form.JsonData.Value;
      // 2
      let cond2 = true;
      if (this.form.JsonData.RuleType2
        && !(this.isAttributeValue2
          ? this.form.JsonData.ValueName2 && this.form.JsonData.Value2
          : this.form.JsonData.Value2)
      ) {
        cond2 = false;
      }
      // 3
      let cond3 = true;
      if (this.form.JsonData.RuleType3
        && !(this.isAttributeValue3
          ? this.form.JsonData.ValueName3 && this.form.JsonData.Value3
          : this.form.JsonData.Value3)
      ) {
        cond3 = false;
      }
      let cond4 = false;
      if (
        (
          this.form.JsonData.DropdownType === 'Instrument attributes'
          && this.form.JsonData.DropdownAttributeName
        )
        || (
          this.form.JsonData.DropdownType === 'Sensor attributes'
          && this.form.JsonData.DropdownAttributeName
        )
        || (this.form.JsonData.DropdownType === 'Sensor or Vector')
        || (this.form.JsonData.DropdownType === 'Instrument')
      ) {
        cond4 = true;
      } else {
        cond4 = false;
      }

      return cond1 && cond2 && cond3 && cond4;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
