<template>
  <v-dialog
    v-model="showDialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title
          v-t="'WindServer.Sites.SiteConfigurations.AdjustDateModalForm.Title'"
        />
        <v-card-subtitle>{{ currentDate }}</v-card-subtitle>
        <v-card-text>
          <v-container fluid>
            <datetime-picker
              v-model="formData.NewDate"
              :label="$t('WindServer.Sites.SiteConfigurations.AdjustDateModalForm.NewDate')"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              :has-clear-button="false"
              :rules="[v => !!v || $t('Validation.Required')]"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-t="'WindServer.Sites.SiteConfigurations.AdjustDateModalForm.Confirm'"
            text
            @click="confirm()"
          />
          <v-btn
            v-t="'WindServer.Sites.SiteConfigurations.AdjustDateModalForm.Close'"
            text
            @click="showDialog = false"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import { format } from 'date-fns';
import DatetimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    configDate: {
      type: Date,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        NewDate: new Date(),
      },
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
      showDialog: this.show,
    };
  },
  computed: {
    ...mapState('site/sites', ['site']),
    currentDate() {
      return format(this.configDate, 'yyyy-MM-dd HH:mm:ss');
    },
  },
  watch: {
    show(newValue) {
      this.showDialog = newValue;
      if (newValue) {
        this.formData.NewDate = this.configDate;
      }
    },
    showDialog(newValue) {
      this.$emit('update:show', newValue);
    },
  },
  methods: {
    async confirm() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('site/siteConfigurations/updateConfigurationDate', {
          ...this.formData,
          CurrentDate: this.configDate,
          SiteId: this.site.SiteId,
        });
        this.showDialog = false;
      }
    },
  },
};
</script>
