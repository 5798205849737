<template>
  <v-virtual-scroll
    :items="form.selectedSensors"
    :item-height="28"
    style="max-height: 260px; overflow-y: auto; overflow-x: hidden;"
    class="v-virtual-scroll--with-height"
  >
    <template #default="{ item }">
      <v-list-item
        :key="item.Id"
        dense
        class="pa-0"
        style="min-height: 24px;"
      >
        <v-list-item-icon
          class="ml-0 mr-1"
          style="cursor:pointer;"
          @click="changeYAxis(item)"
        >
          <v-icon
            v-if="item.T == 'S'"
            :color="item.Y2Axis ? 'cyan' : 'white'"
          >
            mdi-alpha-s-box-outline
          </v-icon>
          <v-icon
            v-if="item.T == 'V'"
            :color="item.Y2Axis ? 'cyan' : 'white'"
          >
            mdi-alpha-v-box-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content
          class="pa-0"
          style="max-width: fit-content;"
        >
          <v-select
            :value="(item.Aggregation || 'AVG')"
            :items="AggregateBy2Query"
            dense
            class="mr-3"
            style="max-width: 70px; font-size: 80%;"
            @input="item.Aggregation = $event"
          />
        </v-list-item-content>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-list-item-content
              class="ellipsis"
              style="text-align: left;"
              v-on="on"
              @mouseover="setSensorTooltip(item)"
            >
              {{ getName(item) }}
            </v-list-item-content>
          </template>
          <div style="white-space: pre;">
            {{ sensorTooltip }}
          </div>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-list-item-content
              class="pt-0 pb-0"
              style="max-width: 100px;"
              v-on="on"
              @mouseover="setSensorTooltip(item)"
            >
              <v-badge
                :content="item.InstrumentName"
                inline
              />
            </v-list-item-content>
          </template>
          <div style="white-space: pre;">
            {{ sensorTooltip }}
          </div>
        </v-tooltip>
        <v-list-item-action
          v-if="form.selectedSensors.length > 1"
          class="ml-0"
        >
          <v-btn
            icon
            small
            @click="sendUp(item)"
          >
            <v-icon>
              mdi-arrow-up-drop-circle
            </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action
          v-if="form.selectedSensors.length > 1"
          class="ml-0"
        >
          <v-btn
            icon
            small
            @click="sendDown(item)"
          >
            <v-icon>
              mdi-arrow-down-drop-circle
            </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action class="ml-0">
          <v-btn
            icon
            small
            @click="$emit('edit', item)"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action class="ml-0">
          <v-btn
            icon
            small
            @click="removeSelectedSensor(item)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-virtual-scroll>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
  models: [
    { data: 'form.selectedSensors', event: 'models:selectedSensors' },
  ],
  props: {
    show: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
    hideY2: {
      type: Boolean,
      default: () => (false),
      required: false,
    },
  },
  data: () => ({
    AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX', 'SUM'],
    sensorTooltip: '',

    form: {
      selectedSensors: [],
      aggregateForEvery: 'Hours',
    },
  }),
  computed: {
    ...mapGetters('app', ['pleaseWait', 'timezone']),
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    'form.selectedSensors': function w(newVal) { this.$emit('models:selectedSensors', newVal); },
  },
  methods: {
    removeSelectedSensor(item) {
      this.form.selectedSensors.splice(this.form.selectedSensors.indexOf(item), 1);
    },
    changeYAxis(item) {
      if (!this.hideY2) {
        this.$set(item, 'Y2Axis', !item.Y2Axis);
      }
    },
    setSensorTooltip(item) {
      this.sensorTooltip = `${item.T === 'S' ? this.$t('Dashboard.Sensor') : this.$t('Dashboard.Vector')}: ${item.DisplayName || item.Name}\n`
        + `Instrument: ${item.InstrumentName}\n`
        + `Alias: ${item.Alias || ''}`;

      if (item.T === 'S') {
        this.sensorTooltip += `\nUnit: ${item.Unit ? item.Unit : ''}`;
      } else {
        this.sensorTooltip += `\nReadingUnit: ${item.ReadingUnit ? item.ReadingUnit : ''}`;
        this.sensorTooltip += `\nSamplingUnit: ${item.SamplingUnit ? item.SamplingUnit : ''}`;
      }
    },
    getName(item) {
      let name = item.DisplayName || item.Name;

      if (item.T === 'S' && item.Unit) {
        name += ` (${item.Unit})`;
      } else if (item.T === 'V' && item.ReadingUnit) {
        name += ` (${item.ReadingUnit})`;
      }

      return name;
    },
    sendUp(item) {
      const index = this.form.selectedSensors.indexOf(item);
      if (index !== 0) {
        const temp = this.form.selectedSensors[index];
        this.form.selectedSensors[index] = this.form.selectedSensors[index - 1];
        this.form.selectedSensors[index - 1] = temp;
      }
    },
    sendDown(item) {
      const index = this.form.selectedSensors.indexOf(item);
      if (index < this.form.selectedSensors.length - 1) {
        const temp = this.form.selectedSensors[index];
        this.form.selectedSensors[index] = this.form.selectedSensors[index + 1];
        this.form.selectedSensors[index + 1] = temp;
      }
    },
  },
};
</script>

<style scoped>
.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-virtual-scroll--with-height {
  height: 260px;
}
</style>
