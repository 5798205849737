import AnchorTypesList from './components/AnchorTypesList.vue';
import AnchorTypesFormModal from './components/AnchorTypesFormModal.vue';

export default [
  {
    path: '/Equipment/AnchorTypes',
    name: 'Equipment.AnchorTypes.List',
    component: AnchorTypesList,
    children: [
      {
        path: '/Equipment/AnchorTypes/Create',
        name: 'Equipment.AnchorTypes.Create',
        component: AnchorTypesFormModal,
      },
      {
        path: '/Equipment/AnchorTypes/:id',
        name: 'Equipment.AnchorTypes.Update',
        component: AnchorTypesFormModal,
        props: true,
      },
    ],
  },
];
