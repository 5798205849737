import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsEmailImportRules extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$t('Edit'),
          width: 80,
          resizable: true,
          hide: !this.vue.isEditable,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.editEmailImportRule(event.data);
            },
          },
        },
        {
          field: 'StartDate',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.StartDate'),
          editable: false,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          field: 'EndDate',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.EndDate'),
          editable: false,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          field: 'SubjectLinePattern',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.SubjectLinePattern'),
          editable: false,
          filter: true,
          sortable: true,
          width: 200,
        },
        {
          field: 'AttachmentFileNamePattern',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.AttachmentFilenamePattern'),
          editable: false,
          filter: true,
          sortable: true,
          width: 200,
        },
        {
          field: 'SiteNumberPattern',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.SiteNumber'),
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'SerialNumberPattern',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.SerialNumber'),
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'RecordDatePattern',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.EmailImportRules.RecordDate'),
          editable: false,
          filter: true,
          sortable: true,
        },
      ]);
    });
  }
}
