<template>
  <div class="mt-7">
    <h1>Tower Shadow</h1>
    <p>Inspection Id : {{ id }}</p>
  </div>
</template>

<script>

export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>
