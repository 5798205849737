<template>
  <v-container
    fluid
  >
    <div
      v-if="$auth.roles.includes('Web.Sensor.Override')"
    >
      <v-toolbar
        dense
      >
        <v-toolbar-items
          v-for="(item, index) in toolbarContent"
          :key="index"
        >
          <toolbar-button-with-tooltip
            :tooltip-text="item.tooltip"
            :icon-name="item.icon"
            :disabled="!item.enabled"
            @click="item.click"
          />
        </v-toolbar-items>
      </v-toolbar>
      <v-row class="toolbar-present">
        <v-col
          lg="4"
          class="toolbar-present"
        >
          <sensor-selection
            v-models:selectedSensors="form.selectedSensors"
            :show-sensors="(true)"
            :show-vectors="(false)"
            :show-virtual="!form.hideVirtualInstruments"
            :instrument-ids="(instruments)"
            :site="(site)"
          />
          <time-range-card
            v-models:periodicity="form.periodicity"
            v-models:dateRangeFrom="form.dateRangeFrom"
            v-models:dateRangeTo="form.dateRangeTo"
            v-models:lastnScale="form.lastnScale"
            v-models:lastnPeriod="form.lastnPeriod"
            v-models:dateList="form.dateList"
            :show="(true)"
            :sensor-id-list="sensorIdList"
            :vector-id-list="vectorIdList"
            :selected-sensors="form.selectedSensors"
          />
          <v-checkbox
            v-model="form.hideVirtualInstruments"
            :label="$t('Analytics.HideVI')"
          />
        </v-col>
        <v-col
          lg="8"
          class="toolbar-present"
        >
          <dashboard-stack-single-widget-host
            :settings="settings"
          />
        </v-col>
      </v-row>
    </div>
    <div
      v-else
    >
      <v-row class="no-toolbar">
        <v-col
          lg="4"
          class="no-toolbar"
        >
          <sensor-selection
            v-models:selectedSensors="form.selectedSensors"
            :show-sensors="(true)"
            :show-vectors="(false)"
            :instrument-ids="(instruments)"
            :site="(site)"
          />
          <time-range-card
            v-models:periodicity="form.periodicity"
            v-models:dateRangeFrom="form.dateRangeFrom"
            v-models:dateRangeTo="form.dateRangeTo"
            v-models:lastnScale="form.lastnScale"
            v-models:lastnPeriod="form.lastnPeriod"
            v-models:dateList="form.dateList"
            :show="(true)"
            :sensor-id-list="sensorIdList"
            :vector-id-list="vectorIdList"
            :selected-sensors="form.selectedSensors"
          />
          <v-checkbox
            v-model="form.hideVirtualInstruments"
            :label="$t('Analytics.HideVI')"
          />
        </v-col>
        <v-col
          lg="8"
          class="no-toolbar"
        >
          <dashboard-stack-single-widget-host
            :settings="settings"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import datehandling from '@/components/datehandling';
import i18n from '@/plugins/i18n';
import TimeRangeCard from '@/dashboard/components/graphform/TimeRangeCard.vue';
import DashboardStackSingleWidgetHost from '@/dashboard/components/DashboardStackSingleWidgetHost.vue';
import SensorSelection from '@/components/sensor/SensorSelection.vue';
import ToolbarButtonWithTooltip from '../components/ToolbarButtonWithTooltip.vue';

export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'form.selectedSensors', event: 'models:selectedSensors' },
  ],
  components: {
    TimeRangeCard,
    DashboardStackSingleWidgetHost,
    SensorSelection,
    ToolbarButtonWithTooltip,
  },
  props: {
    site: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    instruments: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data: () => ({
    LastPicker: ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],
    LastPicker2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE'],
    AggregateByItems: ['Any', 'Average', 'Min', 'Max', 'Sum'],
    AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX', 'SUM'],
    AggregateForEveryItems: ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],
    AggregateForEvery2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'],
    toolbarContent: [],
    showGrid: false,
    toolbarButtons: [],
    rowdata: [],
    refresh: 0,

    form: {
      periodicity: 'last_n',
      dateRangeFrom: null,
      dateRangeTo: null,
      lastnScale: 'Days',
      lastnPeriod: 15,
      dateList: [],
      InputAggregation: 'ANY',
      selectedSensors: [],
      hideVirtualInstruments: false,
    },
    validateButtonEnabled: false,
  }),
  computed: {
    ...mapGetters('app', ['pleaseWait', 'timezone']),

    settings() {
      if (this.form.selectedSensors.length > 0) { return this.buildSettings(); }
      return null;
    },
    sensorIdList() {
      return this.form.selectedSensors
        .filter((x) => x.T === 'S')
        .map((x) => x.Id);
    },
    vectorIdList() {
      return this.form.selectedSensors
        .filter((x) => x.T === 'V')
        .map((x) => ([x.Id, x.Name]));
    },
    stringDateRange() {
      return [this.form.dateRangeFrom, this.form.dateRangeTo];
    },
    dateRangeStart() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[0];
    },
    dateRangeEnd() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[dateRange.length - 1];
    },
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    'form.selectedSensors': function w(newVal) {
      this.$emit('models:selectedSensors', newVal);
      [0].forEach((x) => {
        if (this.form.selectedSensors.length > 0) {
          if (this.form.selectedSensors.find((sensor) => sensor.IsVirtual)) {
            this.toolbarContent[x].enabled = false;
            this.$store.dispatch('analytics/change', []);
            this.$store.dispatch('analytics/updateQuery', null);
          } else {
            this.toolbarContent[x].enabled = true;
            this.$store.dispatch('analytics/change', this.form.selectedSensors);
          }
        } else {
          this.toolbarContent[x].enabled = false;
          this.$store.dispatch('analytics/change', []);
          this.$store.dispatch('analytics/updateQuery', null);
        }
      });
    },
    settings() {
      this.$store.dispatch('analytics/updateQuery', this.settings);
    },
    'form.periodicity': function () {
      this.$store.dispatch('analytics/updateQuery', this.buildSettings());
    },
    'form.dateRangeFrom': function () {
      this.$store.dispatch('analytics/updateQuery', this.buildSettings());
    },
    'form.dateRangeTo': function () {
      this.$store.dispatch('analytics/updateQuery', this.buildSettings());
    },
    'form.lastnScale': function () {
      this.$store.dispatch('analytics/updateQuery', this.buildSettings());
    },
    'form.lastnPeriod': function () {
      this.$store.dispatch('analytics/updateQuery', this.buildSettings());
    },
    'form.dateList': function () {
      this.$store.dispatch('analytics/updateQuery', this.buildSettings());
    },
    'form.hideVirtualInstruments': function () {
      this.$store.dispatch('analytics/updateQuery', this.buildSettings());
    },
  },
  created() {
  },
  mounted() {
    this.toolbarContent = [
      {
        tooltip: i18n.t('Analytics.Icons.Validate'),
        icon: 'mdi-check-circle-outline',
        enabled: this.validateButtonEnabled,
        click: () => { this.onSetValidateClick(); },
      },
    ];
  },
  methods: {
    onSetValidateClick() {
      this.$router.push('/analytics/validation');
    },

    buildSettings() {
      const query = {
        user: {
          timezone: this.timezone,
          visualisationSubType: 'sensor+series+line',
          visualisationType: 'plotly',
        },
        data: {
          formulas: [],
          input_filter: [],
          input_time_axis: {
            round_to: {
              scale: 'NONE',
              multiplier: 1,
              divisor: 1,
            },
          },
          output_time_axis: {
            round_to: {
              scale: 'NONE',
              multiplier: 1,
              divisor: 1,
            },
          },
          sensor_selection: [],
          vector_selection: [],
          timezone: this.timezone,
          output_in_local: false,
        },
        visualization: {
          alias: [],
          graphType: 'scatter',
          graphStyle: 'lines',
          graphOptions: {
            x_axis: [],
            y_axis: [],
            groupby: [],
          },
        },
      };

      query.visualization.graphOptions.x_axis.push({
        ts: 'value',
      });
      query.user.selectedSensors = this.form.selectedSensors;
      query.data.formulas = query.data.formulas.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'S')
          .map((x) => ({
            symbol: `s${x.Id}`,
            formula_text: `=s${x.Id};`,
            output_aggregation: x.Aggregation || 'ANY',
          })),
      );
      query.data.sensor_selection = (this.form.selectedSensors
        .filter((x) => x.T === 'S')
        .map((x) => ({
          symbol: `s${x.Id}`,
          sensor_id: x.Id,
          input_aggregation: x.Aggregation || 'ANY',
          unit: x.Unit,
        }))
      );
      query.visualization.alias = query.visualization.alias.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'S')
          .map((x) => (JSON.parse(`{"s${x.Id}": "${x.InstrumentName}.${x.DisplayName || x.Name}"}`))),
      );

      query.visualization.graphOptions.y_axis = query.visualization.graphOptions.y_axis
        .concat(
          this.form.selectedSensors
            .filter((x) => !x.Y2Axis)
            .filter((x) => x.T === 'S')
            .map((x) => (JSON.parse(`{"s${x.Id}": "value"}`))),
        );

      query.visualization.graphOptions.y_axis = query.visualization.graphOptions.y_axis
        .concat(
          this.form.selectedSensors
            .filter((x) => !x.Y2Axis)
            .filter((x) => x.T === 'V')
            .map((x, i) => (JSON.parse(`{"v${x.Id}${i}": "value"}`))),
        );

      query.visualization.graphOptions.y2_axis = (query.visualization.graphOptions.y2_axis || [])
        .concat(
          this.form.selectedSensors
            .filter((x) => x.Y2Axis)
            .filter((x) => x.T === 'S')
            .map((x) => (JSON.parse(`{"s${x.Id}": "value"}`))),
        );

      query.visualization.graphOptions.y2_axis = (query.visualization.graphOptions.y2_axis || [])
        .concat(
          this.form.selectedSensors
            .filter((x) => x.Y2Axis)
            .filter((x) => x.T === 'V')
            .map((x, i) => (JSON.parse(`{"v${x.Id}${i}": "value"}`))),
        );

      if (query.visualization.graphOptions.y2_axis.length === 0) {
        query.visualization.graphOptions.y2_axis = undefined;
      }

      query.data.input_time_axis.round_to.scale = 'NONE';
      query.data.input_time_axis.round_to.multiplier = 1;
      query.data.input_time_axis.round_to.divisor = 1;

      query.data.output_time_axis.round_to.scale = 'NONE';
      query.data.output_time_axis.round_to.multiplier = 1;
      query.data.output_time_axis.round_to.divisor = 1;

      if (this.form.periodicity === 'last_n') {
        query.data.input_filter.push({
          last_n: {
            scale: this.LastPicker2Query[this.LastPicker.indexOf(this.form.lastnScale)],
            period: Number(this.form.lastnPeriod),
          },
        });
      } else if (this.form.periodicity === 'time_range') {
        query.data.input_filter.push({
          time_range: {
            start: datehandling.formatForApi(this.dateRangeStart),
            end: datehandling.formatForApi(this.dateRangeEnd),
          },
        });
      } else if (this.form.periodicity === 'time_list') {
        query.data.input_filter.push({
          time_list: {
            dates: this.form.dateList.map((x) => datehandling.formatForApi(x)),
          },
        });
      }

      query.data.output_in_local = true;

      return query;
    },
  },
};
</script>

<style lang="scss">
.toolbar-present {
  height: calc(96vh - 48px);
}
.no-toolbar{
  height: calc(100vh - 48px);
}
</style>
