<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-form
          ref="form"
          lazy-validation
          :readonly="readMode"
        >
          <v-card-text>
            <v-container fluid>
              <v-select
                v-model="formData.DataGatewayId"
                :label="$t('Worker.Workers.Edit.Gateway')"
                :items="datagateways"
                :autofocus="isEdit"
                :rules="[v => !!v || $t('Validation.Required')]"
                item-text="Name"
                item-value="DataGatewayId"
              />
              <v-text-field
                v-model="formData.Name"
                :label="$t('Worker.Workers.Edit.Name')"
                :autofocus="isEdit"
                :rules="[v => !!v || $t('Validation.Required')]"
              />
              <v-textarea
                v-if="id!==null"
                v-model="formData.JsonData"
                :readonly="!isEdit"
                :rules="[ValidateJson]"
              />
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              v-if="!readMode"
              text
              @click="submit"
            >
              {{ $t('Accept') }}
            </v-btn>
            <v-btn
              text
              @click.stop="show=false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    workerid: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      showTowerModel: false,
      showConfirm: false,
      errorMessage: '',
      readMode: !this.$auth.roles.includes('Web.WorkerConfiguration.Manage'),
    };
  },
  computed: {
    ...mapState('worker/workerconfigs', ['config', 'datagateways']),
    isEdit() {
      return this.formData.WorkerId != null;
    },
  },
  watch: {
    id() {
      if (!this.readMode) {
        this.showConfirm = true;
      }
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.id && this.id != null) { // Update mode
      this.getModel();
    } else { // Create mode
      await this.$store.dispatch('worker/workerconfigs/listgateways');
      this.show = true;
    }
  },
  methods: {
    async getModel() {
      await this.$store.dispatch('worker/workerconfigs/get', { workerid: this.workerid, id: this.id });
      this.formData = JSON.parse(JSON.stringify(this.config));
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'Worker.WorkerConfigs.List', params: { workerid: this.workerid } });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.id === null) {
          await this.$store.dispatch('worker/workerconfigs/create', { workerid: this.workerid, payload: this.formData });
        } else {
          await this.$store.dispatch('worker/workerconfigs/update', { id: this.id, payload: this.formData });
        }
        this.navigateToList();
      }
    },
    ValidateJson(txt) {
      try {
        JSON.parse(txt);
        return true;
      } catch {
        return false;
      }
    },
    cleanError() {
      this.errorMessage = '';
    },
  },
};
</script>
