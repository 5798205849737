<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              ref="height"
              v-model="formData.Height"
              :label="$t('WindServer.Sites.LoggerConfiguration.Heights.Height')"
              :rules="numberRule"
              type="number"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      numberRule: [(v) => (!Number.isNaN(parseFloat(v))) || this.$t('Validation.Required')],
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['height']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$store.dispatch('site/loggerConfigurations/height', null);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async setFormData() {
      if (this.height) {
        this.formData = JSON.parse(JSON.stringify(this.height));
      } else { // Create mode
        this.formData = {
          DataFileImportSettingId: this.id,
        };
      }
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          this.formData.Height = Number(this.formData.Height);
          if (this.height) {
            await this.$store.dispatch('site/loggerConfigurations/updateHeight', this.formData);
            this.close();
          } else {
            await this.$store.dispatch('site/loggerConfigurations/createHeight', this.formData);
            this.formData = {};
            this.$store.dispatch('site/loggerConfigurations/height', null);
            this.$refs.form.reset();
            this.$refs.height.focus();
          }
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/loggerConfigurations/height', null);
      this.$refs.form.reset();
    },
  },
};
</script>
