<template>
  <v-tooltip
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        :disabled="disabled"
        v-on="on"
        @click="onClick"
      >
        <v-icon :color="color">
          {{ iconName }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltipText: {
      type: String,
      default: () => '',
    },
    iconName: {
      type: String,
      default: () => 'mdi-plus',
    },
    color: {
      type: String,
      default: () => undefined,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style>

</style>
