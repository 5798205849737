const state = {
  selectedInstruments: [],
};

const actions = {
};

const mutations = {
  select(state, payload) {
    state.selectedInstruments = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
