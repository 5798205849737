<template>
  <v-dialog
    v-model="display"
    :width="dialogWidth"
  >
    <template #activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="formattedDatetime"
        :rules="rules"
        readonly
        v-on="on"
        @click="emitClick"
      >
        <template #progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            />
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-text-field
          v-model="textInputDateTime"
          :color="textInputDateTimeColor"
        />
      </v-card-text>
      <v-card-text class="px-0 py-0">
        <v-tabs
          v-model="activeTab"
          fixed-tabs
        >
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </slot>
          </v-tab>
          <v-tab
            key="timer"
            :disabled="dateSelected"
          >
            <slot name="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              full-width
              @input="showTimePicker"
            />
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              v-model="time"
              class="v-time-picker-custom"
              v-bind="timePickerProps"
              full-width
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <slot
          name="actions"
          :parent="this"
        >
          <v-btn
            v-if="hasClearButton"
            color="grey lighten-1"
            text
            @click.native="clearHandler"
          >
            {{ clearText }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="okHandler"
          >
            {{ okText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parse } from 'date-fns';

const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00:00';
const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = 'CLEAR';
const DEFAULT_OK_TEXT = 'OK';

export default {
  model: {
    prop: 'datetime',
    event: 'input',
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: [Array, Object],
      default: () => {},
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: DEFAULT_TIME_FORMAT,
    },
    hasClearButton: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT,
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT,
    },
    textFieldProps: {
      type: Object,
      default: () => {},
    },
    datePickerProps: {
      type: Object,
      default: () => {},
    },
    timePickerProps: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
      textInputDateTime: null,
      textInputDateTimeColor: 'inherit',
    };
  },
  computed: {
    dateTimeFormat() {
      return `${this.dateFormat} ${this.timeFormat}`;
    },
    defaultDateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    formattedDatetime() {
      return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : '';
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = `${this.date} ${this.time}`;
        if (this.time.length === 5) {
          datetimeString += ':00';
        }
        return parse(datetimeString, this.defaultDateTimeFormat, new Date());
      }
      return null;
    },
    dateSelected() {
      return !this.date;
    },
  },
  watch: {
    datetime() {
      this.init();
    },
    textInputDateTime(t) {
      let datetimeString = t;
      if (datetimeString.length === 4) {
        datetimeString += '-01-01 00:00:00';
        this.showDatePicker();
      } else if (datetimeString.length === 7) {
        datetimeString += '-01 00:00:00';
        this.showDatePicker();
      } else if (datetimeString.length === 10) {
        datetimeString += ' 00:00:00';
        this.showDatePicker();
      } else if (datetimeString.length === 13) {
        datetimeString += ':00:00';
        this.showTimePicker();
      } else if (datetimeString.length === 16) {
        datetimeString += ':00';
        this.showTimePicker();
      }
      const parsedDt = parse(datetimeString, this.defaultDateTimeFormat, new Date());
      // eslint-disable-next-line no-self-compare
      if (parsedDt.getTime() === parsedDt.getTime()) {
        this.date = format(parsedDt, DEFAULT_DATE_FORMAT);
        this.time = format(parsedDt, DEFAULT_TIME_FORMAT);
        this.textInputDateTimeColor = 'inherit';
      } else {
        this.textInputDateTimeColor = 'red';
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.datetime) {
        return;
      }

      let initDateTime;
      if (this.datetime instanceof Date) {
        initDateTime = this.datetime;
      } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
        // see https://stackoverflow.com/a/9436948
        initDateTime = parse(this.datetime, this.dateTimeFormat, new Date());
      }

      this.date = format(initDateTime, DEFAULT_DATE_FORMAT);
      this.time = format(initDateTime, DEFAULT_TIME_FORMAT);

      this.textInputDateTime = this.formattedDatetime;
    },
    okHandler() {
      this.resetPicker();
      this.$emit('input', this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit('input', null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
    showDatePicker() {
      this.activeTab = 0;
    },
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>
