<template>
  <v-app>
    <v-overlay
      :value="pleaseWait"
      z-index="9999"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-overlay
      :value="!!fullScreenImage"
      z-index="9999"
    >
      <v-img
        :src="fullScreenImage"
        @click="closeFullScreenImage"
      />
    </v-overlay>
    <router-view />
    <confirm ref="confirm" />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import confirm from '@/components/ConfirmErrorDialog.vue';

export default {
  name: 'App',
  components: {
    confirm,
  },
  computed: {
    ...mapGetters('app', ['pageTitle', 'pleaseWait', 'fullScreenImage']),
  },
  created() {
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
    window.$confirm = this.$refs.confirm.open;
  },
  methods: {
    closeFullScreenImage() {
      this.$store.dispatch('app/closeFullScreenImage');
    },
  },
};
</script>

<style lang="scss">
 @import "~ag-grid-community/dist/styles/ag-grid.css";
 @import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>
