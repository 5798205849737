<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    max-width="900px"
    @click:outside="init"
    @show="init"
  >
    <v-card>
      <SensorSelection
        v-models:selectedSensors="selectedSensors"
        :hideY2="this.hideY2"
        :show-sensors="showSensors"
        :show-vectors="showVectors"
        :item-increment="itemIncrement"
      />
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="addSensor"
        >
          {{ $t('Add') }}
        </v-btn>
        <v-btn
          text
          @click.stop="show=false"
          @click="init"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SensorSelection from '@/components/sensor/SensorSelection.vue';

export default {
  name: 'SensorSelector',
  components: {
    SensorSelection,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    showSensors: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    showVectors: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    itemIncrement: {
      type: Number,
      default: () => 20,
      required: false,
    },
    hideY2: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data: () => ({
    selectedSensors: [],
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async init() {
      this.selectedSensors = [];
    },
    addSensor() {
      this.$emit('sensors-added', this.selectedSensors);
      this.init();
      this.show = false;
    },
  },
  i18n: {
    messages: {
      en: {
        Add: 'Add',
        Close: 'Close',
      },
      fr: {
        Add: 'Ajouter',
        Close: 'Fermer',
      },
    },
  },
};
</script>
