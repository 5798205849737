<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <v-toolbar dense>
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Back')"
        :icon-name="'mdi-arrow-left'"
        @click="doBack"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Refresh')"
        :icon-name="'mdi-refresh'"
        @click="doRefresh"
      />
    </v-toolbar>
    <v-tabs centered>
      <v-tab>
        {{ $t('Instruments.Attribute') }}
      </v-tab>
    </v-tabs>
    <sensor-attribute-list
      style="height: calc(100vh - 120px)"
      :refresh="refresh"
    />
  </div>
</template>

<script>
import importal from '@/api/importal';
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import SensorAttributeList from './tables/SensorAttributeList.vue';

export default {
  components: {
    SensorAttributeList,
    ToolbarButtonWithTooltip,
  },
  data: () => ({
    refresh: 0,
    instrument: null,
  }),
  computed: {
  },
  created() {
    this.getSensorInfo();
  },
  methods: {
    getSensorInfo() {
      importal.get(`DevSensorList?SensorId=${this.$route.params.id}`)
        .then((result) => result.data[0])
        .then((data) => {
          this.$store.commit('app/pageTitle', { key: 'Sensor {Name}', payload: data });
          this.instrument = { Id: data.InstrumentId, Name: data.InstrumentName };
        });
    },
    async doBack() {
      await this.$router.push({ name: 'InstrumentList', params: { id: this.instrument.Id } });
      this.$store.commit('app/pageTitle', { key: 'Instrument {Name}', payload: this.instrument });
      this.$emit('back');
    },
    doRefresh() {
      this.refresh += 1;
    },
  },
};
</script>

<style lang="scss">
</style>
