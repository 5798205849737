import i18n, { selectedLocale } from '@/plugins/i18n';
import DashboardMenu from '@/dashboard/dashboard.menu';
import AnalyticsMenu from '@/analytics/analytics.menu';
import DeviceMenu from '@/devices/devices.menu';
import AlertMenu from '@/alert/version3/alert.menu';
import virtualInstrumentMenu from '@/virtualinstrument/virtualinstrument.menu';
import settingsMenu from '@/settings/settings.menu';
import equipmentMenu from '@/equipment/equipment.menu';
import qualityCheckMenu from '@/qualityCheck/qualityCheck.menu';
import customersMenu from '@/customers/customers.menu';
import workerMenu from '@/worker/worker.menu';

const state = {
  locale: selectedLocale,
  timezone: null,
  tenantId: localStorage.getItem('tenantId') || null, // Load tenantId from local storage
  accessGroupIds: JSON.parse(localStorage.getItem('accessGroupIds')) || [], // Load accessGroupIds from local storage
  activeLink: null,
  pageTitle: {
    key: null,
    payload: null,
  },
  pleaseWait: 0,
  fullScreenImage: null,
  menu: [
    ...DashboardMenu,
    ...AnalyticsMenu,
    ...customersMenu,
    ...DeviceMenu,
    ...AlertMenu,
    ...virtualInstrumentMenu,
    ...settingsMenu,
    ...workerMenu,
    {
      isWindServer: true,
      label: 'WindServer.Title',
      icon: 'mdi-wind-turbine',
      to: { name: 'WindServer' },
      title: 'WindServer.PageTitle',
      items: [],
    },
    ...equipmentMenu,
    ...qualityCheckMenu,
  ],
  globalDateRange: {
    activated: false,
    dateRange: {
      periodicity: 'last_n',
      lastN: {
        period: 2,
        scale: 'Hours',
      },
      range: {
        start: null,
        end: null,
      },
    },
    aggregateBy: {
      multiplier: 1,
      scale: 'Hours',
    },
    aggregateUseTimeZone: true,
  },
};

const findLinkByName = (m) => m.to.name === state.activeLink;
const searchChildMenu = (m) => {
  if (m.items) {
    return m.items.find(findLinkByName);
  }
  return findLinkByName(m);
};

const getters = {
  pageTitle: (state) => {
    const m = state.menu.find(searchChildMenu);
    if (!m) {
      return state.pageTitle;
    }
    if (m.items) {
      const title = m.items.find(findLinkByName)?.title;
      if (title) {
        return {
          key: title,
          payload: null,
        };
      }
      return state.pageTitle;
    }
    const rc = m?.title;
    if (rc) {
      return {
        key: rc,
        payload: null,
      };
    }
    return state.pageTitle;
  },
  pleaseWait: (state) => state.pleaseWait > 0,
  timezone: (state) => state.timezone,
  tenantId: (state) => state.tenantId,
  isTenantNetworkOperator: (state) => state.tenantId === '88811297-79af-4304-aad6-4e8632d8da0f',
  accessGroupIds: (state) => state.accessGroupIds,
  fullScreenImage: (state) => state.fullScreenImage,
  globalDateRange: (state) => {
    const item = localStorage.getItem('IMPortal_Global_Data_Range');
    if (item) {
      state.globalDateRange = JSON.parse(item);
    }
    return state.globalDateRange;
  },
};

const mutations = {
  timezone(state, newTz) {
    state.timezone = newTz;
  },
  locale(state, newLocale) {
    i18n.locale = newLocale;
    state.locale = newLocale;
    window.localStorage.locale = newLocale;
  },
  tenantId(state, newTenantId) {
    state.tenantId = newTenantId;
    localStorage.setItem('tenantId', newTenantId); // Store tenantId in local storage
  },
  accessGroupIds(state, newAccessGroupIds) {
    state.accessGroupIds = newAccessGroupIds;
    localStorage.setItem('accessGroupIds', JSON.stringify(newAccessGroupIds)); // Store accessGroupIds in local storage
  },
  pageTitle(state, payload) {
    state.pageTitle = payload;
    state.activeLink = null;
  },
  activeLink(state, payload) {
    state.activeLink = payload;
    const m = state.menu.find(searchChildMenu);
    if (m) {
      m.active = true;
    }
  },
  pleaseWait(state, payload) {
    if (payload) {
      state.pleaseWait += 1;
    } else {
      state.pleaseWait -= 1;
    }
  },
  fullScreenImage(state, payload) {
    state.fullScreenImage = payload;
  },
  globalDateRange(state, payload) {
    state.globalDateRange = payload;
    localStorage.removeItem('IMPortal_Global_Data_Range');
    localStorage.setItem('IMPortal_Global_Data_Range', JSON.stringify(state.globalDateRange));
  },
};

const actions = {
  changeLocale({ commit }, newLocale) {
    i18n.locale = 'fr';
    commit('locale', newLocale);
    window.location.reload(); // some components cannot change locale (ex: ag-grid), we need to reload the document.
  },
  changeTenant({ commit }, newTenantId) {
    commit('tenantId', newTenantId);
  },
  changeAccessGroupIds({ commit }, newAccessGroupIds) {
    commit('accessGroupIds', newAccessGroupIds);
  },
  openFullScreenImage({ commit }, payload) {
    commit('fullScreenImage', payload);
  },
  closeFullScreenImage({ commit }) {
    commit('fullScreenImage', null);
  },
  changePageTitle({ commit }, payload) {
    commit('pageTitle', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
