import i18n from '@/plugins/i18n';
import api from '@/api/importal';

const state = {
  qcTask: null,
  qcTasks: [],
  qcTaskTypes: [],
  monitoringSites: [],
  users: [],
  clients: [],
  filters: {},
  qcTaskRecurrences: [],
  qcInspectionTemplates: [],
  AssignableUser: { RoleName: 'Web.QCTasks.User' },
};

const actions = {

  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCTasksCreate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('filterList');
  },

  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCTasksGet?id=${payload.id}`);
    const selectedTask = response.data;
    if (typeof selectedTask.Details === 'object') {
      selectedTask.Details = '';
    }
    commit('qcTask', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit, dispatch }) {
    commit('app/pleaseWait', true, { root: true });
    let response = await api.get('QCTaskTypesList');
    commit('qcTaskTypes', response.data);
    response = await api.post('OpSearchUserList', state.AssignableUser);
    commit('users', response.data);
    response = await api.get('QCTaskMonitoringSitesList');
    commit('monitoringSites', response.data);
    response = await api.get('QCTaskClientsList');
    commit('clients', response.data);
    response = await api.get('QCTaskRecurrencesList');
    const recurrencesList = [];
    response.data.forEach((recurrence) => {
      // eslint-disable-next-line no-param-reassign
      recurrencesList.push({
        Id: recurrence.Id,
        Name: i18n.t('QualityCheck.QCTasks.Tasks.'.concat(recurrence.Name)),
      });
    });
    commit('qcTaskRecurrences', recurrencesList);
    response = await api.get('QCTaskTemplatesList');
    commit('qcInspectionTemplates', response.data);
    response = await api.post('QCTasksList', state.filters);
    commit('qcTasks', response.data);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('filterList');
  },

  async filterList({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.post('QCTasksList', state.filters);
    commit('qcTasks', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const editTask = payload;
    if (typeof payload.SiteId === 'object') {
      editTask.SiteId = editTask.SiteId.Id;
    }
    if (typeof payload.AssignedToUserId === 'object') {
      editTask.AssignedToUserId = editTask.AssignedToUserId.UserId;
    }
    if (typeof payload.QCTaskTypeId === 'object') {
      editTask.QCTaskTypeId = editTask.QCTaskTypeId.TaskTypeId;
    }
    if (editTask.DueDate === '') {
      editTask.DueDate = null;
    }
    if (editTask.CompletedOn === '') {
      editTask.CompletedOn = null;
    }
    await api.put('QCTasksUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('filterList');
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`QCTasksRemove?id=${payload.id}`);
    commit('qcTask', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('filterList');
  },

  async setFilters({ commit }, payload) {
    commit('filters', payload);
  },
};

const mutations = {
  qcTask(state, payload) {
    state.qcTask = payload;
  },
  qcTasks(state, payload) {
    state.qcTasks = payload;
  },
  qcTaskTypes(state, payload) {
    state.qcTaskTypes = payload;
  },
  users(state, payload) {
    state.users = payload;
  },
  monitoringSites(state, payload) {
    state.monitoringSites = payload;
  },
  clients(state, payload) {
    state.clients = payload;
  },
  filters(state, payload) {
    state.filters = payload;
  },
  qcTaskRecurrences(state, payload) {
    state.qcTaskRecurrences = payload;
  },
  qcInspectionTemplates(state, payload) {
    state.qcInspectionTemplates = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
