<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <v-toolbar
      dense
    >
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Add')"
        :icon-name="'mdi-plus'"
        @click="openAddDialog"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Refresh')"
        :icon-name="'mdi-refresh'"
        @click="doRefresh"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Save')"
        :icon-name="'mdi-content-save'"
        :disabled="rowsToSave.length === 0"
        @click="doSave"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Delete')"
        :icon-name="'mdi-delete-forever'"
        :disabled="disableDelete"
        @click="doDelete"
      />
    </v-toolbar>
    <ag-grid-vue
      style="width: 100%; height: 100%;"
      class="ag-theme-balham-dark"
      :column-defs="columnDefs"
      :row-data="rowData"
      :row-selection="'multiple'"
      @grid-ready="onGridReady"
      @row-double-clicked="onRowDoubleClicked"
      @cell-value-changed="onCellValueChanged"
    />
    <v-dialog
      v-model="showDatePicker"
      :close-on-content-click="true"
      :return-value.sync="selectedDateCell"
      max-width="300px"
    >
      <template #activator="{ on, attrs }">
        <div
          v-if="showDatePickerActiveFrom"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-card>
        <v-card-title>
          {{ $t('Add') }}
        </v-card-title>
        <v-card-text>
          <datetime-picker
            v-model="Form.ActiveFrom"
            :datetime="Form.ActiveFrom"
            :label="$t('From')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
          />
        </v-card-text>
        <v-card-text>
          <datetime-picker
            v-model="Form.ActiveTo"
            :datetime="Form.ActiveTo"
            :label="$t('To')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="saveDate()"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Dialog -->
    <v-dialog
      v-model="showAddDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('VirtualInstrument.Template.AddItem') }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedPrincipalInstrument"
            :items="principalInstruments"
            :label="$t('VirtualInstrument.Template.PrincipalInstrument')"
            item-text="InstanceName"
            item-value="VITplTemplateInstanceId"
            dense
          />
          <datetime-picker
            v-model="Form.ActiveFrom"
            :datetime="Form.ActiveFrom"
            :label="$t('From')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
            dense
          />
          <datetime-picker
            v-model="Form.ActiveTo"
            :datetime="Form.ActiveTo"
            :label="$t('To')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
            dense
          />
          <template v-for="sym in symbols">
            <v-text-field
              :key="sym"
              v-model="Form[sym]"
              :label="sym"
              dense
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="closeAddDialog"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            text
            :disabled="disabledSaveButton"
            @click="saveItem"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbarVisible"
      :timeout="4000"
    >
      {{ snackbarMessage }}
      <v-btn
        text
        @click="snackbarVisible = false"
      >
        {{ $t('Close') }}
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="deleteConfirmationDialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('Warning') }}
        </v-card-title>
        <v-card-text>
          {{ $t('VirtualInstrument.Template.NotOkToDelete') }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteConfirmationDialog = false">
            {{ $t('Accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import importal from '@/api/importal';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { AgGridVue } from 'ag-grid-vue';
import datehandling from '@/components/datehandling';
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import DatetimePicker from '@/components/DatetimePicker.vue';

function dateFormatter(params) {
  if (params.value) {
    return datehandling.formatForDisplay(params.value);
  }
  return '';
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true });

function rowFilter(value, index, self) {
  return self.findIndex((y) => y.VITplTemplateInstanceId === value.VITplTemplateInstanceId
                  && y.ValidFromTs.getTime() === value.ValidFromTs.getTime()
                  && y.ValidToTs?.getTime() === value.ValidToTs?.getTime()) === index;
}

export default {
  components: {
    AgGridVue,
    ToolbarButtonWithTooltip,
    DatetimePicker,
  },
  data: () => ({
    refresh: 0,

    pickerTextFieldProps: {
      dense: true,
      style: 'font-size: 80%;',
    },
    pickerDateProps: {
      locale: 'en-ca',
    },
    pickerTimeProps: {
      format: '24hr',
    },

    columnDefs: null,
    rowData: null,
    gridApi: null,
    columnApi: null,

    showDatePicker: false,
    selectedDateCell: null,
    showDatePickerActiveFrom: false,

    rowsToSave: [],
    symbols: [],

    Form: {
      ActiveTo: new Date(2099, 0, 1),
      ActiveFrom: new Date(),
    },

    showAddDialog: false,
    selectedPrincipalInstrument: null,
    principalInstruments: [],
    disableDelete: true,
    snackbarMessage: '',
    snackbarVisible: false,
    deleteConfirmationDialog: false,
  }),
  computed: {
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),

    VITplTemplateId() {
      return this.$route.params.id;
    },

    disabledSaveButton() {
      if (
        this.selectedPrincipalInstrument
        && this.Form.ActiveTo
        && this.Form.ActiveFrom
        && this.symbols.map(
          (value) => this.Form[value],
        ).lenght === this.symbols.lenght
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    tenantId() {
      this.refresh += 1;
      this.readConstants();
    },
    accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.refresh += 1;
        this.readConstants();
      }
    },
    selectedPrincipalInstrument(newVal) {
      if (newVal) {
        this.Form.ActiveTo = new Date(2099, 0, 1);
        this.Form.ActiveFrom = new Date();
      }
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    showSnackbar(message) {
      this.snackbarMessage = message;
      this.snackbarVisible = true;
    },
    saveDate() {
      if (this.selectedDateCell) {
        const selectedRowData = this.gridApi.getSelectedRows()[0];
        if (this.Form.ActiveFrom) {
          selectedRowData.ValidFromTs = this.Form.ActiveFrom;
        }
        if (this.Form.ActiveTo) {
          selectedRowData.ValidToTs = this.Form.ActiveTo;
        }
        this.gridApi.updateRowData({ update: [selectedRowData] });
        const idx = this.rowsToSave
          .findIndex((x) => x.VITplTemplateInstanceId === selectedRowData.VITplTemplateInstanceId);
        if (idx >= 0) {
          [this.rowsToSave[idx]] = this.gridApi.getSelectedRows();
        } else {
          this.rowsToSave.push(this.gridApi.getSelectedRows()[0]);
        }
      }
      this.showDatePicker = false;
      this.Form.ActiveFrom = new Date();
      this.Form.ActiveTo = new Date(2099, 0, 1);
      this.showSnackbar(this.$t('VirtualInstrument.Template.Reminder'));
    },

    doRefresh() {
      this.refresh += 1;
      this.readConstants();
    },

    async onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.readConstants();

      params.api.addEventListener('cellClicked', (event) => {
        if (
          event.column.getColId() === 'ValidFromTs'
          || event.column.getColId() === 'ValidToTs'
        ) {
          this.showDatePicker = true;
          this.Form.ActiveFrom = event.data.ValidFromTs;
          this.Form.ActiveTo = event.data.ValidToTs;
          this.selectedDateCell = {
            column: event.column,
            date: event.value,
          };
        }
      });

      // Add event listener for row selection
      params.api.addEventListener('rowSelected', this.updateDeleteButtonState);
    },

    onCellValueChanged(event) {
      const idx = this.rowsToSave
        .findIndex((x) => x.VITplTemplateInstanceId === event.data.VITplTemplateInstanceId);
      if (idx >= 0) {
        this.rowsToSave[idx] = event.data;
      } else {
        this.rowsToSave.push(event.data);
      }
      this.showSnackbar(this.$t('VirtualInstrument.Template.Reminder'));
    },

    updateDeleteButtonState() {
      const selectedRows = this.gridApi.getSelectedRows();
      // Update disableDelete based on the number of selected rows
      this.disableDelete = selectedRows.length === 0;
    },
    onRowDoubleClicked() {
    },

    async doSave() {
      const constants = this.rowData.map((x) => this.symbols.map((y) => ({
        ConstantId: x[y.concat('-Id')],
        VITplTemplateInstanceId: x.VITplTemplateInstanceId,
        ValidFromTs: datehandling.zonedTimeToUtc(x.ValidFromTs),
        ValidToTs: datehandling.zonedTimeToUtc(x.ValidToTs),
        Symbol: y,
        Value: Number(x[y]),
      }))).flat(1);

      // Check if rowsToSave is not already in constants
      // NEW rows will not be in constants
      this.rowsToSave.forEach((row) => {
        if (!constants.some((c) => c.ConstantId === row[row.Symbol.concat('-Id')])) {
          this.symbols.forEach((y) => {
            constants.push({
              ConstantId: 0,
              VITplTemplateInstanceId: row.VITplTemplateInstanceId,
              ValidFromTs: datehandling.zonedTimeToUtc(row.ValidFromTs),
              ValidToTs: datehandling.zonedTimeToUtc(row.ValidToTs),
              Symbol: y,
              Value: Number(row[y]),
            });
          });
        }
      });
      this.rowsToSave.splice(0, this.rowsToSave.length); // Clear
      await this.CallApiPost('ViTemplateSaveConstants', {
        VITplTemplateId: this.VITplTemplateId,
        Constants: constants,
      });

      // refresh after saving
      this.doRefresh();
    },

    readConstants() {
      this.CallApiPost('ViTemplateListConstants', {
        VITplTemplateId: this.VITplTemplateId,
      });
    },

    fillGrid(data) {
      this.rowData = [];
      this.columnDefs = [];

      const symbols = data
        .map((x) => x.Symbol)
        .filter(onlyUnique)
        .sort(sortAlphaNum);
      this.symbols = symbols;

      const baseCols = [
        {
          field: 'InstanceName',
          headerName: 'Principal instrument',
          sortable: true,
          resizable: true,
          suppressMovable: true,
          editable: false,
        },
        {
          field: 'ValidFromTs',
          headerName: 'From',
          sortable: false,
          resizable: true,
          valueFormatter: dateFormatter,
          width: 150,
          suppressMovable: true,
          editable: false,
        },
        {
          field: 'ValidToTs',
          headerName: 'To',
          sortable: false,
          resizable: true,
          valueFormatter: dateFormatter,
          width: 150,
          suppressMovable: true,
          editable: false,
        },
      ];
      this.columnDefs = this.columnDefs.concat(
        baseCols,
        symbols.map((x) => ({
          field: x,
          sortable: false,
          resizable: true,
          suppressMovable: true,
          editable: true,
        })),
      );

      // For deleting
      this.columnDefs = this.columnDefs.concat(
        {
          headerName: '',
          checkboxSelection: true,
          // headerCheckboxSelection: true,
          pinned: 'left',
          width: 50,
          field: 'checkBoxBtn',
        },
      );

      const rowSource = data
        .filter(rowFilter);

      // This is not good to continue
      this.rowData = rowSource;

      for (let i = 0; i < data.length; i += 1) {
        const s = data[i];
        let row = this.rowData
          .find((x) => x.VITplTemplateInstanceId === s.VITplTemplateInstanceId
                    && x.ValidFromTs.getTime() === s.ValidFromTs.getTime()
                    && x.ValidToTs?.getTime() === s.ValidToTs?.getTime());
        if (!row) {
          row = {
            VITplTemplateInstanceId: s.VITplTemplateInstanceId,
            InstanceName: s.InstanceName,
            ValidFromTs: s.ValidFromTs,
            ValidToTs: s.ValidToTs,
          };
          this.rowData.push(row);
        }
        row[s.Symbol] = s.Value;
        // each row should have the info for all the symbols such as constantId
        row[s.Symbol.concat('-Id')] = s.ConstantId;
      }
    },

    async CallApiPost(endpoint, obj) {
      await this.$PleaseWaitOneAsync(
        importal.post(endpoint, obj)
          .then((resp) => {
            this.fillGrid(resp.data);
          })
          .catch((error) => {
            this.ShowError(error);
          }),
      );
    },
    async CallApiGet(endpoint) {
      await this.$PleaseWaitOneAsync(
        importal.get(endpoint)
          .then((resp) => {
            this.fillGrid(resp.data);
          })
          .catch((error) => {
            this.ShowError(error);
          }),
      );
    },
    ShowError(error) {
      const { data } = error.response;
      this.$root.$confirm(`${data.exception}\n${data.stacktrace}`, true, {
        title: `${error.response.status}: ${error.response.statusText}`,
      });
    },

    openAddDialog() {
      this.selectedPrincipalInstrument = null;
      this.Form.ActiveTo = new Date(2099, 0, 1);
      this.Form.ActiveFrom = new Date();
      this.principalInstruments = this.rowData.map((row) => ({
        VITplTemplateInstanceId: row.VITplTemplateInstanceId,
        InstanceName: row.InstanceName,
      }));
      this.symbols.forEach((symbol) => {
        this.Form[symbol] = null;
      });
      this.showAddDialog = true;
    },

    closeAddDialog() {
      this.showAddDialog = false;
    },

    saveItem() {
      if (
        this.selectedPrincipalInstrument
        && this.Form.ActiveTo
        && this.Form.ActiveFrom
        && this.symbols.map(
          (value) => this.Form[value],
        ).lenght === this.symbols.lenght
      ) {
        let goodRow = null;
        const values = this.rowData.filter(
          (row) => row.VITplTemplateInstanceId === this.selectedPrincipalInstrument,
        );
        if (values.length > 0) {
          [goodRow] = values;
        }
        const newItem = {
          VITplTemplateInstanceId: goodRow.VITplTemplateInstanceId,
          InstanceName: goodRow.InstanceName,
          ValidFromTs: this.Form.ActiveFrom,
          ValidToTs: this.Form.ActiveTo,
          Symbol: goodRow.Symbol,
          VirtualInstrumentId: goodRow.VirtualInstrumentId,
          VirtualInstrumentName: goodRow.VirtualInstrumentName,
        };

        this.symbols.forEach((symbol) => {
          newItem[symbol] = this.Form[symbol];
        });

        this.gridApi.applyTransaction({ add: [newItem] });

        this.rowsToSave.push(newItem);
        this.doSave();
      }
      this.showAddDialog = false;
    },

    async doDelete() {
      // Save first
      if (this.rowsToSave.length !== 0) {
        this.doSave();
      }

      const rowsToDelete = this.gridApi.getSelectedRows();
      // All the rows
      let remainingRows = [];
      this.gridApi.forEachNode((node) => remainingRows.push(node.data));
      let okToDelete = true;

      // Check the remaning rows after delete
      rowsToDelete.forEach((rowToDelete) => {
        remainingRows = remainingRows.filter((x) => x !== rowToDelete);
      });

      if (remainingRows.length > 0) {
        rowsToDelete.forEach((rowToDelete) => {
          if (
            remainingRows.filter(
              (x) => x.VITplTemplateInstanceId === rowToDelete.VITplTemplateInstanceId,
            ).length === 0
          ) {
            okToDelete = false;
          }
        });
      } else {
        okToDelete = false;
      }

      if (okToDelete) {
        const constants = rowsToDelete.map((row) => this.symbols.map((sym) => ({
          ConstantId: row[sym.concat('-Id')],
          VITplTemplateInstanceId: row.VITplTemplateInstanceId,
          ValidFromTs: datehandling.zonedTimeToUtc(row.ValidFromTs),
          ValidToTs: datehandling.zonedTimeToUtc(row.ValidToTs),
          Symbol: sym,
          Value: Number(row[sym]),
        }))).flat(1);

        if (constants) {
          await this.CallApiPost('ViTemplateDeleteConstants', {
            VITplTemplateId: this.VITplTemplateId,
            Constants: constants,
          });
        }
      } else {
        this.deleteConfirmationDialog = true;
      }

      // refresh after delete
      this.doRefresh();
    },
  },
};
</script>

<style lang="scss">
</style>
