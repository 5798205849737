<template>
  <div>
    <v-card-subtitle
      class="text-subtitle-2"
    >
      {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.EnterAlertInformation') }}
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col>
          <datetime-picker
            v-model="localActiveFrom"
            :datetime="localActiveFrom"
            :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.From')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
          />
      &nbsp;
      &nbsp;
        </v-col>
        <v-col>
          <datetime-picker
            v-model="localActiveTo"
            :datetime="localActiveTo"
            :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.To')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
          />
        </v-col>
      </v-row>
      <v-card-subtitle
        v-if="localSelectedAlertType.Name !== 'Scheduled'"
        class="text-subtitle-2"
      >
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.CheckSensorValueEvery') }}
      </v-card-subtitle>
      <v-row
        v-if="localSelectedAlertType.Name !== 'Scheduled'"
      >
        <v-col>
          <v-text-field
            v-model="localValidRateInMinValue"
            :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Value')"
            type="number"
            dense
          />
      &nbsp;
      &nbsp;
        </v-col>
        <v-col>
          <v-select
            v-model="localValidRateInMinScale"
            :placeholder="$t('Dashboard.SubscribeMenu.SubscribeSettings.TimeScale')"
            :items="validationRateInMinScale"
            item-text="Name"
            item-value="Scale"
            dense
            return-object
          />
      &nbsp;
      &nbsp;
        </v-col>
      </v-row>
      <v-card-subtitle class="text-subtitle-2">
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.SelectValidDay') }}
      </v-card-subtitle>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                v-model="localValidDay[1]"
                dense
                :false-value="'0'"
                :true-value="'2'"
                :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Monday')"
              />
              <v-checkbox
                v-model="localValidDay[2]"
                dense
                :false-value="'0'"
                :true-value="'4'"
                :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Tuesday')"
              />
              <v-checkbox
                v-model="localValidDay[3]"
                dense
                :false-value="'0'"
                :true-value="'8'"
                :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Wednesday')"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                v-model="localValidDay[4]"
                dense
                :false-value="'0'"
                :true-value="'16'"
                :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Thursday')"
              />
              <v-checkbox
                v-model="localValidDay[5]"
                dense
                :false-value="'0'"
                :true-value="'32'"
                :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Friday')"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
              <v-checkbox
                v-model="localValidDay[6]"
                dense
                :false-value="'0'"
                :true-value="'64'"
                :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Saturday')"
              />
              <v-checkbox
                v-model="localValidDay[0]"
                dense
                :false-value="'0'"
                :true-value="'1'"
                :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Sunday')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-subtitle class="text-subtitle-2">
        {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.TimeOfDay') }}
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <time-picker
              v-model="localTimeOfDayStart"
              :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Start')"
              v-bind="timePickerProps"
              full-width
            />
          </v-col>
          <v-col>
            <time-picker
              v-model="localTimeOfDayEnd"
              :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.End')"
              v-bind="timePickerProps"
              full-width
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card-text>
  </div>
</template>

<script>
import DatetimePicker from '@/components/DatetimePicker.vue';
import TimePicker from '@/components/TimePicker.vue';

export default {
  components: {
    DatetimePicker,
    TimePicker,
  },
  props: {
    activeFrom: { type: Date, default: () => null },
    activeTo: { type: Date, default: () => null },
    selectedAlertType: { type: Object, default: () => null },
    validRateInMinValue: { type: String, default: () => null },
    validRateInMinScale: { type: Object, default: () => null },
    validationRateInMinScale: { type: Array, default: () => null },
    validDay: { type: Array, default: () => null },
    timeOfDayStart: { type: String, default: () => null },
    timeOfDayEnd: { type: String, default: () => null },
  },
  data() {
    return {
      pickerTextFieldProps: {
        dense: true,
        style: 'font-size: 80%;',
      },
      pickerDateProps: {
        locale: 'en-ca',
      },
      pickerTimeProps: {
        format: '24hr',
      },
      timePickerProps: {
      },
      localActiveFrom: this.activeFrom,
      localActiveTo: this.activeTo,
      localSelectedAlertType: this.selectedAlertType,
      localValidRateInMinValue: this.validRateInMinValue,
      localValidDay: this.validDay,
      localTimeOfDayStart: this.timeOfDayStart,
      localTimeOfDayEnd: this.timeOfDayEnd,
      localValidRateInMinScale: this.validRateInMinScale,
    };
  },
  watch: {
    localValidRateInMinScale(newValue) {
      this.$emit('update:ValidRateInMinScale', newValue);
    },
    localActiveFrom(newValue) {
      this.$emit('update:ActiveFrom', newValue);
    },
    localActiveTo(newValue) {
      this.$emit('update:ActiveTo', newValue);
    },
    localValidRateInMinValue(newValue) {
      this.$emit('update:ValidRateInMinValue', newValue);
    },
    localValidDay(newValue) {
      this.$emit('update:ValidDay', newValue);
    },
    localTimeOfDayStart(newValue) {
      this.$emit('update:TimeOfDayStart', newValue);
    },
    localTimeOfDayEnd(newValue) {
      this.$emit('update:TimeOfDayEnd', newValue);
    },
  },
};
</script>

<style scoped>
</style>
