import api from '@/api/importal';

const state = {
  siteNote: null,
  siteNotes: [],
  siteId: null,
  noteId: null,
};

const actions = {

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`SiteNotesList?siteId=${state.siteId}`);
    commit('siteNotes', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('SiteNotesCreate', {
      Note: payload.Note,
      IsInternal: payload.IsInternal,
      IsMaintenanceRecord: payload.IsMaintenanceRecord,
      TechnicianName: payload.TechnicianName,
      SiteId: Number(state.siteId),
      CreationTs: payload.CreationTs,
    });
    await dispatch('list');
    commit('app/pleaseWait', false, { root: true });
  },

  async get({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`SiteNotesGet?id=${Number(state.noteId)}`);
    const note = response.data;
    commit('siteNote', note);
    commit('app/pleaseWait', false, { root: true });
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const note = payload;
    await api.put('SiteNotesUpdate', note);
    await dispatch('list');
    commit('app/pleaseWait', false, { root: true });
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`SiteNotesRemove?id=${payload.id}`);
    commit('siteNote', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async setSiteId({ commit }, payload) {
    commit('siteId', payload);
  },

  async setNoteId({ commit }, payload) {
    commit('noteId', payload.noteId);
  },

  async setSiteNote({ commit }, payload) {
    commit('siteNote', payload.noteId);
  },

};

const mutations = {

  siteNotes(state, payload) {
    state.siteNotes = payload;
  },

  siteNote(state, payload) {
    state.siteNote = payload;
  },

  siteId(state, payload) {
    state.siteId = payload;
  },

  noteId(state, payload) {
    state.noteId = payload;
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
