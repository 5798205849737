/* eslint-disable no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import datehandling from '@/components/datehandling';
import config from '../../config';
import i18n from '../../plugins/i18n';
import axiosRetry from 'axios-retry';

export const parseObject = (obj) => {
  if (typeof obj !== 'object' || obj == null) {
    return;
  }

  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      return;
    }

    if (typeof obj[key] === 'string') {
      // eslint-disable-next-line
      obj[key] = datehandling.parseFromApi(obj[key]);
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((el) => parseObject(el));
    } else if (typeof obj[key] === 'object') {
      parseObject(obj[key]);
    }
  });
};

const api = axios.create({
  baseURL: config.API.IMPORTAL.BASE_URL + config.API.IMPORTAL.ENDPOINT,
});

function onError(error) {
  let message = i18n.t('ApiError.Message');

  if (error.response.data.PresentationKey) {
    message = i18n.t(error.response.data.PresentationKey,
      error.response.data.PresentationValues);
  } else if (error.response.data.applicationId === 'DevGatewayBuildAutoConfig') {
    message = i18n.t('ApiError.AutoConfig');
    message = message.concat(error.response.data.exception);
  } else if (error.response.data.applicationId === 'DevGatewayDispatchFiles') {
    message = i18n.t('ApiError.DispatchFiles');
    message = message.concat(error.response.data.exception);
  } else if (error.response.data.exception) {
    message = error.response.data.exception.concat(error.response.data.stacktrace);
  } else if (error.response.status === 401) {
    Vue.prototype.$auth.loginWithRedirect();
  }

  window.$confirm(message, true, {
    title: i18n.t('ApiError.Title'),
    color: 'red',
  });

  store.commit('app/pleaseWait', false, { root: true });

  return Promise.reject(error);
}

api.interceptors.request.use(async (request) => {
  const apiToken = await Vue.prototype.$auth.GetToken();
  if (store) {
    request.headers['importal-selected-tenant'] = store.getters['app/tenantId'];

    const selectedAccessGroups = store.getters['app/accessGroupIds'];
    if (selectedAccessGroups.length > 0) {
      request.headers['importal-selected-access-groups'] = selectedAccessGroups;
    }
  }

  request.headers['Access-Control-Allow-Origin'] = '*';
  // By-passing token - TODO need to implement CRUD for users and permissions;
  if (apiToken) {
    request.headers.Authorization = `Bearer ${apiToken.idToken}`;
  }
  request.headers.Accept = 'application/json';
  request.headers['Content-Type'] = 'application/json';
  request.headers['Accept-Language'] = `${i18n.locale}-US`;

  return request;
}, (error) => onError(error));

api.interceptors.response.use((response) => {
  parseObject(response);
  return response;
}, (error) => onError(error));

// Force retry on network error
axiosRetry(api, {
  retries: 3,
  retryCondition: (error) => {
    if (error && error.message && error.message === 'Network Error') {
      return true;
    }
    return axiosRetry.isNetworkOrIdempotentRequestError(error);
  },
});

export default api;
