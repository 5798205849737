import api from '@/api/importal';

const state = {
  users: [],
};

const actions = {
  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const users = await api.get('OpUserList');
    commit('users', users.data);
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  users(state, payload) {
    state.users = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
