<template>
  <v-dialog
    v-model="showDialog"
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-card-title>Equipment name goes here</v-card-title>
      <v-card-text>
        <p>PE Infos goes here!</p>
        <p>To be done in US #22238, #22501</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="showDialog = false"
          v-t="'WindServer.Sites.SiteConfigurationDiagram.EquipmentModal.Close'"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      showDialog: this.show,
    };
  },
  watch: {
    show(newValue) {
      this.showDialog = newValue;
    },
    showDialog(newValue) {
      this.$emit('update:show', newValue);
    },
  },
};
</script>
