/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';
import datehandling from '@/components/datehandling';
import importal from '@/api/importal';

function NotNull(x) {
  return x !== null && x !== undefined;
}

export default class GsAlert extends ServerSideDataGridGsClientBase {
  constructor(cb) {
    super();
    this.callback = cb;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          width: 40,
          resizable: true,
        },
        {
          field: 'Journal',
          headerName: i18n.t('Alert.Manage.AlertList.Actions'),
          resizable: true,
          width: 120,

          cellRenderer: (params) => {
            const container = document.createElement('div');

            const infoIcon = document.createElement('i');
            infoIcon.className = 'v-icon mdi mdi-information';
            infoIcon.title = i18n.t('Alert.Manage.AlertList.SeeInfo');
            infoIcon.style.marginRight = '10px';
            infoIcon.style.cursor = 'pointer';
            infoIcon.addEventListener('click', () => {
              this.callback.onSeeInfo(params.data);
            });

            const journalIcon = document.createElement('i');
            journalIcon.className = 'v-icon mdi mdi-book';
            journalIcon.title = i18n.t('Alert.Manage.AlertList.AlertJournal');
            journalIcon.style.marginRight = '10px';
            journalIcon.style.cursor = 'pointer';
            journalIcon.addEventListener('click', () => {
              this.callback.onSeeJournal(params.data);
            });

            const editIcon = document.createElement('i');
            editIcon.className = 'v-icon mdi mdi-pencil';
            editIcon.title = i18n.t('Alert.Manage.AlertList.Edit');
            editIcon.style.cursor = 'pointer';
            editIcon.addEventListener('click', () => {
              this.callback.onEdit(params.data);
            });

            container.appendChild(infoIcon);
            container.appendChild(journalIcon);
            container.appendChild(editIcon);
            return container;
          },
        },
        {
          field: 'Status',
          headerName: i18n.t('Alert.Manage.AlertList.Status'),
          width: 75,
          resizable: true,
          filter: true,
          sortable: true,
          cellRenderer: this.statusCellRenderer.bind(this),
        },
        {
          field: 'InstrumentName',
          headerName: i18n.t('Alert.Manage.AlertList.InstrumentName'),
          width: 100,
          resizable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'SensorName',
          headerName: i18n.t('Alert.Manage.AlertList.SensorName'),
          width: 100,
          resizable: true,
          sortable: true,
          filter: true,
        },
        {
          field: 'State',
          headerName: i18n.t('Alert.Manage.AlertList.State'),
          width: 80,
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.stateCellRenderer.bind(this),
        },
        // {
        //   field: 'Ack',
        //   headerName: i18n.t('Alert.Manage.AlertList.Ack'),
        //   width: 80,
        //   resizable: true,
        //   sortable: true,
        //   filter: true,
        //   cellRenderer: this.ackCellRenderer.bind(this),
        // },
        {
          field: 'ValidationLastTime',
          headerName: i18n.t('Alert.Manage.AlertList.ValidationLastTime'),
          width: 140,
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.dateCellRendererFromUTC.bind(this),
        },
        {
          field: 'ViolationTs',
          headerName: i18n.t('Alert.Manage.AlertList.LastViolation'),
          width: 140,
          resizable: true,
          sortable: true,
          filter: true,
          cellRenderer: this.dateCellRendererFromZone.bind(this),
        },
        {
          field: 'SensorAlertName',
          headerName: i18n.t('Alert.Manage.AlertList.SensorAlertName'),
          width: 145,
          resizable: true,
          filter: true,
          sortable: true,
          editable: true,
        },
        {
          field: 'PDF',
          headerName: i18n.t('Alert.Manage.AlertList.Dashboard'),
          width: 200,
          cellRenderer: (params) => this.GetDashboardName(params.data),
          resizable: true,
          filter: true,
          sortable: true,
        },
        // {
        //   field: 'Description',
        //   headerName: i18n.t('Alert.Manage.AlertList.Description'),
        //   width: 400,
        //   cellRenderer: (params) => this.GetItemContent(params.data),
        //   resizable: true,
        // },
      ]);
    });
  }

  // Called when an editable column is changed
  // The function name must match the data Model
  // MAY call successCallback if the API call returns the table data
  onChange() {
    return {
      SensorAlertName(event, successCallback, failCallback) {
        importal
          .post('AlertSensorRename', {
            BasicAlertConfigId: event.data.BasicAlertConfigId,
            SensorId: [event.data.SensorId],
            Name: event.data.SensorAlertName,
          })
          .then((resp) => {
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
    };
  }

  typeCellRenderer(params) {
    let iconClass;
    let title;
    if (this.isNominal(params.data)) {
      iconClass = 'mdi-numeric';
      title = i18n.t('Alert.Manage.AlertList.Nominal');
    } else if (this.isMissingData(params.data)) {
      iconClass = 'mdi-help-circle-outline';
      title = i18n.t('Alert.Manage.AlertList.MissingData');
    } else {
      iconClass = 'mdi-calendar-month';
      title = i18n.t('Alert.Manage.AlertList.Scheduled');
    }

    return `<i class="v-icon mdi ${iconClass}" title="${title}"></i>`;
  }

  stateCellRenderer(params) {
    const state = params.value;
    const isActive = state === 'Active';

    let iconClass = isActive ? 'mdi-alert' : 'mdi-check-circle';
    let color = isActive ? 'red' : 'green';
    let title = isActive ? i18n.t('Alert.Manage.AlertList.Active') : i18n.t('Alert.Manage.AlertList.Inactive');

    if (this.isAck(params.data) && isActive && !params.data.IsScheduled) {
      iconClass = 'mdi-alert-outline';
      title = i18n.t('Alert.AlertStatus.StateFlags.ActifAndAck');
    }

    if (params.data.ValidationLastTime === null || params.data.ValidationLastTime === undefined) {
      color = 'white';
      iconClass = 'mdi-help';
      title = i18n.t('Alert.Manage.AlertList.Unknown');
    }

    return `<i class="v-icon mdi ${iconClass}" title="${title}" style="color: ${color};"></i>`;
  }

  dateCellRendererFromUTC(params) {
    if (params.value) {
      return datehandling.formatForDisplay(datehandling.zonedTimeToUtc(params.value));
    }
    return '';
  }

  dateCellRendererFromZone(params) {
    if (params.value) {
      return datehandling.formatForDisplay(params.value);
    }
    return '';
  }

  statusCellRenderer(params) {
    const isMute = this.isMute(params.data);
    const iconClass = isMute ? 'mdi-bell-off' : 'mdi-bell';
    const title = isMute ? i18n.t('Alert.Manage.AlertList.Mute') : i18n.t('Alert.Manage.AlertList.Unmute');

    return `<i class="v-icon mdi ${iconClass}" title="${title}"></i>`;
  }

  modeCellRenderer(params) {
    const isRepetitive = this.isRepetitive(params.data);
    const iconClass = isRepetitive ? 'mdi-repeat' : 'mdi-repeat-off';
    const title = isRepetitive ? i18n.t('Alert.Manage.AlertList.Persistent') : i18n.t('Alert.Manage.AlertList.FirstOnly');

    return `<i class="v-icon mdi ${iconClass}" title="${title}"></i>`;
  }

  shareCellRenderer(params) {
    const isShared = this.isShared(params.data);
    if (isShared) {
      return `<i class="v-icon mdi mdi-account-multiple" title="${i18n.t('Alert.Manage.AlertList.Shared')}"></i>`;
    }
    return '';
  }

  ackCellRenderer(params) {
    const isAcknowledged = params.value === i18n.t('Alert.Manage.AlertList.Acked');
    if (!isAcknowledged) {
      return `<i class="v-icon mdi mdi-alert-circle-outline" title="${i18n.t('Alert.Manage.AlertList.Unacked')}"></i>`;
    }
    return '';
  }

  priorityCellRenderer(params) {
    const priority = params.value;
    let color = 'white'; // default color

    switch (priority) {
      case i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Diagnostic'):
        color = 'rgb(83, 86, 255)';
        break;
      case i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Low'):
        color = 'rgb(0, 255, 0)';
        break;
      case i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Medium'):
        color = 'rgb(253, 231, 103)';
        break;
      case i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.High'):
        color = 'rgb(253, 164, 3)';
        break;
      case i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Critical'):
        color = 'rgb(233, 70, 39)';
        break;
      default:
        if (params.data.IsScheduled) {
          color = 'rgb(83, 86, 255)';
        }
        break;
    }

    return `<span style="color: ${color};">${priority}</span>`;
  }

  isNominal(item) {
    if (
      NotNull(item.ValueLt)
      || NotNull(item.ValueLteq)
      || NotNull(item.ValueGt)
      || NotNull(item.ValueGteq)
    ) {
      return true;
    }
    return false;
  }

  isMissingData(item) {
    if (
      NotNull(item.ValueLt)
      || NotNull(item.ValueLteq)
      || NotNull(item.ValueGt)
      || NotNull(item.ValueGteq)
    ) {
      return false;
    }
    if (item.IsScheduled) {
      return false;
    }
    return true;
  }

  isRepetitive(item) {
    if (item.NotificationMode) {
      return true;
    }
    return false;
  }

  isShared(item) {
    return item.sharedUsers.filter((x) => x.id !== item.UserId).length > 0
      || item.sharedAccessGroups.length > 0;
  }

  isActive(item) {
    // eslint-disable-next-line no-bitwise
    if (item.StateFlag & 0x0001) {
      return true;
    }
    return false;
  }

  isAck(item) {
    // eslint-disable-next-line no-bitwise
    if (item.StateFlag & 0x0002) {
      return true;
    }
    if (item.IsScheduled) {
      return true;
    }
    return false;
  }

  isMute(item) {
    // eslint-disable-next-line no-bitwise
    if (item.StateFlag & 0x0004) {
      return true;
    }
    return false;
  }

  GetAlertStateColor(state) {
    switch (state) {
      case i18n.t('Alert.Manage.AlertList.Inactive'):
        return 'rgb(0, 255, 0)';
      case i18n.t('Alert.Manage.AlertList.Active'):
        return 'rgb(233, 70, 39)';
      default:
        return 'rgb(255, 0, 0)';
    }
  }

  GetDashboardName(state) {
    const sendDashboardJSON = JSON.parse(state.SendFullDashboard);
    if (sendDashboardJSON) {
      const gooddash = this.callback.dashboards.filter(
        (dash) => dash.DashboardId === sendDashboardJSON.DashboardId,
      );
      if (gooddash.length > 0 && gooddash[0].Name) {
        return gooddash[0].Name;
      }
    }
    return i18n.t('Alert.Manage.AlertList.NoDash');
  }

  GetItemState(item) {
    let msg = '';

    msg += i18n.t('Alert.Manage.AlertList.Acknowledged');
    if (this.isAck(item)) {
      msg += i18n.t('Alert.Manage.AlertList.Acked');
    } else {
      msg += i18n.t('Alert.Manage.AlertList.Unacked');
    }

    msg += ' | ';
    msg += i18n.t('Alert.Manage.AlertList.CurrentState');
    if (this.isActive(item)) {
      msg += i18n.t('Alert.Manage.AlertList.Active');
    } else {
      msg += i18n.t('Alert.Manage.AlertList.Inactive');
    }

    msg += ' | ';
    msg += i18n.t('Alert.Manage.AlertList.Priority');
    msg += ': ';
    if (item.IsScheduled) {
      msg += i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Diagnostic');
    } else {
      switch (item.NotificationPriority) {
        case -25:
          msg += i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Diagnostic');
          break;
        case 0:
          msg += i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Low');
          break;
        case null:
        case 25:
        case undefined:
          msg += i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Medium');
          break;
        case 50:
          msg += i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.High');
          break;
        case 75:
          msg += i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Critical');
          break;
        default:
          msg += i18n.t('Dashboard.SubscribeMenu.SubscribeSettings.Medium');
          break;
      }
    }
    return msg;
  }

  GetItemContent(alert) {
    let alertExtra = '';
    const alertWhenGt = i18n.t('Alert.Manage.AlertList.AlertWhenGreaterOrEqualThan');
    const alertWhenG = i18n.t('Alert.Manage.AlertList.AlertWhenGreaterThan');
    const alertWhenLt = i18n.t('Alert.Manage.AlertList.AlertWhenLessOrEqualThan');
    const alertWhenL = i18n.t('Alert.Manage.AlertList.AlertWhenLessThan');
    const alertEvery = i18n.t('Alert.Manage.AlertList.AlertEvery');
    const alertAfter = i18n.t('Alert.Manage.AlertList.AlertAfter');
    const missingData = i18n.t('Alert.Manage.AlertList.HoursOfMissingData');
    const alertWhen = i18n.t('Alert.Manage.AlertList.AlertWhen');
    const GThan = i18n.t('Alert.Manage.AlertList.GreaterThan');
    const GEThan = i18n.t('Alert.Manage.AlertList.GreaterThanOrEqualTo');
    const LThan = i18n.t('Alert.Manage.AlertList.LessThan');
    const LEThan = i18n.t('Alert.Manage.AlertList.LessThanOrEqualTo');
    const checkingEvery = i18n.t('Alert.Manage.AlertList.CheckingEvery');

    if (alert.IsScheduled) {
      alertExtra += `${alertEvery} ${alert.ValueDelayHours} ${i18n.t('Alert.Manage.AlertList.Hours').toLowerCase()}`;
    } else if (NotNull(alert.ValueLt)
                || NotNull(alert.ValueLteq)
                || NotNull(alert.ValueGt)
                || NotNull(alert.ValueGteq)) {
      const windowVal = [];
      const isInclusive = [];
      if (NotNull(alert.ValueGt)) {
        alertExtra += `${alertWhenG} ${alert.ValueGt}`;
        windowVal.push(alert.ValueGt);
        isInclusive.push(false);
      }
      if (NotNull(alert.ValueGteq)) {
        alertExtra += `${alertWhenGt} ${alert.ValueGteq}`;
        windowVal.push(alert.ValueGteq);
        isInclusive.push(true);
      }
      if (NotNull(alert.ValueLt)) {
        alertExtra += `${alertWhenL} ${alert.ValueLt}`;
        windowVal.push(alert.ValueLt);
        isInclusive.push(false);
      }
      if (NotNull(alert.ValueLteq)) {
        alertExtra += `${alertWhenLt} ${alert.ValueLteq}`;
        windowVal.push(alert.ValueLteq);
        isInclusive.push(true);
      }

      if (windowVal.length === 2) {
        if (windowVal[0] < windowVal[1]) {
          // the first is smaller than the second
          // it is inside
          // G or GE than and L or LE than
          alertExtra = `${alertWhen} `;
          if (isInclusive[0]) {
            alertExtra += `${GEThan} `;
            alertExtra += `${windowVal[0]}`;
          } else {
            alertExtra += `${GThan} `;
            alertExtra += `${windowVal[0]}`;
          }
          alertExtra += ' and ';
          if (isInclusive[1]) {
            alertExtra += `${LEThan} `;
            alertExtra += `${windowVal[1]}`;
          } else {
            alertExtra += `${LThan} `;
            alertExtra += `${windowVal[1]}`;
          }
        } else {
          // the first is greater than the second
          // it is outside
          // G or GE than and L or LE than
          alertExtra = `${alertWhen} `;
          if (isInclusive[1]) {
            alertExtra += `${LEThan} `;
            alertExtra += `${windowVal[1]}`;
          } else {
            alertExtra += `${LThan} `;
            alertExtra += `${windowVal[1]}`;
          }
          alertExtra += ' and ';
          if (isInclusive[0]) {
            alertExtra += `${GEThan} `;
            alertExtra += `${windowVal[0]}`;
          } else {
            alertExtra += `${GThan} `;
            alertExtra += `${windowVal[0]}`;
          }
        }
      }
    } else {
      const alertCheck = this.GetEditMissingSensorCheck(alert);
      alertExtra += ` ${alertAfter} ${alert.ValueDelayHours} ${missingData} ${checkingEvery} ${alertCheck}`;
    }

    return `${alertExtra}`;
  }

  GetEditMissingSensorCheck(Sensor) {
    const frequencyHours = Sensor.ValidationRateMinutes;
    for (let i = 0; i < Sensor.length; i += 1) {
      if (frequencyHours % (Sensor[i].Scale) === 0) {
        const Scaling = Sensor[i].Name;
        const ScalingTranslate = 'Alert.Manage.AlertList.'.concat(Scaling);
        const Value = frequencyHours / (Sensor[i].Scale);
        const ReturnValue = `${Value} ${i18n.t(ScalingTranslate)}`;
        return ReturnValue;
      }
    }
    return `${frequencyHours} ${i18n.t('Alert.Manage.AlertList.Minutes')}`;
  }

  newItemFormDefinition() {
    if (this.callback) {
      this.callback.addNewSensor();
    }
    return null;
  }

  onNewItem() {
    if (this.callback) {
      this.callback.addNewSensor();
    }
  }
}
