<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <v-toolbar
      dense
    >
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Refresh')"
        :icon-name="'mdi-refresh'"
        @click="doRefresh"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Principal rule')"
        :icon-name="'mdi-arrow-decision-outline'"
        @click="doSetPrincipalRule"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Output instrument name template')"
        :icon-name="'mdi-arrow-down-bold-hexagon-outline'"
        @click="doSetOutputNameTemplate"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Add Virtual Instrument')"
        :icon-name="'mdi-plus'"
        :disabled="!(viData.Content.PrincipalInstrumentMatchingRule && viData.Content.OutputInstrumentName)"
        @click="doNewVirtualInstrument"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Run Template')"
        :icon-name="'mdi-run'"
        :disabled="!(viData.Content.PrincipalInstrumentMatchingRule && viData.Content.OutputInstrumentName)"
        @click="doRunTemplate"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Edit Constants')"
        :icon-name="'mdi-alpha-c-box'"
        @click="doEditConstants"
      />
    </v-toolbar>
    <dialog-set-principal-rule
      v-if="showSetPrincipalRuleDialog"
      v-models:NameTemplate="editingNode.NameTemplate"
      v-models:show="showSetPrincipalRuleDialog"
      @on-accept="onSetPrincipalRuleAccept"
    />
    <dialog-set-output-instrument-name
      v-if="showSetOutputInstrumentNameDialog"
      v-models:NameTemplate="editingNode.NameTemplate"
      v-models:show="showSetOutputInstrumentNameDialog"
      @on-accept="onSetOutputInstrumentNameAccept"
    />
    <dialog-new-virtual-instrument
      v-if="showNewVirtualInstrumentDialog"
      v-models:NameTemplate="editingNode.NameTemplate"
      v-models:show="showNewVirtualInstrumentDialog"
      @on-accept="onNewVirtualInstrumentAccept"
    />
    <template-high-level-svg
      :vi-data="viData"
      @node-action-click="nodeActionClick"
    />
  </div>
</template>

<script>
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import { mapGetters } from 'vuex';
import importal from '@/api/importal';
import TemplateHighLevelSvg from './TemplateHighLevelSvg.vue';
import DialogSetPrincipalRule from './components/DialogSetPrincipalRule.vue';
import DialogSetOutputInstrumentName from './components/DialogSetOutputInstrumentName.vue';
import DialogNewVirtualInstrument from './components/DialogNewVirtualInstrument.vue';

export default {
  components: {
    ToolbarButtonWithTooltip,
    TemplateHighLevelSvg,
    DialogSetPrincipalRule,
    DialogSetOutputInstrumentName,
    DialogNewVirtualInstrument,
  },
  data: () => ({
    refresh: 0,
    viData: null,

    showSetPrincipalRuleDialog: false,
    showSetOutputInstrumentNameDialog: false,
    showNewVirtualInstrumentDialog: false,
    editingNode: {
      NameTemplate: null,
    },
  }),
  computed: {
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),

    VITplTemplateId() {
      return this.$route.params.id;
    },
  },
  watch: {
    tenantId() {
      this.refresh += 1;
      this.readTemplate();
    },
    accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.refresh += 1;
        this.readTemplate();
      }
    },
  },
  created() {
  },
  mounted() {
    this.readTemplate();
  },
  methods: {
    doRefresh() {
      this.refresh += 1;
      this.readTemplate();
    },

    doRunTemplate() {
      this.$router.push(`/vitpl/${this.VITplTemplateId}/run`);
    },

    doEditConstants() {
      this.$router.push(`/vitpl/${this.VITplTemplateId}/const`);
    },

    doNewVirtualInstrument() {
      this.editingNode.NameTemplate = null;
      this.showNewVirtualInstrumentDialog = true;
    },

    onNewVirtualInstrumentAccept(data) {
      this.CallApiPost('ViTemplateViNew', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        Name: data.NameTemplate,
      });
    },

    doSetPrincipalRule() {
      this.editingNode.NameTemplate = this.viData.Content.PrincipalInstrumentMatchingRule;
      this.showSetPrincipalRuleDialog = true;
    },

    doSetOutputNameTemplate() {
      this.editingNode.NameTemplate = this.viData.Content.OutputInstrumentName;
      this.showSetOutputInstrumentNameDialog = true;
    },

    onSetPrincipalRuleAccept(data) {
      this.CallApiPost('ViTemplateSetPrincipalInstrumentMatchingRule', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        Rule: data.NameTemplate,
      });
    },

    onSetOutputInstrumentNameAccept(data) {
      this.CallApiPost('ViTemplateSetOutputInstrumentNameTemplate', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        Rule: data.NameTemplate,
      });
    },

    showTemplate(templateContents) {
      if (templateContents.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const [templateContent] = templateContents;
        this.viData = templateContent;
      }
    },

    readTemplate() {
      this.CallApiGet(`ViTemplateList?tenantId=${this.tenantId}&VITplTemplateId=${this.VITplTemplateId}`);
    },

    nodeActionClick(actionType, node) {
      if (actionType === 'delete' && node.nodeType === 'virtual_instrument') {
        /* Remove a virtual instrument container from the template */
        importal.post('ViTemplateViRemove', {
          TenantId: this.tenantId,
          VITplTemplateId: this.VITplTemplateId,
          ViId: node.Id,
        })
          .then((resp) => {
            this.showTemplate(resp.data);
          })
          .catch(() => {
          });
      } else if (actionType === 'edit' && node.nodeType === 'virtual_instrument') {
        this.$router.push(`/vitpl/${this.VITplTemplateId}/${node.Id}`);
      }
    },

    async CallApiPost(endpoint, obj) {
      await this.$PleaseWaitOneAsync(
        importal.post(endpoint, obj)
          .then((resp) => {
            this.showTemplate(resp.data);
          })
          .catch((error) => {
            this.ShowError(error);
          }),
      );
    },
    async CallApiGet(endpoint) {
      await this.$PleaseWaitOneAsync(
        importal.get(endpoint)
          .then((resp) => {
            this.showTemplate(resp.data);
          })
          .catch((error) => {
            this.ShowError(error);
          }),
      );
    },
    ShowError(error) {
      const { data } = error.response;
      this.$root.$confirm(`${data.exception}\n${data.stacktrace}`, true, {
        title: `${error.response.status}: ${error.response.statusText}`,
      });
    },
  },
};
</script>

<style lang="scss">
</style>
