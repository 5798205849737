export default [
  {
    label: 'Alert.Title',
    icon: 'mdi-bell-outline',
    title: 'Alert.PageTitle',
    items: [
      {
        label: 'Alert.Manage.Title',
        to: { name: 'Alert.Manage' },
        title: 'Alert.Manage.PageTitle',
      },
      {
        label: 'Alert.AlertStatus.Title',
        to: { name: 'Alert.Status' },
        title: 'Alert.AlertStatus.PageTitle',
      },
    ],
  },
];
