/* eslint-disable no-param-reassign */
import api from '@/api/importal';

// eslint-disable-next-line no-unused-vars, camelcase
window.render_graphic = async function render_graphic(div_id, pcfg, queryobj) {
  // eslint-disable-next-line no-console
  // console.log('render_graphic:', queryobj);
  // TODO: Define an algorithm to query data in sequential chunks
  await window.Plotly.react(div_id, pcfg)
    .then(() => {
      api.post('SensorQuery', queryobj)
        .then((reply) => {
          if (reply.status === 200) {
            // eslint-disable-next-line no-console
            // console.log(reply, pcfg);
            for (let i = 1; i < reply.data.Columns.length; i += 1) {
              pcfg.data[i - 1].x = reply.data.Rows.map((x) => x[0]);
              pcfg.data[i - 1].y = reply.data.Rows.map((x) => x[i]);
            }
            window.Plotly.react(div_id, pcfg);
          }
        });
    });
};
