<template>
  <span>
    <v-btn
      icon
      x-small
      style="vertical-align: baseline;"
      @click="btnClickedHandler()"
    >
      <v-icon>{{ params.icon }}</v-icon>
    </v-btn>{{ params.value }}</span>
</template>

<script>
export default {
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params);
    },
  },
};
</script>
