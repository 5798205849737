<template>
  <div>
    <server-side-data-grid
      style="height: calc(100vh - 120px); width: 100%;"
      :refresh="refreshGrid"
      :grid-source="sources.Mails"
      :show-new-button="$auth.roles.includes('Web.Dashboard.ManageMailList')"
    />
    <v-card
      v-if="EditMailList != null"
      class="right-sidebar"
      tile
      outlined
      elevation="10"
    >
      <v-card-title>
        {{ EditMailList.Name }}
      </v-card-title>
      <v-spacer />
      <v-divider />
      <v-card-subtitle
        class="text-subtitle-2"
      >
        {{ $t('Settings.MailList.Recipients') }}
      </v-card-subtitle>
      <v-spacer />
      <v-divider />
      <v-card-text>
        <v-list
          shaped
          dense
          style="max-height: 300px; overflow-y: auto;"
        >
          <v-list-item-group
            v-model="SelectedItem"
            color="red"
          >
            <v-list-item
              v-for="(item, i) in RecipentList"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon>
                  mdi-account
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="$t(item.Email)"
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-if="SelectedItem == i"
                  icon
                  @click="DeleteRecipient()"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions>
          <v-btn
            v-if="AskEmail == false"
            text
            @click="AskEmail = true"
          >
            {{ $t('Add') }}
          </v-btn>
          <v-btn
            v-if="AskEmail == false"
            text
            @click="EditMailList = null"
          >
            {{ $t('Close') }}
          </v-btn>
          <v-text-field
            v-if="AskEmail"
            v-model="NewEmail"
            :label="$t('Settings.MailList.NewRecipientsEmail')"
            type="text"
            dense
            ref="emailLabel"
          />
          <v-btn
            v-if="AskEmail"
            text
            @click="AddRecipient();"
          >
            {{ $t('Settings.MailList.Confirm') }}
          </v-btn>
          <v-btn
            v-if="AskEmail"
            text
            @click="AskEmail = false"
          >
            {{ $t('Settings.MailList.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import importal from '@/api/importal';
import MailList from './gridsources/gsmails';

function isEmail(input) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(input);
}

export default {
  components: {
    ServerSideDataGrid,
  },
  props: {
    refresh: {
      type: Number,
      default: () => (0),
    },
  },
  data: () => ({
    refreshInternal: 0,
    sources: {
      Mails: null,
    },
    EditMailList: null,
    SelectedItem: null,
    RecipentList: null,
    NewEmail: null,
    AskEmail: false,
  }),
  computed: {
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),

    refreshGrid() {
      return this.refresh + this.refreshInternal;
    },
  },
  watch: {
    async tenantId() {
      this.refreshInternal += 1;
    },
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.refreshInternal += 1;
      }
    },
  },
  created() {
    this.sources.Mails = new MailList(this);
  },
  methods: {
    async Edit(data) {
      this.EditMailList = data;
      const temp = await importal.get(`AlertMailListRecipientList?mailListUid=${this.EditMailList.MailListUid}`);
      this.RecipentList = temp.data;
    },
    async DeleteRecipient() {
      await importal.post('AlertMailListRecipientRemove', {
        MailListEmailId: this.RecipentList[this.SelectedItem].MailListEmailId,
      }).then((result) => result.data)
        .then((data) => this.GetList(data));
    },
    async AddRecipient() {
      if (isEmail(this.NewEmail)) {
        await importal.post('AlertMailListRecipientNew', {
          MailListUid: this.EditMailList.MailListUid,
          Email: this.NewEmail,
        }).then((result) => result.data)
          .then((data) => this.GetList(data));
        this.NewEmail = null;
        this.AskEmail = false;
      } else {
        // Shake the label when the email is incorrect
        this.$refs.emailLabel.$el.classList.add('shake-label'); // Use .$el

        // Remove the shaking class after the animation finishes
        setTimeout(() => {
          this.$refs.emailLabel.$el.classList.remove('shake-label'); // Use .$el
        }, 500); // Adjust the timeout to match the animation duration
      }
    },
    GetList(data) {
      this.RecipentList = data;
    },
  },
};
</script>

<style lang="scss" scoped>
  .right-sidebar {
    position: absolute;
    top: 49px;
    right: 10px;
    width: 500px;
    z-index: 9999;
  }
  .shake-label {
    animation: shake 0.5s ease-in-out;
  }

  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    25%, 75% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(10px);
    }
  }
</style>
