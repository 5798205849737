<template>
  <div style="height: Calc(100% + 45px); overflow: auto; padding: 13px;" class="secondary">
    <div>
      <div class="d-flex flex-wrap">
        <div class="mr-3" style="width:130px">
          <v-text-field
            :value="weekOf"
            :label="$t('QualityCheck.QCInspections.WeekOf')"
            readonly
          />
        </div>
        <div style="width:300px">
          <v-select
            :label="$t('QualityCheck.QCInspections.Assignations.Label')"
            v-model="selectedAssignation"
            :items="assignationList"
            item-text="Name"
            item-value="Id"
            v-on:change="getInspections"
          />
        </div>
        <div style="width: Calc(100% - 445px); text-align: end;">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="setShowInspectionGeneration();"
          >
            {{ $t('QualityCheck.QCInspections.InspectionGeneration.Title') }}
          </v-btn>
        </div>
      </div>
    </div>
    <v-expansion-panels
      v-model="inspections"
      multiple
    >
      <v-expansion-panel class="panel" v-for="(inspection,id) in inspections" :key="id">
        <v-expansion-panel-header class="panel-header pr-0">
          <table>
            <tr>
              <td>{{ inspection.client }}</td>
              <td style="text-align: right;padding-right: 7px;">
                {{ inspection.sitesDue }}
                {{ $t('QualityCheck.QCInspections.Header.SitesDue') }}
                ({{ inspection.inspected }} {{ $t('QualityCheck.QCInspections.Header.Inspected') }}) --
                {{ inspection.verified }} {{ $t('QualityCheck.QCInspections.Header.InspectionsVerified') }} --
                {{ inspection.submitted }} {{ $t('QualityCheck.QCInspections.Header.Submitted') }} --
                {{ inspection.incomplete }} {{ $t('QualityCheck.QCInspections.Header.Incomplete') }}
              </td>
            </tr>
          </table>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="panel-content">
          <v-simple-table style="background-color: transparent;border: 1px solid #555;margin-bottom: 11px;" dense="true">
            <template v-slot:default>
              <thead>
                <tr style="background-color: #000;">
                  <th>{{ $t('QualityCheck.QCInspections.Columns.Site') }}</th>
                  <th>{{ $t('QualityCheck.QCInspections.Columns.Period') }}</th>
                  <th>{{ $t('QualityCheck.QCInspections.Columns.Technician') }}</th>
                  <th>{{ $t('QualityCheck.QCInspections.Columns.Analyst') }}</th>
                  <th>{{ $t('QualityCheck.QCInspections.Columns.InspectionResult') }}</th>
                  <th>{{ $t('QualityCheck.QCInspections.Columns.Status') }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(site,siteId) in inspection.sites"
                  :key="siteId"
                  :class="{ highlightedRow : site.result === 'Suspicious' }"
                >
                  <td>{{ site.name }}</td>
                  <td>{{ site.periodStart }} - {{ site.periodEnd }}</td>
                  <td>{{ site.technician }}</td>
                  <td>{{ site.analyst }}</td>
                  <td>{{ site.result }}</td>
                  <td>{{ site.status }}</td>
                  <td>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-1 menuIcon"
                          fab
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>
                            mdi-chevron-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list
                        nav
                        dense
                      >
                        <div>
                          <v-btn plain style="text-transform: none;" v-on:click="displayInspectionSection(site.inspectionId,0)">
                            {{ $t('QualityCheck.QCInspections.Actions.Traces') }}
                          </v-btn>
                        </div>
                        <div>
                          <v-btn plain style="text-transform: none;" v-on:click="displayInspectionSection(site.inspectionId,1)">
                            {{ $t('QualityCheck.QCInspections.Actions.TowerShadow') }}
                          </v-btn>
                        </div>
                        <div>
                          <v-btn plain style="text-transform: none;" v-on:click="displayInspectionSection(site.inspectionId,2)">
                            {{ $t('QualityCheck.QCInspections.Actions.StdWd') }}
                          </v-btn>
                        </div>
                        <div>
                          <v-btn plain style="text-transform: none;" v-on:click="displayInspectionSection(site.inspectionId,3)">
                            {{ $t('QualityCheck.QCInspections.Actions.TurbulenceIntensity') }}
                          </v-btn>
                        </div>
                        <div>
                          <v-btn plain style="text-transform: none;" v-on:click="displayInspectionSection(site.inspectionId,4)">
                            {{ $t('QualityCheck.QCInspections.Actions.Summary') }}
                          </v-btn>
                        </div>
                        <div class="mt-2 mb-2"><hr style="border-color: #555" /></div>
                        <div>
                          <v-btn plain style="text-transform: none;" v-if="!site.inspected">
                            {{ $t('QualityCheck.QCInspections.Actions.Regenerate') }}
                          </v-btn>
                        </div>
                        <div>
                          <v-btn plain style="text-transform: none;" v-if="site.inspected && !site.verified && $auth.roles.includes('Web.QCAnalyst')"
                                 v-on:click="updateInspection(site.inspectionId,'Verify')">
                            {{ $t('QualityCheck.QCInspections.Actions.Verify') }}
                          </v-btn>
                        </div>
                        <div>
                          <v-btn plain style="text-transform: none;" v-if="site.inspected || (!site.verified && $auth.roles.includes('Web.QCAnalyst'))"
                                 v-on:click="updateInspection(site.inspectionId,'Unsubmit')">
                            {{ $t('QualityCheck.QCInspections.Actions.Unsubmit') }}
                          </v-btn>
                        </div>
                        <div>
                          <v-btn plain style="text-transform: none;" v-if="site.verified && $auth.roles.includes('Web.QCAnalyst')"
                                 v-on:click="updateInspection(site.inspectionId,'Unverify')">
                            {{ $t('QualityCheck.QCInspections.Actions.Unverify') }}
                          </v-btn>
                        </div>
                        <div>
                          <v-btn plain style="text-transform: none;" v-if="!site.inspected && $auth.roles.includes('Web.QCAnalyst')"
                                 v-on:click="deleteInspection(site.inspectionId)">
                            {{ $t('QualityCheck.QCInspections.Actions.Delete') }}
                          </v-btn>
                        </div>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="row mt-2">
      <div class="col col-4">
        <v-btn
          v-if="$auth.roles.includes('Web.QCAnalyst')"
          v-bind="attrs"
          v-on="on"
          style="text-transform: none;color:black;font-weight: 600;"
          color='orange lighten-4'
          @click="verifyAllNonSuspicous();"
        >
          {{ $t('QualityCheck.QCInspections.Actions.VerifyAllNonSuspicous') }}
        </v-btn>
      </div>
      <div class="col col-4 text-center">
        <div>{{ $t('QualityCheck.QCInspections.Status.Verified') }} : {{ countVerified }}</div>
        <div>{{ $t('QualityCheck.QCInspections.Status.Submitted') }} : {{ countSubmitted }}</div>
        <div>{{ $t('QualityCheck.QCInspections.Status.Incomplete') }} : {{ countIncomplete }}</div>
      </div>
    </div>
    <ConfirmDialog
        v-model="showDeleteDialog"
        :message="$t('QualityCheck.QCInspections.Delete')"
        :no-cancel="false"
        :title="$t('Warning')"
        @on-accept="confirmDeleteInspection"
      />
      <QCInspectionGenerationModal></QCInspectionGenerationModal>
      <router-view />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import QCInspectionGenerationModal from './QCInspectionGenerationModal.vue';

export default {
  components: {
    ConfirmDialog,
    QCInspectionGenerationModal,
  },
  data() {
    return {
      dialog: true,
      showDeleteDialog: false,
      deleteInspectionId: null,
    };
  },
  computed: {
    ...mapState('qualityCheck/qcInspections', ['weekOf', 'assignationList', 'inspections', 'selectedAssignation', 'countVerified', 'countSubmitted', 'countIncomplete', 'showInspectionGeneration']),
  },
  async created() {
    await this.$store.dispatch('qualityCheck/qcInspections/getWeekOf');
    await this.$store.dispatch('qualityCheck/qcInspections/getAssignationList');
    await this.$store.dispatch('qualityCheck/qcInspections/getInspections', 'Everyone');
  },
  methods: {
    getInspections(assignation) {
      this.$store.dispatch('qualityCheck/qcInspections/getInspections', assignation);
    },
    // eslint-disable-next-line
    async displayInspectionSection(inspectionId, section) {
      await this.$store.dispatch('qualityCheck/qcInspections/setSelectedSection', section);
      this.$router.push({
        name: 'QualityCheck.QCInspections.Details',
        params: {
          id: inspectionId,
        },
      });
    },

    deleteInspection(inspectionId) {
      this.deleteInspectionId = inspectionId;
      this.showDeleteDialog = true;
    },

    async confirmDeleteInspection() {
      await this.updateInspection(this.deleteInspectionId, 'Delete');
    },

    async updateInspection(inspectionId, action) {
      await this.$store.dispatch('qualityCheck/qcInspections/updateInspection', {
        QCInspectionId: inspectionId,
        Action: action,
      });
      await this.$store.dispatch('qualityCheck/qcInspections/getInspections', this.selectedAssignation);
    },
    setShowInspectionGeneration() {
      this.$store.dispatch('qualityCheck/qcInspections/setShowInspectionGeneration', true);
    },
    async verifyAllNonSuspicous() {
      await this.$store.dispatch('qualityCheck/qcInspections/verifyAllNonSuspicous');
      await this.$store.dispatch('qualityCheck/qcInspections/getInspections', this.selectedAssignation);
    },
  },
};
</script>

<style scoped>
.panel {
  border: 1px solid #888;
  margin: -1px;
}
.panel-header {
  margin-top:-5px;
  margin-left: -9px;
  margin-bottom:-5px;
}
.panel-content {
  padding-top: -15px;
}
.menuIcon {
  height: 27px !important;
  width:27px !important;
}
.highlightedRow {
  background-color: wheat;
  color: black;
}
tbody tr:hover {
    background-color: white !important;
    color: black;
  }
</style>
