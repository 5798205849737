<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <UnitDialog
      v-if="unitDialog.show"
      v-models:show="unitDialog.show"
      sensor-type="Sensor"
    />
    <DataGrid
      :grid-source="sources.SensorSource"
      :show-new-button="($auth.roles.includes('Web.Sensor.Create') || $auth.roles.includes('Web.Sensor.Modify')) && logger.PeNumber === null"
      :toolbar-buttons="toolbarButtons"
      :refresh="refresh"
      :row-data-init="rowDataInit"
      @selection-changed="onSensorSelectionChanged"
      @length-changed="onLengthChanged"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import DataGrid from '@/components/ServerSideDataGrid.vue';
import SensorSource from '../gridsources/gssensor';
import UnitDialog from '../units/UnitDialog.vue';

export default {
  name: 'SensorList',
  components: {
    DataGrid,
    UnitDialog,
  },
  props: {
    refresh: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    rowDataInit: null,
    selectedSensor: null,
    toolbarButtons: [],
    unitDialog: {
      show: false,
    },
    sources: {
      SensorSource: null,
    },
  }),
  computed: {
    ...mapState('devices/instruments/units', ['sensors']),
    ...mapState('devices/instruments', ['logger']),
  },
  watch: {
    sensors() {
      if (!this.unitDialog.show) {
        this.rowDataInit = this.sensors;
      }
    },
  },
  created() {
    this.sources.SensorSource = new SensorSource(this, {});
  },
  mounted() {
    this.toolbarButtons = [
      {
        tooltip: i18n.t('Instruments.UnitDialog.UpdateUnit'),
        icon: 'mdi-ruler',
        enabled: false,
        click: () => this.onUpdateUnitClick(),
      },
    ];
  },
  methods: {
    onSensorSelectionChanged(event) {
      this.selectedSensor = event.api.getSelectedNodes();
      this.$emit('sensor-select', this.selectedSensor);
    },
    async onUpdateUnitClick() {
      this.unitDialog.show = true;
      await this.$store.dispatch('devices/instruments/units/sensorList', this.$route.params.id);
    },
    onLengthChanged(length) {
      this.toolbarButtons[0].enabled = length > 0;
    },
  },
};
</script>

<style lang="scss">
</style>
