/* eslint-disable class-methods-use-this */
import i18n from '@/plugins/i18n';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsSensors extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.Site.ConfigurationPeriods.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 100,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.Site.ConfigurationPeriods.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.editSensor(event.data);
            },
          },
        },
        {
          filter: true,
          sortable: true,
          width: 100,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.PeNumber'),
          valueGetter: (params) => `${params.data.PeNumber ? String(params.data.PeNumber).padStart(5, '0') : 'Auto'}`,
        },
        {
          field: 'SerialNumber',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.SerialNumber'),
        },
        {
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.Type'),
          valueGetter: (params) => this.getSensorTypeI18N(params),
        },
        {
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.Model'),
          valueGetter: (params) => params.data.ManufacturerName.concat(' ').concat(params.data.ModelName),
        },
        {
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.Mount'),
          valueGetter: (params) => this.getMount(params),
          cellClass: this.GetMountClass,
        },
        {
          field: 'Height',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.Height'),
          cellClass: this.GetMountClass,
        },
        {
          field: 'Heading',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.HeadingFromTower'),
        },
        {
          field: 'Distance',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.DistanceToTower'),
        },
        {
          field: 'Deadband',
          filter: true,
          sortable: true,
          headerName: this.vue.$t('WindServer.Sites.SiteConfigurations.DeadbandOrientation'),
        },
      ]);
    });
  }

  getSensorTypeI18N(params) {
    if (params.data.Type != null) {
      return i18n.t('Equipments.SensorType.'.concat(params.data.Type));
    }
    return '';
  }

  GetMountClass(params) {
    if (params.data.MountId == null) {
      return 'redBackgroundCell';
    }
    return '';
  }

  getMount(params) {
    if (params.data.MountId != null) {
      if (params.data.MountId === 'Panel' || params.data.MountId === 'Tower') {
        return i18n.t('WindServer.Sites.SiteConfigurations.MountType.'.concat(params.data.MountId));
      }
      let m = '';
      if (params.data.MountId.length < 10) { // else guid
        m = i18n.t('WindServer.Sites.SiteConfigurations.PeNumber')
          .concat(String(params.data.MountId).padStart(5, '0'))
          .concat(' ');
      }
      let value = '';
      value = i18n.t('WindServer.Sites.SiteConfigurations.MountType.Boom')
        .concat(' ')
        .concat(m)
        .concat(params.data.Height)
        .concat('m ');
      if (params.data.Heading) {
        value = value.concat(params.data.Heading)
          .concat('°');
      } else {
        value = value.concat('VERT');
      }
      return value;
    }
    return '';
  }
}
