<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="formData.TenantId"
              :label="$t('Settings.Tenants.TenantId')"
              :rules="[v => !!v || $t('Validation.Required')]"
              :readonly="true"
              :disabled="true"
            />
            <v-text-field
              v-model="formData.Name"
              :label="$t('Settings.Tenants.Name')"
              maxlength="254"
              :rules="[v => !!v || $t('Validation.Required')]"
            />
            <!-- JSG 2023-02-16: Removed validation as the feature is not complete: :rules="[v => !!v || $t('Validation.Required')]" -->
            <v-text-field
              v-model="formData.ClientMnemonic"
              :label="$t('Settings.Tenants.TenantMnemonic')"
              maxlength="5"
            />
            <!-- JSG 2023-02-16: Removed validation as the feature is not complete: :rules="[v => !!v || $t('Validation.Required')]" -->
            <v-select
              ref="projectManagerSelector"
              v-model="formData.ProjectManager"
              :label="$t('Settings.Tenants.ProjectManager')"
              :items="projectManagers"
              item-text="DisplayName"
              item-value="UserId"
              return-object
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        TenantId: null,
      },
      show: false,
    };
  },
  computed: {
    ...mapState('settings/tenants', ['tenant', 'projectManagers']),
  },
  watch: {
    async id() {
      await this.getTenant();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.id && this.id != null) { // Update mode
      await this.getTenant();
    } else { // Create mode
      this.show = true;
      this.formData.TenantId = crypto.randomUUID();
    }
  },
  methods: {
    async getTenant() {
      await this.$store.dispatch('settings/tenants/get', { id: this.id });
      this.formData = JSON.parse(JSON.stringify(this.tenant));
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'Settings.Tenants.List' });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.id) {
          await this.$store.dispatch('settings/tenants/update', this.formData);
        } else {
          await this.$store.dispatch('settings/tenants/create', this.formData);
        }

        this.navigateToList();
      }
    },
  },
};
</script>
