<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <div>
            <v-file-input
              v-model="files"
              small-chips
              show-size
              multiple
              clearable
            >
              <template #selection="{ text, index }">
                <v-chip
                  small
                  text-color="white"
                  color="#295671"
                  close
                  @click:close="remove(index)"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            :disabled="files.length === 0 || files === null"
            @click="submitFile"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  components: {
  },
  props: {
    parentId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      files: [],
      selectedFile: [],
    };
  },
  computed: {
  },
  watch: {
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    this.show = true;
  },
  methods: {

    async submitFile() {
      const formData = new FormData();
      this.files.forEach((file) => {
        formData.append('file', file);
      });
      await this.$store.dispatch('site/siteAttachments/uploadFile', formData);
      this.show = false;
    },

    async navigateToList() {
      this.$router.go(-1);
    },

  },
};
</script>
