<template>
  <span>
    <span>{{ textForm }}</span>
  </span>
</template>

<script>
export default {
  components: {
  },
  data: () => ({
    initialized: false,
    value: null,
    showDialog: false,
    textFieldProps: {
      style: 'font-size: 90%; max-height: 26px;',
      dense: true,
      flat: true,
      solo: true,
      // height: 20,
      // 'max-height': 20,
      'hide-details': true,
      class: 'aggrid-input-in-cell',
    },
  }),
  computed: {
    /*
 {
      "type": "Point",
      "coordinates": [
        -117.2215576171875,
        32.77550506591797
      ]
    }     */
    textForm() {
      if (this.value
          && this.value.type === 'Point') {
        return `${this.value.coordinates[1].toFixed(5)}, ${this.value.coordinates[0].toFixed(5)}`;
      }
      return '';
    },
  },
  beforeMount() {
    this.value = this.params.data.GeoLocation;
  },
  methods: {
    refresh(params) {
      this.params = params;
      this.value = this.params.data.GeoLocation;
    },
    onInput(val) {
      this.params.setValue(val);
    },
    doEdit() {
      this.editValue = this.textForm;
      this.showDialog = true;
    },
  },
};
</script>

<style lang="scss">
  .aggrid-input-in-cell > .v-input__control {
    min-height: 20px !important;
  }
</style>
