<template>
  <div>
    <v-card-title class="px-3 pt-3 pb-0">
      <p class="my-0">
        {{ $t(graphText) }}
      </p>
    </v-card-title>
    <v-card-title class="px-3 pt-0 pb-3 mb-3">
      <p class="my-0">
        {{ $t('Dashboard.VisualizationSetting.VisualizationSetting') }}
      </p>
    </v-card-title>
    <v-card-actions>
      <v-btn
        :disabled="pleaseWait"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        :disabled="pleaseWait"
        @click="save"
      >
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
  },
  props: {
    visualisation: {
      type: String,
      default: () => null,
      required: true,
    },
    graphType: {
      type: String,
      default: () => null,
      required: true,
    },
    graphStyle: {
      type: String,
      default: () => null,
      required: true,
    },
    graphText: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  data: () => ({
    model: {
      widgetId: null,
      enabled: true,
      visible: true,
      axisWidth: 4,
      axisHeight: 200,
      axisX: 0,
      axisY: 0,
      axisLock: false,
    },
    form: {
    },
  }),
  computed: {
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapGetters('app', ['pleaseWait']),

    isNew() {
      return this.activeWidget === undefined;
    },
    jsonWidgetSettings() {
      const query = {
        user: {

        },
      };
      return JSON.stringify(query);
    },
  },
  watch: {
    activeWidget() {
      this.init();
    },
    editModeFlag(val) {
      if (!val) {
        this.clearWidgetSettings();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.clearWidgetSettings();
      if (!this.isNew) {
        this.loadWidgetSettings();
      }
    },
    clearWidgetSettings() {
    },
    loadWidgetSettings() {
      // const widgetSettings = JSON.parse(this.activeWidget.WidgetSettings);
    },
    cancel() {
      this.$emit('cancel', this.isNew);
    },
    save() {
      if (this.isNew) {
        this.create();
      } else {
        this.saveWidgetSettings();
      }
    },
    create() {
      this.$emit('create', {
        DashboardsWidgetId: this.activeWidgetId,
        DatasourceSettings: '', // TODO check if this property is useful, it seems that WidgetSettings do it all
        WidgetSettings: this.jsonWidgetSettings,
        WidgetId: this.model.widgetId,
        Enabled: this.model.enabled,
        Visible: this.model.visible,
        AxisHeight: this.model.axisHeight,
        AxisWidth: this.model.axisWidth,
        AxisY: this.model.axisY,
        AxisX: this.model.axisX,
        AxisLock: this.model.axisLock,
      });
    },
    saveWidgetSettings() {
      this.$emit('save', {
        DashboardsWidgetId: this.activeWidgetId,
        WidgetSettings: this.jsonWidgetSettings,
      });
    },
  },
};
</script>

<style>

</style>
