<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <QCInspectionTemplateFilters />
    <ServerSideDataGrid
      :grid-source="sources.QCInspectionTemplates"
      :show-new-button="$auth.roles.includes('Web.QCInspectionTemplate.Create')"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="qcInspectionTemplates"
      id="lstInspections"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import QCInspectionTemplateList from '../gridsources/gsQCInspectionTemplates';
import QCInspectionTemplateFilters from './QCInspectionTemplateFilters.vue';

export default {
  components: {
    ServerSideDataGrid,
    QCInspectionTemplateFilters,
  },
  data() {
    return {
      sources: {
        QCInspectionTemplates: null,
      },
    };
  },
  computed: {
    ...mapState('qualityCheck/qcInspectionTemplates', ['qcInspectionTemplates']),
  },
  created() {
    this.sources.QCInspectionTemplates = new QCInspectionTemplateList(this);
    this.refreshList();
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'QualityCheck.QCInspectionTemplates.Create' });
    },
    refreshList() {
      this.$store.dispatch('qualityCheck/qcInspectionTemplates/list');
    },
  },
};
</script>
