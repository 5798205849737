/* eslint-disable class-methods-use-this */
import i18n from '@/plugins/i18n';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsLoggerConfigurations extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$t('Edit'),
          width: 80,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.editChannel(event.data);
            },
          },
        },
        {
          field: 'ChannelCode',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Name'),
          width: 90,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Sensor',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Sensor'),
          width: 350,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatValue,
        },
        {
          field: 'Label',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Label'),
          width: 350,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'ChannelSlope',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.ChannelSlope'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'ChannelOffset',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.ChannelOffset'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'UnitOfMeasure',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.UnitOfMeasure'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatValue,
        },
        {
          field: 'Height',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.Height'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'HeadingFromTower',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.HeadingFromTower'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'DistanceToTower',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.DistanceToTower'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'AveragingInterval',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.AveragingInterval'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 80,
          resizable: true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (this.vue.$t('WindServer.Sites.LoggerConfiguration.ChannelColumns.DeleteName', { Name: event.data.ChannelCode })),
            clicked: async (event) => {
              await this.vue.$store.dispatch('site/loggerConfigurations/removeChannel', { id: event.data.ChannelCalibrationId });
            },
          },
        },
      ]);
    });
  }

  formatValue(params) {
    let value = '';
    switch (params.colDef.field) {
      case 'Sensor':
        if (params.data.Sensor) {
          value = params.data.Sensor.ModelName;
          if (params.data.Sensor.PeNumber !== null && params.data.Sensor.PeNumber !== undefined) value = `${value}, PE# ${String(params.data.Sensor.PeNumber).padStart(5, '0')}`;
          if (params.data.Sensor.SerialNumber !== null && params.data.Sensor.SerialNumber !== undefined) value = `${value}, S/N ${params.data.Sensor.SerialNumber}`;
          if (params.data.Sensor.MountId !== null && !params.data.Sensor.IsNew) {
            if (typeof params.data.Sensor.MountId !== 'object') {
              value = `${value}, ${params.data.Sensor.MountId}`;
            } else if (params.data.Sensor.MountId.MountId !== null) value = `${value}, ${params.data.Sensor.MountId.MountId}`;
          }
        } else {
          value = i18n.t('WindServer.Sites.LoggerConfiguration.UnknownSensor');
        }
        return value;
      case 'UnitOfMeasure':
        if (params.data.UnitOfMeasure) {
          let name = params.data.UnitOfMeasure;
          if (name.indexOf('*') >= 0) {
            name = name.substring(0, name.indexOf('*') + 1);
          }
          value = i18n.t('WindServer.Sites.LoggerConfiguration.UnitsOfMeasure.'.concat(name));
        }
        return value;
      default:
        return '';
    }
  }
}
