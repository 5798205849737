<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      id="gridAssignations"
      :grid-source="sources.QCAssignations"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="qcAssignations"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import QCAssignationList from '../gridsources/gsQCAssignations';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        QCAssignations: null,
      },
      toolbarButtons: [],
    };
  },
  computed: {
    ...mapState('qualityCheck/qcSchedules', ['qcAssignations', 'weekOf']),
  },
  watch: {
    weekOf() {
      this.refreshList();
    },
  },
  created() {
    this.sources.QCAssignations = new QCAssignationList(this);
  },
  mounted() {
    this.toolbarButtons = [];

    this.toolbarButtons.push({
      id: 'btnRefreshAssignations',
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'QualityCheck.QCProblemTypes.Create' });
    },
    refreshList() {
      this.$store.dispatch('qualityCheck/qcSchedules/assignationsList');
    },
  },
};
</script>
