<template>
  <div
    ref="container"
    :style="getStyle"
  />
</template>

<script>
import * as monaco from 'monaco-editor';

export default {
  props: {
    value: {
      type: String,
      default: () => '',
    },
    height: {
      type: String,
      default: () => '100%',
    },
    refresh: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    editor: null,
  }),
  computed: {
    getStyle() {
      return `width: 100%; height: ${this.height};`;
    },
  },
  watch: {
    refresh() {
      this.editor.setValue(this.value);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.editor = monaco.editor.create(this.$refs.container, {
        value: this.value,
        language: 'TemplateSetScriptLanguage-1.0',
        parameterHints: true,
        tempresult: [],
      });

      this.editor.onDidChangeModelContent(() => {
        const value = this.editor.getValue();
        if (this.value !== value) {
          this.$emit('input', value);
        }
      });
    });
  },
  beforeDestroy() {
    if (this.editor) this.editor.dispose();
  },
};
</script>

<style>

</style>
