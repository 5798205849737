import api from '@/api/importal';

const state = {
  landOwnerEmail: null,
  landOwnerEmails: [],
  landOwnerId: null,
  emailAlreadyExists: false,
};

const actions = {
  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`LandOwnerEmailsList?landOwnerId=${state.landOwnerId}`);
    commit('landOwnerEmails', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('LandOwnerEmailsCreate', {
      Email: payload.Email,
      LandOwnerId: Number(state.landOwnerId),
    });
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`LandOwnerEmailsGet?id=${Number(payload.emailId)}`);
    commit('landOwnerEmail', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('LandOwnerEmailsUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`LandOwnerEmailsRemove?id=${payload.id}`);
    commit('landOwnerEmail', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async setLandOwnerId({ commit }, payload) {
    commit('landOwnerId', payload);
  },
  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('emailAlreadyExists', false);
    const response = await api.get(`LandOwnerEmailsValidate?id=${payload.LandOwnerEmailId != null ? payload.LandOwnerEmailId : 0}&email=${payload.Email}&landOwnerId=${state.landOwnerId}`);
    if (response.data != null) {
      commit('emailAlreadyExists', true);
    }
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  landOwnerEmails(state, payload) {
    state.landOwnerEmails = payload;
  },
  landOwnerEmail(state, payload) {
    state.landOwnerEmail = payload;
  },
  landOwnerId(state, payload) {
    state.landOwnerId = payload;
  },
  emailAlreadyExists(state, payload) {
    state.emailAlreadyExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
