<template>
  <span>
    <v-btn
      icon
      x-small
      style="vertical-align: baseline;"
      @click="btnClickedHandler()"
    >
      <v-icon>mdi-account-multiple</v-icon>
    </v-btn>
    {{ params.value }}
  </span>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params);
    },
  },
};
</script>
