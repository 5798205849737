<template>
  <v-tab-item
    v-if="$auth.roles.includes('Web.AccessGroup.Manage')"
    style="height: calc(100% - 110px);"
  >
    <v-row style="height: 100%">
      <v-col style="height: 100%; max-width: 50%;">
        <server-side-data-grid
          :grid-source="sources.UserGrantAGSource"
          :show-new-button="false"
          :refresh="refresh"
        />
      </v-col>
      <v-col
        v-if="selection.user"
        style="height: 100%; max-width: 50%;"
      >
        <v-card-title>{{ selection.user.UserName }}</v-card-title>
        <v-row class="pt-2 pb-5">
          <v-col style="max-width: 50%;">
            <v-select
              v-model="selection.roles"
              :label="$t('Settings.AccessControl.GrantRevokeRoles.AddRoles')"
              :items="availableRolesList"
              item-text="RoleName"
              :multiple="true"
              hide-details
              dense
              return-object
            />
          </v-col>
          <v-col>
            <v-btn
              class="mr-auto"
              :disabled="selection.roles.length === 0"
              @click="onGrant()"
            >
              <v-icon class="pr-2">
                mdi-plus
              </v-icon>
              {{ $t('Grant') }}
            </v-btn>
          </v-col>
        </v-row>
        <server-side-data-grid
          :grid-source="sources.RoleGrantAGSource"
          :show-new-button="false"
          :refresh="refresh"
          :row-data-init="selectedUserRoleList"
        />
      </v-col>
    </v-row>
  </v-tab-item>
</template>

<script>
import importal from '@/api/importal';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import UserGrantAGSource from '../gridsources/gsusergrantro';
import RoleGrantAGSource from '../gridsources/gsrolesgrantrole';

export default {
  components: {
    ServerSideDataGrid,
  },
  props: {
    refresh: {
      type: Number,
      default: () => (0),
    },
  },
  data: () => ({
    availableRolesList: [],
    selectedUserRoleList: [],
    sources: {
      UserGrantAGSource: null,
      RoleGrantAGSource: null,
    },
    selection: {
      user: null,
      roles: [],
    },
  }),
  watch: {
    'selection.user': {
      async handler() {
        await this.initSecondPart();
      },
      immediate: true,
    },
    refresh: {
      async handler() {
        await this.init();
        await this.initSecondPart();
      },
      immediate: true,
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      this.sources.UserGrantAGSource = new UserGrantAGSource(this);
      this.sources.RoleGrantAGSource = new RoleGrantAGSource(this);
    },
    async initSecondPart() {
      this.selection.roles = [];
      this.availableRolesList = [];
      this.selectedUserRoleList = [];

      await this.initSelectedUserRoles();
      await this.updateAvailableRoles();
    },
    async initSelectedUserRoles() {
      if (this.selection.user) {
        this.selectedUserRoleList = await (await importal.post('OpUserListRole', {
          UserIds: [this.selection.user.UserId],
        })).data[0].Roles;
      }
    },
    async updateAvailableRoles() {
      if (this.selection.user) {
        this.availableRolesList = await (await importal.get('OpRoleList')).data;

        if (this.selectedUserRoleList.length > 0) {
          this.availableRolesList = this.availableRolesList
            .filter((x) => !this.selectedUserRoleList.some((y) => y.RoleId === x.RoleId));
        }
      }
    },
    async onGrant() {
      const { roles } = this.selection;
      this.selection.roles = [];
      this.selectedUserRoleList = [];

      this.selectedUserRoleList = await (await importal.post('OpUserGrantRole', {
        UserIds: [this.selection.user.UserId],
        RoleIds: roles.map((x) => x.RoleId),
      })).data[0].Roles;

      roles.forEach((x) => this.availableRolesList.splice(this.availableRolesList.indexOf(x), 1));
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
