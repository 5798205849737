<template>
  <div style="width: 100%;height:100%">
    <ServerSideDataGrid
      style="height:100%;"
      :grid-source="sources.Sites"
      :show-new-button="$auth.roles.includes('Web.Site.Create')"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="sites"
    />
    <v-dialog
      v-model="showKml"
      transition="dialog-bottom-transition"
      max-width="350"
    >
      <v-card>
        <h3
          style="
            text-align: center;
            padding: 11px;
            padding-bottom: 35px;
        "
        >
          {{ $t('WindServer.Sites.KML.Export') }}
        </h3>
        <v-divider class="mx-4" />
        <v-btn
          width="350"
          text
          @click="exportKML(1)"
        >
          {{ $t('WindServer.Sites.KML.KmlLabelSiteName') }}
        </v-btn>
        <v-divider class="mx-4" />
        <v-btn
          width="350"
          text
          @click="exportKML(2)"
        >
          {{ $t('WindServer.Sites.KML.KmlLabelTenantAndSiteName') }}
        </v-btn>
        <v-divider class="mx-4" />
        <v-btn
          width="350"
          text
          @click="exportKML(3)"
        >
          {{ $t('WindServer.Sites.KML.KmlLabelSiteNumber') }}
        </v-btn>
        <v-divider class="mx-4" />
        <v-btn
          width="350"
          text
          @click="exportKML(4)"
        >
          {{ $t('WindServer.Sites.KML.KmlLabelTenantAndSiteNumber') }}
        </v-btn>
        <v-divider class="mx-4" />
        <div style="height:29px;" />
      </v-card>
    </v-dialog>
    <router-view />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import SiteList from '../gridsources/gsSites';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        Sites: null,
      },
      toolbarButtons: [],
      showKml: false,
    };
  },
  computed: {
    ...mapState('site/sites', ['sites']),
  },
  created() {
    this.sources.Sites = new SiteList(this);
    this.getFormListsData();
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.Site.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('New'),
        icon: 'mdi-plus',
        enabled: true,
        click: () => { this.createNew(); },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });

    this.toolbarButtons.push({
      tooltip: this.$t('WindServer.Sites.KML.Export'),
      icon: 'mdi-map-marker-multiple-outline',
      enabled: true,
      click: () => { this.showExportKML(); },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'Sites.Create' });
    },
    refreshList() {
      this.$store.dispatch('site/sites/list');
    },
    getFormListsData() {
      this.$store.dispatch('site/sites/getFormListsData');
    },
    showExportKML() {
      this.showKml = true;
    },
    exportKML(option) {
      this.$store.dispatch('site/sites/exportKML', option);
      this.showKml = false;
    },
  },
};
</script>

  <style>
  .ag-invisible
  {
    width: 0px;
    margin-left: -11px;
  }
  </style>
