import SettingList from '../../views/settings/SettingList.vue';
import MailLists from '../../views/settings/MailLists.vue';

export default [
  {
    path: '/settings',
    name: 'Settings',
    component: SettingList,
  },
  {
    path: '/settings/access',
    name: 'Settings.AccessControl',
    component: SettingList,
  },
  {
    path: '/settings/mail',
    name: 'Settings.MailList',
    component: MailLists,
  },
];
