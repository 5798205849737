import { format, addMinutes } from 'date-fns';
import api from '@/api/importal';
import store from '@/store';
import dtz from 'date-fns-tz';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm:ss';
const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.000'Z'";

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  feedInfos: null,
  availableDates: [],
  startReached: false,
};

const getters = {
  initColumnIndex: () => (data) => Object.fromEntries(data.Columns.map((v, i) => [v.ColumnName, i])),
  timestampOptions: () => (images) => images.map((x) => ({ name: format(x.ts, TIME_FORMAT), index: x.index })),
  utcDate: () => (date) => addMinutes(date, date.getTimezoneOffset()),

  localDateFromString: () => (dateString) => {
    const datePart = dateString.split('-');
    return new Date(datePart[0], Number(datePart[1]) - 1, datePart[2]);
  },

  isPreviousDailyInfosNeeded: (state) => (date) => {
    if (state.startReached) {
      return false;
    }

    const firstDate = new Date(state.availableDates[0]);
    const currentDate = new Date(date);

    return firstDate.getFullYear() === currentDate.getFullYear()
      && firstDate.getMonth() === currentDate.getMonth();
  },
};

const actions = {
  async send({ commit }, payload) {
    return withWait({ commit }, () => api.post('IngressDispatchCommentFeed', payload));
  },

  async getDailyInfos({ commit, state, getters }, widgetSettingsData) {
    if (state.startReached) {
      return;
    }

    let endDate;
    if (state.availableDates.length === 0) {
      endDate = new Date();
    } else {
      endDate = getters.localDateFromString(state.availableDates[0]);
      endDate.setDate(endDate.getDate() - 1);
    }

    endDate.setHours(23, 59, 59, 999);
    const startDate = new Date(endDate.getFullYear() - 1,
      endDate.getMonth(), endDate.getDate() + 1);

    const data = await (await api.post('FileFeedQuery', {
      query_type: 'daily',
      feeds: widgetSettingsData.feeds,
      input_filter: [{
        time_range: {
          start: format(getters.utcDate(startDate), DATE_TIME_FORMAT),
          end: format(getters.utcDate(endDate), DATE_TIME_FORMAT),
        },
      }],
      timezone: store.getters['app/timezone'],
    })).data;

    if (!data) {
      return;
    }

    if (data.Rows.length === 0) {
      state.startReached = true;
      return;
    }

    const columnIndex = getters.initColumnIndex(data);
    let availableDates = data.Rows
      .map((x) => format(getters.utcDate(x[columnIndex.ts]), DATE_FORMAT));
    if (availableDates[availableDates.length - 1] === state.availableDates[0]) {
      availableDates.pop();
    }
    availableDates = availableDates.concat(state.availableDates);
    commit('availableDates', availableDates);

    if (state.feedInfos) {
      state.feedInfos.index = availableDates.indexOf(state.feedInfos.date);
    }
  },
  async getFullInfosForDay({
    commit, dispatch, state, getters,
  }, payload) {
    if (getters.isPreviousDailyInfosNeeded(payload.date)) {
      await dispatch('getDailyInfos', payload.widgetSettingsData);
    }

    const startDate = new Date(`${payload.date}T00:00:00.000`);
    const endDate = new Date(`${payload.date}T23:59:59.999`);

    const data = await (await api.post('FileFeedQuery', {
      query_type: 'full',
      feeds: payload.widgetSettingsData.feeds,
      input_filter: [{
        time_range: {
          start: format(getters.utcDate(startDate), DATE_TIME_FORMAT),
          end: format(getters.utcDate(endDate), DATE_TIME_FORMAT),
        },
      }],
      timezone: payload.widgetSettingsData.timezone,
    })).data;

    if (!data || data.Rows.length === 0) {
      return;
    }

    const columnIndex = getters.initColumnIndex(data);
    const infos = [];
    data.Rows.forEach((x) => {
      // Bug in Datehandling
      // Received time : 13h Utc
      // Wrong conversion : 13h GMT-4 (instead of 9h GMT-4)
      // PATCH: Put the time back in Utc and adjust the time depending of the timezone in the dropdown menu
      let timeUtc = dtz.zonedTimeToUtc(x[columnIndex.ts]);
      timeUtc = dtz.toDate(timeUtc);
      const appTimezone = store.getters['app/timezone']; // Portal Timezone
      const timeAppTz = dtz.utcToZonedTime(timeUtc, appTimezone);

      if (x[columnIndex.path]) {
        infos.push({
          index: infos.length,
          ts: timeAppTz,
          feedId: x[columnIndex.feed_id],
          path: x[columnIndex.path],
          mime_type: x[columnIndex.mime_type],
        });
      } else {
        // comment
        infos.push({
          index: infos.length,
          ts: timeAppTz,
          feedId: x[columnIndex.feed_id],
          mime_type: x[columnIndex.mime_type],
          category: x[columnIndex.category],
          access_group_id: x[columnIndex.access_group_id],
          content: x[columnIndex.content],
          user_id: x[columnIndex.user_id],
          user_name: x[columnIndex.user_name],
        });
      }
    });

    const index = state.availableDates.indexOf(payload.date);
    commit('feedInfos', { date: payload.date, index, infos });
  },
};

const mutations = {
  init(state) {
    state.feedInfos = null;
    state.availableDates = [];
    state.startReached = false;
  },
  feedInfos(state, payload) {
    state.feedInfos = payload;
  },
  availableDates(state, payload) {
    state.availableDates = payload;
  },
  startReached(state, payload) {
    state.startReached = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
