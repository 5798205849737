<template>
  <v-card class="search-options flex-shrink-0">
    <v-form ref="form" lazy-validation>
      <v-list class="pt-0">
        <v-subheader class="text-uppercase font-weight-bold" style="height: 32px" v-t="'Customers.Sites.SearchOptions.Filters'" />
        <v-divider />
        <v-list-item>
          <v-text-field
            clearable
            ref="KeywordFilter"
            @change="v => onKeywordsChange(v)"
            @click:clear="onKeywordsChange(null)"
            :label="$t('Customers.Sites.SearchOptions.KeywordFilter')" />
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-select
            clearable
            ref="Tenant"
            :items="tenants"
            item-text="TenantName"
            item-value="TenantId"
            :label="$t('Customers.Sites.SearchOptions.Tenant')"
            @change="v => onTenantChange(v)"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
          />
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-select
            clearable
            ref="Country"
            :items="locations"
            item-text="CountryName"
            item-value="CountryId"
            :label="$t('Customers.Sites.SearchOptions.Country')"
            @change="v => onCountryChange(v)"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
          />
        </v-list-item>
        <v-list-item>
          <v-select
            clearable
            ref="ProvinceState"
            :items="provinceStates"
            item-text="ProvinceStateName"
            item-value="ProvinceStateId"
            :label="$t('Customers.Sites.SearchOptions.ProvinceState')"
            @change="v => onProvinceStateChange(v)"
            :disabled="provinceStates.length === 0"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
          />
        </v-list-item>
        <v-list-item>
          <v-select
            clearable
            ref="Municipality"
            :items="municipalities"
            :label="$t('Customers.Sites.SearchOptions.Municipality')"
            @change="v => onMunicipalityChange(v)"
            :disabled="municipalities.length === 0"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
          />
        </v-list-item>
      </v-list>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      options: { Keywords: '' },
      municipalities: [],
      provinceStates: [],
    };
  },
  computed: {
    ...mapState('customers/sites', ['locations', 'tenants']),
  },
  methods: {
    onCountryChange(value) {
      this.options.CountryId = value;

      this.options.ProvinceStateId = null;
      this.provinceStates = [];
      this.options.Municipality = null;
      this.municipalities = [];

      const country = this.locations.find((x) => x.CountryId === this.options.CountryId);

      if (country) {
        this.provinceStates = country.ProvinceStates;
      }

      this.update();
    },
    onKeywordsChange(value) {
      this.options.Keywords = value;
      this.update();
    },
    onMunicipalityChange(value) {
      this.options.Municipality = value;
      this.update();
    },
    onProvinceStateChange(value) {
      this.options.ProvinceStateId = value;
      this.options.Municipality = null;
      this.municipalities = [];

      const country = this.locations.find((x) => x.CountryId === this.options.CountryId);

      if (country) {
        const provinceState = country.ProvinceStates
          .find((x) => x.ProvinceStateId === this.options.ProvinceStateId);

        if (provinceState) {
          this.municipalities = provinceState.Municipalities;
        }
      }

      this.update();
    },
    onTenantChange(value) {
      this.options.TenantId = value;
      this.update();
    },
    update() {
      this.$emit('update', this.options);
    },
  },
  created() {
    this.$store.dispatch('customers/sites/getFilters');
  },
};
</script>

<style scoped>
  .search-options {
    width: 250px;
  }
</style>
