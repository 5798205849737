import AlertStatus from '@/alert/version3/Status/AlertStatus.vue';

export default [
  {
    path: '/alert/status',
    name: 'Alert.Status',
    component: AlertStatus,
  },
  {
    path: '/alert/status/sensorId/:sensorId',
    name: 'Alert.Status.Sensor',
    component: AlertStatus,
  },
  {
    path: '/alert/status/sensorId/:sensorId/alertId/:alertId',
    name: 'Alert.Status.Alert',
    component: AlertStatus,
  },
];
