import QCInspectionTemplateList from './components/QCInspectionTemplateList.vue';
import QCInspectionTemplateFormModal from './components/QCInspectionTemplateFormModal.vue';
import QCTemplateSectionList from '../qcTemplateSections/components/QCTemplateSectionList.vue';
import QCTemplateSectionFormModal from '../qcTemplateSections/components/QCTemplateSectionFormModal.vue';
import QCSectionMeasurementList from '../qcSectionMeasurements/components/QCSectionMeasurementList.vue';
import QCSectionMeasurementFormModal from '../qcSectionMeasurements/components/QCSectionMeasurementFormModal.vue';
import QCSectionQuestionList from '../qcSectionQuestions/components/QCSectionQuestionList.vue';
import QCSectionQuestionFormModal from '../qcSectionQuestions/components/QCSectionQuestionFormModal.vue';

export default [
  {
    path: '/QualityCheck/QCInspectionTemplates',
    name: 'QualityCheck.QCInspectionTemplates.List',
    component: QCInspectionTemplateList,
    children: [
      {
        path: '/QualityCheck/QCInspectionTemplates/Create',
        name: 'QualityCheck.QCInspectionTemplates.Create',
        component: QCInspectionTemplateFormModal,
      },
      {
        path: '/QualityCheck/QCInspectionTemplates/:id',
        name: 'QualityCheck.QCInspectionTemplates.Update',
        component: QCInspectionTemplateFormModal,
        props: true,
        children: [
          {
            path: '/QualityCheck/QCInspectionTemplates/:id/Sections/',
            name: 'QualityCheck.QCInspectionTemplates.Sections',
            component: QCTemplateSectionList,
          },
          {
            path: '/QualityCheck/QCInspectionTemplates/:id/Sections/Create',
            name: 'QualityCheck.QCInspectionTemplates.Sections.Create',
            component: QCTemplateSectionFormModal,
          },
          {
            path: '/QualityCheck/QCInspectionTemplates/:id/Sections/:sectionId',
            name: 'QualityCheck.QCInspectionTemplates.Sections.Update',
            component: QCTemplateSectionFormModal,
            props: true,
            children: [
              {
                path: '/QualityCheck/QCInspectionTemplates/:id/Sections/:sectionId/Measurements',
                name: 'QualityCheck.QCInspectionTemplates.Sections.Measurements',
                component: QCSectionMeasurementList,
              },
              {
                path: '/QualityCheck/QCInspectionTemplates/:id/Sections/:sectionId/Measurements/Create',
                name: 'QualityCheck.QCInspectionTemplates.Sections.Measurements.Create',
                component: QCSectionMeasurementFormModal,
              },
              {
                path: '/QualityCheck/QCInspectionTemplates/:id/Sections/:sectionId/Measurements/:measurementId',
                name: 'QualityCheck.QCInspectionTemplates.Sections.Measurements.Update',
                component: QCSectionMeasurementFormModal,
                props: true,
              },
              {
                path: '/QualityCheck/QCInspectionTemplates/:id/Sections/:sectionId/Questions',
                name: 'QualityCheck.QCInspectionTemplates.Sections.Questions',
                component: QCSectionQuestionList,
              },
              {
                path: '/QualityCheck/QCInspectionTemplates/:id/Sections/:sectionId/Questions/Create',
                name: 'QualityCheck.QCInspectionTemplates.Sections.Questions.Create',
                component: QCSectionQuestionFormModal,
              },
              {
                path: '/QualityCheck/QCInspectionTemplates/:id/Sections/:sectionId/Questions/:questionId',
                name: 'QualityCheck.QCInspectionTemplates.Sections.Questions.Update',
                component: QCSectionQuestionFormModal,
                props: true,
              },
            ],
          },
        ],
      },
    ],
  },
];
