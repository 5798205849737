/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import i18n from '@/plugins/i18n';
import importal from '@/api/importal';
import datehandling from '@/components/datehandling';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class VectorIngressSource extends ServerSideDataGridGsClientBase {
  constructor(vue, cb) {
    super();
    this.vue = vue;
    this.callback = cb;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    const SensorAttributeList = await importal.get('DevGatewaySensorAttributeNameList')
      .then((result) => result.data);
    return new Promise((resolve) => {
      resolve([
        {
          field: 'VectorSensorId',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.VectorSensorId'),
          width: 120,
          resizable: true,
          hide: this.vue.readmode === true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (`vector "${event.data.InputInstrumentName}"`),
            clicked: (event) => {
              this.apiDevGatewayIngressVectorRemove(event.api, event.data);
            },
          },
        },
        {
          field: 'InputInstrumentName',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.InputInstrumentName'),
          width: 180,
          editable: true,
        },
        {
          field: 'VectorizationName',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.VectorizationName'),
          editable: true,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: SensorAttributeList,
          },
          width: 180,
        },
        {
          field: 'VectorizationAxis',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.VectorizationAxis'),
          editable: true,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: SensorAttributeList,
          },
          width: 180,
        },
        {
          field: 'OutputInstrumentName',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.OutputInstrumentName'),
          width: 180,
          cellRenderer: 'EditButtonCellRenderer',
          hide: this.vue.readmode === true,
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.callback.EditOutputVector(event.api, event.data);
            },
          },
        },
        {
          field: 'LastSeenTs',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.LastSeenTs'),
          width: 200,
          valueFormatter: (params) => datehandling.formatForDisplay(params.value),
        },
      ]);
    });
  }

  onChange() {
    const { callback } = this;
    return {
      InputInstrumentName(event, successCallback, failCallback) {
        importal.post('DevGatewayIngressVectorRename',
          {
            DataGatewayId: event.data.DataGatewayId,
            InstrumentName: event.oldValue,
            NewInstrumentName: event.newValue,
          })
          .then((resp) => {
            callback.cbDevGatewayIngressVectorList(resp.data);
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
      VectorizationName(event, successCallback, failCallback) {
        importal.post('DevGatewayIngressVectorUpdate', {
          DataGatewayId: event.data.DataGatewayId,
          InstrumentName: event.data.InputInstrumentName,
          InstrumentId: event.data.InstrumentId,
          VectorizationType: event.data.VectorizationType,
          VectorizationName: event.data.VectorizationName,
          VectorizationAxis: event.data.VectorizationAxis,
        })
          .then((resp) => {
            callback.cbDevGatewayIngressVectorList(resp.data);
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
      VectorizationAxis(event, successCallback, failCallback) {
        importal.post('DevGatewayIngressVectorUpdate', {
          DataGatewayId: event.data.DataGatewayId,
          InstrumentName: event.data.InputInstrumentName,
          InstrumentId: event.data.InstrumentId,
          VectorizationType: event.data.VectorizationType,
          VectorizationName: event.data.VectorizationName,
          VectorizationAxis: event.data.VectorizationAxis,
        })
          .then((resp) => {
            callback.cbDevGatewayIngressVectorList(resp.data);
            successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            failCallback();
          });
      },
    };
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue, callback } = this;
    return {
      getRows: function GetRows(params) {
        importal.get(`DevGatewayIngressVectorList?DataGatewayId=${vue.$route.params.id}`)
          .then((resp) => {
            callback.cbDevGatewayIngressVectorList(resp.data);
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve({
        Model: {
          DataGatewayId: vue.$route.params.id,
          InstrumentName: null,
        },
        Schema: {
          DataGatewayId: { label: i18n.t('Devices.Gateway.AddNew.GatewayId'), type: 'text', disabled: true },
          InstrumentName: {
            label: i18n.t('Devices.Gateway.AddNew.InstrumentName'), type: 'text', flex: 12,
          },
        },
      });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    const { callback } = this;
    importal
      .post('DevGatewayIngressVectorNew', {
        DataGatewayId: data.DataGatewayId,
        InstrumentName: data.InstrumentName,
      })
      .then((resp) => {
        callback.cbDevGatewayIngressVectorList(resp.data);
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async apiDevGatewayIngressVectorRemove(api, data) {
    const { callback } = this;
    return importal
      .post('DevGatewayIngressVectorRemove', {
        DataGatewayId: data.DataGatewayId,
        InstrumentName: data.InputInstrumentName,
      })
      .then((resp) => {
        callback.cbDevGatewayIngressSensorList(resp.data);
        api.setRowData(resp.data);
      });
  }
}
