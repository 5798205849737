export default [
  {
    isWindServer: true,
    label: 'Equipments.Title',
    icon: 'mdi-wrench',
    title: 'Equipments.Title',
    items: [
      {
        label: 'Equipments.Models.Title',
        to: { name: 'Equipment.Models.List' },
        title: 'Equipments.Models.Title',
      },
      {
        label: 'Equipments.Equipments.Title',
        to: { name: 'Equipment.Equipments.List' },
        title: 'Equipments.Equipments.Title',
      },
      {
        label: 'Equipments.PENumbers.Title',
        to: { name: 'Equipment.PENumbers.List' },
        title: 'Equipments.PENumbers.Title',
      },
      {
        label: 'Equipments.AnchorTypes.Title',
        to: { name: 'Equipment.AnchorTypes.List' },
        title: 'Equipments.AnchorTypes.Title',
      },
      {
        label: 'Equipments.CellularAccountProviders.Label',
        to: { name: 'Equipment.CellularAccountProviders.List' },
        title: 'Equipments.CellularAccountProviders.Title',
      },
    ],
  },
];
