<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="formData.Email"
              :label="$t('Settings.LandOwnerEmails.Columns.Email')"
              :rules="emailRules"
              :error-messages="errorMessage"
              @input="cleanError"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  props: {
    emailId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      emailRules: {
      },
      errorMessage: '',
      readMode: !this.$auth.roles.includes('Web.LandOwner.Manage'),
    };
  },
  computed: {
    ...mapState('landOwner/landOwnerEmails', ['landOwnerEmail', 'emailAlreadyExists']),
    ...mapState('landOwner/landOwners', ['landOwner']),
  },
  watch: {
    async emailId() {
      await this.getLandOwnerEmail();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.emailId && this.emailId != null) {
      await this.getLandOwnerEmail();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    isValidEmail(email) {
      if (email) {
        const validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return email.match(validRegex);
      }
      return false;
    },
    async getLandOwnerEmail() {
      await this.$store.dispatch('landOwner/landOwnerEmails/get', { emailId: this.emailId });
      this.formData = this.$clone(this.landOwnerEmail);
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'LandOwners.Update' });
    },
    async submit() {
      this.emailRules = [(v) => !!v || this.$t('Validation.Required'),
        (v) => (this.isValidEmail(v) || this.$t('Validation.InvalidEmail'))];
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (await this.validateEmail()) {
            if (this.emailId) {
              await this.$store.dispatch('landOwner/landOwnerEmails/update', this.formData);
            } else {
              await this.$store.dispatch('landOwner/landOwnerEmails/create', this.formData);
            }

            this.navigateToList();
          }
        }
      });
    },
    async validateEmail() {
      await this.$store.dispatch('landOwner/landOwnerEmails/validate', this.formData);
      if (this.emailAlreadyExists) {
        this.errorMessage = i18n.t('Settings.LandOwnerEmails.EmailExist');
      } else {
        this.errorMessage = '';
        return true;
      }
      return false;
    },
    cleanError() {
      this.errorMessage = '';
    },
  },
};
</script>
