<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.equipmentModels"
      :show-new-button="false"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="equipmentModels"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import ModelList from '../gridsources/gsmodels';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        equipmentModels: null,
      },
      toolbarButtons: [],
      showConfirm: false,
      editModel: null,
    };
  },
  computed: {
    ...mapState('equipment/models', ['equipmentModels']),
    ...mapState('equipments', ['sensorType']),
  },
  created() {
    this.sources.equipmentModels = new ModelList(this);
    this.refreshList();
    // this.sources.equipmentModels = new ModelList(this, {
    //  onEdit: (api, data) => this.onEdit(api, data),
    //  onDisplay: (api, data) => this.onDisplay(api, data),
    // });
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.Equipment.Model.Create')) {
      this.toolbarButtons = [
        {
          tooltip: this.$t('New'),
          icon: 'mdi-plus',
          enabled: true,
          click: () => { this.createNew(); },
        },
      ];
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    refreshList() {
      this.$store.dispatch('equipment/models/list');
    },
    createNew() {
      this.$router.push({ name: 'Equipment.Models.Create' });
    },
  },
};
</script>
