<template>
  <div>
    <v-menu
      v-model="show"
      :close-on-content-click="false"
      offset-y
    >
      <template #activator="{ on: onMenu, attrs: attrsMenu }">
        <v-tooltip
          bottom
          :disabled="show || !model"
        >
          {{ model ? model[itemText] : '' }}
          <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
            <v-text-field
              :value="model ? model[itemText] : ''"
              :label="label"
              append-icon="mdi-menu-down"
              :error="error"
              :disabled="disabled"
              readonly
              hide-details
              class="mx-2"
              :style="{ width: width }"
              v-bind="{ ...attrsMenu, ...attrsTooltip }"
              v-on="{ ...onMenu, ...onTooltip }"
            />
          </template>
        </v-tooltip>
      </template>
      <v-list dense class="dropdown-list">
        <v-list-item-group v-model="modelIndex">
          <v-list-item :value="0">
            <search-text-field
              :value="searchValue"
              :tooltip="$t('Analytics.SensorSelection.Hint')"
              :on-searched="onSearched"
            />
          </v-list-item>
          <v-list-item v-if="!items.length" :value="-1">
            <v-list-item-subtitle>{{ $t('NoData') }}</v-list-item-subtitle>
          </v-list-item>
          <template v-else>
            <v-tooltip
              v-for="(item, index) in items"
              :key="index"
              bottom
              :disabled="!item[itemText]"
            >
              {{ item[itemText] }}
              <template #activator="{ on }">
                <v-list-item
                  v-on="on"
                  :value="index + 1"
                  :active-color="modelIndex === index + 1"
                  @click="model = item"
                >
                  <v-list-item-title>
                    {{ item[itemText] || '' }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-tooltip>
            <v-list-item v-if="hasMoreItems" :value="items.length + 1">
              <v-list-item-subtitle>{{ $t('More') }}</v-list-item-subtitle>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import SearchTextField from './SearchTextField.vue';

export default {
  name: 'SelectWithSearch',
  components: {
    SearchTextField,
  },
  props: {
    value: {
      default: undefined,
    },
    searchValue: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    hasMoreItems: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '200px',
    },
    error: {
      type: Boolean,
      default: false,
    },
    onChanged: {
      type: Function,
      default: () => {},
    },
    onSearched: {
      type: Function,
      default: () => {},
    },
    onMore: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      model: null,
      modelIndex: null,
      searchModel: null,
    };
  },
  created() {
    this.model = this.value;
    this.modelIndex = this.getModelIndex();
  },
  watch: {
    show(newVal) {
      if (!newVal && (!this.modelIndex || this.modelIndex === 0)) {
        this.modelIndex = this.getModelIndex();
      }
    },
    async modelIndex(newVal) {
      if (newVal && this.hasMoreItems && newVal === this.items.length + 1) {
        this.modelIndex = undefined;
        await this.onMore();
      }
    },
    model() {
      this.modelIndex = this.getModelIndex();

      if (this.model !== this.value) {
        this.onChanged(this.model);
        this.show = false;
      }
    },
    value() {
      this.model = this.value;
    },
  },
  methods: {
    getModelIndex() {
      return this.model ? this.items.indexOf(this.model) + 1 : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-selected {
  color: white;
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: thin;
}
.dropdown-unselected {
  color: lightgray;
  border-bottom-color: lightgray;
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.dropdown-disabled {
  color: lighten( lightgray, 30%);
  border-bottom-color: lighten(lightgray, 30%);
  border-bottom-style: dashed;
  border-bottom-width: thin;
}

.dropdown-list {
  max-height: 400px;
  overflow: auto;
}
</style>
