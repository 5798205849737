<template>
  <DateTimePicker
    v-model="selectedDateTime"
    :text-field-props="textFieldProps"
    :has-clear-button="false"
    :disabled="!isEditable(params)"
    @input="onInput"
  />
</template>

<script>
import DateTimePicker from './DatetimePicker.vue';

export default {
  components: {
    DateTimePicker,
  },
  data: () => ({
    initialized: false,
    selectedDateTime: null,
    textFieldProps: {
      style: 'font-size: 90%; max-height: 26px;',
      dense: true,
      flat: true,
      solo: true,
      // height: 20,
      // 'max-height': 20,
      'hide-details': true,
      class: 'aggrid-input-in-cell',
    },
  }),
  beforeMount() {
    this.selectedDateTime = this.params.value;
  },
  methods: {
    isEditable(params) {
      if (typeof params.editable === 'function') {
        return params.editable(params);
      }

      return params.editable;
    },
    refresh(params) {
      this.params = params;
      this.selectedDateTime = this.params.value;
    },
    onInput(val) {
      this.params.setValue(val);
    },
  },
};
</script>

<style lang="scss">
  .aggrid-input-in-cell > .v-input__control {
    min-height: 20px !important;
  }
</style>
