<template>
  <v-dialog
    v-model="showDialog"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card
      v-if="editorActive"
    >
      <v-card-title>{{ $t('Title') }}</v-card-title>
      <v-card-text>
        {{ $t('Description') }}
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="NameTemplate"
          label="Name Template"
          type="text"
          dense
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="showDialog=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          text
          :disabled="onAcceptDisabled"
          @click.stop="onAccept"
        >
          {{ $t('Accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'NameTemplate', event: 'models:NameTemplate' },
  ],
  components: {
  },
  data: () => ({
    show: false,
    showDialog: false,

    NameTemplate: null,
  }),
  computed: {
    editorActive() {
      return this.show;
    },
    onAcceptDisabled() {
      return !this.NameTemplate || this.NameTemplate.length === 0;
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
    showDialog(value) {
      if (!value) {
        this.show = false;
      }
    },

    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) { this.$emit('models:show', newVal); },
    NameTemplate: function w(newVal) { this.$emit('models:NameTemplate', newVal); },
  },
  created() {
  },
  methods: {
    onAccept() {
      this.showDialog = false;
      this.$emit('on-accept', {
        NameTemplate: this.NameTemplate,
      });
    },
  },
  i18n: {
    messages: {
      en: {
        Title: 'Template for the output instrument name. See DSN006.1 for details.',
        Description: 'The name of the instrument. Use {Name} to replace a parameter from the principal instrument match with parameter Name.',
      },
      fr: {
        Title: 'Gabarit du nom de l\'instrument de sortie. Voir DSN006.1 pour les details.',
        Description: 'Le nom de l\'instrument. Utilisez {Name} pour remplacer un paramètre de la correspondance d\'instrument principal par le paramètre Name.',
      },
    },
  },
};
</script>

<style>

</style>
