<template>
  <v-dialog
    v-model="form.show"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>Aggregation control.</v-card-title>
      <v-subheader class="ma-0">
        {{ $t('InputAggregation') }}
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-row>
          <v-col sm="5">
            <v-text-field
              v-model="form.InputMultiplier"
              :label="$t('AggregateBy')"
              type="number"
              dense
            />
          </v-col>
          <v-col sm="6">
            <v-select
              v-model="form.InputForEvery"
              :items="AggregateForEveryItems"
              label=" "
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-text-field
              v-model="form.InputOffset"
              :label="$t('OffsetBy')"
              type="number"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-subheader class="ma-0">
        {{ $t('OutputAggregation') }}
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-row>
          <v-col sm="5">
            <v-text-field
              v-model="form.OutputMultiplier"
              :label="$t('AggregateBy')"
              type="number"
              dense
            />
          </v-col>
          <v-col sm="6">
            <v-select
              v-model="form.OutputForEvery"
              :items="AggregateForEveryItems"
              label=" "
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-text-field
              v-model="form.OutputOffset"
              :label="$t('OffsetBy')"
              type="number"
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="onAccept"
        >
          {{ $t('Save') }}
        </v-btn>
        <v-btn
          text
          @click.stop="form.show=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    attributes: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    form: {
      show: false,
      InputMultiplier: 1,
      InputForEvery: 'Do not agregate',
      InputOffset: 0,
      OutputMultiplier: 1,
      OutputForEvery: 'Do not agregate',
      OutputOffset: 0,
    },
    AggregateForEveryItems: ['DoNotAgregate', 'Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],
    AggregateForEvery2Query: ['NONE', 'YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'],
  }),
  watch: {
    value(val) {
      this.form.show = val;
      if (this.form.show) {
        this.initialize();
      }
    },
    'form.show': function show() {
      if (!this.form.show) {
        this.$emit('input', this.form.show);
      }
    },
  },
  i18n: {
    messages: {
      en: {
        AggregateBy: 'Aggregate by',
        InputAggregation: 'Input Aggregation',
        Cancel: 'Cancel',
        Save: 'Save',
        OutputAggregation: 'Output Aggregation',
        OffsetBy: 'Offset by',
        DoNotAgregate: 'Do not agregate',
      },
      fr: {
        AggregateBy: 'Agrégé par',
        InputAggregation: 'Agrégation entrée',
        Cancel: 'Annuler',
        Save: 'Enregistrer',
        OutputAggregation: 'Agrégation de sortie',
        OffsetBy: 'Décalé par',
        DoNotAgregate: 'Ne pas agréger',
      },
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.attributes) {
        this.form.InputForEvery = this.AggregateForEveryItems[this.AggregateForEvery2Query.indexOf(this.attributes.find((x) => x.Name === 'input_time_axis.round_to.scale')?.ValueStr ?? 'NONE')];
        this.form.InputMultiplier = this.attributes.find((x) => x.Name === 'input_time_axis.round_to.divisor')?.ValueInt
          ?? this.attributes.find((x) => x.Name === 'input_time_axis.round_to.multiplier')?.ValueInt
          ?? 1;
        this.form.InputOffset = this.attributes.find((x) => x.Name === 'input_time_axis.round_to.offset')?.ValueInt
          ?? 0;
        this.form.OutputForEvery = this.AggregateForEveryItems[this.AggregateForEvery2Query.indexOf(this.attributes.find((x) => x.Name === 'output_time_axis.round_to.scale')?.ValueStr ?? 'NONE')];
        this.form.OutputMultiplier = this.attributes.find((x) => x.Name === 'output_time_axis.round_to.divisor')?.ValueInt
          ?? this.attributes.find((x) => x.Name === 'output_time_axis.round_to.multiplier')?.ValueInt
          ?? 1;
        this.form.OutputOffset = this.attributes.find((x) => x.Name === 'output_time_axis.round_to.offset')?.ValueInt
          ?? 0;
      }
    },
    onAccept() {
      this.$emit('on-accept', {
        InputMultiplier: this.form.InputMultiplier,
        InputForEvery: this.AggregateForEvery2Query[this.AggregateForEveryItems.indexOf(this.form.InputForEvery)],
        InputOffset: this.form.InputOffset,
        OutputMultiplier: this.form.OutputMultiplier,
        OutputForEvery: this.AggregateForEvery2Query[this.AggregateForEveryItems.indexOf(this.form.OutputForEvery)],
        OutputOffset: this.form.OutputOffset,
      });
    },
  },
};
</script>

<style>

</style>
