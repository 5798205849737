<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <datetime-picker
              ref="dtStart"
              v-model="formData.StartDate"
              :label="$t('WindServer.Sites.SiteConfigurations.StartingDate')"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              :has-clear-button="false"
              :rules="[dateIsValid]"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import DatetimePicker from '@/components/DatetimePicker.vue';
import i18n from '@/plugins/i18n';

const DEFAULT_DATE_FORMAT = 'yyyyMMdd';
const DEFAULT_TIME_FORMAT = 'HHmmss';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  computed: {
    ...mapState('site/siteConfigurations', ['siteConfiguration', 'siteId']),
    ...mapState('site/sites', ['site']),
    dateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT}${DEFAULT_TIME_FORMAT}`;
    },
  },
  watch: {
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    this.formData.StartDate = new Date();
    this.show = true;
  },
  methods: {
    async navigateToList() {
      this.$router.push({ name: 'Sites.SiteConfigurations' });
    },

    async submit() {
      if (this.$refs.form.validate()) {
        const dtFormated = this.formData.StartDate ? format(new Date(this.formData.StartDate.toISOString().slice(0, -1)), this.dateTimeFormat) : '';
        this.$router.push(
          {
            name: 'Sites.SiteConfigurations.CreateModel',
            params:
            {
              id: this.siteId,
              startdate: dtFormated,
            },
          },
        );
      }
    },
    dateIsValid(values) {
      if (!values) {
        return this.$t('Validation.Required');
      }
      if (Date.parse(values) < this.site.InstallationDate
        || (Boolean(this.site.RetirementDate) && Date.parse(values) > this.site.RetirementDate)) {
        this.$refs.dtStart.display = false;
        this.$refs.dtStart.showDatePicker();
        return this.$t('WindServer.Sites.SiteConfigurations.InvalidStartingDate');
      }
      return true;
    },
  },
};
</script>
