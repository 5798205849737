import models from './models/models.store';
import equipments from './equipments/equipments.store';
import penumbers from './penumbers/penumbers.store';
import anchortypes from './anchortypes/anchortypes.store';
import cellularaccountproviders from './cellularaccountproviders/cellularaccountproviders.store';

export default {
  namespaced: true,
  modules: {
    models,
    equipments,
    penumbers,
    anchortypes,
    cellularaccountproviders,
  },
};
