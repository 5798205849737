/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class gsSensors extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'Name',
          headerName: this.vue.$t('WindServer.Sites.DataTrace.SensorName'),
          editable: false,
          filter: true,
          sortable: true,
          width: 400,
        },
        {
          field: 'Instrument.Name',
          headerName: this.vue.$t('WindServer.Sites.DataTrace.InstrumentName'),
          editable: false,
          filter: true,
          sortable: true,
        },
      ]);
    });
  }
}
