<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <datetime-picker
              v-model="formData.StartingTimestamp"
              :label="$t('WindServer.Sites.LoggerConfiguration.TimeAdjustments.StartingTimestamp')"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              :has-clear-button="false"
              :rules="requiredRule"
            />
            <datetime-picker
              v-model="formData.EndingTimestamp"
              :label="$t('WindServer.Sites.LoggerConfiguration.TimeAdjustments.EndingTimestamp')"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              :has-clear-button="false"
              :rules="requiredRule"
            />
            <v-text-field
              v-model="formData.Adjustment"
              :label="$t('WindServer.Sites.LoggerConfiguration.TimeAdjustments.Adjustment')"
              :rules="numberRule"
              type="number"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import DatetimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      numberRule: [(v) => (!Number.isNaN(parseFloat(v))) || this.$t('Validation.Required')],
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['timeAdjustment']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$store.dispatch('site/loggerConfigurations/timeAdjustment', null);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async setFormData() {
      if (this.timeAdjustment) {
        this.formData = JSON.parse(JSON.stringify(this.timeAdjustment));
        this.formData.StartingTimestamp = this.timeAdjustment.StartingTimestamp;
        this.formData.EndingTimestamp = this.timeAdjustment.EndingTimestamp;
      } else { // Create mode
        this.formData = {
          DataFileImportSettingId: this.id,
          StartingTimestamp: new Date(),
          EndingTimestamp: new Date(),
        };
      }
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          this.formData.Adjustment = Number(this.formData.Adjustment);
          if (this.timeAdjustment) {
            await this.$store.dispatch('site/loggerConfigurations/updateTimeAdjustment', this.formData);
          } else {
            await this.$store.dispatch('site/loggerConfigurations/createTimeAdjustment', this.formData);
          }
          this.close();
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/loggerConfigurations/timeAdjustment', null);
      this.$refs.form.reset();
    },
  },
};
</script>
