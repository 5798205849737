<template>
  <div style="height: Calc(100% - 30px); width: 100%">
    <ServerSideDataGrid
      ref="grid1"
      :grid-source="sources.SiteFiles"
      :show-new-button="$auth.roles.includes('Web.Site.Create')"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="selectedFolderFiles"
      :enable-selection="'multiple'"
    />
    <ConfirmDialog
      v-model="dialog.Show"
      :message="dialog.Message"
      :no-cancel="dialog.NoCancel"
      :title="dialog.Title"
      @on-accept="onAccept()"
    />
    <router-view />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import SiteFileList from '../gridsources/gsFiles';

const Actions = {
  Remove: 'remove',
  DownloadFolder: 'downloadFolder',
  NoAction: 'noAction',
};

export default {
  components: {
    ServerSideDataGrid,
    ConfirmDialog,
  },
  data() {
    return {
      sources: {
        SiteFiles: null,
      },
      toolbarButtons: [],
      dialog: {
        Action: '',
        Message: '',
        NoCancel: false,
        Title: '',
        Show: false,
        acceptCallback: null,
      },
      deleteMode: null,
    };
  },
  computed: {
    ...mapState('site/siteAttachments', ['selectedFolderFiles', 'selectedFolderId', 'selectedFolderName']),
  },
  created() {
    this.sources.SiteFiles = new SiteFileList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.Site.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('WindServer.Sites.Attachments.AddFolder'),
        icon: 'mdi-folder-plus-outline',
        enabled: true,
        click: async () => {
          if (this.selectedFolderId != null) {
            this.$router.push({ name: 'Customers.Attachments.Create' });
          }
        },
      });
    }

    // *****************************************************************
    // To be reactivated if "rename folder" would be required.
    // if (this.$auth.roles.includes('Web.Site.Manage')) {
    //   this.toolbarButtons.push({
    //     tooltip: this.$t('WindServer.Sites.Attachments.RenameFolder'),
    //     icon: 'mdi-folder-edit-outline',
    //     enabled: true,
    //     click: async () => {
    //       if (this.selectedFolderId != null) {
    //         this.$router.push({ name: 'Customers.Attachments.Rename' });
    //       }
    //     },
    //   });
    // }
    // *****************************************************************

    if (this.$auth.roles.includes('Web.Site.Delete')) {
      this.toolbarButtons.push({
        tooltip: this.$t('WindServer.Sites.Attachments.DeleteFolder'),
        icon: 'mdi-folder-remove-outline',
        enabled: true,
        click: () => {
          this.deleteMode = 'folder';
          this.openDeleteConfirmation();
        },
      });
    }

    if (this.$auth.roles.includes('Web.Site.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('WindServer.Sites.Attachments.AddFile'),
        icon: 'mdi-plus',
        enabled: true,
        click: async () => {
          if (this.selectedFolderId != null) {
            this.$router.push({ name: 'Customers.Attachments.Upload' });
          }
        },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });

    if (this.$auth.roles.includes('Web.Site.Delete')) {
      this.toolbarButtons.push({
        tooltip: this.$t('WindServer.Sites.Attachments.DeleteSelectedFiles'),
        icon: 'mdi-delete-forever',
        enabled: true,
        click: () => {
          this.deleteMode = 'files';
          this.openDeleteConfirmation();
        },
      });
    }
    this.toolbarButtons.push({
      tooltip: this.$t('WindServer.Sites.Attachments.DownloadSelectedFolder'),
      icon: 'mdi-download',
      enabled: true,
      click: () => {
        this.openDownloadConfirmation();
      },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'Customers.Attachments.Create' });
    },
    refreshList() {
      this.$store.dispatch('site/siteAttachments/list');
    },
    openDeleteConfirmation() {
      if (this.deleteMode === 'folder' && this.selectedFolderId != null && this.selectedFolderId !== 0) {
        this.dialog.Title = this.$t('ConfirmDeletion');
        this.dialog.Message = this.$t('WindServer.Sites.Attachments.DeleteConfirmation');
        this.dialog.Show = true;
      } else if (this.deleteMode === 'files' && this.$refs.grid1.gridApi.getSelectedRows().length > 0) {
        this.dialog.Title = this.$t('ConfirmDeletion');
        this.dialog.Message = this.$t('WindServer.Sites.Attachments.DeleteMultipleFilesConfirmation');
        this.dialog.Show = true;
      }
      this.dialog.NoCancel = false;
      this.dialog.Action = Actions.Remove;
    },
    openDownloadConfirmation() {
      if (this.selectedFolderId != null) {
        this.dialog.Title = this.$t('ConfirmDownload');
        this.dialog.Message = this.$t('WindServer.Sites.Attachments.DownloadConfirmation');
        this.dialog.Show = true;
        this.dialog.NoCancel = false;
        this.dialog.Action = Actions.DownloadFolder;
      }
    },
    async onAccept() {
      switch (this.dialog.Action) {
        case Actions.DownloadFolder:
          this.downloadFolder();
          break;

        case Actions.Remove:
          this.remove();
          break;

        case Actions.NoAction:
        default:
          break;
      }
    },
    async remove() {
      if (this.deleteMode === 'folder') {
        await this.$store.dispatch('site/siteAttachments/removeFolder', this.selectedFolderId);
        this.deleteMode = null;
      } else if (this.deleteMode === 'files') {
        const selectedFiles = this.$refs.grid1.gridApi.getSelectedRows();
        const selectedFilesIds = [];
        selectedFiles.forEach((element) => {
          selectedFilesIds.push(element.SiteAttachmentId);
        });
        await this.$store.dispatch('site/siteAttachments/removeMultipleFiles', selectedFilesIds);
        this.deleteMode = '';
      }
    },

    async downloadFolder() {
      await this.$store.dispatch('site/siteAttachments/downloadFolder');
      this.dialog.Show = true;
      this.dialog.NoCancel = true;
      this.dialog.Action = Actions.NoAction;
      this.dialog.Title = this.$t('WindServer.Sites.Attachments.DialogTitle');
      this.dialog.Message = this.$t('WindServer.Sites.Attachments.DownloadSent');
    },
  },
};
</script>

<style>
.ag-invisible
{
  width: 0px;
  margin-left: -11px;
}
</style>
