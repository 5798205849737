<template>
  <v-node-screen
    ref="screen"
    :markers="arrowMarkers"
  >
    <v-node-group
      v-if="groupNodes"
      :nodes="graph.nodes"
    />

    <v-node-edge
      v-for="edge in graph.edges"
      :key="edge.id"
      :data="edge"
      :nodes="graph.nodes"
      :class="`edge-${edge.srcNode.nodeType}`"
    />

    <v-node-node
      v-for="node in graph.nodes"
      :key="node.id"
      :data="node"
      :class="`node-${node.nodeType}`"
      @fit-content="nodeFitContent"
    >
      <div
        v-if="node.nodeType === 'virtual_instrument'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-pencil-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip('edit', node) }}</span>
          </v-tooltip>
          [{{ node.Name }}]
        </div>
        Virtual instrument<br>
        {{ node.Id }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_time_sync'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-clock-check node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Time Sync
        </div>
        {{ node.Aggregation }} by {{ node.multiplier }} {{ node.scale }}
      </div>
      <div
        v-if="node.nodeType === 'output_time_sync'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-clock-check node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Time Sync
        </div>
        {{ node.Aggregation }} by {{ node.multiplier }} {{ node.scale }}
      </div>

      <div
        v-if="node.nodeType === 'internal_binding_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Binding [{{ node.SourceSymbol }}]
        </div>
        (auto)
      </div>

      <div
        v-if="node.nodeType === 'output_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Output [{{ node.Symbol }}]
        </div>
        {{ node.NameTemplate }}
      </div>

      <div
        v-if="node && node.nodeType === 'missing_input'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-progress-question node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Name }}]
        </div>
        Missing input
      </div>
      <div
        v-if="node && node.nodeType === 'missing_output'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-progress-question node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Output [{{ node.Symbol }}]
        </div>
        Missing output
      </div>

      <div
        v-if="node && node.nodeType === 'input_direct_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        {{ node.SensorRule }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_direct_vector'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-v-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        {{ node.SensorRule }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_direct_sensor_array'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-v-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        {{ node.SensorRule }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.AttributeName }}<br>
        S:{{ node.SensorRule }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_vector'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-v-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.AttributeName }}<br>
        S:{{ node.SensorRule }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_sensor_array'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.AttributeName }}<br>
        S:{{ node.SensorRule }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_sensor_attribute'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-a-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        S:{{ node.SensorRule }}<br>
        A:{{ node.AttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_sensor_attribute'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-a-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.InstrumentAttributeName }}<br>
        S:{{ node.SensorRule }}<br>
        A:{{ node.AttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_direct_instrument_attribute'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-a-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        A:{{ node.InstrumentAttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_instrument_attribute'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-a-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.AttributeName }}<br>
        A:{{ node.InstrumentAttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_direct_instrument_capture'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-c-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        A:{{ node.InstrumentAttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_instrument_capture'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-c-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.AttributeName }}<br>
        A:{{ node.InstrumentAttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_constant_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-c-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
      </div>
    </v-node-node>
  </v-node-screen>
</template>

<script>
import VNodeGraph from '@/components/vnodes/graph';
import VNodeGroup from '@/components/vnodes/VNodeGroup.vue';
import VNodeEdge from '@/components/vnodes/VNodeEdge.vue';
import VNodeNode from '@/components/vnodes/VNodeNode.vue';
import VNodeScreen from '@/components/vnodes/VNodeScreen.vue';
// import datehandling from '@/components/datehandling';

export default {
  components: {
    VNodeEdge,
    VNodeGroup,
    VNodeNode,
    VNodeScreen,
  },
  props: {
    viData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    graph: null,
    groupNodes: false,
    graphType: 'basic',
    graphDir: 'right',
    arrowMarkers: [
      {
        id: 'arrow-end-missing_input',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: red',
      },
      {
        id: 'arrow-end-missing_output',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: red',
      },
      {
        id: 'arrow-end-formula',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightcoral',
      },
      {
        id: 'arrow-end-input_sensor_xform',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightcoral',
      },
      {
        id: 'arrow-end-input_external_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_external_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-internal_binding_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_direct_sensor_array',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_direct_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_direct_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_external_sensor_array',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_time_sync',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: goldenrod',
      },
      {
        id: 'arrow-end-output_time_sync',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: goldenrod',
      },
      {
        id: 'arrow-end-output_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightsalmon',
      },
      {
        id: 'arrow-end-output_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightsalmon',
      },
      {
        id: 'arrow-end-input_constant_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_external_instrument_attribute',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_direct_instrument_attribute',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_external_instrument_capture',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_direct_instrument_capture',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_sensor_attribute',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_external_sensor_attribute',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-vector_constant',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-sensor_baseline',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: aqua',
      },
      {
        id: 'arrow-end-vector_baseline',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: aqua',
      },
      {
        id: 'arrow-end-sensor_attribute_input',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightsteelblue',
      },
      {
        id: 'arrow-end-instrument_attribute_input',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: rgb(139, 174, 219)',
      },
    ],
  }),
  watch: {
    viData() {
      this.buildGraph();
    },
  },
  beforeMount() {
    this.graph = new VNodeGraph();
  },
  mounted() {
    this.buildGraph();
  },
  methods: {
    buildGraph() {
      this.graph.reset();
      if (!this.viData || !this.viData.Content) {
        return;
      }
      const InternalBindings = this.viData.Bindings
        .filter((x) => x.BindingType === 'Internal');

      const globalSymbolTable = [];
      const globalOutputSymbols = [];
      // eslint-disable-next-line no-unused-vars
      const viNodes = this.viData.Content.VirtualInstruments.map((VirtualInstrument) => {
        const z = this.graph.createNode(VirtualInstrument);
        z.nodeType = 'virtual_instrument';
        z.width = 150;
        z.height = 100;

        z.$SymbolTable = [];
        z.$OutputSymbols = [];
        for (let j = 0; j < VirtualInstrument.Formulas.length; j += 1) {
          const formula = VirtualInstrument.Formulas[j];

          if (!globalOutputSymbols.find((u) => u === formula.OutputSymbol)) {
            globalOutputSymbols.push(formula.OutputSymbol);
          }
          if (!z.$OutputSymbols.find((u) => u === formula.OutputSymbol)) {
            z.$OutputSymbols.push(formula.OutputSymbol);
          }

          for (let k = 0; k < formula.SymbolTable.length; k += 1) {
            const sym = formula.SymbolTable[k];
            if (!globalSymbolTable.find((u) => u.Name === sym.Name)) {
              globalSymbolTable.push(sym);
            }
            if (!z.$SymbolTable.find((u) => u.Name === sym.Name)) {
              z.$SymbolTable.push(sym);
            }
          }
        }

        return z;
      });

      const internalBindingNodes = InternalBindings
        .map((x) => {
          const z = this.graph.createNode(x);
          z.nodeType = 'internal_binding_sensor';
          z.width = 100;
          z.height = 100;
          return z;
        });

      // eslint-disable-next-line no-unused-vars
      const inputSensorNodes = this.viData.Content.InputRules
        .filter((x) => !(x.RuleType === 'ConstantSensor'
        || x.RuleType === 'ConstantVector'
        || x.RuleType === 'InstrumentAttribute'
        || x.RuleType === 'SensorAttribute'
        || x.RuleType === 'ExternalSensorAttribute'
        || x.RuleType === 'InstrumentCapture'
        || x.RuleType === 'InternalBinding'))
        .map((x) => {
          const z = this.graph.createNode(x);
          if (x.RuleType === 'DirectSensor') {
            z.nodeType = 'input_direct_sensor';
          } else if (x.RuleType === 'DirectVector') {
            z.nodeType = 'input_direct_vector';
          } else if (x.RuleType === 'DirectSensorArray') {
            z.nodeType = 'input_direct_sensor_array';
          } else if (x.RuleType === 'ExternalSensor') {
            z.nodeType = 'input_external_sensor';
          } else if (x.RuleType === 'ExternalSensorArray') {
            z.nodeType = 'input_external_sensor_array';
          } else if (x.RuleType === 'DirectVector') {
            z.nodeType = 'input_direct_vector';
          } else if (x.RuleType === 'ExternalVector') {
            z.nodeType = 'input_external_vector';
          } else {
            z.nodeType = 'unknown';
          }
          z.width = 100;
          z.height = 100;
          return z;
        });

      const constantSensorNodes = this.viData.Content.InputRules
        .filter((x) => x.RuleType === 'ConstantSensor'
        || x.RuleType === 'ConstantVector'
        || x.RuleType === 'InstrumentAttribute'
        || x.RuleType === 'SensorAttribute'
        || x.RuleType === 'ExternalSensorAttribute'
        || x.RuleType === 'InstrumentCapture')
        .map((x) => {
          const z = this.graph.createNode(x);
          if (x.RuleType === 'ConstantSensor') {
            z.nodeType = 'input_constant_sensor';
          } else if (x.RuleType === 'ConstantVector') {
            z.nodeType = 'input_constant_vector';
          } else if (x.RuleType === 'InstrumentAttribute') {
            if (x.InstrumentRule && x.InstrumentRule.length > 0) {
              z.nodeType = 'input_external_instrument_attribute';
            } else {
              z.nodeType = 'input_direct_instrument_attribute';
            }
          } else if (x.RuleType === 'InstrumentCapture') {
            if (x.InstrumentRule && x.InstrumentRule.length > 0) {
              z.nodeType = 'input_external_instrument_capture';
            } else {
              z.nodeType = 'input_direct_instrument_capture';
            }
          } else if (x.RuleType === 'SensorAttribute') {
            z.nodeType = 'input_sensor_attribute';
          } else if (x.RuleType === 'ExternalSensorAttribute') {
            z.nodeType = 'input_external_sensor_attribute';
          } else {
            z.nodeType = 'unknown';
          }
          z.width = 100;
          z.height = 100;
          return z;
        });

      let inputViLinks = [];
      for (let i = 0; i < viNodes.length; i += 1) {
        const VirtualInstrument = viNodes[i];
        inputViLinks = inputViLinks.concat(
          inputSensorNodes
            .filter((x) => VirtualInstrument.$SymbolTable.find((u) => u.Name === x.Symbol))
            .map((x) => ({
              LinkType: 'Input',
              VirtualInstrument,
              InputSensor: x,
            })),
        ).concat(
          internalBindingNodes
            .filter((x) => x.DestinationVi === VirtualInstrument.Id
                        && VirtualInstrument.$SymbolTable.find((u) => u.Name === x.SourceSymbol))
            .map((x) => ({
              LinkType: 'Binding',
              VirtualInstrument,
              InputSensor: x,
            })),
        );
      }

      const makeInputTimeSyncNode = (s) => {
        const inputTimeSyncAttr = {
          $InputNode: s.InputSensor,
          Symbol: s.InputSensor.Symbol || s.InputSensor.DestinationSymbol,
          Aggregation: s.InputSensor.InputAggregation ?? '???',
          scale: s.VirtualInstrument.InputTimeAxisScale ?? 'NONE',
          divisor: s.VirtualInstrument.InputTimeAxisDivisor,
          multiplier: s.VirtualInstrument.InputTimeAxisMultiplier,
          offset: s.VirtualInstrument.InputTimeAxisOffset,
        };
        if (!this.showAllNodes && inputTimeSyncAttr.scale === 'NONE') {
          return null;
        }
        const z = this.graph.createNode(inputTimeSyncAttr);
        z.nodeType = 'input_time_sync';
        z.width = 100;
        z.height = 100;
        return z;
      };
      const inputTimeSyncNodes = inputViLinks.map((s) => makeInputTimeSyncNode(s))
        // .concat(inputVectorNodes.map((s) => makeInputTimeSyncNode(s)))
        .filter((x) => x !== null);

      const outputSensorNodes = this.viData.Content.OutputRules
        .map((x) => {
          const z = this.graph.createNode(x);
          if (x.RuleType === 'CreateSensor') {
            z.nodeType = 'output_sensor';
          } else if (x.RuleType === 'CreateVector') {
            z.nodeType = 'output_vector';
          } else {
            z.nodeType = 'unknown';
          }
          z.width = 100;
          z.height = 100;
          return z;
        });

      let outputViLinks = [];
      for (let i = 0; i < viNodes.length; i += 1) {
        const VirtualInstrument = viNodes[i];
        outputViLinks = outputViLinks.concat(
          outputSensorNodes
            .filter((x) => VirtualInstrument.$OutputSymbols.find((u) => u === x.Symbol))
            .map((x) => ({
              VirtualInstrument,
              OutputSensor: x,
            })),
        ).concat(
          internalBindingNodes
            .filter((x) => x.SourceVi === VirtualInstrument.Id
                        && VirtualInstrument.$OutputSymbols.find((u) => u === x.SourceSymbol))
            .map((x) => ({
              VirtualInstrument,
              OutputSensor: x,
            })),
        );
      }

      const makeOutputTimeSyncNode = (s) => {
        const formulaNode = s.VirtualInstrument.Formulas
          .find((x) => x.OutputSymbol === (s.OutputSensor.Symbol || s.OutputSensor.SourceSymbol));
        const outputTimeSyncAttr = {
          $OutputNode: s.OutputSensor,
          $ViNode: s.VirtualInstrument,
          Symbol: s.OutputSensor.Symbol || s.OutputSensor.SourceSymbol,
          Aggregation: formulaNode?.OutputAggregation ?? '???',
          scale: s.VirtualInstrument.OutputTimeAxisScale ?? 'NONE',
          divisor: s.VirtualInstrument.OutputTimeAxisDivisor,
          multiplier: s.VirtualInstrument.OutputTimeAxisMultiplier,
          offset: s.VirtualInstrument.OutputTimeAxisOffset,
        };
        if (!this.showAllNodes && outputTimeSyncAttr.scale === 'NONE') {
          return null;
        }
        const z = this.graph.createNode(outputTimeSyncAttr);
        z.nodeType = 'output_time_sync';
        z.width = 100;
        z.height = 100;
        return z;
      };
      const outputTimeSyncNodes = outputViLinks.map((s) => makeOutputTimeSyncNode(s))
        // .concat(outputVectorNodes.map((s) => makeOutputTimeSyncNode(s)))
        .filter((x) => x !== null);

      let missingInputNodes = [];
      for (let i = 0; i < this.viData.Content.VirtualInstruments.length; i += 1) {
        const VirtualInstrument = this.viData.Content.VirtualInstruments[i];
        VirtualInstrument.$SymbolTable = [];
        for (let j = 0; j < VirtualInstrument.Formulas.length; j += 1) {
          const formula = VirtualInstrument.Formulas[j];
          // eslint-disable-next-line no-unused-vars
          missingInputNodes = missingInputNodes.concat(formula.SymbolTable
          // eslint-disable-next-line no-loop-func
            .filter((x) => !missingInputNodes.find((n) => x.Name === n.Name))
            .filter((x) => !inputSensorNodes.find((n) => x.Name === n.Symbol))
          // .filter((x) => !inputVectorNodes.find((n) => x.InputSymbol === n.Symbol))
            .filter((x) => !constantSensorNodes.find((n) => x.Name === n.Symbol))
            .filter((x) => !internalBindingNodes.find((n) => x.Name === n.SourceSymbol))
          // .filter((x) => !baselineSensorNodes.find((n) => x.InputSymbol === n.Symbol))
          // .filter((x) => !constantVectorNodes.find((n) => x.InputSymbol === n.Symbol))
          // .filter((x) => !baselineVectorNodes.find((n) => x.InputSymbol === n.Symbol))
          // .filter((x) => !inputSensorAttributeNodes.find((n) => x.InputSymbol === n.Symbol))
          // .filter((x) => !inputInstrumentAttributeNodes.find((n) => x.InputSymbol === n.Symbol))
            .map((x) => {
              const z = this.graph.createNode(x);
              z.nodeType = 'missing_input';
              z.width = 100;
              z.height = 100;
              return z;
            }));
        }
      }

      // eslint-disable-next-line no-unused-vars
      const missingOutputNodes = [];
      for (let i = 0; i < this.viData.Content.VirtualInstruments.length; i += 1) {
        const VirtualInstrument = this.viData.Content.VirtualInstruments[i];
        const items = VirtualInstrument.Formulas
          .filter((x) => !outputSensorNodes.find((n) => x.OutputSymbol === n.Symbol))
        // .filter((x) => !outputVectorNodes.find((n) => x.OutputSymbol === n.Symbol))
          .filter((x) => !internalBindingNodes.find((n) => x.OutputSymbol === n.SourceSymbol))
          .map((x) => {
            const z = this.graph.createNode({
              Symbol: x.OutputSymbol,
            });
            z.nodeType = 'missing_output';
            z.width = 100;
            z.height = 100;
            return z;
          });
        for (let z = 0; z < items.length; z += 1) {
          missingOutputNodes.push(items[z]);
        }
      }

      const makeArrow = (x, y, ypos, yposSrc = null, arrowType = 'hsmooth') => {
        const e = this.graph.createEdge(x, y, {
          srcNode: x,
          dstNode: y,
        });
        e.fromAnchor = { x: '100%', y: `${yposSrc || ypos}%` };
        e.toAnchor = { x: '0%', y: `${ypos}%` };
        e.type = arrowType;
        return e;
      };

      const totalFormulaInputs = missingInputNodes.length
        + inputSensorNodes.length
        + constantSensorNodes.length;

      function inputOffset(inOfft, xi) {
        return ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1);
      }

      /*
          Link Input nodes to the VI node, optionally through the TimeSync node
       */
      inputSensorNodes.forEach((x, xi) => {
        const inOfft = 0;
        /* + inputVectorNodes.length
              + constantSensorNodes.length
              + baselineSensorNodes.length
              + constantVectorNodes.length
              + baselineVectorNodes.length
              + inputSensorAttributeNodes.length
              + inputInstrumentAttributeNodes.length; */
        const tSync = inputTimeSyncNodes
          .find((a) => a.Symbol === (x.Symbol || x.DestinationSymbol));

        if (tSync) {
          viNodes
            .filter((y) => y.$SymbolTable.find((n) => n.Name === x.Symbol))
            .forEach((y) => {
              makeArrow(x, tSync, 50);
              makeArrow(tSync, y, inputOffset(inOfft, xi), 50);
            });
        } else {
          viNodes
            .filter((y) => y.$SymbolTable.find((n) => n.Name === x.Symbol))
            .forEach((y) => {
              makeArrow(x, y, inputOffset(inOfft, xi), 50);
            });
        }
      });

      /*
          Link Binding nodes to the VI node, optionally through the TimeSync node
       */
      internalBindingNodes
        .forEach((x, xi) => {
          const inOfft = inputSensorNodes.length;
          const tSync = inputTimeSyncNodes
            .find((a) => a.Symbol === x.DestinationSymbol
                    && a.$InputNode.DestinationVi === x.DestinationVi);

          if (tSync) {
            viNodes
              .filter((y) => y.Id === x.DestinationVi
                        && y.$SymbolTable.find((n) => n.Name === x.DestinationSymbol))
              .forEach((y) => {
                makeArrow(x, tSync, 50);
                makeArrow(tSync, y, inputOffset(inOfft, xi), 50);
              });
          } else {
            viNodes
              .filter((y) => y.Id === x.DestinationVi
                        && y.$SymbolTable.find((n) => n.Name === x.DestinationSymbol))
              .forEach((y) => {
                makeArrow(x, y, inputOffset(inOfft, xi), 50);
              });
          }
        });

      constantSensorNodes.forEach((x, xi) => {
        const inOfft = inputSensorNodes.length
         + internalBindingNodes.length;
        viNodes
          .filter((y) => y.$SymbolTable.find((n) => n.Name === x.Symbol))
          .forEach((y) => {
            makeArrow(x, y, inputOffset(inOfft, xi), 50);
          });
      });

      missingInputNodes.forEach((x, xi) => {
        const inOfft = inputSensorNodes.length
          + constantSensorNodes.length
          + internalBindingNodes.length;
        /* + inputVectorNodes.length
              + constantSensorNodes.length
              + baselineSensorNodes.length
              + constantVectorNodes.length
              + baselineVectorNodes.length
              + inputSensorAttributeNodes.length
              + inputInstrumentAttributeNodes.length; */
        viNodes
          .filter((y) => y.$SymbolTable.find((n) => n.Name === x.Name))
          .forEach((y) => {
            makeArrow(x, y, inputOffset(inOfft, xi));
          });
      });

      const totalFormulaOutputs = internalBindingNodes.length
        + outputSensorNodes.length
        + missingOutputNodes.length;

      function outputOffset(inOfft, xi) {
        return ((inOfft + xi + 1) * 100) / (totalFormulaOutputs + 1);
      }

      /*
          Link VI node to the Binding nodes, optionally through the TimeSync node
       */
      internalBindingNodes.forEach((y, yi) => {
        const inOfft = 0;
        const fNode = viNodes
          .find((x) => x.Id === y.SourceVi && x.$OutputSymbols.find((u) => u === y.SourceSymbol));
        const sNode = outputTimeSyncNodes
          .find((x) => x.$OutputNode.SourceVi === fNode.Id
                    && x.$OutputNode.DestinationVi === y.DestinationVi
                    && x.Symbol === y.SourceSymbol);
        if (fNode && sNode) {
          makeArrow(fNode, sNode, 50, outputOffset(inOfft, yi));
          makeArrow(sNode, y, 50);
        } else if (fNode) {
          makeArrow(fNode, y, outputOffset(inOfft, yi));
        }
      });

      outputSensorNodes.forEach((y, yi) => {
        const inOfft = internalBindingNodes.length;
        const fNode = viNodes.find((x) => x.$OutputSymbols.find((u) => u === y.Symbol));
        const sNode = outputTimeSyncNodes.find((x) => x.Symbol === y.Symbol);
        if (fNode && sNode) {
          makeArrow(fNode, sNode, outputOffset(inOfft, yi));
          makeArrow(sNode, y, outputOffset(inOfft, yi));
        } else if (fNode) {
          makeArrow(fNode, y, outputOffset(inOfft, yi));
        }
      });

      missingOutputNodes.forEach((y, yi) => {
        const inOfft = internalBindingNodes.length
          + outputSensorNodes.length;
        const fNode = viNodes
          .find((x) => x.$OutputSymbols.find((z) => z === y.Symbol));
        makeArrow(fNode, y, outputOffset(inOfft, yi));
      });

      this.graph.graphNodes({
        nodes: this.graph.nodes,
        edges: this.graph.edges,
        type: this.graphType,
        dir: this.graphDir,
        spacing: 100,
      });
    },
    nodeFitContent() {
      this.graph.graphNodes({
        nodes: this.graph.nodes,
        edges: this.graph.edges,
        type: this.graphType,
        dir: this.graphDir,
        spacing: 100,
      });
      this.$refs.screen.zoomNodes(this.graph.nodes);
    },

    nodeActionClick(actionType, node) {
      this.$emit('node-action-click', actionType, node);
    },
    getTooltip(actionType) {
      if (actionType === 'edit') {
        return this.$t('Edit');
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>  .screen {
  color: blue;
}

.formula-text-ellipsis {
  font-size: 80%;
}
.node-header {
  background-color: lightgrey;
  display: flex;
  text-align: center;
  margin: -10px -10px 0 -10px;
  padding: 0px 5px 0 5px;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
}
.node-footer {
  display: flex;
  margin: 0px -10px -10px -10px;
  padding: 0px 0px 0 0px;
  border-radius: 0px 0px 5px 5px;
}
.node-inner-missing_input::v-deep .node-header {
  background-color: orange !important;
}
.node-inner-missing_output::v-deep .node-header {
  background-color: orange !important;
}

.node-header-icon {
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.node-header-icon-passive {
    color: gray;
  }

.node-inner {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.node-virtual_instrument::v-deep .background {
  background-color: lightcoral;
}
.node-input_sensor_xform::v-deep .background {
  background-color: lightcoral;
}
.node-input_external_sensor::v-deep .background {
  background-color: lightblue;
}
.node-input_external_vector::v-deep .background {
  background-color: lightblue;
}
.node-input_external_sensor_array::v-deep .background {
  background-color: lightblue;
}
.node-input_direct_sensor::v-deep .background {
  background-color: lightblue;
}
.node-input_direct_vector::v-deep .background {
  background-color: lightblue;
}
.node-input_direct_sensor_array::v-deep .background {
  background-color: lightblue;
}
.node-internal_binding_sensor::v-deep .background {
  background-color: lightblue;
}
.node-input_vector::v-deep .background {
  background-color: lightblue;
}
.node-input_time_sync::v-deep .background {
  background-color: goldenrod;
}
.node-output_time_sync::v-deep .background {
  background-color: goldenrod;
}
.node-output_sensor::v-deep .background {
  background-color: lightsalmon;
}
.node-output_vector::v-deep .background {
  background-color: lightsalmon;
}
.node-input_constant_sensor::v-deep .background {
  background-color: palegreen;
}
.node-input_external_instrument_attribute::v-deep .background {
  background-color: palegreen;
}
.node-input_direct_instrument_attribute::v-deep .background {
  background-color: palegreen;
}
.node-input_external_instrument_capture::v-deep .background {
  background-color: palegreen;
}
.node-input_direct_instrument_capture::v-deep .background {
  background-color: palegreen;
}
.node-input_sensor_attribute::v-deep .background {
  background-color: palegreen;
}
.node-input_external_sensor_attribute::v-deep .background {
  background-color: palegreen;
}
.node-vector_constant::v-deep .background {
  background-color: palegreen;
}
.node-sensor_baseline::v-deep .background {
  background-color: aqua;
}
.node-vector_baseline::v-deep .background {
  background-color: aqua;
}
.node-missing_input::v-deep .background {
  background-color: lightgray;
}
.node-missing_output::v-deep .background {
  background-color: lightgray;
}
.node-sensor_attribute_input::v-deep .background {
  background-color: lightsteelblue;
}
.node-instrument_attribute_input::v-deep .background {
  background-color: rgb(139, 174, 219);
}

.edge-virtual_instrument {
  stroke: lightcoral !important;
  marker: url(#arrow-end-formula) !important;
}
.edge-input_sensor_xform {
  stroke: lightcoral !important;
  marker: url(#arrow-end-input_sensor_xform) !important;
}
.edge-input_external_sensor {
  stroke: lightblue !important;
  marker: url(#arrow-end-input_external_sensor) !important;
}
.edge-input_external_vector {
  stroke: lightblue !important;
  marker: url(#arrow-end-input_external_vector) !important;
}
.edge-input_external_sensor_array {
  stroke: lightblue !important;
  marker: url(#arrow-end-input_external_sensor_array) !important;
}
.edge-internal_binding_sensor {
  stroke: lightblue !important;
  marker: url(#arrow-end-internal_binding_sensor) !important;
}
.edge-input_direct_sensor {
  stroke: lightblue !important;
  marker: url(#arrow-end-input_direct_sensor) !important;
}
.edge-input_direct_vector {
  stroke: lightblue !important;
  marker: url(#arrow-end-input_direct_sensor) !important;
}
.edge-input_direct_sensor_vector {
  stroke: lightblue !important;
  marker: url(#arrow-end-input_direct_sensor_array) !important;
}
.edge-input_vector {
  stroke: lightblue !important;
  marker: url(#arrow-end-input_vector) !important;
}
.edge-input_time_sync {
  stroke: goldenrod !important;
  marker: url(#arrow-end-input_time_sync) !important;
}
.edge-output_time_sync {
  stroke: goldenrod !important;
  marker: url(#arrow-end-output_time_sync) !important;
}
.edge-output_sensor {
  stroke: lightsalmon !important;
  marker: url(#arrow-end-output_sensor) !important;
}
.edge-output_vector {
  stroke: lightsalmon !important;
  marker: url(#arrow-end-output_vector) !important;
}
.edge-input_constant_sensor {
  stroke: palegreen !important;
  marker: url(#arrow-end-input_constant_sensor) !important;
}
.edge-input_external_instrument_attribute {
  stroke: palegreen !important;
  marker: url(#arrow-end-input_external_instrument_attribute) !important;
}
.edge-input_direct_instrument_attribute {
  stroke: palegreen !important;
  marker: url(#arrow-end-input_direct_instrument_attribute) !important;
}
.edge-input_external_instrument_capture {
  stroke: palegreen !important;
  marker: url(#arrow-end-input_external_instrument_capture) !important;
}
.edge-input_direct_instrument_capture {
  stroke: palegreen !important;
  marker: url(#arrow-end-input_direct_instrument_capture) !important;
}
.edge-input_sensor_attribute {
  stroke: palegreen !important;
  marker: url(#arrow-end-input_sensor_attribute) !important;
}
.edge-input_external_sensor_attribute {
  stroke: palegreen !important;
  marker: url(#arrow-end-input_external_sensor_attribute) !important;
}
.edge-vector_constant {
  stroke: palegreen !important;
  marker: url(#arrow-end-vector_constant) !important;
}
.edge-sensor_baseline {
  stroke: aqua !important;
  marker: url(#arrow-end-sensor_baseline) !important;
}
.edge-vector_baseline {
  stroke: aqua !important;
  marker: url(#arrow-end-vector_baseline) !important;
}
.edge-missing_input {
  stroke: red !important;
  marker: url(#arrow-end-missing_input) !important;
  stroke-dasharray: 5 5;
}
.edge-missing_output {
  stroke: red !important;
  marker: url(#arrow-end-missing_output) !important;
  stroke-dasharray: 5 5;
}
.edge-sensor_attribute_input {
  stroke: lightsteelblue !important;
  marker: url(#arrow-end-sensor_attribute_input) !important;
}
.edge-instrument_attribute_input {
  stroke: rgb(139, 174, 219) !important;
  marker: url(#arrow-end-instrument_attribute_input) !important;
}
</style>
