<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <v-card>
      <v-card-text>
        <v-container fluid>
          <v-form>
            <ag-grid-vue
              style="width: 100%; height: 400px;"
              class="ag-theme-balham-dark"
              :column-defs="columnDefs"
              :row-data="towerModels"
              :row-selection="'single'"
              :locale-text="localeText"
              @grid-ready="onGridReady"
              @selection-changed="onGridSelectionChanged"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="onAccept"
        >
          {{ $t('Accept') }}
        </v-btn>
        <v-btn
          text
          @click.stop="onCancel"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import i18n from '@/plugins/i18n';
import agGridFr from '@/locales/agGridFr';

export default {
  components: {
    AgGridVue,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data: () => ({
    columnDefs: null,
    gridApi: null,
    columnApi: null,
    towerSelected: null,
    localeText: null,
  }),
  computed: {
    ...mapState('equipment/models', ['towerModels']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
    if (i18n.locale === 'fr') {
      this.localeText = agGridFr.locale_fr;
    }
    this.columnDefs = [
      {
        headerName: i18n.t('Equipments.Models.Columns.ManufacturerName'), field: 'ManufacturerName', filter: true, sortable: true,
      },
      {
        headerName: i18n.t('Equipments.Models.Columns.ModelName'), field: 'ModelName', filter: true, sortable: true,
      },
      {
        headerName: i18n.t('Equipments.Models.Columns.Height'), field: 'TowerHeight', filter: true, sortable: true,
      },
    ];
  },
  methods: {
    onAccept() {
      // this.$store.commit('equipment/models/towerModelSelected', this.towerSelected);
      this.$emit('on-accept', this.towerSelected);
    },
    onCancel() {
      this.$emit('input', false);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onGridSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.towerSelected = selectedRows[0];
      }
    },
  },
};
</script>
