<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="formData.TypeName"
              :label="$t('Equipments.AnchorTypes.Name')"
              :rules="[v => !!v || $t('Validation.Required')]"
              :autofocus="true"
              :error-messages="errorMessage"
              @input="cleanError"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      errorMessage: '',
      readMode: !this.$auth.roles.includes('Web.Equipment.AnchorType.Manage'),
    };
  },
  computed: {
    ...mapState('equipment/anchortypes', ['anchorType', 'nameAlreadyExists']),
  },
  watch: {
    async id() {
      await this.getAnchorType();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.id && this.id != null) { // Update mode
      await this.getAnchorType();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    async getAnchorType() {
      await this.$store.dispatch('equipment/anchortypes/get', { id: this.id });
      this.formData = this.$clone(this.anchorType);
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'Equipment.AnchorTypes.List' });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (await this.validateName()) {
          if (this.id) {
            await this.$store.dispatch('equipment/anchortypes/update', this.formData);
          } else {
            await this.$store.dispatch('equipment/anchortypes/create', this.formData);
          }

          this.navigateToList();
        }
      }
    },
    async validateName() {
      await this.$store.dispatch('equipment/anchortypes/validate', this.formData);
      if (this.nameAlreadyExists) {
        this.errorMessage = i18n.t('Equipments.AnchorTypes.AnchorNameExist');
      } else {
        this.errorMessage = '';
        return true;
      }
      return false;
    },
    cleanError() {
      this.errorMessage = '';
    },
  },
};
</script>
