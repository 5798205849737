import AnalyticsView from '@/analytics/AnalyticsView.vue';
import QuickSensorView from '@/analytics/QuickSensorView.vue';
import InstrumentMapView from '@/analytics/InstrumentMapView.vue';
import MapManagementView from '@/analytics/map/MapManagementView.vue';
import ValidationView from '@/analytics/ValidationView.vue';

export default [
  {
    path: '/analytics',
    name: 'Analytics',
    component: QuickSensorView,
  },
  {
    path: '/analytics/adhoc',
    name: 'Analytics.AdHoc',
    component: AnalyticsView,
  },
  {
    path: '/analytics/quicksensor',
    name: 'Analytics.QuickSensor',
    component: QuickSensorView,
  },
  {
    path: '/analytics/validation',
    name: 'Analytics.Validation',
    component: ValidationView,
  },
  {
    path: '/analytics/mapinstrument',
    name: 'Analytics.InstrumentMapView',
    component: InstrumentMapView,
  },
  {
    path: '/analytics/map-management',
    name: 'Analytics.MapManagement',
    component: MapManagementView,
  },
];
