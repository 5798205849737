<template>
  <div>
    <sensor-selector
      v-if="showSensorSelectorDialog"
      v-model="showSensorSelectorDialog"
      :show-sensors="showSensors"
      :show-vectors="showVectors"
      @sensors-added="addSensors($event)"
    />
    <edit-sensor-modal
      v-if="showEditSensor"
      v-model="showEditSensor"
      :sensor="editedSensor"
      :unit-list="units"
      @save="saveSensor"
    />
    <v-card-title class="px-3 pt-3 pb-0">
      <p class="my-0">
        {{ $t(graphText) }}
      </p>
    </v-card-title>
    <v-card-title class="px-3 pt-0 pb-3 mb-3">
      <p class="my-0">
        {{ $t('Dashboard.VisualizationSetting.VisualizationSetting') }}
      </p>
    </v-card-title>

    <div v-if="showSensorSelection">
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleSubTitles') }}
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-text-field
          v-model="form.mainTitle"
          :placeholder="$t('Dashboard.VisualizationSetting.MainTitle')"
          dense
        />
        <v-text-field
          v-model="form.unitTitle"
          :placeholder="$t('Dashboard.VisualizationSetting.Unit')"
          dense
        />
      </v-card-text>

      <v-subheader class="ma-0">
        {{ $t('Dashboard.Placeholder.TitleSelect') }}
      </v-subheader>
      <placeholder-selection
        :selected-placeholders="form.selectedPlaceholders"
        button-text="Dashboard.Placeholder.AddPlaceholder"
        :is-sensor="showSensors"
        :max-items="1"
        :hide-y2="true"
        :card-disable="form.selectedSensors.concat(form.selectedPlaceholders).length >= 1"
        class="pb-3"
        @change="value => form.selectedPlaceholders = value"
      />

      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleSelectSensor') }}
      </v-subheader>
      <v-card-text class="pb-0 pt-0">
        <v-btn
          :disabled="form.selectedSensors.concat(form.selectedPlaceholders).length >= 1"
          block
          @click="openAddSensorDialog"
        >
          <v-icon>mdi-memory</v-icon>
          {{ showSensors ? $t('Dashboard.AddASensor') : $t('Dashboard.AddAVector') }}
        </v-btn>
        <selected-sensor-scroll
          v-models:selectedSensors="form.selectedSensors"
          :hide-y2="true"
          :show="(true)"
          @edit="editSensor"
        />
      </v-card-text>
    </div>

    <div v-if="showTimeRangeSelection">
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleTimeRange') }}
      </v-subheader>
      <time-range-card
        v-models:periodicity="form.periodicity"
        v-models:dateRangeFrom="form.dateRangeFrom"
        v-models:dateRangeTo="form.dateRangeTo"
        v-models:lastnScale="form.lastnScale"
        v-models:lastnPeriod="form.lastnPeriod"
        v-models:dateList="form.dateList"
        :show="showTimeRangeSelection"
        :show-range="(false)"
        :show-record="(false)"
        :sensor-id-list="sensorIdList"
        :vector-id-list="([])"
        :selected-sensors="form.selectedSensors"
      />
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitlePeriodAggregation') }}
      </v-subheader>
      <period-aggregation-card
        v-models:aggregateMultiplier="form.aggregateMultiplier"
        v-models:aggregateForEvery="form.aggregateForEvery"
        v-models:aggregateUseTimeZone="form.aggregateUseTimeZone"
        :show="showTimeRangeSelection"
      />
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitlePrecisionDigits') }}
      </v-subheader>
      <v-card-text
        class="pb-0 pt-0"
      >
        <v-text-field
          v-model="form.precisionDigits"
          type="number"
          class="pb-0 pt-0"
        />
      </v-card-text>
    </div>

    <wizard-action-buttons-card
      :show-back="showBackButton"
      :show-next="showNextButton"
      :show-save="showSaveButton"
      :confirm-disabled="confirmDisabled"
      @button:cancel="cancel()"
      @button:back="back()"
      @button:next="next()"
      @button:save="save()"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import datehandling from '@/components/datehandling';
import TimeRangeCard from '@/dashboard/components/graphform/TimeRangeCard.vue';
import SensorSelector from '@/dashboard/components/SensorSelector.vue';
import PeriodAggregationCard from '@/dashboard/components/graphform/PeriodAggregationCard.vue';
import SelectedSensorScroll from '@/dashboard/components/graphform/SelectedSensorScroll.vue';
import WizardActionButtonsCard from '@/dashboard/components/graphform/WizardActionButtonsCard.vue';
import PlaceholderSelection from '@/dashboard/components/placeholder/PlaceholderSelection.vue';
import EditSensorModal from '@/dashboard/components/graphform/EditSensorModal.vue';
import importal from '@/api/importal';

export default {
  components: {
    SensorSelector,
    TimeRangeCard,
    PeriodAggregationCard,
    SelectedSensorScroll,
    WizardActionButtonsCard,
    PlaceholderSelection,
    EditSensorModal,
  },
  props: {
    visualisation: {
      type: String,
      default: () => null,
      required: true,
    },
    graphText: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  data: () => ({
    LastPicker: ['Years', 'Months', 'Days', 'Hours', 'Minutes'],
    LastPicker2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE'],
    AggregateByItems: ['Any', 'Average', 'Min', 'Max'],
    AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX'],
    AggregateForEveryItems: ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],
    AggregateForEvery2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'],
    menuStepCounter: 0,
    editedSensor: null,
    units: [],
    model: {
      widgetId: null,
      enabled: true,
      visible: true,
      axisWidth: 4,
      axisHeight: 200,
      axisX: 0,
      axisY: 0,
      axisLock: false,
    },
    form: {
      selectedSensors: [],
      selectedPlaceholders: [],
      graphType: null,
      periodicity: 'last_n',
      dateRangeFrom: null,
      dateRangeTo: null,
      lastnScale: 'Hours',
      lastnPeriod: 2,
      dateList: [],
      aggregateBy: 'Any',
      aggregateMultiplier: 1,
      aggregateForEvery: 'Hours',
      aggregateUseTimeZone: true,

      mainTitle: null,
      unitTitle: null,

      precisionDigits: 3,
    },
    dateRangeMenu: false,
    showSensorSelectorDialog: false,
    pickerTextFieldProps: {
      dense: true,
      style: 'font-size: 80%;',
    },
    pickerDateProps: {
      locale: 'en-ca',
    },
    pickerTimeProps: {
      format: '24hr',
    },
    showEditSensor: false,
  }),
  computed: {
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapGetters('app', ['pleaseWait', 'timezone']),
    sensorIdList() {
      return this.form.selectedSensors
        .filter((x) => x.T === 'S')
        .map((x) => x.Id);
    },

    confirmDisabled() {
      if (this.showSensorSelection) {
        return (!this.form.selectedSensors || this.form.selectedSensors.length === 0)
          && (!this.form.selectedPlaceholders || this.form.selectedPlaceholders.length === 0);
      }

      if (this.showTimeRangeSelection) {
        if (this.form.precisionDigits < 0 || this.form.precisionDigits > 6) {
          return true;
        }

        if (this.form.periodicity === 'last_n') {
          return !(this.form.lastnScale && this.form.lastnPeriod);
        }
        if (this.form.periodicity === 'time_range') {
          return !(this.form.dateRangeFrom && this.form.dateRangeTo);
        }
        if (this.form.periodicity === 'time_list') {
          return !(this.form.dateList && this.form.dateList.length > 0);
        }
      }

      return true;
    },
    showNextButton() {
      return this.menuStepCounter < 1;
    },
    showBackButton() {
      return this.menuStepCounter > 0;
    },
    showSaveButton() {
      return !this.showNextButton;
    },
    showSensorSelection() {
      return this.menuStepCounter === 0;
    },
    showTimeRangeSelection() {
      return this.menuStepCounter === 1;
    },

    showSensors() {
      return this.visualisation.startsWith('sensor+');
    },
    showVectors() {
      return this.visualisation.startsWith('vector+');
    },
    isNew() {
      return this.activeWidget === undefined;
    },
    stringDateRange() {
      return [this.form.dateRangeFrom, this.form.dateRangeTo];
    },
    dateRangeStart() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[0];
    },
    dateRangeEnd() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[dateRange.length - 1];
    },
    jsonWidgetSettings() {
      const query = {
        user: {
          timezone: this.timezone,
        },
        data: {
          formulas: [],
          input_filter: [],
          input_time_axis: {
            round_to: {
              scale: 'HOUR',
              multiplier: 1,
              divisor: 1,
            },
          },
          output_time_axis: {
            round_to: {
              scale: 'HOUR',
              multiplier: 1,
              divisor: 1,
            },
          },
          sensor_selection: [],
          vector_selection: [],
          timezone: this.timezone,
          output_in_local: false,
        },
        visualization: {
          alias: [],
          graphType: this.graphType,
          graphStyle: this.graphStyle,
          graphOptions: {
            x_axis: [],
            y_axis: [],
            groupby: [],
          },
        },
      };

      query.visualization.graphOptions.x_axis.push({
        ts: 'value',
      });

      query.user.selectedSensors = this.form.selectedSensors;
      query.user.selectedPlaceholders = this.form.selectedPlaceholders;
      query.user.mainTitle = this.form.mainTitle;
      query.user.unitTitle = this.form.unitTitle;
      query.user.precisionDigits = this.form.precisionDigits;

      this.$store.dispatch('visualisationSettingsCard/jsonWidgetSettings', {
        widgetSettings: query,
        visualisationSubType: this.visualisation,
        units: this.units,
      });

      query.data.input_time_axis.round_to.scale = (this.form.aggregateUseTimeZone ? 'TZ' : '') + this.AggregateForEvery2Query[
        this.AggregateForEveryItems.indexOf(this.form.aggregateForEvery)
      ];
      query.data.input_time_axis.round_to.multiplier = Number(this.form.aggregateMultiplier);
      query.data.input_time_axis.round_to.divisor = Number(this.form.aggregateMultiplier);

      query.data.output_time_axis.round_to.scale = 'NONE';
      query.data.output_time_axis.round_to.multiplier = 1;
      query.data.output_time_axis.round_to.divisor = 1;

      if (this.form.periodicity === 'last_n') {
        query.data.input_filter.push({
          last_n: {
            scale: this.LastPicker2Query[this.LastPicker.indexOf(this.form.lastnScale)],
            period: Number(this.form.lastnPeriod),
          },
        });
      } else if (this.form.periodicity === 'time_range') {
        query.data.input_filter.push({
          time_range: {
            start: datehandling.formatForApi(this.dateRangeStart),
            end: datehandling.formatForApi(this.dateRangeEnd),
          },
        });
      }

      if (this.form.aggregateUseTimeZone) {
        query.data.output_in_local = true;
      }

      // Update date range and aggregation with global config vs widget config
      this.$store.dispatch('dashboardWidget/updateDateRangeAndAggregation', {
        widgetSettings: query,
        visualisationSubType: this.visualisation,
      });

      return JSON.stringify(query);
    },
  },
  watch: {
    activeWidget() {
      this.init();
    },
    editModeFlag(val) {
      if (!val) {
        this.clearWidgetSettings();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      if (this.units.length === 0) {
        this.units = await (await importal.get('DevUnitList')).data;
      }

      this.clearWidgetSettings();
      if (!this.isNew) {
        this.loadWidgetSettings();
      }
    },

    // Menu NAV
    next() {
      this.menuStepCounter += 1;
    },
    back() {
      this.menuStepCounter -= 1;
    },

    addSensors(array) {
      this.form.selectedSensors.splice(0, this.form.selectedSensors.length);
      if (array.length > 0) {
        this.form.selectedSensors.push(this.$clone(array[0]));
      }
    },
    loadSensors(array, obj, type) {
      for (let i = 0; i < array.length; i += 1) {
        const item = array[i];
        let name = item.symbol;
        if (obj.visualization.alias) {
          const zz = obj.visualization.alias.find((d) => d[item.symbol] !== undefined);
          if (zz) {
            name = zz[item.symbol];
          }
        }
        if (type === 'S') {
          this.form.selectedSensors.push({
            T: type,
            Id: item.sensor_id,
            Name: name,
            Unit: item.unit,
          });
        } else if (type === 'V') {
          this.form.selectedSensors.push({
            T: type,
            Id: item.vector_sensor_id,
            Name: item.vector_name,
            ReadingUnit: item.readingUnit,
            SamplingUnit: item.samplingUnit,
          });
        }
      }
    },
    clearWidgetSettings() {
      this.form.selectedSensors = [];
      this.form.periodicity = 'last_n';
      this.form.dateRangeFrom = null;
      this.form.dateRangeTo = null;
      this.form.lastnScale = 'Hours';
      this.form.lastnPeriod = 2;
      this.form.aggregateBy = 'Any';
      this.form.aggregateMultiplier = 1;
      this.form.aggregateForEvery = 'Hours';
      this.form.mainTitle = null;
      this.form.unitTitle = null;
      this.form.precisionDigits = 3;
    },
    async loadWidgetSettings() {
      const widgetSettings = datehandling.parseObjectFromApi(
        JSON.parse(this.activeWidget.WidgetSettings),
      );

      this.form.selectedPlaceholders = widgetSettings.user.selectedPlaceholders || [];
      if (widgetSettings.user.selectedSensors) {
        this.form.selectedSensors = widgetSettings.user.selectedSensors;
      } else {
        this.form.selectedSensors = [];
        this.loadSensors(widgetSettings.data.sensor_selection, widgetSettings, 'S');
        this.loadSensors(widgetSettings.data.vector_selection, widgetSettings, 'V');
        for (let i = 0; i < this.form.selectedSensors.length; i += 1) {
          const ss = this.form.selectedSensors[i];
          if (ss.T === 'S') {
            ss.Aggregation = widgetSettings.data.sensor_selection
              .find((x) => ss.Id === x.sensor_id)
              .input_aggregation;
          } else if (ss.T === 'V') {
            ss.Aggregation = widgetSettings.data.vector_selection
              .find((x) => ss.Id === x.vector_sensor_id && ss.Name === x.vector_name)
              .input_aggregation;
          }
        }
      }

      const filter = widgetSettings.user.input_filter
        ? widgetSettings.user.input_filter[0]
        : widgetSettings.data.input_filter[0];
      if (filter) {
        if (filter.time_range) {
          this.form.periodicity = 'time_range';
          this.form.dateRangeFrom = filter.time_range.start;
          this.form.dateRangeTo = filter.time_range.end;
        } else if (filter.last_n) {
          this.form.periodicity = 'last_n';
          this.form.lastnScale = this.LastPicker[
            this.LastPicker2Query.indexOf(filter.last_n.scale)
          ];
          this.form.lastnPeriod = filter.last_n.period;
        }
      }

      const inputTimeAxis = widgetSettings.user.input_time_axis
        ? widgetSettings.user.input_time_axis
        : widgetSettings.data.input_time_axis;
      this.form.aggregateForEvery = this.AggregateForEveryItems[
        this.AggregateForEvery2Query.indexOf(inputTimeAxis.round_to.scale.replace('TZ', ''))
      ];
      this.form.aggregateUseTimeZone = inputTimeAxis.round_to.scale.startsWith('TZ');
      if (!this.form.aggregateForEvery) {
        this.form.aggregateForEvery = this.AggregateForEveryItems[
          this.AggregateForEvery2Query
            .indexOf(widgetSettings.data.output_time_axis.round_to.scale.replace('TZ', ''))
        ];
        this.form.aggregateUseTimeZone = widgetSettings.data.output_time_axis.round_to.scale.startsWith('TZ');
      }
      if (!this.form.aggregateUseTimeZone) {
        // if it is false, it could be an old widget settings
        if (widgetSettings.data.output_in_local === undefined
          && widgetSettings.data.timezone === undefined) {
          // old widgetSettings
          this.form.aggregateUseTimeZone = true;
        }
      }
      this.form.aggregateMultiplier = inputTimeAxis.round_to.multiplier;
      this.form.mainTitle = widgetSettings.user.mainTitle || null;
      this.form.unitTitle = widgetSettings.user.unitTitle || null;
      this.form.precisionDigits = widgetSettings.user.precisionDigits || 3;

      const sensors = this.form.selectedSensors.filter((x) => x.T === 'S');
      if (sensors.length > 0) {
        const queryParams = sensors.map((x) => `SensorId=${x.Id}`).join('&');
        const data = await (await importal.get(`DevSensorList?${queryParams}`)).data;

        sensors.forEach((x) => {
          const dataSensor = data.find((y) => y.SensorId === x.Id);
          const selectedSensor = x;

          if (dataSensor.Unit) {
            const dataUnit = this.units.find((y) => y.Symbol === dataSensor.Unit);
            const selectedUnit = this.units.find((y) => y.Symbol === selectedSensor.Unit);
            if (!selectedSensor.Unit || selectedUnit.UnitType !== dataUnit.UnitType) {
              selectedSensor.Unit = dataUnit.Symbol;
            }
          } else if (selectedSensor.Unit) {
            delete selectedSensor.Unit;
          }
        });
      }
    },
    openAddSensorDialog() {
      this.showSensorSelectorDialog = true;
    },
    cancel() {
      this.$emit('cancel', this.isNew);
    },
    save() {
      if (this.isNew) {
        this.create();
      } else {
        this.saveWidgetSettings();
      }
    },
    async editSensor(item) {
      this.editedSensor = item;
      this.showEditSensor = true;
    },
    saveSensor(newValues) {
      if (newValues.alias.length > 0) {
        this.editedSensor.Alias = newValues.alias;
      } else {
        delete this.editedSensor.Alias;
      }

      if (this.editedSensor.T === 'S') {
        if (newValues.unit) {
          this.editedSensor.Unit = newValues.unit.Symbol;
        }
      } else {
        if (newValues.readingUnit) {
          this.editedSensor.ReadingUnit = newValues.readingUnit.Symbol;
        }

        if (newValues.samplingUnit) {
          this.editedSensor.SamplingUnit = newValues.samplingUnit.Symbol;
        }
      }

      this.editedSensor = null;
    },
    create() {
      this.$emit('create', {
        DashboardsWidgetId: this.activeWidgetId,
        DatasourceSettings: '', // TODO check if this property is useful, it seems that WidgetSettings do it all
        WidgetSettings: this.jsonWidgetSettings,
        WidgetId: this.model.widgetId,
        Enabled: this.model.enabled,
        Visible: this.model.visible,
        AxisHeight: this.model.axisHeight,
        AxisWidth: this.model.axisWidth,
        AxisY: this.model.axisY,
        AxisX: this.model.axisX,
        AxisLock: this.model.axisLock,
      });
    },
    saveWidgetSettings() {
      this.$emit('save', {
        DashboardsWidgetId: this.activeWidgetId,
        WidgetSettings: this.jsonWidgetSettings,
      });
    },
    selectPeriodicity(val) {
      this.form.periodicity = val;
    },
  },
};
</script>

<style>

</style>
