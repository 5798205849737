<template>
  <v-app-bar
    app
    clipped-left
    flat
    dense
    class="header-bar"
  >
    <v-toolbar-title>
      <v-img
        lazy-src="@/assets/logo.png"
        src="@/assets/logo.png"
        max-height="100%"
        max-width="100%"
        :class="$vuetify.breakpoint.smAndDown ? 'mr-1' : 'mr-4'"
      />
    </v-toolbar-title>
    <span class="nav-title">
      {{ $t(pageTitle.key, pageTitle.payload) }}
    </span>
    <v-spacer />
    <div class="mr-2">
      <global-date-range-menu />
    </div>
    <tenant-switcher />
    <access-group-switcher />
    <time-zone-switcher />
    <language-switcher />
    <v-badge
      :content="messages"
      :value="messages"
      color="red"
      bordered
      overlap
      offset-y="17"
    >
      <v-icon
        color="grey darken-5"
        :class="$vuetify.breakpoint.smAndDown ? 'ml-1' : 'ml-2'"
      >
        mdi-bell-outline
      </v-icon>
    </v-badge>
    <v-tooltip
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          color="grey darken-5"
          :class="$vuetify.breakpoint.smAndDown ? 'ml-1' : 'ml-2'"
          v-on="on"
          @click="toggleDashboardAutoRefresh"
        >
          {{ autoRefresh ? 'mdi-refresh-auto' : 'mdi-autorenew-off' }}
        </v-icon>
      </template>
      <span>{{ autoRefresh ? $t('Header.AutoRefreshOn') : $t('Header.AutoRefreshOff') }}</span>
    </v-tooltip>
    <v-menu
      class="toolbar-menu-item"
      offset-y
      origin="center center"
      :nudge-bottom="10"
      transition="scale-transition"
    >
      <template #activator="{ attrs, on }">
        <v-btn
          slot="activator"
          icon
          large
          text
          :ripple="false"
          :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'"
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar
            size="32"
            color="primary"
          >
            <span class="white--text"> {{ initials }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list dense>
        <v-subheader>{{ $auth.user != null ? $auth.user.name : "" }}</v-subheader>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="item.click"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import AccessGroupSwitcher from '@/components/AccessGroupSwitcher.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import GlobalDateRangeMenu from '@/components/header/GlobalDateRangeMenu.vue';
import TimeZoneSwitcher from '@/components/TimeZoneSwitcher.vue';
import TenantSwitcher from '@/components/TenantSwitcher.vue';

export default {
  name: 'HeaderBar',
  components: {
    AccessGroupSwitcher,
    LanguageSwitcher,
    GlobalDateRangeMenu,
    TimeZoneSwitcher,
    TenantSwitcher,
  },
  data() {
    return {
      autoRefresh: false,
      refreshTimer: null,
      messages: 0,
      items: [
        {
          icon: 'mdi-account-circle',
          href: '#',
          title: 'Profile',
          click: () => {
          },
        },
        {
          icon: 'mdi-cog',
          href: '#',
          title: 'Settings',
          click: () => {
          },
        },
        {
          icon: 'mdi-exit-to-app',
          href: '#',
          title: 'Log Out',
          click: () => {
            const auth = this.$auth;
            auth.logoutWithRedirect();
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters('app', ['pageTitle']),
    initials() {
      return this.$auth.user && this.$auth.user.name ? this.$auth.user.name.split(' ').map((x) => x[0].toUpperCase()).join('') : '';
    },
  },
  created() {
    const storedRefresh = localStorage.getItem('refreshTimer');
    this.autoRefresh = storedRefresh ? JSON.parse(storedRefresh) : false;
    this.setAutoRefresh();
  },
  beforeDestroy() {
    this.cancelAutoRefresh();
  },
  methods: {
    toggleDashboardAutoRefresh() {
      this.autoRefresh = !this.autoRefresh;
      localStorage.refreshTimer = this.autoRefresh;
      this.setAutoRefresh();
    },
    cancelAutoRefresh() {
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer);
      }
    },
    initRefreshTimer() {
      this.refreshTimer = setInterval(() => {
        this.$root.$emit('dashboard-refresh');
      }, 60000); // refresh every minute
    },
    setAutoRefresh() {
      if (this.autoRefresh) {
        this.initRefreshTimer();
      } else {
        this.cancelAutoRefresh();
      }
    },
  },
};
</script>

<style>
.header-bar {
  /* background: transparent !important; */
  background: linear-gradient(90.59deg, #333E48 -6.03%, #14415A -6.03%, #2A2438 50.51%);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.09);
  border-radius: 0px;
}
.nav-title {
  color: #ef5350;
  text-transform: uppercase;
}
@media screen and (min-width: 641px) {
  .nav-title {
    font-size: 1.25em;
    line-height: 0.9em;
  }
}
@media screen and (min-width: 482px) and (max-width: 640px) {
  .nav-title {
    font-size: 0.9rem;
    line-height: 0.8em;
    }
}
@media screen and (max-width: 481px) {
  .nav-title {
    font-size: 0.7rem;
    line-height: 0.8em;
    }
}
.notif-icon {
  font-weight: 600;
  color: #898989;
}
</style>
