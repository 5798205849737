<template>
  <v-dialog
    v-model="showDialog"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card
      v-if="editorActive"
    >
      <v-card-title>Output sensor rule See DSN006.1 for details.</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="NameTemplate"
          label="Name Template"
          type="text"
          dense
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="showDialog=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          text
          :disabled="onAcceptDisabled"
          @click.stop="onAccept"
        >
          {{ $t('Accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'nodeType', event: 'models:nodeType' },
    { data: 'NameTemplate', event: 'models:NameTemplate' },
  ],
  components: {
  },
  data: () => ({
    show: false,
    nodeType: '',
    showDialog: false,

    NameTemplate: null,
  }),
  computed: {
    editorActive() {
      return this.show;
    },
    onAcceptDisabled() {
      return !this.NameTemplate || this.NameTemplate.length === 0;
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
    showDialog(value) {
      if (!value) {
        this.show = false;
      }
    },

    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) { this.$emit('models:show', newVal); },
    nodeType: function w(newVal) { this.$emit('models:nodeType', newVal); },
    NameTemplate: function w(newVal) { this.$emit('models:NameTemplate', newVal); },
  },
  created() {
  },
  methods: {
    onAccept() {
      this.showDialog = false;
      this.nodeType = 'output_sensor';
      this.$emit('on-accept', {
        nodeType: this.nodeType,
        NameTemplate: this.NameTemplate,
      });
    },
  },
};
</script>

<style>

</style>
