<template>
  <v-tab-item
    v-if="$auth.roles.includes('Web.AccessGroup.Manage')"
    style="height: calc(100% - 110px);"
  >
    <AutoFormPopup
      v-if="editPopup.Show"
      v-model="editPopup.Show"
      :model="editPopup.Model"
      :schema="editPopup.Schema"
      @on-accept="onEditPopupAccept"
    />
    <ConfirmDialog
      v-model="dialog.Show"
      :message="dialog.Message"
      :no-cancel="dialog.NoCancel"
      :title="dialog.Title"
      @on-accept="dialog.acceptCallback()"
    />
    <v-row style="height: 100%">
      <v-col style="height: 100%; max-width: 50%;">
        <server-side-data-grid
          :grid-source="sources.OrganizationalUnitsSource"
          :show-new-button="true"
          :refresh="refresh"
          :validate-popup-values="true"
        />
      </v-col>
      <v-col
        v-if="selection.unit"
        style="height: 100%; max-width: 50%;"
      >
        <v-card-title>{{ `${selection.unit.Name} (Id: ${selection.unit.OrganisationalUnitId})` }}</v-card-title>
        <v-row class="mb-5">
          <v-col style="max-width: 50%;">
            <v-select
              v-model="selection.metaRole"
              :label="$t('Settings.AccessControl.ManageOrganizationalUnits.UpdateMetaRole')"
              :items="metaRolesList"
              item-text="MetaRoleName"
              hide-details
              dense
              return-object
            />
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  class="mr-auto"
                  :disabled="!selection.metaRole"
                  v-on="on"
                  @click="updateMetaRole()"
                >
                  <v-icon class="pr-2">
                    mdi-account-convert
                  </v-icon>
                  {{ $t('Apply') }}
                </v-btn>
              </template>
              {{ $t('Settings.AccessControl.ManageOrganizationalUnits.MetaRoleAdvise') }}
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="max-width: 50%;">
            <v-select
              v-model="selection.users"
              :label="$t('Settings.AccessControl.ManageOrganizationalUnits.AddUser')"
              :items="availableUsersList"
              item-text="UserName"
              :multiple="true"
              hide-details
              dense
              return-object
            />
          </v-col>
          <v-col>
            <v-btn
              class="mr-auto"
              :disabled="selection.users.length === 0"
              @click="onUserAdded()"
            >
              <v-icon class="pr-2">
                mdi-account-multiple-plus
              </v-icon>
              {{ $t('Add') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <v-col class="pt-0">
            <v-checkbox
              key="ForceUnitChange"
              v-model="selection.isForcedUnitChange"
              class="py-0 my-0"
              :label="$t('Settings.AccessControl.ManageOrganizationalUnits.ForceUnitChange')"
              hide-details="true"
              @change="updateAvailableUsers()"
            />
          </v-col>
        </v-row>
        <server-side-data-grid
          :grid-source="sources.OrganizationalUnitUsersSource"
          :show-new-button="false"
          :refresh="refresh"
          :row-data-init="selectedUnitUserList"
        />
      </v-col>
    </v-row>
  </v-tab-item>
</template>

<script>
import importal from '@/api/importal';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import AutoFormPopup from '@/components/AutoFormPopup.vue';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import OrganizationalUnitsSource from '../gridsources/gsorganizationalunits';
import OrganizationalUnitUsersSource from '../gridsources/gsorganizationalunitusers';

export default {
  components: {
    AutoFormPopup,
    ConfirmDialog,
    ServerSideDataGrid,
  },
  props: {
    refresh: {
      type: Number,
      default: () => (0),
    },
  },
  data: () => ({
    selectedUnitUserList: null,
    availableUsersList: null,
    metaRolesList: null,
    editPopup: {
      Show: false,
      Model: null,
      Schema: null,
    },
    dialog: {
      Message: '',
      NoCancel: false,
      Title: '',
      Show: false,
      acceptCallback: null,
    },
    sources: {
      OrganizationalUnitsSource: null,
      OrganizationalUnitUsersSource: null,
    },
    selection: {
      unit: null,
      metaRole: null,
      users: [],
      isForcedUnitChange: false,
    },
  }),
  watch: {
    'selection.unit': {
      async handler() {
        await this.initSecondPart();
      },
      immediate: true,
    },
    refresh: {
      async handler() {
        await this.init();
        await this.initSecondPart();
      },
      immediate: true,
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      this.sources.OrganizationalUnitsSource = new OrganizationalUnitsSource(this);
      this.sources.OrganizationalUnitUsersSource = new OrganizationalUnitUsersSource(this);
    },
    async initSecondPart() {
      this.selectedUnitUserList = [];
      this.selection.metaRole = null;
      this.selection.users = [];

      await this.getMetaRoles();
      await this.updateAvailableUsers();
      await this.initSelectedUnitUsers();
    },
    async getMetaRoles() {
      if (this.selection.unit) {
        this.metaRolesList = await (await importal.get('OpMetaRoleList')).data;
      }
    },
    async updateAvailableUsers() {
      if (this.selection.unit) {
        this.availableUsersList = await (await importal.get('OpUserListOrgUnit?'
          + `orgUnitId=${this.selection.unit.OrganisationalUnitId}`
          + `&isForcedUnitChange=${this.selection.isForcedUnitChange}`)).data;
      }
    },
    async initSelectedUnitUsers() {
      if (this.selection.unit) {
        this.selectedUnitUserList = await (await importal
          .get(`OpOrgUnitUserList?orgUnitId=${this.selection.unit.OrganisationalUnitId}`)).data;
      }
    },
    onEditPopupAccept(data) {
      const errorMessage = this.sources.OrganizationalUnitsSource.validateValues(data);

      if (!errorMessage || errorMessage.length === 0) {
        this.sources.OrganizationalUnitsSource.apiOpOrgUnitUpdate(data);
      } else {
        this.showWarning(
          errorMessage,
          () => this.sources.OrganizationalUnitsSource.onEditButtonClick(data.Api, data),
        );
      }
    },
    async updateMetaRole() {
      await importal.post('OpOrgUnitAssignMetaRole', {
        OrgUnitId: this.selection.unit.OrganisationalUnitId,
        MetaRoleId: this.selection.metaRole.MetaRoleId,
      });

      this.selection.metaRole = null;
    },
    async onUserAdded() {
      const { users } = this.selection;
      this.selection.users = [];
      this.selectedUnitUserList = await (await importal.post('OpOrgUnitUserAdd', {
        OrganisationalUnitId: this.selection.unit.OrganisationalUnitId,
        UserIds: users.map((x) => x.UserId),
        Force: this.selection.isForcedUnitChange,
      })).data;

      users.forEach((x) => this.availableUsersList.splice(this.availableUsersList.indexOf(x), 1));
    },
    showWarning(message, callback) {
      this.dialog.Message = message;
      this.dialog.NoCancel = true;
      this.dialog.Title = this.$t('Settings.Dialog.Warning');
      this.dialog.acceptCallback = callback;
      this.dialog.Show = true;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
