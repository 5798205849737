/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import api from '@/api/importal';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  placeholders: [],
  selectedPlaceholderSensors: [],
  nextSelectedPlaceHolderSensors: [],
  transferTimeout: null,
};

const getters = {
  tokenSensorName: () => '%sensorName%',
  tokenInstrumentName: () => '%instrumentName%',
  tokenAggregation: () => '%aggregation%',
  tokenUnit: () => '%unit%',
  defaultAlias: (state, getters) => (withUnit) => {
    if (withUnit) {
      return `${getters.tokenInstrumentName}.${getters.tokenSensorName} (${getters.tokenAggregation}, ${getters.tokenUnit})`;
    }
    return `${getters.tokenInstrumentName}.${getters.tokenSensorName} (${getters.tokenAggregation})`;
  },
  defaultName: () => (item) => `${item.JsonData.DropdownType}_${item.JsonData.RuleType}_${item.JsonData.ValueType}_${item.JsonData.Value}`,

  placeholders: (state) => state.placeholders,
  selectedPlaceholderSensors: (state) => state.selectedPlaceholderSensors,

  sensorAlias: (state, getters) => (placeholderAlias, sensor) => {
    let unit = sensor.T === 'S' ? sensor.Unit : sensor.ReadingUnit;
    unit = (typeof unit === 'string' || unit instanceof String) ? unit : null;

    let alias = placeholderAlias || getters.defaultAlias(unit);
    alias = alias.replaceAll(getters.tokenInstrumentName, sensor.InstrumentName);
    alias = alias.replaceAll(getters.tokenSensorName, sensor.Name);
    alias = alias.replaceAll(getters.tokenAggregation, (sensor.Aggregation || 'AVG'));
    alias = alias.replaceAll(getters.tokenUnit, unit);
    return alias;
  },
};

const actions = {
  async list({ commit }, dashboardId) {
    return withWait({ commit }, () => api.get(`DashboardPlaceholderList?dashboardId=${dashboardId}`))
      .then((resp) => {
        commit('placeholders', resp.data);
        // commit('selectedPlaceholderSensors', []);
      });
  },
  async create({ commit }, payload) {
    return withWait({ commit }, () => api.post('DashboardPlaceholderNew', payload))
      .then((resp) => commit('placeholders', resp.data));
  },
  async update({ commit }, payload) {
    return withWait({ commit }, () => api.post('DashboardPlaceholderUpdate', payload))
      .then((resp) => commit('placeholders', resp.data));
  },
  async remove({ commit }, payload) {
    return withWait({ commit }, () => api.delete('DashboardPlaceholderRemove', { data: payload }))
      .then((resp) => commit('placeholders', resp.data));
  },
  updateSelectedSensor({ state, commit }, payload) {
    const currentState = state.selectedPlaceholderSensors;
    const item = currentState
      .find((x) => x.placeholderId === payload.placeholderId);
    if (item) {
      item.selectedItem = payload.selectedItem;
    } else {
      currentState.push(payload);
    }
    commit('selectedPlaceholderSensors', currentState);
  },
  updateSelectedSensorFromInstrument({ state, commit }, payload) {
    const currentState = state.selectedPlaceholderSensors;
    const item = currentState
      .find((x) => x.placeholderId === payload.placeholderId);
    if (item) {
      let match = false;
      if (item.selectedItem) {
        item.selectedItem.forEach((selected, index) => {
          if (selected.Id === payload.selectedItem[0].Id
            && selected.InstrumentName === payload.selectedItem[0].InstrumentName) {
            match = true;
            if (payload.selectedItem[0].selected) {
              // Update the selected item
              item.selectedItem[index] = payload.selectedItem;
            } else {
              // Remove item from currentState
              item.selectedItem.splice(index, 1);
            }
          }
        });
      } else {
        item.selectedItem = [];
      }
      if (!match) {
        // No match was found, push selectedItem to currentState
        // payload.selectedItem is always one element
        item.selectedItem.push(payload.selectedItem[0]);
      }
    } else {
      currentState.push(payload);
    }
    commit('selectedPlaceholderSensors', currentState);
  },

  updateSelectedSensorFromInstAttribute({ state, commit }, payload) {
    const currentState = state.selectedPlaceholderSensors;
    const item = currentState
      .find((x) => x.placeholderId === payload.placeholderId);
    if (item) {
      let match = false;
      if (item.selectedItem) {
        item.selectedItem.forEach((selected, index) => {
          if (selected.Value === payload.selectedItem[0].Value) {
            match = true;
            if (payload.selectedItem[0].selected) {
              // Update the selected item
              item.selectedItem[index] = payload.selectedItem;
            } else {
              // Remove item from currentState
              item.selectedItem.splice(index, 1);
            }
          }
        });
      } else {
        item.selectedItem = [];
      }
      if (!match) {
        // No match was found, push selectedItem to currentState
        // payload.selectedItem is always one element
        item.selectedItem.push(payload.selectedItem[0]);
      }
    } else {
      currentState.push(payload);
    }
    commit('selectedPlaceholderSensors', currentState);
  },
};

const mutations = {
  init(state) {
    state.placeholders = [];
    state.selectedPlaceholderSensors = [];
    state.nextSelectedPlaceholderSensors = [];
  },
  placeholders(state, payload) {
    state.placeholders = payload;
  },
  selectedPlaceholderSensors(state, payload) {
    state.nextSelectedPlaceholderSensors = payload;
    if (state.transferTimeout !== null) {
      clearTimeout(state.transferTimeout);
      state.transferTimeout = null;
    }
    state.transferTimeout = setTimeout(() => {
      state.selectedPlaceholderSensors = state.nextSelectedPlaceholderSensors;
      clearTimeout(state.transferTimeout);
      state.transferTimeout = null;
    }, 25);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
