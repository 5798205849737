<template>
  <v-dialog
    v-model="showDialog"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card
      v-if="editorActive"
    >
      <v-card-title>Select aggregation.</v-card-title>
      <v-card-text>
        <v-select
          v-model="Aggregation"
          :items="AggregationDropdownItems"
          item-text="text"
          item-value="value"
          dense
          class="mr-12"
          style="width: 90px; font-size: 80%;"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="showDialog=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          text
          :disabled="onAcceptDisabled"
          @click.stop="onAccept"
        >
          {{ $t('Accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'nodeType', event: 'models:nodeType' },
    { data: 'Aggregation', event: 'models:Aggregation' },
  ],
  props: {
    aggregateByItems: { type: Array, required: true },
    aggregateBy2Query: { type: Array, required: true },
  },
  data: () => ({
    show: false,
    nodeType: '',
    showDialog: false,

    Aggregation: null,
  }),
  computed: {
    editorActive() {
      return this.show && (this.nodeType === 'input_time_sync' || this.nodeType === 'output_time_sync');
    },
    onAcceptDisabled() {
      return !this.Aggregation;
    },
    AggregationDropdownItems() {
      const rc = [];
      for (let i = 0; i < this.aggregateBy2Query.length; i += 1) {
        rc.push({
          text: this.aggregateByItems[i],
          value: this.aggregateBy2Query[i],
        });
      }
      return rc;
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
    showDialog(value) {
      if (!value) {
        this.show = false;
      }
    },

    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) { this.$emit('models:show', newVal); },
    nodeType: function w(newVal) { this.$emit('models:nodeType', newVal); },
    Aggregation: function w(newVal) { this.$emit('models:Aggregation', newVal); },
  },
  created() {
  },
  methods: {
    onAccept() {
      this.showDialog = false;
      this.$emit('on-accept', {
        nodeType: this.nodeType,
        Aggregation: this.Aggregation,
      });
    },
  },
};
</script>

<style>
</style>
