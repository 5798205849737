export default [
  {
    label: 'Analytics.Title',
    icon: 'mdi-database',
    title: 'Analytics.PageTitle',
    to: { name: 'Analytics' },
    items: [
      {
        label: 'Analytics.QuickSensor.Title',
        to: { name: 'Analytics.QuickSensor' },
        title: 'Analytics.QuickSensor.PageTitle',
      },
      {
        label: 'Analytics.InstrumentMapView.Title',
        to: { name: 'Analytics.InstrumentMapView' },
        title: 'Analytics.InstrumentMapView.PageTitle',
      },
      {
        label: 'Analytics.MapManagement.Title',
        to: { name: 'Analytics.MapManagement' },
        title: 'Analytics.MapManagement.PageTitle',
      },
      {
        label: 'Analytics.AdHoc.Title',
        to: { name: 'Analytics.AdHoc' },
        title: 'Analytics.AdHoc.PageTitle',
      }],
  }];
