<template>
    <v-dialog
      persistent
      v-model="show"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-card-text>
            <v-container fluid>
              <v-text-field
                :value="selectedTenant"
                :label="$t('QualityCheck.QCSchedules.Tenants')"
                readonly
              />
              <v-select
                v-model="formData.Technician"
                :label="$t('QualityCheck.QCSchedules.Technician')"
                :items="qcTechnicians"
                :rules="requiredRule"
                :error-messages="errorMessage"
                @click="cleanError"
                item-text="Fullname"
                item-value="Id"
              />
              <v-select
                v-model="formData.Analyst"
                :label="$t('QualityCheck.QCSchedules.Analyst')"
                :items="qcAnalysts"
                :rules="requiredRule"
                :error-messages="errorMessage"
                @click="cleanError"
                item-text="Fullname"
                item-value="Id"
              />
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="submit"
            >
              {{ $t('Accept') }}
            </v-btn>
            <v-btn
              text
              @click.stop="show=false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
   </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      formData: {},
      show: false,
      requiredRule: null,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('qualityCheck/qcSchedules', ['qcAnalysts', 'qcTechnicians', 'selectedTenant', 'selectedTenantAnalystId', 'selectedTenantTechnicianId']),
  },
  watch: {
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    this.formData.Technician = this.selectedTenantTechnicianId;
    this.formData.Analyst = this.selectedTenantAnalystId;
    this.show = true;
  },
  methods: {
    navigateToList() {
      this.$router.push({ name: 'QualityCheck.QCSchedules.Tenants' });
    },
    cleanError() {
      this.requiredRule = '';
      this.errorMessage = '';
    },
    async submit() {
      this.requiredRule = [(v) => !!v || this.$t('Validation.Required')];
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          await this.$store.dispatch('qualityCheck/qcSchedules/assignation', this.formData);
          this.$router.push({ name: 'QualityCheck.QCSchedules.Tenants' });
        }
      });
    },
  },
};
</script>
