<template>
  <v-dialog
    v-model="show"
    max-width="700"
    @keydown="handleKeyDown"
  >
    <v-card>
      <v-card-title>
        {{ $t('MergeTitle') }}
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-form
            ref="form"
            lazy-validation
            @keydown="handleKeyDown"
          >
            <v-select
              v-model="selectedItem"
              :items="dropdownItems"
              :label="$t('SelectOption')"
              item-value="BasicAlertconfigId"
              return-object
              outlined
              dense
              required
            >
              <!-- Item Slot: Custom display for dropdown options -->
              <template #item="{ item }">
                <div>
                  <strong>{{ $t('LongName') }}:</strong> {{ item.LongName }},
                  <strong>{{ $t('GraphLabel') }}:</strong> {{ item.Name }}
                </div>
              </template>

              <!-- Selection Slot: Custom display for selected value -->
              <template #selection="{ item }">
                <div v-if="item">
                  <strong>{{ item.LongName }}</strong> ({{ item.Name }})
                </div>
              </template>
            </v-select>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="confirmAccept"
        >
          {{ $t('Accept') }}
        </v-btn>
        <v-btn
          text
          @click.stop="show = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Confirmation Dialog -->
    <v-dialog
      v-model="confirmDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('Confirmation') }}
        </v-card-title>
        <v-card-text>
          {{ $t('MergeConfirmation', { alertName: selectedItem?.Name }) }}
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="onConfirm"
          >
            {{ $t('Yes') }}
          </v-btn>
          <v-btn
            text
            @click="confirmDialog = false"
          >
            {{ $t('No') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertMigratePopup',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    dropdownItems: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    selectedItem: null, // Holds the selected item from the dropdown
    confirmDialog: false, // Controls the confirmation dialog visibility
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    confirmAccept() {
      if (this.$refs.form.validate() && this.selectedItem) {
        this.confirmDialog = true; // Open the confirmation dialog
      }
    },
    onConfirm() {
      this.confirmDialog = false;
      this.show = false;
      this.$emit('on-accept', this.selectedItem);
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.confirmAccept();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        this.show = false;
      }
    },
  },
  i18n: {
    messages: {
      en: {
        Accept: 'Accept',
        Cancel: 'Cancel',
        SelectOption: 'Select an option',
        LongName: 'Name',
        GraphLabel: 'Graph Label',
        MergeTitle: 'Select branch to merge',
        Confirmation: 'Confirmation',
        MergeConfirmation: 'Are you sure you want to merge the selected alerts into {alertName}?',
        Yes: 'Yes',
        No: 'No',
      },
      fr: {
        Accept: 'Accepter',
        Cancel: 'Annuler',
        SelectOption: 'Sélectionner une option',
        LongName: 'Nom',
        GraphLabel: 'Étiquette de Graphique',
        MergeTitle: 'Sélectionnez la branche à fusionner',
        Confirmation: 'Confirmation',
        MergeConfirmation: 'Êtes-vous sûr de vouloir fusionner les alertes sélectionnées dans {alertName}?',
        Yes: 'Oui',
        No: 'Non',
      },
    },
  },
};
</script>
