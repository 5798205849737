/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsRoles extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          maxWidth: 30,
          resizable: false,
          cellStyle: { padding: 0 },
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => this.onEditButtonClick(event.api, event.data),
          },
        },
        {
          field: 'RoleName', minWidth: 300, headerName: i18n.t('Settings.AccessControl.ManageRoles.RoleName'),
        },
        {
          field: 'RoleId', headerName: i18n.t('Settings.AccessControl.ManageRoles.RoleId'),
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    return {
      getRows: (params) => {
        importal.get('OpRoleList')
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    return new Promise((resolve) => {
      resolve({
        Model: {
          RoleId: crypto.randomUUID(),
          RoleName: null,
        },
        Schema: {
          RoleId: {
            label: i18n.t('Settings.AccessControl.ManageRoles.RoleId'), type: 'text', disabled: true, flex: 12,
          },
          RoleName: { label: i18n.t('Settings.AccessControl.ManageRoles.RoleName'), type: 'text', flex: 12 },
        },
      });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    importal
      .post('OpRoleNew', {
        RoleId: data.RoleId,
        RoleName: data.RoleName,
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async onEditButtonClick(api, data) {
    const d = await this.editItemFormDefinition(data, api);
    this.vue.editPopup.Model = d.Model;
    this.vue.editPopup.Schema = d.Schema;
    this.vue.editPopup.Show = true;
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async editItemFormDefinition(data, api) {
    return new Promise((resolve) => {
      resolve({
        Model: {
          Api: api,
          RoleId: data.RoleId,
          RoleName: data.RoleName,
        },
        Schema: {
          RoleId: {
            label: i18n.t('Settings.AccessControl.ManageRoles.RoleId'), type: 'text', disabled: true, flex: 12,
          },
          RoleName: { label: i18n.t('Settings.AccessControl.ManageRoles.RoleName'), type: 'text', flex: 12 },
        },
      });
    });
  }

  async apiOpOrgUnitUpdate(data) {
    const resp = await importal
      .post('OpRoleRename', {
        RoleId: data.RoleId,
        RoleName: data.RoleName,
      });
    data.Api.setRowData(resp.data);
  }

  validateValues(data) {
    let errormessage = '';
    if (!data.RoleName || data.RoleName.length === 0) {
      errormessage = errormessage.concat(`${i18n.t('Validation.RequiredNamedField',
        { Field: i18n.t('Settings.AccessControl.ManageRoles.RoleName') })}. `);
    }

    return errormessage;
  }
}
