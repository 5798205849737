<template>
  <div style="height: 100%; width: 100%">
    <server-side-data-grid
      :grid-source="sources.SensorIngressSource"
      :show-new-button="$auth.roles.includes('Web.Tenant.Create') && readmode === false"
      :refresh="refresh"
    />
    <v-dialog
      v-model="ShowChangeSensorDialog"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title>
          {{ $t('AssociateInputSensorWithOutput') }}
        </v-card-title>
        <v-card-text>
          <v-overflow-btn
            v-model="OutputInstrument"
            class="my-2"
            :items="OutputInstrumentList"
            :disabled="ChangeSensorDialogUpdating"
            :label="$t('SelectAnInstrument')"
            item-text="Name"
            return-object
            @change="GetSensorList"
          />
          <v-overflow-btn
            v-model="OutputSensor"
            class="my-2"
            :items="OutputSensorList"
            :disabled="!OutputInstrument.InstrumentId || !OutputSensorList || OutputSensorList.length === 0 || ChangeSensorDialogUpdating"
            :loading="OutputInstrument.InstrumentId && !OutputSensorList"
            :label="$t('SelectASensor')"
            item-text="Name"
            return-object
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            :disabled="ChangeSensorDialogUpdating"
            @click="SensorRemove"
          >
            {{ $t('Remove') }}
          </v-btn>
          <v-btn
            :disabled="!OutputSensor.SensorId || ChangeSensorDialogUpdating"
            @click="SensorChosen"
          >
            {{ $t('Submit') }}
          </v-btn>
          <v-btn
            :disabled="ChangeSensorDialogUpdating"
            @click="ShowChangeSensorDialog = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import importal from '@/api/importal';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import SensorIngressSource from './gridsources/gssensoringress';

export default {
  name: 'DeviceList',
  components: {
    ServerSideDataGrid,
  },
  props: {
    refresh: {
      type: Number,
      default: () => (0),
    },
  },
  data: () => ({
    sources: {
      SensorIngressSource: null,
    },
    IngressSensorList: [],
    ShowChangeSensorDialog: false,
    ChangeSensorDialogUpdating: false,
    ChangeSensorDialogEvent: null,
    OutputInstrumentList: [],
    OutputSensorList: null,
    OutputInstrument: {
      Name: null,
      InstrumentId: null,
    },
    OutputSensor: {
      Name: null,
      SensorId: null,
    },
  }),
  i18n: {
    messages: {
      en: {
        AssociateInputSensorWithOutput: 'Associate Input Sensor With Output',
        SelectAnInstrument: 'Select an Instrument',
        SelectASensor: 'Select a Sensor',
        Close: 'Close',
        Submit: 'Submit',
        Remove: 'Remove',
      },
      fr: {
        AssociateInputSensorWithOutput: 'Associer un capteur entrée à la sortie',
        SelectAnInstrument: 'Sélectionner un instrument',
        SelectASensor: 'Sélectionner un capteur',
        Close: 'Fermer',
        Submit: 'Soumettre',
        Remove: 'Retirer',
      },
    },
  },
  computed: {
    ...mapState('devices/gateways', ['readmode']),
  },
  watch: {
    async refresh() {
      await this.init();
    },
  },
  async mounted() {
    await this.init();
  },
  created() {
    this.sources.SensorIngressSource = new SensorIngressSource(this, {
      EditOutputSensor: (api, event) => this.EditOutputSensor(api, event),
      cbDevGatewayIngressSensorList: (data) => this.cbDevGatewayIngressSensorList(data),
    });
  },
  methods: {
    async init() {
      await importal.get(`DevInstrumentList?DataGatewayId=${this.$route.params.id}`)
        .then((result) => result.data)
        .then((data) => this.GetOutputInstrumentList(data));
    },
    cbDevGatewayIngressSensorList(data) {
      this.IngressSensorList = data;
    },
    EditOutputSensor(api, event) {
      this.ChangeSensorDialogEvent = { api, event };
      this.OutputSensor = [];
      this.OutputInstrument = [];
      this.ShowChangeSensorDialog = true;
      this.ChangeSensorDialogUpdating = false;
    },
    GetOutputInstrumentList(data) {
      for (let i = 0; i < data.length; i += 1) {
        // eslint-disable-next-line max-len
        if (!(this.OutputInstrumentList.includes(data[i])) && data[i].Name !== null) {
          this.OutputInstrumentList.push(data[i]);
        }
      }
    },
    async GetSensorList() {
      this.OutputSensorList = [];
      await importal.get(`DevSensorList?InstrumentId=${this.OutputInstrument.InstrumentId}`)
        .then((resp) => {
          for (let i = 0; i < resp.data.length; i += 1) {
            if (!(this.OutputSensorList.includes(resp.data[i]))
                  && resp.data[i].Name !== null) {
              // && !this.IngressSensorList.find((x) => x.SensorId === resp.data[i].SensorId)) {
              this.OutputSensorList.push(resp.data[i]);
            }
          }
        });
      this.OutputSensorList.sort((a, b) => a.Name.localeCompare(b.Name));
    },
    async SensorChosen() {
      const { api, event } = this.ChangeSensorDialogEvent;
      this.ChangeSensorDialogUpdating = true;
      await importal.post('DevGatewayIngressSensorUpdate', {
        DataGatewayId: event.DataGatewayId,
        InstrumentName: event.InputInstrumentName,
        SensorName: event.InputSensorName,
        SensorId: this.OutputSensor.SensorId,
        DataType: event.DataType,
        DataUnit: event.DataUnit,
      }).then((result) => result.data)
        .then((data) => {
          this.ShowChangeSensorDialog = false;
          api.setRowData(data);
          this.cbDevGatewayIngressSensorList(data);
        });
    },
    async SensorRemove() {
      const { api, event } = this.ChangeSensorDialogEvent;
      this.ChangeSensorDialogUpdating = true;
      await importal.post('DevGatewayIngressSensorUpdate', {
        DataGatewayId: event.DataGatewayId,
        InstrumentName: event.InputInstrumentName,
        SensorName: event.InputSensorName,
        SensorId: null,
        DataType: event.DataType,
        DataUnit: event.DataUnit,
      }).then((result) => result.data)
        .then((data) => {
          this.ShowChangeSensorDialog = false;
          api.setRowData(data);
          this.cbDevGatewayIngressSensorList(data);
        });
    },
  },
};
</script>

<style lang="scss">
</style>
