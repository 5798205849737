/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsInspectionNotes extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.QCInspection.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 80,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.QCInspection.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'QualityCheck.QCInspections.Notes.Update',
                params: {
                  id: event.data.QCInspectionId,
                  noteId: event.data.QCInspectionNoteId,
                },
              });
            },
          },
        },
        {
          field: 'Date',
          headerName: this.vue.$t('QualityCheck.QCInspectionNotes.Date'),
          width: 150,
          editable: false,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
        },
        {
          field: 'DisplayName',
          headerName: this.vue.$t('QualityCheck.QCInspectionNotes.User'),
          width: 150,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Note',
          headerName: this.vue.$t('QualityCheck.QCInspectionNotes.Note'),
          flex: 1,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 80,
          resizable: true,
          hide: !this.vue.$auth.roles.includes('Web.QCInspection.Delete'),
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (this.vue.$t('QualityCheck.QCInspectionNotes.DeleteConfirmation', { Id: event.data.QCInspectionNoteId })),
            clicked: async (event) => {
              await this.vue.$store.dispatch('qualityCheck/qcInspectionNotes/remove', event.data.QCInspectionNoteId);
              await this.vue.$store.dispatch('qualityCheck/qcInspectionNotes/list', this.vue.id);
            },
          },
        },
      ]);
    });
  }
}
