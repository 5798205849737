<template>
  <v-node-screen
    ref="screen"
    :markers="arrowMarkers"
  >
    <v-node-group
      v-if="groupNodes"
      :nodes="graph.nodes"
    />

    <v-node-edge
      v-for="edge in graph.edges"
      :key="edge.id"
      :data="edge"
      :nodes="graph.nodes"
      :class="`edge-${edge.srcNode.nodeType}`"
    />

    <v-node-node
      v-for="node in graph.nodes"
      :key="node.id"
      :data="node"
      :class="`node-${node.nodeType}`"
      @fit-content="nodeFitContent"
    >
      <div
        v-if="node.nodeType === 'missing_input'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Input') }} [{{ node.InputSymbol }}]
        </div>
        {{ $t('MissingInput') }}
      </div>
      <div
        v-if="node.nodeType === 'missing_output'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Output') }} [{{ node.Symbol }}]
        </div>
        {{ $t('MissingOutput') }}
      </div>
      <div
        v-if="node.nodeType === 'input_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Input') }} [{{ node.Symbol }}]
        </div>
        {{ node.InstrumentName }}<br>
        {{ node.SensorName }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-if="!node.InputTransform"
                v-bind="attrs"
                :class="nodeFooterIconClass('input-transform', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('input-transform', node)"
              />
            </template>
            <span>{{ getTooltip(node, true) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="node.nodeType === 'input_make_vector'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Input') }} [{{ node.Symbol }}]
        </div>
        <ul class="make-vector-list">
          <li
            v-for="item in node.Sensors"
            :key="item.SensorId"
            class="make-vector-list-item"
          >
            {{ item.InstrumentName }}<br>
            &nbsp;&nbsp;{{ item.SensorName }}
          </li>
        </ul>
        <div class="node-footer">
          <v-spacer />
        </div>
      </div>
      <div
        v-if="node.nodeType === 'input_vector'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Input') }} [{{ node.Symbol }}]
        </div>
        {{ node.InstrumentName }}<br>
        {{ node.DisplayName }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="node.nodeType === 'input_sensor_xform'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('InputTransform') }}
        </div>
        <span class="formula-text-ellipsis">{{ node.InputTransform }}</span><br>
        {{ node.TransformXUnit }} {{ node.TransformYUnit }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="node.nodeType === 'input_make_vector_xform'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('InputTransform') }}
        </div>
        <span class="formula-text-ellipsis">{{ node.InputTransform }}</span><br>
        {{ node.TransformXUnit }} {{ node.TransformYUnit }}
        <div class="node-footer">
          <v-spacer />
        </div>
      </div>
      <div
        v-if="node.nodeType === 'input_time_sync'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('TimeSync') }}
        </div>
        {{ node.Aggregation }} by {{ node.multiplier }} {{ node.scale }}
      </div>
      <div
        v-if="node.nodeType === 'output_time_sync'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('TimeSync') }}
        </div>
        {{ node.Aggregation }} by {{ node.multiplier }} {{ node.scale }}
      </div>
      <div
        v-if="node.nodeType === 'formula'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Formula') }}
        </div>
        <span class="formula-text-ellipsis">{{ node.FormulaText }}</span><br>
        {{ node.Unit }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="node.nodeType === 'output_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Output') }} [{{ node.Symbol }}]
        </div>
        {{ node.InstrumentName }}<br>
        {{ node.SensorName }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="node.nodeType === 'output_vector'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Output') }} [{{ node.Symbol }}]
        </div>
        {{ node.InstrumentName }}<br>
        {{ node.DisplayName }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="node.nodeType === 'sensor_constant'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Constant') }} [{{ node.Symbol }}]
        </div>
        {{ node.Value }}<br>
        {{ $t('ValidFrom') }} <br>
        {{ formatDateForDisplay(node.ValidFromTs) }}<br>
        to<br>
        {{ formatDateForDisplay(node.ValidToTs) }}<br>
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('new-sensor-constant', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('new-sensor-constant', node)"
              />
            </template>
            <span>{{ getTooltip(node, true) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="node.nodeType === 'sensor_baseline'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Baseline') }} [{{ node.Symbol }}]
        </div>
        {{ node.InstrumentName }}<br>
        {{ node.SensorName }}<br>
        {{ node.Value }}<br>
        {{ $t('At') }} {{ formatDateForDisplay(node.SourceTs) }}<br>
        {{ $t('ValidFrom') }} <br>
        {{ formatDateForDisplay(node.ValidFromTs) }}<br>
        {{ $t('TimeSync') }}<br>
        {{ formatDateForDisplay(node.ValidToTs) }}<br>
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('new-sensor-baseline', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('new-sensor-baseline', node)"
              />
            </template>
            <span>{{ getTooltip(node, true) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="node.nodeType === 'vector_constant'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Constant') }} [{{ node.Symbol }}]
        </div>
        {{ node.Value }}<br>
        {{ $t('ValidFrom') }} <br>
        {{ formatDateForDisplay(node.ValidFromTs) }}<br>
        {{ $t('To') }}<br>
        {{ formatDateForDisplay(node.ValidToTs) }}<br>
      </div>
      <div
        v-if="node.nodeType === 'vector_baseline'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          {{ $t('Baseline') }} [{{ node.Symbol }}]
        </div>
        {{ node.InstrumentName }}<br>
        {{ node.VectorName }}<br>
        {{ $t('At') }} {{ formatDateForDisplay(node.SourceTs) }}<br>
        {{ $t('ValidFrom') }} <br>
        {{ formatDateForDisplay(node.ValidFromTs) }}<br>
        {{ $t('To') }}<br>
        {{ formatDateForDisplay(node.ValidToTs) }}<br>
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('new-vector-baseline', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('new-vector-baseline', node)"
              />
            </template>
            <span>{{ getTooltip(node, true) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node.nodeType === 'sensor_attribute_input'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          S.Attribute [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentName }}<br>
        S:{{ node.SensorName }}<br>
        A:{{ node.AttributeName }}<br>
        D:{{ node.DefaultValue }}<br>
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node.nodeType === 'instrument_attribute_input'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeHeaderIconClass(node)"
                v-on="on"
                @click.stop="nodeHeaderIconClick(node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          I.Attribute [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentName }}<br>
        A:{{ node.AttributeName }}<br>
        D:{{ node.DefaultValue }}<br>
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                :class="nodeFooterIconClass('delete', node)"
                v-on="on"
                @click.stop="nodeFooterIconClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-node-node>
  </v-node-screen>
</template>

<script>
import VNodeGraph from '@/components/vnodes/graph';
import VNodeGroup from '@/components/vnodes/VNodeGroup.vue';
import VNodeEdge from '@/components/vnodes/VNodeEdge.vue';
import VNodeNode from '@/components/vnodes/VNodeNode.vue';
import VNodeScreen from '@/components/vnodes/VNodeScreen.vue';
import datehandling from '@/components/datehandling';

export default {
  components: {
    VNodeEdge,
    VNodeGroup,
    VNodeNode,
    VNodeScreen,
  },
  props: {
    viData: {
      type: Object,
      default: () => {},
    },
    showAllNodes: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    VirtualInstrumentId: null,
    info: {
      meta: null,
      exec: null,
    },
    editor: {
      node: null,
      show: false,
      text: '',
    },
    graph: null,
    groupNodes: false,
    graphType: 'basic',
    graphDir: 'right',
    arrowMarkers: [
      {
        id: 'arrow-end-missing_input',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: red',
      },
      {
        id: 'arrow-end-missing_output',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: red',
      },
      {
        id: 'arrow-end-formula',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightcoral',
      },
      {
        id: 'arrow-end-input_sensor_xform',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightcoral',
      },
      {
        id: 'arrow-end-input_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_time_sync',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: goldenrod',
      },
      {
        id: 'arrow-end-output_time_sync',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: goldenrod',
      },
      {
        id: 'arrow-end-output_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightsalmon',
      },
      {
        id: 'arrow-end-output_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightsalmon',
      },
      {
        id: 'arrow-end-sensor_constant',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-vector_constant',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-sensor_baseline',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: aqua',
      },
      {
        id: 'arrow-end-vector_baseline',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: aqua',
      },
      {
        id: 'arrow-end-sensor_attribute_input',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightsteelblue',
      },
      {
        id: 'arrow-end-instrument_attribute_input',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: rgb(139, 174, 219)',
      },
    ],
  }),
  watch: {
    viData() {
      this.buildGraph();
    },
  },
  beforeMount() {
    this.graph = new VNodeGraph();
  },
  mounted() {
    this.buildGraph();
  },
  methods: {
    nodeHeaderIconClass(node) {
      let iconName = 'mdi-progress-question';
      switch (node.nodeType) {
        case 'input_sensor': iconName = 'mdi-alpha-s-box'; break;
        case 'output_sensor': iconName = 'mdi-alpha-s-box'; break;
        case 'input_vector': iconName = 'mdi-alpha-v-box'; break;
        case 'output_vector': iconName = 'mdi-alpha-v-box'; break;
        case 'input_sensor_xform': iconName = 'mdi-math-integral-box'; break;
        case 'formula': iconName = 'mdi-math-integral-box'; break;
        case 'input_time_sync': iconName = 'mdi-clock-check'; break;
        case 'output_time_sync': iconName = 'mdi-clock-check'; break;
        case 'sensor_constant': iconName = 'mdi-alpha-c-box'; break;
        case 'vector_constant': iconName = 'mdi-alpha-c-box'; break;
        case 'sensor_baseline': iconName = 'mdi-alpha-b-box'; break;
        case 'vector_baseline': iconName = 'mdi-alpha-b-box'; break;
        case 'sensor_attribute_input': iconName = 'mdi-alpha-a-box'; break;
        case 'instrument_attribute_input': iconName = 'mdi-alpha-a-box'; break;
        case 'input_make_vector': iconName = 'mdi-alpha-v-box node-header-icon-passive'; break;
        case 'input_make_vector_xform': iconName = 'mdi-math-integral-box node-header-icon-passive'; break;
        default: break;
      }

      const isPassive = this.canEditContent(node) ? '' : ' node-header-icon-passive';

      return `mdi mdi-24px node-header-icon ${iconName}${isPassive}`;
    },
    // eslint-disable-next-line no-unused-vars
    nodeFooterIconClass(classType, node) {
      let iconName = 'mdi-progress-question';
      switch (classType) {
        case 'input-transform': iconName = 'mdi-math-integral-box'; break;
        case 'delete': iconName = 'mdi-delete-forever'; break;
        case 'new-sensor-constant': iconName = 'mdi-alpha-c-box'; break;
        case 'new-sensor-baseline': iconName = 'mdi-alpha-b-box'; break;
        case 'new-vector-baseline': iconName = 'mdi-alpha-b-box'; break;
        default: break;
      }

      const isPassive = this.canEditContent(node) ? '' : ' node-header-icon-passive';

      return `mdi mdi-24px node-header-icon ${iconName}${isPassive}`;
    },

    getTooltip(n, isFooter = false) {
      if (!n || !n.nodeType) { return ''; }
      if (isFooter) {
        // debugger;
        if (n.nodeType === 'sensor_constant') {
          return `${this.$root.$i18n.t('New')} ${n.nodeType.replace('xform', 'transform').replace(/_/g, ' ')}`;
        }
        if (n.nodeType === 'vector_baseline') {
          return `${this.$root.$i18n.t('VirtualInstrument.Select')} ${n.nodeType.replace('xform', 'transform').replace(/_/g, ' ')}`;
        }
        return this.$root.$i18n.t('VirtualInstrument.NewFormula');
      }
      if (n.nodeType.startsWith('missing')) {
        return `${this.$root.$i18n.t('VirtualInstrument.Add')} ${n.nodeType.replace('xform', 'transform').replace(/_/g, ' ')}`;
      }
      if (n.nodeType === 'input_sensor' || n.nodeType === 'input_time_sync') {
        return `${this.$root.$i18n.t('VirtualInstrument.Select')} ${n.nodeType.replace('xform', 'transform').replace(/_/g, ' ')}`;
      }
      return `${this.$root.$i18n.t('VirtualInstrument.Edit.Title')} ${n.nodeType.replace('xform', 'transform').replace(/_/g, ' ')}`;
    },
    formatDateForDisplay(d) {
      return datehandling.formatForDisplay(d);
    },
    EnsureDateTime(x, field, def = null) {
      if (!x[field]) {
        // eslint-disable-next-line no-param-reassign
        x[field] = datehandling.parseFromApi(def);
      }
    },
    buildGraph() {
      this.graph.reset();
      if (!this.viData || !this.viData.formulas) {
        return;
      }
      // eslint-disable-next-line no-unused-vars
      const formulaNodes = this.viData.formulas.map((x) => {
        const z = this.graph.createNode(x);
        z.nodeType = 'formula';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const inputSensorNodes = this.viData.input_sensors.map((x) => {
        const z = this.graph.createNode(x);
        z.nodeType = 'input_sensor';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const inputSensorTransformNodes = this.viData.input_sensors
        .filter((x) => !!x.InputTransform)
        .map((x) => {
          const z = this.graph.createNode(x);
          z.nodeType = 'input_sensor_xform';
          z.width = 100;
          z.height = 100;
          return z;
        });

      // eslint-disable-next-line no-unused-vars
      const inputMakeVectorNodes = this.viData.make_vector.map((x) => {
        const z = this.graph.createNode(x);
        z.nodeType = 'input_make_vector';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const inputMakeVectorTransformNodes = this.viData.make_vector
        .filter((x) => !!x.InputTransform)
        .map((x) => {
          const z = this.graph.createNode(x);
          z.nodeType = 'input_make_vector_xform';
          z.width = 100;
          z.height = 100;
          return z;
        });

      // eslint-disable-next-line no-unused-vars
      const outputSensorNodes = this.viData.output_sensors.map((x) => {
        const z = this.graph.createNode(x);
        z.nodeType = 'output_sensor';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const inputVectorNodes = this.viData.input_vectors.map((x) => {
        const z = this.graph.createNode(x);
        z.nodeType = 'input_vector';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const inputVectorTransformNodes = this.viData.input_vectors
        .filter((x) => !!x.InputTransform)
        .map((x) => {
          const z = this.graph.createNode(x);
          z.nodeType = 'input_vector_xform';
          z.width = 100;
          z.height = 100;
          return z;
        });

      // eslint-disable-next-line no-unused-vars
      const outputVectorNodes = this.viData.output_vectors.map((x) => {
        const z = this.graph.createNode(x);
        z.nodeType = 'output_vector';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const constantSensorNodes = this.viData.sensor_constants.map((x) => {
        this.EnsureDateTime(x, 'ValidFromTs');
        this.EnsureDateTime(x, 'ValidToTs', '2099-01-01 05:00:00');
        const z = this.graph.createNode(x);
        z.nodeType = 'sensor_constant';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const baselineSensorNodes = this.viData.sensor_baselines.map((x) => {
        this.EnsureDateTime(x, 'SourceTs');
        this.EnsureDateTime(x, 'ValidFromTs');
        this.EnsureDateTime(x, 'ValidToTs', '2099-01-01 05:00:00');
        const z = this.graph.createNode(x);
        z.nodeType = 'sensor_baseline';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const constantVectorNodes = this.viData.vector_constants.map((x) => {
        this.EnsureDateTime(x, 'ValidFromTs');
        this.EnsureDateTime(x, 'ValidToTs', '2099-01-01 05:00:00');
        const z = this.graph.createNode(x);
        z.nodeType = 'vector_constant';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const baselineVectorNodes = this.viData.vector_baselines.map((x) => {
        this.EnsureDateTime(x, 'SourceTs');
        this.EnsureDateTime(x, 'ValidFromTs');
        this.EnsureDateTime(x, 'ValidToTs', '2099-01-01 05:00:00');
        const z = this.graph.createNode(x);
        z.nodeType = 'vector_baseline';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const inputSensorAttributeNodes = this.viData.sensor_attribute_inputs.map((x) => {
        const z = this.graph.createNode(x);
        z.nodeType = 'sensor_attribute_input';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const inputInstrumentAttributeNodes = this.viData.instrument_attribute_inputs.map((x) => {
        const z = this.graph.createNode(x);
        z.nodeType = 'instrument_attribute_input';
        z.width = 100;
        z.height = 100;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const missingInputNodes = this.viData.symbol_table
        .filter((x) => !inputSensorNodes.find((n) => x.InputSymbol === n.Symbol))
        .filter((x) => !inputMakeVectorNodes.find((n) => x.InputSymbol === n.Symbol))
        .filter((x) => !inputVectorNodes.find((n) => x.InputSymbol === n.Symbol))
        .filter((x) => !constantSensorNodes.find((n) => x.InputSymbol === n.Symbol))
        .filter((x) => !baselineSensorNodes.find((n) => x.InputSymbol === n.Symbol))
        .filter((x) => !constantVectorNodes.find((n) => x.InputSymbol === n.Symbol))
        .filter((x) => !baselineVectorNodes.find((n) => x.InputSymbol === n.Symbol))
        .filter((x) => !inputSensorAttributeNodes.find((n) => x.InputSymbol === n.Symbol))
        .filter((x) => !inputInstrumentAttributeNodes.find((n) => x.InputSymbol === n.Symbol))
        .map((x) => {
          const z = this.graph.createNode(x);
          z.nodeType = 'missing_input';
          z.width = 100;
          z.height = 100;
          return z;
        });

      // eslint-disable-next-line no-unused-vars
      const missingOutputNodes = this.viData.formulas
        .filter((x) => !outputSensorNodes.find((n) => x.OutputSymbol === n.Symbol))
        .filter((x) => !outputVectorNodes.find((n) => x.OutputSymbol === n.Symbol))
        .map((x) => {
          const z = this.graph.createNode({
            Symbol: x.OutputSymbol,
          });
          z.nodeType = 'missing_output';
          z.width = 100;
          z.height = 100;
          return z;
        });

      const makeInputTimeSyncNode = (s) => {
        const inputTimeSyncAttr = {
          $InputNode: s,
          Symbol: s.Symbol,
          Aggregation: s.InputAggregation,
          scale: this.viData.attributes.find((x) => x.Name === 'input_time_axis.round_to.scale')?.ValueStr ?? 'NONE',
          divisor: this.viData.attributes.find((x) => x.Name === 'input_time_axis.round_to.divisor')?.ValueInt ?? 1,
          multiplier: this.viData.attributes.find((x) => x.Name === 'input_time_axis.round_to.multiplier')?.ValueInt ?? 1,
          offset: this.viData.attributes.find((x) => x.Name === 'input_time_axis.round_to.multiplier')?.ValueInt ?? 0,
        };
        if (!this.showAllNodes && inputTimeSyncAttr.scale === 'NONE') {
          return null;
        }
        const z = this.graph.createNode(inputTimeSyncAttr);
        z.nodeType = 'input_time_sync';
        z.width = 100;
        z.height = 100;
        return z;
      };
      const inputTimeSyncNodes = []
        .concat(inputSensorNodes.map((s) => makeInputTimeSyncNode(s)))
        .concat(inputMakeVectorNodes.map((s) => makeInputTimeSyncNode(s)))
        .concat(inputVectorNodes.map((s) => makeInputTimeSyncNode(s)))
        .filter((x) => x !== null);

      const makeOutputTimeSyncNode = (s) => {
        const formulaNode = formulaNodes.find((x) => x.OutputSymbol === s.Symbol);
        const outputTimeSyncAttr = {
          $OutputNode: s,
          $FormulaNode: formulaNode,
          Symbol: s.Symbol,
          Aggregation: formulaNode?.OutputAggregation ?? '???',
          scale: this.viData.attributes.find((x) => x.Name === 'output_time_axis.round_to.scale')?.ValueStr ?? 'NONE',
          divisor: this.viData.attributes.find((x) => x.Name === 'output_time_axis.round_to.divisor')?.ValueInt ?? 1,
          multiplier: this.viData.attributes.find((x) => x.Name === 'output_time_axis.round_to.multiplier')?.ValueInt ?? 1,
          offset: this.viData.attributes.find((x) => x.Name === 'output_time_axis.round_to.multiplier')?.ValueInt ?? 0,
        };
        if (!this.showAllNodes && outputTimeSyncAttr.scale === 'NONE') {
          return null;
        }
        const z = this.graph.createNode(outputTimeSyncAttr);
        z.nodeType = 'output_time_sync';
        z.width = 100;
        z.height = 100;
        return z;
      };
      const outputTimeSyncNodes = outputSensorNodes.map((s) => makeOutputTimeSyncNode(s))
        .concat(outputVectorNodes.map((s) => makeOutputTimeSyncNode(s)))
        .filter((x) => x !== null);

      const makeArrow = (x, y, ypos, arrowType = 'hsmooth') => {
        const e = this.graph.createEdge(x, y, {
          srcNode: x,
          dstNode: y,
        });
        e.fromAnchor = { x: '100%', y: `${ypos}%` };
        e.toAnchor = { x: '0%', y: `${ypos}%` };
        e.type = arrowType;
        return e;
      };

      // Input to formula edges
      const totalFormulaInputs = 0
            + inputSensorNodes.length
            + inputMakeVectorNodes.length
            + inputVectorNodes.length
            + constantSensorNodes.length
            + baselineSensorNodes.length
            + constantVectorNodes.length
            + baselineVectorNodes.length
            + inputInstrumentAttributeNodes.length
            + inputSensorAttributeNodes.length
            + missingInputNodes.length;
      inputSensorNodes.forEach((x, xi) => {
        const inOfft = 0;
        const xfrm = inputSensorTransformNodes.find((a) => a.Symbol === x.Symbol);
        const tSync = inputTimeSyncNodes.find((a) => a.Symbol === x.Symbol);
        if (!xfrm) {
          if (tSync) {
            makeArrow(x, tSync, 50);
            formulaNodes
              .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
              .forEach((y) => {
                makeArrow(tSync, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
              });
          } else {
            formulaNodes
              .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
              .forEach((y) => {
                makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
              });
          }
        } else if (tSync) {
          makeArrow(x, xfrm, 50);
          makeArrow(xfrm, tSync, 50);
          formulaNodes
            .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
            .forEach((y) => {
              makeArrow(tSync, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
            });
        } else {
          makeArrow(x, xfrm, 50);
          formulaNodes
            .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
            .forEach((y) => {
              makeArrow(xfrm, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
            });
        }
      });
      inputMakeVectorNodes.forEach((x, xi) => {
        const inOfft = inputSensorNodes.length;
        const xfrm = inputMakeVectorTransformNodes.find((a) => a.Symbol === x.Symbol);
        const tSync = inputTimeSyncNodes.find((a) => a.Symbol === x.Symbol);
        if (!xfrm) {
          if (tSync) {
            makeArrow(x, tSync, 50);
            formulaNodes
              .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
              .forEach((y) => {
                makeArrow(tSync, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
              });
          } else {
            formulaNodes
              .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
              .forEach((y) => {
                makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
              });
          }
        } else if (tSync) {
          makeArrow(x, xfrm, 50);
          makeArrow(xfrm, tSync, 50);
          formulaNodes
            .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
            .forEach((y) => {
              makeArrow(tSync, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
            });
        } else {
          makeArrow(x, xfrm, 50);
          formulaNodes
            .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
            .forEach((y) => {
              makeArrow(xfrm, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
            });
        }
      });
      inputVectorNodes.forEach((x, xi) => {
        const inOfft = 0
          + inputSensorNodes.length
          + inputMakeVectorNodes.length;
        const xfrm = inputVectorTransformNodes.find((a) => a.Symbol === x.Symbol);
        const tSync = inputTimeSyncNodes.find((a) => a.Symbol === x.Symbol);
        if (!xfrm) {
          if (tSync) {
            makeArrow(x, tSync, 50);
            formulaNodes
              .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
              .forEach((y) => {
                makeArrow(tSync, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
              });
          } else {
            formulaNodes
              .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
              .forEach((y) => {
                makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
              });
          }
        } else if (tSync) {
          makeArrow(x, xfrm, 50);
          makeArrow(xfrm, tSync, 50);
          formulaNodes
            .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
            .forEach((y) => {
              makeArrow(tSync, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
            });
        } else {
          makeArrow(x, xfrm, 50);
          formulaNodes
            .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
            .forEach((y) => {
              makeArrow(xfrm, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
            });
        }
      });
      constantSensorNodes.forEach((x, xi) => {
        const inOfft = 0
          + inputSensorNodes.length
          + inputMakeVectorNodes.length
          + inputVectorNodes.length;
        formulaNodes
          .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
          .forEach((y) => {
            makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
          });
      });
      baselineSensorNodes.forEach((x, xi) => {
        const inOfft = 0
          + inputSensorNodes.length
          + inputMakeVectorNodes.length
          + inputVectorNodes.length
          + constantSensorNodes.length;
        formulaNodes
          .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
          .forEach((y) => {
            makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
          });
      });
      constantVectorNodes.forEach((x, xi) => {
        const inOfft = 0
              + inputSensorNodes.length
              + inputMakeVectorNodes.length
              + inputVectorNodes.length
              + constantSensorNodes.length
              + baselineSensorNodes.length;
        formulaNodes
          .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
          .forEach((y) => {
            makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
          });
      });
      baselineVectorNodes.forEach((x, xi) => {
        const inOfft = 0
          + inputSensorNodes.length
          + inputMakeVectorNodes.length
          + inputVectorNodes.length
          + constantSensorNodes.length
          + baselineSensorNodes.length
          + constantVectorNodes.length;
        formulaNodes
          .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
          .forEach((y) => {
            makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
          });
      });

      inputSensorAttributeNodes.forEach((x, xi) => {
        const inOfft = 0
          + inputSensorNodes.length
          + inputVectorNodes.length
          + inputMakeVectorNodes.length
          + constantSensorNodes.length
          + baselineSensorNodes.length
          + constantVectorNodes.length
          + baselineVectorNodes.length;
        formulaNodes
          .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
          .forEach((y) => {
            makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
          });
      });

      inputInstrumentAttributeNodes.forEach((x, xi) => {
        const inOfft = 0
          + inputSensorNodes.length
          + inputMakeVectorNodes.length
          + inputVectorNodes.length
          + constantSensorNodes.length
          + baselineSensorNodes.length
          + constantVectorNodes.length
          + baselineVectorNodes.length
          + inputSensorAttributeNodes.length;
        formulaNodes
          .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.Symbol))
          .forEach((y) => {
            makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
          });
      });

      missingInputNodes.forEach((x, xi) => {
        const inOfft = 0
          + inputSensorNodes.length
          + inputMakeVectorNodes.length
          + inputVectorNodes.length
          + constantSensorNodes.length
          + baselineSensorNodes.length
          + constantVectorNodes.length
          + baselineVectorNodes.length
          + inputSensorAttributeNodes.length
          + inputInstrumentAttributeNodes.length;
        formulaNodes
          .filter((y) => this.viData.symbol_table.find((n) => n.OutputSymbol === y.OutputSymbol && n.InputSymbol === x.InputSymbol))
          .forEach((y) => {
            makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
          });
      });

      outputSensorNodes.forEach((y) => {
        const fNode = formulaNodes.find((x) => x.OutputSymbol === y.Symbol);
        const sNode = outputTimeSyncNodes.find((x) => x.Symbol === y.Symbol);
        if (fNode && sNode) {
          makeArrow(fNode, sNode, 50);
          makeArrow(sNode, y, 50);
        } else if (fNode) {
          makeArrow(fNode, y, 50);
        }
      });
      outputVectorNodes.forEach((y) => {
        const fNode = formulaNodes.find((x) => x.OutputSymbol === y.Symbol);
        const sNode = outputTimeSyncNodes.find((x) => x.Symbol === y.Symbol);
        if (fNode && sNode) {
          makeArrow(fNode, sNode, 50);
          makeArrow(sNode, y, 50);
        } else if (fNode) {
          makeArrow(fNode, y, 50);
        }
      });
      missingOutputNodes.forEach((y) => {
        const fNode = formulaNodes.find((x) => x.OutputSymbol === y.Symbol);
        makeArrow(fNode, y, 50);
      });

      this.graph.graphNodes({
        nodes: this.graph.nodes,
        edges: this.graph.edges,
        type: this.graphType,
        dir: this.graphDir,
        spacing: 100,
      });
    },
    nodeFitContent() {
      this.graph.graphNodes({
        nodes: this.graph.nodes,
        edges: this.graph.edges,
        type: this.graphType,
        dir: this.graphDir,
        spacing: 100,
      });
      this.$refs.screen.zoomNodes(this.graph.nodes);
    },

    // eslint-disable-next-line no-unused-vars
    canEditContent(node) {
      return this.viData && this.viData.IsManagedByTemplate === false;
    },

    nodeHeaderIconClick(node) {
      if (this.canEditContent(node)) {
        this.$emit('node-header-icon-click', node);
      }
    },
    getDeleteMessage(node) {
      let msg = this.$root.$i18n.t('Delete');
      if (node && node.nodeType) {
        msg += ` ${node.nodeType.replace('xform', 'transform').replace(/_/g, ' ')}`;
      }
      if (node && node.InstrumentName) {
        msg += ` ${node.InstrumentName}`;
      }
      if (node && node.SensorName) {
        msg += ` ${node.SensorName}`;
      }
      if (node && node.Symbol) {
        msg += ` ${node.Symbol}`;
      }
      msg += '?';
      return msg;
    },
    nodeFooterIconClick(action, node) {
      if (this.canEditContent(node)) {
        if (action === 'delete') {
          const msg = this.getDeleteMessage(node);
          this.$root.$confirm(msg).then((confirm) => {
            if (confirm) {
              this.$emit(`node-${action}`, node);
            }
          });
        } else {
          this.$emit(`node-${action}`, node);
        }
      }
    },
  },
  i18n: {
    messages: {
      en: {
        To: 'to',
        ValidFrom: 'Valid from',
        Delete: 'Delete',
        RefreshDashboardTitle: 'Refresh dashboard',
        Input: 'Input',
        MissingInput: 'Missing input',
        Output: 'Output',
        MissingOutput: 'Missing output',
        InputTransform: 'Input transform',
        TimeSync: 'Time sync',
        Formula: 'Formula',
        Baseline: 'Baseline',
        Constant: 'Constant',
        At: 'At',
      },
      fr: {
        To: 'à',
        ValidFrom: 'Valide de',
        Delete: 'Supprimer',
        RefreshDashboardTitle: 'Rafraîchir le tableau de bord',
        Input: 'Entrée',
        MissingInput: 'Entrée manquante',
        Output: 'Sortie',
        MissingOutput: 'Sortie manquante',
        InputTransform: 'Transformation entrée',
        TimeSync: 'Temps de synchronisation',
        Formula: 'Formule',
        Baseline: 'Repère',
        Constant: 'Constante',
        At: 'à',
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .screen {
    color: blue;
  }

  .formula-text-ellipsis {
    font-size: 80%;
  }
  .node-header {
    background-color: lightgrey;
    display: flex;
    text-align: center;
    margin: -10px -10px 0 -10px;
    padding: 0px 5px 0 5px;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
  }
  .node-footer {
    display: flex;
    margin: 0px -10px -10px -10px;
    padding: 0px 0px 0 0px;
    border-radius: 0px 0px 5px 5px;
  }
  .node-inner-missing_input::v-deep .node-header {
    background-color: orange !important;
  }
  .node-inner-missing_output::v-deep .node-header {
    background-color: orange !important;
  }

  .node-header-icon {
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
  .node-header-icon-passive {
    color: gray;
  }

  .node-inner {
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .node-formula::v-deep .background {
    background-color: lightcoral;
  }
  .node-input_sensor_xform::v-deep .background {
    background-color: lightcoral;
  }
  .node-input_make_vector_xform::v-deep .background {
    background-color: lightcoral;
  }
  .node-input_sensor::v-deep .background {
    background-color: lightblue;
  }
  .node-input_vector::v-deep .background {
    background-color: lightblue;
  }
  .node-input_make_vector::v-deep .background {
    background-color: lightblue;
  }
  .node-input_time_sync::v-deep .background {
    background-color: goldenrod;
  }
  .node-output_time_sync::v-deep .background {
    background-color: goldenrod;
  }
  .node-output_sensor::v-deep .background {
    background-color: lightsalmon;
  }
  .node-output_vector::v-deep .background {
    background-color: lightsalmon;
  }
  .node-sensor_constant::v-deep .background {
    background-color: palegreen;
  }
  .node-vector_constant::v-deep .background {
    background-color: palegreen;
  }
  .node-sensor_baseline::v-deep .background {
    background-color: aqua;
  }
  .node-vector_baseline::v-deep .background {
    background-color: aqua;
  }
  .node-missing_input::v-deep .background {
    background-color: lightgray;
  }
  .node-missing_output::v-deep .background {
    background-color: lightgray;
  }
  .node-sensor_attribute_input::v-deep .background {
    background-color: lightsteelblue;
  }
  .node-instrument_attribute_input::v-deep .background {
    background-color: rgb(139, 174, 219);
  }

  .edge-formula {
    stroke: lightcoral !important;
    marker: url(#arrow-end-formula) !important;
  }
  .edge-input_sensor_xform {
    stroke: lightcoral !important;
    marker: url(#arrow-end-input_sensor_xform) !important;
  }
  .edge-input_make_vector_xform {
    stroke: lightcoral !important;
    marker: url(#arrow-end-input_sensor_xform) !important;
  }
  .edge-input_sensor {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_sensor) !important;
  }
  .edge-input_vector {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_vector) !important;
  }
  .edge-input_make_vector {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_vector) !important;
  }
  .edge-input_time_sync {
    stroke: goldenrod !important;
    marker: url(#arrow-end-input_time_sync) !important;
  }
  .edge-output_time_sync {
    stroke: goldenrod !important;
    marker: url(#arrow-end-output_time_sync) !important;
  }
  .edge-output_sensor {
    stroke: lightsalmon !important;
    marker: url(#arrow-end-output_sensor) !important;
  }
  .edge-output_vector {
    stroke: lightsalmon !important;
    marker: url(#arrow-end-output_vector) !important;
  }
  .edge-sensor_constant {
    stroke: palegreen !important;
    marker: url(#arrow-end-sensor_constant) !important;
  }
  .edge-vector_constant {
    stroke: palegreen !important;
    marker: url(#arrow-end-vector_constant) !important;
  }
  .edge-sensor_baseline {
    stroke: aqua !important;
    marker: url(#arrow-end-sensor_baseline) !important;
  }
  .edge-vector_baseline {
    stroke: aqua !important;
    marker: url(#arrow-end-vector_baseline) !important;
  }
  .edge-missing_input {
    stroke: red !important;
    marker: url(#arrow-end-missing_input) !important;
    stroke-dasharray: 5 5;
  }
  .edge-missing_output {
    stroke: red !important;
    marker: url(#arrow-end-missing_output) !important;
    stroke-dasharray: 5 5;
  }
  .edge-sensor_attribute_input {
    stroke: lightsteelblue !important;
    marker: url(#arrow-end-sensor_attribute_input) !important;
  }
  .edge-instrument_attribute_input {
    stroke: rgb(139, 174, 219) !important;
    marker: url(#arrow-end-instrument_attribute_input) !important;
  }

  .make-vector-list {
    padding: 0;
  }
  .make-vector-list-item {
    display: block;
    font-size: xx-small;
    border-bottom: 1px white dashed;
    :last-child {
      border-bottom: none;
    }
  }
  .make-vector-list-item:last-child {
    border-bottom: none;
  }

</style>
