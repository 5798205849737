<template>
  <div class="lang-dropdown">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-select
          v-model="selectedAccessGroups"
          :items="AccessGroups"
          :placeholder="$t('Header.AccessGroups')"
          :style="{ width: '200px' }"
          item-text="Name"
          multiple
          return-object
          @change="handleItemSelected"
          v-on="on"
        />
      </template>
      <span>{{ $t('Header.SwitchAccessGroups') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'AccessGroupSwitcher',
  data: () => ({
    selectedAccessGroups: [],
    AccessGroups: [],
  }),
  computed: {
    ...mapState('app', ['tenantId', 'accessGroupIds']),
    ...mapGetters('user', ['identity']),
  },
  watch: {
    tenantId() {
      this.selectedAccessGroups = [];
      this.$store.commit('app/accessGroupIds', []);
      // Refresh access group list based on tenant when refreshing tenant
      this.AccessGroups = this.identity.listaccessgroupinfo.filter((x) => x.TenantId === this.tenantId)
        .map((x) => ({ Name: x.AccessGroupName, AccessGroupId: x.AccessGroupId }));
    },
    identity() {
      // Refresh access group list based on tenant when receiving identity
      this.AccessGroups = this.identity.listaccessgroupinfo.filter((x) => x.TenantId === this.tenantId)
        .map((x) => ({ Name: x.AccessGroupName, AccessGroupId: x.AccessGroupId }));
      this.selectedAccessGroups = this.AccessGroups.filter(
        (group) => this.accessGroupIds.includes(group.AccessGroupId),
      );
    },
    selectedAccessGroups: {
      handler() {
        // Ensure the selected items label is updated whenever selectedAccessGroups changes
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  methods: {
    handleItemSelected(selectedItems) {
      this.$store.commit('app/accessGroupIds', selectedItems.map((x) => x.AccessGroupId));
    },
  },
};
</script>
