<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <server-side-data-grid
      :grid-source="sources.GridSource"
      :show-new-button="$auth.roles.includes('Web.Sensor.Modify') && logger.PeNumber === null"
      :refresh="refresh"
      :row-data-init="rowDataInit"
    />
    <auto-form-popup
      v-model="popup.Show"
      :model="popup.Model"
      :schema="popup.Schema"
      @on-accept="onPopupAccept"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import importal from '@/api/importal';
import AutoFormPopup from '@/components/AutoFormPopup.vue';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import GridSource from '../gridsources/gsinstrumentattribute';

export default {
  components: {
    ServerSideDataGrid,
    AutoFormPopup,
  },
  props: {
    refresh: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    rowDataInit: null,
    selectedSensor: null,
    sources: {
      GridSource: null,
    },
    popup: {
      Show: false,
      Model: {
        InstrumentAttributeId: null,
        Name: null,
        Value: null,
        StartDate: null,
      },
      Schema: {
        InstrumentAttributeId: { label: 'Id', type: 'text', disabled: true },
        Name: {
          label: 'Name', type: 'text', flex: 12,
        },
        Value: {
          label: 'Value', type: 'text', flex: 12,
        },
        StartDate: {
          label: 'Start Date', type: 'DateTimePickerForm', flex: 12,
        },
      },
    },
  }),
  computed: {
    ...mapState('devices/instruments', ['logger']),
  },
  async created() {
    this.sources.GridSource = new GridSource(this, {
      onEdit: this.onEdit,
      onClone: this.onClone,
    });
  },
  methods: {
    onEdit(data) {
      this.popup.Schema.InstrumentAttributeId.class = '';
      this.popup.Model.InstrumentAttributeId = data.InstrumentAttributeId;
      this.popup.Model.Name = data.Name;
      this.popup.Model.Value = String(data.ValueStr ?? data.ValueFloat ?? data.ValueInt);
      this.popup.Model.StartDate = data.ValidFromTs ?? new Date(1752, 11, 31, 18, 42, 28);
      this.popup.Show = true;
    },
    onClone(data) {
      this.popup.Schema.InstrumentAttributeId.class = 'd-none';
      this.popup.Model.InstrumentAttributeId = null;
      this.popup.Model.Name = data.Name;
      this.popup.Model.Value = String(data.ValueStr ?? data.ValueFloat ?? data.ValueInt);
      this.popup.Model.StartDate = new Date();
      this.popup.Show = true;
    },
    onPopupAccept(data) {
      const { ValueFloat, ValueInt, ValueStr } = this.sources.GridSource
        .ParseAttributeValues(data.Value);
      if (data.InstrumentAttributeId) {
        importal
          .post('DevInstrumentAttributeUpdate', {
            InstrumentAttributeId: data.InstrumentAttributeId,
            Name: data.Name,
            ValueFloat,
            ValueInt,
            ValueStr,
            StartDate: data.StartDate,
          })
          .then((resp) => {
            this.rowDataInit = resp.data;
          });
      } else {
        importal
          .post('DevInstrumentAttributeNew', {
            InstrumentId: Number(this.$route.params.id),
            Name: data.Name,
            ValueFloat,
            ValueInt,
            ValueStr,
            StartDate: data.StartDate,
          })
          .then((resp) => {
            this.rowDataInit = resp.data;
          });
      }
    },
  },
};
</script>

<style lang="scss">
</style>
