<template>
  <v-card style="height: 100%; overflow: hidden;">
    <v-row no-gutters>
      <v-col cols="1">
        <v-card-actions
          v-if="!editModeFlag && (widgetSettings.user.selectedIcon || sourceImage)"
          class="mt-3"
          style="text-align: center"
        >
          <v-icon @click="showModal = true">
            mdi-calendar
          </v-icon>
        </v-card-actions>
      </v-col>
      <v-col cols="10">
        <v-card-text
          v-if="toggleTitle || !displayDateTime"
          style="text-align: center"
        >
          {{ displayName }}
        </v-card-text>
        <v-card-text
          v-else
          style="text-align: center;"
        >
          {{ displayDateTime }}
        </v-card-text>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <div v-if="isPictureType">
      <div v-if="hasData == null">
        <div class="loader" />
      </div>
      <div
        v-else-if="hasData === false"
        class="graphnodata"
      />
      <div
        v-else-if="!sourceBlob || sourceBlob.size === 0"
        class="iconNoImage"
      />
      <div
        v-else
        ref="displayValueEl"
        :style="displayValueStyle"
      >
        <v-img
          width="100%"
          height="100%"
          :src="sourceImage"
        />
      </div>
    </div>
    <div v-else-if="isAudioType">
      <div v-if="hasData === null">
        <div class="loader" />
      </div>
      <v-icon
        v-else-if="hasData === false"
        size="180px"
        class="d-flex align-self-center"
      >
        {{ widgetSettings.user.selectedIcon }}
      </v-icon>
      <div
        v-else
        ref="displayValueEl"
        :style="displayValueStyle"
      >
        <figure
          style="width: 100%;"
        >
          <audio
            controls
            :src="sourceAudio"
            style="width: 100%;"
          >
            <a
              :href="sourceAudio"
              download
            >
              {{ $t('Dashboard.MediaWidget.DownloadAudio') }}
            </a>
          </audio>
        </figure>
      </div>
    </div>
    <div v-else-if="isPDFType">
      <div v-if="hasData == null">
        <div class="loader" />
      </div>
      <v-icon
        v-else-if="hasData === false"
        size="180px"
        class="d-flex align-self-center"
      >
        {{ widgetSettings.user.selectedIcon }}
      </v-icon>
      <div
        v-else
        ref="displayValueEl"
        :style="displayValueStyle"
      >
        <object
          v-if="sourcePDF !== null"
          :data="sourcePDF"
          type="application/pdf"
          width="100%"
          height="500px"
        >
          <p>PDF cannot be displayed.
            <a
              :href="sourcePDF"
              target="_blank"
            >
              Download PDF
            </a>
          </p>
        </object>
      </div>
    </div>
    <div v-else-if="isVideoType">
      <div v-if="hasData == null">
        <div class="loader" />
      </div>
      <v-icon
        v-else-if="hasData === false"
        size="180px"
        class="d-flex align-self-center"
      >
        {{ widgetSettings.user.selectedIcon }}
      </v-icon>
      <div
        v-else
        ref="displayValueEl"
        :style="displayValueStyle"
      >
        <figure>
          <video
            v-if="sourceVideo !== null && videoType != null"
            autobuffer
            controls
            style="width: 100% !important; height: auto !important; max-height: 500px;"
          >
            <source
              :src="sourceVideo"
              :type="videoType"
            >
          </video>
        </figure>
      </div>
    </div>
    <v-icon
      v-else
      size="180px"
      class="d-flex align-self-center"
    >
      {{ widgetSettings.user.selectedIcon }}
    </v-icon>
    <media-feed-modal
      :show="showModal"
      :widget-settings="widgetSettings"
      @close="showModal = false"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import importal from '@/api/importal';
import datehandling from '@/components/datehandling';
import DashboardStackVueWidgetAdapter from '../DashboardStackVueWidgetAdapter';
import MediaFeedModal from './MediaFeed/MediaFeedModal.vue';

function getTextMetrics(text, font) {
  // re-use canvas object for better performance
  const canvas = getTextMetrics.canvas || (getTextMetrics.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics;
}

function getTextWidth(text, font) {
  return getTextMetrics(text, font).width;
}
function getTextHeight(text, font) {
  const metrics = getTextMetrics(text, font);
  return metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
}

function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFontSize(el = document.body, fixedFontSize = null) {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';
  return `${fontWeight} ${fixedFontSize || fontSize} ${fontFamily}`;
}

export default {
  name: 'MediaFeedVueWidget',
  components: {
    MediaFeedModal,
  },
  props: {
    widgetSettings: {
      type: Object,
      default: () => {},
      required: true,
    },
    widgetAdapter: {
      type: DashboardStackVueWidgetAdapter,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      sourcePDF: null,
      editModeFlag: false, // Directly mutated by the adapter
      toggleTitle: false,
      widgetSize: null, // Directly mutated by the adapter
      displayName: '???',
      displayDateTime: null,

      hasData: null,
      sourceBlob: null,
      sourceImage: null,
      sourceAudio: null,
      typeAudio: null,

      sourceVideo: null,
      typeVideo: null,

      showModal: false,

      maxFontSize: 500,
      minFontSize: 8,

    };
  },
  computed: {
    ...mapGetters('mediaFeed', ['initColumnIndex']),

    displayValueText() {
      return String(this.displayDateTime);
    },
    elementHeight() {
      return getTextHeight(
        this.displayValueText,
        getCanvasFontSize(this.$refs.displayValueEl),
      ) + 50;
    },
    fontSize() {
      const fontHeight = Math.max(
        Math.min(
          (7.0 * (this.widgetSize.h - 54)) / getTextHeight(
            this.displayValueText,
            getCanvasFontSize(this.$refs.displayValueEl, 12),
          ),
          parseFloat(this.maxFontSize),
        ),
        parseFloat(this.minFontSize),
      );
      const fontWidth = (10.0 * (this.widgetSize.w - 50)) / getTextWidth(
        this.displayValueText,
        getCanvasFontSize(this.$refs.displayValueEl, 12),
      );
      return Math.min(fontHeight, fontWidth);
    },
    displayValueStyle() {
      return `text-align: center; width: 100%; font-size: ${this.fontSize}px;`;
    },
    isPictureType() {
      return !this.widgetSettings.user.selectedMediaType
        || this.widgetSettings.user.selectedMediaType === 'Picture';
    },
    isAudioType() {
      return !this.widgetSettings.user.selectedMediaType
        || this.widgetSettings.user.selectedMediaType === 'Audio';
    },
    isVideoType() {
      return !this.widgetSettings.user.selectedMediaType
        || this.widgetSettings.user.selectedMediaType === 'Video';
    },
    isPDFType() {
      return !this.widgetSettings.user.selectedMediaType
        || this.widgetSettings.user.selectedMediaType === 'PDF';
    },
  },
  watch: {
    toggleTitle: {
      handler() {
        setTimeout(() => {
          this.toggleTitle = !this.toggleTitle;
        }, 5000);
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.displayName = this.widgetSettings.user.mainTitle;

      if (this.isPictureType) {
        const data = await (await importal.post('FileFeedQuery', this.widgetSettings.data)).data;

        if (data && data.Rows.length > 0) {
          const columnIndex = this.initColumnIndex(data);
          const thisRow = data.Rows[0];
          this.displayDateTime = datehandling.formatForDisplay(thisRow[columnIndex.ts]);
          const response = await importal.get(
            `FileFeedDownload?feed_id=${thisRow[columnIndex.feed_id]}&path=${encodeURIComponent(thisRow[columnIndex.path])}`, {
              responseType: 'blob',
              data: this.widgetSettings.data,
            },
          );
          const imageBlob = await response.data;
          this.sourceBlob = imageBlob.slice(0, imageBlob.size, thisRow[columnIndex.mime_type]);

          const reader = new FileReader();
          reader.readAsDataURL(this.sourceBlob);
          reader.onloadend = () => {
            this.sourceImage = reader.result;
          };
          this.hasData = true;
        } else {
          this.hasData = false;
        }
      } else if (this.isAudioType) {
        const data = await (await importal.post('FileFeedQuery', this.widgetSettings.data)).data;

        if (data && data.Rows.length > 0) {
          const columnIndex = this.initColumnIndex(data);
          const thisRow = data.Rows[0];
          this.displayDateTime = datehandling.formatForDisplay(thisRow[columnIndex.ts]);
          const mimeType = thisRow[columnIndex.mime_type];
          const feedId = thisRow[columnIndex.feed_id];
          const path = thisRow[columnIndex.path];

          await this.updateAudioFromSas(feedId, path, mimeType);
          this.hasData = true;
        } else {
          this.hasData = false;
        }
      } else if (this.isVideoType) {
        const data = await (await importal.post('FileFeedQuery', this.widgetSettings.data)).data;

        if (data && data.Rows.length > 0) {
          const columnIndex = this.initColumnIndex(data);
          const thisRow = data.Rows[0];
          this.displayDateTime = datehandling.formatForDisplay(thisRow[columnIndex.ts]);
          const mimeType = thisRow[columnIndex.mime_type];
          const feedId = thisRow[columnIndex.feed_id];
          const path = thisRow[columnIndex.path];

          await this.updateVideoFromSas(feedId, path, mimeType);
          this.hasData = true;
        } else {
          this.hasData = false;
        }
      } else if (this.isPDFType) {
        const data = await (await importal.post('FileFeedQuery', this.widgetSettings.data)).data;
        if (data && data.Rows.length > 0) {
          const columnIndex = this.initColumnIndex(data);
          const thisRow = data.Rows[0];
          this.displayDateTime = datehandling.formatForDisplay(thisRow[columnIndex.ts]);
          const mimeType = thisRow[columnIndex.mime_type];
          const feedId = thisRow[columnIndex.feed_id];
          const path = thisRow[columnIndex.path];

          await this.updatePDFFromBlob(feedId, path, mimeType);
          this.hasData = true;
        } else {
          this.hasData = false;
        }
      }
    },

    async updatePDFFromBlob(feedId, path, mimeType) {
      this.sourcePDF = null;

      const response = await importal.get(
        `FileFeedDownload?feed_id=${feedId}&path=${encodeURIComponent(path)}`, {
          responseType: 'blob',
          data: this.widgetSettings.data,
        },
      );

      const pdfBlob = await response.data;
      this.sourceBlob = pdfBlob.slice(0, pdfBlob.size, mimeType);

      const blobUrl = URL.createObjectURL(this.sourceBlob);
      this.sourcePDF = blobUrl;
    },

    async updatePDFFromSas(feedId, path) {
      this.sourcePDF = null;

      const pdfSasUrl = await (await importal.get(
        `FileFeedDownloadSas?feed_id=${feedId}&path=${encodeURIComponent(path)}`, {
          responseType: 'raw',
        },
      )).data;

      const buster = Number(new Date());
      this.sourcePDF = `${pdfSasUrl}&xyz=${buster}`;
    },

    async updateAudioFromSas(feedId, path, mimeType) {
      this.sourceAudio = null;

      const videoSasUrl = await (await importal.get(
        `FileFeedDownloadSas?feed_id=${feedId}&path=${encodeURIComponent(path)}`, {
          responseType: 'raw',
        },
      )).data;

      const buster = Number(new Date());
      this.sourceAudio = `${videoSasUrl}&xyz=${buster}`;
      this.audioType = mimeType;
    },

    async updateVideoFromSas(feedId, path, mimeType) {
      this.sourceVideo = null;

      const videoSasUrl = await (await importal.get(
        `FileFeedDownloadSas?feed_id=${feedId}&path=${encodeURIComponent(path)}`, {
          responseType: 'raw',
        },
      )).data;

      const buster = Number(new Date());
      this.sourceVideo = `${videoSasUrl}&xyz=${buster}`;
      this.videoType = mimeType;
    },

    async updateAudioFromBlob(feedId, path, mimeType) {
      const data = await (await importal.post('FileFeedQuery', this.widgetSettings.data)).data;

      if (data && data.Rows.length > 0) {
        const columnIndex = this.initColumnIndex(data);
        const thisRow = data.Rows[0];
        this.displayDateTime = datehandling.formatForDisplay(thisRow[columnIndex.ts]);
        const response = await importal.get(
          `FileFeedDownload?feed_id=${feedId}&path=${encodeURIComponent(path)}`, {
            responseType: 'blob',
            data: this.widgetSettings.data,
          },
        );
        const imageBlob = await response.data;
        this.sourceBlob = imageBlob.slice(0, imageBlob.size, mimeType);

        const reader = new FileReader();
        reader.readAsDataURL(this.sourceBlob);
        reader.onloadend = () => {
          this.sourceAudio = reader.result;
        };
        this.hasData = true;
      } else {
        this.hasData = false;
      }
    },

  },
};
</script>
