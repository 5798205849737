import gateways from './gateways/gateways.store';
import instruments from './instruments/instruments.store';

export default {
  namespaced: true,
  modules: {
    gateways,
    instruments,
  },
};
