import WorkersList from './components/WorkersList.vue';
import WorkersFormModal from './components/WorkersFormModal.vue';
import WorkersTokenModal from './components/WorkersTokenModal.vue';

export default [
  {
    path: '/Communication/Workers',
    name: 'Worker.Workers.List',
    component: WorkersList,
    children: [
      {
        path: '/Communication/Workers/Create',
        name: 'Worker.Workers.Create',
        component: WorkersFormModal,
      },
      {
        path: '/Communication/Workers/:id/Token',
        name: 'Worker.Workers.Token',
        component: WorkersTokenModal,
        props: true,
      },
      {
        path: '/Communication/Workers/:id',
        name: 'Worker.Workers.Update',
        component: WorkersFormModal,
        props: true,
      },
    ],
  },
];
