import i18n from '@/plugins/i18n';
import api from '@/api/importal';

const state = {
  templateSection: null,
  templateSections: [],
  templateId: null,
  sectionTypes: null,
  nameExists: false,
};

const actions = {
  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCTemplateSectionsList?id=${state.templateId}`);
    commit('templateSections', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async sectionTypesList({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('QCTemplateSectionTypesList');
    const typesList = [];
    response.data.forEach((type) => {
      // eslint-disable-next-line no-param-reassign
      typesList.push({
        Id: type.Id,
        Name: i18n.t('QualityCheck.QCTemplateSections.Types.'.concat(type.Name)),
      });
    });
    commit('sectionTypes', typesList);
    commit('app/pleaseWait', false, { root: true });
  },
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCTemplateSectionsCreate', {
      Name: payload.Name,
      QCTemplateSectionId: null,
      TypeId: payload.TypeId,
      QCInspectionTemplateId: state.templateId,
    });
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCTemplateSectionsGet?id=${payload.sectionId}`);
    commit('templateSection', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('QCTemplateSectionsUpdate', {
      Name: payload.Name,
      QCTemplateSectionId: state.templateSection.QCTemplateSectionId,
      TypeId: payload.TypeId,
      QCInspectionTemplateId: state.templateSection.QCInspectionTemplateId,
    });
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`QCTemplateSectionsRemove?id=${payload.id}`);
    commit('templateSection', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('nameExists', false);
    const response = await api.get(`QCTemplateSectionsValidate?id=${state.templateSection == null ? null : state.templateSection.QCTemplateSectionId}&name=${payload.Name}`);
    commit('nameExists', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async setTemplateId({ commit }, payload) {
    commit('templateId', payload);
  },
  async setTemplateSection({ commit }, payload) {
    commit('templateSection', payload);
  },
};

const mutations = {
  templateSections(state, payload) {
    state.templateSections = payload;
  },
  templateSection(state, payload) {
    state.templateSection = payload;
  },
  templateId(state, payload) {
    state.templateId = payload;
  },
  sectionTypes(state, payload) {
    state.sectionTypes = payload;
  },
  nameExists(state, payload) {
    state.nameExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
