<template>
  <v-tab-item
    v-if="$auth.roles.includes('Web.AccessGroup.Manage')"
    style="height: calc(100% - 110px);"
  >
    <AutoFormPopup
      v-if="editPopup.Show"
      v-model="editPopup.Show"
      :model="editPopup.Model"
      :schema="editPopup.Schema"
      @on-accept="onEditPopupAccept"
    />
    <ConfirmDialog
      v-model="dialog.Show"
      :message="dialog.Message"
      :no-cancel="dialog.NoCancel"
      :title="dialog.Title"
      @on-accept="dialog.acceptCallback()"
    />
    <ServerSideDataGrid
      :grid-source="sources.AccessGroupSource"
      :show-new-button="$auth.roles.includes('Web.AccessGroup.Manage')"
      :refresh="refresh"
      :validate-popup-values="true"
    />
  </v-tab-item>
</template>

<script>
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import AutoFormPopup from '@/components/AutoFormPopup.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import AccessGroupSource from '../gridsources/gsaccessgroups';

export default {
  components: {
    AutoFormPopup,
    ConfirmDialog,
    ServerSideDataGrid,
  },
  props: {
    refresh: {
      type: Number,
      default: () => (0),
    },
  },
  data: () => ({
    editPopup: {
      Show: false,
      Model: null,
      Schema: null,
    },
    dialog: {
      Message: '',
      NoCancel: false,
      Title: '',
      Show: false,
      acceptCallback: null,
    },
    sources: {
      AccessGroupSource: null,
    },
  }),
  watch: {
    refresh: {
      async handler() {
        await this.init();
      },
      immediate: true,
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      this.sources.AccessGroupSource = new AccessGroupSource(this);
    },
    async onEditPopupAccept(data) {
      const errorMessage = this.sources.AccessGroupSource.validateValues(data);

      if (!errorMessage || errorMessage.length === 0) {
        await this.sources.AccessGroupSource.apiOpOrgAccessGroupRename(data);
      } else {
        this.showWarning(
          errorMessage,
          () => this.sources.AccessGroupSource.onEditButtonClick(data.Api, data),
        );
      }
    },
    showWarning(message, callback) {
      this.dialog.Message = message;
      this.dialog.NoCancel = true;
      this.dialog.Title = this.$t('Settings.Dialog.Warning');
      this.dialog.acceptCallback = callback;
      this.dialog.Show = true;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
