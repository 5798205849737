<template>
  <div
    class="bg-overlay"
    style="position: fixed;margin: 0px;width: 100%;height: 100%;"
  >
    <HeaderBar />
    <NavigationMenu />
    <v-main class="fill-height">
      <div
        v-if="$auth.isAuthenticated && $auth.info.listaccessgroupinfo.length === 0"
        style="background-color: royalblue; text-align: center; font-size: x-large;"
      >
        Registration complete
      </div>
      <router-view v-if="$auth.isAuthenticated && $auth.info.listaccessgroupinfo.length !== 0" />
    </v-main>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import HeaderBar from '../HeaderBar.vue';
import NavigationMenu from '../NavigationMenu.vue';

export default {
  name: 'LoggedIn',
  components: {
    NavigationMenu,
    HeaderBar,
  },
  computed: {
    ...mapState('app', ['activeLink']),
    ...mapGetters('app', ['pageTitle']),
  },
  watch: {
    '$auth.isAuthenticated': function w(value) {
      if (value) {
        this.$store.commit('user/identity', this.$auth.info);
      }
    },
  },
  created() {
    this.refreshPageTitle();
  },
  methods: {
    logout() {
      // TODO: Implément a confirm notification
      const auth = this.$auth;
      auth.logoutWithRedirect();
    },
    refreshPageTitle() {
      this.$store.commit('app/activeLink', this.$route.name);
    },
  },
};
</script>
