/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsValidation extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.callback = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          width: 40,
          resizable: false,
        },
      ]);
    });
  }
}
