<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <layer-picker-dialog
      v-models:show="layerPickerDialog.show"
      v-models:layer="layerPickerDialog.layer"
      @click:submit="layerPickerDialogSubmit"
    />
    <mdi-icon-picker-dialog
      v-models:show="iconPickerDialog.show"
      v-models:icon="iconPickerDialog.icon"
      @click:submit="iconPickerDialogSubmit"
    />
    <location-selector
      v-models:show="locationDialog.show"
      v-models:latitude="locationDialog.latitude"
      v-models:longitude="locationDialog.longitude"
      @click:submit="onLocationSelectorSubmit"
    />
    <v-dialog
      v-if="showAGDialog"
      v-model="AGDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('Devices.Icons.ChangeAccessGroup') }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedItem"
            :items="items"
            :label="$t('Devices.Gateway.AddNew.AccessGroup')"
            item-text="AccessGroupName"
            item-value="AccessGroupId"
          />
        </v-card-text>
        <v-card-actions style="justify-content: right;">
          <v-btn @click="closeDialog">
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Cancel') }}
          </v-btn>
          <v-btn @click="confirmSelection">
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <server-side-data-grid
      :refresh="refresh"
      :grid-source="sources.GsInstrument"
      :show-new-button="$auth.roles.includes('Web.Tenant.Create')"
      :toolbar-buttons="toolbarButtons"
      enable-selection="multiple"
      @selection-changed="onSelect"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import i18n from '@/plugins/i18n';
import importal from '@/api/importal';
import PointGeolocationCellRenderer from '@/components/PointGeolocationCellRenderer.vue';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import GsInstrument from './gridsources/gsinstrument';
import LayerPickerDialog from './LayerPickerDialog.vue';
import MdiIconPickerDialog from './MdiIconPickerDialog.vue';
import LocationSelector from './LocationSelector.vue';

export default {
  components: {
    ServerSideDataGrid,
    // eslint-disable-next-line vue/no-unused-components
    PointGeolocationCellRenderer,
    LayerPickerDialog,
    MdiIconPickerDialog,
    LocationSelector,
  },
  data: () => ({
    refresh: 0,
    sources: {
      GsInstrument: null,
    },
    toolbarButtons: [],
    selectedRows: [],
    layerPickerDialog: {
      show: false,
      layer: '',
    },
    iconPickerDialog: {
      show: false,
      icon: '',
    },
    locationDialog: {
      show: false,
      latitude: '0',
      longitude: '0',
    },
    AGDialog: false,
    showAGDialog: false,
    selectedItem: null,
    items: [],
  }),
  computed: {
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),

    setLayerButtonEnabled() {
      return this.selectedRows.length > 0;
    },
  },
  watch: {
    async tenantId() {
      this.refresh += 1;
    },
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.refresh += 1;
      }
    },
  },
  created() {
    this.sources.GsInstrument = new GsInstrument(this);
  },
  mounted() {
    this.toolbarButtons = [
      {
        tooltip: i18n.t('Refresh'),
        icon: 'mdi-refresh',
        enabled: true,
        click: () => { this.doRefresh(); },
      },
      {
        tooltip: i18n.t('Devices.Icons.SetLayer'),
        icon: 'mdi-layers-outline',
        enabled: this.setLayerButtonEnabled,
        click: () => { this.onSetLayerClick(); },
      },
      {
        tooltip: i18n.t('Devices.Icons.SetIcon'),
        icon: 'mdi-crop-free',
        enabled: this.setLayerButtonEnabled,
        click: () => { this.onSetIconClick(); },
      },
      {
        tooltip: i18n.t('Devices.Icons.SetLocation'),
        icon: 'mdi-map-marker',
        enabled: this.setLayerButtonEnabled,
        click: () => { this.onSetLocationClick(); },
      },
      {
        tooltip: i18n.t('Devices.Icons.ChangeAccessGroup'),
        icon: 'mdi-account-switch',
        enabled: this.setLayerButtonEnabled,
        click: () => { this.onSetAGClick(); },
      },
    ];
  },
  methods: {
    onSelect(e) {
      const { api } = e;
      this.selectedRows = api.getSelectedRows();
      [1, 2, 3, 4].forEach((x) => {
        this.toolbarButtons[x].enabled = this.setLayerButtonEnabled;
      });
    },

    setToolBarButtonsDisabled() {
      [1, 2, 3, 4].forEach((x) => {
        this.toolbarButtons[x].enabled = 0;
      });
    },

    onSetIconClick() {
      const valid = this.selectedRows
        .filter((x) => !!x.Icon && x.Icon.length > 3)
        .map((x) => x.Icon);
      if (valid.length > 0) {
        [this.iconPickerDialog.icon] = valid;
      } else {
        this.iconPickerDialog.icon = '';
      }
      this.iconPickerDialog.show = true;
    },
    iconPickerDialogSubmit(icon) {
      this.devInstrumentAttributeUpdateStrSelected('$ICON$', icon);
    },

    onSetLayerClick() {
      const valid = this.selectedRows
        .filter((x) => !!x.Layer && x.Layer.length > 3)
        .map((x) => x.Layer);
      if (valid.length > 0) {
        [this.layerPickerDialog.layer] = valid;
      } else {
        this.layerPickerDialog.layer = '';
      }
      this.layerPickerDialog.show = true;
    },
    layerPickerDialogSubmit(layer) {
      this.devInstrumentAttributeUpdateStrSelected('$LAYER$', layer);
    },

    async onSetAGClick() {
      this.showAGDialog = true;
      this.items = await (await importal.get('AccessGroupList')).data;
      this.AGDialog = true;
    },

    devInstrumentAttributeUpdateStrSelected(name, value) {
      const tasks = this.selectedRows.map((row) => importal
        .post('DevInstrumentAttributeUpdateStr', {
          InstrumentId: row.InstrumentId,
          Name: name,
          ValueStr: value,
        }));
      this.selectedRows = [];
      this.setToolBarButtonsDisabled();

      this.$PleaseWaitAsync(tasks)
        .then(() => {
          this.refresh += 1;
        });
    },

    devInstrumentRelocateSelected(lat, lon) {
      const tasks = this.selectedRows.map((row) => importal
        .post('DevInstrumentRelocate',
          {
            InstrumentId: row.InstrumentId,
            Latitude: parseFloat(lat),
            Longitude: parseFloat(lon),
          }));
      this.selectedRows = [];
      this.setToolBarButtonsDisabled();

      this.$PleaseWaitAsync(tasks)
        .then(() => {
          this.refresh += 1;
        });
    },

    onLocationSelectorSubmit({ latitude, longitude }) {
      this.locationDialog.latitude = latitude;
      this.locationDialog.longitude = longitude;
      this.devInstrumentRelocateSelected(latitude, longitude);
    },
    closeDialog() {
      this.showAGDialog = false;
    },
    confirmSelection() {
      this.showAGDialog = false;
      const tasks = this.selectedRows.map((row) => importal
        .post('DevInstrumentAccessGroupUpdate', {
          InstrumentId: row.InstrumentId,
          AccessGroupId: this.selectedItem,
        }));

      this.selectedRows = [];
      this.setToolBarButtonsDisabled();

      this.$PleaseWaitAsync(tasks)
        .then(() => {
          this.refresh += 1;
        });
    },
    onSetLocationClick() {
      const valid = this.selectedRows
        .filter((x) => !!x.GeoLocation && !!x.GeoLocation.coordinates)
        .map((x) => x.GeoLocation.coordinates);
      if (valid.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.locationDialog.longitude = String(valid[0][0]);
        // eslint-disable-next-line prefer-destructuring
        this.locationDialog.latitude = String(valid[0][1]);
      } else {
        this.locationDialog.longitude = '0';
        this.locationDialog.latitude = '0';
      }
      this.locationDialog.show = true;
    },
    doRefresh() {
      this.refresh += 1;
    },
  },
};
</script>

<style lang="scss">
</style>
