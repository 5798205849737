/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsModels extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.WorkerConfiguration.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 80,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.WorkerConfiguration.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'Worker.WorkerConfigs.Update',
                params: {
                  workerid: event.data.WorkerId,
                  id: event.data.WorkerConfigurationId,
                },
              });
            },
          },
        },
        {
          headerName: this.vue.$t('Worker.WorkerConfigurations.Columns.WorkerId'),
          field: 'WorkerId',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.WorkerConfigurations.Columns.WorkerConfigurationId'),
          field: 'WorkerConfigurationId',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.WorkerConfigurations.Columns.Name'),
          field: 'Name',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.WorkerConfigurations.Columns.LastExecutedDate'),
          field: 'LastExecutedDate',
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
        },
        {
          headerName: this.vue.$t('Worker.WorkerConfigurations.Columns.LastErrorDate'),
          field: 'LastErrorDate',
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
        },
        {
          headerName: this.vue.$t('Worker.WorkerConfigurations.Columns.LastError'),
          field: 'LastError',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 80,
          resizable: true,
          hide: !this.vue.$auth.roles.includes('Web.WorkerConfiguration.Manage'),
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            show: () => true,
            confirm: (event) => (event.data.Name),
            clicked: async (event) => {
              await this.vue.$store.dispatch('worker/workerconfigs/remove', { id: event.data.WorkerConfigurationId });
            },
          },
        },
      ]);
    });
  }
}
