import VirtualInstrumentTemplateSetList from './VirtualInstrumentTemplateSetList.vue';
import VirtualInstrumentTemplateSetEdit from './VirtualInstrumentTemplateSetEdit.vue';

export default [
  {
    path: '/tpls',
    name: 'VirtualInstrumentTemplateSet.List',
    component: VirtualInstrumentTemplateSetList,
  },
  {
    path: '/tpls/:id',
    name: 'VirtualInstrumentTemplateSet.Edit',
    component: VirtualInstrumentTemplateSetEdit,
  },
];
