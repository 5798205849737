<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    @keydown="handleKeyDown"
  >
    <v-card>
      <v-toolbar
        dense
        flat
      >
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pt-3"
        v-html="message"
      />
      <v-card-actions class="pt-3">
        <v-spacer />
        <v-btn
          v-if="!noCancel"
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click.native="onCancel"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="body-2 font-weight-bold"
          outlined
          @click.native="onAccept"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    message: {
      type: String,
      default: () => undefined,
      required: false,
    },
    title: {
      type: String,
      default: () => 'Confirm',
      required: false,
    },
    noCancel: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data: () => ({
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  created() {
  },
  methods: {
    onAccept() {
      this.show = false;
      this.$emit('on-accept');
    },
    onCancel() {
      this.show = false;
      this.$emit('on-cancel');
    },
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.onAccept();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        this.show = false;
      }
    },
    handleInput() {
    },
  },
  i18n: {
    messages: {
      en: {
        Cancel: 'Cancel',
      },
      fr: {
        Cancel: 'Annuler',
      },
    },
  },
};
</script>
