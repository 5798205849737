/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsTenants extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$t('Edit'),
          width: 80,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            show: (event) => (!event.data.CanOperateNetwork),
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'Settings.Tenants.Update',
                params: {
                  id: event.data.TenantId,
                },
              });
            },
          },
        },
        {
          field: 'TenantId',
          headerName: this.vue.$t('Settings.Tenants.TenantId'),
          minWidth: 250,
          resizable: true,
        },
        {
          field: 'Name',
          headerName: this.vue.$t('Settings.Tenants.Name'),
          filter: true,
          sortable: true,
        },
        {
          field: 'ClientMnemonic',
          headerName: this.vue.$t('Settings.Tenants.TenantMnemonic'),
          filter: true,
          sortable: true,
        },
        {
          field: 'CanOperateNetwork',
          headerName: this.vue.$t('Settings.Tenants.CanOperateNetwork'),
          filter: true,
          sortable: true,
          valueGetter: (params) => (params.data.CanOperateNetwork ? this.vue.$t('Settings.Tenants.Yes') : this.vue.$t('Settings.Tenants.No')),
        },
        {
          field: 'ProjectManager.DisplayName',
          headerName: this.vue.$t('Settings.Tenants.ProjectManager'),
          filter: true,
          sortable: true,
        },
      ]);
    });
  }
}
