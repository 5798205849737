<template>
  <div :class="classText">
    <v-tooltip
      :disabled="tooltip == null || tooltip.length === 0"
      bottom
    >
      <template #activator="{ on, attrs }">
        <div
          multiple
          v-on="on"
        >
          <v-select
            v-model="selectedItems"
            :items="items"
            item-text="Name"
            :label="label"
            :placeholder="placeholder"
            v-bind="attrs"
            class="cursor-pointer"
            :style="`width: ${widthPx}px;`"
            multiple
            hide-details
            return-object
            v-on="on"
            @change="emitSelectedItems"
          >
            <template #selection="{ index }">
              <span v-if="index < 1">
                <span>{{ selectedDisplayValue }}</span>
              </span>
            </template>
            <template
              slot="item"
              slot-scope="data"
            >
              <span>{{ data.item.Name }}</span>
              <v-spacer />
              <span v-if="itemAppendProp && data.item[itemAppendProp]">({{ data.item[itemAppendProp] }})</span>
            </template>
          </v-select>
        </div>
      </template>
      <div style="white-space: pre;">
        {{ tooltip }}
      </div>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  name: 'MultiSelectEllipsis',
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'selectedItems', event: 'models:selectedItems' },
  ],
  props: {
    items: {
      type: Array,
      default: () => [],
      required: false,
    },
    widthPx: {
      type: Number,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    tooltipText: {
      type: String,
      default: '',
      required: false,
    },
    classText: {
      type: String,
      default: '',
      required: false,
    },
    itemAppendProp: {
      type: String,
      default: null,
      required: false,
    },
  },
  data: () => ({
    selectedItems: [],
    selectedDisplayValue: '',
    nbLettersForTruncate: 0,
  }),
  computed: {
    tooltip() {
      let text = this.tooltipText;

      if (this.selectedItems.length > 0) {
        if (text.length > 0) {
          text = text.concat('\n\n');
        }
        text = text.concat(`${this.$t('Header.Selected')} :`);
        // eslint-disable-next-line no-return-assign
        this.selectedItems.forEach((x) => {
          text = text.concat(`\n\t${x.Name}`);
          if (this.itemAppendProp && x[this.itemAppendProp]) {
            text = text.concat(` (${x[this.itemAppendProp]})`);
          }
        });
      }

      return text;
    },
  },
  watch: {
    items() {
      this.selectedItems = [];
    },
    selectedItems(newVal) {
      this.truncateText();
      this.$emit('models:selectedItems', newVal);
    },
  },
  created() {
    const textWidth = this.widthPx - 20;
    const approxLetterPx = 9.5;
    const ellipsisLength = 2 * approxLetterPx;
    this.nbLettersForTruncate = Math.floor((textWidth - ellipsisLength) / approxLetterPx);
  },
  methods: {
    truncateText() {
      if (this.selectedItems.length === 0) {
        return;
      }

      const item = this.selectedItems[0];

      if (this.selectedItems.length === 1) {
        // 'ItemName' or 'LongItemNam...'
        this.selectedDisplayValue = item.Name.length <= this.nbLettersForTruncate
          ? item.Name
          : `${item.Name.slice(0, this.nbLettersForTruncate)}...`;
      } else {
        // 'ItemName (+nb)' or 'LongItemNam... (+nb)'
        const nbLetter = this.nbLettersForTruncate - 3;
        const text = item.Name.length <= nbLetter ? item.Name : `${item.Name.slice(0, nbLetter)}...`;
        this.selectedDisplayValue = `${text} (+${this.selectedItems.length - 1})`;
      }
    },
    emitSelectedItems() {
      this.$emit('item-selected', this.selectedItems);
    },
  },
};
</script>

<style lang="scss">
.lang-dropdown {
  font-size: 1rem;
  font-weight: 600;
  div.v-input {
    div.v-input__control {
      div.v-text-field__details {
        display: none;
      }
      div.v-input__slot::before {
        border: none;
      }
      div.v-input__slot {
        margin: 0;
      }
    }
  }
}

</style>
