<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="50vw"
  >
    <v-card style="display: flex; flex-direction: column; height: 100%;">
      <v-card-title>
        <span class="text-h5">{{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Share') }}</span>
      </v-card-title>
      <v-card-subtitle v-if="!!sensor">
        {{ sensor.Name }}
      </v-card-subtitle>
      <div style="display: flex; justify-content: space-between; flex: 1;">
        <div style="height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 4px; margin: auto;">
          <v-list dense>
            <v-list-item
              v-for="(user, index) in filteredUsers"
              :key="user.UserId"
              :class="{ 'selected-item': isNoSharedSelected(index) }"
              @click="toggleNoShared(index)"
            >
              <template v-slot:default="{ }">
                <v-icon left>
                  {{ user.icon }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ user.UserName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.group }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list>
        </div>

        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; margin: auto;">
          <v-btn
            icon
            :disabled="selectedNoShared.length === 0"
            @click="sendToShare()"
          >
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            icon
            :disabled="selectedShared.length === 0"
            @click="sendToNoShare()"
          >
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-btn>
        </div>

        <div style="height: 300px; overflow-y: auto; border: 1px solid #ccc; border-radius: 4px; margin: auto">
          <v-list dense>
            <v-list-item
              v-for="(user, index) in sharedWith"
              :key="user.UserId"
              :class="{ 'selected-item': isSharedSelected(index) }"
              @click="toggleShared(index)"
            >
              <template v-slot:default="{ }">
                <v-icon left>
                  {{ user.icon }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ user.UserName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.group }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list>
        </div>
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="onShare"
        >
          {{ $t('Submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    sensor: {
      type: Object,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      selectedNoShared: [],
      filteredUsers: [],
      searchInput: null,
      sharedWith: [],
      selectedShared: [],
      sharedUsersList: [],
      sharedAGList: [],
    };
  },
  computed: {
    ...mapState('user', ['users']),
    ...mapState('user', ['accessGroups']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    searchInput(val) {
      this.filteredUsers = this.searchUserGroup(val);
    },
  },
  async created() {
    this.$store.commit('app/pleaseWait', true);
    await this.$store.dispatch('user/list');
    await this.$store.dispatch('user/accessGroupList');
    this.$store.commit('app/pleaseWait', false);
    this.getSharedUsers(this.sensor);
    this.getFilteredUsers();
  },
  methods: {
    toggleNoShared(index) {
      if (this.selectedNoShared.includes(index)) {
        this.selectedNoShared = this.selectedNoShared.filter((i) => i !== index);
      } else {
        this.selectedNoShared.push(index);
      }
    },
    toggleShared(index) {
      if (this.selectedShared.includes(index)) {
        this.selectedShared = this.selectedShared.filter((i) => i !== index);
      } else {
        this.selectedShared.push(index);
      }
    },
    isNoSharedSelected(index) {
      return this.selectedNoShared.includes(index);
    },

    isSharedSelected(index) {
      return this.selectedShared.includes(index);
    },
    sendToShare() {
      if (this.selectedNoShared.length > 0) {
        const selectedUsers = this.selectedNoShared.map((index) => this.filteredUsers[index]);
        this.sharedWith.push(...selectedUsers);
        selectedUsers.forEach((x) => {
          if (x.icon === 'mdi-account-outline') {
            this.sharedUsersList.push(x);
          } else {
            this.sharedAGList.push(x);
          }
        });
        this.filteredUsers = this.filteredUsers.filter(
          (_, index) => !this.selectedNoShared.includes(index),
        );
        this.selectedNoShared = [];
      }
    },
    sendToNoShare() {
      if (this.selectedShared.length > 0) {
        const nonStarredSelected = this.selectedShared.filter((index) => {
          const selectedItem = this.sharedWith[index];
          return selectedItem.icon !== 'mdi-star';
        });

        const removedElements = this.sharedWith.filter(
          (_, index) => nonStarredSelected.includes(index),
        );
        this.sharedWith = this.sharedWith.filter((_, index) => !nonStarredSelected.includes(index));

        this.sharedUsersList = this.sharedUsersList.filter(
          (item) => !removedElements.some(
            (removedItem) => removedItem.UserId === item.UserId && removedItem.UserName === item.UserName,
          ),
        );

        this.sharedAGList = this.sharedAGList.filter(
          (item) => !removedElements.some(
            (removedItem) => removedItem.UserId === item.UserId && removedItem.UserName === item.UserName,
          ),
        );

        this.getFilteredUsers();
        this.selectedShared = [];
      }
    },
    getSharedUsers(d) {
      const ulistAG = [];
      const ulistUsers = [];
      // Do for users
      if (d && d.sharedUsers && d.sharedUsers.length > 0) {
        d.sharedUsers.forEach((u) => {
          const match = this.users.find((a) => a.UserId === u.id);
          if (match) {
            if (u.id === d.UserId) {
              ulistUsers.push({
                UserName: match.UserName, UserId: match.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-star',
              });
            } else {
              ulistUsers.push({
                UserName: match.UserName, UserId: match.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-account-outline',
              });
            }
          }
        });
      } else {
        const rightUser = this.users.find((a) => a.UserId === this.sensor.UserId);
        if (rightUser) {
          ulistUsers.push({
            UserName: rightUser.UserName, UserId: rightUser.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-star',
          });
        }
      }
      // Do for AG
      d.sharedAccessGroups.forEach((u) => {
        const match = this.accessGroups.find((a) => +a.AccessGroupId === +u.id);
        if (match) {
          ulistAG.push({
            UserName: match.Name, UserId: match.AccessGroupId, group: this.$root.$i18n.t('Dashboard.Share.AccessGroup'), icon: 'mdi-account-group-outline',
          });
        }
      });

      // Final push
      const ulist = [];
      ulistUsers.forEach((u) => {
        ulist.push(u);
        this.sharedUsersList.push(u);
      });
      ulistAG.forEach((ag) => {
        ulist.push(ag);
        this.sharedAGList.push(ag);
      });

      this.sharedWith = ulist;
    },
    getFilteredUsers() {
      const list = [];
      if (this.sharedWith.length > 0) {
        this.users.forEach((u1) => {
          let alreadyShared = false;
          this.sharedWith.forEach((u2) => {
            if (u1.UserId === u2.UserId || u1.UserId === this.sensor.UserId) {
              alreadyShared = true;
            }
          });
          if (!alreadyShared) {
            list.push({
              UserName: u1.UserName, UserId: u1.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-account-outline',
            });
          }
        });
        this.accessGroups.forEach((g1) => {
          let alreadyShared = false;
          this.sharedWith.forEach((g2) => {
            if (g1.AccessGroupId === g2.UserId) {
              alreadyShared = true;
            }
          });
          if (!alreadyShared) {
            list.push({
              UserName: g1.Name, UserId: g1.AccessGroupId, group: this.$root.$i18n.t('Dashboard.Share.AccessGroup'), icon: 'mdi-account-group-outline',
            });
          }
        });
      }
      this.filteredUsers = list;
    },
    searchUserGroup(search) {
      if (!search || search.length === 0) { return ''; }
      const list = [];
      const us = this.users.filter((u) => u.UserName.toLowerCase().includes(search.toLowerCase()));
      const gs = this.accessGroups.filter(
        (a) => a.Name.toLowerCase().includes(search.toLowerCase()),
      );
      if (us && us.length > 0) {
        list.push({ header: this.$root.$i18n.t('Dashboard.Share.Users') });
        us.forEach((u) => {
          list.push({
            UserName: u.UserName, UserId: u.UserId, group: this.$root.$i18n.t('Dashboard.Share.User'), icon: 'mdi-account-outline',
          });
        });
      }
      if (gs && gs.length > 0) {
        list.push({ header: this.$root.$i18n.t('Dashboard.Share.AccessGroups') });
        gs.forEach((g) => {
          list.push({
            UserName: g.Name, UserId: g.AccessGroupId, group: this.$root.$i18n.t('Dashboard.Share.AccessGroup'), icon: 'mdi-account-group-outline',
          });
        });
      }
      return list;
    },
    async refresh(close) {
      this.$store.commit('app/pleaseWait', true);
      setTimeout(async () => {
        // await this.$store.dispatch('dashboard/list');
        this.$store.commit('app/pleaseWait', false);
        if (close) {
          this.show = false;
        }
      }, 2000);
    },
    async withOverlayAsync(body) {
      this.$store.commit('app/pleaseWait', true);
      await body()
        .finally(() => { this.$store.commit('app/pleaseWait', false); });
    },
    async onShare() {
      const origUsers = this.sensor.sharedUsers;
      const alert = [];
      await this.doShareUsers(origUsers, alert);
      const origAG = this.sensor.sharedAccessGroups;
      await this.doShareAG(origAG, alert);

      if (alert.length > 0) {
        const alertData = await alert[0].data;
        this.$emit('close-alert-share', alertData);
      } else {
        this.$emit('close-alert-share');
      }
    },

    async doShareAG(listAG, alertSoloList) {
      let diff = null;
      let alert = null;
      if (listAG && listAG.length > 0) {
        if (this.sharedAGList.length === 0) {
          diff = listAG;
        }
        diff = listAG.filter(
          (x) => this.sharedAGList.map((i) => i.UserId).indexOf(x.id) < 0,
        );
        if (diff && diff.length > 0) {
          await Promise.all(diff.map(async (d) => {
            // alert = await importal.post('AlertBasicShareWithGroup', {
            alert = await this.$store.dispatch('alert/shareWithGroupV3', {
              AlertId: this.sensor.BasicAlertConfigId,
              AccessGroupId: +d.id,
              IsShared: false,
            });
          }));
        }
      }
      await Promise.all(this.sharedAGList.map(async (u) => {
        // alert = await importal.post('AlertBasicShareWithGroup', {
        alert = await this.$store.dispatch('alert/shareWithGroupV3', {
          AlertId: this.sensor.BasicAlertConfigId,
          AccessGroupId: +u.UserId,
          IsShared: true,
        });
      }));
      if (alert) {
        alertSoloList.splice(0, 1, alert);
      }
    },

    async doShareUsers(listUser, alertSoloList) {
      let alert = null;
      let diff = null;
      if (listUser && listUser.length > 0) {
        if (this.sharedUsersList.length === 0) {
          diff = listUser;
        }
        diff = listUser.filter(
          (x) => this.sharedUsersList.map((i) => i.UserId).indexOf(x.id) < 0,
        );
        if (diff && diff.length > 0) {
          await Promise.all(diff.map(async (d) => {
            // alert = await importal.post('AlertBasicShareWithUser', {
            alert = await this.$store.dispatch('alert/shareWithUserV3', {
              AlertId: this.sensor.BasicAlertConfigId,
              UserId: d.id,
              IsShared: false,
            });
          }));
        }
      }
      await Promise.all(this.sharedUsersList.map(async (u) => {
        // alert = await importal.post('AlertBasicShareWithUser', {
        alert = await this.$store.dispatch('alert/shareWithUserV3', {
          AlertId: this.sensor.BasicAlertConfigId,
          UserId: u.UserId,
          IsShared: true,
        });
      }));
      if (alert) {
        alertSoloList.push(alert);
      }
    },
    onCancel() {
      this.show = false;
      this.$emit('close-alert-share');
    },
  },
};
</script>

<style>
.selected-item {
  background-color: #d3d3d336;
}
</style>
