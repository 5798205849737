<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-card-text>
          <v-container fluid>
            <v-select
              ref="propertyType"
              v-model="formData.PropertyType"
              :label="$t('WindServer.Sites.LoggerConfiguration.Columns.Property')"
              :items="properties"
              :rules="requiredRule"
              item-text="Name"
              item-value="PropertyTypeId"
              return-object
            />
            <v-select
              v-if="isData"
              v-model="formData.StatisticType"
              :label="$t('WindServer.Sites.LoggerConfiguration.Columns.Statistic')"
              :items="statisticTypes"
              :rules="requiredRule"
              item-text="Name"
              item-value="StatisticTypeId"
              return-object
            />
            <v-select
              v-if="isData"
              v-model="formData.UnitOfMeasure"
              :label="$t('WindServer.Sites.LoggerConfiguration.Columns.UnitOfMeasure')"
              :items="unitsOfMeasure"
              :rules="requiredRule"
              item-text="NameI18n"
              item-value="UnitOfMeasureId"
              return-object
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      properties: null,
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['measurementPoint', 'propertyTypes', 'statisticTypes', 'unitsOfMeasure']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$store.dispatch('site/loggerConfigurations/measurementPoint', null);
        this.$emit('input', value);
      },
    },
    isData() {
      return this.formData.PropertyType != null
      && this.formData.PropertyType.PropertyTypeId !== -1;
    },
  },
  methods: {
    async setFormData() {
      if (this.measurementPoint) {
        this.formData = JSON.parse(JSON.stringify(this.measurementPoint));
      } else { // Create mode
        this.formData = {
          DataFileImportSettingId: this.id,
        };
      }
      this.properties = JSON.parse(JSON.stringify(this.propertyTypes));
      this.properties.splice(0, 0, {
        Name: i18n.t('WindServer.Sites.LoggerConfiguration.ColumnTypes.'.concat('Ignore')),
        Code: '',
        PropertyTypeId: -1,
      });
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (this.measurementPoint) {
            await this.$store.dispatch('site/loggerConfigurations/updateMeasurementPoint', this.formData);
            this.close();
          } else {
            await this.$store.dispatch('site/loggerConfigurations/createMeasurementPoint', this.formData);
            this.formData = {};
            this.$store.dispatch('site/loggerConfigurations/measurementPoint', null);
            this.$refs.form.reset();
            this.$refs.propertyType.focus();
          }
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/loggerConfigurations/measurementPoint', null);
      this.$refs.form.reset();
    },
  },
};
</script>
