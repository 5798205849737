<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-card>
      <v-toolbar
        dense
        flat
      >
        <v-toolbar-title>
          {{ $t('Equipments.PENumbers.ReservationDetails') }}
        </v-toolbar-title>
      </v-toolbar>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text class="pt-3">
          <v-textarea
            v-model="details"
            :label="$t('Equipments.PENumbers.Details')"
            :autofocus="true"
            :rules="[v => !!v || $t('Validation.Required')]"
            rows="3"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="onCancel"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('equipment/penumbers', ['details']),
    details: {
      get() { return this.$store.state.equipment.penumbers.details; },
      set(value) { this.$store.commit('equipment/penumbers/details', value); },
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$emit('input', false);
        this.$emit('on-accept');
      }
    },
    onCancel() {
      this.$emit('input', false);
      this.details = null;
      this.$refs.form.reset();
    },
  },
};
</script>
