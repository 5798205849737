<template>
  <v-menu
    v-model="dateRangeShow"
    :close-on-content-click="false"
    offset-y
    max-width="400"
  >
    <template #activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-btn
            :class="{ 'red': globalDateRange.activated }"
            v-bind="{ ...attrsMenu, ...attrsTooltip }"
            v-on="{ ...onMenu, ...onTooltip }"
            @click="dateRangeShow=true"
          >
            <v-icon>
              {{ globalDateRange.activated ? 'mdi-calendar-check' : 'mdi-calendar-remove' }}
            </v-icon>
          </v-btn>
        </template>
        <div style="white-space: pre;">
          <span>{{ tooltip }}</span>
        </div>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('Header.GlobalDateRange.Title') }}
      </v-card-title>
      <v-card-text>
        <v-checkbox
          key="GlobalDateRange"
          :input-value="form.activated"
          :label="$t('Header.GlobalDateRange.ActivateConfiguration')"
          class="m-2"
          hide-details
          @change="value => form.activated=value"
        />
      </v-card-text>
      <v-divider />
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitleTimeRange') }}
      </v-subheader>
      <time-range-card
        v-models:periodicity="form.dateRange.periodicity"
        v-models:lastnPeriod="form.dateRange.lastN.period"
        v-models:lastnScale="form.dateRange.lastN.scale"
        v-models:dateRangeFrom="form.dateRange.range.start"
        v-models:dateRangeTo="form.dateRange.range.end"
        :show="true"
        :show-record="false"
        :sensor-id-list="([])"
        :vector-id-list="([])"
        :selected-sensors="([])"
      />
      <v-subheader class="ma-0">
        {{ $t('Dashboard.VisualizationSetting.TitlePeriodAggregation') }}
      </v-subheader>
      <period-aggregation-card
        v-models:aggregateMultiplier="form.aggregateBy.multiplier"
        v-models:aggregateForEvery="form.aggregateBy.scale"
        v-models:aggregateUseTimeZone="form.aggregateUseTimeZone"
        :show="true"
      />
      <v-card-actions class="justify-end">
        <v-btn
          text
          :disabled="saveDisabled"
          @click="onSave"
        >
          {{ $t('Save') }}
        </v-btn>
        <v-btn
          text
          @click="dateRangeShow=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import datehandling from '@/components/datehandling';
import PeriodAggregationCard from '@/dashboard/components/graphform/PeriodAggregationCard.vue';
import TimeRangeCard from '@/dashboard/components/graphform/TimeRangeCard.vue';

export default {
  name: 'GlobalDateRangeMenu',
  components: {
    PeriodAggregationCard,
    TimeRangeCard,
  },
  data() {
    return {
      dateRangeShow: false,
      form: {
        activated: false,
        dateRange: {
          periodicity: 'last_n',
          lastN: {
            scale: 'Hours',
            period: 2,
          },
          range: {
            start: null,
            end: null,
          },
        },
        aggregateBy: {
          multiplier: 1,
          scale: 'Hours',
        },
        aggregateUseTimeZone: true,
      },
    };
  },
  computed: {
    ...mapGetters('app', ['globalDateRange']),

    saveDisabled() {
      if (!(this.form.aggregateBy.multiplier && this.form.aggregateBy.scale)) {
        return true;
      }

      if (this.form.dateRange.periodicity === 'last_n') {
        return !(this.form.dateRange.lastN.scale && this.form.dateRange.lastN.period);
      }
      if (this.form.dateRange.periodicity === 'time_range') {
        return !(this.form.dateRange.range.start && this.form.dateRange.range.end);
      }

      return false;
    },
    tooltip() {
      return this.$t(
        'Header.GlobalDateRange.Tooltip',
        { status: (this.globalDateRange.activated ? this.$t('Activated') : this.$t('Deactivated')) },
      );
    },
  },
  watch: {
    'form.activated': function w() {
      this.onSave();
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    onSave() {
      this.$store.commit('app/globalDateRange', this.form);
      this.dateRangeShow = false;
    },

    async init() {
      this.form = JSON.parse(JSON.stringify(this.globalDateRange));
      if (this.form.dateRange.range.start) {
        // need to parse from API, but it adds time, so we convert back
        this.form.dateRange.range.start = datehandling.zonedTimeToUtc(datehandling
          .parseFromApi(this.form.dateRange.range.start));
      }
      if (this.form.dateRange.range.end) {
        // need to parse from API, but it adds time, so we convert back
        this.form.dateRange.range.end = datehandling.zonedTimeToUtc(datehandling
          .parseFromApi(this.form.dateRange.range.end));
      }
    },
  },
};
</script>

<style>
</style>
