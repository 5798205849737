<template>
  <v-tab-item
    v-if="$auth.roles.includes('Web.AccessGroup.Manage')"
    style="height: calc(100% - 110px);"
  >
    <v-row style="height: 100%">
      <v-col style="height: 100%; max-width: 50%;">
        <server-side-data-grid
          :grid-source="sources.UserGrantAGSource"
          :show-new-button="false"
          :refresh="refresh"
        />
      </v-col>
      <v-col
        v-if="selection.user"
        style="height: 100%; max-width: 50%;"
      >
        <v-card-title>{{ selection.user.UserName }}</v-card-title>
        <v-row class="pt-2 pb-5">
          <v-col style="max-width: 50%;">
            <v-select
              v-model="selection.accessGroups"
              :label="$t('Settings.AccessControl.GrantRevokeAccessGroups.AddAccessGroups')"
              :items="availableAccessGroupList"
              item-text="Name"
              :multiple="true"
              hide-details
              dense
              return-object
            >
              <template #item="data">
                <v-tooltip left>
                  <template #activator="{ on, attrs }">
                    <v-layout
                      wrap
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-action class="mr-3">
                        <v-checkbox v-model="data.attrs.inputValue" />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.Name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-layout>
                  </template>
                  {{ getTenantToolTip(data.item.TenantName) }}
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-btn
              class="mr-auto"
              :disabled="selection.accessGroups.length === 0"
              @click="onGrant()"
            >
              <v-icon class="pr-2">
                mdi-plus
              </v-icon>
              {{ $t('Grant') }}
            </v-btn>
          </v-col>
        </v-row>
        <server-side-data-grid
          :grid-source="sources.AccessGroupGrantAGSource"
          :show-new-button="false"
          :refresh="refresh"
          :row-data-init="selectedUserAccessGroupList"
        />
      </v-col>
    </v-row>
  </v-tab-item>
</template>

<script>
import importal from '@/api/importal';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import UserGrantAGSource from '../gridsources/gsusergrantag';
import AccessGroupGrantAGSource from '../gridsources/gsaccessgroupgrantag';

export default {
  components: {
    ServerSideDataGrid,
  },
  props: {
    refresh: {
      type: Number,
      default: () => (0),
    },
  },
  data: () => ({
    availableAccessGroupList: [],
    selectedUserAccessGroupList: [],
    sources: {
      UserGrantAGSource: null,
      AccessGroupGrantAGSource: null,
    },
    dialog: {
      Message: '',
      NoCancel: false,
      Title: '',
      Show: false,
      acceptCallback: null,
    },
    selection: {
      user: null,
      accessGroups: [],
    },
  }),
  watch: {
    'selection.user': {
      async handler() {
        await this.initSecondPart();
      },
      immediate: true,
    },
    refresh: {
      async handler() {
        await this.init();
        await this.initSecondPart();
      },
      immediate: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.sources.UserGrantAGSource = new UserGrantAGSource(this);
      this.sources.AccessGroupGrantAGSource = new AccessGroupGrantAGSource(this);
    },
    async initSecondPart() {
      this.selection.accessGroups = [];
      this.selectedUserAccessGroupList = [];
      this.availableAccessGroupList = [];

      await this.initSelectedUserAccessGroups();
      await this.updateAvailableAccessGroups();
    },
    async initSelectedUserAccessGroups() {
      if (this.selection.user) {
        this.selectedUserAccessGroupList = await (await importal.post('OpUserListAccessGroup', {
          UserIds: [this.selection.user.UserId],
        })).data[0].AccessGroups;
      }
    },
    async updateAvailableAccessGroups() {
      if (this.selection.user) {
        this.availableAccessGroupList = await (await importal.get('OpAccessGroupList')).data;

        if (this.selectedUserAccessGroupList.length > 0) {
          this.availableAccessGroupList = this.availableAccessGroupList.filter((x) => !this.selectedUserAccessGroupList
            .some((y) => y.AccessGroupId === x.AccessGroupId));
        }
      }
    },
    async onGrant() {
      const { accessGroups } = this.selection;
      this.selection.accessGroups = [];
      this.selectedUserAccessGroupList = [];

      this.selectedUserAccessGroupList = await (await importal.post('OpUserGrantAccessGroup', {
        UserIds: [this.selection.user.UserId],
        AccessGroupIds: accessGroups.map((x) => x.AccessGroupId),
      })).data[0].AccessGroups;

      accessGroups.forEach((x) => this.availableAccessGroupList
        .splice(this.availableAccessGroupList.indexOf(x), 1));

      await importal.post('NewUserHomepageUpdate', {
        UserId: this.selection.user.UserId,
        AccessGroupId: accessGroups.map((x) => x.AccessGroupId)[0],
      });
    },
    getTenantToolTip(tenantName) {
      return `${this.$t('Settings.AccessControl.GrantRevokeAccessGroups.Tenant')}: ${tenantName}`;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
