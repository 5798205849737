export default [
  {
    isWindServer: true,
    label: 'QualityCheck.Title',
    icon: 'mdi-checkbox-marked-circle-outline',
    title: 'QualityCheck.Title',
    items: [
      {
        label: 'QualityCheck.QCTaskTypes.Title',
        to: { name: 'QualityCheck.QCTaskTypes.List' },
        title: 'QualityCheck.QCTaskTypes.Title',
      },
      {
        label: 'QualityCheck.QCProblemTypes.Title',
        to: { name: 'QualityCheck.QCProblemTypes.List' },
        title: 'QualityCheck.QCProblemTypes.Title',
      },
      {
        label: 'QualityCheck.QCTasks.Title',
        to: { name: 'QualityCheck.QCTasks.List' },
        title: 'QualityCheck.QCTasks.Title',
      },
      {
        label: 'QualityCheck.QCInspectionTemplates.Title',
        to: { name: 'QualityCheck.QCInspectionTemplates.List' },
        title: 'QualityCheck.QCInspectionTemplates.Title',
      },
      {
        label: 'QualityCheck.QCSchedules.Title',
        to: { name: 'QualityCheck.QCSchedules' },
        title: 'QualityCheck.QCSchedules.Title',
      },
      {
        label: 'QualityCheck.QCInspections.Title',
        to: { name: 'QualityCheck.QCInspections.List' },
        title: 'QualityCheck.QCInspections.Title',
      },
    ],
  },
];
