<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
    scrollable
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-container fluid>
          <p>
            {{ $t('WindServer.Sites.DataTrace.FlagSetsFor') }}
            {{ flagSets.Name }}
          </p>
          <p>
            {{ flagActive }}
          </p>
          <FlagFilters
            ref="filter"
            @update="o => refresh(o)"
          />
          <v-card-text style="height: 500px;">
            <ag-grid-vue
              rowSelection="multiple"
              style="height: 100%"
              class="grid ag-theme-balham-dark"
              :columnDefs="columnDefs"
              :rowData="flagSets.QaFlagSets"
              :locale-text="localeText"
              :headerHeight="0"
              pagination
              @grid-ready="onGridReady"
              @cell-clicked="cellWasClicked"
            />
          </v-card-text>
        </v-container>
      </v-form>
      <v-card-actions class="justify-end">
        <v-btn
          v-if="$auth.roles.includes('Web.Site.DataTrace.Manage')"
          text
          @click.stop="shift"
        >
          {{ $t('WindServer.Sites.DataTrace.Shift') }}
        </v-btn>
        <v-btn
          v-if="$auth.roles.includes('Web.Site.DataTrace.Manage')"
          text
          :disabled="flagsSelected != 1"
          @click.stop="edit"
        >
          {{ $t('Edit') }}
        </v-btn>
        <v-btn
          v-if="$auth.roles.includes('Web.Site.DataTrace.Create')"
          text
          :disabled="flagsSelected == 0"
          @click.stop="showConfirm = true"
        >
          {{ $t('Delete') }}
        </v-btn>
        <v-btn
          text
          @click.stop="close"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <FlagSetsEditModal
      ref="editFlag"
      v-model="editFlag"
      @show-modal="showModal"
    />
    <ShiftFlagSetsModal
      ref="shiftFlag"
      v-model="shiftFlag"
      @show-modal="showModal"
    />
    <ConfirmDialog
      v-model="showConfirm"
      :message="$t('WindServer.Sites.DataTrace.DeleteMessage')"
      :title="$t('ConfirmDeletion')"
      @on-accept="deleteFlags"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import { AgGridVue } from 'ag-grid-vue';
import i18n from '@/plugins/i18n';
import agGridFr from '@/locales/agGridFr';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import FlagCellRenderer from './FlagCellRenderer.vue';
import FlagFilters from './FlagFilters.vue';
import FlagSetsEditModal from './FlagSetsEditModal.vue';
import ShiftFlagSetsModal from './ShiftFlagSetsModal.vue';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

export default {
  components: {
    ConfirmDialog,
    FlagFilters,
    FlagSetsEditModal,
    ShiftFlagSetsModal,
    AgGridVue,
    FlagCellRenderer, // eslint-disable-line vue/no-unused-components
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      columnDefs: [
        {
          field: 'QaCodeId',
          cellRenderer: 'FlagCellRenderer',
          width: 723,
          resizable: true,
          autoHeight: true,
        },
      ],
      rowData: [],
      localeText: null,
      gridApi: null,
      columnApi: null,
      searchOptions: {},
      flagsSelected: 0,
      editFlag: false,
      shiftFlag: false,
      showConfirm: false,
    };
  },
  computed: {
    ...mapState('site/dataTrace', ['flagSets', 'qaCodes']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    flagActive() {
      let value = i18n.t('WindServer.Sites.DataTrace.FlagSetsActive');
      if (this.flagSets && this.flagSets.DataStartDate) {
        value = value.concat(i18n.t('WindServer.Sites.DataTrace.Between'))
          .concat(format(this.flagSets.DataStartDate, this.dateTimeFormat))
          .concat(i18n.t('WindServer.Sites.DataTrace.And'))
          .concat(format(this.flagSets.DataEndDate, this.dateTimeFormat));
      }
      return value;
    },
  },
  created() {
    if (i18n.locale === 'fr') {
      this.localeText = agGridFr.locale_fr;
    }
  },
  methods: {
    async onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    edit() {
      const s = this.gridApi.getSelectedRows()[0];
      this.editFlag = true;
      this.$refs.editFlag.setData(s);
      this.$emit('input', false);
    },
    shift() {
      this.shiftFlag = true;
      this.$emit('input', false);
    },
    async deleteFlags() {
      const selectedRows = [];
      if (this.gridApi.getSelectedRows().length > 0) {
        this.gridApi.getSelectedRows().forEach((x) => {
          selectedRows.push(x.QaFlagSetId);
        });
      }
      if (selectedRows.length > 0) {
        await this.$store.dispatch('site/dataTrace/deleteFlags', selectedRows);
        this.refresh(this.searchOptions);
      }
    },
    showModal(refresh) {
      this.$emit('input', true);
      if (refresh) {
        this.refresh(this.searchOptions);
      }
    },
    close() {
      this.flagsSelected = 0;
      this.$refs.filter.close();
      this.$emit('input', false);
    },
    getQaCodeDesc(qaCodeId) {
      return i18n.t('WindServer.Sites.DataTrace.QaCodes.'.concat(qaCodeId));
    },
    async refresh(options) {
      await this.$store.dispatch('site/dataTrace/getFlagSets', options);
      this.searchOptions = options;
    },
    cellWasClicked() {
      this.flagsSelected = this.gridApi.getSelectedRows().length;
    },

  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ag-cell {
    padding: 0px !important;
  }
  .ag-theme-balham-dark {
    padding: 0px !important;
  }
  .ag-theme-balham-dark .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding: 0px !important;
  }
  .ag-theme-balham-dark .ag-cell {
    padding: 0px !important;
  }
  .v-expansion-panel-header {
    min-height: 30px !important;
    padding: 8px 12px;
  }
}
</style>
