<template>
  <l-map
    class="map"
    ref="myMap"
    :zoom="map.zoom"
    :center="map.center"
    :options="map.options"
    style="height: 100%"
  >
    <l-tile-layer
      :url="map.url"
      :options="{ className: 'map-tiles' }"
    />
    <l-marker
      v-for="s in validLocationSites"
      :key="s.SiteGuid"
      :lat-lng="[s.Latitude, s.Longitude]"
      :icon="getMarkerIcon(s)"
    >
      <l-popup :options="{ minWidth: 500 }">
        <h4 class="pb-2">{{s.Name}}</h4>
        <div class="row no-gutters">
          <DataCoverageChart class="col" :site="s" />
          <StatusContent class="col ml-3" :site="s" />
        </div>
        <div class="row no-gutters mt-2">
          <JumpToButtons class="col" :site="s" />
        </div>
      </l-popup>
    </l-marker>
  </l-map>
</template>

<script>
import { mapState } from 'vuex';
import L from 'leaflet';
import {
  LMap, LTileLayer, LMarker, LPopup,
} from 'vue2-leaflet';
import DataCoverageChart from './DataCoverageChart.vue';
import JumpToButtons from './JumpToButtons.vue';
import StatusContent from './StatusContent.vue';

export default {
  components: {
    // eslint-disable-next-line
    LMap, LTileLayer, LMarker, LPopup,
    DataCoverageChart,
    JumpToButtons,
    StatusContent,
  },
  data() {
    return {
      map: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 2,
        center: [0, 0],
        options: {
          zoomSnap: 0.5,
        },
      },
    };
  },
  computed: {
    ...mapState('customers/sites', ['sites']),
    validLocationSites() {
      return this.sites.filter((site) => site.Latitude && site.Longitude);
    },
  },
  methods: {
    // eslint-disable-next-line
    getMarkerIcon(s) {
      const options = {
        html: `<i class="mdi mdi-map-marker mdi-36px" status="${s.Status}"></i>`,
        iconSize: [36, 36],
        iconAnchor: [18, 36],
        popupAnchor: [0, -36],
        className: '',
      };

      return new L.DivIcon(options);
    },
  },
};
</script>

<style scoped>
  .map >>> i[status=NoIssue] {
    color: #50b0f2;
  }

  .map >>> i[status=LowPriorityIssues] {
    color: #bcbd22;
  }

  .map >>> i[status=HighPriorityIssues] {
    color: #d62728;
  }

  .map >>> i[status=Retired] {
    color: #7f7f7f;
  }
</style>
