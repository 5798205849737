<template>
  <div class="lang-dropdown">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div v-on="on" multiple>
          <v-select
            v-model="SelectedTenant"
            :items="Tenants"
            item-text="Name"
            v-bind="attrs"
            class="cursor-pointer"
            style="width: 200px"
            single-line
            hide-details
            return-object
            v-on="on"
          />
        </div>
      </template>
      <span>{{ $t('Header.SwitchTenant') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data: () => ({
    InternalSelectedTenant: null,
  }),
  computed: {
    ...mapState('app', ['tenantId']),
    ...mapGetters('user', ['identity']),
    ...mapGetters('app', ['tenantId']),

    Tenants() {
      if (this.identity) {
        const arr = this.identity.listaccessgroupinfo.map((x) => ({
          Name: x.Name,
          TenantId: x.TenantId,
        }));
        return arr.filter(
          (a, i) => arr.findIndex((s) => a.TenantId === s.TenantId) === i,
        );
      }
      return [];
    },

    SelectedTenant: {
      get() {
        return this.InternalSelectedTenant;
      },
      set(value) {
        this.InternalSelectedTenant = value;
        this.$store.commit('app/tenantId', value.TenantId);
      },
    },
  },
  watch: {
    // Watch for changes in Tenants and initialize SelectedTenant if necessary
    Tenants: {
      immediate: true,
      handler(newTenants) {
        if (newTenants.length > 0 && !this.InternalSelectedTenant) {
          const initialTenant = newTenants.find((tenant) => tenant.TenantId === this.tenantId)
           || newTenants[0];
          this.SelectedTenant = initialTenant;
        }
      },
    },
    // Watch for changes in tenantId and update InternalSelectedTenant if necessary
    tenantId: {
      immediate: true,
      handler(newTenantId) {
        if (newTenantId) {
          const tenant = this.Tenants.find(
            (tenantb) => tenantb.TenantId === newTenantId,
          );
          if (tenant) {
            this.InternalSelectedTenant = tenant;
          }
        }
      },
    },
  },
  created() {
    // Initialize SelectedTenant when the component is created
    if (this.Tenants.length > 0 && !this.InternalSelectedTenant) {
      const initialTenant = this.Tenants.find((tenant) => tenant.TenantId === this.tenantId)
        || this.Tenants[0];
      this.SelectedTenant = initialTenant;
    }
  },
};
</script>

<style lang="scss">
.lang-dropdown {
  font-size: 1rem;
  font-weight: 600;
  div.v-input {
    div.v-input__control {
      div.v-text-field__details {
        display: none;
      }
      div.v-input__slot::before {
        border: none;
      }
      div.v-input__slot {
        margin: 0;
      }
    }
  }
}
</style>
