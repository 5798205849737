<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    max-width="800"
    max-height="1000"
  >
    <v-card>
      <v-card-title class="pa-3">
        <p class="mb-0 mt-0">
          {{ `${$t('Dashboard.MediaWidget.FilesPdf')}` }}
          {{ ` : ${feedInfos.date}` }}
        </p>
      </v-card-title>
      <div
        v-if="!pdf"
        style="height: 800px;"
      >
        <div class="loader" />
      </div>
      <section
        v-else
        style="overflow:hidden; height: 800px;"
      >
        <v-row no-gutters>
          <v-col cols="auto">
            <v-card-text class="pb-0">
              {{ `${$t('Dashboard.MediaWidget.Files')} :` }}
            </v-card-text>
          </v-col>
          <v-col cols="auto">
            <v-card-actions>
              <v-btn
                :disabled="!hasPrevious"
                text
                @click="getPrevious"
              >
                <v-icon>mdi-arrow-left</v-icon>
                {{ $t('Previous') }}
              </v-btn>
              <v-btn
                :disabled="!hasNext"
                text
                @click="getNext"
              >
                <v-icon>mdi-arrow-right</v-icon>
                {{ $t('Next') }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <div class="d-flex fill-height">
          <v-col cols="3">
            <v-virtual-scroll
              :items="feedInfos.infos"
              :item-height="40"
              style="max-height: 800px; overflow-y: auto; overflow-x: hidden;"
              class="v-virtual-scroll--with-height"
            >
              <template #default="{ item }">
                <v-list-item
                  :key="item.index"
                  dense
                  class="pa-0 pl-2"
                  active-class="primary"
                  :input-value="selectedIndex === item.index"
                >
                  <v-btn
                    icon
                    @click="downloadFile(item)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <v-card-text
                    class="py-2 px-1 cursor-pointer"
                    @click="getSelected(item.index)"
                  >
                    {{ getTime(item.ts) }}
                  </v-card-text>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-col>
          <iframe
            :src="pdf"
            style="border: none; width: 70%; height: 70%;"
          />
        </div>
      </section>
      <v-card-actions class="d-flex justify-end align-end">
        <v-btn
          text
          @click="$emit('back', ({date: feedInfos.date, index: selectedIndex}))"
        >
          {{ $t('Back') }}
        </v-btn>
        <v-btn
          text
          @click="show=false"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import importal from '@/api/importal';
import fileDownload from 'js-file-download';
import datehandling from '@/components/datehandling';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: true,
    },
    widgetSettingsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      pdf: null,
    };
  },
  computed: {
    ...mapState('mediaFeed', ['feedInfos', 'availableDates']),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    hasPrevious() {
      return this.selectedIndex > 0 || this.feedInfos.index > 0;
    },
    hasNext() {
      return this.selectedIndex + 1 < this.feedInfos.infos.length
        || this.feedInfos.index + 1 < this.availableDates.length;
    },
  },
  watch: {
    selectedItem: {
      async handler(newVal) {
        if (newVal && newVal.date) {
          this.selectedIndex = newVal.index;
          this.updatePdf();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async updatePdf() {
      this.pdf = null;
      const infos = this.feedInfos.infos[this.selectedIndex];
      if (!infos.blob) {
        await this.loadBlob(infos);
      }

      const pdfBlob = infos.blob.slice(0, infos.blob.size, infos.mime_type);
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = () => {
        this.pdf = reader.result;
      };
    },
    async loadBlob(infos) {
      const pdfBlob = await (await importal.get(
        `FileFeedDownload?feed_id=${infos.feedId}&path=${encodeURIComponent(infos.path)}`, {
          responseType: 'blob',
          data: this.widgetSettingsData,
        },
      )).data;

      const infosPdf = infos;
      infosPdf.blob = pdfBlob;
    },
    async getPrevious() {
      this.selectedIndex -= 1;
      if (this.selectedIndex < 0) {
        await this.updateItems(this.availableDates[this.feedInfos.index - 1]);
        this.selectedIndex = this.feedInfos.infos.length - 1;
      }
      this.updatePdf();
    },
    async getNext() {
      this.selectedIndex += 1;
      if (this.selectedIndex >= this.feedInfos.infos.length) {
        await this.updateItems(this.availableDates[this.feedInfos.index + 1]);
        this.selectedIndex = 0;
      }
      this.updatePdf();
    },
    async getSelected(index) {
      this.selectedIndex = index;
      this.updatePdf();
    },
    async updateItems(newDate) {
      if (newDate) {
        await this.$store.dispatch('mediaFeed/getFullInfosForDay', {
          date: newDate,
          widgetSettingsData: this.widgetSettingsData,
        });
      }
    },
    getTime(time) {
      return datehandling.formatTimeOnlyForDisplay(time);
    },
    async downloadFile(infos) {
      if (!infos.blob) {
        await this.loadBlob(infos);
      }
      fileDownload(infos.blob, infos.path.split('/').pop(), infos.mime_type);
    },
  },
};
</script>
