<template>
  <div
    style="height: calc(100vh - 120px); width: 100%"
  >
    <div
      class="row"
      style="margin: 15px; font-size: 15px;"
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            id="btnRefreshTenants"
            style="margin-top:-7px;margin-right:15px; min-width:40px;padding:0px;"
            v-bind="attrs"
            v-on="on"
            @click="refreshList();"
          >
            <i class="v-icon mdi mdi-refresh" />
          </v-btn>
 </template>
        <span>  {{ $t('Refresh') }} </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="$auth.roles.includes('Web.QCSchedule.Manage')"
            id="btnShowAssignation"
            style="margin-top:-7px;margin-right:15px; min-width:40px;padding:0px;"
            v-bind="attrs"
            v-on="on"
            @click="assignation();"
          >
            <i class="v-icon mdi mdi-account-supervisor" />
          </v-btn>
        </template>
      <span>  {{ $t('QualityCheck.QCSchedules.Assign') }} </span>
      </v-tooltip>
      <span>{{ $t('QualityCheck.QCSchedules.Tenants') }} : </span>
      <span
        id="lblAllTenantsCount"
        style="margin-left: 11px;"
      >{{ qcTenantsAllCount }}
      </span>
      <span style="margin-left: 25px; margin-right: 25px;">-</span>
      <span>{{ $t('QualityCheck.QCSchedules.Assigned') }} : </span>
      <span
        id="lblAssignedTenantsCount"
        style="margin-left: 11px;"
      >{{ qcTenantsAssignedCount }}
      </span>
      <span style="margin-left: 25px; margin-right: 25px;">-</span>
      <span>{{ $t('QualityCheck.QCSchedules.Remaining') }}  : </span>
      <span
        id="lblRemainingTenantsCount"
        style="margin-left: 11px;"
      >{{ qcTenantsRemainingCount }}
      </span>
    </div>
    <ServerSideDataGrid
      id="gridTenants"
      ref="gridTenants"
      :grid-source="sources.QCTenants"
      :row-data-init="qcTenants"
      :enable-selection="'multiple'"
    />
    <router-view />
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import QCTenantList from '../gridsources/gsQCTenants';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        QCTenants: null,
      },
    };
  },
  computed: {
    ...mapState('qualityCheck/qcSchedules', ['qcTenants', 'qcTenantsAllCount', 'qcTenantsAssignedCount', 'qcTenantsRemainingCount', 'weekOf']),
  },
  watch: {
    weekOf() {
      this.refreshList();
    },
  },
  created() {
    this.sources.QCTenants = new QCTenantList(this);
  },
  mounted() {
  },
  methods: {
    refreshList() {
      this.$store.dispatch('qualityCheck/qcSchedules/tenantsList');
    },
    async assignation() {
      const selectedTenants = this.$refs.gridTenants.gridApi.getSelectedRows();
      if (selectedTenants.length > 0) {
        const selectedIds = [];
        selectedTenants.forEach((element) => {
          selectedIds.push(element.AccessGroupId);
        });
        if (selectedTenants.length === 1) {
          this.$store.dispatch('qualityCheck/qcSchedules/setSelectedTenant', selectedTenants[0].TenantName);
        } else {
          this.$store.dispatch('qualityCheck/qcSchedules/setSelectedTenant', `${selectedTenants.length} ${i18n.t('QualityCheck.QCSchedules.SelectedTenants')}`);
        }
        const analystId = selectedTenants.filter((p) => p.AnalystId === selectedTenants[0].AnalystId).length === selectedTenants.length
          ? selectedTenants[0].AnalystId : null;
        const technicianId = selectedTenants.filter((p) => p.TechnicianId === selectedTenants[0].TechnicianId).length === selectedTenants.length
          ? selectedTenants[0].TechnicianId : null;
        this.$store.dispatch('qualityCheck/qcSchedules/setSelectedTenantAnalystId', analystId);
        this.$store.dispatch('qualityCheck/qcSchedules/setSelectedTenantTechnicianId', technicianId);
        this.$store.dispatch('qualityCheck/qcSchedules/setSelectedTenantIds', selectedIds);
        this.$router.push({ name: 'QualityCheck.QCSchedules.Tenants.Assignation' });
      }
    },
  },
};
</script>
