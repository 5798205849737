<template>
  <v-dialog
    v-model="form.show"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>{{ $t('TryVirtualInstrument') }}</v-card-title>
      <v-tabs
        v-model="tab"
      >
        <v-tab
          :key="'$setup'"
          :disabled="tryButtonDisabled"
        >
          {{ $t('SetUp') }}
        </v-tab>
        <v-tab
          v-for="item in form.OutputSensorTabs"
          :key="item.Symbol"
        >
          {{ item.Symbol }}
        </v-tab>
        <v-tab
          v-for="item in form.OutputVectorTabs"
          :key="item.Symbol"
        >
          {{ item.Symbol }}
        </v-tab>
        <v-tab
          v-for="item in form.InputSensorTabs"
          :key="item.Symbol"
        >
          {{ item.Symbol }}
        </v-tab>
        <v-tab
          v-for="item in form.InputVectorTabs"
          :key="item.Symbol"
        >
          {{ item.Symbol }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item
          :key="'$setup'"
        >
          <v-card-subtitle>
            &nbsp;
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <div
                class="col-3"
              >
                {{ $t('Range') }}
              </div>
                &nbsp;
                &nbsp;
              <datetime-picker
                v-model="form.ValidFromTs"
                :label="$t('From')"
                :text-field-props="pickerTextFieldProps"
                :date-picker-props="pickerDateProps"
                :time-picker-props="pickerTimeProps"
              />
                &nbsp;
                &nbsp;
              <datetime-picker
                v-model="form.ValidToTs"
                :label="$t('To')"
                :text-field-props="pickerTextFieldProps"
                :date-picker-props="pickerDateProps"
                :time-picker-props="pickerTimeProps"
              />
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item
          v-for="item in form.OutputSensorTabs"
          :key="item.Symbol"
        >
          <dialog-try-out-sensor-output-graph
            :valid-from-ts="form.ValidFromTs"
            :valid-to-ts="form.ValidToTs"
            :node="item"
            :info="info"
          />
        </v-tab-item>
        <v-tab-item
          v-for="item in form.OutputVectorTabs"
          :key="item.Symbol"
        >
          <dialog-try-out-vector-output-graph
            :valid-from-ts="form.ValidFromTs"
            :valid-to-ts="form.ValidToTs"
            :node="item"
            :info="info"
          />
        </v-tab-item>
        <v-tab-item
          v-for="item in form.InputSensorTabs"
          :key="item.Symbol"
        >
          <dialog-try-out-sensor-input-graph
            :valid-from-ts="form.ValidFromTs"
            :valid-to-ts="form.ValidToTs"
            :node="item"
            :info="info"
          />
        </v-tab-item>
        <v-tab-item
          v-for="item in form.InputVectorTabs"
          :key="item.Symbol"
        >
          <dialog-try-out-vector-input-graph
            :valid-from-ts="form.ValidFromTs"
            :valid-to-ts="form.ValidToTs"
            :node="item"
            :info="info"
          />
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions class="justify-end">
        <v-btn
          :disabled="tryButtonDisabled"
          text
          @click.stop="onTryOutButton"
        >
          Try
        </v-btn>
        <v-btn
          text
          @click.stop="form.show=false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatetimePicker from '@/components/DatetimePicker.vue';
import DialogTryOutSensorInputGraph from './DialogTryOutSensorInputGraph.vue';
import DialogTryOutSensorOutputGraph from './DialogTryOutSensorOutputGraph.vue';
import DialogTryOutVectorInputGraph from './DialogTryOutVectorInputGraph.vue';
import DialogTryOutVectorOutputGraph from './DialogTryOutVectorOutputGraph.vue';

export default {
  components: {
    DatetimePicker,
    DialogTryOutSensorInputGraph,
    DialogTryOutSensorOutputGraph,
    DialogTryOutVectorInputGraph,
    DialogTryOutVectorOutputGraph,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    tab: null,
    form: {
      show: false,
      ValidFromTs: null,
      ValidToTs: null,
      InputSensorTabs: [],
      OutputSensorTabs: [],
      InputVectorTabs: [],
      OutputVectorTabs: [],
    },
    pickerTextFieldProps: {
      dense: true,
      clearable: true,
      class: 'col-4',
      style: 'font-size: 80%;',
    },
    pickerDateProps: {
      locale: 'en-ca',
    },
    pickerTimeProps: {
      format: '24hr',
    },
  }),
  computed: {
    tryButtonDisabled() {
      return this.form.InputSensorTabs.length > 0
      || this.form.InputVectorTabs.length > 0
      || this.form.OutputSensorTabs.length > 0
      || this.form.OutputVectorTabs.length > 0;
    },
  },
  watch: {
    value(val) {
      this.form.show = val;
      if (this.form.show) {
        this.initialize();
      } else {
        this.form.InputSensorTabs = [];
        this.form.OutputSensorTabs = [];
        this.form.InputVectorTabs = [];
        this.form.OutputVectorTabs = [];
      }
    },
    'form.show': function show() {
      if (!this.form.show) {
        this.$emit('input', this.form.show);
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
    },
    onTryOutButton() {
      this.form.InputSensorTabs = this.info.exec.input_sensors;
      this.form.OutputSensorTabs = this.info.exec.output_sensors;
      this.form.InputVectorTabs = this.info.exec.input_vectors;
      this.form.OutputVectorTabs = this.info.exec.output_vectors;
      this.tab = null;
    },
  },
  i18n: {
    messages: {
      en: {
        From: 'From',
        To: 'To',
        Range: 'Range',
        SetUp: 'Setup',
        TryVirtualInstrument: 'Try Virtual Instrument',
      },
      fr: {
        From: 'De',
        To: 'À',
        Range: 'Étendue',
        SetUp: 'Réglages',
        TryVirtualInstrument: 'Essayer un instrument virtuel',
      },
    },
  },
};
</script>

<style>

</style>
