<template>
  <v-card-text>
    <v-row dense>
      <v-text-field
        placeholder="Search"
        outlined
        class="pb-2"
        @input="updateSearch"
        @click.stop="searchClick"
      />
    </v-row>
    <v-row
      dense
    >
      <v-virtual-scroll
        :items="filteredIcons"
        :item-height="50"
        :bench="0"
        height="235"
        style="top: -35px;"
      >
        <template #default="{ item }">
          <v-list-item
            @click="selectedIcon(item.name)"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-{{ item.name }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ item.name }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-row>
  </v-card-text>
</template>

<script>
import iconData from '@mdi/svg/meta.json';

export default {
  props: {
    value: {
      type: String,
      default: () => (''),
      required: true,
    },
  },
  data: () => ({
    search: '',
    id: '',
  }),
  computed: {
    filteredIcons() {
      return iconData
        .filter((i) => (
          i.name.includes(this.search)
          || i.aliases.includes(this.search)
          || i.tags.includes(this.search)
        ));
    },
    idQuery() {
      return `#${this.id}`;
    },
  },
  created() {
    this.id = Math.random().toString(36).replace('0.', 'icon-picker' || '');
  },
  methods: {
    updateSearch(e) {
      this.search = e;
    },
    searchClick() {

    },
    selectedIcon(name) {
      this.$emit('input', `mdi-${name}`);
    },
  },
};
</script>

<style scoped>
    .v-menu__content {
        min-width: 300px !important;
        background-color: white;
    }
</style>
