<template>
  <ag-grid-vue
    rowHeight="175"
    style="height: 100%"
    class="grid ag-theme-balham-dark"
    :columnDefs="columnDefs"
    :rowData="sites"
    :locale-text="localeText"
    pagination
  />
</template>

<script>
import i18n from '@/plugins/i18n';
import { mapState } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import agGridFr from '@/locales/agGridFr';
import DataCoverageChartCellRenderer from './DataCoverageChartCellRenderer.vue';
import StatusCellRenderer from './StatusCellRenderer.vue';
import TowerCellRenderer from './TowerCellRenderer.vue';

export default {
  components: {
    AgGridVue,
    DataCoverageChartCellRenderer, // eslint-disable-line vue/no-unused-components
    StatusCellRenderer, // eslint-disable-line vue/no-unused-components
    TowerCellRenderer, // eslint-disable-line vue/no-unused-components
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: this.$t('Customers.Sites.Tower'),
          cellRenderer: 'TowerCellRenderer',
          width: 400,
          resizable: true,
        },
        {
          headerName: this.$t('Customers.Sites.DataCoverage'),
          cellRenderer: 'DataCoverageChartCellRenderer',
          width: 200,
        },
        {
          headerName: this.$t('Customers.Sites.Status'),
          cellRenderer: 'StatusCellRenderer',
          flex: 1,
        },
      ],
      rowData: [],
      localeText: null,
    };
  },
  computed: {
    ...mapState('customers/sites', ['sites']),
  },
  created() {
    if (i18n.locale === 'fr') {
      this.localeText = agGridFr.locale_fr;
    }
  },
};
</script>

<style scoped>
.grid >>> .ag-cell-value {
  line-height: normal;
  padding: 12px;
}
</style>
