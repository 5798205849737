<template>
  <v-row justify="center">
      <v-dialog
        v-model="show"
        scrollable
        persistent
        max-width="910px"
      >
        <v-card>
          <v-card-text style="height: 79vh;padding:0px;">
            <v-tabs
      v-model="activeTab"
      fixed-tabs
      style="border-width:0px;"
    >
      <v-tab key="information">
        <slot>
          {{ $t('QualityCheck.QCInspectionTemplates.Informations') }}
        </slot>
      </v-tab>
      <v-tab
      v-if="sectionId == null ? false : true"
        key="questions"
      >
        <slot>
            {{ $t('QualityCheck.QCTemplateSections.Questions') }}
        </slot>
      </v-tab>
      <v-tab
      v-if="sectionId == null ? false : true"
        key="measurements"
      >
        <slot>
            {{ $t('QualityCheck.QCTemplateSections.Measurements') }}
        </slot>
      </v-tab>
            <v-tab-item
        key="information"
        style="padding: 15px;padding-top: 30px;"
      >
      <v-form
        ref="form"
        lazy-validation
      >
    <div class="row">
      <v-text-field
      id="txtInspectionName"
              class="col col-6"
              v-model="qcInspectionTemplate.Name"
              :label="$t('QualityCheck.QCInspectionTemplates.Name')"
              readonly
            />
          </div>
    <div class="row">
            <v-text-field
            id="txtSectionName"
              class="col col-6"
              v-model="formData.Name"
              :label="$t('QualityCheck.QCTemplateSections.Name')"
              :rules="mandatoryRule"
              :readonly="readMode & sectionId!=null"
              :error-messages="errorMessage"
              @input="cleanError"
            />
    </div>
    <div class="row">
      <v-select
      class="col col-6"
      id="txtSectionType"
      v-model="formData.TypeId"
      :label="$t('QualityCheck.QCTemplateSections.Type')"
      :items="sectionTypes"
      :rules="mandatoryRule"
      item-text="Name"
      item-value="Id"
      @click="cleanError"
      :readonly="readMode & sectionId!=null"
      />
    </div>
    </v-form>
      </v-tab-item>
      <v-tab-item
        key="questions"
        style=""
      >
      <SectionQuestionList />
      </v-tab-item>
      <v-tab-item
        key="measurements"
        style=""
      >
      <SectionMeasurementList />
      </v-tab-item>
       </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
            v-if="$auth.roles.includes('Web.QCInspectionTemplate.Manage') && activeTab == 0"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import SectionMeasurementList from '../../qcSectionMeasurements/components/QCSectionMeasurementList.vue';
import SectionQuestionList from '../../qcSectionQuestions/components/QCSectionQuestionList.vue';

export default {
  components: {
    SectionMeasurementList,
    SectionQuestionList,
  },
  props: {
    sectionId: {
      type: Number,
      required: false,
      default: null,
    },
    readMode: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      activeTab: null,
      mandatoryRule: null,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('qualityCheck/qcInspectionTemplates', ['qcInspectionTemplate']),
    ...mapState('qualityCheck/qcTemplateSections', ['sectionTypes', 'templateSection', 'nameExists']),
  },
  watch: {
    async sectionId() {
      await this.getSection();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    await this.$store.dispatch('qualityCheck/qcTemplateSections/sectionTypesList');
    await this.$store.dispatch('qualityCheck/qcSectionMeasurements/setSectionId', this.sectionId);
    await this.$store.dispatch('qualityCheck/qcSectionQuestions/setSectionId', this.sectionId);
    if (this.sectionId && this.sectionId != null) { // View or Update mode
      await this.getSection();
    } else { // Create mode
      await this.$store.dispatch('qualityCheck/qcTemplateSections/setTemplateSection', null);
      this.show = true;
    }
  },
  methods: {
    async getSection() {
      await this.$store.dispatch('qualityCheck/qcTemplateSections/get', { sectionId: this.sectionId });
      this.formData = this.$clone(this.templateSection);
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'QualityCheck.QCInspectionTemplates.Sections' });
    },
    async submit() {
      this.mandatoryRule = [(v) => !!v || this.$t('Validation.Required')];
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (await this.validate()) {
            if (this.sectionId) {
              await this.$store.dispatch('qualityCheck/qcTemplateSections/update', this.formData);
            } else {
              await this.$store.dispatch('qualityCheck/qcTemplateSections/create', this.formData);
            }

            this.navigateToList();
          }
        }
      });
    },

    cleanError() {
      this.mandatoryRule = '';
      this.errorMessage = '';
    },

    async validate() {
      await this.$store.dispatch('qualityCheck/qcTemplateSections/validate', this.formData);
      if (this.nameExists) {
        this.errorMessage = i18n.t('QualityCheck.QCTemplateSections.NameExists');
      } else {
        this.errorMessage = '';
        return true;
      }
      return false;
    },

  },
};
</script>
