import QCTaskList from './components/QCTaskList.vue';
import QCTaskFormModal from './components/QCTaskFormModal.vue';

export default [
  {
    path: '/QualityCheck/QCTasks',
    name: 'QualityCheck.QCTasks.List',
    component: QCTaskList,
    children: [
      {
        path: '/QualityCheck/QCTasks/Create',
        name: 'QualityCheck.QCTasks.Create',
        component: QCTaskFormModal,
      },
      {
        path: '/QualityCheck/QCTasks/:id',
        name: 'QualityCheck.QCTasks.Update',
        component: QCTaskFormModal,
        props: true,
      },
    ],
  },
];
