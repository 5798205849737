<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title>
          {{ $t('WindServer.Sites.SiteConfigurations.DestinationTitle') }}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-select
              v-model="formData.StorageSite"
              :label="$t('WindServer.Sites.SiteConfigurations.Destination')"
              :items="equipmentModel.StorageSites"
              :rules="[v => !!v || $t('Validation.Required')]"
              :return-object="true"
              item-text="Name"
              item-value="SiteId"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    type: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    ...mapState('site/siteConfigurations', ['equipmentModel']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('site/siteConfigurations/delete', this.formData);
        this.close();
      }
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$refs.form.reset();
    },
  },
};
</script>
