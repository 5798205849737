const state = {
  landOwner: {
    id: null,
    fullname: null,
    address: null,
    note: null,
    isOwner: null,
  },
};

const actions = {
};

const mutations = {
  setLandOwner(state, landowner) {
    state.landOwner = landowner;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
