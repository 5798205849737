import SiteList from './sites/components/SiteList.vue';
import SiteFormModal from './sites/components/SiteFormModal.vue';
import SiteNoteList from './siteNotes/components/SiteNoteList.vue';
import SiteNoteFormModal from './siteNotes/components/SiteNoteFormModal.vue';
import SiteConfigurationsList from './siteConfigurations/components/SiteConfigurationsList.vue';
import SiteConfigurationsCreateModal from './siteConfigurations/components/SiteConfigurationsCreateModal.vue';
import SiteConfigurationsFormModal from './siteConfigurations/components/SiteConfigurationsFormModal.vue';
import SiteConfigurationsDetailsModal from './siteConfigurations/components/SiteConfigurationsDetailsModal.vue';
import SiteAttachmentList from './siteAttachments/components/SiteAttachmentList.vue';
import SiteFolderModal from './siteAttachments/components/SiteFolderModal.vue';
import FolderRename from './siteAttachments/components/FolderRenameModal.vue';

export default [
  {
    path: '/Devices/Sites',
    name: 'Sites.List',
    component: SiteList,
    children: [
      {
        path: '/Devices/Sites/Create',
        name: 'Sites.Create',
        component: SiteFormModal,
      },
      {
        path: '/Devices/Sites/:id',
        name: 'Sites.Update',
        component: SiteFormModal,
        props: (route) => (
          {
            ...route.params,
            ...{ id: Number.parseInt(route.params.id, 10) || undefined },
          }
        ),
        children: [
          {
            path: '/Devices/Sites/:id/Notes/',
            name: 'Sites.Notes',
            component: SiteNoteList,
          },
          {
            path: '/Devices/Sites/:id/Notes/Create',
            name: 'Sites.Notes.Create',
            component: SiteNoteFormModal,
          },
          {
            path: '/Devices/Sites/:id/Notes/CreateMaintenanceRecord',
            name: 'Sites.Notes.CreateMaintenanceRecord',
            component: SiteNoteFormModal,
          },
          {
            path: '/Devices/Sites/:id/Notes/:noteId',
            name: 'Sites.Notes.Update',
            component: SiteNoteFormModal,
            props: true,
          },
          {
            path: '/Devices/Sites/:id/Configurations/',
            name: 'Sites.SiteConfigurations',
            component: SiteConfigurationsList,
          },
          {
            path: '/Devices/Sites/:id/Configurations/Create',
            name: 'Sites.SiteConfigurations.Create',
            component: SiteConfigurationsCreateModal,
          },
          {
            path: '/Devices/Sites/:id/Configurations/Create/:startdate',
            name: 'Sites.SiteConfigurations.CreateModel',
            component: SiteConfigurationsFormModal,
            props: true,
          },
          {
            path: '/Devices/Sites/:id/Configurations/Edit/:startdate',
            name: 'Sites.SiteConfigurations.EditModel',
            component: SiteConfigurationsFormModal,
            props: true,
          },
          {
            path: '/Devices/Sites/:id/Configurations/Details/:startdate',
            name: 'Sites.SiteConfigurations.Details',
            component: SiteConfigurationsDetailsModal,
            props: true,
          },
          {
            path: '/Devices/Sites/:id/Attachments/',
            name: 'Sites.Attachments',
            component: SiteAttachmentList,
            props: true,
            children: [
              {
                path: '/Devices/Sites/:id/Attachments/Create',
                name: 'Sites.Attachments.Create',
                component: SiteFolderModal,
              },
              {
                path: '/Devices/Sites/:id/Attachments/Rename',
                name: 'Sites.Attachments.Rename',
                component: FolderRename,
              },
            ],
          },
        ],
      },
    ],
  },
];
