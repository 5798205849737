<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <v-card>
      <v-card-text>
        <v-container fluid>
          <v-form>
            <ag-grid-vue
              v-if="isBoom"
              style="width: 100%; height: 400px;"
              class="ag-theme-balham-dark"
              :column-defs="columnBoom"
              :row-data="models"
              :row-selection="'single'"
              :locale-text="localeText"
              @grid-ready="onGridReady"
              @selection-changed="onGridSelectionChanged"
            />
            <ag-grid-vue
              v-else-if="isGinPole"
              style="width: 100%; height: 400px;"
              class="ag-theme-balham-dark"
              :column-defs="columnGinPole"
              :row-data="models"
              :row-selection="'single'"
              :locale-text="localeText"
              @grid-ready="onGridReady"
              @selection-changed="onGridSelectionChanged"
            />
            <ag-grid-vue
              v-else-if="isLogger"
              style="width: 100%; height: 400px;"
              class="ag-theme-balham-dark"
              :column-defs="columnLogger"
              :row-data="models"
              :row-selection="'single'"
              :locale-text="localeText"
              @grid-ready="onGridReady"
              @selection-changed="onGridSelectionChanged"
            />
            <ag-grid-vue
              v-else-if="isTower"
              style="width: 100%; height: 400px;"
              class="ag-theme-balham-dark"
              :column-defs="columnTower"
              :row-data="models"
              :row-selection="'single'"
              :locale-text="localeText"
              @grid-ready="onGridReady"
              @selection-changed="onGridSelectionChanged"
            />
            <ag-grid-vue
              v-else
              style="width: 100%; height: 400px;"
              class="ag-theme-balham-dark"
              :column-defs="columnDefs"
              :row-data="models"
              :row-selection="'single'"
              :locale-text="localeText"
              @grid-ready="onGridReady"
              @selection-changed="onGridSelectionChanged"
            />
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="onAccept"
        >
          {{ $t('Accept') }}
        </v-btn>
        <v-btn
          text
          @click.stop="onCancel"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import i18n from '@/plugins/i18n';
import agGridFr from '@/locales/agGridFr';

export default {
  components: {
    AgGridVue,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    equipmentTypeId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data: () => ({
    columnDefs: null,
    columnBoom: null,
    columnGinPole: null,
    columnLogger: null,
    columnTower: null,
    modelSelected: null,
    gridApi: null,
    columnApi: null,
    localeText: null,
  }),
  computed: {
    ...mapState('equipment/equipments', ['models']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isBoom() {
      return this.equipmentTypeId != null
      && this.equipmentTypeId === 1;
    },
    isGinPole() {
      return this.equipmentTypeId != null
      && this.equipmentTypeId === 3;
    },
    isLogger() {
      return this.equipmentTypeId != null
      && this.equipmentTypeId === 4;
    },
    isTower() {
      return this.equipmentTypeId != null
      && this.equipmentTypeId === 6;
    },
  },
  created() {
    if (i18n.locale === 'fr') {
      this.localeText = agGridFr.locale_fr;
    }
    this.columnDefs = [
      {
        headerName: this.$t('Equipments.Equipments.Columns.ManufacturerName'), field: 'ManufacturerName', filter: true, sortable: true, resizable: true,
      },
      {
        headerName: this.$t('Equipments.Equipments.Columns.ModelName'), field: 'ModelName', filter: true, sortable: true, resizable: true,
      },
    ];
    this.columnBoom = this.$clone(this.columnDefs);
    this.columnBoom.push({
      headerName: this.$t('Equipments.Equipments.Columns.BoomLength'), field: 'BoomLength', filter: true, sortable: true, resizable: true,
    });
    this.columnBoom.push({
      headerName: this.$t('Equipments.Equipments.Columns.Height'), field: 'PostHeight', filter: true, sortable: true, resizable: true,
    });
    this.columnGinPole = this.$clone(this.columnDefs);
    this.columnGinPole.push({
      headerName: this.$t('Equipments.Equipments.Columns.TowerManufacturer'), field: 'GinPoleTowerManufacturerName', filter: true, sortable: true, resizable: true,
    });
    this.columnGinPole.push({
      headerName: this.$t('Equipments.Equipments.Columns.TowerModel'), field: 'GinPoleTowerModelName', filter: true, sortable: true, resizable: true,
    });
    this.columnGinPole.push({
      headerName: this.$t('Equipments.Equipments.Columns.Height'), field: 'GinPoleTowerHeight', filter: true, sortable: true, resizable: true,
    });
    this.columnLogger = this.$clone(this.columnDefs);
    this.columnLogger.push({
      headerName: this.$t('Equipments.Equipments.Columns.LoggerType'), field: 'LoggerType', filter: true, sortable: true, resizable: true,
    });
    this.columnTower = this.$clone(this.columnDefs);
    this.columnTower.push({
      headerName: this.$t('Equipments.Equipments.Columns.Height'), field: 'TowerHeight', filter: true, sortable: true, resizable: true,
    });
  },
  methods: {
    onAccept() {
      this.$emit('on-accept', this.modelSelected);
    },
    onCancel() {
      this.$emit('input', false);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onGridSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.modelSelected = selectedRows[0];
      }
    },
  },
};
</script>
