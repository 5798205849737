<template>
  <v-simple-table
    dense
    fixed-header
    height="100%"
  >
    <template #default>
      <thead>
        <tr>
          <th
            v-t="'Customers.Sites.DetailsAndTimeline.Date'"
            class="text-left date"
          />
          <th
            v-if="maintenanceRecords"
            v-t="'Customers.Sites.DetailsAndTimeline.Technician'"
            class="text-left user"
          />
          <th
            v-else
            v-t="'Customers.Sites.DetailsAndTimeline.User'"
            class="text-left user"
          />
          <th
            v-t="'Customers.Sites.DetailsAndTimeline.Note'"
            class="text-left note"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(note, idx) in notes"
          :key="idx"
        >
          <td class="date">
            {{ formatDateTime(note.CreationTs) }}
          </td>
          <td
            v-if="maintenanceRecords"
            class="user"
          >
            {{ note.TechnicianName }}
          </td>
          <td
            v-else
            class="user"
          >
            <a :href="`mailto:${note.UserName}`">{{ note.UserDisplayName || note.UserName }}</a>
          </td>
          <td class="note">
            {{ note.Note }}
          </td>
        </tr>
        <tr v-if="notes.length == 0">
          <td
            v-t="'Customers.Sites.DetailsAndTimeline.NoDataToDisplay'"
            colspan="3"
            class="text-center"
          />
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState } from 'vuex';
import datehandling from '@/components/datehandling';

export default {
  props: {
    maintenanceRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState('customers/sites', ['site']),
    notes() {
      return this.site.Notes.filter((x) => x.IsMaintenanceRecord === this.maintenanceRecords);
    },
  },
  methods: {
    formatDateTime(date) {
      return datehandling.formatForDisplay(date);
    },
  },
};
</script>

<style scoped>
  tr {
    vertical-align: top;
  }

  td {
    padding: 12px !important;
  }

  .date {
    width: 175px;
  }

  .user {
    width: 250px;
  }
</style>
