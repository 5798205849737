<template>
  <div>
    <v-card-subtitle
      class="text-subtitle-2"
    >
      {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.EnterAlertInformation') }}
    </v-card-subtitle>
    <v-card-text>
      <div>
        <label>
          {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.NotificationMode') }}
        </label>
      </div>
      &nbsp;
      <v-radio-group
        v-model="localAlertMode"
      >
        <v-radio
          v-for="item in alertModeList"
          :key="item.Name"
          :label="item.Name"
          :value="item.Value"
        />
      </v-radio-group>
      <div>
        <label>
          {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.NotificationPriority') }}
        </label>
      </div>
      &nbsp;
      <v-radio-group
        v-model="localAlertPriority"
      >
        <v-radio
          v-for="item in alertPriorityList"
          :key="item.Name"
          :label="item.Name"
          :value="item.Value"
        />
      </v-radio-group>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    alertModeList: { type: Array, default: () => null },
    alertPriorityList: { type: Array, default: () => null },
    alertMode: { type: Number, default: () => null },
    alertPriority: { type: Number, default: () => null },
  },
  data() {
    return {
      localAlertMode: this.alertMode,
      localAlertPriority: this.alertPriority,
    };
  },
  watch: {
    localAlertMode(newValue) {
      this.$emit('update:AlertMode', newValue);
    },
    localAlertPriority(newValue) {
      this.$emit('update:AlertPriority', newValue);
    },
  },
};
</script>

<style scoped>
</style>
