<template>
  <v-card-text
    v-if="show"
    class="pb-0 pt-0"
  >
    <v-row>
      <v-col sm="4">
        <v-text-field
          v-model="form.aggregateMultiplier"
          :label="$t('AggregateBy')"
          type="number"
          oninput="if (this.value && this.value < 1) this.value = 1;"
          dense
        />
      </v-col>
      <v-col sm="5">
        <v-select
          v-model="form.aggregateForEvery"
          :items="AggregateForEveryItems"
          label=" "
          dense
        />
      </v-col>
      <v-tooltip
        bottom
      >
        <template #activator="{ on }">
          <v-col
            sm="2"
            v-on="on"
          >
            <v-checkbox
              v-model="inverseAggregateUseTimeZone"
              :label="$t('HonorTimeZone')"
              dense
            />
          </v-col>
        </template>
        <span>
          {{ $t('HonorTimeZoneTooltip') }}
        </span>
      </v-tooltip>
    </v-row>
  </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
  models: [
    { data: 'form.aggregateMultiplier', event: 'models:aggregateMultiplier' },
    { data: 'form.aggregateForEvery', event: 'models:aggregateForEvery' },
    { data: 'form.aggregateUseTimeZone', event: 'models:aggregateUseTimeZone' },
  ],
  props: {
    show: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
  },
  data: () => ({
    AggregateForEveryItems: ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],

    form: {
      aggregateMultiplier: 1,
      aggregateForEvery: 'Hours',
      aggregateUseTimeZone: false,
    },
  }),
  computed: {
    ...mapGetters('app', ['pleaseWait', 'timezone']),
    inverseAggregateUseTimeZone: {
      get() {
        return !this.form.aggregateUseTimeZone;
      },
      set(value) {
        this.form.aggregateUseTimeZone = !value;
      },
    },
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    'form.aggregateMultiplier': function w(newVal) { this.$emit('models:aggregateMultiplier', newVal); },
    'form.aggregateForEvery': function w(newVal) { this.$emit('models:aggregateForEvery', newVal); },
    'form.aggregateUseTimeZone': function w(newVal) { this.$emit('models:aggregateUseTimeZone', newVal); },
  },
  methods: {
    removeSelectedSensor(item) {
      this.form.selectedSensors.splice(this.form.selectedSensors.indexOf(item), 1);
    },
  },
  i18n: {
    messages: {
      en: {
        HonorTimeZoneTooltip: 'Honor the UTC when aggregating',
        HonorTimeZone: 'UTC',
        AggregateBy: 'Aggregate by',
      },
      fr: {
        HonorTimeZoneTooltip: 'Utilise le horaire UTC lors de l\'aggrégation',
        HonorTimeZone: 'UTC',
        AggregateBy: 'Agrégé par',
      },
    },
  },
};
</script>

<style>

</style>
