<template>
  <div>
    <SensorSelector
      v-model="showSensorSelectorDialog"
      :show-sensors="showSensors"
      :show-vectors="showVectors"
      @sensors-added="addSensors($event)"
    />
    <v-card-title class="px-3 pt-3 pb-0">
      <p class="my-0">
        {{ $t(graphText) }}
      </p>
    </v-card-title>
    <v-card-title class="px-3 pt-0 pb-3 mb-3">
      <p class="my-0">
        {{ $t('Dashboard.VisualizationSetting.VisualizationSetting') }}
      </p>
    </v-card-title>
    <v-subheader class="ma-0">
      {{ $t('Dashboard.VisualizationSetting.TitleTimeRange') }}
    </v-subheader>
    <v-card-text class="pb-0 pt-0">
      <v-radio-group
        v-model="form.periodicity"
        dense
        class="ma-0"
      >
        <v-radio value="last_n">
          <template #label>
            <div>
              {{ $t('Last') }}
            </div>
              &nbsp;
              &nbsp;
            <v-text-field
              v-model="form.lastnPeriod"
              placeholder="1"
              type="number"
              dense
              @click="selectPeriodicity('last_n')"
            />
              &nbsp;
              &nbsp;
            <v-select
              v-model="form.lastnScale"
              :placeholder="$t('Years')"
              :items="LastPicker"
              dense
              @click="selectPeriodicity('last_n')"
            />
          </template>
        </v-radio>
        <v-radio value="time_range">
          <template #label>
            <div>
              {{ $t('Range') }}
            </div>
              &nbsp;
              &nbsp;
            <datetime-picker
              v-model="form.dateRangeFrom"
              :label="$t('From')"
              :text-field-props="pickerTextFieldProps"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              @click="selectPeriodicity('time_range')"
            />
              &nbsp;
              &nbsp;
            <datetime-picker
              v-model="form.dateRangeTo"
              :label="$t('To')"
              :text-field-props="pickerTextFieldProps"
              :date-picker-props="pickerDateProps"
              :time-picker-props="pickerTimeProps"
              @click="selectPeriodicity('time_range')"
            />
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
    <v-subheader class="ma-0">
      {{ $t('Dashboard.VisualizationSetting.TitlePeriodAggregation') }}
    </v-subheader>
    <period-aggregation-card
      v-models:aggregateMultiplier="form.aggregateMultiplier"
      v-models:aggregateForEvery="form.aggregateForEvery"
      v-models:aggregateUseTimeZone="form.aggregateUseTimeZone"
      :show="showTimeRangeSelection"
    />
    <v-subheader class="ma-0">
      {{ $t('Dashboard.VisualizationSetting.TitleSelectSensor') }}
    </v-subheader>
    <v-card-text class="pb-0 pt-0">
      <v-btn
        :title="showSensors ? $t('Dashboard.AddSensors') : $t('Dashboard.AddVectors')"
        block
        @click="openAddSensorDialog"
      >
        <span class="mdi mdi-memory" />
        {{ showSensors ? $t('Dashboard.AddSensors') : $t('Dashboard.AddVectors') }}
      </v-btn>
      <v-virtual-scroll
        :items="form.selectedSensors"
        :item-height="28"
        height="160"
      >
        <template #default="{ item }">
          <v-list-item
            :key="item.Id"
            dense
            class="pa-0"
            style="min-height: 24px;"
          >
            <v-list-item-icon class="ml-0 mr-1">
              <v-icon v-if="item.T == 'S'">
                mdi-alpha-s-box-outline
              </v-icon>
              <v-icon v-if="item.T == 'V'">
                mdi-alpha-v-box-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-action>
              <v-select
                :value="(item.Aggregation || 'AVG')"
                :items="AggregateBy2Query"
                dense
                class="mr-3"
                style="width: 90px; font-size: 80%;"
                @input="item.Aggregation = $event"
              />
            </v-list-item-action>
            <v-list-item-title>{{ item.InstrumentName }}.{{ item.Name }}</v-list-item-title>
            <v-list-item-action>
              <v-btn
                icon
                small
                @click="form.selectedSensors.splice(form.selectedSensors.indexOf(item))"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="pleaseWait"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        :disabled="form.selectedSensors.length === 0"
        @click="save"
      >
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import datehandling from '@/components//datehandling';
import DatetimePicker from '@/components//DatetimePicker.vue';
import SensorSelector from '../SensorSelector.vue';
import PeriodAggregationCard from '@/dashboard/components/graphform/PeriodAggregationCard.vue';

export default {
  components: {
    SensorSelector,
    DatetimePicker,
    PeriodAggregationCard,
  },
  props: {
    visualisation: {
      type: String,
      default: () => null,
      required: true,
    },
    graphType: {
      type: String,
      default: () => null,
      required: true,
    },
    graphStyle: {
      type: String,
      default: () => null,
      required: true,
    },
    graphText: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  data: () => ({
    LastPicker: ['Years', 'Months', 'Days', 'Hours', 'Minutes'],
    LastPicker2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE'],
    AggregateByItems: ['Any', 'Average', 'Min', 'Max'],
    AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX'],
    AggregateForEveryItems: ['Years', 'Months', 'Days', 'Hours', 'Minutes', 'Seconds'],
    AggregateForEvery2Query: ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND'],
    model: {
      widgetId: null,
      enabled: true,
      visible: true,
      axisWidth: 4,
      axisHeight: 200,
      axisX: 0,
      axisY: 0,
      axisLock: false,
    },
    form: {
      selectedSensors: [],
      graphType: null,
      periodicity: 'last_n',
      dateRangeFrom: null,
      dateRangeTo: null,
      lastnScale: 'Hours',
      lastnPeriod: 2,
      aggregateBy: 'Any',
      aggregateMultiplier: 1,
      aggregateForEvery: 'Hours',
      aggregateUseTimeZone: true,
    },
    dateRangeMenu: false,
    showSensorSelectorDialog: false,
    showTimeRangeSelection: true,
    pickerTextFieldProps: {
      dense: true,
      style: 'font-size: 80%;',
    },
    pickerDateProps: {
      locale: 'en-ca',
    },
    pickerTimeProps: {
      format: '24hr',
    },
  }),
  computed: {
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapGetters('app', ['pleaseWait', 'timezone']),

    showSensors() {
      return this.visualisation.startsWith('sensor+');
    },
    showVectors() {
      return this.visualisation.startsWith('vector+');
    },
    isNew() {
      return this.activeWidget === undefined;
    },
    stringDateRange() {
      return [this.form.dateRangeFrom, this.form.dateRangeTo];
    },
    dateRangeStart() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[0];
    },
    dateRangeEnd() {
      const dateRange = this.stringDateRange.map((stringDate) => stringDate);
      return dateRange.sort((a, b) => Date.parse(a) - Date.parse(b))[dateRange.length - 1];
    },
    jsonWidgetSettings() {
      const query = {
        user: {
          timezone: this.timezone,
        },
        data: {
          formulas: [],
          input_filter: [],
          input_time_axis: {
            round_to: {
              scale: 'HOUR',
              multiplier: 1,
              divisor: 1,
            },
          },
          output_time_axis: {
            round_to: {
              scale: 'HOUR',
              multiplier: 1,
              divisor: 1,
            },
          },
          sensor_selection: [],
          vector_selection: [],
          timezone: this.timezone,
          output_in_local: false,
        },
        visualization: {
          alias: [],
          graphType: this.graphType,
          graphStyle: this.graphStyle,
          graphOptions: {
            x_axis: [],
            y_axis: [],
            groupby: [],
          },
        },
      };

      if (this.visualisation.startsWith('vector+scatter+') || this.visualisation.startsWith('vector+fourier+')) {
        query.visualization.graphOptions.x_axis = [(this.form.selectedSensors.filter((x) => x.T === 'V').map((x, i) => (JSON.parse(`{"v${x.Id}${i}": "index"}`)))[0])];
        query.visualization.graphOptions.groupby.push({
          ts: 'value',
        });
      } else {
        query.visualization.graphOptions.x_axis.push({
          ts: 'value',
        });
      }

      query.user.selectedSensors = this.form.selectedSensors;

      query.data.formulas = query.data.formulas.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'S')
          .map((x) => ({
            symbol: `s${x.Id}`,
            formula_text: `=s${x.Id};`,
            output_aggregation: x.Aggregation || 'AVG',
          })),
      );

      if (this.visualisation.startsWith('vector+fourier+')) {
        query.data.formulas = query.data.formulas.concat(
          this.form.selectedSensors
            .filter((x) => x.T === 'V')
            .map((x, i) => ({
              symbol: `v${x.Id}${i}`,
              formula_text: `=VRealFft(VDimRescale(v${x.Id}${i},0.05));`,
              output_aggregation: x.Aggregation || 'AVG',
            })),
        );
      } else {
        query.data.formulas = query.data.formulas.concat(
          this.form.selectedSensors
            .filter((x) => x.T === 'V')
            .map((x, i) => ({
              symbol: `v${x.Id}${i}`,
              formula_text: `=v${x.Id}${i};`,
              output_aggregation: x.Aggregation || 'AVG',
            })),
        );
      }

      query.data.sensor_selection = (this.form.selectedSensors
        .filter((x) => x.T === 'S')
        .map((x) => ({
          symbol: `s${x.Id}`,
          sensor_id: x.Id,
          input_aggregation: x.Aggregation || 'AVG',
        }))
      );

      query.data.vector_selection = (this.form.selectedSensors
        .filter((x) => x.T === 'V')
        .map((x, i) => ({
          symbol: `v${x.Id}${i}`,
          vector_sensor_id: x.Id,
          vector_name: x.Name,
          input_aggregation: x.Aggregation || 'AVG',
        }))
      );

      query.visualization.graphOptions.y_axis = query.visualization.graphOptions.y_axis.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'S')
          .map((x) => (JSON.parse(`{"s${x.Id}": "value"}`))),
      );

      query.visualization.graphOptions.y_axis = query.visualization.graphOptions.y_axis.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'V')
          .map((x, i) => (JSON.parse(`{"v${x.Id}${i}": "value"}`))),
      );

      query.visualization.alias = query.visualization.alias.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'S')
          .map((x) => (JSON.parse(`{"s${x.Id}": "${x.InstrumentName}.${x.Name}"}`))),
      );

      query.visualization.alias = query.visualization.alias.concat(
        this.form.selectedSensors
          .filter((x) => x.T === 'V')
          .map((x, i) => (JSON.parse(`{"v${x.Id}${i}": "${x.InstrumentName}.${x.Name}"}`))),
      );

      if (this.visualisation.startsWith('vector+fourier+')) {
        query.data.input_time_axis.round_to.scale = 'NONE';
        query.data.input_time_axis.round_to.multiplier = 1;
        query.data.input_time_axis.round_to.divisor = 1;

        query.data.output_time_axis.round_to.scale = this.AggregateForEvery2Query[
          this.AggregateForEveryItems.indexOf(this.form.aggregateForEvery)
        ];
        query.data.output_time_axis.round_to.multiplier = this.form.aggregateMultiplier;
        query.data.output_time_axis.round_to.divisor = this.form.aggregateMultiplier;
      } else {
        query.data.input_time_axis.round_to.scale = this.AggregateForEvery2Query[
          this.AggregateForEveryItems.indexOf(this.form.aggregateForEvery)
        ];
        query.data.input_time_axis.round_to.multiplier = this.form.aggregateMultiplier;
        query.data.input_time_axis.round_to.divisor = this.form.aggregateMultiplier;

        query.data.output_time_axis.round_to.scale = 'NONE';
        query.data.output_time_axis.round_to.multiplier = 1;
        query.data.output_time_axis.round_to.divisor = 1;
      }

      if (this.form.periodicity === 'last_n') {
        query.data.input_filter.push({
          last_n: {
            scale: this.LastPicker2Query[this.LastPicker.indexOf(this.form.lastnScale)],
            period: this.form.lastnPeriod,
          },
        });
      } else if (this.form.periodicity === 'time_range') {
        query.data.input_filter.push({
          time_range: {
            start: datehandling.formatForApi(this.dateRangeStart),
            end: datehandling.formatForApi(this.dateRangeEnd),
          },
        });
      }

      // Update date range and aggregation with global config vs widget config
      this.$store.dispatch('dashboardWidget/updateDateRangeAndAggregation', {
        widgetSettings: query,
        visualisationSubType: this.visualisation,
      });

      if (this.form.aggregateUseTimeZone) {
        query.data.output_in_local = true;
      }

      return JSON.stringify(query);
    },
  },
  watch: {
    activeWidget() {
      this.init();
    },
    editModeFlag(val) {
      if (!val) {
        this.clearWidgetSettings();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.clearWidgetSettings();
      if (!this.isNew) {
        this.loadWidgetSettings();
      }
    },
    addSensors(array) {
      if (array.length > 0) {
        array.forEach((n) => {
          this.form.selectedSensors.push(this.$clone(n));
        });
      }
    },
    loadSensors(array, obj, type) {
      for (let i = 0; i < array.length; i += 1) {
        let name = array[i].symbol;
        if (obj.visualization.alias) {
          const zz = obj.visualization.alias.find((d) => d[array[i].symbol] !== undefined);
          if (zz) {
            name = zz[array[i].symbol];
          }
        }
        if (type === 'S') {
          this.form.selectedSensors.push({
            T: type,
            Id: array[i].sensor_id,
            Name: name,
          });
        } else if (type === 'V') {
          this.form.selectedSensors.push({
            T: type,
            Id: array[i].vector_sensor_id,
            Name: array[i].vector_name,
          });
        }
      }
    },
    clearWidgetSettings() {
      this.form.selectedSensors = [];
      this.form.periodicity = 'last_n';
      this.form.dateRangeFrom = null;
      this.form.dateRangeTo = null;
      this.form.lastnScale = 'Hours';
      this.form.lastnPeriod = 2;
      this.form.aggregateBy = 'Any';
      this.form.aggregateMultiplier = 1;
      this.form.aggregateForEvery = 'Hours';
    },
    loadWidgetSettings() {
      const widgetSettings = datehandling.parseObjectFromApi(
        JSON.parse(this.activeWidget.WidgetSettings),
      );
      if (widgetSettings.user.selectedSensors) {
        this.form.selectedSensors = widgetSettings.user.selectedSensors;
      } else {
        this.form.selectedSensors = [];
        this.loadSensors(widgetSettings.data.sensor_selection, widgetSettings, 'S');
        this.loadSensors(widgetSettings.data.vector_selection, widgetSettings, 'V');
      }
      for (let i = 0; i < this.form.selectedSensors.length; i += 1) {
        const ss = this.form.selectedSensors[i];
        if (ss.T === 'S') {
          ss.Aggregation = widgetSettings.data.sensor_selection
            .find((x) => ss.Id === x.sensor_id)
            .input_aggregation;
        } else if (ss.T === 'V') {
          ss.Aggregation = widgetSettings.data.vector_selection
            .find((x) => ss.Id === x.vector_sensor_id && ss.Name === x.vector_name)
            .input_aggregation;
        }
      }

      const filter = widgetSettings.user.input_filter
        ? widgetSettings.user.input_filter[0]
        : widgetSettings.data.input_filter[0];
      if (filter) {
        if (filter.time_range) {
          this.form.periodicity = 'time_range';
          this.form.dateRangeFrom = filter.time_range.start;
          this.form.dateRangeTo = filter.time_range.end;
        } else if (filter.last_n) {
          this.form.periodicity = 'last_n';
          this.form.lastnScale = this.LastPicker[
            this.LastPicker2Query.indexOf(filter.last_n.scale)
          ];
          this.form.lastnPeriod = filter.last_n.period;
        }
      }

      const inputTimeAxis = widgetSettings.user.input_time_axis
        ? widgetSettings.user.input_time_axis
        : widgetSettings.data.input_time_axis;
      this.form.aggregateForEvery = this.AggregateForEveryItems[
        this.AggregateForEvery2Query.indexOf(inputTimeAxis.round_to.scale.replace('TZ', ''))
      ];
      this.form.aggregateUseTimeZone = inputTimeAxis.round_to.scale.startsWith('TZ');
      if (!this.form.aggregateForEvery) {
        this.form.aggregateForEvery = this.AggregateForEveryItems[
          this.AggregateForEvery2Query
            .indexOf(widgetSettings.data.output_time_axis.round_to.scale.replace('TZ', ''))
        ];
        this.form.aggregateUseTimeZone = widgetSettings.data.output_time_axis.round_to.scale.startsWith('TZ');
      }
      if (!this.form.aggregateUseTimeZone) {
        // if it is false, it could be an old widget settings
        if (widgetSettings.data.output_in_local === undefined
          && widgetSettings.data.timezone === undefined) {
          // old widgetSettings
          this.form.aggregateUseTimeZone = true;
        }
      }
      this.form.aggregateMultiplier = inputTimeAxis.round_to.multiplier;
    },
    openAddSensorDialog() {
      this.showSensorSelectorDialog = true;
    },
    cancel() {
      this.$emit('cancel', this.isNew);
    },
    save() {
      if (this.isNew) {
        this.create();
      } else {
        this.saveWidgetSettings();
      }
    },
    create() {
      this.$emit('create', {
        DashboardsWidgetId: this.activeWidgetId,
        DatasourceSettings: '', // TODO check if this property is useful, it seems that WidgetSettings do it all
        WidgetSettings: this.jsonWidgetSettings,
        WidgetId: this.model.widgetId,
        Enabled: this.model.enabled,
        Visible: this.model.visible,
        AxisHeight: this.model.axisHeight,
        AxisWidth: this.model.axisWidth,
        AxisY: this.model.axisY,
        AxisX: this.model.axisX,
        AxisLock: this.model.axisLock,
      });
    },
    saveWidgetSettings() {
      this.$emit('save', {
        DashboardsWidgetId: this.activeWidgetId,
        WidgetSettings: this.jsonWidgetSettings,
      });
    },
    selectPeriodicity(val) {
      this.form.periodicity = val;
    },
  },
};
</script>

<style>

</style>
