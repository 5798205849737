<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <v-dialog
      v-model="showSelectAccessGroup"
      persistent
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-form
          ref="form"
          lazy-validation
          :readonly="readMode"
        >
          <v-card-text>
            <v-container fluid>
              <v-select
                v-model="targetAccessGroupId"
                :label="$t('VirtualInstrument.Template.SelectAccessGroup')"
                :items="accessgroups"
                :rules="[v => !!v || $t('Validation.Required')]"
                item-text="AccessGroupName"
                item-value="AccessGroupId"
              />
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              v-if="!readMode"
              text
              @click="doApplyTemplateApiCall"
            >
              {{ $t('Accept') }}
            </v-btn>
            <v-btn
              text
              @click.stop="showSelectAccessGroup=false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <dialog-view-instance-io
      v-if="showViewInstanceDialog"
      v-models:show="showViewInstanceDialog"
      :instance="viData"
    />
    <server-side-data-grid
      :grid-source="sources.DataSource"
      :toolbar-buttons="toolbarButtons"
      :show-new-button="false"
      :refresh="refresh"
      :enable-selection="'multiple'"
      @selection-changed="instanceSelectionChanged"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import importal from '@/api/importal';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import DataSource from './gridsources/gsmatch';
import DialogViewInstanceIo from './components/DialogViewInstanceIO.vue';

export default {
  components: {
    ServerSideDataGrid,
    DialogViewInstanceIo,
  },
  data: () => ({
    refresh: 0,
    viData: null,
    showViewInstanceDialog: false,
    toolbarButtons: [],

    runnableInstances: [],
    removableInstances: [],
    accessgroups: [],

    sources: {
      DataSource: null,
    },

    editingNode: {
      NameTemplate: null,
    },

    showSelectAccessGroup: false,
    targetAccessGroupId: null,
  }),
  computed: {
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),

    VITplTemplateId() {
      return this.$route.params.id;
    },

    createButtonEnabled() {
      return this.runnableInstances.length > 0;
    },

    deleteButtonEnabled() {
      return this.removableInstances.length > 0;
    },
  },
  watch: {
    tenantId() {
      this.refresh += 1;
      // this.readTemplate();
    },
    accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.refresh += 1;
        // this.readTemplate();
      }
    },
  },
  created() {
    this.sources.DataSource = new DataSource(this);
  },
  mounted() {
    // this.readTemplate();
    this.toolbarButtons = [
      {
        tooltip: 'Refresh',
        icon: 'mdi-refresh',
        enabled: true,
        click: () => { this.doRefresh(); },
      },
      {
        tooltip: 'Create',
        icon: 'mdi-run',
        enabled: this.createButtonEnabled,
        click: () => { this.doApplyTemplate(); },
      },
      {
        tooltip: 'Delete',
        icon: 'mdi-delete-forever',
        enabled: this.deleteButtonEnabled,
        click: () => { this.doRemoveTemplate(); },
      },
    ];
  },
  methods: {
    doRefresh() {
      this.refresh += 1;
      // this.readTemplate();
    },

    instanceSelectionChanged(e) {
      const { api } = e;
      const runnable = api.getSelectedRows()
        .filter((x) => x.IsComplete);
      this.runnableInstances.splice(0, this.runnableInstances.length);
      this.runnableInstances.push(...runnable);

      const removable = api.getSelectedRows()
        .filter((x) => x.IsInstanciated);
      this.removableInstances.splice(0, this.removableInstances.length);
      this.removableInstances.push(...removable);

      [1].forEach((x) => {
        if (this.toolbarButtons[x]) {
          this.toolbarButtons[x].enabled = this.createButtonEnabled;
        }
      });
      [2].forEach((x) => {
        if (this.toolbarButtons[x]) {
          this.toolbarButtons[x].enabled = this.deleteButtonEnabled;
        }
      });
    },

    async doApplyTemplate() {
      await this.$PleaseWaitOneAsync(
        importal.get('AccessGroupList')
          .then((resp) => resp.data)
          .then((data) => { this.accessgroups = data; this.showSelectAccessGroup = true; })
          .catch((error) => {
            this.ShowError(error);
          }),
      );
    },

    doApplyTemplateApiCall() {
      this.showSelectAccessGroup = false;
      this.CallApiPost('ViTemplateApplyTemplate', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        InstanceIds: this.runnableInstances.map((x) => x.InstanceId),
        TargetAccessGroupId: this.targetAccessGroupId,
      });
    },

    doRemoveTemplate() {
      this.CallApiPost('ViTemplateUnapplyTemplate', {
        TenantId: this.tenantId,
        VITplTemplateId: this.VITplTemplateId,
        InstanceIds: this.removableInstances.map((x) => x.InstanceId),
      });
    },

    showInstanceIO(params) {
      this.viData = params;
      this.showViewInstanceDialog = true;
    },

    async CallApiPost(endpoint, obj) {
      await this.$PleaseWaitOneAsync(
        importal.post(endpoint, obj)
          .then((resp) => {
            this.showTemplate(resp.data);
          })
          .catch((error) => {
            this.ShowError(error);
          }),
      );
    },
    async CallApiGet(endpoint) {
      await this.$PleaseWaitOneAsync(
        importal.get(endpoint)
          .then((resp) => {
            this.showTemplate(resp.data);
          })
          .catch((error) => {
            this.ShowError(error);
          }),
      );
    },
    ShowError(error) {
      const { data } = error.response;
      this.$root.$confirm(`${data.exception}\n${data.stacktrace}`, true, {
        title: `${error.response.status}: ${error.response.statusText}`,
      });
    },
  },
};
</script>

<style lang="scss">
</style>
