export default [
  {
    label: 'Settings.Title',
    icon: 'mdi-cog',
    to: { name: 'Settings' },
    title: 'Settings.PageTitle',
    items: [
      {
        label: 'Settings.AccessControl.Title',
        to: { name: 'Settings.AccessControl' },
        title: 'Settings.AccessControl.PageTitle',
      },
      {
        label: 'Settings.Tenants.Title',
        to: { name: 'Settings.Tenants.List' },
        title: 'Settings.Tenants.Title',
      },
      {
        label: 'Settings.MailList.Title',
        to: { name: 'Settings.MailList' },
        title: 'Settings.MailList.PageTitle',
      },
      {
        label: 'Settings.LandOwner.Title',
        to: { name: 'LandOwners.List' },
        title: 'Settings.LandOwner.PageTitle',
        isWindServer: true,
      },
    ],
  },

];
