<template>
  <div class="main-dash-container">
    <v-row
      style="height: calc(100vh - 420px); width: 100%"
      class="ma-0 pa-0"
    >
      <v-col
        cols="12"
        class="ma-0 pa-0"
      >
        <v-tabs centered>
          <v-tab @click="tabChange('Script')">
            {{ $t('VirtualInstrument.TemplateSets.Script') }}
          </v-tab>
          <v-tab @click="tabChange('AssociatedTemplates')">
            {{ $t('VirtualInstrument.TemplateSets.AssociatedTemplates') }}
          </v-tab>
        </v-tabs>

        <ConfirmDialog
          v-model="confirm.show"
          :title="confirm.title"
          :message="confirm.message"
          @on-accept="confirm.callback"
        />

        <div
          v-if="tabValue == 'Script' && tplsScript"
          style="height: calc(100vh - 120px); width: 100%"
        >
          <v-toolbar dense>
            <toolbar-button-with-tooltip
              :tooltip-text="$t('Save')"
              icon-name="mdi-content-save"
              @click="saveScript()"
            />

            <toolbar-button-with-tooltip
              :tooltip-text="$t('Refresh')"
              icon-name="mdi-refresh"
              @click="doRefresh()"
            />

            <toolbar-button-with-tooltip
              :tooltip-text="$t('VirtualInstrument.TemplateSets.Activate')"
              icon-name="mdi-run"
              :disabled="true"
            />

            <toolbar-button-with-tooltip
              :tooltip-text="$t('VirtualInstrument.TemplateSets.Revert')"
              icon-name="mdi-rewind"
              :disabled="true"
            />
          </v-toolbar>
          <div class="ma-0 pa-5">
            <v-form
              ref="form"
              lazy-validation
            >
              <v-text-field
                v-model="tplsScript.Name"
                :label="$t('VirtualInstrument.TemplateSets.ListHeaders.Name')"
                type="text"
                :rules="requiredRule"
                dense
              />
              <br>
              <v-text-field
                v-model="tplsScript.Description"
                :label="$t('VirtualInstrument.TemplateSets.ListHeaders.Description')"
                type="text"
                dense
              />
              <br>

              <div id="scriptHelp">
                {{ $t('VirtualInstrument.TemplateSets.ScriptHelpMessage') }}
              </div>

              <template-script-monaco-editor
                v-model="tplsScript.ProgramText"
                height="300px"
                :refresh="refresh"
              />
              <div id="programTextValidationError" v-if="showProgramTextError">{{ $t('Validation.Required') }}</div>
            </v-form>
          </div>
        </div>

        <div
          v-if="tabValue == 'AssociatedTemplates'"
          style="height: calc(100vh - 120px); width: 100%"
        >
          <server-side-data-grid
            :grid-source="sources.DataSource"
            :show-new-button="false"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import DataSource from './gridsources/gsscripttemplates';
import importal from '@/api/importal';
import TemplateScriptMonacoEditor from '@/components/monaco/TemplateScriptMonacoEditor.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
  components: {
    ServerSideDataGrid,
    ToolbarButtonWithTooltip,
    TemplateScriptMonacoEditor,
    ConfirmDialog,
  },
  data() {
    return {
      confirm: {
        message: '',
        title: '',
        show: false,
        callback: () => {},
      },
      refresh: 0,
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      sources: {
        DataSource: null,
      },
      tabValue: 'Script',
      tplsScript: null,
      showProgramTextError: false,
    };
  },
  computed: {
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),
  },
  watch: {
    tenantId() {
      if (this.sources.DataSource) {
        this.refresh += 1;
      }
    },
    accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.refresh += 1;
      }
    },
  },
  created() {
    this.sources.DataSource = new DataSource(this);
    this.getScriptData();
  },
  methods: {
    getScriptData() {
      this.$store.commit('app/pleaseWait', true);
      importal.get(`TplsScriptList?tenantId=${this.tenantId}&TplsScriptId=${this.$route.params.id}`)
        .then((response) => {
          this.tplsScript = response.data;
          this.refresh += 1;
          this.$store.commit('app/pleaseWait', false);
        });
    },
    doRefresh() {
      this.confirm.title = this.$t('VirtualInstrument.TemplateSets.RefreshData');
      this.confirm.message = this.$t('VirtualInstrument.TemplateSets.RefreshConfirmation');
      this.confirm.callback = this.getScriptData;
      this.confirm.show = true;
    },
    saveScript() {
      this.showProgramTextError = this.tplsScript.ProgramText === '';
      if (this.$refs.form.validate() && !this.showProgramTextError) {
        this.$store.commit('app/pleaseWait', true);
        importal.post(`TplsScriptUpdate?tenantId=${this.tenantId}`, this.tplsScript)
          .then((response) => {
            this.tplsScript = response.data;
            this.$store.commit('app/pleaseWait', false);
          });
      }
    },
    tabChange(changeto) {
      this.tabValue = changeto;
    },
  },
};
</script>

<style scoped lang="scss">
#scriptHelp {
  opacity: 0.7;
  margin-bottom: 10px;
}

#programTextValidationError {
  color: rgb(255, 82, 82);
  font-size: 12px;
}
</style>
