import api from '@/api/importal';
import i18n from '@/plugins/i18n';
import tree from './siteAttachments.tree';

const state = {
  siteAttachments: [],
  parentId: null,
  nestedFolders: null,
  siteId: null,
  siteGuid: null,
  selectedFolderId: null,
  selectedFolderName: null,
  selectedFolderFiles: [],
  folderNameExists: false,
};

const actions = {

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = (state.siteGuid)
      ? await api.get(`SiteAttachmentsList?siteGuid=${state.siteGuid}`)
      : await api.get(`SiteAttachmentsList?siteId=${state.siteId}`);

    commit('siteAttachments', response.data);
    const attachments = response.data;
    commit('nestedFolders', [
      {
        name: i18n.t('WindServer.Sites.Attachments.TreeViewRootLabel'),
        id: 0,
        expanded: true,
        children: tree.convertToNestedFolders(attachments, null),
      },
    ]);
    commit('app/pleaseWait', false, { root: true });
  },

  async getFolderFiles({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const id = state.selectedFolderId === 0 ? null : state.selectedFolderId;
    const response = (state.siteGuid)
      ? await api.get(`SiteAttachmentsFilesList?parentId=${id}&siteGuid=${state.siteGuid}`)
      : await api.get(`SiteAttachmentsFilesList?parentId=${id}&siteId=${state.siteId}`);
    commit('selectedFolderFiles', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async uploadFile({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    if (state.siteGuid) {
      await api.post(`SiteAttachmentsUpload?id=${state.selectedFolderId === 0 ? null : state.selectedFolderId}&siteGuid=${state.siteGuid}`, payload);
    } else {
      await api.post(`SiteAttachmentsUpload?id=${state.selectedFolderId === 0 ? null : state.selectedFolderId}&siteId=${state.siteId}`, payload);
    }

    await dispatch('getFolderFiles');
    commit('app/pleaseWait', false, { root: true });
  },

  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('SiteAttachmentsFolderCreate', {
      SiteId: (!state.siteGuid) ? state.siteId : null,
      SiteGuid: state.siteGuid,
      Name: payload.Name,
      ParentId: state.selectedFolderId === 0 ? null : state.selectedFolderId,
    });
    await dispatch('list');
    commit('app/pleaseWait', false, { root: true });
  },

  async validateRename({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('folderNameExists', false);
    const response = (state.siteGuid)
      ? await api.get(`SiteAttachmentsFolderNameValidate?parentId=${state.selectedFolderId}&folderName=${payload.Name}&folderId=null&siteGuid=${state.siteGuid}`)
      : await api.get(`SiteAttachmentsFolderNameValidate?siteId=${state.siteId}&parentId=${state.selectedFolderId}&folderName=${payload.Name}&folderId=null`);

    if (response.data != null) {
      commit('folderNameExists', true);
    }
    commit('app/pleaseWait', false, { root: true });
  },

  async validateAdd({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('folderNameExists', false);
    const response = (state.siteGuid)
      ? await api.get(`SiteAttachmentsFolderNameValidate?parentId=${state.selectedFolderId}&folderName=${payload.Name}&folderId=null&siteGuid=${state.siteGuid}`)
      : await api.get(`SiteAttachmentsFolderNameValidate?siteId=${state.siteId}&parentId=${state.selectedFolderId}&folderName=${payload.Name}&folderId=null`);
    if (response.data != null) {
      commit('folderNameExists', true);
    }
    commit('app/pleaseWait', false, { root: true });
  },

  async renameFolder({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('SiteAttachmentsFolderRename', {
      FolderId: state.selectedFolderId,
      Name: payload.Name,
    });
    await dispatch('list');
    commit('app/pleaseWait', false, { root: true });
  },

  async removeFolder({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const { ParentId } = state.siteAttachments.find((x) => x.SiteAttachmentId === payload);
    const name = (ParentId) ? state.siteAttachments.find(
      (x) => x.SiteAttachmentId === ParentId,
    ).Name : i18n.t('WindServer.Sites.Attachments.TreeViewRootLabel');
    await api.delete(`SiteAttachmentsRemoveFolder?id=${payload}`);
    await dispatch('setSelectedFolderId', ParentId);
    await dispatch('setSelectedFolderName', name);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
    await dispatch('getFolderFiles');
  },

  async removeMultipleFiles({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('SiteAttachmentsRemoveMultipleFiles', payload);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
    await dispatch('getFolderFiles');
  },

  async updateLock({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put(`SiteAttachmentsUpdateLock?id=${payload}`);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('getFolderFiles');
  },

  async download({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`SiteAttachmentsDownload?id=${payload.id}`, { responseType: 'blob' });
    let imageBlob = await response.data;
    imageBlob = imageBlob.slice(0, imageBlob.size, response.headers['content-type']);
    const urlA = window.URL.createObjectURL(imageBlob);
    const link2A = document.createElement('a');
    link2A.href = urlA;
    link2A.setAttribute('download', payload.name);
    document.body.appendChild(link2A);
    link2A.click();
    commit('app/pleaseWait', false, { root: true });
  },

  async setSiteAttachments({ commit }, payload) {
    commit('siteAttachments', payload);
  },

  async setNestedFolders({ commit }, payload) {
    commit('nestedFolders', payload);
  },

  async setSiteId({ commit }, payload) {
    commit('siteId', payload);
  },

  async setSiteGuid({ commit }, payload) {
    commit('siteGuid', payload);
  },

  async setParentId({ commit }, payload) {
    commit('parentId', payload);
  },

  async setSelectedFolderId({ commit }, payload) {
    commit('selectedFolderId', payload);
  },

  async setSelectedFolderName({ commit }, payload) {
    commit('selectedFolderName', payload);
  },

  async setSelectedFolderFiles({ commit }, payload) {
    commit('selectedFolderFiles', payload);
  },

  async downloadFolder({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });

    if (state.siteGuid) {
      await api.get(`SiteAttachmentsFolderDownload?parentId=${state.selectedFolderId}&siteGuid=${state.siteGuid}`, payload);
    } else {
      await api.get(`SiteAttachmentsFolderDownload?parentId=${state.selectedFolderId}&siteId=${state.siteId}`, payload);
    }
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {

  siteId(state, payload) {
    state.siteId = payload;
  },

  siteGuid(state, payload) {
    state.siteGuid = payload;
  },

  siteAttachments(state, payload) {
    state.siteAttachments = payload;
  },

  nestedFolders(state, payload) {
    state.nestedFolders = payload;
  },

  parentId(state, payload) {
    state.parentId = payload;
  },

  selectedFolderId(state, payload) {
    state.selectedFolderId = payload;
  },

  selectedFolderName(state, payload) {
    state.selectedFolderName = payload;
  },

  selectedFolderFiles(state, payload) {
    state.selectedFolderFiles = payload;
  },

  folderNameExists(state, payload) {
    state.folderNameExists = payload;
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
