<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    clipped
    flat
    permanent
    light
    :expand-on-hover="mini"
    :mini-variant="mini"
    :width="200"
    class="drawer-nav"
  >
    <v-list
      v-if="$auth.isAuthenticated"
      dense
      class="fa-2x pt-4"
    >
      <v-list-group
        v-for="item in menuContent"
        :key="item.label"
        v-model="item.active"
        :prepend-icon="item.icon"
        append-icon=""
        no-action
        @click="refreshPageTitle"
      >
        <template #activator>
          <v-list-item
            v-if="item.to"
            :to="item.to"
            class="item-link"
          />
          <v-list-item
            :to="toFirstChildMenu(item)"
            class="item-link"
          />
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.label) }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="child in filterChild(item)"
          :key="child.label"
          :to="child.to"
          link
          @click="refreshPageTitle"
        >
          <v-list-item-content>
            <v-list-item-title v-html="$t(child.label)" />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template #append>
      <span :class="mini ? 'mini-poweredBy-text' : 'poweredBy-text'">Powered By</span>
      <v-img
        lazy-src="@/assets/wsp-navlogo.png"
        class="mx-auto"
        max-height="32"
        max-width="68"
        src="@/assets/wsp-navlogo.png"
      />
      <br>
      <v-divider />
      <v-btn
        block
        @click="minify = !minify"
      >
        <v-icon
          v-if="!mini"
          left
          size="32"
        >
          mdi-chevron-double-left
        </v-icon>
        <v-icon
          v-else
          size="30"
        >
          mdi-chevron-double-right
        </v-icon>
        <span
          v-if="!mini"
          class="font-weight-black"
        > {{ $t("Collapse") }} </span>
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'NavigationMenu',
  data() {
    return {
      drawer: true,
      minify: false,
    };
  },
  computed: {
    menuContent() {
      return this.menu.filter((x) => this.$auth !== null && (
        !x.isWindServer || (!!x.isWindServer && this.$ShowWindServer)
      ) && (
        x.isVisible === null || x.isVisible === undefined || x.isVisible(this)
      ));
    },
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.minify;
    },
    ...mapState('app', ['activeLink', 'menu']),
    ...mapGetters('app', ['pageTitle']),
  },
  methods: {
    toFirstChildMenu(item) {
      // const theItem = item;
      const childItem = item.items ? item.items[0]?.to : item.to;
      // if (childItem && childItem.active) {
      //   theItem.active = true;
      // }
      return childItem;
    },
    refreshPageTitle() {
      this.$store.commit('app/activeLink', this.$route.name);
    },
    filterChild(item) {
      if (item && item.items) {
        return item.items
          .filter((x) => !x.isWindServer || (!!x.isWindServer && this.$ShowWindServer));
      }
      return [];
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_includes.scss";

.logo {
  align-items: center;
  justify-content: center;
}
.logo-img {
  width: 78px;
  flex: none !important;
}
.v-list-group__header__prepend-icon {
  margin-right: 10px !important;
}
.v-list-group__items .v-list-item {
  padding-left: 43px !important;
}
.v-navigation-drawer__append {
  text-align: center;
}
.item-link {
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.drawer-nav{
  color: #323E48 !important;
background: linear-gradient(180deg, #F8F8F8 0%, #EDEDED 100%) !important;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 9999 !important;
}
.mini-poweredBy-text {
  font-weight: 400;
  font-size: .5rem;
  color: rgba(50, 62, 72, 0.4);
}
.poweredBy-text {
  font-weight: 400;
  font-size: 1rem;
  color: rgba(50, 62, 72, 0.4);
}
</style>
