<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.workers"
      :show-new-button="false"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="workers"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import WorkerList from '../gridsources/gsworkers';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        workers: null,
      },
      toolbarButtons: [],
      showConfirm: false,
      editModel: null,
    };
  },
  computed: {
    ...mapState('worker/workers', ['workers']),
  },
  created() {
    this.sources.workers = new WorkerList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.Worker.Create')) {
      this.toolbarButtons = [
        {
          tooltip: this.$t('New'),
          icon: 'mdi-plus',
          enabled: true,
          click: () => { this.createNew(); },
        },
      ];
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    refreshList() {
      this.$store.dispatch('worker/workers/list');
    },
    createNew() {
      this.$router.push({ name: 'Worker.Workers.Create' });
    },
  },
};
</script>
