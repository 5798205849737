<template>
  <div class="pl-4">
    <div
      class="text-caption px-0 ma-0"
      style="text-align: center"
    >
      {{ $t('Dashboard.Placeholder.TitleToolbar') }}
    </div>
    <v-row class="pa-0 ma-0 justify-center">
      <placeholder-menu :is-create="true" />
      <placeholder-menu :is-create="false" />
    </v-row>
  </div>
</template>

<script>
import PlaceholderMenu from './PlaceholderMenu.vue';

export default {
  name: 'PlaceholderSettings',
  components: {
    PlaceholderMenu,
  },
};
</script>

<style>
</style>
