<template>
  <v-dialog
    v-model="showDialog"
    persistent
    transition="dialog-bottom-transition"
    width="50vw"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title
          v-t="'WindServer.Sites.LandOwners.SelectorModal.Title'"
        />
        <v-card-text>
          <v-select
            v-model="selectedIds"
            :items="landOwners.filter((x) => !x.IsSelected)"
            item-text="Fullname"
            item-value="LandOwnerId"
            multiple
            chips
            persistent-hint
            :menu-props="{ top: true, offsetY: true }"
            :rules="[v => !!v && v.length > 0 || $t('Validation.Required')]"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-t="'Accept'"
            @click="select"
          />
          <v-btn
            v-t="'Cancel'"
            @click.stop="close"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      selectedIds: [],
      showDialog: false,
    };
  },
  computed: {
    ...mapState('site/siteLandOwners', ['landOwners']),
  },
  watch: {
    show(newValue) {
      this.showDialog = newValue;

      if (newValue) {
        this.selectedIds = [];
        if (this.$refs.form) this.$refs.form.reset();
      }
    },
  },
  methods: {
    close() {
      this.showDialog = false;
      this.$emit('update:show', false);
      this.selectedIds = [];
      this.$refs.form.reset();
    },
    select() {
      if (this.$refs.form.validate()) {
        this.$emit('select', this.selectedIds);
        this.close();
      }
    },
  },
};
</script>

<style>
.ag-invisible
{
  width: 0px;
  margin-left: -11px;
}
</style>
