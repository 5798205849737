/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line no-unused-vars
import * as L from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/leaflet.markercluster'; // Leaflet plugin for clustering
import 'leaflet.featuregroup.subgroup'; // Sub-Plugin of markercluster for layer switcher compat
import formulaeLanguage from '@/components/monaco/formulaeLanguage';
import templateSetScriptLanguageLanguage from '@/components/monaco/templateSetScriptLanguage';
import Vue2MultipleVModels from 'vue2-multiple-vmodels';
import config from './config';
import router from './router';
import msalPlugin from './auth/msal-plugin';
import appinsights from './plugins/appinsights';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import loadFonts from './plugins/webfontloader';
import store from './store';
import 'leaflet/dist/leaflet.css';
import i18n from './plugins/i18n';
import '@/alert/version3';
import '@/analytics';
import '@/dashboard';
import '@/customers';
import '@/devices';
import '@/virtualinstrument';
import '@/qualityCheck';
import '@/equipment';
import '@/settings';
import '@/components/PlotlyPartialRender';

formulaeLanguage.initialize();
templateSetScriptLanguageLanguage.initialize();
loadFonts();

library.add(fas);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('font-awesome-icon', FontAwesomeIcon);

// API
const msalConfig = {
  clientId: config.MSAL.CLIENTID,
  authority: config.MSAL.AUTHORITY,
  redirectUri: config.MSAL.REDIRECTURI,
  userScope: config.MSAL.USER_SCOPE,
  apiScope: config.MSAL.API_SCOPE,
  passwordReset: config.MSAL.PASSWORDRESET,
};

const insightConfig = {
  connectionString: config.APPINSIGHTS.connectionString,
  loggingLevelConsole: 2,
  enableCorsCorrelation: true,
  enableRequestHeaderTracking: true,
  enableResponseHeaderTracking: true,
  enableAutoRouteTracking: true,
  correlationHeaderExcludedDomains: [],
};

Vue.use(router);
Vue.use(store);
Vue.use(vuetify);
Vue.use(msalPlugin, msalConfig);
if (insightConfig.connectionString.length > 0) {
  Vue.use(appinsights, insightConfig);
}
Vue.use(Vue2MultipleVModels);
// Note: The V is important
Vue.component('VDateTimePickerForm', () => import('@/components/form/DateTimePickerForm.vue'));

Vue.prototype.$clone = function $clone(value) {
  if (typeof value !== 'object') {
    return value;
  }

  const obj = value;
  let newObj;

  if (obj instanceof Array) {
    newObj = [];
  } else if (typeof obj === 'object') {
    newObj = {};
  }

  for (const k in obj) {
    const v = obj[k];

    if (obj instanceof Array) {
      newObj.push(this.$clone(v));
    } else
    if (typeof obj === 'object') {
      newObj[k] = this.$clone(v);
    } else {
      // should not arrive at this point
    }
  }

  return newObj;
};

Vue.mixin({
  computed: {
    $ShowWindServer() {
      if (config && config.WINDSERVER && config.WINDSERVER.show === false) {
        return false;
      }
      return true;
    },
  },
  methods: {
    async $PleaseWaitOneAsync(task) {
      await this.$PleaseWaitAsync([task]);
    },
    async $PleaseWaitAsync(tasks) {
      this.$store.commit('app/pleaseWait', true);

      await Promise.all(tasks)
        .then(() => {
          this.$store.commit('app/pleaseWait', false);
        })
        .catch(() => {
          this.$store.commit('app/pleaseWait', false);
        });
    },
  },
});

const app = new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
  vuetify,
});
app.$mount('#app');

require('./styles/main.scss');
