import AlertRoutes from '@/alert/version3/alert.routes';
import AnalyticsRoutes from '@/analytics/analytics.routes';
import DashboardRoutes from '@/dashboard/dashboard.routes';
import DevicesRoutes from '@/devices/devices.routes';
import VirtualInstrumentRoutes from '@/virtualinstrument/virtualinstrument.routes';
import QualityCheckRoutes from '@/qualityCheck/qualityCheck.routes';
import EquipmentRoutes from '@/equipment/equipment.routes';
import SettingRoutes from '@/settings/settings.routes';
import CustomersRoutes from '@/customers/customers.routes';
import WorkerRoutes from '@/worker/worker.routes';

import SettingsRoutes from '../routes/settings';
import WindServerRoutes from '../routes/windserver';
import DemoRoutes from '../routes/demo';

import LoggedIn from '../../views/layouts/LoggedIn.vue';

export default [{
  path: '/',
  redirect: '/dashboard',
  component: LoggedIn,
  children: [
    ...DashboardRoutes,
    ...DevicesRoutes,
    ...AnalyticsRoutes,
    ...SettingsRoutes,
    ...VirtualInstrumentRoutes,
    ...AlertRoutes,
    ...WindServerRoutes,
    ...QualityCheckRoutes,
    ...EquipmentRoutes,
    ...SettingRoutes,
    ...WorkerRoutes,
    ...CustomersRoutes,
    ...DemoRoutes,
  ],
}];
