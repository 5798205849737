<template>
  <div v-if="placeholder">
    <div v-if="placeholder.Data.JsonData.SelectedItem" class="ma-0 pa-0 text-caption" style="color: lightgray;">
      {{ placeholder.Data.JsonData.Name }}
    </div>
    <v-menu v-model="show" :close-on-content-click="false" offset-y>
      <template #activator="{ on: onMenu, attrs: attrsMenu }">
        <v-tooltip bottom :disabled="show">
          <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
            <v-row
              class="mx-1 mb-2 px-0 dropdown-border align-self-end"
              :class="placeholder.Data.JsonData.SelectedItem ? 'dropdown-selected font-weight-bold' : 'dropdown-unselected font-weight-regular'"
              v-bind="{ ...attrsMenu, ...attrsTooltip }"
              v-on="{ ...onMenu, ...onTooltip }"
            >
              <v-col cols="10" class="pb-0 px-0">
                <div class="ellipsis">
                  {{ placeholderLabel }}
                </div>
              </v-col>
              <v-col cols="2" class="pb-0 px-0">
                <v-icon>mdi-menu-down</v-icon>
              </v-col>
            </v-row>
          </template>
          <div style="white-space: pre;">
            {{ tooltipDropdown }}
            <!-- <span v-if="selectedItem">
              {{ '\n\n' + $t('Selected') + ':\n' }}
              {{ $t(
                'Dashboard.Placeholder.Instrument'
                ) + ': ' + selectedItem.InstrumentName + '\n' }}
              {{ $t('Dashboard.Placeholder.' + selectedItem.T === 'S' ? 'Sensor' : 'Vector') }}
              {{ ': ' + (selectedItem.DisplayName || selectedItem.Name) }}
            </span> -->
          </div>
        </v-tooltip>
      </template>
      <v-list dense class="dropdown-list">
        <v-list-item-group v-model="selected">
          <v-list-item v-if="placeholder.Items && placeholder.Items.length !== 0">
            <v-checkbox
              v-model="selectAll"
              color="primary"
              @change="toggleSelectAll"
              label="Select All"
            />
          </v-list-item>
          <v-list-item v-if="!placeholder.Items || placeholder.Items.length === 0">
            <v-list-item-subtitle>{{ $t('NoData') }}</v-list-item-subtitle>
          </v-list-item>
          <v-tooltip v-for="(sensor, index) in placeholder.Items" :key="index" bottom :disabled="!sensor.T">
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-checkbox
                  v-model="sensor.selected"
                  :id="sensor.Id+''"
                  color="primary"
                  @click="toggleItemSelection(sensor)"
                />
                <v-list-item-title>
                  {{ dropdownItemLabel(sensor) }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <div style="white-space: pre;">
              {{ `${$t('Dashboard.Placeholder.Instrument')}: ${sensor.InstrumentName}\n` }}
              {{ $t(`Dashboard.Placeholder.${sensor.T === 'S' ? 'Sensor' : 'Vector'}`) + ': ' }}
              {{ sensor.DisplayName || sensor.Name }}
            </div>
          </v-tooltip>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Vue from 'vue';

export default {
  name: 'PlaceholderDropDown',
  props: {
    placeholder: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data: () => ({
    show: false,
    selectedIndices: [],
    placeholderLabel: 'Loading',
    selectAll: false,
    selected: null,
  }),
  computed: {
    ...mapState('dashboardPlaceholders', ['selectedPlaceholderSensors']),

    selectedItems() {
      return this.selectedIndices.map((index) => this.placeholder.Items[index]);
    },

    selectedItem() {
      const selected = this.selectedPlaceholderSensors
        .filter((x) => x.placeholderId === this.placeholder.Data.PlaceholderId);
      return selected.length > 0
        ? selected[0].selectedItem
        : this.placeholder.Data.JsonData.SelectedItem;
    },
    tooltipDropdown() {
      const jsonData = this.placeholder.Data.JsonData;
      return `${jsonData.Name}`
        + `\n  Alias: ${jsonData.Alias}`
        + `\n  ${this.$t('Dashboard.Placeholder.RuleType')}: ${jsonData.RuleType}`
        + `\n  ${this.$t('Dashboard.Placeholder.ValueType')}: ${jsonData.ValueType}`
        + `\n  ${this.$t('Dashboard.Placeholder.Value')}: ${jsonData.Value}`;
    },
  },
  watch: {
    'placeholder.Items': {
      handler(newVal) {
        if (newVal) {
          // Reset selectedIndices to empty array
          this.selectedIndices = [];

          // Recheck selectedIndices based on newVal
          newVal.forEach((sensor, index) => {
            if (sensor.selected) {
              this.selectedIndices.push(index);
            }
          });

          if (this.selectedIndices.length === this.placeholder.Items.length) {
            this.selectAll = true;
          } else {
            this.selectAll = false;
          }
        }
      },
      deep: true,
    },
    selectedIndices: 'updateLabel',
  },
  created() {
    // Initialize selectedIndices with indices of items where sensor.selected is true
    this.init();
  },
  methods: {
    init() {
      // Initialize selectedIndices with indices of items where sensor.selected is true
      this.placeholder.Items.forEach((sensor, index) => {
        if (sensor.selected) {
          this.selectedIndices.push(index);
        }
      });
    },
    updateLabel() {
      const numSelected = this.selectedIndices.length;
      if (numSelected === 0) {
        this.placeholderLabel = this.placeholder.Data.JsonData.Name; // No items selected, use the default label
      } else if (numSelected === 1) {
        if (this.placeholder.Data.JsonData.DropdownType === 'Instrument attributes'
        || this.placeholder.Data.JsonData.DropdownType === 'Sensor attributes') {
          this.placeholderLabel = this.placeholder.Items[this.selectedIndices[0]].Value; // Use the value of the selected item
        } else {
          this.placeholderLabel = this.placeholder.Items[this.selectedIndices[0]].Name; // Use the name of the selected item
        }
      } else if (numSelected === this.placeholder.Items.length) {
        this.placeholderLabel = 'All'; // All items selected, display 'All'
      } else {
        this.placeholderLabel = `${numSelected} Selected`; // Multiple items selected, display the count
      }
    },

    dropdownItemLabel(sensor) {
      // NEW FORMAT
      if (this.placeholder.Data.JsonData.DropdownType) {
        if (this.placeholder.Data.JsonData.DropdownType === 'Instrument') {
          return sensor.InstrumentName;
        // eslint-disable-next-line no-else-return
        } else if (this.placeholder.Data.JsonData.DropdownType === 'Sensor or Vector') {
          return sensor.DisplayName || sensor.Name;
        } else if (this.placeholder.Data.JsonData.DropdownType === 'Instrument attributes') {
          return sensor.Value;
        } else {
          // Attribute Sensor
          return sensor.Value;
        }
      } else {
        let name;
        if (sensor.DisplayName || sensor.Name) {
          name = sensor.DisplayName || sensor.Name;
        }
        const instrument = sensor.InstrumentName ? `${sensor.InstrumentName}.` : '';
        return instrument.concat(name);
      }
    },

    toggleSelectAll() {
      if (this.selectAll) {
        // Select all items
        this.selectedIndices = this.placeholder.Items.map((item, index) => index);
      } else {
        // Deselect all items
        this.selectedIndices = [];
      }

      // Update the selected property of individual items
      this.updateIndividualCheckboxes();
    },

    updateIndividualCheckboxes() {
      // Update the selected property of individual items based on this.selectedIndices
      this.placeholder.Items.forEach((item, index) => {
        Vue.set(item, 'selected', this.selectedIndices.includes(index));
      });

      this.updateSelectedItems();
    },

    toggleItemSelection(sensor) {
      if (sensor.Id === 'More') {
        this.$emit('more');
      } else {
        const sensorId = sensor.Id;
        const index = this.placeholder.Items.findIndex((item) => item.Id === sensorId);

        if (index !== -1) {
          if (this.selectedIndices.includes(index)) {
            // If the index is already in the array, remove it
            this.selectedIndices = this.selectedIndices.filter((i) => i !== index);
          } else {
            // If the index is not in the array, add it
            this.selectedIndices.push(index);
          }
        }

        if (this.selectedIndices.length !== this.placeholder.Items.length) {
          this.selectAll = false;
        } else {
          this.selectAll = true;
        }

        // Emit the 'change' event with the selected items as an array
        this.updateSelectedItems();
      }
    },
    updateSelectedItems() {
      const selectedItems = this.placeholder.Items.filter((sensor) => sensor.selected);
      this.$emit('change', selectedItems); // Emit the selected items as an array
    },
    isSameAsSelected(item) {
      const selectedItem = this.placeholder.Data.JsonData.SelectedItem;
      return selectedItem
        && item.T === selectedItem.T
        && item.Id === selectedItem.Id
        && item.Name === selectedItem.Name
        && item.InstrumentId === selectedItem.InstrumentId;
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-selected {
  color: white;
  border-bottom-color: white;
}
.dropdown-unselected {
  color: lightgray;
  border-bottom-color: lightgray;
}

.dropdown-border {
  border-bottom-style: solid;
  border-bottom-width: thin;
}

.dropdown-list {
  max-height: 400px;
  overflow: auto;
}
</style>
