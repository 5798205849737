<template>
  <div style="width: 723px">
    <v-card>
      <v-card-text>
        <p class="font-weight-bold">
          {{ getQaCodeDesc(flag.QaCodeId) }}
        </p>
        <p>
          {{ flag.Details }}
        </p>

        <div class="row">
          <v-expansion-panels
            focusable
            class="col col-6"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>{{ flag.QaFlagSetSensors.length }} {{ $t('WindServer.Sites.DataTrace.Sensors') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(sensor, i) in flag.QaFlagSetSensors"
                  :key="i"
                >
                  {{ sensor.Sensor.Name }}
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels
            focusable
            class="col col-6"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>{{ flag.QaFlagSetPeriods.length }} {{ $t('WindServer.Sites.DataTrace.Periods') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(period, i) in flag.QaFlagSetPeriods"
                  :key="i"
                >
                  {{ formatDateTime(period.FlagStart) }} - {{ formatDateTime(period.FlagEnd) }}
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <p class="mt-4">
          {{ $t('WindServer.Sites.DataTrace.FlagSetId') }}
          {{ flag.QaFlagSetId }}
          {{ $t('WindServer.Sites.DataTrace.CreatedBy') }}
          {{ flag.CreatedByUser.DisplayName }}
          {{ $t('WindServer.Sites.DataTrace.On') }}
          {{ formatDateTime(flag.CreationDate) }}
          {{ $t('WindServer.Sites.DataTrace.LastModifiedBy') }}
          {{ flag.LastModifiedByUser.DisplayName }}
          {{ $t('WindServer.Sites.DataTrace.On') }}
          {{ formatDateTime(flag.LastModifiedDate) }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import datehandling from '@/components/datehandling';
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  data() {
    return {
      flag: {},
    };
  },
  beforeMount() {
    this.flag = this.params.data;
  },
  methods: {
    formatDateTime(date) {
      return datehandling.formatForDisplay(date);
    },
    getQaCodeDesc(qaCodeId) {
      return i18n.t('WindServer.Sites.DataTrace.QaCodes.'.concat(qaCodeId));
    },
  },
};
</script>
