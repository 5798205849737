<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <v-select
              v-model="formData.Type"
              :label="$t('WindServer.Sites.SiteConfigurations.AnchorType')"
              :items="equipmentModel.Anchors"
              :rules="[anchorRules]"
              :return-object="true"
              :readonly="editEquipment && !editEquipment.IsNew"
              item-text="TypeName"
              item-value="AnchorTypeId"
              @change="onChange"
            />
            <v-text-field
              v-model="formData.Quantity"
              :label="$t('WindServer.Sites.SiteConfigurations.Quantity')"
              :rules="[v => !!v || $t('Validation.Required')]"
              type="number"
              @change="onChange"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    ...mapState('site/siteConfigurations', ['equipmentModel', 'editEquipment', 'siteConfiguration']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$store.dispatch('site/siteConfigurations/editEquipment', null);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    setFormData() {
      if (this.editEquipment) {
        this.formData = JSON.parse(JSON.stringify(this.editEquipment));
      } else {
        this.formData = {};
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.editEquipment) {
          await this.$store.dispatch('site/siteConfigurations/editAnchor', this.formData);
        } else {
          await this.$store.dispatch('site/siteConfigurations/addAnchor', this.formData);
        }
        this.close();
      }
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/siteConfigurations/editEquipment', null);
      this.$refs.form.reset();
    },
    onChange() {
      this.formData.IsDirty = true;
    },
    anchorRules(values) {
      if (!values) {
        return this.$t('Validation.Required');
      }
      const idx = this.siteConfiguration.Anchors.findIndex((b) => b.Type === values.AnchorTypeId);
      if (idx >= 0) {
        return this.$t('WindServer.Sites.SiteConfigurations.AnchorTypeExist');
      }
      return true;
    },
  },
};
</script>
