import api from '@/api/importal';
import i18n from '@/plugins/i18n';

const state = {
  siteConfiguration: null,
  siteConfigurations: [],
  configurationDetails: null,
  saveSucceeded: true,
  isDirty: false,
  siteId: null,
  equipmentModel: null,
  mountType: null,
  editEquipment: null,
  deleteEquipment: null,
  defaultMountType: [
    {
      MountId: 'Tower',
      MountName: i18n.t('WindServer.Sites.SiteConfigurations.MountType.Tower'),
    },
    {
      MountId: 'Panel',
      MountName: i18n.t('WindServer.Sites.SiteConfigurations.MountType.Panel'),
    },
  ],
};

const actions = {
  async save({ commit, state }) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('SaveSiteConfiguration', state.siteConfiguration)
      .then(() => {
        commit('app/pleaseWait', false, { root: true });
        commit('saveSucceeded', true);
      })
      .catch(() => {
        commit('saveSucceeded', false);
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`SiteConfigurationList?siteId=${state.siteId}`);
    commit('siteConfigurations', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async getDetails({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('configurationDetails', null);
    const response = await api.get(`SiteConfigurationDetails?siteId=${state.siteId}&startDate=${payload}`);
    commit('configurationDetails', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async getViewModel({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('siteConfiguration', null);
    await api.get(`SiteConfigurationViewModel?siteId=${state.siteId}&startDate=${payload.StartDate}`)
      .then((response) => {
        commit('siteConfiguration', response.data);
        state.siteConfiguration.TenantId = payload.TenantId;
        state.mountType = [...state.defaultMountType];
        state.siteConfiguration.Booms.forEach((boom) => {
          commit('mountType', boom);
        });
        commit('isDirty', false);
        commit('app/pleaseWait', false, { root: true });
      })
      .catch(() => {
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async getEquipmentModel({ commit, dispatch }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('SiteConfigurationEquipmentModel');
    commit('equipmentModel', response.data);
    await dispatch('equipment/equipments/getSensorType', null, { root: true });
    await dispatch('equipment/equipments/getCellularAccountProviderList', null, { root: true });
    await dispatch('equipment/equipments/getNetworkType', null, { root: true });
    await dispatch('equipment/equipments/getMobileEquipmentIdType', null, { root: true });
    commit('app/pleaseWait', false, { root: true });
  },

  editEquipment({ commit }, payload) {
    commit('editEquipment', payload);
  },

  delete({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const deleteEq = payload;
    let sensors = null;
    let e = null;
    let idx = null;
    commit('isDirty', true);
    state.deleteEquipment.forEach((equipment) => {
      switch (equipment.EquipmentType) {
        case 0: // Other
          idx = state.siteConfiguration.Other.findIndex((b) => b.Id === equipment.Id);
          e = state.siteConfiguration.Other[idx];
          state.siteConfiguration.Other.splice(idx, 1);
          break;
        case 1: // Booms
          idx = state.siteConfiguration.Booms.findIndex((b) => b.Id === equipment.Id);
          e = state.siteConfiguration.Booms[idx];
          state.siteConfiguration.Booms.splice(idx, 1);
          sensors = state.siteConfiguration.Sensors.filter((s) => s.MountId === e.Id);
          sensors.forEach((s) => {
            // eslint-disable-next-line no-param-reassign
            s.MountId = null;
            // eslint-disable-next-line no-param-reassign
            s.Height = null;
            // eslint-disable-next-line no-param-reassign
            s.Heading = null;
            const i = state.siteConfiguration.Sensors.findIndex((x) => x.Id === s.Id);
            state.siteConfiguration.Sensors.splice(i, 1, s);
          });

          break;
        case 2: // Modems
          idx = state.siteConfiguration.Modems.findIndex((b) => b.Id === equipment.Id);
          e = state.siteConfiguration.Modems[idx];
          state.siteConfiguration.Modems.splice(idx, 1);
          break;
        case 4: // Loggers
          idx = state.siteConfiguration.Loggers.findIndex((b) => b.Id === equipment.Id);
          e = state.siteConfiguration.Loggers[idx];
          state.siteConfiguration.Loggers.splice(idx, 1);
          break;
        case 5: // Sensors
          state.siteConfiguration.Loggers.forEach((l) => {
            l.Channels.forEach((item) => {
              const log = l;
              if (item.Sensor && item.Sensor.Id === equipment.Id) {
                log.IsDirty = !log.IsNew;
                const channel = item;
                channel.Sensor = null;
                channel.PeNumber = null;
                channel.SensorMountId = null;
                channel.IsDirty = !channel.IsNew;
                const i = log.Channels.findIndex(
                  (y) => y.ChannelCalibrationId === item.ChannelCalibrationId,
                );
                log.Channels.splice(i, 1, channel);
              }
            });
          });
          idx = state.siteConfiguration.Sensors.findIndex((b) => b.Id === equipment.Id);
          e = state.siteConfiguration.Sensors[idx];
          state.siteConfiguration.Sensors.splice(idx, 1);
          break;
        case 6: // Tower
          idx = state.siteConfiguration.Tower.findIndex((b) => b.Id === equipment.Id);
          e = state.siteConfiguration.Tower[idx];
          state.siteConfiguration.Tower.splice(idx, 1);
          break;
        case 7: // Anchors
          idx = state.siteConfiguration.Anchors.findIndex((b) => b.Id === equipment.Id);
          e = state.siteConfiguration.Anchors[idx];
          e.IsNew = true;
          commit('removedAnchors', e);
          state.siteConfiguration.Anchors.splice(idx, 1);
          break;
        default:
          break;
      }
      if (!e.IsNew) {
        deleteEq.equipment = equipment;
        commit('removedEquipment', deleteEq);
      }
    });
    // rebuild mountType
    state.mountType = [...state.defaultMountType];
    state.siteConfiguration.Booms.forEach((boom) => {
      commit('mountType', boom);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  deleteEquipment({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('deleteEquipment', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async addTower({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('tower', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async editTower({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('editTower', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async addBoom({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('boom', payload);
    state.mountType = [...state.defaultMountType];
    state.siteConfiguration.Booms.forEach((boom) => {
      commit('mountType', boom);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async editBoom({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('editBoom', payload);
    // regenerate mount type
    state.mountType = [...state.defaultMountType];
    state.siteConfiguration.Booms.forEach((boom) => {
      commit('mountType', boom);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async addSensor({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('sensor', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async editSensor({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('editSensor', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async addModem({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('modem', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async editModem({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('editModem', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async addLogger({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('logger', payload, true);
    commit('app/pleaseWait', false, { root: true });
  },

  async editLogger({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('editLogger', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async addOther({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('other', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async editOther({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('editOther', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async addAnchor({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('anchor', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async editAnchor({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('editAnchor', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async setSiteId({ commit }, payload) {
    commit('siteId', payload);
  },

  async updateConfigurationDate({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('SiteConfigurationUpdateConfigurationDate', payload)
      .then(() => {
        commit('app/pleaseWait', false, { root: true });
        dispatch('list');
      })
      .catch(() => {
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async setSiteConfiguration({ commit }, payload) {
    commit('siteConfiguration', payload);
  },

  setDirty({ commit }, payload) {
    commit('isDirty', payload);
  },
};

const mutations = {

  siteConfigurations(state, payload) {
    state.siteConfigurations = payload;
  },

  siteConfiguration(state, payload) {
    state.siteConfiguration = payload;
  },

  configurationDetails(state, payload) {
    state.configurationDetails = payload;
  },

  equipmentModel(state, payload) {
    state.equipmentModel = payload;
  },

  editTower(state, payload) {
    state.siteConfiguration.Tower.splice(0, 1, {
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId ? payload.ModelId.ModelId : payload.ModelId,
      ModelName: payload.ModelId.ModelName ? payload.ModelId.ModelName : payload.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName ? payload.ModelId.ManufacturerName : payload.ManufacturerName,
      GinPoleOrientation: Number(payload.GinPoleOrientation),
      InstallerName: payload.InstallerName,
      InstallerNote: payload.InstallerNote,
      LightningProtectionDetails: payload.LightningProtectionDetails,
      IsPainted: payload.IsPainted ? payload.IsPainted : false,
      IsNew: payload.IsNew,
      IsDirty: payload.IsNew ? false : payload.IsDirty,
    });
    state.isDirty = state.isDirty || payload.IsNew || payload.IsDirty;
  },

  tower(state, payload) {
    state.siteConfiguration.Tower.push({
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId,
      ModelName: payload.ModelId.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName,
      GinPoleOrientation: Number(payload.GinPoleOrientation),
      InstallerName: payload.InstallerName,
      InstallerNote: payload.InstallerNote,
      LightningProtectionDetails: payload.LightningProtectionDetails,
      IsPainted: payload.IsPainted ? payload.IsPainted : false,
      IsNew: true,
    });
    state.isDirty = true;
  },

  editBoom(state, payload) {
    const idx = state.siteConfiguration.Booms.findIndex((b) => b.Id === payload.Id);
    state.siteConfiguration.Booms.splice(idx, 1, {
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId ? payload.ModelId.ModelId : payload.ModelId,
      ModelName: payload.ModelId.ModelName ? payload.ModelId.ModelName : payload.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName ? payload.ModelId.ManufacturerName : payload.ManufacturerName,
      Distance: payload.ModelId.BoomLength ? payload.ModelId.BoomLength : payload.Distance,
      Height: Number(payload.Height),
      Heading: payload.Heading ? Number(payload.Heading) : null,
      Orientation: payload.Orientation,
      IsNew: payload.IsNew,
      IsDirty: payload.IsNew ? false : payload.IsDirty,
    });
    state.isDirty = state.isDirty || payload.IsNew || payload.IsDirty;

    const boom = state.siteConfiguration.Booms[idx];
    // find sensor attach to it to change it
    const sensors = state.siteConfiguration.Sensors.filter((s) => s.MountId === payload.Id);
    sensors.forEach((s) => {
      // eslint-disable-next-line no-param-reassign
      s.MountId = boom.Id;
      // eslint-disable-next-line no-param-reassign
      s.Height = boom.Height;
      // eslint-disable-next-line no-param-reassign
      s.Heading = boom.Heading;
      const i = state.siteConfiguration.Sensors.findIndex((x) => x.Id === s.Id);
      state.siteConfiguration.Sensors.splice(i, 1, s);
    });
  },

  boom(state, payload) {
    state.siteConfiguration.Booms.push({
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId,
      ModelName: payload.ModelId.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName,
      Distance: payload.ModelId.BoomLength,
      Height: Number(payload.Height),
      Heading: payload.Heading ? Number(payload.Heading) : null,
      Orientation: payload.Orientation,
      IsNew: true,
    });
    state.isDirty = true;
  },

  editSensor(state, payload) {
    const idx = state.siteConfiguration.Sensors.findIndex((b) => b.Id === payload.Id);
    const s = {
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId ? payload.ModelId.ModelId : payload.ModelId,
      ModelName: payload.ModelId.ModelName ? payload.ModelId.ModelName : payload.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName ? payload.ModelId.ManufacturerName : payload.ManufacturerName,
      MountId: payload.MountId.MountId ? payload.MountId.MountId.toString() : payload.MountId.toString(),
      Type: payload.Type,
      Height: payload.Height ? Number(payload.Height) : null,
      Heading: payload.Heading ? Number(payload.Heading) : null,
      Distance: payload.Distance ? Number(payload.Distance) : null,
      Deadband: payload.Deadband ? Number(payload.Deadband) : null,
      IsNew: payload.IsNew,
      IsDirty: payload.IsNew ? false : payload.IsDirty,
    };
    state.siteConfiguration.Sensors.splice(idx, 1, s);
    // refresh logger channel
    state.siteConfiguration.Loggers.forEach((l) => {
      l.Channels.forEach((item) => {
        const log = l;
        if (item.Sensor && item.Sensor.Id === payload.Id) {
          const channel = item;
          channel.Sensor = s;
          const i = log.Channels.findIndex(
            (y) => y.ChannelCalibrationId === item.ChannelCalibrationId,
          );
          log.IsDirty = !log.IsNew;
          log.Channels.splice(i, 1, channel);
        }
      });
    });
    state.isDirty = state.isDirty || payload.IsNew || payload.IsDirty;
  },

  sensor(state, payload) {
    state.siteConfiguration.Sensors.push({
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId,
      ModelName: payload.ModelId.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName,
      MountId: payload.MountId.MountId.toString(),
      Type: payload.Type,
      Height: payload.Height ? Number(payload.Height) : null,
      Heading: payload.Heading ? Number(payload.Heading) : null,
      Distance: payload.Distance ? Number(payload.Distance) : null,
      Deadband: payload.Deadband ? Number(payload.Deadband) : null,
      IsNew: true,
    });
    state.isDirty = true;
  },

  editModem(state, payload) {
    const idx = state.siteConfiguration.Modems.findIndex((b) => b.Id === payload.Id);
    state.siteConfiguration.Modems.splice(idx, 1, {
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId ? payload.ModelId.ModelId : payload.ModelId,
      ModelName: payload.ModelId.ModelName ? payload.ModelId.ModelName : payload.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName ? payload.ModelId.ManufacturerName : payload.ManufacturerName,
      IdType: payload.IdType,
      Identifier: payload.Identifier,
      ProviderId: payload.ProviderId && payload.ProviderId.CellularAccountProviderId ? payload.ProviderId.CellularAccountProviderId : payload.ProviderId,
      ProviderName: payload.ProviderId && payload.ProviderId.ProviderName ? payload.ProviderId.ProviderName : payload.ProviderName,
      Network: payload.Network,
      AccountIsManagedByWindServer: payload.AccountIsManagedByWindServer ? payload.AccountIsManagedByWindServer : false,
      Phone: payload.Phone,
      IpAddress: payload.IpAddress,
      Password: payload.Password,
      IsNew: payload.IsNew,
      IsDirty: payload.IsNew ? false : payload.IsDirty,
    });
    state.isDirty = state.isDirty || payload.IsNew || payload.IsDirty;
  },

  modem(state, payload) {
    state.siteConfiguration.Modems.push({
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId,
      ModelName: payload.ModelId.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName,
      IdType: payload.IdType,
      Identifier: payload.Identifier,
      ProviderId: payload.ProviderId ? payload.ProviderId.CellularAccountProviderId : null,
      ProviderName: payload.ProviderId ? payload.ProviderId.ProviderName : null,
      Network: payload.Network,
      AccountIsManagedByWindServer: payload.AccountIsManagedByWindServer ? payload.AccountIsManagedByWindServer : false,
      Phone: payload.Phone,
      IpAddress: payload.IpAddress,
      Password: payload.Password,
      IsNew: true,
    });
    state.isDirty = true;
  },

  editLogger(state, payload) {
    const idx = state.siteConfiguration.Loggers.findIndex((b) => b.Id === payload.Id);
    state.siteConfiguration.Loggers.splice(idx, 1, {
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId ? payload.ModelId.ModelId : payload.ModelId,
      ModelName: payload.ModelId.ModelName ? payload.ModelId.ModelName : payload.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName ? payload.ModelId.ManufacturerName : payload.ManufacturerName,
      IsNew: payload.IsNew,
      IsDirty: payload.IsNew ? false : payload.IsDirty,
    });
    state.isDirty = state.isDirty || payload.IsNew || payload.IsDirty;
  },

  logger(state, payload) {
    state.siteConfiguration.Loggers.push({
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId,
      ModelName: payload.ModelId.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName,
      IsNew: true,
      Channels: [],
      DeleteCalibrationIds: [],
      DataFileImportSettings: [],
    });
    state.isDirty = true;
  },

  editOther(state, payload) {
    const idx = state.siteConfiguration.Other.findIndex((b) => b.Id === payload.Id);
    state.siteConfiguration.Other.splice(idx, 1, {
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId ? payload.ModelId.ModelId : payload.ModelId,
      ModelName: payload.ModelId.ModelName ? payload.ModelId.ModelName : payload.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName ? payload.ModelId.ManufacturerName : payload.ManufacturerName,
      IsNew: payload.IsNew,
      IsDirty: payload.IsNew ? false : payload.IsDirty,
    });
    state.isDirty = state.isDirty || payload.IsNew || payload.IsDirty;
  },

  other(state, payload) {
    state.siteConfiguration.Other.push({
      PeNumber: payload.PeNumber,
      Id: payload.PeNumber ? payload.PeNumber.toString() : crypto.randomUUID(),
      SerialNumber: payload.SerialNumber,
      ModelId: payload.ModelId.ModelId,
      ModelName: payload.ModelId.ModelName,
      ManufacturerName: payload.ModelId.ManufacturerName,
      IsNew: true,
    });
    state.isDirty = true;
  },

  editAnchor(state, payload) {
    const idx = state.siteConfiguration.Anchors.findIndex((b) => b.Type === payload.Type);
    state.siteConfiguration.Anchors.splice(idx, 1, {
      Id: payload.Id,
      Type: payload.Type.AnchorTypeId ? payload.Type.AnchorTypeId : payload.Type,
      Name: payload.Type.TypeName ? payload.Type.TypeName : payload.Name,
      Quantity: Number(payload.Quantity),
      IsNew: payload.IsNew,
      IsDirty: payload.IsNew ? false : payload.IsDirty,
    });
    state.isDirty = state.isDirty || payload.IsNew || payload.IsDirty;
  },

  anchor(state, payload) {
    state.siteConfiguration.Anchors.push({
      Type: payload.Type.AnchorTypeId,
      Name: payload.Type.TypeName,
      Quantity: Number(payload.Quantity),
      IsNew: true,
    });
    state.isDirty = true;
  },

  removedEquipment(state, payload) {
    state.siteConfiguration.RemovedEquipment.push({
      Id: payload.equipment.Id,
      EquipmentType: payload.equipment.EquipmentType,
      StorageId: payload.StorageSite.SiteId,
      StorageName: payload.StorageSite.Name,
    });
  },

  removedAnchors(state, payload) {
    state.siteConfiguration.RemovedAnchors.push({
      Type: payload.Type,
      Name: payload.Name,
      Quantity: Number(payload.Quantity),
    });
  },

  siteId(state, payload) {
    state.siteId = payload;
  },

  saveSucceeded(state, payload) {
    state.saveSucceeded = payload;
  },

  isDirty(state, payload) {
    state.isDirty = state.isDirty || payload;
  },

  editEquipment(state, payload) {
    state.editEquipment = payload;
  },

  deleteEquipment(state, payload) {
    state.deleteEquipment = payload;
  },

  mountType(state, payload) {
    let mountName = i18n.t('WindServer.Sites.SiteConfigurations.MountType.Boom');
    if (payload.PeNumber) {
      mountName = mountName.concat(' ')
        .concat(i18n.t('WindServer.Sites.SiteConfigurations.PeNumber'))
        .concat(String(payload.PeNumber).padStart(5, '0'));
    }
    mountName = mountName.concat(' ')
      .concat(payload.Height)
      .concat('m ');
    if (payload.Heading) {
      mountName = mountName.concat(payload.Heading)
        .concat('°');
    } else {
      mountName = mountName.concat('VERT');
    }
    state.mountType.unshift({
      MountId: payload.PeNumber ? payload.PeNumber.toString() : payload.Id,
      MountName: mountName,
      Height: payload.Height,
      Heading: payload.Heading,
      Distance: payload.Distance,
      Orientation: payload.Orientation,
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
