<template>
  <div
    class="secondary"
    style="height: calc(100vh - 35px); width: 100%;"
  >
    <div
      class="row"
      style="padding-left:11px;"
    >
      <div
        class="col"
        style="max-width: 291px"
      >
        <div class="row">
          <p style="padding:25px;padding-left: 25px;margin-bottom:0px;">
            {{ $t('QualityCheck.QCSchedules.WeekOf') }} : <span
              id="lblWeekOf"
              style="padding-left: 41px;"
            >{{ weekOf }}
            </span>
          </p>
        </div>
        <div class="row">
          <v-date-picker
            id="calendar"
            v-model="dates"
            range
            show-adjacent-months
            color="green lighten-1"
            :locale="locale"
          />
        </div>
      </div>
      <div class="col">
        <v-tabs
          v-model="activeTab"
          fixed-tabs
          style="border-width:0px;"
        >
          <v-tab
            id="tabAssignations"
            key="assignations"
          >
            <slot>
              {{ $t('QualityCheck.QCSchedules.Assignments') }}
            </slot>
          </v-tab>
          <v-tab
            id="tabTenants"
            key="tenants"
          >
            <slot>
              {{ $t('QualityCheck.QCSchedules.Tenants') }}
            </slot>
          </v-tab>
          <v-tab-item
            key="assignations"
            style="height:Calc(100vh - 95px);"
          >
            <QCAssignationList />
          </v-tab-item>
          <v-tab-item
            key="tenants"
            style="height:Calc(100vh - 95px); "
          >
            <QCTenantList />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import QCAssignationList from './QCAssignationList.vue';
import QCTenantList from './QCTenantList.vue';

export default {
  components: {
    QCAssignationList,
    QCTenantList,
  },
  data() {
    return {
      dates: [],
      activeTab: '',
    };
  },
  computed: {
    ...mapState('qualityCheck/qcSchedules', ['weekOf']),
    ...mapState('app', ['locale']),
  },
  watch: {
    async dates(value) {
      // This watch is fired twice in a row: The first one when the user selects a date
      // and the second one when the date range is calculed and reassigned to dates property.
      // We need to avoid the second one.
      if (value.length === 1) {
        await this.setStartDateAsync(value);
        const endDate = this.setEndDate(value);
        this.dates = [this.weekOf, endDate];
      }
    },
  },
  async created() {
    await this.$store.dispatch('qualityCheck/qcSchedules/techniciansList');
    await this.$store.dispatch('qualityCheck/qcSchedules/analystsList');
    this.$router.push({ name: 'QualityCheck.QCSchedules.Assignations' });

    if (!this.weekOf) {
      await this.setStartDateAsync(new Date().toISOString().split('T')[0]);
    }

    this.dates = [this.weekOf];
  },
  mounted() {
  },
  methods: {
    getFirstWeekDate(d) {
      const dt = new Date(d);
      const day = dt.getDay();
      if (day === 6) return new Date(d);
      const diff = dt.getDate() - day - 1;
      return new Date(dt.setDate(diff));
    },
    getLastWeekDate(d) {
      const dt = this.getFirstWeekDate(d);
      const diff = dt.getDate() + 6;
      return new Date(dt.setDate(diff));
    },
    async setStartDateAsync(d) {
      const startDate = this.getFirstWeekDate(d);
      await this.$store.dispatch('qualityCheck/qcSchedules/setWeekOf', startDate.toISOString().slice(0, 19).substring(0, 10));
    },
    setEndDate(d) {
      return this.getLastWeekDate(d).toISOString().split('T')[0];
    },
  },
};
</script>

<style>
.v-picker__title {
  display: none
}
</style>
