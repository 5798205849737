/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsQCTasks extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.QCTask.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 70,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.QCTask.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'QualityCheck.QCTasks.Update',
                params: {
                  id: event.data.Id,
                },
              });
            },
          },
        },
        {
          field: 'ClientName',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.TenantName'),
          width: 235,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'SiteNumber',
          headerName: this.vue.$t('WindServer.Sites.Properties.Number'),
          width: 105,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'SiteName',
          headerName: this.vue.$t('WindServer.Sites.Properties.Name'),
          width: 230,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'TaskType',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.TaskType'),
          width: 245,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'AssignedTo',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.AssignedTo'),
          width: 245,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Recurrence',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.Recurrence'),
          width: 145,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'InspectionTemplate',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.InspectionTemplate'),
          width: 215,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'DueDate',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.DueDate'),
          width: 105,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatDate,
          cellClass: this.highlightDueDate,
        },
        {
          field: 'CompletedOn',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.CompletedOn'),
          width: 105,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatDate,
        },
        {
          field: 'DataStart',
          headerName: this.vue.$t('WindServer.Sites.Columns.DataStartDate'),
          width: 155,
          editable: false,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          field: 'DataEnd',
          headerName: this.vue.$t('WindServer.Sites.Columns.DataEndDate'),
          width: 155,
          editable: false,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          field: 'LastFullQCTaskId',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.LastFullQCId'),
          width: 145,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'LastFullQCCompletionDate',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.LastFullQCDate'),
          width: 145,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'CreatedOn',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.CreatedOn'),
          width: 145,
          editable: false,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          field: 'CreatedBy',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.CreatedBy'),
          width: 245,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'LastModifiedOn',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.LastModifiedOn'),
          width: 145,
          editable: false,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          field: 'LastModifiedBy',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.LastModifiedBy'),
          width: 245,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Id',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.Id'),
          width: 85,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'SiteId',
          headerName: this.vue.$t('QualityCheck.QCTasks.Tasks.SiteId'),
          width: 85,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 50,
          resizable: true,
          hide: !this.vue.$auth.roles.includes('Web.QCTask.Delete'),
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            show: (event) => event.data.Removable,
            confirm: (event) => (this.vue.$t('QualityCheck.QCTasks.Tasks.DeleteName', { Id: event.data.Id })),
            clicked: async (event) => {
              await this.vue.$store.dispatch('qualityCheck/qcTasks/remove', { id: event.data.Id });
            },
          },
        },
      ]);
    });
  }

  highlightDueDate(params) {
    if (params.data.IsDue) {
      return 'redBackgroundCell';
    }
    return '';
  }

  formatDate(params) {
    switch (params.colDef.field) {
      case 'DueDate':
        if (params.data.DueDate != null) {
          return params.data.DueDate.toISOString().split('T')[0];
        }
        return '';

      case 'CompletedOn':
        if (params.data.CompletedOn != null) {
          return params.data.CompletedOn.toISOString().split('T')[0];
        }
        return '';

      default:
        return '';
    }
  }
}
