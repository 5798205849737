<template>
  <div>
    <v-card-subtitle
      v-if="showMissingDataTimeStep"
      class="text-subtitle-2"
    >
      {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.ChooseTimeOfDataMissing') }}
    </v-card-subtitle>
    <v-card-subtitle
      v-if="showScheduledTimeStep"
      class="text-subtitle-2"
    >
      {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.ChooseDelayBeforeTheAlertIsSent') }}
    </v-card-subtitle>
    <v-card-text
      v-if="showMissingDataTimeStep || showScheduledTimeStep"
    >
      <v-text-field
        v-model="localDelayValue"
        :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.Value')"
        type="number"
        dense
      />
      &nbsp;
      &nbsp;
      <v-select
        v-model="localDelayScale"
        :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.TimeScale')"
        :items="valueDelayInHoursScale"
        item-text="Name"
        item-value="Scale"
        dense
        return-object
      />
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    delayScale: { type: Object, default: () => null },
    delayValue: { type: String, default: () => null },
    valueDelayInHoursScale: { type: Array, default: () => null },
    showMissingDataTimeStep: { type: Boolean, default: () => false },
    showScheduledTimeStep: { type: Boolean, default: () => false },
  },
  data() {
    return {
      localDelayScale: this.delayScale,
      localDelayValue: this.delayValue,
      localValueDelayInHoursScale: this.valueDelayInHoursScale,
    };
  },
  watch: {
    localDelayScale(newValue) {
      this.$emit('update:delayScale', newValue);
    },
    localDelayValue(newValue) {
      this.$emit('update:delayValue', newValue);
    },
    localValueDelayInHoursScale(newValue) {
      this.$emit('update:valueDelayInHoursScale', newValue);
    },
  },
};
</script>

<style scoped>
</style>
