<template>
  <div>
    <v-card-title>
      {{ $t('WindServer.Sites.DataTrace.SelectedSensors') }}
    </v-card-title>
    <v-card-text style="height: 200px;">
      <ServerSideDataGrid
        ref="gridSensors"
        :grid-source="sources.Sensors"
        :row-data-init="sensors"
        :pagination="false"
        enable-selection="multiple"
        @grid-ready="onGridReady"
        @selection-changed="selectionChanged"
      />
    </v-card-text>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import SensorsList from '../gridsources/gsSensors';

export default {
  components: {
    ServerSideDataGrid,
  },
  props: {
  },
  data() {
    return {
      sources: {
        Sensors: null,
      },
      qaSensors: null,
    };
  },
  computed: {
    ...mapState('site/dataTrace', ['sensors']),
  },
  created() {
    this.sources.Sensors = new SensorsList(this);
  },
  methods: {
    setGridSelectedSensors() {
      if (this.qaSensors && this.$refs.gridSensors.gridApi) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.$refs.gridSensors.gridApi.getDisplayedRowCount(); i++) {
          const idx = this.qaSensors.findIndex((s) => s.SensorId
            === this.$refs.gridSensors.gridApi.getRowNode(i).data.SensorId);
          if (idx >= 0) {
            this.$refs.gridSensors.gridApi.getRowNode(i).selectThisNode(true);
          } else {
            this.$refs.gridSensors.gridApi.getRowNode(i).selectThisNode(false);
          }
        }
        this.selectionChanged();
      }
    },
    async selectSensors(params) {
      this.qaSensors = params;
      this.setGridSelectedSensors();
    },
    async onGridReady() {
      this.setGridSelectedSensors();
    },
    selectionChanged() {
      this.$emit('selection-changed', this.$refs.gridSensors.gridApi.getSelectedRows().length);
    },
    async setSelectedSensors() {
      let selectedRows = [];
      if (this.$refs.gridSensors.gridApi.getSelectedRows().length > 0) {
        selectedRows = this.$refs.gridSensors.gridApi.getSelectedRows();
      }
      await this.$store.dispatch('site/dataTrace/updateSelectedSensor', selectedRows);
    },
  },
};
</script>
