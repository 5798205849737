<template>
  <v-layout
    justify-end
  >
    <dashboard-share
      v-model="shareDialog"
    />
    <v-item-group class="menu-group">
      <v-tooltip
        v-if="!editModeFlag"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :disabled="!hasDashboards || dwellFlag"
            icon
            small
            class="d-none d-md-flex"
            v-on="on"
            @click="refreshDashboard"
          >
            <font-awesome-icon
              link
              class="fa-2x"
              :icon="'sync-alt'"
            />
          </v-btn>
        </template>
        <span>{{ $t('Dashboard.Menu.RefreshDashboard') }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="!editModeFlag"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-img
            v-bind="attrs"
            :disabled="!hasDashboards || dwellFlag"
            class="cursor-pointer mx-2 mt-1 d-none d-md-flex"
            lazy-src="@/assets/edit-sharing.png"
            max-height="36"
            max-width="44"
            src="@/assets/edit-sharing.png"
            v-on="on"
            @click="shareDashboard"
          />
        </template>
        <span>{{ $t('Dashboard.Menu.ShareDashboard') }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="editModeFlag"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="primary"
            icon
            small
            outlined
            class="d-none d-md-flex"
            v-on="on"
            @click="toggleEditMode"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Dashboard.Menu.ExitEditMode') }}</span>
      </v-tooltip>
      <v-tooltip
        v-else-if="!editModeFlag && $auth.roles.includes('Web.Dashboard.Modify')"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            small
            outlined
            class="d-none d-md-flex"
            v-on="on"
            @click="toggleEditMode"
          >
            <v-icon size="18">
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('Dashboard.Menu.EnterEditMode') }}</span>
      </v-tooltip>
      <v-menu>
        <template #activator="{ on, attrs }">
          <v-btn
            id="widget-menu-toggle"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <font-awesome-icon
              class="d-flex justify-center fa-2x"
              icon="ellipsis-v"
              :title="$t('Dashboard.Menu.WidgetMenu')"
            />
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-if="mini && !editModeFlag && $auth.roles.includes('Web.Dashboard.Modify')"
            link
            @click="toggleEditMode"
          >
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.EnterEditMode') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="mini && editModeFlag"
            link
            @click="toggleEditMode"
          >
            <v-list-item-icon>
              <v-icon>mdi-eye</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.ExitEditMode') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item
            v-if="mini"
            link
            @click="onRenameClick"
          >
            <v-list-item-icon>
              <v-icon>mdi-form-textbox</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.EditDashboardName') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="editModeFlag"
            :disabled="!hasDashboards"
            link
            @click="addNewWidget"
          >
            <v-list-item-icon>
              <v-icon>mdi-view-grid-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.AddNewWidget') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-divider />
          <v-divider />
          <v-divider />
          <v-list-item
            v-if="mini && !editModeFlag"
            :disabled="!hasDashboards || dwellFlag"
            link
            @click="refreshDashboard"
          >
            <v-list-item-icon>
              <font-awesome-icon
                style="font-size: 1.3em"
                link
                :icon="'sync-alt'"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.RefreshDashboard') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item
            v-if="mini && editModeFlag"
            link
            @click="addNewDashboard"
          >
            <v-list-item-avatar
              style="margin-left: -5px; margin-right: 21px;"
            >
              <v-img
                lazy-src="@/assets/add-dash.png"
                max-height="26"
                max-width="33"
                src="@/assets/add-dash.png"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.AddNewBoard') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item
            v-if="mini && !editModeFlag"
            :disabled="!hasDashboards || dwellFlag"
            link
            @click="shareDashboard"
          >
            <v-list-item-avatar
              style="margin-left: -5px; margin-right: 21px"
            >
              <v-img
                lazy-src="@/assets/edit-sharing.png"
                max-height="30"
                max-width="38"
                src="@/assets/edit-sharing.png"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.ShareDashboard') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item
            disabled
            link
            @click="copyDashboard"
          >
            <v-list-item-icon>
              <v-icon>mdi-content-copy</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.CopyDashboard') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="homeDashboard"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.DefineHomepage') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item
            :disabled="!hasDashboards"
            link
            @click="deleteDashboard"
          >
            <v-list-item-icon>
              <v-icon>mdi-trash-can</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('Dashboard.Menu.DeleteDashboard') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-item-group>
    <Form
      v-model="NameForm.Show"
      :model="NameForm.Model"
      :schema="NameForm.Schema"
      @on-accept="onNameFormAccept"
    />
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/components/AutoFormPopup.vue';
import DashboardShare from './DashboardShare.vue';

export default {
  components: {
    DashboardShare,
    Form,
  },
  props: {
    editModeFlag: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    hasDashboards: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data: () => ({
    autoRefresh: false,
    dwellFlag: false,
    NameForm: {
      Model: {
        Name: null,
      },
      Schema: {
        Name: { label: 'Dashboard Name', type: 'text', flex: 12 },
      },
      Show: false,
    },
    dialog: {
      Message: '',
      NoCancel: false,
      Title: '',
      Show: false,
      acceptCallback: null,
    },
    shareDialog: false,
  }),
  /* i18n: {
    messages: {
      en: {
        EnterEditModeTitle: 'Enter edit mode',
        ExitEditModeTitle: 'Exit edit mode',
        WidgetMenuTitle: 'Show selected widget options',
        RefreshDashboardTitle: 'Refresh dashboard',
      },
    },
  }, */
  computed: {
    ...mapState('dashboard', ['dashboards', 'selectedDashboard']),
    mini() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizedWindow);
  },
  methods: {
    onRenameClick() {
      this.NameForm.Model.Name = this.selectedDashboard.name;
      this.NameForm.Show = true;
    },
    onNameFormAccept() {
      const model = {
        DashboardId: this.selectedDashboard.dashboardId,
        IsTemplate: this.selectedDashboard.isTemplate,
      };
      this.$emit('on-rename', model, this.NameForm.Model.Name);
    },
    shareDashboard() {
      this.shareDialog = true;
    },
    dwellForSomeTime() {
      this.dwellFlag = true;
      setTimeout(() => { this.dwellFlag = false; }, 3000);
    },

    toggleEditMode() {
      this.$emit('toggle-edit-mode');
    },
    refreshDashboard() {
      this.dwellForSomeTime();
      this.$emit('refresh-dashboard');
    },
    addNewDashboard() {
      this.$emit('on-add-dashboard');
    },
    addNewWidget() {
      this.$emit('add-new-widget');
    },
    doAccept() {
      this.dialog.acceptCallback();
    },
    copyDashboard() {
      this.$emit('copy-dashboard');
    },
    homeDashboard() {
      this.$emit('home-dashboard');
    },
    deleteDashboard() {
      this.$root.$confirm(this.$t('Dashboard.Menu.ConfirmDeleteDashboard', { name: this.selectedDashboard.Name })).then((confirm) => {
        if (confirm) {
          this.$emit('delete-dashboard');
        }
      });
    },
    resizedWindow() {
      if (this.editModeFlag) this.toggleEditMode();
    },
  },
};
</script>

<style lang="scss">
.menu-group {
  display:flex;
  align-items: center;
}

</style>
