<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="formData.Find"
              :label="$t('WindServer.Sites.LoggerConfiguration.TextReplacements.Find')"
              :rules="requiredRule"
              :maxlength="255"
            />
            <v-text-field
              v-model="formData.ReplaceWith"
              :label="$t('WindServer.Sites.LoggerConfiguration.TextReplacements.ReplaceWith')"
              :rules="requiredRule"
              :maxlength="255"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['textReplacement']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$store.dispatch('site/loggerConfigurations/textReplacement', null);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async setFormData() {
      if (this.textReplacement) {
        this.formData = JSON.parse(JSON.stringify(this.textReplacement));
      } else { // Create mode
        this.formData = {
          DataFileImportSettingId: this.id,
        };
      }
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (this.textReplacement) {
            await this.$store.dispatch('site/loggerConfigurations/updateTextReplacement', this.formData);
          } else {
            await this.$store.dispatch('site/loggerConfigurations/createTextReplacement', this.formData);
          }
          this.close();
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/loggerConfigurations/textReplacement', null);
      this.$refs.form.reset();
    },
  },
};
</script>
