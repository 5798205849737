import api from '@/api/importal';

const state = {
  peNumber: null,
  peNumbers: [],
  details: null,
};

const actions = {
  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('PENumbersList');
    commit('peNumbers', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async reserve({ commit, state, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('PENumbersReserve', {
      PeNumbers: payload,
      Details: state.details,
    });
    commit('details', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async release({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('PENumbersRelease', {
      PeNumbers: payload,
    });
    commit('details', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
};

const mutations = {
  peNumber(state, payload) {
    state.peNumber = payload;
  },
  peNumbers(state, payload) {
    state.peNumbers = payload;
  },
  details(state, payload) {
    state.details = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
