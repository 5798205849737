<template>
  <div style="height: calc(100vh - 110px); width: 100%">
    <QCTaskFilters />
    <ServerSideDataGrid
      :grid-source="sources.QCTasks"
      :show-new-button="$auth.roles.includes('Web.QCTask.Create')"
      :row-data-init="qcTasks"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import QCTaskList from '../gridsources/gsQCTasks';
import QCTaskFilters from './QCTaskFilters.vue';

export default {
  components: {
    ServerSideDataGrid, QCTaskFilters,
  },
  data() {
    return {
      sources: {
        QCTasks: null,
      },
    };
  },
  computed: {
    ...mapState('qualityCheck/qcTasks', ['qcTasks']),
  },
  created() {
    this.sources.QCTasks = new QCTaskList(this);
    this.refreshList();
  },
  methods: {
    refreshList() {
      this.$store.dispatch('qualityCheck/qcTasks/list');
    },
  },
};
</script>

<style>
.redBackgroundCell
{
  background-color: crimson;
}
</style>
