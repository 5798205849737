<template>
  <v-dialog v-model="show" max-width="600">
    <v-card>
      <v-card-title>
        <span class="headline">Pick Date and Time, Add Comment</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <datetime-picker
                v-model="date"
                :datetime="date"
                :label="$t('Pick Date')"
                :text-field-props="pickerTextFieldProps"
                :date-picker-props="pickerDateProps"
                :time-picker-props="pickerTimeProps"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="comment"
                :counter="8000"
                label="Comment"
                hint="Max 8000 characters"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="show = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!date || !comment"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatetimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      show: this.value,
      date: '',
      comment: '',
      pickerTextFieldProps: {
        dense: true,
        style: 'font-size: 80%;',
      },
      pickerDateProps: {
        locale: 'en-ca',
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  watch: {
    value(val) {
      this.show = val;
    },
    show(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    submit() {
      if (this.date && this.comment) {
        this.$emit('submit', {
          date: this.date,
          comment: this.comment,
        });
        this.show = false;
      } else {
        // Optionally, you can show an error message or handle the empty fields case
        // console.log('Date and comment are required.');
      }
    },
  },
};
</script>
