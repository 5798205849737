import api from '@/api/importal';
import i18n from '@/plugins/i18n';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  allUnits: [],
  categories: [],
  filteredUnits: [],
  sensors: [],
  filteredSensors: [],
};

const actions = {
  async unitList({ commit }) {
    commit('filteredUnits', []);
    return withWait({ commit }, () => api.get('DevUnitCategoryList'))
      .then((resp) => {
        let units = resp.data;
        units = units.map((x) => ({ Name: x, Text: i18n.t(`Units.${x}`) }));
        units.sort((a, b) => a.Text.localeCompare(b.Text));
        commit('categories', units);
      });
  },
  async sensorList({ commit }, instrumentId) {
    return withWait({ commit }, () => api.get(`DevSensorList?InstrumentId=${instrumentId}`))
      .then((resp) => {
        commit('sensors', resp.data);
        commit('filteredSensors', resp.data);
      });
  },
  async vectorList({ commit }, instrumentId) {
    return withWait({ commit }, () => api.get(`DevVectorList?InstrumentId=${instrumentId}`))
      .then((resp) => {
        commit('sensors', resp.data);
        commit('filteredSensors', resp.data);
      });
  },
  async getAllUnits({ commit }) {
    return withWait({ commit }, () => api.get('DevUnitList'))
      .then((resp) => commit('allUnits', resp.data));
  },
  async updateUnitList({ commit }, category) {
    return withWait({ commit }, () => api.get(`DevUnitList?category=${category}`))
      .then((resp) => commit('filteredUnits', resp.data));
  },
  updateSensors({ commit, state }, propName) {
    if (state.filteredUnits.length > 0) {
      const list = state.filteredUnits.map((x) => x.Symbol);
      commit('filteredSensors', state.sensors.filter((x) => x[propName] === null || list.includes(x[propName])));
    } else {
      commit('filteredSensors', state.sensors);
    }
  },
  async setUnit({ commit }, payload) {
    return withWait({ commit }, () => api.post('DevSensorUnitSet', {
      InstrumentId: Number(payload.InstrumentId),
      SensorIds: payload.Sensors.map((x) => x.SensorId),
      Unit: payload.Unit,
    }))
      .then((resp) => commit('sensors', resp.data));
  },
  async setReadingUnit({ commit }, payload) {
    return withWait({ commit }, () => api.post('DevVectorReadingUnitSet', {
      InstrumentId: Number(payload.InstrumentId),
      VectorNames: payload.Sensors.map((x) => x.Name),
      ReadingUnit: payload.Unit,
    }))
      .then((resp) => commit('sensors', resp.data));
  },
  async setSamplingUnit({ commit }, payload) {
    return withWait({ commit }, () => api.post('DevVectorSamplingUnitSet', {
      InstrumentId: Number(payload.InstrumentId),
      VectorNames: payload.Sensors.map((x) => x.Name),
      SamplingUnit: payload.Unit,
    }))
      .then((resp) => commit('sensors', resp.data));
  },
};

const mutations = {
  allUnits(state, payload) {
    state.allUnits = payload;
  },
  categories(state, payload) {
    state.categories = payload;
  },
  filteredUnits(state, payload) {
    state.filteredUnits = payload;
  },
  sensors(state, payload) {
    state.sensors = payload;
  },
  filteredSensors(state, payload) {
    state.filteredSensors = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
