<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <server-side-data-grid
      :grid-source="sources.DataSource"
      :toolbar-buttons="toolbarButtons"
      :show-new-button="$auth.roles.includes('Web.Sensor.Create')"
      :refresh="refresh"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import DataSource from './gridsources/gsvirtualinst';

export default {
  components: {
    ServerSideDataGrid,
  },
  data: () => ({
    refresh: 0,
    sources: {
      DataSource: null,
    },
    toolbarButtons: [],
  }),
  computed: {
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),
  },
  watch: {
    tenantId() {
      if (this.sources.DataSource) {
        this.refresh += 1;
      }
    },
    accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.refresh += 1;
      }
    },
  },
  created() {
    this.sources.DataSource = new DataSource(this);
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>
