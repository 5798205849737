import ModemList from './components/ModemList.vue';

export default [
  {
    path: '/Communication/Modem',
    name: 'Worker.Modem.List',
    component: ModemList,
    children: [
    ],
  },
];
