<template>
  <div>
    <v-dialog
      v-model="show"
      transition="dialog-bottom-transition"
      max-width="800"
      max-height="600"
    >
      <v-card>
        <v-card-title class="pa-3">
          <p class="mb-0 mt-0">
            {{ `${$t('Dashboard.MediaWidget.Comments')}` }}
            {{ ` : ${feedInfos.date}` }}
          </p>
        </v-card-title>
        <div
          v-if="!comments.length"
          style="height: 500px;"
        >
          <div class="loader" />
        </div>
        <section
          v-else
          style="overflow: hidden; height: 500px;"
        >
          <v-row no-gutters>
            <v-col cols="auto">
              <v-card-text class="pb-0">
                {{ `${$t('Dashboard.MediaWidget.Comments')} :` }}
              </v-card-text>
            </v-col>
            <v-col cols="auto">
              <v-card-actions>
                <v-btn
                  :disabled="!hasPrevious"
                  text
                  @click="getPrevious"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                  {{ $t('Previous') }}
                </v-btn>
                <v-btn
                  :disabled="!hasNext"
                  text
                  @click="getNext"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                  {{ $t('Next') }}
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="showCommentEntryDialog = true"
                >
                  {{ $t('Add Comment') }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <div class="d-flex fill-height">
            <v-col cols="3">
              <v-virtual-scroll
                :items="feedInfos.infos"
                :item-height="40"
                style="max-height: 500px; overflow-y: auto; overflow-x: hidden;"
                class="v-virtual-scroll--with-height"
              >
                <template #default="{ item }">
                  <v-list-item
                    :key="item.index"
                    dense
                    class="pa-0 pl-2"
                    active-class="primary"
                    :input-value="selectedIndex === item.index"
                  >
                    <v-btn
                      icon
                      @click="downloadFile(item)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-card-text
                      class="py-2 px-1 cursor-pointer"
                      @click="getSelected(item.index)"
                    >
                      {{ getTime(item.ts) }}
                    </v-card-text>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-col>
            <v-col cols="9">
              <v-card-text v-if="comments[selectedIndex]">
                <p>
                  <strong>{{ comments[selectedIndex].user_name }}</strong>
                  ({{ comments[selectedIndex].user_id }}) at {{ getTime(comments[selectedIndex].ts) }}:
                </p>
                <p>{{ comments[selectedIndex].content }}</p>
              </v-card-text>
            </v-col>
          </div>
        </section>
        <v-card-actions class="d-flex justify-end align-end">
          <v-btn
            text
            @click="$emit('back', { date: feedInfos.date, index: selectedIndex })"
          >
            {{ $t('Back') }}
          </v-btn>
          <v-btn
            text
            @click="show = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <show-comment-entry-dialog
      v-model="showCommentEntryDialog"
      @submit="CommentSubmit"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import datehandling from '@/components/datehandling';
import showCommentEntryDialog from './CommentEntryDialog.vue';

export default {
  components: {
    showCommentEntryDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: true,
    },
    widgetSettingsData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      comments: [],
      showCommentEntryDialog: false,
      commentToEdit: null,
      distinctDates: [],
    };
  },
  computed: {
    ...mapState('mediaFeed', ['feedInfos', 'availableDates']),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    hasPrevious() {
      return this.selectedIndex > 0 || this.feedInfos.index > 0;
    },
    hasNext() {
      return (
        this.selectedIndex + 1 < this.feedInfos.infos.length
        || this.feedInfos.index + 1 < this.distinctDates.length
      );
    },
  },
  watch: {
    selectedItem: {
      async handler(newVal) {
        if (newVal && newVal.date) {
          this.selectedIndex = newVal.index;
          this.updateComments();
          this.updateDistinctDates();
        }
      },
      immediate: true,
    },
    availableDates: {
      handler() {
        this.updateDistinctDates();
      },
      immediate: true,
    },
  },
  methods: {
    updateComments() {
      this.comments = this.feedInfos.infos.map((info) => ({
        content: info.content,
        user_id: info.user_id,
        user_name: info.user_name,
        ts: info.ts,
      }));
    },
    updateDistinctDates() {
      this.distinctDates = [...new Set(this.availableDates.map((date) => date))];
    },
    async getPrevious() {
      this.selectedIndex -= 1;
      if (this.selectedIndex < 0) {
        this.feedInfos.index -= 1;
        await this.updateItems(this.availableDates[this.feedInfos.index]);
        this.selectedIndex = this.feedInfos.infos.length - 1;
      }
      this.updateComments();
    },
    async getNext() {
      this.selectedIndex += 1;
      if (this.selectedIndex >= this.feedInfos.infos.length) {
        this.feedInfos.index += 1;
        await this.updateItems(this.availableDates[this.feedInfos.index]);
        this.selectedIndex = 0;
      }
      this.updateComments();
    },
    async getSelected(index) {
      this.selectedIndex = index;
      this.updateComments();
    },
    async updateItems(newDate) {
      if (newDate) {
        await this.$store.dispatch('mediaFeed/getFullInfosForDay', {
          date: newDate,
          widgetSettingsData: this.widgetSettingsData,
        });
      }
    },
    getTime(time) {
      return datehandling.formatTimeOnlyForDisplay(datehandling.zonedTimeToUtc(time));
    },
    downloadFile(infos) {
      const fileContent = `User: ${infos.user_name} (ID: ${infos.user_id})\n\nComment:\n${infos.content}`;
      const blob = new Blob([fileContent], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${infos.ts}.txt`;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    editComment(infos) {
      this.commentToEdit = infos;
      this.showCommentEntryDialog = true;
    },
    async CommentSubmit(data) {
      if (this.commentToEdit) {
        const index = this.feedInfos.infos.findIndex(
          (info) => info.index === this.commentToEdit.index,
        );
        if (index !== -1) {
          this.feedInfos.infos[index].content = data.comment;
          this.commentToEdit = null;
        }
      } else {
        await this.$store.dispatch('mediaFeed/send', {
          ts: data.date,
          fd: this.feedInfos.infos[0].feedId,
          mt: this.feedInfos.infos[0].mime_type,
          infoUserId: this.identity.user.UserId,
          infoUserName: this.identity.user.UserName,
          infoCategory: 'text',
          infoContent: data.comment,
        });
      }
      this.updateComments();
    },
  },
};
</script>
