<template>
  <v-dialog
    v-model="form.show"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>{{ $t('ValidityRange') }}</v-card-title>
      <v-card-subtitle>
        &nbsp;
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <div
            class="col-3"
          >
            {{ $t('Range') }}
          </div>
            &nbsp;
            &nbsp;
          <datetime-picker
            v-model="form.ValidFromTs"
            :label="$t('From')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
          />
            &nbsp;
            &nbsp;
          <datetime-picker
            v-model="form.ValidToTs"
            :label="$t('To')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
          />
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="onAccept"
        >
          {{ $t('Save') }}
        </v-btn>
        <v-btn
          text
          @click.stop="form.show=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatetimePicker from '@/components/DatetimePicker.vue';

export default {
  components: {
    DatetimePicker,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    form: {
      show: false,
      ValidFromTs: null,
      ValidToTs: null,
    },
    pickerTextFieldProps: {
      dense: true,
      clearable: true,
      class: 'col-4',
      style: 'font-size: 80%;',
    },
    pickerDateProps: {
      locale: 'en-ca',
    },
    pickerTimeProps: {
      format: '24hr',
    },
  }),
  watch: {
    value(val) {
      this.form.show = val;
      if (this.form.show) {
        this.initialize();
      }
    },
    'form.show': function show() {
      if (!this.form.show) {
        this.$emit('input', this.form.show);
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.form.ValidFromTs = this.info.ValidFromTs;
      this.form.ValidToTs = this.info.ValidToTs;
    },
    onAccept() {
      this.$emit('on-accept', {
        ValidFromTs: this.form.ValidFromTs,
        ValidToTs: this.form.ValidToTs,
      });
    },
  },
  i18n: {
    messages: {
      en: {
        ValidityRange: 'Validity Range',
        Range: 'Range',
        From: 'From',
        To: 'To',
        Save: 'Save',
        Cancel: 'Cancel',
      },
      fr: {
        ValidityRange: 'étendue de validité',
        Range: 'étendue',
        From: 'De',
        To: 'À',
        Save: 'Enregistrer',
        Cancel: 'Annuler',
      },
    },
  },
};
</script>

<style>

</style>
