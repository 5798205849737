import api from '@/api/importal';
import units from './units/units.store';

const state = {
  loggers: [],
  logger: {
    PeNumber: null,
  },
  instrumentId: null,
};

const actions = {
  async loggersList({ commit }, id) {
    commit('app/pleaseWait', true, { root: true });
    commit('instrumentId', id);
    const response = await api.get(`LoggersList?instrumentId=${id}`);
    commit('loggers', response.data);
    const logger = await api.get(`InstrumentLogger?instrumentId=${id}`);
    commit('logger', logger.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.get(`LinkInstrument?instrumentId=${state.instrumentId}&peNumber=${payload}`);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('loggersList', state.instrumentId);
  },
};

const mutations = {
  loggers(state, payload) {
    state.loggers = payload;
  },
  logger(state, payload) {
    state.logger = payload;
  },
  instrumentId(state, payload) {
    state.instrumentId = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    units,
  },
};
