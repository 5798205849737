<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.PENumbers"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="peNumbers"
      enable-selection="multiple"
      @selection-changed="onSelectionChanged"
    />
    <router-view />
    <ConfirmDialog
      v-model="dialog.Show"
      :message="dialog.Message"
      :no-cancel="dialog.NoCancel"
      :title="dialog.Title"
      @on-accept="doAccept"
    />
    <ReserveModalForm
      v-model="showReserveModal"
      @on-accept="doReserve"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import PENumberList from '../gridsources/gsPENumbers';
import ReserveModalForm from './ReserveModalForm.vue';

export default {
  components: {
    ServerSideDataGrid,
    ConfirmDialog,
    ReserveModalForm,
  },
  data() {
    return {
      sources: {
        PENumbers: null,
      },
      toolbarButtons: [],
      selection: {
        number: [],
      },
      dialog: {
        Message: '',
        NoCancel: false,
        Title: '',
        Show: false,
        acceptCallback: null,
      },
      showReserveModal: false,
    };
  },
  computed: {
    ...mapState('equipment/penumbers', ['peNumbers']),
  },
  created() {
    this.sources.PENumbers = new PENumberList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
    this.toolbarButtons.push({
      tooltip: this.$t('Equipments.PENumbers.Reserved'),
      icon: 'mdi-checkbox-multiple-marked-outline',
      enabled: true,
      click: () => { this.reserve(); },
    });
    this.toolbarButtons.push({
      tooltip: this.$t('Equipments.PENumbers.Release'),
      icon: 'mdi-checkbox-multiple-blank-outline',
      enabled: true,
      click: () => { this.release(); },
    });
  },
  methods: {
    refreshList() {
      this.$store.dispatch('equipment/penumbers/list');
    },
    reserve() {
      if (this.selection.number.length > 0) {
        this.showReserveModal = true;
      } else {
        this.showWarning();
      }
    },
    async release() {
      if (this.selection.number.length > 0) {
        await this.$store.dispatch('equipment/penumbers/release', this.selection.number.map((x) => x.PeNumber));
        this.selection.number = [];
      } else {
        this.showWarning();
      }
    },
    onSelectionChanged(event) {
      this.selection.number = event.api.getSelectedRows();
    },
    showWarning() {
      this.dialog.Message = i18n.t('Equipments.PENumbers.SelectNumber');
      this.dialog.NoCancel = true;
      this.dialog.Title = i18n.t('Warning');
      this.dialog.acceptCallback = () => {};
      this.dialog.Show = true;
    },
    doAccept() {
      this.dialog.acceptCallback();
    },
    async doReserve() {
      await this.$store.dispatch('equipment/penumbers/reserve', this.selection.number.map((x) => x.PeNumber));
      this.selection.number = [];
    },
  },
};
</script>
