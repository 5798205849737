<template>
  <div>
    Data coverage chart goes here!
  </div>
</template>

<script>
export default {
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
};
</script>
