/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsAttributes extends ServerSideDataGridGsClientBase {
  constructor(vue, cb) {
    super();
    this.vue = vue;
    this.callback = cb;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    this.gatewayInfo = await importal.get(`DevGatewayList?DataGatewayId=${this.vue.$route.params.id}`)
      .then((result) => result.data[0]);

    return new Promise((resolve) => {
      resolve([
        {
          field: 'DataGatewayAttributeId',
          headerName: 'Id',
          width: 100,
          resizable: true,
          hide: this.vue.readmode === true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (`attribute "${event.data.Name}"`),
            clicked: (event) => {
              this.apiDevGatewayIngressSensorRemove(event.api, event.data);
            },
          },
        },
        {
          field: 'Name', width: 180, headerName: i18n.t('Devices.Gateway.Edit_Headers.Name'),
        },
        {
          field: 'ProtocolCd', width: 180, headerName: i18n.t('Devices.Gateway.Edit_Headers.ProtocolCd'),
        },
        {
          field: 'ValueStr', width: 180, headerName: i18n.t('Devices.Gateway.Edit_Headers.ValueStr'),
        },
        {
          field: 'ValueInt', width: 180, headerName: i18n.t('Devices.Gateway.Edit_Headers.ValueInt'),
        },
        {
          field: 'ValueFloat', width: 180, headerName: i18n.t('Devices.Gateway.Edit_Headers.ValueFloat'),
        },
        {
          field: '',
          headerName: i18n.t('Devices.Gateway.Edit_Headers.Edit'),
          width: 100,
          resizable: true,
          hide: this.vue.readmode === true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.callback.onEdit(event.api, event.data);
            },
          },
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue } = this;
    return {
      getRows: function GetRows(params) {
        importal.get(`DevGatewayAttributeList?DataGatewayId=${vue.$route.params.id}`)
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve({
        Model: {
          DataGateWayId: vue.$route.params.id,
          Name: null,
          Value: null,
        },
        Schema: {
          DataGateWayId: { label: i18n.t('Devices.Gateway.AddNew.GatewayId'), type: 'text', disabled: true },
          Name: {
            label: i18n.t('Devices.Gateway.AddNew.Name'), type: 'text', flex: 12,
          },
          Value: {
            label: i18n.t('Devices.Gateway.AddNew.Value'), type: 'text', flex: 12,
          },
        },
      });
    });
  }

  async updateItemFormDefinition() {
    return new Promise((resolve) => {
      resolve({
        Model: {
          DataGatewayAttributeId: null,
          ProtocolCd: null,
          Name: null,
          Value: null,
        },
        Schema: {
          DataGatewayAttributeId: { label: i18n.t('Devices.Gateway.AddNew.GatewayId'), type: 'text', disabled: true },
          ProtocolCd: { label: 'Protocol', type: 'text', disabled: true },
          Name: {
            label: i18n.t('Devices.Gateway.AddNew.Name'), type: 'text', flex: 12,
          },
          Value: {
            label: i18n.t('Devices.Gateway.AddNew.Value'), type: 'text', flex: 12,
          },
        },
      });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    let parsedInt = parseInt(data.Value, 10);
    let parsedFloat = Number(data.Value);
    let valuestr = data.Value;
    if (!Number.isNaN(parsedInt) && parsedInt === parsedFloat) {
      valuestr = parsedFloat.toString();
    } else if (!Number.isNaN(parsedFloat)) {
      parsedInt = null;
      valuestr = parsedFloat.toString();
    } else {
      parsedInt = null;
      parsedFloat = null;
    }
    importal
      .post('DevGatewayAttributeNew', {
        DataGateWayId: data.DataGateWayId,
        Name: data.Name,
        ProtocolCd: this.gatewayInfo.ProtocolCd,
        ValueFloat: parsedFloat,
        ValueInt: parsedInt,
        ValueStr: valuestr,
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      });
  }

  async apiDevGatewayIngressSensorRemove(api, data) {
    await importal
      .post('DevGatewayAttributeRemove', {
        DataGatewayAttributeId: data.DataGatewayAttributeId,
      })
      .then((resp) => {
        api.setRowData(resp.data);
      });
  }

  async apiDevGatewayAttributeUpdate(api, data) {
    let parsedInt = parseInt(data.Value, 10);
    let parsedFloat = Number(data.Value);
    let valuestr = data.Value;
    if (!Number.isNaN(parsedInt) && parsedInt === parsedFloat) {
      valuestr = parsedFloat.toString();
    } else if (!Number.isNaN(parsedFloat)) {
      parsedInt = null;
      valuestr = parsedFloat.toString();
    } else {
      parsedInt = null;
      parsedFloat = null;
    }
    await importal.post('DevGatewayAttributeUpdate',
      {
        DataGatewayAttributeId: data.DataGatewayAttributeId,
        Name: data.Name,
        ProtocolCd: data.ProtocolCd,
        ValueFloat: parsedFloat,
        ValueInt: parsedInt,
        ValueStr: valuestr,
      })
      .then((resp) => {
        api.setRowData(resp.data);
      });
  }
}
