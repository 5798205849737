// import i18n from '@/plugins/i18n';
import api from '@/api/importal';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  rowdata: [],
  accessgroups: [],
  readmode: null,
};

const actions = {
  async list({ commit }) {
    return withWait({ commit }, () => api.get('DevGatewayList'))
      .then((resp) => commit('setRowData', resp.data));
  },
  async create({ commit }, payload) {
    return withWait({ commit }, () => api.post('DevGatewayNew', payload))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async remove({ commit }, id) {
    return withWait({ commit }, () => api.post('DevGatewayRemove', {
      DataGatewayId: id,
    }))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async rename({ commit }, payload) {
    return withWait({ commit }, () => api.post('DevGatewayRename', payload))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async updateProtocolCd({ commit }, payload) {
    return withWait({ commit }, () => api.post('DevGatewayProtocolUpdate', payload))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async accessGroupList({ commit }) {
    return withWait({ commit }, () => api.get('AccessGroupList'))
      .then((resp) => commit('setAccessGroups', resp.data));
  },
  async gatewayReadmode({ commit }, id) {
    return withWait({ commit }, () => api.get(`GatewayReadmode?id=${id}`))
      .then((resp) => commit('readmode', resp.data));
  },
};

const mutations = {
  setRowData(state, payload) {
    state.rowdata = payload;
  },
  mergeRowData(state, payload) {
    state.rowdata = payload;
  },
  setAccessGroups(state, payload) {
    state.accessgroups = payload;
  },
  readmode(state, payload) {
    state.readmode = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
