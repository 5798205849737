import api from '@/api/importal';

const state = {
  sectionQuestion: null,
  sectionQuestions: [],
  sectionId: null,
  nameExists: false,
};

const actions = {
  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCSectionQuestionsList?id=${state.sectionId}`);
    commit('sectionQuestions', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCSectionQuestionsCreate', {
      Name: payload.Name,
      QCSectionId: Number(state.sectionId),
    });
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCSectionQuestionsGet?id=${payload.questionId}`);
    commit('sectionQuestion', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async update({ commit, dispatch }, payload) {
    if (payload.Name !== state.sectionQuestion.Name) {
      commit('app/pleaseWait', true, { root: true });
      await api.put('QCSectionQuestionsUpdate', {
        Name: payload.Name,
        QCSectionQuestionId: Number(payload.QCSectionQuestionId),
        QCSectionId: Number(state.sectionId),
      });
      commit('app/pleaseWait', false, { root: true });
      await dispatch('list');
    }
  },
  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`QCSectionQuestionsRemove?id=${payload.id}`);
    commit('sectionQuestion', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async validate({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('nameExists', false);
    const response = await api.get(`QCSectionQuestionsValidate?id=${state.sectionQuestion === null ? null : state.sectionQuestion.QCSectionQuestionId}&sectionId=${state.sectionId}&name=${payload.Name}`);
    commit('nameExists', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async setSectionId({ commit }, payload) {
    commit('sectionId', payload);
  },
};

const mutations = {
  sectionQuestions(state, payload) {
    state.sectionQuestions = payload;
  },
  sectionQuestion(state, payload) {
    state.sectionQuestion = payload;
  },
  sectionId(state, payload) {
    state.sectionId = payload;
  },
  nameExists(state, payload) {
    state.nameExists = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
