<template>
  <l-map
    class="map"
    ref="myMap"
    :zoom="map.zoom"
    :center="[site.Latitude, site.Longitude]"
    :options="map.options"
    style="height: auto"
    @ready="onMapReady()"
  >
    <l-tile-layer
      :url="map.url"
      :options="{ className: 'map-tiles' }"
    />
    <l-marker
      :lat-lng="[site.Latitude, site.Longitude]"
      :icon="getMarkerIcon()"
    />
  </l-map>
</template>

<script>
import { mapState } from 'vuex';
import L from 'leaflet';
import {
  LMap, LTileLayer, LMarker, LPopup, LTooltip,
} from 'vue2-leaflet';

export default {
  components: {
    // eslint-disable-next-line
    LMap, LTileLayer, LMarker, LPopup, LTooltip,
  },
  data() {
    return {
      map: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 8,
        center: [0, 0],
        options: {
          zoomSnap: 0.5,
        },
      },
    };
  },
  computed: {
    ...mapState('customers/sites', ['site']),
  },
  methods: {
    // eslint-disable-next-line
    getMarkerIcon() {
      const options = {
        html: '<i class="mdi mdi-map-marker mdi-36px"></i>',
        iconSize: [36, 36],
        iconAnchor: [18, 36],
        popupAnchor: [0, -36],
        className: '',
      };

      return new L.DivIcon(options);
    },
    onMapReady() {
      const map = this.$refs.myMap.mapObject;
      setTimeout(() => { map.invalidateSize(true); }, 100);
    },
  },
};
</script>
