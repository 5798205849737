<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="formData.Number"
              :label="$t('Settings.LandOwnerPhones.Columns.Phone')"
              :rules="[v => !!v || $t('Validation.Required')]"
            />
            <v-select
              v-model="formData.PhoneTypeId"
              :label="$t('Settings.LandOwnerPhones.Columns.Type')"
              :items="phoneTypes"
              :rules="[v => !!v || $t('Validation.Required')]"
              item-text="Description"
              item-value="PhoneTypeId"
              return-object
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    phoneId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      readMode: !this.$auth.roles.includes('Web.LandOwner.Manage'),
    };
  },
  computed: {
    ...mapState('landOwner/landOwnerPhones', ['landOwnerPhone']),
    ...mapState('landOwner/landOwners', ['landOwner', 'phoneTypes']),
  },
  watch: {
    async phoneId() {
      await this.getLandOwnerPhone();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.phoneId && this.phoneId != null) {
      await this.getLandOwnerPhone();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    async getLandOwnerPhone() {
      await this.$store.dispatch('landOwner/landOwnerPhones/get', { phoneId: this.phoneId });
      this.formData = this.$clone(this.landOwnerPhone);
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'LandOwners.Update' });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.phoneId) {
          await this.$store.dispatch('landOwner/landOwnerPhones/update', this.formData);
        } else {
          await this.$store.dispatch('landOwner/landOwnerPhones/create', this.formData);
        }

        this.navigateToList();
      }
    },
  },
};
</script>
