import GridStack from './DashboardView.vue';

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: GridStack,
  },
  {
    path: '/dashboard/:id',
    name: 'DashboardLoaded',
    component: GridStack,
  },
];
