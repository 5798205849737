<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text class="pt-3">
          <v-text-field
            v-model="formData.ProviderName"
            :label="$t('Equipments.Equipments.Edit.ProviderName')"
            :autofocus="true"
            :rules="[v => !!v || $t('Validation.Required')]"
            :error-messages="providerMessage"
            @input="cleanError"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="onCancel"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      providerMessage: '',
    };
  },
  computed: {
    ...mapState('equipment/equipments', ['provider']),
    ...mapState('equipment/cellularaccountproviders', ['providernameAlredyExists']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        if (await this.NameValidation()) {
          await this.$store.dispatch('equipment/equipments/saveProvider', this.formData);
          this.$emit('input', false);
          this.resetModal();
        }
      }
    },
    onCancel() {
      this.$emit('input', false);
      this.resetModal();
    },
    async NameValidation() {
      await this.$store.dispatch('equipment/cellularaccountproviders/validate', this.formData);
      if (this.providernameAlredyExists) {
        this.providerMessage = i18n.t('Equipments.CellularAccountProviders.ProviderNameExist');
      } else {
        this.providerMessage = '';
        return true;
      }
      return false;
    },
    cleanError() {
      this.providerMessage = '';
    },
    resetModal() {
      this.formData = {};
      this.$refs.form.reset();
    },
  },
};
</script>
