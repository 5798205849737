function generateByChannels(colNum, data, channels, measurementPoints) {
  let colName;
  let j = 0;
  let col;
  let num = colNum;

  channels.forEach((ch) => {
    j = 0;
    measurementPoints.forEach((mp) => {
      if (mp.PropertyType.PropertyTypeId === -1) { // ignore column
        colName = ch.ChannelCode.concat('-ignore-').concat(j);
        j += 1;
        col = {
          DataFileImportSettingId: data.DataFileImportSettingId,
          DataImportConfigurationId: num - 1,
          IsNew: true,
          ColumnType: {
            ColumnTypeId: 2,
            Name: 'Ignore',
          },
          Name: colName,
        };
        num -= 1;
        data.DataImportConfigs.push(col);
      } else { // Data
        colName = ch.ChannelCode.concat('-').concat(mp.PropertyType.Code).concat('-').concat(mp.StatisticType.Code);
        // eslint-disable-next-line no-param-reassign
        ch.ChannelCalibration = {
          ChannelCalibrationId: ch.ChannelCalibrationId,
          Height: ch.Height,
          HeadingFromTower: ch.HeadingFromTower,
          DistanceToTower: ch.DistanceToTower,
          AveragingInterval: ch.AveragingInterval,
        };
        col = {
          DataFileImportSettingId: data.DataFileImportSettingId,
          DataImportConfigurationId: num - 1,
          IsNew: true,
          ColumnType: {
            ColumnTypeId: 1,
            Name: 'Data',
          },
          Name: colName,
          PropertyType: mp.PropertyType,
          StatisticType: mp.StatisticType,
          UnitOfMeasure: mp.UnitOfMeasure,
          AveragingInterval: ch.AveragingInterval,
          DistanceToTower: ch.DistanceToTower,
          HeadingFromTower: ch.HeadingFromTower,
          Height: ch.Height,
          LoggerChannel: ch,
        };
        num -= 1;
        data.DataImportConfigs.push(col);
      }
    });
  });
}

function generateByHeights(colNum, data, heights, measurementPoints, channelNum, logger) {
  let colName;
  let j = 0;
  let c = 0;
  let col;
  let num = colNum;
  let cnum = channelNum;
  let channel = null;

  heights.forEach((h) => {
    j = 0;
    channel = {
      ChannelCalibrationId: cnum - 1,
      ChannelCode: 'CH'.concat(c),
      AveragingInterval: 600,
      Height: h.Height,
      IsNew: true,
      LoggerId: logger.Id,
      LoggerPeNumber: logger.PeNumber,
      ChannelOffset: 0,
      ChannelSlope: 0,
      DistanceToTower: 0,
      HeadingFromTower: 0,
      UnitOfMeasure: 'unspecified',
      UnitOfMeasureId: 33,
      ChannelCalibration: {
        ChannelCalibrationId: cnum - 1,
        Height: h.Height,
        HeadingFromTower: 0,
        DistanceToTower: 0,
        AveragingInterval: 0,
      },
    };
    logger.Channels.push(channel);
    cnum -= 1;
    c += 1;
    measurementPoints.forEach((mp) => {
      if (mp.PropertyType.PropertyTypeId === -1) { // ignore column
        colName = 'Ignore-'.concat(j).concat('-').concat(String(h.Height).padStart(2, '0'));
        j += 1;
        col = {
          DataFileImportSettingId: data.DataFileImportSettingId,
          DataImportConfigurationId: num - 1,
          IsNew: true,
          ColumnType: {
            ColumnTypeId: 2,
            Name: 'Ignore',
          },
          Name: colName,
        };
        num -= 1;
        data.DataImportConfigs.push(col);
      } else { // Data
        colName = mp.PropertyType.Code.concat('-').concat(mp.StatisticType.Code).concat('-').concat(String(h.Height).padStart(2, '0'));
        // eslint-disable-next-line no-param-reassign
        col = {
          DataFileImportSettingId: data.DataFileImportSettingId,
          DataImportConfigurationId: num - 1,
          IsNew: true,
          ColumnType: {
            ColumnTypeId: 1,
            Name: 'Data',
          },
          Name: colName,
          PropertyType: mp.PropertyType,
          StatisticType: mp.StatisticType,
          UnitOfMeasure: mp.UnitOfMeasure,
          AveragingInterval: channel.AveragingInterval,
          DistanceToTower: channel.DistanceToTower,
          HeadingFromTower: channel.HeadingFromTower,
          Height: channel.Height,
          LoggerChannel: channel,
        };
        num -= 1;
        data.DataImportConfigs.push(col);
      }
    });
  });
}

function generateByFile(Id, data, configs, logger) {
  configs.channelsList.forEach((c) => {
    const channel = c;
    channel.LoggerId = logger.Id;
    channel.LoggerPeNumber = logger.PeNumber;
    channel.ChannelCalibration = {
      ChannelCalibrationId: c.ChannelCalibrationId,
      Height: c.Height,
      HeadingFromTower: c.HeadingFromTower,
      DistanceToTower: c.DistanceToTower,
      AveragingInterval: c.AveragingInterval,
    };
    logger.Channels.push(channel);
  });
  configs.importConfigs.forEach((mp) => {
    const channel = logger.Channels.filter(
      (x) => x.ChannelCalibrationId === mp.LoggerChannelId,
    )[0];
    const col = mp;
    col.DataFileImportSettingId = Id;
    col.DataImportConfigurationId = mp.DataImportConfigurationId;
    col.IsNew = true;
    col.AveragingInterval = channel.AveragingInterval;
    col.DistanceToTower = channel.DistanceToTower;
    col.HeadingFromTower = channel.HeadingFromTower;
    col.Height = channel.Height;
    col.LoggerChannel = channel;
    data.DataImportConfigs.push(col);
  });
}

export default {
  generateByChannels,
  generateByHeights,
  generateByFile,
};
