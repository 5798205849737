// import * as XLSX from 'xlsx/xlsx.mjs';
import render from '../../api/render';
import DashboardStackWidgetBase from './DashboardStackWidgetBase';

export default class DashboardStackPlotlyWidget extends DashboardStackWidgetBase {
  async displayWidget() {
    let settings = null;
    try {
      settings = JSON.parse(this.widget.WidgetSettings);
    } catch (e) {
      return;
    }

    const isPartialQuery = (settings.user.visualisationType === 'plotly'
      && (
        settings.user.visualisationSubType === 'sensor+series+point'
        || settings.user.visualisationSubType === 'sensor+series+line'
        || settings.user.visualisationSubType === 'sensor+series+bar'
      )
    );

    // Pass DashboardsWidgetId to the API to set ID of plotly object
    settings.user.DashboardsWidgetId = this.widget.DashboardsWidgetId;
    settings.visualization.graphType = settings.visualization.graphType || 'scatter';
    settings.visualization.graphStyle = settings.visualization.graphStyle || 'markers';

    if (settings && settings.user) {
      settings.user.timezone = this.interface.getTimeZone();
    }

    const node = this.createWidgetNode();

    let response = null;
    let evaluate = false;

    if (settings && settings.data && settings.data.formulas && settings.data.formulas.length > 0) {
      response = await render.post(isPartialQuery ? 'visualisation/html?partial=true' : 'visualisation/html', settings)
        .catch(() => {
          this.createWidgetContent('<div class="graphnodata" style="color: red;"></div>', ''/* additionalMenus */);
          this.widgetClickListeners();
        });
      evaluate = true;
    } else {
      // mock the response -> it is going to fail without a formula
      response = {
        data: '<div> <div class="graphnodata"></div> <script type="text/javascript"> console.log("Sequence contains no elements")',
      };
    }

    if (!(response && response.data)) return;

    const jsPosition = response.data.search('<script '); // <script type="text/javascript">
    const div = response.data.substring(0, jsPosition);

    let js = response.data.substring(jsPosition).trim();
    js = js.substring(0, js.length - 6)
      .replace('<script type="text/javascript">', '')
      .replace("<\/script>", ''); /* eslint-disable-line */

    const vue = this.interface.getVueParent();

    let additionalMenus = this.createWidgetMenuItem('export_excel', vue.$t.apply(vue, ['Export XSLX']), true);
    if (this.interface.getVueParent().$auth.roles.includes('Web.Sensor.Debug')) {
      additionalMenus += this.createWidgetMenuItem('sensor_query_debug', vue.$t.apply(vue, ['Export KQL']), true);
    }
    if (this.interface.getVueParent().$auth.roles.includes('Web.Dashboard.ModifyAlert')) {
      additionalMenus += this.createWidgetMenuItem('sensor_alert', vue.$t.apply(vue, ['Subscribe']), true);
      // eslint-disable-next-line max-len
      // additionalMenus += this.createWidgetMenuItem('alert_menu', vue.$t.apply(vue, ['Open Subscribe Menu']), true);
    }
    if (this.interface.getVueParent().$auth.roles.includes('Web.Dashboard.ModifyAlert')) {
      if (this.widget) {
        if (JSON.parse(this.widget.WidgetSettings).visualization.crossplot) {
          additionalMenus += this.createWidgetMenuItem('reference_line', vue.$t.apply(vue, ['Reference Line']), true);
        }
      }
    }
    this.createWidgetContent(div, additionalMenus);
    this.widgetClickListeners();

    if (evaluate) {
      // Only evaluate if no mock was done
      // eslint-disable-next-line no-eval
      await eval(js);
    }
    // const scriptEl = document.createElement('script');
    // scriptEl.innerHTML = js;
    // scriptEl.type = 'text/javascript';
    // document.getElementsByTagName('head')[0].appendChild(scriptEl);
    this.waitForWidgetNode(node);
  }

  resizeToContainer(gridstackNode) {
    const gsId = this.widget.DashboardsWidgetId;
    const rect = {
      w: gridstackNode.el.clientWidth,
      h: gridstackNode.el.clientHeight,
    };
    const plot = document.getElementById(gsId);
    if (plot !== null) {
      // eslint-disable-next-line no-eval
      eval(`Plotly.relayout("${gsId}", { width: ${rect.w - 20}, height: ${rect.h - 20}, });`);
    }
  }

  async onWidgetMenuItemClick(e) {
    const menuButton = e.currentTarget;
    const { action } = menuButton.dataset;
    if (action === 'export_excel') {
      await this.interface.onExportExcel();
    } else if (action === 'sensor_query_debug') {
      await this.interface.onSensorQueryDebug();
    } else if (action === 'sensor_alert') {
      await this.interface.OpenSubscribeSetting(this.widget);
    } else if (action === 'reference_line') {
      await this.interface.OpenReferenceSetting(this.widget);
    } else if (action === 'alert_menu') {
      await this.interface.OpenSubscribeMenu();
    }
    super.onWidgetMenuItemClick(e);
  }
}
