<template>
  <div style="height: calc(100vh - 50px); overflow: auto; width: 100%">
    <mdi-icon-picker-dialog
      v-if="showIconPickerDialog"
      v-models:show="showIconPickerDialog"
      v-models:icon="iconPickerDialogSelectedIcon"
      @click:submit="iconPickerDialogSubmitted"
    />
    <layer-picker-dialog
      v-if="layerPickerDialogShow"
      v-models:show="layerPickerDialogShow"
      v-models:layer="layerPickerDialogLayer"
      @click:submit="layerPickerDialogSubmitted"
    />
    <location-selector
      v-if="locationDialog"
      v-models:show="locationDialog"
      v-models:lat="locationDialogLatitude"
      v-models:lon="locationDialogLongitude"
      @click:submit="onLocationSelectorSubmit"
    />
    <v-toolbar dense>
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Back')"
        icon-name="mdi-arrow-left"
        @click="doBack"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Refresh')"
        icon-name="mdi-refresh"
        @click="doRefresh"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Devices.Icons.SetLayer')"
        icon-name="mdi-layers-outline"
        @click="toolbarSetLayer"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Devices.Icons.SetIcon')"
        icon-name="mdi-crop-free"
        @click="toolbarSetIcon"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('Devices.Icons.SetLocation')"
        icon-name="mdi-map-marker"
        @click="toolbarSetLocation"
      />
    </v-toolbar>
    <l-map
      ref="map"
      style="height: 300px; z-index: 1"
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
        :options="tileLayerOptions"
      />
      <l-marker
        ref="mark"
        :lat-lng="markerCenter"
        :icon="markerIcon"
      />
      <l-control position="bottomright">
        <v-btn @click="centerPoint">
          {{ $t('Instruments.CenterOnInstrument') }}
        </v-btn>
      </l-control>
    </l-map>
    <v-row
      style="height: calc(100vh - 420px); width: 100%"
      class="ma-0 pa-0"
    >
      <v-col
        cols="12"
        class="ma-0 pa-0"
      >
        <v-tabs
          centered
        >
          <v-tab @click="tabChange('Attribute')">
            {{ $t('Instruments.Attribute') }}
          </v-tab>
          <v-tab @click="tabChange('Sensor')">
            {{ $t('Instruments.Sensor') }}
          </v-tab>
          <v-tab @click="tabChange('Vector')">
            {{ $t('Instruments.Vector') }}
          </v-tab>
          <v-tab
            v-if="loggers.length > 0"
            @click="tabChange('Logger')"
          >
            {{ $t('Instruments.Logger.Title') }}
          </v-tab>
        </v-tabs>
        <instrument-attribute-list
          v-if="tabValue == 'Attribute'"
          style="height: 450px"
          :refresh="refresh"
        />
        <sensor-list
          v-if="tabValue == 'Sensor'"
          style="height: 450px"
          :refresh="refresh"
          @back="tabChange('Sensor')"
        />
        <vector-list
          v-if="tabValue == 'Vector'"
          style="height: 450px"
          :refresh="refresh"
        />
        <LoggerSelector
          v-if="tabValue == 'Logger'"
          style="height: 450px"
          :refresh="refresh"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  LMap, LTileLayer, LControl, LMarker,
} from 'vue2-leaflet';
import L from 'leaflet';
import { mapState } from 'vuex';
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import importal from '@/api/importal';
import SensorList from './tables/SensorList.vue';
import VectorList from './tables/VectorList.vue';
import InstrumentAttributeList from './tables/InstrumentAttributeList.vue';
import LocationSelector from './LocationSelector.vue';
import MdiIconPickerDialog from './MdiIconPickerDialog.vue';
import LayerPickerDialog from './LayerPickerDialog.vue';
import LoggerSelector from './tables/LoggerSelector.vue';

export default {
  components: {
    LMap,
    LTileLayer,
    LControl,
    LMarker,
    SensorList,
    VectorList,
    InstrumentAttributeList,
    LocationSelector,
    ToolbarButtonWithTooltip,
    MdiIconPickerDialog,
    LayerPickerDialog,
    LoggerSelector,
  },
  data: () => ({
    refresh: 0,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    zoom: 15,
    selectedVector: null,
    selectedSensor: null,
    tabValue: 'Attribute',
    iconName: 'mdi-map-marker',
    center: [0, 0],
    markerLatitude: 0.0,
    markerLongitude: 0.0,
    locationDialogLatitude: 0.0,
    locationDialogLongitude: 0.0,
    locationDialog: false,
    tileLayerOptions: {
      className: 'map-tiles',
    },

    showIconPickerDialog: false,
    iconPickerDialogSelectedIcon: '',
    layerPickerDialogShow: false,
    layerPickerDialogLayer: false,
  }),
  computed: {
    ...mapState('devices/instruments', ['loggers', 'logger']),
    markerCenter() { return [parseFloat(this.markerLatitude), parseFloat(this.markerLongitude)]; },
    markerIcon() {
      return new L.DivIcon(this.markerOptions);
    },
    markerOptions() {
      return {
        html: `<i class="mdi ${this.iconName} mdi-24px"></i>`,
        iconSize: [24, 24],
        iconAnchor: [12, 24],
        popupAnchor: [0, -12],
        className: '',
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.map = this.$refs.map.mapObject;
      this.mark = this.$refs.mark.mapObject;
      this.map.on('click', this.onMapClick);
    });
  },
  beforeDestroy() {
    this.map.off('click', this.onMapClick);
  },
  created() {
    this.getInstrumentInfo();
  },
  methods: {
    updateIcon(name) {
      this.iconName = name;
    },
    updateLayer(name) {
      this.layerPickerDialogLayer = name;
    },
    tabChange(changeto) {
      this.tabValue = changeto;
    },
    doBack() {
      this.$router.push({ name: 'Devices.Instrument' });
      this.$store.commit('app/pageTitle', { key: 'Devices.Instruments.PageTitle' });
    },
    doRefresh() {
      this.refresh += 1;
    },
    getInstrumentInfo() {
      importal.get(`DevInstrumentList?InstrumentId=${this.$route.params.id}`)
        .then((result) => result.data[0])
        .then((data) => {
          this.$store.commit('app/pageTitle', { key: 'Instrument {Name}', payload: data });
          this.getCoordinates(data);
        });
      importal.get(`DevInstrumentAttributeList?InstrumentId=${this.$route.params.id}`)
        .then((result) => result.data)
        .then((data) => {
          const iconInfo = data.filter((x) => x.Name === '$ICON$');
          if (iconInfo.length > 0) {
            this.updateIcon(iconInfo[0].ValueStr);
          }
          const layerInfo = data.filter((x) => x.Name === '$LAYER$');
          if (layerInfo.length > 0) {
            this.updateLayer(layerInfo[0].ValueStr);
          }
        });
    },
    getCoordinates(data) {
      if (data.GeoLocation != null) {
        [this.markerLongitude, this.markerLatitude] = data.GeoLocation.coordinates;
        this.center = this.markerCenter;
      }
      if (data.GeoLocation == null) {
        this.zoom = 0;
      }
    },

    toolbarSetLocation() {
      this.openLocationSelectorDialog(
        parseFloat(this.markerLatitude),
        parseFloat(this.markerLongitude),
      );
    },
    toolbarSetIcon() {
      this.showIconPickerDialog = true;
    },
    toolbarSetLayer() {
      this.layerPickerDialogShow = true;
    },

    openLocationSelectorDialog(lat, lon) {
      this.locationDialogLatitude = lat;
      this.locationDialogLongitude = lon;
      this.$nextTick(() => {
        this.locationDialog = true;
      });
    },
    onLocationSelectorSubmit({ latitude, longitude }) {
      this.markerLatitude = Number(latitude);
      this.markerLongitude = Number(longitude);
      this.setLocation();
    },

    onMapClick(e) {
      const { lat, lng } = e.latlng;
      this.openLocationSelectorDialog(lat, lng);
    },

    centerPoint() {
      this.map.setView(this.center, 15);
    },
    setLocation() {
      importal.post('DevInstrumentRelocate',
        {
          InstrumentId: parseInt(this.$route.params.id, 10),
          Latitude: parseFloat(this.markerLatitude),
          Longitude: parseFloat(this.markerLongitude),
        });
      this.locationDialog = false;
      this.center = this.markerCenter;
    },

    iconPickerDialogSubmitted(icon) {
      this.setOrCreateStrAttribute('$ICON$', icon);
    },

    layerPickerDialogSubmitted(layer) {
      this.setOrCreateStrAttribute('$LAYER$', layer);
    },

    setOrCreateStrAttribute(name, value) {
      importal.get(`DevInstrumentAttributeList?InstrumentId=${this.$route.params.id}`)
        .then((result) => result.data)
        .then((data) => data.filter((x) => x.Name === name))
        .then((data) => {
          if (data.length > 0) {
            const [item] = data;
            importal.post('DevInstrumentAttributeUpdate',
              {
                InstrumentAttributeId: item.InstrumentAttributeId,
                Name: item.Name,
                ValueFloat: null,
                ValueInt: null,
                ValueStr: value,
              })
              .then(() => {
                this.doRefresh();
                if (name === '$ICON$') {
                  this.updateIcon(value);
                }
              });
          } else {
            importal.post('DevInstrumentAttributeNew',
              {
                InstrumentId: Number(this.$route.params.id),
                Name: name,
                ValueFloat: null,
                ValueInt: null,
                ValueStr: value,
              })
              .then(() => {
                this.doRefresh();
                if (name === '$ICON$') {
                  this.updateIcon(value);
                }
              });
          }
        });
    },
  },
};
</script>
<style lang="scss">
</style>
