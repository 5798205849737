/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsSiteNotes extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
    this.maintenanceRecords = this.vue.maintenanceRecords;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.Site.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 80,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.Site.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'Sites.Notes.Update',
                params: {
                  noteId: event.data.SiteNoteId,
                },
              });
            },
          },
        },
        {
          field: 'User',
          headerName: this.vue.$t('WindServer.Sites.Notes.Columns.User'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
          hide: this.maintenanceRecords,
        },
        {
          field: 'TechnicianName',
          headerName: this.vue.$t('WindServer.Sites.Notes.Columns.Technician'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
          hide: !this.maintenanceRecords,
        },
        {
          field: 'CreationTs',
          headerName: this.vue.$t('WindServer.Sites.Notes.Columns.DateTime'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          field: 'Note',
          headerName: this.vue.$t('WindServer.Sites.Notes.Columns.Note'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'IsInternal',
          headerName: this.vue.$t('WindServer.Sites.Notes.Columns.IsInternal'),
          width: 150,
          editable: false,
          filter: true,
          sortable: true,
          cellRenderer: (params) => `${params.value ? '<i class="v-icon mdi mdi-check theme--dark"></i>' : ''}`,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 90,
          resizable: true,
          hide: !this.vue.$auth.roles.includes('Web.Site.Delete'),
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (this.vue.$t('WindServer.Sites.Notes.Columns.Note', { Name: event.data.Note })),
            clicked: async (event) => {
              await this.vue.$store.dispatch('site/siteNotes/remove', { id: event.data.SiteNoteId });
            },
          },
        },
      ]);
    });
  }
}
