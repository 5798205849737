import api from '@/api/importal';

const state = {
  sectionMeasurement: null,
  sectionMeasurements: [],
  sectionId: null,
  measurementValues: null,
};

const actions = {
  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCSectionMeasurementsList?id=${state.sectionId}`);
    commit('sectionMeasurements', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async measurementValuesList({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCMeasurementValuesList?id=${state.sectionId}&measurementId=${payload}`);
    commit('measurementValues', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCSectionMeasurementsCreate', {
      QCMeasurementId: Number(payload.QCMeasurementId),
      QCSectionId: Number(state.sectionId),
    });
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCSectionMeasurementsGet?id=${payload.measurementId}`);
    commit('sectionMeasurement', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async update({ commit, dispatch }, payload) {
    if (payload.QCMeasurementId !== state.sectionMeasurement.QCMeasurementId) {
      commit('app/pleaseWait', true, { root: true });
      await api.put('QCSectionMeasurementsUpdate', {
        QCMeasurementId: Number(payload.QCMeasurementId),
        QCSectionMeasurementId: Number(payload.QCSectionMeasurementId),
        QCSectionId: Number(state.sectionId),
      });
      commit('app/pleaseWait', false, { root: true });
      await dispatch('list');
    }
  },
  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`QCSectionMeasurementsRemove?id=${payload.id}`);
    commit('sectionMeasurement', null);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },
  async setSectionId({ commit }, payload) {
    commit('sectionId', payload);
  },
};

const mutations = {
  sectionMeasurements(state, payload) {
    state.sectionMeasurements = payload;
  },
  sectionMeasurement(state, payload) {
    state.sectionMeasurement = payload;
  },
  sectionId(state, payload) {
    state.sectionId = payload;
  },
  measurementValues(state, payload) {
    state.measurementValues = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
