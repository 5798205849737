<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-card-text>
          <v-row class="align-self-start">
            <v-col>
              <v-tabs
                v-model="selectedTab"
              >
                <v-tab>
                  <div :class="{'v-application error--text': isGeneralError }">
                    {{ $t('Equipments.Equipments.Edit.GeneralTab') }}
                  </div>
                </v-tab>
                <v-tab>
                  <div :class="{'v-application error--text': isModelError }">
                    <span v-if="isBoom">
                      {{ $t('Equipments.Equipments.Edit.BoomModel') }}
                    </span>
                    <span v-else-if="isCellularModem">
                      {{ $t('Equipments.Equipments.Edit.CellularModemModel') }}
                    </span>
                    <span v-else-if="isGinPole">
                      {{ $t('Equipments.Equipments.Edit.GinPoleModel') }}
                    </span>
                    <span v-else-if="isLogger">
                      {{ $t('Equipments.Equipments.Edit.LoggerModel') }}
                    </span>
                    <span v-else-if="isSensor">
                      {{ $t('Equipments.Equipments.Edit.SensorModel') }}
                    </span>
                    <span v-else-if="isTower">
                      {{ $t('Equipments.Equipments.Edit.TowerModel') }}
                    </span>
                    <span v-else>
                      {{ $t('Equipments.Equipments.Edit.ModelName') }}
                    </span>
                  </div>
                </v-tab>
                <v-tab v-if="hasDetails">
                  <span v-if="isCellularModem">
                    {{ $t('Equipments.Equipments.Edit.CellularModemDetails') }}
                  </span>
                  <span v-else-if="isLogger">
                    {{ $t('Equipments.Equipments.Edit.LoggerDetails') }}
                  </span>
                  <span v-else>
                    {{ $t('Equipments.Equipments.Edit.TowerDetails') }}
                  </span>
                </v-tab>
                <v-tab>
                  <div :class="{'v-application error--text': isOwnerError }">
                    {{ $t('Equipments.Equipments.Edit.Owner') }}
                  </div>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-container>
            <v-tabs-items
              v-model="selectedTab"
              style="width: 100%;"
            >
              <v-tab-item :eager="true">
                <v-select
                  v-model="formData.EquipmentTypeId"
                  :label="$t('Equipments.Equipments.Edit.EquipmentType')"
                  :items="equipmentType"
                  :readonly="isEdit"
                  :autofocus="!isEdit"
                  :rules="[EquipmentTypeValidation]"
                  item-text="EquipmentTypeNameI18N"
                  item-value="EquipmentTypeId"
                  @change="changeEquipementType()"
                />
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model.number="formData.PeNumber"
                      :label="$t('Equipments.Equipments.Edit.PeNumber')"
                      :readonly="isEdit"
                      :rules="[v => !!v || $t('Validation.Required')]"
                      type="number"
                    />
                  </v-col>
                  <v-col
                    v-if="!isEdit"
                    cols="12"
                    sm="6"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          :bottom="true"
                          v-bind="attrs"
                          elevation="2"
                          v-on="on"
                          @click.stop="getNextPeNumber"
                        >
                          {{ $t('Get') }}
                        </v-btn>
                      </template>
                      <span>{{ $t('Equipments.Equipments.Edit.GetTooltip') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.DuplicateOfPeNumber"
                      :readonly="true"
                      :label="$t('Equipments.Equipments.Edit.DuplicateOfPeNumber')"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>{{ $t('Equipments.Equipments.Edit.DuplicateOfTooltip') }}</span>
                </v-tooltip>
                <v-text-field
                  v-model="formData.SerialNumber"
                  :label="$t('Equipments.Equipments.Edit.SerialNumber')"
                />
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-checkbox
                      v-model="formData.Tagged"
                      :label="$t('Equipments.Equipments.Edit.Tagged')"
                      type="checkbox"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-checkbox
                      v-model="formData.Retired"
                      :label="$t('Equipments.Equipments.Edit.Retired')"
                      type="checkbox"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :eager="true">
                <v-row>
                  <v-col
                    cols="12"
                    sm="9"
                  >
                    <v-text-field
                      v-model="formData.Model.ManufacturerName"
                      :label="$t('Equipments.Equipments.Edit.ManufacturerName')"
                      :readonly="true"
                      :rules="[v => !!v || $t('Validation.Required')]"
                    />
                    <v-text-field
                      v-model="formData.Model.ModelName"
                      :label="$t('Equipments.Equipments.Edit.ModelName')"
                      :readonly="true"
                      :rules="[v => !!v || $t('Validation.Required')]"
                    />
                    <v-text-field
                      v-if="isBoom"
                      v-model.number="formData.Model.BoomLength"
                      :label="$t('Equipments.Equipments.Edit.BoomLength')"
                      :readonly="true"
                      type="number"
                    />
                    <v-text-field
                      v-if="isBoom"
                      v-model.number="formData.Model.PostHeight"
                      :label="$t('Equipments.Equipments.Edit.PostHeight')"
                      :readonly="true"
                      type="number"
                    />
                    <v-text-field
                      v-if="isSensor"
                      v-model="WindvaneDeadbandValue"
                      :label="$t('Equipments.Equipments.Edit.WindvaneDeadband')"
                      :readonly="true"
                    />
                    <v-text-field
                      v-if="isTower"
                      v-model.number="formData.Model.TowerHeight"
                      :label="$t('Equipments.Equipments.Edit.PostHeight')"
                      :readonly="true"
                      type="number"
                    />
                  </v-col>
                  <v-col
                    v-if="!readMode"
                    cols="12"
                    sm="3"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          :bottom="true"
                          v-bind="attrs"
                          elevation="2"
                          v-on="on"
                          @click.stop="onChange"
                        >
                          {{ $t('Change') }}
                        </v-btn>
                      </template>
                      <span>{{ $t('Equipments.Equipments.Edit.ChangeModelTooltip') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item
                v-if="hasDetails"
                :eager="true"
              >
                <div v-if="isCellularModem">
                  <v-select
                    v-model="formData.MobileEquipmentIdentifierType"
                    :label="$t('Equipments.Equipments.Edit.MobileEquipmentIdentifierType')"
                    :items="mobileEquipmentIdType"
                    required
                    item-text="Name"
                    item-value="Name"
                  />
                  <v-text-field
                    v-model="formData.MobileEquipmentIdentifier"
                    :label="$t('Equipments.Equipments.Edit.MobileEquipmentIdentifier')"
                  />
                  <v-select
                    v-model="formData.NetworkType"
                    :label="$t('Equipments.Equipments.Edit.NetworkType')"
                    :items="networkType"
                    item-text="Name"
                    item-value="Name"
                  />
                  <v-text-field
                    v-model="formData.PhoneNumber"
                    :label="$t('Equipments.Equipments.Edit.PhoneNumber')"
                  />
                  <v-row>
                    <v-col
                      cols="12"
                      sm="9"
                    >
                      <v-select
                        v-model="formData.AccountProvider"
                        :label="$t('Equipments.Equipments.Edit.AccountProvider')"
                        :items="cellularAccountProvider"
                        item-text="ProviderName"
                        item-value="CellularAccountProviderId"
                        return-object
                      />
                    </v-col>
                    <v-col
                      v-if="!readMode"
                      cols="12"
                      sm="3"
                    >
                      <v-btn
                        elevation="2"
                        @click.stop="newProvider"
                      >
                        {{ $t('Equipments.Equipments.Edit.NewProvider') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-checkbox
                    v-model="formData.AccountIsManagedByGenivar"
                    :label="$t('Equipments.Equipments.Edit.ManageByWSP')"
                    type="checkbox"
                  />
                </div>
                <div v-if="isLogger">
                  <v-text-field
                    v-model.number="formData.DefaultAveragingInterval"
                    :label="$t('Equipments.Equipments.Edit.LoggerDefaultAveragingInterval')"
                    type="number"
                  />
                  <v-row>
                    <v-col
                      cols="12"
                      sm="9"
                    >
                      <div>
                        {{ $t('Equipments.Equipments.Edit.LoggerChannels') }}
                      </div>
                      <select
                        id="channels"
                        v-model="channelSelected"
                        size="5"
                        class="v-list v-sheet theme--dark"
                        style="width: 100%"
                      >
                        <option
                          v-for="(item, index) in orderedChannels"
                          :key="index"
                          :value="item"
                          class="v-list-item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </v-col>
                    <v-col
                      v-if="!readMode"
                      cols="12"
                      sm="3"
                    >
                      <v-btn
                        elevation="2"
                        @click.stop="showChannel = true"
                      >
                        {{ $t('Add') }}
                      </v-btn>
                      <v-btn
                        elevation="2"
                        @click.stop="deleteChannel"
                      >
                        {{ $t('Delete') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-text-field
                    v-model="formData.LoggerPasswords"
                    :label="$t('Equipments.Equipments.Edit.LoggerPasswords')"
                  />
                </div>
                <div v-if="isTower">
                  <v-checkbox
                    v-model="formData.IsPainted"
                    :label="$t('Equipments.Equipments.Edit.IsPainted')"
                    type="checkbox"
                  />
                </div>
              </v-tab-item>
              <v-tab-item :eager="true">
                <v-select
                  v-model="formData.Tenant"
                  :label="$t('Equipments.Equipments.Edit.Owner')"
                  :items="tenants"
                  :rules="[v => !!v.TenantId || $t('Validation.Required')]"
                  item-text="Name"
                  item-value="TenantId"
                  return-object
                  @change="changeTenant()"
                />
                <datetime-picker
                  v-if="!isEdit || tenantChange"
                  v-model="formData.StartingDate"
                  :label="$t('Equipments.Equipments.Edit.OwnerShipStartingDate')"
                  :date-picker-props="pickerDateProps"
                  :time-picker-props="pickerTimeProps"
                  :has-clear-button="false"
                  :rules="[v => !!v || $t('Validation.Required')]"
                />
                <v-text-field
                  v-else
                  v-model="formattedStartingDate"
                  readonly
                  :label="$t('Equipments.Equipments.Edit.OwnerShipStartingDate')"
                  :rules="[v => !!v || $t('Validation.Required')]"
                />
                <v-card v-if="isEdit">
                  <v-card-subtitle> {{ $t('Equipments.Equipments.Edit.OwnerShipHistory') }}</v-card-subtitle>
                  <v-card-text>
                    <v-container fluid>
                      <div style="height: 165px; width: 100%">
                        <ServerSideDataGrid
                          :grid-source="sources.owners"
                          :row-data-init="owners"
                        />
                      </div>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <ModelModalList
      v-model="showModelModal"
      :equipment-type-id="formData.EquipmentTypeId"
      @on-accept="acceptModel"
    />
    <ProviderModalForm
      v-model="showProviderModal"
    />
    <ChannelModalForm
      v-model="showChannel"
      @on-accept="acceptChannel"
    />
    <ConfirmDialog
      v-model="showChannelError"
      :message="$t('Equipments.Equipments.Edit.ChannelExist')"
      :title="$t('Equipments.Equipments.Title')"
      :no-cancel="true"
      @on-accept="showChannelError = false"
    />
    <ConfirmDialog
      v-model="showConfirm"
      :message="$t('Equipments.Equipments.Edit.ConfirmMessage', [formattedPeNumber, availablePeNumber.Name, formattedDatetime, availablePeNumber.ReservationDetails])"
      :title="$t('Equipments.Equipments.Edit.ApplyChanges')"
      @on-accept="save"
    />
    <ConfirmDialog
      v-model="showError"
      :message="$t('Equipments.Equipments.Edit.ErrorMessage', [formattedPeNumber])"
      :title="$t('Equipments.Equipments.Edit.ApplyChanges')"
      :no-cancel="true"
      @on-accept="showError = false"
    />
    <ConfirmDialog
      v-model="showValidate"
      :message="$t('Equipments.Equipments.Edit.ValidateMessage')"
      :title="$t('Equipments.Equipments.Edit.ApplyChanges')"
      :no-cancel="true"
      @on-accept="showValidate = false"
    />
    <ConfirmDialog
      v-model="showOwnerError"
      :message="$t('Equipments.Equipments.Edit.OwnerError', [validOwnerShip ? validOwnerShip.Name : ''])"
      :title="$t('Equipments.Equipments.Title')"
      :no-cancel="true"
      @on-accept="showOwnerError = false"
    />
  </v-dialog>
</template>

<script>

import { format } from 'date-fns';
import { mapState } from 'vuex';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import DatetimePicker from '@/components/DatetimePicker.vue';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import i18n from '@/plugins/i18n';
import ModelModalList from './ModelModalList.vue';
import ProviderModalForm from './ProviderModalForm.vue';
import ChannelModalForm from './ChannelModalForm.vue';
import OwnerList from '../gridsources/gsowners';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

export default {
  components: {
    ConfirmDialog,
    DatetimePicker,
    ServerSideDataGrid,
    ModelModalList,
    ProviderModalForm,
    ChannelModalForm,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        PeNumber: null,
        LoggerChannels: [],
        Model: {},
        Tenant: {},
        StartingDate: new Date(),
      },
      sources: {
        owners: null,
      },
      show: false,
      selectedTab: null,
      channelSelected: '',
      showChannelError: false,
      showChannel: false,
      showModelModal: false,
      showProviderModal: false,
      showConfirm: false,
      showError: false,
      showOwnerError: false,
      showValidate: false,
      currentTenant: null,
      tenantChange: false,
      isGeneralError: false,
      isModelError: false,
      isOwnerError: false,
      readMode: !this.$auth.roles.includes('Web.Equipment.Management.Manage'),
      pickerDateProps: {
        locale: i18n.locale,
      },
      pickerTimeProps: {
        format: '24hr',
      },
    };
  },
  computed: {
    ...mapState('equipment/equipments', ['equipment', 'owners', 'equipmentType', 'mobileEquipmentIdType', 'networkType', 'cellularAccountProvider', 'models', 'sensorType', 'availablePeNumber', 'tenants', 'validOwnerShip']),
    isEdit() {
      return this.id != null;
    },
    isBase() {
      return this.formData.EquipmentTypeId != null
      && this.formData.EquipmentTypeId === 0;
    },
    isBoom() {
      return this.formData.EquipmentTypeId != null
      && this.formData.EquipmentTypeId === 1;
    },
    isCellularModem() {
      return this.formData.EquipmentTypeId != null
      && this.formData.EquipmentTypeId === 2;
    },
    isGinPole() {
      return this.formData.EquipmentTypeId != null
      && this.formData.EquipmentTypeId === 3;
    },
    isLogger() {
      return this.formData.EquipmentTypeId != null
      && this.formData.EquipmentTypeId === 4;
    },
    isSensor() {
      return this.formData.EquipmentTypeId != null
      && this.formData.EquipmentTypeId === 5;
    },
    isTower() {
      return this.formData.EquipmentTypeId != null
      && this.formData.EquipmentTypeId === 6;
    },
    hasDetails() {
      return this.isCellularModem || this.isLogger || this.isTower;
    },
    WindvaneDeadbandValue() {
      if ((this.formData.Model != null) && (this.formData.Model.SensorType === 'Windvane' || this.formData.Model.SensorType === 'Vanemometer')) {
        return this.formData.Model.WindvaneDeadband;
      }
      return 'N/A';
    },
    orderedChannels() {
      if (this.formData.LoggerChannels.length > 0) {
        const sortChannels = this.formData.LoggerChannels
          .slice()
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            return 1;
          });
        return sortChannels;
      }
      return null;
    },
    dateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    formattedDatetime() {
      return this.availablePeNumber.ReservedDate ? format(this.availablePeNumber.ReservedDate, this.dateTimeFormat) : '';
    },
    formattedPeNumber() {
      return this.availablePeNumber.PeNumber ? String(this.availablePeNumber.PeNumber).padStart(5, '0') : '';
    },
    formattedStartingDate() {
      return this.formData.StartingDate ? format(this.formData.StartingDate, this.dateTimeFormat) : '';
    },
  },
  watch: {
    async id() {
      await this.getEquipment();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    await this.$store.dispatch('equipment/equipments/getEquipmentType');
    await this.$store.dispatch('equipment/equipments/getNetworkType');
    await this.$store.dispatch('equipment/equipments/getMobileEquipmentIdType');
    await this.$store.dispatch('equipment/equipments/getCellularAccountProviderList');
    await this.$store.dispatch('equipment/equipments/getTenants');
    if (this.id && this.id != null) { // Update mode
      this.sources.owners = new OwnerList(this);
      await this.getEquipment();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    async getEquipment() {
      await this.$store.dispatch('equipment/equipments/get', { id: this.id });
      this.formData = JSON.parse(JSON.stringify(this.equipment));
      if (this.formData.Tenant) {
        this.formData.Tenant.StartingDate = this.equipment.Tenant.StartingDate;
      } else {
        this.formData.Tenant = {
          StartingDate: null,
        };
      }
      this.currentTenant = JSON.parse(JSON.stringify(this.formData.Tenant));
      if (this.formData.Tenant.StartingDate != null) {
        this.currentTenant.StartingDate = this.equipment.Tenant.StartingDate;
      }
      this.formData.StartingDate = this.currentTenant.StartingDate;
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'Equipment.Equipments.List' });
    },
    async submit() {
      this.isGeneralError = false;
      this.isModelError = false;
      this.isOwnerError = false;
      if (this.$refs.form.validate()) {
        this.formData.Tenant.StartingDate = this.formData.StartingDate;
        if (this.id) {
          await this.$store.dispatch('equipment/equipments/update', this.formData);
          this.navigateToList();
        } else {
          await this.$store.dispatch('equipment/equipments/validatePeNumber', this.formData.PeNumber);
          if (this.availablePeNumber.IsAvailable) {
            await this.$store.dispatch('equipment/equipments/create', this.formData);
            this.navigateToList();
          } else if (this.availablePeNumber.ReservedDate != null) {
            this.showConfirm = true;
          } else {
            this.showError = true;
          }
        }
      } else {
        if (this.formData.EquipmentTypeId == null || this.formData.PeNumber == null
          || this.formData.PeNumber.length === 0) {
          this.isGeneralError = true;
        }
        if (this.formData.Model.ManufacturerName == null || this.formData.Model.ModelName == null) {
          this.isModelError = true;
        }
        if (this.formData.Tenant.TenantId == null || this.formData.StartingDate == null) {
          this.isOwnerError = true;
        }
        this.showValidate = true;
      }
    },
    async save() {
      await this.$store.dispatch('equipment/equipments/create', this.formData);
      this.navigateToList();
    },
    async changeEquipementType() {
      this.formData.Model = {};
    },
    async changeTenant() {
      if (this.isEdit && this.currentTenant.TenantId !== this.formData.Tenant.TenantId) {
        await this.$store.dispatch('equipment/equipments/validateOwnerShip', this.formData);
        if (this.validOwnerShip
          && this.validOwnerShip.TenantId !== this.formData.Tenant.TenantId) {
          this.formData.Tenant = this.currentTenant;
          this.formData.StartingDate = this.currentTenant.StartingDate;
          this.showOwnerError = true;
        } else {
          this.tenantChange = true;
          this.formData.StartingDate = null;
        }
      } else if (this.isEdit) {
        this.formData.Tenant = this.currentTenant;
        this.formData.StartingDate = this.currentTenant.StartingDate;
      }
    },
    async getNextPeNumber() {
      await this.$store.dispatch('equipment/equipments/getNextPeNumber');
      this.formData.PeNumber = this.equipment.PeNumber;
    },
    newProvider() {
      this.showProviderModal = true;
    },
    deleteChannel() {
      const idx = this.formData.LoggerChannels.findIndex((c) => c === this.channelSelected);
      if (idx >= 0) {
        this.formData.LoggerChannels.splice(idx, 1);
      }
    },
    onChange() {
      this.$store.dispatch('equipment/equipments/getModels', this.formData.EquipmentTypeId);
      this.showModelModal = true;
    },
    acceptModel(value) {
      this.formData.Model = value;
      this.showModelModal = false;
    },
    acceptChannel(value) {
      this.showChannel = false;
      const idx = this.formData.LoggerChannels.findIndex((c) => c === value);
      if (idx >= 0) {
        this.showChannelError = true;
      } else {
        this.formData.LoggerChannels.push(value);
      }
    },
    EquipmentTypeValidation(value) {
      if (value >= 0) {
        return true;
      }
      return this.$t('Validation.Required');
    },
  },
};
</script>
