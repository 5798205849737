<template>
  <div style="height: 100%; width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.SiteConfigurations"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="siteConfigurations"
    />
    <router-view />
    <AdjustDateModalForm
      :show.sync="showAdjustDateModalForm"
      :config-date="configDate"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { format } from 'date-fns';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import AdjustDateModalForm from './AdjustDateModalForm.vue';
import SiteConfigurationsList from '../gridsources/gsSiteConfigurations';

const DEFAULT_DATE_FORMAT = 'yyyyMMdd';
const DEFAULT_TIME_FORMAT = 'HHmmss';

export default {
  components: {
    AdjustDateModalForm,
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        SiteConfigurations: null,
      },
      toolbarButtons: [],
      showAdjustDateModalForm: false,
      configDate: new Date(),
    };
  },
  computed: {
    ...mapState('site/siteConfigurations', ['siteConfigurations', 'siteId']),
    ...mapState('site/sites', ['site']),
    dateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT}${DEFAULT_TIME_FORMAT}`;
    },
  },
  created() {
    this.$store.dispatch('site/siteConfigurations/setSiteId', this.site.SiteId);
    this.sources.SiteConfigurations = new SiteConfigurationsList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.Site.ConfigurationPeriods.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('New'),
        icon: 'mdi-plus',
        enabled: true,
        click: () => { this.createNew(); },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'Sites.SiteConfigurations.Create', params: { id: this.site.SiteId } });
    },
    refreshList() {
      this.$store.dispatch('site/siteConfigurations/list');
    },
    onEdit(payload) {
      const dtFormated = format(new Date(payload.StartDate.toISOString().slice(0, -1)),
        this.dateTimeFormat);
      this.$router.push(
        {
          name: 'Sites.SiteConfigurations.EditModel',
          params:
          {
            id: this.siteId,
            startdate: dtFormated,
          },
        },
      );
    },
    adjustDate(data) {
      this.showAdjustDateModalForm = true;
      this.configDate = data.StartDate;
    },
    details(payload) {
      const dtFormated = format(new Date(payload.StartDate.toISOString().slice(0, -1)),
        this.dateTimeFormat);
      this.$router.push(
        {
          name: 'Sites.SiteConfigurations.Details',
          params:
          {
            id: this.siteId,
            startdate: dtFormated,
          },
        },
      );
    },
  },
};
</script>
