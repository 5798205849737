<template>
  <div class="mx-3 my-3">
    <h1>Presentation Exception Demo</h1>
    <v-btn
      @click="submit()"
    >
      Click me!
    </v-btn>
  </div>
</template>

<script>
import api from '@/api/importal';

export default {
  methods: {
    async submit() {
      await api.post('PresentationExceptionDemo');
    },
  },
};
</script>
