<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model.number="formData.PeNumber"
              :label="$t('WindServer.Sites.SiteConfigurations.PeNumber')"
              :readonly="editEquipment && !editEquipment.IsNew"
              type="number"
            />
            <v-text-field
              v-model="formData.SerialNumber"
              :label="$t('WindServer.Sites.SiteConfigurations.SerialNumber')"
              @change="onChange"
            />
            <v-select
              v-model="formData.Type"
              :label="$t('WindServer.Sites.SiteConfigurations.Type')"
              :items="sensorType"
              :rules="[v => !!v || $t('Validation.Required')]"
              :readonly="editEquipment && !editEquipment.IsNew"
              item-text="SensorTypeNameI18N"
              item-value="Name"
              @change="onChange"
            />
            <v-select
              v-model="formData.ModelId"
              :label="$t('WindServer.Sites.SiteConfigurations.ModelName')"
              :items="calculatedSensors"
              :rules="[v => !!v || $t('Validation.Required')]"
              :return-object="true"
              item-text="ModelName"
              item-value="ModelId"
              @change="onChange"
            >
              <template
                slot="selection"
                slot-scope="data"
              >
                {{ data.item.ManufacturerName }} {{ data.item.ModelName }}
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                <div
                  v-html="data.item.ManufacturerName +' '+ data.item.ModelName"
                />
              </template>
            </v-select>
            <v-select
              v-model="formData.MountId"
              :label="$t('WindServer.Sites.SiteConfigurations.Mount')"
              :items="mountType"
              :rules="[v => !!v || $t('Validation.Required')]"
              :return-object="true"
              item-text="MountName"
              item-value="MountId"
              @change="changeMount()"
            />
            <v-text-field
              v-if="!isPanelMount"
              v-model="formData.Height"
              :label="$t('WindServer.Sites.SiteConfigurations.Height')"
              :rules="[v => !!v || $t('Validation.Required')]"
              :readonly="isBoomMount"
              type="number"
              @change="onChange"
            />
            <v-text-field
              v-if="!isPanelMount && !isVerticalMount"
              v-model="formData.Heading"
              :label="$t('WindServer.Sites.SiteConfigurations.HeadingFromTower')"
              :rules="[v => !!v || $t('Validation.Required')]"
              :readonly="isBoomMount"
              type="number"
              @change="onChange"
            />
            <v-text-field
              v-if="isBoomMount"
              v-model="formData.Distance"
              :label="$t('WindServer.Sites.SiteConfigurations.DistanceToTower')"
              :rules="[v => !!v || $t('Validation.Required')]"
              :readonly="isBoomMount"
              type="number"
              @change="onChange"
            />
            <v-text-field
              v-if="isWindvane"
              v-model="formData.Deadband"
              :label="$t('WindServer.Sites.SiteConfigurations.DeadbandOrientation')"
              :rules="[v => !!v || $t('Validation.Required')]"
              type="number"
              @change="onChange"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
// import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      isBoomMount: false,
    };
  },
  computed: {
    ...mapState('site/siteConfigurations', ['equipmentModel', 'mountType', 'editEquipment']),
    ...mapState('equipment/equipments', ['sensorType']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$store.dispatch('site/siteConfigurations/editEquipment', null);
        this.$emit('input', value);
      },
    },
    calculatedSensors() {
      if (this.formData.Type) {
        return this.equipmentModel.Sensors
          .filter((x) => x.SensorType === this.formData.Type);
      }
      return null;
    },
    isWindvane() {
      return this.formData.Type != null
      && (this.formData.Type === 'Windvane' || this.formData.Type === 'Vanemometer');
    },
    isPanelMount() {
      return (this.formData.MountId != null && this.formData.MountId.MountId === 'Panel')
        || this.formData.MountId === 'Panel';
    },
    isVerticalMount() {
      return (this.formData.MountId != null && this.formData.MountId.Orientation && this.formData.MountId.Orientation === 'Vertical');
    },
  },
  async created() {
    if (this.formData.MountId && this.formData.MountId.MountId !== 'Tower' && this.formData.MountId.MountId !== 'Panel') {
      this.isBoomMount = true;
    }
  },
  methods: {
    setFormData() {
      if (this.editEquipment) {
        this.formData = JSON.parse(JSON.stringify(this.editEquipment));
      } else {
        this.formData = {};
      }
    },
    changeMount() {
      this.isBoomMount = false;
      if (this.formData.MountId.MountId !== 'Tower' && this.formData.MountId.MountId !== 'Panel') {
        this.isBoomMount = true;
        this.formData.Height = this.formData.MountId.Height;
        this.formData.Heading = this.formData.MountId.Heading;
        this.formData.Distance = this.formData.MountId.Distance;
      }
      this.formData.IsDirty = true;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.editEquipment) {
          await this.$store.dispatch('site/siteConfigurations/editSensor', this.formData);
        } else {
          await this.$store.dispatch('site/siteConfigurations/addSensor', this.formData);
        }
        this.close();
      }
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/siteConfigurations/editEquipment', null);
      this.$refs.form.reset();
    },
    onChange() {
      this.formData.IsDirty = true;
    },
  },
};
</script>
