<template>
  <v-node-screen
    ref="screen"
    :markers="arrowMarkers"
  >
    <v-node-group
      v-if="groupNodes"
      :nodes="graph.nodes"
    />

    <v-node-edge
      v-for="edge in graph.edges"
      :key="edge.id"
      :data="edge"
      :nodes="graph.nodes"
      :class="`edge-${edge.srcNode.nodeType}`"
    />

    <v-node-node
      v-for="node in graph.nodes"
      :key="node.id"
      :data="node"
      :class="`node-${node.nodeType}`"
      @fit-content="nodeFitContent"
    >
      <div
        v-if="node.nodeType === 'output_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Output [{{ node.Symbol }}]
        </div>
        {{ node.NameTemplate }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node.nodeType === 'internal_output_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon-passive"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Binding [{{ node.SourceSymbol }}]
        </div>
        D:{{ node.DestinationVi }}
      </div>

      <div
        v-if="node.nodeType === 'output_time_sync'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-clock-check node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Time Sync
        </div>
        {{ node.Aggregation }} by {{ node.multiplier }} {{ node.scale }}
      </div>

      <div
        v-if="node && node.nodeType === 'formula'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-math-integral-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Formula
        </div>
        <span class="formula-text-ellipsis">{{ node.FormulaText }}</span><br>
        {{ node.Unit }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        v-if="node && node.nodeType === 'missing_input'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-progress-question node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Name }}]
        </div>
        Missing input
      </div>
      <div
        v-if="node && node.nodeType === 'missing_output'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-progress-question node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Output [{{ node.Symbol }}]
        </div>
        Missing output
      </div>
      <div
        v-if="node && node.nodeType === 'input_time_sync'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-clock-check node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Time Sync
        </div>
        {{ node.Aggregation }} by {{ node.multiplier }} {{ node.scale }}
      </div>

      <div
        v-if="node && node.nodeType === 'internal_input_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.DestinationSymbol }}]
        </div>
        S:{{ node.SourceVi }}<br>
        D:{{ node.DestinationVi }}
      </div>

      <div
        v-if="node && node.nodeType === 'input_direct_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        {{ node.SensorRule }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-if="!node.InputTransform"
                v-bind="attrs"
                class="mdi mdi-24px mdi-math-integral-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('input-transform', node)"
              />
            </template>
            <span>{{ getTooltip(node, true) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_direct_vector'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-v-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        {{ node.SensorRule }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_internal_binding'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        Internal Binding
      </div>

      <div
        v-if="node && (node.nodeType === 'input_direct_sensor_array' || node.nodeType === 'input_external_sensor_array')"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-v-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        {{ node.SensorRule }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-if="!node.InputTransform"
                v-bind="attrs"
                class="mdi mdi-24px mdi-math-integral-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('input-transform', node)"
              />
            </template>
            <span>{{ getTooltip(node, true) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-s-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.AttributeName }}<br>
        S:{{ node.SensorRule }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-if="!node.InputTransform"
                v-bind="attrs"
                class="mdi mdi-24px mdi-math-integral-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('input-transform', node)"
              />
            </template>
            <span>{{ getTooltip(node, true) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_vector'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-v-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.AttributeName }}<br>
        S:{{ node.SensorRule }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_sensor_attribute'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-a-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        S:{{ node.SensorRule }}<br>
        A:{{ node.AttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_sensor_attribute'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-a-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.InstrumentAttributeNamegit }}<br>
        S:{{ node.SensorRule }}<br>
        A:{{ node.AttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_direct_instrument_attribute'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-a-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        A:{{ node.InstrumentAttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_direct_instrument_capture'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-c-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        A:{{ node.InstrumentAttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_instrument_attribute'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-a-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.AttributeName }}<br>
        A:{{ node.InstrumentAttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_external_instrument_capture'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-a-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        I:{{ node.InstrumentRule }} on {{ node.AttributeName }}<br>
        A:{{ node.InstrumentAttributeName }}<br>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node && node.nodeType === 'input_constant_sensor'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-alpha-c-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input [{{ node.Symbol }}]
        </div>
        D:{{ node.ConstantDefaultValue !== null ? node.ConstantDefaultValue : '(null)' }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-if="node.nodeType === 'input_sensor_xform'"
        :class="`node-inner node-inner-${node.nodeType}`"
      >
        <div class="node-header">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-math-integral-box node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('edit', node)"
              />
            </template>
            <span>{{ getTooltip(node) }}</span>
          </v-tooltip>
          Input Transform
        </div>
        <span class="formula-text-ellipsis">{{ node.InputTransform }}</span><br>
        {{ node.TransformXUnit }} {{ node.TransformYUnit }}
        <div class="node-footer">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="mdi mdi-24px mdi-delete-forever node-header-icon"
                v-on="on"
                @click.stop="nodeActionClick('delete', node)"
              />
            </template>
            <span>{{ $t('Delete') }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-node-node>
  </v-node-screen>
</template>

<script>
import VNodeGraph from '@/components/vnodes/graph';
import VNodeGroup from '@/components/vnodes/VNodeGroup.vue';
import VNodeEdge from '@/components/vnodes/VNodeEdge.vue';
import VNodeNode from '@/components/vnodes/VNodeNode.vue';
import VNodeScreen from '@/components/vnodes/VNodeScreen.vue';
// import datehandling from '@/components/datehandling';

export default {
  components: {
    VNodeEdge,
    VNodeGroup,
    VNodeNode,
    VNodeScreen,
  },
  props: {
    viData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    graph: null,
    groupNodes: false,
    graphType: 'basic',
    graphDir: 'right',
    arrowMarkers: [
      {
        id: 'arrow-end-missing_input',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: red',
      },
      {
        id: 'arrow-end-missing_output',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: red',
      },
      {
        id: 'arrow-end-formula',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightcoral',
      },
      {
        id: 'arrow-end-input_sensor_xform',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightcoral',
      },
      {
        id: 'arrow-end-input_external_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_external_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_direct_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_direct_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_direct_sensor_array',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_external_sensor_array',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightblue',
      },
      {
        id: 'arrow-end-input_time_sync',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: goldenrod',
      },
      {
        id: 'arrow-end-output_time_sync',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: goldenrod',
      },
      {
        id: 'arrow-end-output_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightsalmon',
      },
      {
        id: 'arrow-end-output_vector',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightsalmon',
      },
      {
        id: 'arrow-end-input_constant_sensor',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_external_instrument_attribute',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_direct_instrument_attribute',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_external_instrument_capture',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_direct_instrument_capture',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_sensor_attribute',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-input_external_sensor_attribute',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-vector_constant',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: palegreen',
      },
      {
        id: 'arrow-end-sensor_baseline',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: aqua',
      },
      {
        id: 'arrow-end-vector_baseline',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: aqua',
      },
      {
        id: 'arrow-end-sensor_attribute_input',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: lightsteelblue',
      },
      {
        id: 'arrow-end-instrument_attribute_input',
        type: 'arrow-end',
        scale: 0.33,
        style: 'fill: rgb(139, 174, 219)',
      },
    ],

    showAllNodes: false,
  }),
  watch: {
    viData() {
      this.buildGraph();
    },
  },
  beforeMount() {
    this.graph = new VNodeGraph();
  },
  mounted() {
    this.buildGraph();
  },
  methods: {
    buildGraph() {
      this.graph.reset();
      if (!this.viData || this.viData.VirtualInstruments.length === 0) {
        return;
      }

      const defaultNodeProp = {
        width: 100,
        height: 100,
      };

      const VirtualInstrument = this.viData.VirtualInstruments[0];
      const InternalBindings = this.viData.Bindings
        .filter((x) => x.BindingType === 'Internal');
        /*
      const InputBindings = this.viData.Bindings
        .filter((x) => x.BindingType === 'Input')
        .filter((x) => !InternalBindings.find((y) => y.SourceSymbol === x.SourceSymbol));
      const OutputBindings = this.viData.Bindings
        .filter((x) => x.BindingType === 'Output')
        .filter((x) => !InternalBindings.find((y) => y.SourceSymbol === x.SourceSymbol));
*/
      // eslint-disable-next-line no-unused-vars
      const formulaNodes = VirtualInstrument.Formulas.map((x) => {
        const z = this.graph.createNode(x);
        z.nodeType = 'formula';
        z.width = defaultNodeProp.width;
        z.height = defaultNodeProp.height;
        return z;
      });

      // eslint-disable-next-line no-unused-vars
      const inputSensorNodes = this.viData.InputRules
        .filter((x) => formulaNodes.find((u) => u.SymbolTable.find((v) => v.Name === x.Symbol)))
        .filter((x) => !(x.RuleType === 'ConstantSensor'
        || x.RuleType === 'ConstantVector'
        || x.RuleType === 'InstrumentAttribute'
        || x.RuleType === 'SensorAttribute'
        || x.RuleType === 'ExternalSensorAttribute'
        || x.RuleType === 'InstrumentCapture'))
        .map((x) => {
          const z = this.graph.createNode(x);
          if (x.RuleType === 'DirectSensor') {
            z.nodeType = 'input_direct_sensor';
          } else if (x.RuleType === 'DirectSensorArray') {
            z.nodeType = 'input_direct_sensor_array';
          } else if (x.RuleType === 'ExternalSensor') {
            z.nodeType = 'input_external_sensor';
          } else if (x.RuleType === 'ExternalSensorArray') {
            z.nodeType = 'input_external_sensor_array';
          } else if (x.RuleType === 'DirectVector') {
            z.nodeType = 'input_direct_vector';
          } else if (x.RuleType === 'ExternalVector') {
            z.nodeType = 'input_external_vector';
          } else if (x.RuleType === 'InternalBinding') {
            z.nodeType = 'input_internal_binding';
          } else {
            z.nodeType = 'unknown';
          }
          z.width = defaultNodeProp.width;
          z.height = defaultNodeProp.height;
          return z;
        });

      /*
        .concat(
          InternalBindings
            .filter((x) => formulaNodes.find((u) => u.SymbolTable.find((v) => v.Name === x.DestinationSymbol))) // Input of a formula
            .filter((x) => x.DestinationVi === VirtualInstrument.Id)
            .map((x) => {
              const z = this.graph.createNode(x);
              if (x.FormulaType === 'FLOAT') {
                z.nodeType = 'internal_input_sensor';
              } else {
                z.nodeType = 'internal_input_vector';
              }
              z.width = defaultNodeProp.width;
              z.height = defaultNodeProp.height;
              return z;
            }),
        )
        */

      const constantSensorNodes = this.viData.InputRules
        .filter((x) => formulaNodes.find((u) => u.SymbolTable.find((v) => v.Name === x.Symbol)))
        .filter((x) => x.RuleType === 'ConstantSensor'
        || x.RuleType === 'ConstantVector'
        || x.RuleType === 'InstrumentAttribute'
        || x.RuleType === 'SensorAttribute'
        || x.RuleType === 'ExternalSensorAttribute'
        || x.RuleType === 'InstrumentCapture')
        .map((x) => {
          const z = this.graph.createNode(x);
          if (x.RuleType === 'ConstantSensor') {
            z.nodeType = 'input_constant_sensor';
          } else if (x.RuleType === 'ConstantVector') {
            z.nodeType = 'input_constant_vector';
          } else if (x.RuleType === 'InstrumentAttribute') {
            if (x.InstrumentRule && x.InstrumentRule.length > 0) {
              z.nodeType = 'input_external_instrument_attribute';
            } else {
              z.nodeType = 'input_direct_instrument_attribute';
            }
          } else if (x.RuleType === 'InstrumentCapture') {
            if (x.InstrumentRule && x.InstrumentRule.length > 0) {
              z.nodeType = 'input_external_instrument_capture';
            } else {
              z.nodeType = 'input_direct_instrument_capture';
            }
          } else if (x.RuleType === 'SensorAttribute') {
            z.nodeType = 'input_sensor_attribute';
          } else if (x.RuleType === 'ExternalSensorAttribute') {
            z.nodeType = 'input_external_sensor_attribute';
          } else {
            z.nodeType = 'unknown';
          }
          z.width = defaultNodeProp.width;
          z.height = defaultNodeProp.height;
          return z;
        });

      const outputSensorNodes = this.viData.OutputRules
        .filter((x) => formulaNodes.find((u) => u.OutputSymbol === x.Symbol)) // Output of a formula
        .map((x) => {
          const z = this.graph.createNode(x);
          if (x.RuleType === 'CreateSensor') {
            z.nodeType = 'output_sensor';
          } else if (x.RuleType === 'CreateVector') {
            z.nodeType = 'output_vector';
          } else {
            z.nodeType = 'unknown';
          }
          z.width = defaultNodeProp.width;
          z.height = defaultNodeProp.height;
          return z;
        }).concat(
          InternalBindings
            .filter((x) => formulaNodes.find((u) => u.OutputSymbol === x.SourceSymbol)) // Output of a formula
            .map((x) => {
              const z = this.graph.createNode(x);
              if (x.FormulaType === 'FLOAT') {
                z.nodeType = 'internal_output_sensor';
              } else {
                z.nodeType = 'internal_output_vector';
              }
              z.width = defaultNodeProp.width;
              z.height = defaultNodeProp.height;
              return z;
            }),
        );

      // eslint-disable-next-line no-unused-vars
      const inputSensorTransformNodes = this.viData.InputRules
        .filter((x) => !!x.InputTransform)
        .map((x) => {
          const z = this.graph.createNode(x);
          z.nodeType = 'input_sensor_xform';
          z.width = defaultNodeProp.width;
          z.height = defaultNodeProp.height;
          return z;
        });

      const makeInputTimeSyncNode = (s) => {
        const inputTimeSyncAttr = {
          $InputNode: s,
          Symbol: s.Symbol || s.DestinationSymbol,
          Aggregation: s.InputAggregation ?? '???',
          scale: VirtualInstrument.InputTimeAxisScale ?? 'NONE',
          divisor: VirtualInstrument.InputTimeAxisDivisor,
          multiplier: VirtualInstrument.InputTimeAxisMultiplier,
          offset: VirtualInstrument.InputTimeAxisOffset,
        };
        if (!this.showAllNodes && inputTimeSyncAttr.scale === 'NONE') {
          return null;
        }
        const z = this.graph.createNode(inputTimeSyncAttr);
        z.nodeType = 'input_time_sync';
        z.width = defaultNodeProp.width;
        z.height = defaultNodeProp.height;
        return z;
      };
      const inputTimeSyncNodes = inputSensorNodes.map((s) => makeInputTimeSyncNode(s))
        // .concat(inputVectorNodes.map((s) => makeInputTimeSyncNode(s)))
        .filter((x) => x !== null);

      const makeOutputTimeSyncNode = (s) => {
        const formulaNode = formulaNodes
          .find((x) => x.OutputSymbol === s.Symbol || x.OutputSymbol === s.SourceSymbol);
        const outputTimeSyncAttr = {
          $OutputNode: s,
          $FormulaNode: formulaNode,
          Symbol: s.Symbol || s.SourceSymbol,
          Aggregation: formulaNode?.OutputAggregation ?? '???',
          scale: VirtualInstrument.OutputTimeAxisScale ?? 'NONE',
          divisor: VirtualInstrument.OutputTimeAxisDivisor,
          multiplier: VirtualInstrument.OutputTimeAxisMultiplier,
          offset: VirtualInstrument.OutputTimeAxisOffset,
        };
        if (!this.showAllNodes && outputTimeSyncAttr.scale === 'NONE') {
          return null;
        }
        const z = this.graph.createNode(outputTimeSyncAttr);
        z.nodeType = 'output_time_sync';
        z.width = defaultNodeProp.width;
        z.height = defaultNodeProp.height;
        return z;
      };
      const outputTimeSyncNodes = outputSensorNodes.map((s) => makeOutputTimeSyncNode(s))
        // .concat(outputVectorNodes.map((s) => makeOutputTimeSyncNode(s)))
        .filter((x) => x !== null);

      let missingInputNodes = [];
      for (let i = 0; i < VirtualInstrument.Formulas.length; i += 1) {
        const formula = VirtualInstrument.Formulas[i];
        // eslint-disable-next-line no-unused-vars
        missingInputNodes = missingInputNodes.concat(formula.SymbolTable
          // eslint-disable-next-line no-loop-func
          .filter((x) => !missingInputNodes.find((n) => x.Name === n.Name))
          .filter((x) => !inputSensorNodes.find((n) => x.Name === n.Symbol)) // External
          .filter((x) => !inputSensorNodes.find((n) => x.Name === n.DestinationSymbol)) // Internal
          // .filter((x) => !inputVectorNodes.find((n) => x.InputSymbol === n.Symbol))
          .filter((x) => !constantSensorNodes.find((n) => x.Name === n.Symbol))
          // .filter((x) => !baselineSensorNodes.find((n) => x.InputSymbol === n.Symbol))
          // .filter((x) => !constantVectorNodes.find((n) => x.InputSymbol === n.Symbol))
          // .filter((x) => !baselineVectorNodes.find((n) => x.InputSymbol === n.Symbol))
          // .filter((x) => !inputSensorAttributeNodes.find((n) => x.InputSymbol === n.Symbol))
          // .filter((x) => !inputInstrumentAttributeNodes.find((n) => x.InputSymbol === n.Symbol))
          .map((x) => {
            const z = this.graph.createNode(x);
            z.nodeType = 'missing_input';
            z.width = defaultNodeProp.width;
            z.height = defaultNodeProp.height;
            return z;
          }));
      }

      // eslint-disable-next-line no-unused-vars
      const missingOutputNodes = VirtualInstrument.Formulas
        .filter((x) => !outputSensorNodes.find((n) => x.OutputSymbol === n.Symbol)) // external
        .filter((x) => !outputSensorNodes.find((n) => x.OutputSymbol === n.SourceSymbol)) // internal
        // .filter((x) => !outputVectorNodes.find((n) => x.OutputSymbol === n.Symbol))
        .map((x) => {
          const z = this.graph.createNode({
            Symbol: x.OutputSymbol,
          });
          z.nodeType = 'missing_output';
          z.width = defaultNodeProp.width;
          z.height = defaultNodeProp.height;
          return z;
        });

      const makeArrow = (x, y, ypos, arrowType = 'hsmooth') => {
        const e = this.graph.createEdge(x, y, {
          srcNode: x,
          dstNode: y,
        });
        e.fromAnchor = { x: '100%', y: `${ypos}%` };
        e.toAnchor = { x: '0%', y: `${ypos}%` };
        e.type = arrowType;
        return e;
      };

      // Input to formula edges
      const totalFormulaInputs = inputSensorNodes.length
        + constantSensorNodes.length
        + missingInputNodes.length;
      /* + inputVectorNodes.length
            + baselineSensorNodes.length
            + constantVectorNodes.length
            + baselineVectorNodes.length
            + inputInstrumentAttributeNodes.length
            + inputSensorAttributeNodes.length
            + ; */
      inputSensorNodes.forEach((x, xi) => {
        const inOfft = 0;
        const xfrm = inputSensorTransformNodes
          .find((a) => a.Symbol === x.Symbol || a.Symbol === x.DestinationSymbol);
        const tSync = inputTimeSyncNodes
          .find((a) => a.Symbol === x.Symbol
                    || (a.Symbol === x.DestinationSymbol
                        && a.$InputNode.DestinationVi === x.DestinationVi));
        if (!xfrm) {
          if (tSync) {
            makeArrow(x, tSync, 50);
            formulaNodes
              .filter((y) => y.SymbolTable.find((n) => n.Name === x.Symbol || n.Name === x.DestinationSymbol))
              .forEach((y) => {
                makeArrow(tSync, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
              });
          } else {
            formulaNodes
              .filter((y) => y.SymbolTable.find((n) => n.Name === x.Symbol || n.Name === x.DestinationSymbol))
              .forEach((y) => {
                makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
              });
          }
        } else if (tSync) {
          makeArrow(x, xfrm, 50);
          makeArrow(xfrm, tSync, 50);
          formulaNodes
            .filter((y) => y.SymbolTable.find((n) => n.Name === x.Symbol || n.Name === x.DestinationSymbol))
            .forEach((y) => {
              makeArrow(tSync, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
            });
        } else {
          makeArrow(x, xfrm, 50);
          formulaNodes
            .filter((y) => y.SymbolTable.find((n) => n.Name === x.Symbol || n.Name === x.DestinationSymbol))
            .forEach((y) => {
              makeArrow(xfrm, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
            });
        }
      });

      constantSensorNodes.forEach((x, xi) => {
        const inOfft = inputSensorNodes.length;
        formulaNodes
          .filter((y) => y.SymbolTable.find((n) => n.Name === x.Symbol))
          .forEach((y) => {
            makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
          });
      });

      missingInputNodes.forEach((x, xi) => {
        const inOfft = inputSensorNodes.length
            + constantSensorNodes.length;
        /* + inputVectorNodes.length
              + constantSensorNodes.length
              + baselineSensorNodes.length
              + constantVectorNodes.length
              + baselineVectorNodes.length
              + inputSensorAttributeNodes.length
              + inputInstrumentAttributeNodes.length; */
        formulaNodes
          .filter((y) => y.SymbolTable.find((n) => n.Name === x.Name))
          .forEach((y) => {
            makeArrow(x, y, ((inOfft + xi + 1) * 100) / (totalFormulaInputs + 1));
          });
      });

      outputSensorNodes.forEach((y) => {
        const fNode = formulaNodes.find((x) => x.OutputSymbol === y.Symbol);
        const sNode = outputTimeSyncNodes.find((x) => x.Symbol === y.Symbol);
        if (fNode && sNode) {
          makeArrow(fNode, sNode, 50);
          makeArrow(sNode, y, 50);
        } else if (fNode) {
          makeArrow(fNode, y, 50);
        }
      });
      outputSensorNodes.forEach((y) => {
        const fNode = formulaNodes.find((x) => x.OutputSymbol === y.SourceSymbol);
        const sNode = outputTimeSyncNodes
          .find((x) => x.Symbol === y.SourceSymbol
                    && x.$OutputNode.DestinationVi === y.DestinationVi);
        if (fNode && sNode) {
          makeArrow(fNode, sNode, 50);
          makeArrow(sNode, y, 50);
        } else if (fNode) {
          makeArrow(fNode, y, 50);
        }
      });
      missingOutputNodes.forEach((y) => {
        const fNode = formulaNodes.find((x) => x.OutputSymbol === y.Symbol);
        makeArrow(fNode, y, 50);
      });

      this.graph.graphNodes({
        nodes: this.graph.nodes,
        edges: this.graph.edges,
        type: this.graphType,
        dir: this.graphDir,
        spacing: 100,
      });
    },
    nodeFitContent() {
      this.graph.graphNodes({
        nodes: this.graph.nodes,
        edges: this.graph.edges,
        type: this.graphType,
        dir: this.graphDir,
        spacing: 100,
      });
      this.$refs.screen.zoomNodes(this.graph.nodes);
    },

    nodeActionClick(actionType, node) {
      this.$emit('node-action-click', actionType, node);
    },
    getTooltip(actionType) {
      if (actionType === 'edit') {
        return this.$t('Edit');
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .screen {
    color: blue;
  }

  .formula-text-ellipsis {
    font-size: 80%;
  }
  .node-header {
    background-color: lightgrey;
    display: flex;
    text-align: center;
    margin: -10px -10px 0 -10px;
    padding: 0px 5px 0 5px;
    border-radius: 5px 5px 0px 0px;
    align-items: center;
  }
  .node-footer {
    display: flex;
    margin: 0px -10px -10px -10px;
    padding: 0px 0px 0 0px;
    border-radius: 0px 0px 5px 5px;
  }
  .node-inner-missing_input::v-deep .node-header {
    background-color: orange !important;
  }
  .node-inner-missing_output::v-deep .node-header {
    background-color: orange !important;
  }

  .node-header-icon {
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .node-header-icon-passive {
    color: gray;
  }

  .node-inner {
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .node-formula::v-deep .background {
    background-color: lightcoral;
  }
  .node-input_sensor_xform::v-deep .background {
    background-color: lightcoral;
  }
  .node-input_external_sensor::v-deep .background {
    background-color: lightblue;
  }
  .node-input_external_vector::v-deep .background {
    background-color: lightblue;
  }
  .node-input_direct_sensor::v-deep .background {
    background-color: lightblue;
  }
  .node-input_direct_vector::v-deep .background {
    background-color: lightblue;
  }
  .node-input_direct_sensor_array::v-deep .background {
    background-color: lightblue;
  }
  .node-input_external_sensor_array::v-deep .background {
    background-color: lightblue;
  }
  .node-input_vector::v-deep .background {
    background-color: lightblue;
  }
  .node-input_time_sync::v-deep .background {
    background-color: goldenrod;
  }
  .node-output_time_sync::v-deep .background {
    background-color: goldenrod;
  }
  .node-output_sensor::v-deep .background {
    background-color: lightsalmon;
  }
  .node-internal_output_sensor::v-deep .background {
    background-color: lightsalmon;
  }

  .node-output_vector::v-deep .background {
    background-color: lightsalmon;
  }
  .node-input_constant_sensor::v-deep .background {
    background-color: palegreen;
  }
  .node-input_external_instrument_attribute::v-deep .background {
    background-color: palegreen;
  }
  .node-input_direct_instrument_attribute::v-deep .background {
    background-color: palegreen;
  }
  .node-input_external_instrument_capture::v-deep .background {
    background-color: palegreen;
  }
  .node-input_direct_instrument_capture::v-deep .background {
    background-color: palegreen;
  }
  .node-input_sensor_attribute::v-deep .background {
    background-color: palegreen;
  }
  .node-input_external_sensor_attribute::v-deep .background {
    background-color: palegreen;
  }
  .node-vector_constant::v-deep .background {
    background-color: palegreen;
  }
  .node-sensor_baseline::v-deep .background {
    background-color: aqua;
  }
  .node-vector_baseline::v-deep .background {
    background-color: aqua;
  }
  .node-missing_input::v-deep .background {
    background-color: lightgray;
  }
  .node-missing_output::v-deep .background {
    background-color: lightgray;
  }
  .node-sensor_attribute_input::v-deep .background {
    background-color: lightsteelblue;
  }
  .node-instrument_attribute_input::v-deep .background {
    background-color: rgb(139, 174, 219);
  }

  .edge-formula {
    stroke: lightcoral !important;
    marker: url(#arrow-end-formula) !important;
  }
  .edge-input_sensor_xform {
    stroke: lightcoral !important;
    marker: url(#arrow-end-input_sensor_xform) !important;
  }
  .edge-input_external_sensor {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_external_sensor) !important;
  }
  .edge-input_external_vector {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_external_vector) !important;
  }
  .edge-input_direct_sensor {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_direct_sensor) !important;
  }
  .edge-input_direct_vector {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_direct_vector) !important;
  }
  .edge-input_direct_sensor_array {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_direct_sensor_array) !important;
  }
  .edge-input_external_sensor_array {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_external_sensor_array) !important;
  }
  .edge-input_vector {
    stroke: lightblue !important;
    marker: url(#arrow-end-input_vector) !important;
  }
  .edge-input_time_sync {
    stroke: goldenrod !important;
    marker: url(#arrow-end-input_time_sync) !important;
  }
  .edge-output_time_sync {
    stroke: goldenrod !important;
    marker: url(#arrow-end-output_time_sync) !important;
  }
  .edge-output_sensor {
    stroke: lightsalmon !important;
    marker: url(#arrow-end-output_sensor) !important;
  }
  .edge-output_vector {
    stroke: lightsalmon !important;
    marker: url(#arrow-end-output_vector) !important;
  }
  .edge-input_constant_sensor {
    stroke: palegreen !important;
    marker: url(#arrow-end-input_constant_sensor) !important;
  }
  .edge-input_external_instrument_attribute {
    stroke: palegreen !important;
    marker: url(#arrow-end-input_external_instrument_attribute) !important;
  }
  .edge-input_direct_instrument_attribute {
    stroke: palegreen !important;
    marker: url(#arrow-end-input_direct_instrument_attribute) !important;
  }
  .edge-input_external_instrument_capture {
    stroke: palegreen !important;
    marker: url(#arrow-end-input_external_instrument_capture) !important;
  }
  .edge-input_direct_instrument_capture {
    stroke: palegreen !important;
    marker: url(#arrow-end-input_direct_instrument_capture) !important;
  }
  .edge-input_sensor_attribute {
    stroke: palegreen !important;
    marker: url(#arrow-end-input_sensor_attribute) !important;
  }
  .edge-input_external_sensor_attribute {
    stroke: palegreen !important;
    marker: url(#arrow-end-input_external_sensor_attribute) !important;
  }
  .edge-vector_constant {
    stroke: palegreen !important;
    marker: url(#arrow-end-vector_constant) !important;
  }
  .edge-sensor_baseline {
    stroke: aqua !important;
    marker: url(#arrow-end-sensor_baseline) !important;
  }
  .edge-vector_baseline {
    stroke: aqua !important;
    marker: url(#arrow-end-vector_baseline) !important;
  }
  .edge-missing_input {
    stroke: red !important;
    marker: url(#arrow-end-missing_input) !important;
    stroke-dasharray: 5 5;
  }
  .edge-missing_output {
    stroke: red !important;
    marker: url(#arrow-end-missing_output) !important;
    stroke-dasharray: 5 5;
  }
  .edge-sensor_attribute_input {
    stroke: lightsteelblue !important;
    marker: url(#arrow-end-sensor_attribute_input) !important;
  }
  .edge-instrument_attribute_input {
    stroke: rgb(139, 174, 219) !important;
    marker: url(#arrow-end-instrument_attribute_input) !important;
  }

</style>
