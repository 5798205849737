<template>
  <v-card
    v-if="editorActive"
  >
    <v-card-title
      v-if="showSensorStep"
    >
      {{ $t('SelectBaselineSensor') }}
    </v-card-title>
    <!-- SENSOR SELECTION STEP -->
    <v-card-text
      v-if="showSensorStep"
    >
      <v-row>
        <v-col sm="6">
          <v-text-field
            v-model="form.searchSensorTerm"
            hint="Use * wildcard to specify *ending or *contains*, default to starting."
            :label="$t('sensor')"
            prepend-icon="mdi-magnify"
            append-icon="mdi-arrow-right-circle"
            @click:append="searchSensor"
            @keyup.enter="searchSensor"
          />
        </v-col>
        <v-col sm="6">
          <v-text-field
            v-model="form.searchInstrumentTerm"
            hint="Use * wildcard to specify *ending or *contains*, default to starting."
            label="instrument"
            prepend-icon="mdi-magnify"
            append-icon="mdi-arrow-right-circle"
            @click:append="searchSensor"
            @keyup.enter="searchSensor"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-virtual-scroll
      v-if="showSensorStep"
      :key="form.itemList.Id"
      :items="form.itemList"
      :item-height="28"
      height="260"
      @scroll.native="scrolling"
    >
      <template #default="{ item }">
        <v-list-item
          :key="item.Id"
          class="sensor-search-item pt-0 pb-0"
          style="min-height: 24px;"
          @click="selectSensor(item)"
        >
          <v-list-item-icon
            class="pt-0 pb-0 mt-0 mb-0"
          >
            <v-icon v-if="item.T == 'S'">
              mdi-alpha-s-box-outline
            </v-icon>
            <v-icon v-if="item.T == 'V'">
              mdi-alpha-v-box-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            {{ item.DisplayName || item.Name }}
          </v-list-item-content>
          <v-list-item-content
            class="pt-0 pb-0"
          >
            <v-badge
              :content="item.InstrumentName"
              inline
            />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>

    <!-- Approx date SELECTION STEP -->
    <v-card-title
      v-if="showDateStep"
    >
      {{ $t('SelectBaselineDay') }}
    </v-card-title>
    <v-date-picker
      v-if="showDateStep"
      v-model="form.selectedDate"
      full-width
      @input="selectDayOfData"
    />

    <!-- VALUE SELECTION STEP -->
    <v-card-title
      v-if="showDataStep"
    >
      {{ $t('SelectBaselineData') }}
    </v-card-title>
    <ag-grid-vue
      v-if="showDataStep"
      style="width: 100%; height: 400px;"
      class="ag-theme-balham-dark"
      :column-defs="form.columnDefs"
      :row-data="form.rowData"
      :row-selection="'single'"
      @grid-ready="onGridReady"
      @selection-changed="onGridSelectionChanged"
    />

    <!-- Validity range SELECTION STEP -->
    <v-card-title
      v-if="showRangeStep"
    >
      {{ $t('ValidityRange') }}
    </v-card-title>
    <v-card-subtitle
      v-if="showRangeStep"
    >
      &nbsp;
    </v-card-subtitle>
    <v-card-text
      v-if="showRangeStep"
    >
      <v-row>
        <div
          class="col-3"
        >
          {{ $t('Range') }}
        </div>
          &nbsp;
          &nbsp;
        <datetime-picker
          v-model="form.ValidFromTs"
          :label="$t('From')"
          :text-field-props="pickerTextFieldProps"
          :date-picker-props="pickerDateProps"
          :time-picker-props="pickerTimeProps"
        />
          &nbsp;
          &nbsp;
        <datetime-picker
          v-model="form.ValidToTs"
          :label="$t('To')"
          :text-field-props="pickerTextFieldProps"
          :date-picker-props="pickerDateProps"
          :time-picker-props="pickerTimeProps"
        />
      </v-row>
    </v-card-text>

    <slot />
  </v-card>
</template>

<script>
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { AgGridVue } from 'ag-grid-vue';
import DatetimePicker from '@/components/DatetimePicker.vue';
import UtcDateTimeCellRenderer from '@/components/UtcDateTimeCellRenderer.vue';
import importal from '@/api/importal';
import {
  addDays, formatISO, format, parse,
} from 'date-fns';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default {
  components: {
    AgGridVue,
    DatetimePicker,
    // eslint-disable-next-line vue/no-unused-components
    UtcDateTimeCellRenderer,
  },
  props: {
    init: {
      type: Object,
      default: () => {},
      required: true,
    },
    itemIncrement: {
      type: Number,
      default: () => 20,
      required: false,
    },
  },
  data: () => ({
    editor: {
      editorMode: null,
      node: {
        SensorId: null,
        SensorName: null,
        InstrumentName: null,
        nodeType: null,
      },
    },
    form: {
      searchSensorTerm: '',
      searchInstrumentTerm: '',
      itemList: [],
      selectedSensors: [],
      SensorId: null,
      SensorName: null,
      InstrumentName: null,
      VectorSensorId: null,
      VectorName: null,
      selectedDate: null,
      selectedRecord: null,
      columnDefs: null,
      rowData: null,
      ValidFromTs: null,
      ValidToTs: null,
      Value: null,
    },
    pickerTextFieldProps: {
      dense: true,
      clearable: true,
      class: 'col-4',
      style: 'font-size: 80%;',
    },
    pickerDateProps: {
      locale: 'en-ca',
    },
    pickerTimeProps: {
      format: '24hr',
    },
  }),
  computed: {
    editorActive() {
      return this.editor && this.editor.show && this.editor.node && (this.editor.node.nodeType === 'vector_baseline');
    },
    showSensors() {
      return this.editor.node.nodeType === 'sensor_baseline';
    },
    showVectors() {
      return this.editor.node.nodeType === 'vector_baseline';
    },
    sensorCount() {
      if (this.showSensors) {
        return this.itemIncrement.toString();
      }
      return '0';
    },
    sensorOffset() {
      return this.form.itemList.filter((x) => x.T === 'S').length.toString();
    },
    vectorCount() {
      if (this.showVectors) {
        return this.itemIncrement.toString();
      }
      return '0';
    },
    vectorOffset() {
      return this.form.itemList.filter((x) => x.T === 'V').length.toString();
    },
    showSensorStep() {
      return (!this.form.VectorSensorId || !this.form.VectorName)
        && !this.form.selectedDate && !this.form.selectedRecord;
    },
    showDateStep() {
      return this.form.VectorSensorId && this.form.VectorName
        && !this.form.selectedDate && !this.form.selectedRecord;
    },
    showDataStep() {
      return this.form.VectorSensorId && this.form.VectorName
        && this.form.selectedDate && !this.form.selectedRecord;
    },
    showRangeStep() {
      return this.form.VectorSensorId && this.form.VectorName
        && this.form.selectedDate && this.form.selectedRecord;
    },
    jsonSettings() {
      return JSON.stringify(this.buildSettings());
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.initialize();
      }
    },
    editor() {
      if (this.editorActive) {
        this.$emit('editor-update', this.editor);
      }
    },
    'form.ValidFromTs': function formValidFromTs() {
      this.validXTsInput();
    },
    'form.ValidToTs': function formValidFromTs() {
      this.validXTsInput();
    },
  },
  async created() {
    this.scrolling = debounce(this.scrolling);
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.editor = this.init;
      this.form.VectorSensorId = this.editor.node.VectorSensorId;
      this.form.VectorName = this.editor.node.VectorName;
      this.editor.node.Symbol = this.editor.node.Symbol ?? this.editor.node.InputSymbol;
      if (this.editor.node.ValidFromTs) {
        this.form.ValidFromTs = this.editor.node.ValidFromTs;
      } else {
        this.form.ValidFromTs = null;
      }
      if (this.editor.node.ValidToTs) {
        this.form.ValidToTs = this.editor.node.ValidToTs;
      } else {
        this.form.ValidToTs = null;
      }
      if (this.editor.node.SourceTs && this.editor.node.VectorSensorId
          && this.editor.node.VectorName) {
        this.form.selectedRecord = JSON.parse(`{ "ts": "${formatISO(this.editor.node.SourceTs)}", "value": { "_si": ${this.editor.node.SamplingInterval}, "_so": ${this.editor.node.SamplingOffset}, "value": ${JSON.stringify(this.editor.node.Value)} } }`);
      } else {
        this.form.selectedRecord = null;
      }
      if (this.editor.node.SourceTs) {
        this.form.selectedDate = format(this.editor.node.SourceTs, 'yyyy-MM-dd');
      } else {
        this.form.selectedDate = null;
      }
      const query = `SensorList?so=0&sc=${this.sensorCount}&vo=0&vc=${this.vectorCount}`
        + `&searchSensor=${this.form.searchSensorTerm}`
        + `&searchInstrument=${this.form.searchInstrumentTerm}`;
      this.form.itemList = await (await importal.get(query)).data;
    },
    async scrolling(event) {
      const element = event.currentTarget || event.target;
      if (element && element.scrollHeight - element.scrollTop === element.clientHeight) {
        // this.$emit('scroll-end');
        await this.showMore();
      }
    },
    async showMore() {
      this.spamfree = true;
      const newItems = await (await importal.get(`SensorList?so=${this.sensorOffset}&sc=${this.sensorCount}`
        + `&vo=${this.vectorOffset}&vc=${this.vectorCount}`
        + `&searchSensor=${this.form.searchSensorTerm}`
        + `&searchInstrument=${this.form.searchInstrumentTerm}`)).data;
      while (newItems.length > 0) {
        this.form.itemList.push(newItems[0]);
        newItems.shift();
      }
      this.spamfree = false;
    },
    async searchSensor() {
      this.form.itemList = [];
      this.form.itemList = await (await importal.get(`SensorList?so=0&sc=${this.sensorCount}`
        + `&vo=0&vc=${this.vectorCount}`
        + `&searchSensor=${this.form.searchSensorTerm}`
        + `&searchInstrument=${this.form.searchInstrumentTerm}`)).data;
    },
    selectSensor(item) {
      if (item.T === 'S') {
        this.form.SensorId = item.Id;
        this.form.SensorName = item.Name;
        this.form.InstrumentName = item.InstrumentName;
        // this.$emit('editor-update', this.editor);
      } else if (item.T === 'V') {
        this.form.VectorSensorId = item.Id;
        this.form.VectorName = item.Name;
        this.form.InstrumentName = item.InstrumentName;
        // this.$emit('editor-update', this.editor);
      }
    },
    selectDayOfData() {
    },

    buildSettings() {
      return {
        formulas: [{
          symbol: 'value',
          formula_text: '=value;',
          output_aggregation: 'ANY',
        },
        ],
        input_filter: [{
          time_range: {
            start: parse(this.form.selectedDate, 'yyyy-MM-dd', new Date()),
            end: addDays(parse(this.form.selectedDate, 'yyyy-MM-dd', new Date()), 1),
          },
        },
        ],
        input_time_axis: {
          round_to: {
            scale: 'NONE',
            multiplier: 1,
            divisor: 1,
          },
        },
        output_time_axis: {
          round_to: {
            scale: 'NONE',
            multiplier: 1,
            divisor: 1,
          },
        },
        sensor_selection: [],
        vector_selection: [{
          symbol: 'value',
          vector_sensor_id: this.form.VectorSensorId,
          vector_name: this.form.VectorName,
          input_aggregation: 'ANY',
        },
        ],
      };
    },

    async onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.form.rowData = null;

      const data = await (await importal.post('SensorQuery', this.jsonSettings)).data;

      this.form.columnDefs = [];

      data.Columns.forEach((col) => {
        const defChild = {
          field: col.ColumnName,
          headerName: col.ColumnName,
          sortable: true,
          resizable: true,
        };

        if (col.ColumnName === 'ts') {
          defChild.cellRenderer = 'UtcDateTimeCellRenderer';
        }

        this.form.columnDefs.push(defChild);
      });

      const thisData = [];
      for (let i = 0; i < data.Rows.length; i += 1) {
        const obj = {};
        for (let j = 0; j < data.Columns.length; j += 1) {
          obj[data.Columns[j].ColumnName] = data.Rows[i][j];
        }
        thisData.push(obj);
      }
      this.form.rowData = thisData;

      if (thisData.length === 0) {
        this.form.selectedDate = null;
      }
    },
    onGridSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.form.selectedRecord = selectedRows[0];
      } else {
        this.form.selectedRecord = null;
      }
    },
    validXTsInput() {
      if (this.form.ValidFromTs && this.form.ValidToTs) {
        this.editor.VectorSensorId = this.form.VectorSensorId;
        this.editor.VectorName = this.form.VectorName;
        this.editor.SourceTs = this.form.selectedRecord.ts;
        this.editor.Value = this.form.selectedRecord.value.value;
        // eslint-disable-next-line no-underscore-dangle
        this.editor.SamplingInterval = this.form.selectedRecord.value._si;
        // eslint-disable-next-line no-underscore-dangle
        this.editor.SamplingOffset = this.form.selectedRecord.value._so;
        this.editor.ValidFromTs = this.form.ValidFromTs;
        this.editor.ValidToTs = this.form.ValidToTs;
        this.$emit('editor-update', this.editor);
      }
    },
  },
  i18n: {
    messages: {
      en: {
        SelectBaselineSensor: 'Select Baseline Sensor',
        Range: 'Range',
        SelectBaselineDay: 'Select Baseline Day',
        sensor: 'sensor',
        From: 'From',
        To: 'To',
        Value: 'Value',
        SelectBaselineData: 'Select Baseline Data',
        ValidityRange: 'Validity Range',
      },
      fr: {
        SelectBaselineSensor: 'Sélectionner le capteur de référence',
        Range: 'Étendue',
        SelectBaselineDay: 'Sélectionner le jour de référence',
        sensor: 'capteur',
        From: 'De',
        To: 'À',
        Value: 'Valeur',
        SelectBaselineData: 'Sélectionner les données de référence',
        ValidityRange: 'Étendue de validité',
      },
    },
  },
};
</script>

<style>

</style>
