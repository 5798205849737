<template>
  <div>
    <DataCoverageChart :site="params.data" />
  </div>
</template>

<script>
import DataCoverageChart from './DataCoverageChart.vue';

export default {
  components: {
    DataCoverageChart,
  },
};
</script>
