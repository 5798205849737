<template>
  <dashboard-stack-single-widget-host
    :settings="settings"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import datehandling from '@/components/datehandling';
import DashboardStackSingleWidgetHost from '@/dashboard/components/DashboardStackSingleWidgetHost.vue';

export default {
  components: { DashboardStackSingleWidgetHost },
  props: {
    validFromTs: {
      type: Date,
      default: () => null,
      required: true,
    },
    validToTs: {
      type: Date,
      default: () => null,
      required: true,
    },
    node: {
      type: Object,
      default: () => ({}),
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters('app', ['pleaseWait', 'timezone']),

    settings() {
      return this.buildSettings();
    },
  },
  methods: {
    buildSettings() {
      return {
        user: {
          timezone: this.timezone,
          selectedSensors: [{
            T: 'V',
            Id: this.node.VectorSensorId,
            Name: this.node.VectorName,
            InstrumentName: this.node.InstrumentName,
            InstrumentId: null,
            Aggregation: this.node.InputAggregation,
          },
          ],
          visualisationType: 'plotly',
          visualisationSubType: 'vector+scatter+line',
          DashboardsWidgetId: this.widgetId,
        },
        data: {
          formulas: [{
            symbol: 'value',
            formula_text: '=value;',
            output_aggregation: this.node.InputAggregation,
          },
          ],
          input_filter: [{
            time_range: {
              start: datehandling.formatForApi(this.validFromTs),
              end: datehandling.formatForApi(this.validToTs),
            },
          },
          ],
          input_time_axis: {
            round_to: {
              scale: (this.info?.exec?.attributes?.find((x) => x.Name === 'input_time_axis.round_to.scale')?.ValueStr ?? 'NONE'),
              multiplier: (this.info?.exec?.attributes?.find((x) => x.Name === 'input_time_axis.round_to.multiplier')?.ValueInt ?? 1),
              divisor: (this.info?.exec?.attributes?.find((x) => x.Name === 'input_time_axis.round_to.divisor')?.ValueInt ?? 1),
            },
          },
          output_time_axis: {
            round_to: {
              scale: 'NONE',
              multiplier: 1,
              divisor: 1,
            },
          },
          sensor_selection: [],
          vector_selection: [{
            symbol: 'value',
            vector_sensor_id: this.node.VectorSensorId,
            vector_name: this.node.VectorName,
            input_aggregation: this.node.InputAggregation,
          }],
        },
        visualization: {
          alias: [JSON.parse(`{ "value": "${this.node.InstrumentName}.${this.node.VectorName}"}`)],
          graphType: 'scatter',
          graphStyle: 'lines',
          graphOptions: {
            x_axis: [{
              value: 'index',
            },
            ],
            y_axis: [{
              value: 'value',
            },
            ],
            groupby: [{
              ts: 'value',
            }],
          },
        },
      };
    },
  },
};
</script>

<style>

</style>
