import EquipmentList from './components/EquipmentList.vue';
import EquipmentFormModal from './components/EquipmentFormModal.vue';

export default [
  {
    path: '/Equipment/Equipments',
    name: 'Equipment.Equipments.List',
    component: EquipmentList,
    children: [
      {
        path: '/Equipment/Equipments/Create',
        name: 'Equipment.Equipments.Create',
        component: EquipmentFormModal,
      },
      {
        path: '/Equipment/Equipments/:id',
        name: 'Equipment.Equipments.Update',
        component: EquipmentFormModal,
        props: true,
      },
    ],
  },
];
