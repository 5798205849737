import i18n from '@/plugins/i18n';
import api from '@/api/importal';

const state = {
  equipment: {
    EquipmentTypeId: null,
    DuplicateOfPeNumber: null,
    PeNumber: null,
    SerialNumber: null,
    Tagged: null,
    Retired: null,
    MobileEquipmentIdentifierType: null,
    MobileEquipmentIdentifier: null,
    NetworkType: null,
    PhoneNumber: null,
    AccountProvider: null,
    AccountIsManagedByGenivar: null,
    DefaultAveragingInterval: null,
    LoggerChannels: [],
    LoggerPasswords: null,
    IsPainted: null,
    Model: {
      ModelId: null,
      ManufacturerName: null,
      ModelName: null,
      BoomLength: null,
      PostHeight: null,
      WindvaneDeadband: null,
      TowerHeight: null,
    },
  },
  equipments: [],
  availablePeNumber: {},
  validOwnerShip: null,
  equipmentType: null,
  sensorType: null,
  models: null,
  channelCode: null,
  mobileEquipmentIdType: null,
  networkType: null,
  cellularAccountProvider: null,
  owners: [],
  tenants: [],
};

const actions = {
  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('EquipmentList');
    commit('equipments', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async validatePeNumber({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.post('ValidatePeNumber', {
      PeNumber: Number(payload),
    });
    commit('availablePeNumber', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async validateOwnerShip({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.post('ValidateOwnerShip', {
      PeNumber: Number(payload.PeNumber),
      TenantId: payload.Tenant.TenantId,
    });
    commit('validOwnerShip', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api
      .post('EquipmentCreate', {
        PeNumber: Number(payload.PeNumber),
        EquipmentTypeId: payload.EquipmentTypeId,
        SerialNumber: payload.SerialNumber,
        Tagged: (payload.Tagged != null ? payload.Tagged : false),
        Retired: (payload.Retired != null ? payload.Retired : false),
        ModelId: payload.Model.ModelId,
        MobileEquipmentIdentifierType: payload.MobileEquipmentIdentifierType,
        MobileEquipmentIdentifier: payload.MobileEquipmentIdentifier,
        NetworkType: payload.NetworkType,
        PhoneNumber: payload.PhoneNumber,
        CellularAccountProviderId: (payload.AccountProvider != null ? payload.AccountProvider.CellularAccountProviderId : null),
        AccountIsManagedByGenivar: (payload.AccountIsManagedByGenivar != null ? payload.AccountIsManagedByGenivar != null : false),
        DefaultAveragingInterval: payload.DefaultAveragingInterval,
        LoggerChannels: payload.LoggerChannels,
        LoggerPasswords: payload.LoggerPasswords,
        IsPainted: (payload.IsPainted != null ? payload.IsPainted : false),
        SensorType: payload.Model.SensorType,
        Tenant: payload.Tenant,
      });
    commit('app/pleaseWait', false, { root: true });
    commit('init');
    await dispatch('list');
  },

  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('init');
    const response = await api.get(`EquipmentGet?id=${payload.id}`);
    commit('equipment', response.data);
    state.equipment.AccountProvider = {
      CellularAccountProviderId: response.data.CellularAccountProviderId,
    };
    commit('owners', response.data.equipmentOwnership);
    commit('app/pleaseWait', false, { root: true });
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api
      .put('EquipmentUpdate', {
        PeNumber: parseFloat(payload.PeNumber),
        EquipmentTypeId: payload.EquipmentTypeId,
        SerialNumber: payload.SerialNumber,
        Tagged: (payload.Tagged != null ? payload.Tagged : false),
        Retired: (payload.Retired != null ? payload.Retired : false),
        ModelId: payload.Model.ModelId,
        MobileEquipmentIdentifierType: payload.MobileEquipmentIdentifierType,
        MobileEquipmentIdentifier: payload.MobileEquipmentIdentifier,
        NetworkType: payload.NetworkType,
        PhoneNumber: payload.PhoneNumber,
        CellularAccountProviderId: (payload.AccountProvider != null ? payload.AccountProvider.CellularAccountProviderId : null),
        AccountIsManagedByGenivar: (payload.AccountIsManagedByGenivar != null ? payload.AccountIsManagedByGenivar != null : false),
        DefaultAveragingInterval: payload.DefaultAveragingInterval,
        LoggerChannels: payload.LoggerChannels,
        LoggerPasswords: payload.LoggerPasswords,
        IsPainted: (payload.IsPainted != null ? payload.IsPainted : false),
        SensorType: payload.Model.SensorType,
        Tenant: payload.Tenant,
      });
    commit('app/pleaseWait', false, { root: true });
    commit('init');
    await dispatch('list');
  },

  async saveProvider({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('CellularAccountProvidersCreate', payload);
    commit('app/pleaseWait', false, { root: true });
    commit('cellularAccountProvider', null);
    await dispatch('getCellularAccountProviderList');
  },
  async getEquipmentType({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('EquipmentTypeList');
    const { data } = response;
    data.forEach((equipment) => {
      // eslint-disable-next-line no-param-reassign
      equipment.EquipmentTypeNameI18N = i18n.t('Equipments.EquipmentType.'.concat(equipment.EquipmentTypeName));
    });
    commit('app/pleaseWait', false, { root: true });
    commit('equipmentType', data);
  },
  async getNetworkType({ commit, state }) {
    if (state.networkType == null) {
      commit('app/pleaseWait', true, { root: true });
      const response = await api.get('NetworkTypeList');
      commit('networkType', response.data);
      commit('app/pleaseWait', false, { root: true });
    }
  },
  async getMobileEquipmentIdType({ commit, state }) {
    if (state.mobileEquipmentIdType == null) {
      commit('app/pleaseWait', true, { root: true });
      const response = await api.get('MobileEquipmentIdTypeList');
      commit('mobileEquipmentIdType', response.data);
      commit('app/pleaseWait', false, { root: true });
    }
  },
  async getCellularAccountProviderList({ commit, state }) {
    if (state.cellularAccountProvider == null) {
      commit('app/pleaseWait', true, { root: true });
      const response = await api.get('CellularAccountProvidersList');
      commit('cellularAccountProvider', response.data);
      commit('app/pleaseWait', false, { root: true });
    }
  },
  async getSensorType({ commit, state }) {
    if (state.sensorType == null) {
      commit('app/pleaseWait', true, { root: true });
      const response = await api.get('SensorTypeList');
      const { data } = response;
      data.forEach((sensor) => {
        // eslint-disable-next-line no-param-reassign
        sensor.SensorTypeNameI18N = i18n.t('Equipments.SensorType.'.concat(sensor.Name));
      });
      commit('sensorType', data);
      commit('app/pleaseWait', false, { root: true });
    }
  },
  async getNextPeNumber({ commit, state }) {
    if (state.equipment.PeNumber == null) {
      commit('app/pleaseWait', true, { root: true });
      const response = await api.get('GetNextPeNumber');
      commit('app/pleaseWait', false, { root: true });
      commit('PeNumber', response.data);
    }
  },
  async getModels({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`ModelListByEquipmentType?equipmentType=${payload}`);
    commit('app/pleaseWait', false, { root: true });
    commit('models', response.data);
  },

  async getTenants({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const tenants = await api.get('EquipmentTenantsList');
    commit('tenants', tenants.data);
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  equipment(state, payload) {
    state.equipment = payload;
  },
  equipments(state, payload) {
    state.equipments = payload;
  },
  owners(state, payload) {
    state.owners = payload;
  },
  tenants(state, payload) {
    state.tenants = payload;
  },
  provider(state, payload) {
    state.provider = payload;
  },
  providerName(state, payload) {
    state.provider.ProviderName = payload;
  },
  equipmentType(state, payload) {
    state.equipmentType = payload;
  },
  availablePeNumber(state, payload) {
    state.availablePeNumber = payload;
  },
  validOwnerShip(state, payload) {
    state.validOwnerShip = payload;
  },
  sensorType(state, payload) {
    state.sensorType = payload;
  },
  networkType(state, payload) {
    state.networkType = payload;
  },
  cellularAccountProvider(state, payload) {
    state.cellularAccountProvider = payload;
  },
  mobileEquipmentIdType(state, payload) {
    state.mobileEquipmentIdType = payload;
  },
  PeNumber(state, payload) {
    state.equipment.PeNumber = String(payload).padStart(5, '0');
  },
  models(state, payload) {
    state.models = payload;
  },
  init(state) {
    state.equipment.PeNumber = null;
    state.equipment.EquipmentTypeId = null;
    state.equipment.DuplicateOfPeNumber = null;
    state.equipment.SerialNumber = null;
    state.equipment.Tagged = null;
    state.equipment.Retired = null;
    state.equipment.MobileEquipmentIdentifierType = null;
    state.equipment.MobileEquipmentIdentifier = null;
    state.equipment.NetworkType = null;
    state.equipment.PhoneNumber = null;
    state.equipment.AccountProvider = null;
    state.equipment.AccountIsManagedByGenivar = null;
    state.equipment.DefaultAveragingInterval = null;
    state.equipment.LoggerChannels = [];
    state.equipment.LoggerPasswords = null;
    state.equipment.IsPainted = null;
    state.equipment.Model.ModelId = null;
    state.equipment.Model.ManufacturerName = null;
    state.equipment.Model.ModelName = null;
    state.equipment.Model.BoomLength = null;
    state.equipment.Model.PostHeight = null;
    state.equipment.Model.WindvaneDeadband = null;
    state.equipment.Model.TowerHeight = null;
  },
  channelCode(state, payload) {
    state.channelCode = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
