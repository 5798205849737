<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card-text>
    <v-container fluid>
      <v-row>
        <div class="col-4">
          <p>
            {{ $t('WindServer.Sites.LoggerConfiguration.DataImportSettings.StartDate') }}
            {{ formattedStartDate }}
          </p>
          <p>
            {{ $t('WindServer.Sites.LoggerConfiguration.DataImportSettings.EndDate') }}
            {{ formattedEndDate }}
          </p>
        </div>
        <div
          class="col-4"
        >
          <p v-if="!isEditable">
            {{ $t('WindServer.Sites.LoggerConfiguration.DataImportSettings.Note') }}
          </p>
        </div>
        <div
          v-if="isEditable"
          class="col-4"
        >
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class=""
                v-bind="attrs"
                v-on="on"
                @click="editData();"
              >
                <i class="v-icon mdi mdi-pencil" />
              </v-btn>
            </template>
            <span>  {{ $t('Edit') }} </span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                v-bind="attrs"
                v-on="on"
                @click="deleteData();"
              >
                <i class="v-icon mdi mdi-delete-forever" />
              </v-btn>
            </template>
            <span>  {{ $t('Delete') }} </span>
          </v-tooltip>
        </div>
        <div
          v-else
          class="col-4"
        >
          <p>
            <a
              @click="endConfig"
            >
              {{ $t('WindServer.Sites.LoggerConfiguration.DataImportSettings.EndAt') }}
              {{ formattedStartDate }}
            </a>
          </p>
          <p>
            <a
              @click="replaceConfig"
            >
              {{ $t('WindServer.Sites.LoggerConfiguration.DataImportSettings.Replace') }}
            </a>
          </p>
        </div>
      </v-row>
      <v-row>
        <v-text-field
          v-model="item.Details"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.Details')"
          class="col-3"
        />
        <v-select
          v-model="item.TimeStampId"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.Timestamps')"
          :items="timeStamps"
          item-text="Name"
          item-value="TimeStampId"
          class="col-3"
        />
        <v-text-field
          v-model="item.DateTimeDataFormat"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.DateTimeDataFormat')"
          class="col-3"
        />
        <v-select
          v-model="item.FileTypeId"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.FileType')"
          :items="fileTypes"
          item-text="Name"
          item-value="FileTypeId"
          class="col-3"
        />
        <v-text-field
          v-model="item.Delimiter"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.Delimiter')"
          class="col-3"
        />
        <v-text-field
          v-model="item.SkipLines"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.SkipLines')"
          type="number"
          class="col-3"
        />
        <v-text-field
          v-model="item.LinesPerRecord"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.LinesPerRecord')"
          type="number"
          class="col-3"
        />
        <v-text-field
          v-model="item.IntervalLength"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.IntervalLength')"
          type="number"
          class="col-3"
        />
        <v-text-field
          v-model="item.RowPattern"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.RowPattern')"
          class="col-3"
        />
        <v-text-field
          v-model="item.MissingDataValues"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.MissingDataValues')"
          class="col-3"
        />
        <v-text-field
          v-model="item.FileTag"
          :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.FileTag')"
          class="col-3"
        />
      </v-row>
      <v-row>
        <div
          class="col-6"
          style="height: 200px"
        >
          <TimeAdjustmentList
            :id="item.DataFileImportSettingId"
            :is-editable="isEditable"
          />
        </div>
        <div
          class="col-6"
          style="height: 200px"
        >
          <TextReplacementList
            :id="item.DataFileImportSettingId"
            :is-editable="isEditable"
          />
        </div>
      </v-row>
      <v-row>
        <div
          class="col-12"
          style="height: 410px; margin-top: 70px; margin-bottom: 100px;"
        >
          <ColumnList
            :id="item.DataFileImportSettingId"
            :is-editable="isEditable"
          />
        </div>
      </v-row>
      <v-row>
        <div
          class="col-12"
          style="height: 200px; margin-bottom: 100px;"
        >
          <EmailImportRulesList
            :id="item.DataFileImportSettingId"
            :startingDate="item.StartingDate"
            :is-editable="isEditable"
          />
        </div>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import TimeAdjustmentList from './TimeAdjustmentList.vue';
import TextReplacementList from './TextReplacementList.vue';
import ColumnList from './ColumnList.vue';
import EmailImportRulesList from './EmailImportRulesList.vue';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

export default {
  components: {
    TimeAdjustmentList,
    TextReplacementList,
    ColumnList,
    EmailImportRulesList,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['timeStamps', 'fileTypes', 'siteConfiguration']),
    dateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    formattedStartDate() {
      return this.item.StartingDate ? format(this.item.StartingDate, this.dateTimeFormat) : '';
    },
    formattedEndDate() {
      return this.item.EndingDate ? format(this.item.EndingDate, this.dateTimeFormat) : '';
    },
    isEditable() {
      return this.item.StartingDate.getTime() === this.siteConfiguration.StartingDate.getTime();
    },
  },
  methods: {
    async editData() {
      await this.$store.dispatch('site/loggerConfigurations/dataFileImport', this.item);
    },
    async deleteData() {
      await this.$store.dispatch('site/loggerConfigurations/removeDataFileImport', { id: this.item.DataFileImportSettingId });
    },
    async endConfig() {
      await this.$store.dispatch('site/loggerConfigurations/endDataFileImport', { id: this.item.DataFileImportSettingId });
    },
    async replaceConfig() {
      await this.$store.dispatch('site/loggerConfigurations/replaceDataFileImport', { id: this.item.DataFileImportSettingId });
    },
  },
};
</script>
