<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <server-side-data-grid
      :show-new-button="$auth.roles.includes('Web.BaseLayer.Create')"
      :grid-source="sources.GsMapManagement"
      :refresh="refresh"
      :row-data-init="mapRowdata"
      :toolbar-buttons="toolbarButtons"
      @onNewButtonClicked="onNewMap"
    />
    <map-dialog
      v-model="mapDialog.show"
      :map="mapDialog.map"
      :isCreate="mapDialog.isCreate"
      :has-image="mapDialog.hasImage"
      @update="updateMap"
    />
    <confirm-dialog
      v-model="deleteDialog.show"
      :title="$t('ConfirmDeletionMessage', { Action: $t('Delete'), Name: deleteDialog.name })"
      @on-accept="deleteMap()"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import GsMapManagement from './gridsources/gsmapmanagement';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import MapDialog from './MapDialog.vue';

export default {
  name: 'MapManagementView',
  components: {
    ConfirmDialog,
    MapDialog,
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        GsMapManagement: null,
      },

      refresh: 0,
      toolbarButtons: [],

      mapDialog: {
        show: false,
        map: null,
        isCreate: false,
        hasImage: true,
      },
      deleteDialog: {
        show: false,
        id: null,
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),
    ...mapState('analytics', ['mapRowdata']),
  },
  watch: {
    async tenantId() {
      this.onRefresh();
    },
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.onRefresh();
      }
    },
  },
  created() {
    this.sources.GsMapManagement = new GsMapManagement(this, {
      onNewMap: () => this.onNewMap(),
      onEditMap: (data) => this.onEditMap(data),
      onDeleteMap: (data) => this.onDeleteMap(data),
      onEditImage: (data) => this.onEditImage(data),
    });
  },
  async mounted() {
    this.$store.dispatch('analytics/mapList');

    this.toolbarButtons = [
      {
        tooltip: this.$t('Refresh'),
        icon: 'mdi-refresh',
        enabled: true,
        click: () => { this.onRefresh(); },
      },
    ];
  },
  methods: {
    onRefresh() {
      this.refresh += 1;
      this.$store.dispatch('analytics/mapList');
    },
    onNewMap() {
      this.mapDialog.show = true;
      this.mapDialog.isCreate = true;
      this.mapDialog.hasImage = true;
      this.mapDialog.map = null;
    },
    onEditMap(data) {
      this.mapDialog.show = true;
      this.mapDialog.isCreate = false;
      this.mapDialog.hasImage = false;
      this.mapDialog.map = {
        BaseLayerId: data.BaseLayerId,
        Name: data.Name,
        Group: data.Group,
        AccessGroupId: data.AccessGroupId,
      };
    },
    onEditImage(data) {
      this.mapDialog.show = true;
      this.mapDialog.isCreate = false;
      this.mapDialog.hasImage = true;
      this.mapDialog.map = {
        BaseLayerId: data.BaseLayerId,
      };
    },
    async updateMap(values) {
      if (this.mapDialog.isCreate) {
        await this.$store.dispatch('analytics/mapCreate', values).then(() => {
          this.mapDialog.show = false;
        });
      } else if (!this.mapDialog.hasImage) {
        await this.$store.dispatch('analytics/mapUpdate', values).then(() => {
          this.mapDialog.show = false;
        });
      } else {
        await this.$store.dispatch('analytics/uploadImage', values).then(() => {
          this.mapDialog.show = false;
        });
      }
    },
    onDeleteMap(data) {
      this.deleteDialog.name = data.Name;
      this.deleteDialog.id = data.BaseLayerId;
      this.deleteDialog.show = true;
    },
    async deleteMap() {
      await this.$store.dispatch('analytics/mapRemove', this.deleteDialog.id).then(() => {
        this.deleteDialog.name = '';
        this.deleteDialog.id = null;
        this.deleteDialog.show = false;
      });
    },
  },
};
</script>
