import sites from './sites/sites.store';
import siteNotes from './siteNotes/siteNotes.store';
import siteConfigurations from './siteConfigurations/siteConfigurations.store';
import siteAttachments from './siteAttachments/siteAttachments.store';
import siteLandOwners from './siteLandOwners/siteLandOwners.store';
import loggerConfigurations from './loggerConfigurations/loggerConfigurations.store';
import dataTrace from './dataTrace/dataTrace.store';

export default {
  namespaced: true,
  modules: {
    sites,
    siteNotes,
    siteConfigurations,
    siteAttachments,
    siteLandOwners,
    loggerConfigurations,
    dataTrace,
  },
};
