<template>
  <div style="height: 200px;">
    <v-card-title>
      {{ $t('WindServer.Sites.LoggerConfiguration.TextReplacements.Section') }}
      <v-tooltip
        v-if="isEditable"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="createNew();"
          >
            <i class="v-icon mdi mdi-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('New') }} </span>
      </v-tooltip>
      <v-tooltip
        v-if="isEditable"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="deleteTextReplacements()"
          >
            <i class="v-icon mdi mdi-delete-forever" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.LoggerConfiguration.TextReplacements.Delete') }} </span>
      </v-tooltip>
    </v-card-title>

    <ServerSideDataGrid
      ref="gridText"
      :grid-source="sources.TextReplacements"
      :row-data-init="textReplacements"
      :pagination="false"
      :enable-selection="enableSelection"
    />
    <TextReplacementFormModal
      :id="id"
      v-model="showTextReplacement"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import TextReplacementList from '../gridsources/gsTextReplacements';
import TextReplacementFormModal from './TextReplacementFormModal.vue';

export default {
  components: {
    ServerSideDataGrid,
    TextReplacementFormModal,
  },
  props: {
    id: {
      type: Number,
      default: null,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      sources: {
        TextReplacements: null,
      },
      textReplacements: [],
      showTextReplacement: false,
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['logger']),
    enableSelection() {
      if (this.isEditable) {
        return 'multiple';
      }
      return undefined;
    },
  },
  created() {
    this.sources.TextReplacements = new TextReplacementList(this);
    const data = this.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === this.id,
    )[0];
    this.textReplacements = data.TextReplacements;
  },
  methods: {
    createNew() {
      this.showTextReplacement = true;
    },
    async editTextReplacement(payload) {
      await this.$store.dispatch('site/loggerConfigurations/textReplacement', payload);
      this.showTextReplacement = true;
    },
    async deleteTextReplacements() {
      let selectedRows = [];
      if (this.$refs.gridText.gridApi.getSelectedRows().length > 0) {
        selectedRows = this.$refs.gridText.gridApi.getSelectedRows();
      }
      if (selectedRows.length > 0) {
        await this.$store.dispatch('site/loggerConfigurations/deleteTextReplacement', selectedRows);
      }
    },
  },
};
</script>
