<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="formData.Name"
              :label="$t('WindServer.Sites.Attachments.FolderName')"
              :rules="mandatoryRules"
              :error-messages="errorMessage"
              @input="cleanError"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="renameFolder"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  data() {
    return {
      formData: {},
      show: false,
      mandatoryRules: null,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('site/siteAttachments', ['selectedFolderName', 'selectedFolderId', 'folderNameExists']),
  },
  watch: {
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    this.formData = {
      Name: this.selectedFolderName,
    };
    this.show = true;
  },
  methods: {

    async renameFolder() {
      this.mandatoryRules = [(v) => !!v || this.$t('Validation.Required')];
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (this.selectedFolderName !== this.formData.Name) {
            if (await this.validate()) {
              this.show = false;
              await this.$store.dispatch('site/siteAttachments/renameFolder', this.formData);
            }
          }
        }
      });
    },

    async navigateToList() {
      this.$router.go(-1);
    },

    async validate() {
      await this.$store.dispatch('site/siteAttachments/validateRename', this.formData);
      if (this.folderNameExists) {
        this.errorMessage = i18n.t('WindServer.Sites.Attachments.NameExists');
      } else {
        this.errorMessage = '';
        return true;
      }
      return false;
    },

    cleanError() {
      this.mandatoryRules = '';
      this.errorMessage = '';
    },

  },
};
</script>
