<template>
  <v-dialog
    v-model="showDialog"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card
      v-if="editorActive"
    >
      <v-card-title
        v-if="nodeType === 'formula'"
      >
        Edit formula.
      </v-card-title>
      <v-card-title
        v-if="nodeType === 'input_sensor_xform'"
      >
        Edit input transform.
      </v-card-title>
      <v-card-text>
        Hit [CTRL-SPACE] for help.
        See DSN-0001_FormulaCompier for details.
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-if="nodeType === 'formula'"
          v-model="OutputSymbol"
          label="Output Symbol"
          :error="OutputSymbolErrors.length > 0"
          :error-messages="OutputSymbolErrors"
          :readonly="editorMode !== 'new'"
        />
      </v-card-text>
      <v-card-text
        style="padding-top: 20px;"
      >
        <formulae-monaco-editor
          v-model="FormulaText"
          height="300px"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="showDialog=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          text
          @click.stop="onAccept"
        >
          {{ $t('Accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormulaeMonacoEditor from '@/components/monaco/FormulaeMonacoEditor.vue';

export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'nodeType', event: 'models:nodeType' },
    { data: 'FormulaText', event: 'models:FormulaText' },
    { data: 'OutputSymbol', event: 'models:OutputSymbol' },
    { data: 'OutputSymbolErrors', event: 'models:OutputSymbolErrors' },
  ],
  components: {
    FormulaeMonacoEditor,
  },
  props: {
    editorMode: {
      type: String,
      default: () => 'new',
      required: false,
    },
  },
  data: () => ({
    show: false,
    nodeType: '',
    FormulaText: '',
    OutputSymbol: '',
    OutputSymbolErrors: [],

    showDialog: false,
  }),
  computed: {
    editorActive() {
      return this.show && (this.nodeType === 'formula' || this.nodeType === 'input_sensor_xform');
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.initialize();
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
    showDialog(value) {
      if (!value) {
        this.show = false;
      }
    },

    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) { this.$emit('models:show', newVal); },
    nodeType: function w(newVal) { this.$emit('models:nodeType', newVal); },
    FormulaText: function w(newVal) { this.$emit('models:FormulaText', newVal); },
    OutputSymbol: function w(newVal) { this.$emit('models:OutputSymbol', newVal); },
    OutputSymbolErrors: function w(newVal) { this.$emit('models:OutputSymbolErrors', newVal); },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
    },
    onAccept() {
      this.showDialog = false;
      this.$emit('on-accept', {
        nodeType: this.nodeType,
        FormulaText: this.FormulaText,
        OutputSymbol: this.OutputSymbol,
        OutputSymbolErrors: this.OutputSymbolErrors,
      });
    },
  },
};
</script>

<style>

</style>
