import {
  parseJSON,
  format,
  // formatISO,
} from 'date-fns';
import dtz from 'date-fns-tz';

const dateTimeOffsetRegex = /^\d\d\d\d-\d\d-\d\d[T ]\d\d:\d\d:\d\d\.?\d*([-+]\d\d:\d\d|Z)$/;
const dateTimeRegex = /^\d\d\d\d-\d\d-\d\d[T ]\d\d:\d\d:\d\d\.?\d*$/;
const dateRegex = /^\d\d\d\d-\d\d-\d\d$/;

export default {
  parseFromApi(str) {
    const dateTimeOffsetResult = dateTimeOffsetRegex.exec(str);
    if (dateTimeOffsetResult && dateTimeOffsetResult.length > 0) {
      return this.utcToZonedTime(parseJSON(str)); // Respect the included time zone
    }
    const dateTimeResult = dateTimeRegex.exec(str);
    if (dateTimeResult && dateTimeResult.length > 0) {
      return this.utcToZonedTime(parseJSON(str)); // Assume UTC
    }
    const dateResult = dateRegex.exec(str);
    if (dateResult && dateResult.length > 0) {
      return this.utcToZonedTime(parseJSON(str)); // Assume UTC
    }
    return str;
  },

  parseFromLocal(str) {
    const dateTimeOffsetResult = dateTimeOffsetRegex.exec(str);
    if (dateTimeOffsetResult && dateTimeOffsetResult.length > 0) {
      return this.zonedTimeToUtc(parseJSON(str)); // Respect the included time zone
    }
    const dateTimeResult = dateTimeRegex.exec(str);
    if (dateTimeResult && dateTimeResult.length > 0) {
      return dtz.toDate(str); // Assume already in UTC
    }
    const dateResult = dateRegex.exec(str);
    if (dateResult && dateResult.length > 0) {
      return dtz.toDate(str); // Assume already in UTC
    }
    return str;
  },

  parseObjectFromApi(obj) {
    if (typeof obj !== 'object' || obj == null) {
      return null;
    }

    Object.keys(obj).forEach((key) => {
      if (!obj[key]) {
        return;
      }

      if (typeof obj[key] === 'string') {
        // eslint-disable-next-line
        obj[key] = this.parseFromApi(obj[key]);
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((el) => this.parseObjectFromApi(el));
      } else if (typeof obj[key] === 'object') {
        this.parseObjectFromApi(obj[key]);
      }
    });

    return obj;
  },

  formatForDisplay(input) {
    if (input) {
      return format(input, 'yyyy-MM-dd HH:mm:ss');
    }
    return null;
  },

  formatDateOnlyForDisplay(input) {
    if (input) {
      return format(input, 'yyyy-MM-dd');
    }
    return null;
  },

  formatTimeOnlyForDisplay(input) {
    if (input) {
      return format(input, 'HH:mm:ss');
    }
    return null;
  },

  formatForApi(input) {
    return this.zonedTimeToUtc(input).toISOString();
  },

  utcToZonedTime(input) {
    return dtz.utcToZonedTime(input);
  },
  zonedTimeToUtc(input) {
    return dtz.zonedTimeToUtc(input);
  },
};
