/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsModels extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.Worker.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 80,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.Worker.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'Worker.Workers.Update',
                params: {
                  id: event.data.WorkerId,
                },
              });
            },
          },
        },
        {
          field: '',
          headerName: this.vue.$t('Worker.Workers.Columns.Configuration'),
          width: 80,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            icon: 'mdi-wrench',
            clicked: (event) => {
              this.vue.$router.push({
                name: 'Worker.WorkerConfigs.List',
                params: {
                  workerid: event.data.WorkerId,
                },
              });
            },
          },
        },
        {
          field: '',
          headerName: this.vue.$t('Worker.Workers.Columns.Token'),
          width: 80,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            icon: 'mdi-key-variant',
            clicked: (event) => {
              this.vue.$router.push({
                name: 'Worker.Workers.Token',
                params: {
                  id: event.data.WorkerId,
                },
              });
            },
          },
        },
        {
          headerName: this.vue.$t('Worker.Workers.Columns.WorkerId'),
          field: 'WorkerId',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.Workers.Columns.Name'),
          field: 'Name',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.Workers.Columns.Version'),
          field: 'Version',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Worker.Workers.Columns.LastSeen'),
          field: 'LastSeen',
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
        },
        {
          headerName: this.vue.$t('Worker.Workers.Columns.Status'),
          field: '',
          filter: true,
          sortable: true,
          editable: false,
          valueGetter: (params) => ((new Date() - params.data.LastSeen) < 600000 ? this.vue.$t('Worker.Workers.Online') : this.vue.$t('Worker.Workers.Offline')),
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 80,
          resizable: true,
          hide: !this.vue.$auth.roles.includes('Web.Worker.Manage'),
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            show: () => (true),
            confirm: (event) => (event.data.Name),
            clicked: async (event) => {
              await this.vue.$store.dispatch('worker/workers/remove', { id: event.data.WorkerId });
            },
          },
        },
      ]);
    });
  }
}
