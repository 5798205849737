<template>
  <v-dialog
    v-model="showDataImportModal"
    persistent
    transition="dialog-bottom-transition"
    max-width="1200"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <v-row>
              <div class="col-4">
                <p>
                  {{ $t('WindServer.Sites.LoggerConfiguration.DataImportSettings.StartDate') }}
                  {{ formattedStartDate }}
                </p>
                <p>
                  {{ $t('WindServer.Sites.LoggerConfiguration.DataImportSettings.EndDate') }}
                  {{ formattedEndDate }}
                </p>
              </div>
              <div
                class="col-4"
              />
            </v-row>
            <v-row>
              <v-text-field
                v-model="formData.Details"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.Details')"
                :rules="requiredRule"
                class="col-3"
                @change="onChange"
              />
              <v-select
                v-model="formData.TimeStampId"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.Timestamps')"
                :items="timeStamps"
                :rules="requiredRule"
                item-text="Name"
                item-value="TimeStampId"
                class="col-3"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.DateTimeDataFormat"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.DateTimeDataFormat')"
                class="col-3"
                @change="onChange"
              />
              <v-select
                v-model="formData.FileTypeId"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.FileType')"
                :items="fileTypes"
                :rules="requiredRule"
                item-text="Name"
                item-value="FileTypeId"
                class="col-3"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.Delimiter"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.Delimiter')"
                :rules="requiredRule"
                class="col-3"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.SkipLines"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.SkipLines')"
                :rules="numberRule"
                type="number"
                class="col-3"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.LinesPerRecord"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.LinesPerRecord')"
                :rules="numberRule"
                type="number"
                class="col-3"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.IntervalLength"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.IntervalLength')"
                :rules="numberRule"
                type="number"
                class="col-3"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.RowPattern"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.RowPattern')"
                :rules="requiredRule"
                class="col-3"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.MissingDataValues"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.MissingDataValues')"
                class="col-3"
                @change="onChange"
              />
              <v-text-field
                v-model="formData.FileTag"
                :label="$t('WindServer.Sites.LoggerConfiguration.DataImportSettings.FileTag')"
                class="col-3"
                @change="onChange"
              />
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

export default {
  components: {
  },
  data() {
    return {
      formData: {
        IsDirty: false,
      },
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      numberRule: [(v) => (!Number.isNaN(parseFloat(v))) || this.$t('Validation.Required')],
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['showDataImportModal', 'loggerConfiguration', 'timeStamps', 'fileTypes', 'dataFileImport', 'defaultDataFileImport']),
    dateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    formattedStartDate() {
      return this.formData && this.formData.StartingDate ? format(this.formData.StartingDate, this.dateTimeFormat) : '';
    },
    formattedEndDate() {
      return this.formData && this.formData.EndingDate ? format(this.formData.EndingDate, this.dateTimeFormat) : '';
    },
  },
  watch: {
    async showDataImportModal() {
      if (this.showDataImportModal) {
        await this.setFormData();
      }
    },
  },
  methods: {
    async setFormData() {
      if (this.dataFileImport) {
        this.formData = JSON.parse(JSON.stringify(this.dataFileImport));
        this.formData.StartingDate = this.dataFileImport.StartingDate;
        this.formData.EndingDate = this.dataFileImport.EndingDate;
        this.formData.IsDirty = false;
      } else {
        await this.setDefaultData();
      }
    },
    async setDefaultData() {
      await this.$store.dispatch('site/loggerConfigurations/getDefaultDataFileImport');
      this.formData = JSON.parse(JSON.stringify(this.defaultDataFileImport));
      this.formData.StartingDate = this.defaultDataFileImport.StartingDate;
      this.formData.EndingDate = this.defaultDataFileImport.EndingDate;
      this.formData.IsDirty = false;
    },
    async submit() {
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          await this.$store.dispatch('site/siteConfigurations/setDirty', this.formData.IsDirty);
          if (this.dataFileImport) {
            this.formData.SkipLines = Number(this.formData.SkipLines);
            this.formData.LinesPerRecord = Number(this.formData.LinesPerRecord);
            this.formData.IntervalLength = Number(this.formData.IntervalLength);
            await this.$store.dispatch('site/loggerConfigurations/updateDataFileImport', this.formData);
          } else {
            this.formData.SkipLines = Number(this.formData.SkipLines);
            this.formData.LinesPerRecord = Number(this.formData.LinesPerRecord);
            this.formData.IntervalLength = Number(this.formData.IntervalLength);
            await this.$store.dispatch('site/loggerConfigurations/createDataFileImport', this.formData);
          }
          await this.close();
        }
      });
    },
    async close() {
      this.formData = {};
      this.$store.dispatch('site/loggerConfigurations/dataFileImport', null);
      this.$refs.form.reset();
      await this.$store.dispatch('site/loggerConfigurations/closeData');
    },
    onChange() {
      this.formData.IsDirty = true;
    },
  },
};
</script>
