/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsTemplateSets extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    const { vue } = this;
    return new Promise((resolve) => {
      resolve([
        {
          field: 'TplsScriptId',
          headerName: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.Edit'),
          width: 120,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked(params) {
              vue.$router.push(`/tpls/${params.data.TplsScriptId}`);
            },
          },
        },
        {
          headerName: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.Name'),
          field: 'Name',
          editable: true,
          sortable: true,
        },
        {
          headerName: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.Description'),
          field: 'Description',
          editable: true,
          sortable: true,
        },
        {
          headerName: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.CreationTs'),
          field: 'CreationDate',
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
          },
          sortable: false,
          resizable: true,
        },
        {
          headerName: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.ModifiedTs'),
          field: 'ModifiedDate',
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
          },
          sortable: false,
          resizable: true,
        },
        {
          headerName: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.ActivatedTs'),
          field: 'ActivatedDate',
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
          },
          sortable: false,
          resizable: true,
        },
        {
          headerName: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.RevertedTs'),
          field: 'RevertedDate',
          cellRenderer: 'EditableDateTimeCellRenderer',
          cellRendererParams: {
          },
          sortable: false,
          resizable: true,
        },
        {
          headerName: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.Active'),
          field: 'IsActive',
          sortable: false,
          resizable: true,
          valueGetter: (params) => (params.data.IsActive ? this.vue.$t('VirtualInstrument.TemplateSets.Yes') : this.vue.$t('VirtualInstrument.TemplateSets.No')),
        },
      ]);
    });
  }

  // Data source definition for AG Grid
  // See: https://www.ag-grid.com/vue-data-grid/infinite-scrolling/#datasource-interface
  // NOTE: This interface is also used in 'client' mode
  dataSource() {
    const { vue } = this;
    return {
      getRows: function GetRows(params) {
        importal.get(`TplsTemplateSetList?tenantId=${vue.tenantId}`)
          .then((resp) => {
            params.successCallback(resp.data, resp.data.length);
          })
          .catch(() => {
            params.failCallback();
          });
      },
    };
  }

  // returns a Promise that resolves to a form definition
  // See: https://wotamann.gitbook.io/vuetify-form-base/
  // See also: https://github.com/wotamann/vuetify-form-base
  // Returns Model:  the data mode
  //         Schema: the form definition
  async newItemFormDefinition() {
    const { vue } = this;
    const requiredRule = (msg) => (v) => !!v || msg;
    return new Promise((resolve) => {
      resolve({
        Model: {
          TenantId: vue.tenantId,
          Name: null,
          Description: null,
        },
        Schema: {
          TenantId: {
            label: this.vue.$t('VirtualInstrument.TemplateSets.TenantId'),
            type: 'text',
            flex: 12,
            readonly: true,
            disabled: true,
          },
          Name: {
            label: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.Name'),
            type: 'text',
            flex: 12,
            required: true,
            rules: [requiredRule(this.vue.$t('Validation.Required'))],
          },
          Description: { label: this.vue.$t('VirtualInstrument.TemplateSets.ListHeaders.Description'), type: 'text', flex: 12 },
        },
      });
    });
  }

  // Called on new item form 'accept'
  // MAY call successCallback if the API call returns the table data
  onNewItem(data, successCallback, failCallback) {
    this.vue.$store.commit('app/pleaseWait', true);
    importal
      .post('TplsScriptNew', {
        TenantId: data.TenantId,
        Name: data.Name,
        Description: data.Description || '',
      })
      .then((resp) => {
        successCallback(resp.data, resp.data.length);
      })
      .catch(() => {
        failCallback();
      })
      .finally(() => {
        this.vue.$store.commit('app/pleaseWait', false);
      });
  }
}
