import GatewayList from '@/devices/gateways/GatewayList.vue';
import GatewayEdit from '@/devices/gateways/GateWayEdit/GateWay.vue';

export default [
  {
    path: '/devices/gateway',
    name: 'Devices.Gateway',
    component: GatewayList,
  },
  {
    path: '/devices/gateway/:id',
    name: 'Devices.GatewayEdit',
    component: GatewayEdit,
  },
];
