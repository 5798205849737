<template>
  <div class="main-dash-container">
    <v-toolbar
      flat
      class="dash-toolbar"
    >
      <dashboard-select
        :sel-dashboard="model"
        :dash-items="dashboards"
        :edit-mode-flag="editModeFlag"
        @on-tab-select="changeTab"
        @on-update="updateTab"
      />
      <v-tooltip
        v-if="editModeFlag && $auth.roles.includes('Web.Dashboard.Create')"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-img
            v-bind="attrs"
            class="cursor-pointer ml-4 mt-4 d-none d-md-flex"
            lazy-src="@/assets/add-dash.png"
            max-height="33"
            max-width="36"
            src="@/assets/add-dash.png"
            v-on="on"
            @click="addNewDashboard"
          />
        </template>
        <span>{{ $t('Dashboard.Menu.AddNewBoard') }}</span>
      </v-tooltip>
      <placeholder-settings v-if="editModeFlag" />
      <v-spacer />
      <DashboardMenu
        :dashboard="selectedDashboard"
        :edit-mode-flag="editModeFlag"
        :has-dashboards="hasDashboards"
        @toggle-edit-mode="menuToggleEditMode"
        @add-new-widget="menuAddNewWidget"
        @copy-dashboard="copyDashboard"
        @delete-dashboard="menuDeleteDashboard"
        @refresh-dashboard="refreshDashboard"
        @on-rename="updateTab"
        @on-add-dashboard="addNewDashboard"
        @home-dashboard="homeDashboard"
      />
    </v-toolbar>
    <v-toolbar
      v-if="placeholders.length > 0"
      flat
      class="dash-toolbar pt-0"
    >
      <placeholder-stack />
    </v-toolbar>
    <div
      class="pa-0 dash-container"
    >
      <!-- Dashboard -->
      <DashboardStack
        activate-api
        :edit-mode-flag="editModeFlag"
        :dashboard.sync="model"
        :cell-height="cellHeight"
        @interface="getDashboardStackInterface"
        @refreshDash="refreshDashboard"
      />
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-fab-transition>
            <v-btn
              v-show="editModeFlag"
              v-bind="attrs"
              absolute
              bottom
              right
              fab
              class="add-widget-btn"
              @click="menuAddNewWidget"
              v-on="on"
            >
              <v-icon>mdi-view-grid-plus-outline</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <span>{{ $t('Dashboard.Menu.AddNewWidget') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DashboardSelect from '@/dashboard/components/DashboardSelect.vue';
import DashboardMenu from '@/dashboard/components/DashboardMenu.vue';
import DashboardStack from '@/dashboard/components/DashboardStack.vue';
import PlaceholderSettings from './components/placeholder/PlaceholderSettings.vue';
import PlaceholderStack from './components/placeholder/PlaceholderStack.vue';

export default {
  name: 'DashboardView',
  components: {
    DashboardSelect,
    DashboardMenu,
    DashboardStack,
    PlaceholderSettings,
    PlaceholderStack,
  },
  data: () => ({
    model: {
      name: null,
      dashboardId: null,
      isTemplate: true,
      users: null,
      displayAccessGroupId: null,
      displayAccessGroupName: null,
    },
    editModeFlag: false,
    stackMenuInterface: null,
    cellHeight: 15, // Number of pixels of vertical snap for widgets
  }),
  computed: {
    ...mapState('dashboard', ['dashboards', 'selectedDashboard']),
    ...mapState('dashboardPlaceholders', ['placeholders']),
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),
    hasDashboards() {
      return this.dashboards.length > 0;
    },
  },
  watch: {
    async tenantId() {
      await this.init();
    },
    async accessGroupIds(newVal, oldVal) {
      if (!this.spamfree && oldVal.join() !== newVal.join()) {
        await this.init();
      }
    },
    async selectedDashboard() {
      this.changeTab(this.selectedDashboard);
    },
  },
  created() {
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.$root.$on('dashboard-refresh', this.refreshDashboard);
      this.$store.commit('app/pleaseWait', true);
      await this.$store.dispatch('dashboard/list');
      if (this.dashboards && this.dashboards.length > 0) {
        let dashboard;
        if (this.model.dashboardId) {
          dashboard = this.dashboards.find((x) => x.DashboardId === this.model.dashboardId);
        } else if (this.$route.params.id) {
          dashboard = this.dashboards.find((x) => x.DashboardId === this.$route.params.id);
        } else if (this.dashboards.find((x) => x.isDefault)) {
          dashboard = this.dashboards.find((x) => x.isDefault);
        }
        if (dashboard) {
          // recharge active widgets
          await this.$store.dispatch('dashboardWidget/list', { dashId: dashboard.DashboardId });
          this.changeTab(dashboard);
        } else {
          // recharge active widgets
          await this.$store.dispatch('dashboardWidget/list', { dashId: this.dashboards[0].DashboardId });
          this.changeTab(this.dashboards[0]);
        }
      } else {
        this.changeTab(null);
      }

      this.$store.commit('app/pleaseWait', false);
    },
    getDashboardStackInterface(i) {
      this.stackMenuInterface = i;
    },

    async changeTab(d) {
      this.model = {};

      if (d) {
        this.model.dashboardId = d.DashboardId;
        this.model.isTemplate = d.IsTemplate;
        this.model.name = this.dashboards
          .filter((dashboard) => dashboard.DashboardId === d.DashboardId)[0]?.Name;
        this.model.users = d.sharedUsers.filter((s) => {
          if (s.id.indexOf('.') >= 0) {
            return s.id.split('.')[1] !== this.$auth.user.localAccountId;
          }
          return s.id !== this.$auth.user.localAccountId;
        });
        this.model.displayAccessGroupId = d.DisplayAccessGroupId;
        this.model.displayAccessGroupName = d.DisplayAccessGroupName;
      } else {
        this.model = {
          name: null,
          dashboardId: null,
          isTemplate: true,
          users: null,
          displayAccessGroupId: null,
          displayAccessGroupName: null,
        };
      }

      const twoValuesAreNull = !this.model.dashboardId && this.$route.params.id === 'null';
      if (!twoValuesAreNull && this.model.dashboardId !== this.$route.params.id) {
        this.$router.push(`/dashboard/${this.model.dashboardId}`);
      }

      this.$store.dispatch('dashboard/select', d);
      this.$store.dispatch('dashboardPlaceholders/list', this.model.dashboardId);
    },

    async withOverlayAsync(body) {
      this.$store.commit('app/pleaseWait', true);
      await body()
        .finally(() => { this.$store.commit('app/pleaseWait', false); });
    },

    // #region Dashboard and Widget CRUD logic
    async addNewDashboard() {
      await this.withOverlayAsync(async () => {
        const index = this.dashboards.length + 1;
        const defaultName = `${this.$t('Dashboard.New')} ${index}`;
        const newDashboardId = crypto.randomUUID();

        await this.$store.dispatch('dashboard/create', {
          DashboardId: newDashboardId,
          Name: defaultName,
          IsTemplate: false,
        });
        this.$store.commit('dashboardPlaceholders/init');

        // document.querySelectorAll(`[dash-id="${newDashboardId}"]`)[0].click();
        const newDashboard = this.dashboards
          .filter((dashboard) => dashboard.DashboardId === newDashboardId)[0];
        this.model = {};
        this.model.dashboardId = newDashboardId;
        this.model.isTemplate = false;
        this.model.name = newDashboard?.Name;

        this.$store.dispatch('dashboard/select', newDashboard);
        this.$router.push(`/dashboard/${this.model.dashboardId}`);

        setTimeout(() => {
          this.menuAddNewWidget();
        }, 500);
      });
    },

    async copyDashboard() {
      // TODO - complete
    },
    async homeDashboard() {
      await this.$store.dispatch('dashboard/home', {
        DashboardId: '1',
      });
    },
    async updateTab(d, newName) {
      await this.withOverlayAsync(async () => {
        await this.$store.dispatch('dashboard/save', {
          DashboardId: d.DashboardId,
          Name: newName,
          IsTemplate: d.IsTemplate,
          DisplayAccessGroupId: d.DisplayAccessGroupId,
        });
      });
    },
    // #endregion

    menuToggleEditMode() {
      this.editModeFlag = !this.editModeFlag;
    },
    menuAddNewWidget() {
      if (this.stackMenuInterface) {
        this.stackMenuInterface.menuAddNewWidget();
      }
    },

    async menuDeleteDashboard() {
      await this.withOverlayAsync(async () => {
        /* if (this.dashboards.length > 1) {
          document.querySelectorAll(`[dash-id="${this.model.dashboardId}"]`)[0].remove(); // for instant ux response
        } */
        await this.$store.dispatch('dashboard/remove', { DashId: this.model.dashboardId });
        await this.$store.dispatch('dashboardPlaceholders/remove', { DashboardId: this.model.dashboardId });

        if (this.dashboards && this.dashboards.length > 0) {
          this.changeTab(this.dashboards[0]);
        }
        /* const toSelectTab = document.querySelectorAll('[dash-id]')[0];
        if (toSelectTab) {
          toSelectTab.click();
        } */
      });
    },

    async refreshDashboard() {
      if (this.editModeFlag) { return; }
      if (this.stackMenuInterface) {
        await this.withOverlayAsync(async () => {
          await this.stackMenuInterface.menuRefreshDashboard();
        });
      }
    },
  },
};
</script>

<style lang="scss">
  // @import "@/styles/_includes.scss";

.main-dash-container {
  // background-image: url('@/assets/wspOverlay.png'),
  //   linear-gradient(126.13deg, #333E48 -3.75%, #3B4955 76.61%);
  // background: linear-gradient(126.13deg, #333E48 -3.75%, #3B4955 76.61%);
  background: transparent !important;
}
.dash-toolbar {
  // background: linear-gradient(126.13deg, #2A333B -3.75%, #3C4954 76.61%);
  background: rgba(0, 0, 0, 0.25) !important;
  // background: transparent !important;
  .v-toolbar__content, .v-toolbar__extension {
    padding-right: 0;
  }
}
  .v-tab {
    text-transform: none !important;
  }

  .grid-stack {
    margin-bottom: 0;
  }

  .grid-stack-item,
  .widget {
    border: 1px solid;
    // @include theme-style(border-color, border-color, important);
    border-radius: 10px;
  }

  .grid-stack-item:hover,
  .widget:hover {
    border: 1px solid;
    // @include theme-style(border-color, grey-darken-1, important);
    // @include theme-style(background, grey-lighten-4, important);
    cursor: pointer;
  }

  .active-widget {
    border: 1px solid;
    // @include theme-style(border-color, primary-fg, important);
    cursor: pointer;
  }

  .widget {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .new-widget {
    border: 2px dashed;
  }

  .new-widget:hover {
    border: 3px dashed;
  }

  .graphnodata {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" id="footer-sample-full" width="120px" height="120px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16" class="iconify iconify--charm"><g fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M8.25 14.25h-5.5V1.75h10.5v6.5"></path><path d="M14.25 10.75l-3.5 3.5m-5-6.5h4.5m-4.5 3h1.5m-1.5-6h4.5m.5 6l3.5 3.5"></path></g></svg>');
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    z-index: 1;
    background-repeat: no-repeat;
  }

  .iconNoImage {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" id="footer-sample-full" width="120px" height="120px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" class="iconify iconify--charm"><g fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M13.09 20H6L12 14L13.88 15.88C14.5 14.9 15.36 14.1 16.4 13.6L18 12V13.09C18.33 13.04 18.66 13 19 13C19.34 13 19.67 13.04 20 13.09V8L14 2H6C4.89 2 4 2.89 4 4V20C4 21.1 4.89 22 6 22H13.81C13.46 21.39 13.21 20.72 13.09 20M13 3.5L18.5 9H13V3.5M8 9C9.11 9 10 9.9 10 11S9.11 13 8 13 6 12.11 6 11 6.9 9 8 9M22.54 16.88L20.41 19L22.54 21.12L21.12 22.54L19 20.41L16.88 22.54L15.47 21.12L17.59 19L15.47 16.88L16.88 15.47L19 17.59L21.12 15.47L22.54 16.88Z" /></g></svg>');
    position: absolute;
    left: 50%;
    top: 50%;
    width: 240px;
    height: 240px;
    margin: -76px 0 0 -76px;
    z-index: 1;
    background-repeat: no-repeat;
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #ff00006c; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    animation: spin 2s linear infinite;
  }

  .widgetLoader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #ff00006c; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: -38px 0 0 -38px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .widget-toolbar {
    position: absolute;
    left: 3px;
    top: 3px;
    // z-index: 100;
  }
  .widget-button-menu:hover {
    color: red;
  }
  .add-widget-btn {
    background: #03AB9A !important;
  }

</style>
