<template>
  <v-dialog
    v-model="show"
    persistent
    width="400"
  >
    <v-card tile>
      <v-card-title>
        {{ $t('SetLayer') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="layer"
        />
      </v-card-text>
      <v-card-text>
        <v-select
          :items="layerList"
          :disabled="layerList.length == 0"
          :label="$t('CurrentLayers')"
          outlined
          @input="layerSelected"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          :disabled="!canSubmit"
          text
          @click="submitClick"
        >
          {{ $t('Submit') }}
        </v-btn>
        <v-btn
          text
          @click="show = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import importal from '@/api/importal';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default {
  components: {
  },
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'layer', event: 'models:layer' },
  ],
  props: {
  },
  emits: ['click:submit'],
  data: () => ({
    show: false,
    layer: '',
    layerList: [],
  }),
  computed: {
    canSubmit() {
      return this.layer && this.layer.length > 3;
    },
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) {
      if (newVal) {
        this.dialogShown();
      }
      this.$emit('models:show', newVal);
    },
    layer: function w(newVal) {
      this.$emit('models:layer', newVal);
    },
  },
  i18n: {
    messages: {
      en: {
        SetLayer: 'Set Layer',
        CurrentLayers: 'Current Layers',
        Submit: 'Submit',
        Close: 'Close',
      },
      fr: {
        SetLayer: 'Définir la couche',
        CurrentLayers: 'Couches actives',
        Submit: 'Soumettre',
        Close: 'Fermer',
      },
    },
  },
  created() {
    this.loadDropDown = debounce(this.loadDropDown);
  },
  mounted() {
    this.loadDropDown();
  },
  methods: {
    submitClick() {
      this.show = false;
      this.$emit('click:submit', this.layer);
    },
    dialogShown() {
      this.loadDropDown();
    },
    loadDropDown() {
      importal
        .get('MapLayerList')
        .then((resp) => (resp.data))
        .then((data) => {
          this.layerList = data;
        });
    },
    layerSelected(e) { this.layer = e; },
  },
};
</script>

<style>

</style>
