<template>
  <div style="height: 440px;">
    <v-card-title>
      {{ $t('WindServer.Sites.LoggerConfiguration.Columns.Section') }}
      <v-tooltip
        v-if="isEditable"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="createNew();"
          >
            <i class="v-icon mdi mdi-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('New') }} </span>
      </v-tooltip>
      <v-tooltip
        v-if="isEditable"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="deleteDataImportConfigs()"
          >
            <i class="v-icon mdi mdi-delete-forever" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.LoggerConfiguration.Columns.Delete') }} </span>
      </v-tooltip><v-tooltip
        v-if="isEditable"
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="generateColumns();"
          >
            <i class="v-icon mdi mdi-format-columns" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.LoggerConfiguration.Columns.Generate') }} </span>
      </v-tooltip>
    </v-card-title>
    <ServerSideDataGrid
      ref="gridCol"
      :grid-source="sources.Columns"
      :row-data-init="columns"
      :pagination="false"
      :enable-selection="enableSelection"
    />
    <ColumnFormModal
      :id="id"
      v-model="showColumn"
      :fixed="fixed"
    />
    <GenerateColumnFormModal
      :id="id"
      v-model="showGenerate"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import ColumnList from '../gridsources/gsColumns';
import ColumnFormModal from './ColumnFormModal.vue';
import GenerateColumnFormModal from './GenerateColumnFormModal.vue';

export default {
  components: {
    ServerSideDataGrid,
    ColumnFormModal,
    GenerateColumnFormModal,
  },
  props: {
    id: {
      type: Number,
      default: null,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      sources: {
        Columns: null,
      },
      columns: [],
      showColumn: false,
      showGenerate: false,
      fixed: false,
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['logger']),
    enableSelection() {
      if (this.isEditable) {
        return 'multiple';
      }
      return undefined;
    },
  },
  created() {
    this.sources.Columns = new ColumnList(this);
    const data = this.logger.DataFileImportSettings.filter(
      (x) => x.DataFileImportSettingId === this.id,
    )[0];
    this.columns = data.DataImportConfigs;
  },
  methods: {
    createNew() {
      this.setFixed();
      this.showColumn = true;
    },
    async editDataImportConfig(payload) {
      this.setFixed();
      await this.$store.dispatch('site/loggerConfigurations/dataImportConfig', payload);
      this.showColumn = true;
    },
    async deleteDataImportConfigs() {
      let selectedRows = [];
      if (this.$refs.gridCol.gridApi.getSelectedRows().length > 0) {
        selectedRows = this.$refs.gridCol.gridApi.getSelectedRows();
      }
      if (selectedRows.length > 0) {
        await this.$store.dispatch('site/loggerConfigurations/deleteDataImportConfig', selectedRows);
      }
    },
    generateColumns() {
      this.setFixed();
      this.showGenerate = true;
    },
    setFixed() {
      const data = this.logger.DataFileImportSettings.filter(
        (x) => x.DataFileImportSettingId === this.id,
      )[0];
      this.fixed = data.FileTypeId === 2;
    },
  },
};
</script>

<style>
.redBackgroundCell
{
  background-color: crimson;
}
</style>
