<template>
  <div class="col col-4">
    <v-card-title class="pl-0 pt-0 pb-0">
      {{ $t('WindServer.Sites.LoggerConfiguration.Heights.Section') }}
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="createNew();"
          >
            <i class="v-icon mdi mdi-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('New') }} </span>
      </v-tooltip>
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="deleteHeights()"
          >
            <i class="v-icon mdi mdi-delete-forever" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.LoggerConfiguration.Heights.Delete') }} </span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pl-0 pr-0">
      <v-container
        class="pl-0"
        fluid
        style="height: 350px;"
      >
        <ServerSideDataGrid
          ref="gridHeight"
          :grid-source="sources.Heights"
          :row-data-init="heights"
          :pagination="false"
          enable-selection="multiple"
        />
      </v-container>
    </v-card-text>

    <HeightFormModal
      :id="id"
      v-model="showHeight"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import HeightsList from '../gridsources/gsHeights';
import HeightFormModal from './HeightFormModal.vue';

export default {
  components: {
    ServerSideDataGrid,
    HeightFormModal,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      sources: {
        Heights: null,
      },
      showHeight: false,
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['heights']),
  },
  created() {
    this.sources.Heights = new HeightsList(this);
  },
  methods: {
    createNew() {
      this.showHeight = true;
    },
    async editHeights(payload) {
      await this.$store.dispatch('site/loggerConfigurations/height', payload);
      this.showHeight = true;
    },
    async deleteHeights() {
      let selectedRows = [];
      if (this.$refs.gridHeight.gridApi.getSelectedRows().length > 0) {
        selectedRows = this.$refs.gridHeight.gridApi.getSelectedRows();
      }
      if (selectedRows.length > 0) {
        await this.$store.dispatch('site/loggerConfigurations/deleteHeight', selectedRows);
      }
    },
  },
};
</script>
