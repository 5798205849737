// eslint-disable-next-line no-unused-vars
const agGridfr = {
  // Set Filter
  selectAll: '(Sélectionnez tout)',
  selectAllSearchResults: '(Sélectionner tous les résultats de recherche)',
  searchOoo: 'Rechercher...',
  blanks: '(Vides)',
  noMatches: 'Aucun résultat',

  // Number Filter & Text Filter
  filterOoo: 'Filtre...',
  equals: 'Egaux',
  notEqual: 'Non égal',
  blank: 'Vide',
  notBlank: 'Non vide',
  empty: 'Choisir un',

  // Number Filter
  greaterThan: 'Plus grand que',
  lessThan: 'Inférieur à',
  lessThanOrEqual: 'Inférieur ou égal',
  greaterThanOrEqual: 'Plus grand que ou égal',
  inRange: "Dans l'intervalle",
  inRangeStart: 'de',
  inRangeEnd: 'à',

  // Text Filter
  contains: 'Contient',
  notContains: 'Ne contient pas',
  startsWith: 'Commence avec',
  endsWith: 'Se termine par',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'ET',
  orCondition: 'OU',

  // Filter Buttons
  applyFilter: 'Appliquer',
  resetFilter: 'Réinitialiser',
  clearFilter: 'Vider',
  cancelFilter: 'Annuler',

  // Filter Titles
  textFilter: 'Filtre sur le texte',
  numberFilter: 'Filtre sur les nombres',
  dateFilter: 'Filtre sur les dates',
  setFilter: 'Set Filter',

  // Side Bar
  columns: 'Colonnes',
  filters: 'Filtres',

  // columns tool panel
  pivotMode: 'Mode Pivot',
  groups: 'Groupes de lignes',
  rowGroupColumnsEmptyMessage: 'Faites glisser ici pour définir les groupes de lignes',
  values: 'Valeurs',
  valueColumnsEmptyMessage: 'Glisser ici pour agréger',
  pivots: 'Libellés des colonnes',
  pivotColumnsEmptyMessage: 'Glisser ici pour définir les étiquettes des colonnes',

  // Header of the Default Group Column
  group: 'Groupe',

  // Row Drag
  rowDragRow: 'rangée',
  rowDragRows: 'rangées',

  // Other
  loadingOoo: 'Chargement...',
  loadingError: 'ERR',
  noRowsToShow: 'Pas de rangées à afficher',
  enabled: 'Activé',

  // Menu
  pinColumn: 'Épingler la colonne',
  pinLeft: 'Épingler à gauche',
  pinRight: 'Épingler à gauche',
  noPin: "Pas d'épingle",
  valueAggregation: 'Agrégation de valeurs',
  noAggregation: 'Aucune',
  autosizeThiscolumn: 'Dimensionnement automatique de cette colonne',
  autosizeAllColumns: 'Dimensionnement automatique de toutes les colonnes',
  groupBy: 'Grouper par',
  ungroupBy: 'Dégrouper par',
  // eslint-disable-next-line no-template-curly-in-string
  addToValues: 'Ajouter ${variable} aux valeurs',
  // eslint-disable-next-line no-template-curly-in-string
  removeFromValues: 'Retirer ${variable} des valeurs',
  // eslint-disable-next-line no-template-curly-in-string
  addToLabels: 'Ajouter ${variable} aux étiquettes',
  // eslint-disable-next-line no-template-curly-in-string
  removeFromLabels: 'Retirer ${variable} des étiquettes',
  resetColumns: 'Réinitialiser les colonnes',
  expandAll: 'Développer tout',
  collapseAll: 'Fermer tout',
  copy: 'Copier',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copier avec les en-têtes',
  copyWithGroupHeaders: 'Copier avec les en-têtes de groupe',
  paste: 'Coller',
  ctrlV: 'Ctrl+V',
  export: 'Exporter',
  csvExport: 'Exportation CSV',
  excelExport: 'Exportation Excel',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Somme',
  first: 'Premier',
  last: 'Dernier',
  min: 'Min',
  max: 'Max',
  none: 'Aucun',
  count: 'Compter',
  avg: 'Moyenne',
  filteredRows: 'Filtré',
  selectedRows: 'Selectionné',
  totalRows: 'Nombre total de lignes',
  totalAndFilteredRows: 'Lignes',
  more: 'Plus',
  to: 'à',
  of: 'de',
  page: 'Page',
  nextPage: 'Page suivante',
  lastPage: 'Dernière page',
  firstPage: 'Première age',
  previousPage: 'Page précédente',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Graphique à pivot et mode pivot',
  pivotChart: 'Graphique croisé dynamique',
  chartRange: 'Gamme de graphique',

  columnChart: 'Colonne',
  groupedColumn: 'Groupé',
  stackedColumn: 'Empilé',
  normalizedColumn: '100% empilées',

  barChart: 'Barre',
  groupedBar: 'Groupé',
  stackedBar: 'Empilé',
  normalizedBar: '100% empilées',

  pieChart: 'Tarte',
  pie: 'Tarte',
  doughnut: 'Beigne',

  line: 'Ligne',

  xyChart: 'X Y (Diffusion)',
  scatter: 'Diffusion',
  bubble: 'Bulle',

  areaChart: 'Zone ',
  area: 'Zone ',
  stackedArea: 'Empilé',
  normalizedArea: '100% empilées',

  histogramChart: 'Histogramme',
  histogramFrequency: 'Fréquence',

  combinationChart: 'Combinaison',
  columnLineCombo: 'Colonne et ligne',
  AreaColumnCombo: 'Zone et colonne',

  // Charts
  pivotChartTitle: 'Graphique croisé dynamique',
  rangeChartTitle: 'Graphique de la portée',
  settings: 'Paramètres',
  data: 'Données',
  format: 'Format',
  categories: 'Catégories',
  defaultCategory: '(Aucune)',
  series: 'Series',
  xyValues: 'Valeurs X Y',
  paired: 'Mode apparié',
  axis: 'Axe',
  navigator: 'Navigateur',
  color: 'Couleur',
  thickness: 'Épaisseur',
  xType: 'X Type',
  automatic: 'Automatique',
  catégorie: 'Catégorie',
  number: 'Numéro',
  time: 'Time',
  autoRotate: 'Rotation automatique',
  xRotation: 'Rotation en X',
  yRotation: 'Rotation en Y',
  ticks: 'Tics',
  width: 'Largeur',
  height: 'Hauteur',
  length: 'Longueur',
  padding: 'Remplissage ',
  spacing: 'Espacement',
  graphique: 'Graphique',
  title: 'Titre',
  titlePlaceholder: 'Titre du graphique - double-cliquez pour le modifier',
  background: 'Fond',
  font: 'Police',
  top: 'Haut de page',
  right: 'Droite',
  bottom: 'Bas de page',
  left: 'Gauche',
  labels: 'Étiquettes',
  size: 'Taille',
  minSize: 'Taille minimale',
  maxSize: 'Taille maximale',
  légende: 'Légende',
  position: 'Position',
  markerSize: 'Taille du marqueur',
  markerStroke: 'Marqueur de trait',
  markerPadding: 'Marqueur de remplissage',
  itemSpacing: 'Espacement des éléments',
  itemPaddingX: "Remplissage de l'élément X",
  itemPaddingY: "Remplissage de l'élément en Y",
  layoutHorizontalSpacing: 'Espacement horizontal',
  layoutVerticalSpacing: 'Espacement vertical',
  strokeWidth: 'Largeur du trait',
  lineDash: "Trait d'union",
  offset: 'Décalage',
  offsets: 'Décalages',
  Tooltips: 'info-bulles',
  callout: 'Appel',
  markers: 'Marqueurs',
  shadow: 'Ombrage',
  blur: 'Flou',
  xOffset: 'Décalage en X',
  yOffset: 'Décalage en Y',
  lineWidth: 'Largeur de ligne',
  normal: 'Normal',
  bold: 'Gras',
  italic: 'Italique',
  boldItalic: 'Italique gras',
  predefined: 'Prédéfini',
  fillOpacity: 'Opacité de remplissage',
  strokeOpacity: 'Opacité du trait',
  histogramBinCount: 'Nombre de case',
  columnGroup: 'Colonne',
  barGroup: 'Barre',
  pieGroup: 'Tarte',
  lineGroup: 'Ligne',
  scatterGroup: 'X Y (Diffusion)',
  areaGroup: 'Zone',
  histogramGroup: 'Histogramme',
  combinationGroup: 'Combinaison',
  groupedColumnTooltip: 'Grouped',
  stackedColumnTooltip: 'Empilé',
  normalizedColumnTooltip: '100% empilés',
  groupedBarTooltip: 'Groupé',
  stackedBarTooltip: 'Empilé',
  normalizedBarTooltip: '100% empilés',
  pieTooltip: 'Tarte',
  doughnutTooltip: 'Beigne',
  lineTooltip: 'Ligne',
  groupedAreaTooltip: 'Zone',
  stackedAreaTooltip: 'Empilé',
  normalizedAreaTooltip: '100% empilés',
  scatterTooltip: 'Diffusion',
  bubbleTooltip: 'Bulle',
  histogrammeTooltip: 'Histogramme',
  columnLineComboTooltip: 'Colonne et ligne',
  areaColumnComboTooltip: 'Zone et colonne',
  customComboTooltip: 'Combinaison personnalisée',
  noDataToChart: 'Aucune donnée disponible pour le graphique',
  pivotChartRequiresPivotMode: 'Le graphique pivot doit avoir le mode pivot activé',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Lié à la grille',
  chartUnlinkToolbarTooltip: 'Non lié à la grille',
  chartDownloadToolbarTooltip: 'Télécharger le graphique',
  seriesChartType: 'Type de graphique de série',
  seriesType: 'Type de série',
  secondaryAxis: 'Axe secondaire',

  // ARIA
  ariaChecked: 'vérifié',
  ariaColumn: 'Colonne',
  ariaColumnGroup: 'Groupe de colonnes',
  ariaColumnList: 'Liste de colonnes',
  ariaColumnSelectAll: 'Basculer la sélection de toutes les colonnes',
  ariaDateFilterInput: 'Entrée du filtre de date',
  ariaDefaultListName: 'Liste',
  ariaFilterColumnsInput: 'Entrée des colonnes du filtre',
  ariaFilterFromValue: 'Filtre à partir de la valeur',
  ariaFilterInput: 'Entrée du filtre',
  ariaFilterList: 'Liste de filtres',
  ariaFilterToValue: 'Filtre sur la valeur',
  ariaFilterValue: 'Filtrer la valeu',
  ariaFilteringOperator: 'Opérateur de filtrage',
  ariaHidden: 'caché',
  ariaIndeterminate: 'indéterminé',
  ariaInputEditor: "Éditeur d'entrée",
  ariaMenuColumn: 'Appuyez sur CTRL ENTER pour ouvrir le menu de la colonne.',
  ariaRowDeselect: 'Appuyez sur ESPACE pour désélectionner cette ligne',
  ariaRowSelectAll: 'Appuyez sur Espace pour basculer la sélection de toutes les lignes.',
  ariaRowToggleSelection: 'Appuyez sur Espace pour basculer la sélection des lignes',
  ariaRowSelect: 'Appuyez sur ESPACE pour sélectionner cette ligne',
  ariaSearch: 'Recherche',
  ariaSortableColumn: 'Appuyez sur ENTER pour trier',
  ariaToggleVisibility: 'Appuyez sur ESPACE pour basculer la visibilité',
  ariaUnchecked: 'non coché',
  ariaVisible: 'visible',
  ariaSearchFilterValues: 'Valeurs du filtre de recherche',

  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: 'Groupes de rangées',
  ariaValuesDropZonePanelLabel: 'Valeurs',
  ariaPivotDropZonePanelLabel: 'Étiquettes de colonne',
  ariaDropZoneColumnComponentDescription: 'Appuyez sur DELETE pour supprimer',
  ariaDropZoneColumnValueItemDescription: "Appuyez sur ENTER pour modifier le type d'agrégation",
  ariaDropZoneColumnGroupItemDescription: 'Appuyez sur ENTER pour trier',
  // used for aggregate drop zone, format:
  ariaDropZoneColumnComponentAggFuncSeperator: ' de ',
  ariaDropZoneColumnComponentSortAscending: 'ascendant',
  ariaDropZoneColumnComponentSortDescending: 'descendant',

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: 'Menu de la colonne',
  ariaLabelCellEditor: 'Éditeur de cellules',
  ariaLabelDialog: 'Dialogue',
  ariaLabelSelectField: 'Sélectionner le champ',
  ariaLabelTooltip: 'Info-bulle',
  ariaLabelContextMenu: 'Menu contextuel',
  ariaLabelSubMenu: 'Sous-menu',
  ariaLabelAggregationFunction: "Fonction d'agrégation",

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ',',
  decimalSeparator: '.',
};

export default ({
  locale_fr: agGridfr,
});
