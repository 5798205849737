<template>
  <div>
    <v-dialog
      v-model="show"
      persistent
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-form
          ref="form"
          lazy-validation
          :readonly="readMode"
        >
          <v-card-text>
            <v-container fluid>
              <v-select
                v-model="formData.EquipmentType"
                :label="$t('Equipments.Models.Edit.EquipmentType')"
                :items="equipmentType"
                :readonly="isEdit"
                :autofocus="!isEdit"
                :rules="[v => !!v || $t('Validation.Required')]"
                item-text="EquipmentTypeNameI18N"
                item-value="EquipmentTypeId"
                return-object
                @change="changeEquipementType()"
              />
              <v-text-field
                v-model="formData.ManufacturerName"
                :label="$t('Equipments.Models.Edit.ManufacturerName')"
                :autofocus="isEdit"
                :rules="[v => !!v || $t('Validation.Required')]"
              />
              <v-text-field
                v-model="formData.ModelName"
                :label="$t('Equipments.Models.Edit.ModelName')"
                :rules="[v => !!v || $t('Validation.Required')]"
                :error-messages="errorMessage"
                @input="cleanError"
              />
              <v-text-field
                v-if="isBoom"
                v-model.number="formData.BoomLength"
                :label="$t('Equipments.Models.Edit.BoomLength')"
                :rules="[boomLenghtValidation]"
                type="number"
              />
              <v-text-field
                v-if="isBoom"
                v-model.number="formData.PostHeight"
                :label="$t('Equipments.Models.Edit.PostHeight')"
                :rules="[boomPostHeightValidation]"
                type="number"
              />
              <v-checkbox
                v-if="isBoom"
                v-model="formData.TBoom"
                :label="$t('Equipments.Models.Edit.TBoom')"
                type="checkbox"
              />
              <v-card v-if="isGinPole">
                <v-card-subtitle> {{ $t('Equipments.Models.Edit.TowerModel') }}</v-card-subtitle>
                <v-card-text>
                  <v-container fluid>
                    <v-form>
                      <v-text-field
                        v-model="formData.TowerModel.ManufacturerName"
                        :label="$t('Equipments.Models.Edit.ManufacturerName')"
                        :readonly="true"
                      />
                      <v-text-field
                        v-model="formData.TowerModel.ModelName"
                        :label="$t('Equipments.Models.Edit.ModelName')"
                        :readonly="true"
                      />
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions
                  v-if="!readMode"
                  class="justify-end"
                >
                  <v-btn
                    text
                    @click.stop="onChangeTower"
                  >
                    {{ $t('Change') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-select
                v-if="isLogger"
                v-model="formData.LoggerType"
                :label="$t('Equipments.Models.Edit.LoggerType')"
                :items="['DataLogger', 'SODAR', 'LIDAR']"
                :rules="[v => !!v || $t('Validation.Required')]"
              />
              <v-text-field
                v-if="isSensor"
                v-model="formData.ShortName"
                :label="$t('Equipments.Models.Edit.ShortName')"
                :rules="[v => !!v || $t('Equipments.Models.Edit.ShortNameRequired')]"
                type="text"
              />
              <v-select
                v-if="isSensor"
                v-model="formData.SensorType"
                :label="$t('Equipments.Models.Edit.SensorType')"
                :items="sensorType"
                :rules="[v => !!v || $t('Validation.Required')]"
                item-text="SensorTypeNameI18N"
                item-value="Name"
              />
              <v-text-field
                v-if="isSensor"
                v-model="formData.OutputType"
                :label="$t('Equipments.Models.Edit.OutputType')"
                :rules="[outputTypeValidation]"
                :maxlength="50"
                type="text"
              />
              <v-text-field
                v-if="isSensor"
                v-model.number="formData.OffsetFromTopOfPost"
                :label="$t('Equipments.Models.Edit.OffsetFromTopOfPost')"
                :rules="[offsetValidation]"
                type="number"
              />
              <v-text-field
                v-if="isWindvane"
                v-model.number="formData.WindvaneDeadband"
                :label="$t('Equipments.Models.Edit.WindvaneDeadband')"
                :rules="[deadbandTypeValidation]"
                type="number"
              />
              <v-text-field
                v-if="isTower"
                v-model.number="formData.TowerHeight"
                :label="$t('Equipments.Models.Edit.TowerHeight')"
                :rules="[towerHeightValidation]"
                type="number"
              />
              <v-checkbox
                v-if="isTower"
                v-model="formData.LatticeTower"
                :label="$t('Equipments.Models.Edit.LatticeTower')"
                type="checkbox"
              />
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              v-if="!readMode"
              text
              @click="submit"
            >
              {{ $t('Accept') }}
            </v-btn>
            <v-btn
              text
              @click.stop="show=false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <TowerModelForm
        v-model="showTowerModel"
        @on-accept="onTowerModelAccept"
      />
    </v-dialog>
    <ConfirmDialog
      v-model="showConfirm"
      :message="$t('Equipments.Models.Edit.ConfirmMessage')"
      :title="$t('Equipments.Models.Edit.ConfirmTitle')"
      @on-accept="getModel"
      @on-cancel="navigateToList"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import TowerModelForm from './TowerModelForm.vue';

export default {
  components: {
    ConfirmDialog,
    TowerModelForm,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      showTowerModel: false,
      showConfirm: false,
      errorMessage: '',
      readMode: !this.$auth.roles.includes('Web.Equipment.Model.Manage'),
    };
  },
  computed: {
    ...mapState('equipment/models', ['equipmentModel', 'nameAlreadyExists']),
    ...mapState('equipment/equipments', ['equipmentType', 'sensorType']),
    isEdit() {
      return this.formData.ModelId != null;
    },
    isBoom() {
      return this.formData.EquipmentType != null
      && this.formData.EquipmentType.EquipmentTypeId === 1;
    },
    isGinPole() {
      return this.formData.EquipmentType != null
      && this.formData.EquipmentType.EquipmentTypeId === 3;
    },
    isLogger() {
      return this.formData.EquipmentType != null
      && this.formData.EquipmentType.EquipmentTypeId === 4;
    },
    isSensor() {
      return this.formData.EquipmentType != null
      && this.formData.EquipmentType.EquipmentTypeId === 5;
    },
    isTower() {
      return this.formData.EquipmentType != null
      && this.formData.EquipmentType.EquipmentTypeId === 6;
    },
    isWindvane() {
      return this.formData.EquipmentType != null
      && this.formData.EquipmentType.EquipmentTypeId === 5
      && this.formData.SensorType != null
      && (this.formData.SensorType === 'Windvane' || this.formData.SensorType === 'Vanemometer');
    },
  },
  watch: {
    id() {
      if (!this.readMode) {
        this.showConfirm = true;
      }
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    await this.$store.dispatch('equipment/equipments/getEquipmentType');
    if (this.id && this.id != null) { // Update mode
      if (!this.readMode) {
        this.showConfirm = true;
      } else {
        this.getModel();
      }
    } else { // Create mode
      this.formData.TowerModel = {};
      this.show = true;
    }
  },
  methods: {
    async getModel() {
      await this.$store.dispatch('equipment/models/get', { id: this.id });
      this.formData = JSON.parse(JSON.stringify(this.equipmentModel));
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'Equipment.Models.List' });
    },
    async changeEquipementType() {
      if (this.formData.EquipmentType.EquipmentTypeId === 5) {
        await this.$store.dispatch('equipment/equipments/getSensorType');
      }
    },
    onChangeTower() {
      this.$store.dispatch('equipment/models/getTowerModels');
      this.showTowerModel = true;
    },
    onTowerModelAccept(payload) {
      this.formData.TowerModel.ManufacturerName = payload.ManufacturerName;
      this.formData.TowerModel.ModelName = payload.ModelName;
      this.formData.TowerModelId = payload.ModelId;
      this.showTowerModel = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (await this.validateName()) {
          await this.$store.dispatch('equipment/models/save', this.formData);
          this.navigateToList();
        }
      }
    },
    boomLenghtValidation(value) {
      if (value > 0) {
        return true;
      }
      return this.$t('Equipments.Models.Edit.BoomLengthRequired');
    },
    boomPostHeightValidation(value) {
      if (value > 0) {
        return true;
      }
      return this.$t('Equipments.Models.Edit.BoomPostHeightRequired');
    },
    outputTypeValidation(value) {
      if (value) {
        if (value.length > 50) {
          return this.$t('Equipments.Models.Edit.OutputType50CharMax');
        }
        return true;
      }
      return this.$t('Equipments.Models.Edit.OutputTypeRequired');
    },
    offsetValidation(value) {
      if (!value || !value.toString() || value < 0) {
        return this.$t('Equipments.Models.Edit.OffsetRequired');
      }
      return true;
    },
    deadbandTypeValidation(value) {
      if (!value.toString() || value < 0 || value >= 360) {
        return this.$t('Equipments.Models.Edit.DeadbandRequired');
      }
      return true;
    },
    towerHeightValidation(value) {
      if (!value || value <= 0) {
        return this.$t('Equipments.Models.Edit.TowerHeightRequired');
      }
      return true;
    },
    async validateName() {
      await this.$store.dispatch('equipment/models/validate', this.formData);
      if (this.nameAlreadyExists) {
        this.errorMessage = i18n.t('Equipments.Models.ModelNameExist');
      } else {
        this.errorMessage = '';
        return true;
      }
      return false;
    },
    cleanError() {
      this.errorMessage = '';
    },
  },
};
</script>
