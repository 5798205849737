<template>
  <div class="row">
    <div
      class="col col-4 site-modal"
      style="overflow:auto"
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right"
            style="margin-top:13px;margin-left:9px;min-width:40px;padding:0px;"
            v-bind="attrs"
            v-on="on"
            @click="toggleTreeview();"
          >
            <i class="v-icon mdi mdi-file-tree-outline" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.Attachments.ExpandCollapse') }} </span>
      </v-tooltip>
      <v-treeview
        ref="treeview"
        v-model="tree"
        style="padding: 5px; font-size: 14px; width: fit-content;"
        :items="nestedFolders"
        item-key="id"
      >
        <template #prepend="{ item }">
          <v-btn
            v-if="!item.file"
            class="folderIcon"
            icon
            color="amber lighten-1"
            @click="getFolderContent(item)"
          >
            <v-icon>mdi-folder</v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </div>
    <div
      class="col col-8"
      style="height: 100vh;"
    >
      <p style="font-size: 14px;">
        <span style="color:#777">{{ $t('WindServer.Sites.Attachments.SelectedFolder') }} :
        </span> {{ selectedFolderName }}
      </p>
      <SiteFilesListMain />
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import SiteFilesListMain from './SiteFilesListMain.vue';

export default {
  components: {
    SiteFilesListMain,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data: () => ({
    showFolderModal: false,
    showMenu: false,
    newFolder: {},
    dialog: {
      Message: '',
      NoCancel: false,
      Title: '',
      Show: false,
      acceptCallback: null,
    },
    tree: [],
    expanded: true,
  }),
  computed: {
    ...mapState('site/siteAttachments', ['siteAttachments', 'nestedFolders', 'selectedFolderName', 'selectedFolderId']),
  },

  watch: {
    nestedFolders: {
      handler() {
        this.$nextTick(() => {
          this.$refs.treeview.updateAll(this.expanded);
        });
      },
      immediate: true,
    },
  },

  async beforeCreate() {
    await this.$store.dispatch('site/siteAttachments/setSelectedFolderFiles', []);
    this.tree.push({ name: this.selectedFolderName, id: this.selectedFolderId });
    await this.getFolderContent(this.tree[0]);
  },
  methods: {

    async toggleTreeview() {
      this.expanded = !this.expanded;
      this.$refs.treeview.updateAll(this.expanded);
    },

    async getFolderContent(item) {
      await this.$store.dispatch('site/siteAttachments/setSelectedFolderId', item.id);
      await this.$store.dispatch('site/siteAttachments/setSelectedFolderName', item.name);
      await this.$store.dispatch('site/siteAttachments/getFolderFiles');
    },

    async addNewFolder() {
      await this.$store.dispatch('site/siteAttachments/create', this.newFolder);
      this.showFolderModal = false;
    },
  },
};
</script>

<style>
.v-treeview-node__root {
  margin-bottom: -19px;
}
.v-treeview-node__label {
  font-size: 13px;
}
.folderIcon {
  margin-left: -6px;
  margin-right: -5px;
}
</style>
