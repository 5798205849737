<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <div :class="{ 'error--text': noPeriods }">
          <FlagPeriodsList />
        </div>
        <div
          v-if="isEditMode"
          :class="{ 'error--text': noSensors }"
        >
          <SensorsList
            ref="sensorsList"
            @selection-changed="selectionChanged"
          />
        </div>
        <div>
          <v-card-text>
            <v-textarea
              v-model="formData.Details"
              :label="$t('WindServer.Sites.DataTrace.Details')"
              :autofocus="true"
              :rules="requiredRule"
              rows="3"
            />
            <v-select
              v-model="formData.QaCodeNavigation"
              :label="$t('WindServer.Sites.DataTrace.QaCode')"
              :items="qaCodes"
              item-text="DetailsI18N"
              item-value="QaCodeId"
              :rules="requiredRule"
              return-object
            />
          </v-card-text>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import FlagPeriodsList from './FlagPeriodsList.vue';
import SensorsList from './SensorsList.vue';

export default {
  components: {
    FlagPeriodsList,
    SensorsList,
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
    flag: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      formData: {
        Details: '',
      },
      requiredRule: [(v) => !!v || this.$t('Validation.Required')],
      isEditMode: false,
      nbSensors: 0,
    };
  },
  computed: {
    ...mapState('site/dataTrace', ['flagPeriods', 'qaCodes']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.formData = {
          Details: '',
        };
        this.$emit('input', value);
      },
    },
    noPeriods() {
      return this.flagPeriods == null || this.flagPeriods.length === 0;
    },
    noSensors() {
      return this.nbSensors === 0;
    },
  },
  created() {
  },
  methods: {
    async submit() {
      this.$nextTick(async () => {
        if (this.flagPeriods != null
        && this.flagPeriods.length !== 0
        && (!this.isEditMode || (this.isEditMode && this.nbSensors !== 0))
        && this.$refs.form.validate()) {
          if (this.isEditMode) {
            await this.setSensors();
            await this.$store.dispatch('site/dataTrace/updateFlags', this.formData);
            this.$emit('show-modal', true);
          } else {
            await this.$store.dispatch('site/dataTrace/createFlags', this.formData);
          }
          this.close();
        }
      });
    },
    close() {
      this.$emit('input', false);
      this.$emit('show-modal', false);
      this.$store.dispatch('site/dataTrace/initFlags', null);
      this.formData = {};
      this.$refs.form.reset();
      this.isEditMode = false;
    },
    async setData(s) {
      this.formData = { ...s };
      this.isEditMode = true;
      await this.$store.dispatch('site/dataTrace/flagPeriods', s.QaFlagSetPeriods);
      await this.$refs.sensorsList.selectSensors(s.QaFlagSetSensors);
    },
    selectionChanged(n) {
      this.nbSensors = n;
    },
    async setSensors() {
      await this.$refs.sensorsList.setSelectedSensors();
    },
  },
};
</script>
