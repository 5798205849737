/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsSites extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'SiteId',
          headerName: this.vue.$t('QualityCheck.QCInspections.Sites.Id'),
          width: 95,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Number',
          headerName: this.vue.$t('QualityCheck.QCInspections.Sites.Number'),
          width: 95,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'Name',
          headerName: this.vue.$t('QualityCheck.QCInspections.Sites.Name'),
          width: 490,
          editable: false,
          filter: true,
          sortable: true,
        },
      ]);
    });
  }
}
