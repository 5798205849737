<template>
  <div>
    <v-card-subtitle
      class="text-subtitle-2"
    >
      {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.EnterAlertInformation') }}
    </v-card-subtitle>
    <v-card-text>
      <v-text-field
        v-if="editingOnlyPdf"
        v-model="localAlertName"
        type="text"
        dense
      >
        <template #label>
          <div>
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.AlertName') }}
          </div>
        </template>
      </v-text-field>
      &nbsp;
      &nbsp;
      <v-text-field
        v-if="editingOnlyPdf"
        v-model="localLongName"
        type="text"
        dense
      >
        <template #label>
          <div>
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.AlertLongName') }}
          </div>
        </template>
      </v-text-field>
      &nbsp;
      &nbsp;
      <v-text-field
        v-if="editSensor"
        v-model="loclaSensorAlertName"
        type="text"
        dense
      >
        <template #label>
          <div>
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.SensorAlertName') }}
          </div>
        </template>
      </v-text-field>
      &nbsp;
      &nbsp;
      <v-text-field
        v-if="editingOnlyPdf"
        v-model="localAlertSubject"
        type="text"
        dense
      >
        <template #label>
          <div>
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.EmailSubject') }}
          </div>
        </template>
      </v-text-field>
      &nbsp;
      &nbsp;
      <v-textarea
        v-if="editingOnlyPdf"
        v-model="localAlertBody"
      >
        <template #label>
          <div>
            {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.EmailBody') }} <small>{{ $t('Dashboard.SubscribeMenu.SubscribeSettings.Optional') }}</small>
          </div>
        </template>
      </v-textarea>
      <v-select
        v-if="!editConfig"
        v-model="localDashboardList"
        :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.DashboardLists')"
        :items="dashboardsWNone"
        item-text="Name"
        item-value="DashboardId"
        return-object
        dense
      />
      <v-select
        v-if="editingOnlyPdf"
        v-model="localMailList"
        :label="$t('Dashboard.SubscribeMenu.SubscribeSettings.MailLists')"
        :items="mailLists"
        item-text="Name"
        item-value="MailListUid"
        return-object
        dense
      />
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    alertSubject: { type: String, default: () => null },
    alertName: { type: String, default: () => null },
    alertBody: { type: String, default: () => null },
    dashboardList: { type: Object, default: () => null },
    mailList: { type: Object, default: () => null },
    mailLists: { type: Array, default: () => null },
    dashboardsWNone: { type: Array, default: () => null },
    editSensor: { type: Boolean, default: () => false },
    editConfig: { type: Boolean, default: () => false },
    editPdf: { type: Boolean, default: () => false },
    alertLongName: { type: String, default: () => null },
    sensorAlertName: { type: String, default: () => null },
  },
  data() {
    return {
      localMailList: this.mailList,
      localDashboardList: this.dashboardList,
      localAlertBody: this.alertBody,
      localAlertSubject: this.alertSubject,
      localAlertName: this.alertName,
      localLongName: this.alertLongName,
      loclaSensorAlertName: this.sensorAlertName,
    };
  },
  computed: {
    editingOnlyPdf() {
      if (this.editSensor) {
        return false;
      }
      if (this.editPdf) {
        return false;
      }
      return true;
    },
  },
  watch: {
    localMailList(newValue) {
      this.$emit('update:MailList', newValue);
    },
    localDashboardList(newValue) {
      this.$emit('update:DashboardList', newValue);
    },
    localAlertBody(newValue) {
      this.$emit('update:AlertBody', newValue);
    },
    localAlertSubject(newValue) {
      this.$emit('update:AlertSubject', newValue);
    },
    localAlertName(newValue) {
      this.$emit('update:AlertName', newValue);
    },
    localLongName(newValue) {
      this.$emit('update:LongName', newValue);
    },
    loclaSensorAlertName(newValue) {
      this.$emit('update:SensorAlertName', newValue);
    },
  },
};
</script>

<style scoped>
</style>
