<template>
  <div>
    <v-card-title class="px-3 pt-3 pb-0">
      <p class="my-0">
        {{ $t(graphText) }}
      </p>
    </v-card-title>
    <v-card-title class="px-3 pt-0 pb-3 mb-3">
      <p class="my-0">
        {{ $t('Dashboard.VisualizationSetting.VisualizationSetting') }}
      </p>
    </v-card-title>
    <v-row>
      <v-col>
        <v-slider
          v-model="cluster"
          :max="255"
          :min="1"
          label="Cluster range size"
          class="align-center"
        >
          <template #append>
            <v-text-field
              v-model="cluster"
              class="mt-0 pt-0"
              type="number"
              style="width: 60px"
            />
          </template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card-text>
          <v-slider
            v-model="tickSize"
            :tick-labels="ticksLabels"
            :max="48"
            :min="12"
            step="12"
            ticks="always"
            tick-size="4"
            label="Icon size"
            class="align-center"
          />
        </v-card-text>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn
        class="mr-4"
        @click="cancel"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn @click="save">
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
  },
  props: {
    visualisation: {
      type: String,
      default: () => null,
      required: true,
    },
    graphType: {
      type: String,
      default: () => null,
      required: true,
    },
    graphStyle: {
      type: String,
      default: () => null,
      required: true,
    },
    graphText: {
      type: String,
      default: () => '',
      required: false,
    },
  },
  data: () => ({
    model: {
      widgetId: null,
      enabled: true,
      visible: true,
      axisWidth: 4,
      axisHeight: 200,
      axisX: 0,
      axisY: 0,
      axisLock: false,
    },
    cluster: 80,
    tickSize: 36,
    ticksLabels: [
      'Tiny',
      'Small',
      'Medium',
      'Large',
    ],
    form: {
    },
  }),
  computed: {
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    ...mapGetters('app', ['pleaseWait']),

    isNew() {
      return this.activeWidget === undefined;
    },
    jsonWidgetSettings() {
      const query = {
        user: {
          mapOptions: {
            iconsize: this.tickSize,
            cluster: this.cluster,
          },
        },
      };
      return JSON.stringify(query);
    },
  },
  watch: {
    activeWidget() {
      this.init();
    },
    editModeFlag(val) {
      if (!val) {
        this.clearWidgetSettings();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.clearWidgetSettings();
      if (!this.isNew) {
        this.loadWidgetSettings();
      }
    },
    clearWidgetSettings() {
    },
    loadWidgetSettings() {
      const widgetSettings = JSON.parse(this.activeWidget.WidgetSettings);
      if (widgetSettings.user
        && widgetSettings.user.mapOptions) {
        this.tickSize = widgetSettings.user.mapOptions.iconsize;
        this.cluster = widgetSettings.user.mapOptions.cluster;
      }
    },
    cancel() {
      this.$emit('cancel', this.isNew);
    },
    save() {
      if (this.isNew) {
        this.create();
      } else {
        this.saveWidgetSettings();
      }
    },
    create() {
      this.$emit('create', {
        DashboardsWidgetId: this.activeWidgetId,
        DatasourceSettings: '', // TODO check if this property is useful, it seems that WidgetSettings do it all
        WidgetSettings: this.jsonWidgetSettings,
        WidgetId: this.model.widgetId,
        Enabled: this.model.enabled,
        Visible: this.model.visible,
        AxisHeight: this.model.axisHeight,
        AxisWidth: this.model.axisWidth,
        AxisY: this.model.axisY,
        AxisX: this.model.axisX,
        AxisLock: this.model.axisLock,
      });
    },
    saveWidgetSettings() {
      this.$emit('save', {
        DashboardsWidgetId: this.activeWidgetId,
        WidgetSettings: this.jsonWidgetSettings,
      });
    },
  },
};
</script>

<style>

</style>
