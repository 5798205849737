<template>
  <v-row
    v-if="placeholders.length > 0"
    justify="start"
    class="mt-0 pb-2 align-self-end"
  >
    <v-col
      v-for="placeholder in placeholderItems"
      :key="placeholder.placeholderId"
      class="py-0 align-self-end"
      cols="2"
    >
      <placeholder-drop-down
        :placeholder="placeholder"
        @change="updateSelectedItem(placeholder, $event)"
        @more="getPlaceholderItems(placeholder)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import api from '@/api/importal';
import PlaceholderDropDown from './PlaceholderDropDown.vue';

function filterPlaceholder(jsonData, sensorOffset, vectorOffset, itemCount) {
  let params = '';
  let sensorCount = 0;
  let vectorCount = 0;
  let searchInstrumentTerm = '';
  let searchSensorTerm = '';
  let instAttributeName = '';
  let instAttributeValue = '';
  let sensorAttributeName = '';
  let sensorAttributeValue = '';
  let searchSensorUnit = '';

  // 1
  if (jsonData.RuleType === 'Instrument') {
    sensorCount = itemCount;
    vectorCount = itemCount;
    params += `so=${sensorOffset}&sc=${sensorCount}&vo=${vectorOffset}&vc=${vectorCount}`;

    if (jsonData.ValueType === 'Name') {
      params += `&searchInstrument=${jsonData.Value}`;
      searchInstrumentTerm = jsonData.Value;
    } else if (jsonData.ValueType === 'Attribute') {
      instAttributeName = jsonData.ValueName;
      instAttributeValue = jsonData.Value;
      params += `&instrumentAttrName=${jsonData.ValueName}`.concat(`&instrumentAttrValue=${jsonData.Value}`);
    }
  } else if (jsonData.RuleType === 'Sensor') {
    sensorCount = itemCount;
    params += `so=${sensorOffset}&sc=${sensorCount}&vo=${vectorOffset}&vc=${vectorCount}`;

    if (jsonData.ValueType === 'Name') {
      params += `&searchSensor=${jsonData.Value}`;
      searchSensorTerm = jsonData.Value;
    } else if (jsonData.ValueType === 'Unit') {
      searchSensorUnit = jsonData.Value;
      params += `&unitCategory=${jsonData.Value}`;
    } else if (jsonData.ValueType === 'Attribute') {
      sensorAttributeName = jsonData.ValueName;
      sensorAttributeValue = jsonData.Value;
      params += `&sensorAttrName=${jsonData.ValueName}`.concat(`&sensorAttrValue=${jsonData.Value}`);
    }
  } else if (jsonData.RuleType === 'Vector') {
    vectorCount = itemCount;
    params += `so=${sensorOffset}&sc=${sensorCount}&vo=${vectorOffset}&vc=${vectorCount}`;

    if (jsonData.ValueType === 'Name') {
      params += `&searchSensor=${jsonData.Value}`;
    } else {
      params += `&unitCategory=${jsonData.Value}`;
    }
  }

  // 2
  if (jsonData.RuleType2 === 'Instrument') {
    if (jsonData.ValueType2 === 'Name') {
      params += `&searchInstrument=${jsonData.Value2}`;
      searchInstrumentTerm = jsonData.Value2;
    } else if (jsonData.ValueType2 === 'Attribute') {
      instAttributeName = jsonData.ValueName2;
      instAttributeValue = jsonData.Value2;
      params += `&instrumentAttrName=${jsonData.ValueName2}`.concat(`&instrumentAttrValue=${jsonData.Value2}`);
    }
  } else if (jsonData.RuleType2 === 'Sensor') {
    if (jsonData.ValueType2 === 'Name') {
      params += `&searchSensor=${jsonData.Value2}`;
      searchSensorTerm = jsonData.Value2;
    } else if (jsonData.ValueType2 === 'Unit') {
      searchSensorUnit = jsonData.Value2;
      params += `&unitCategory=${jsonData.Value2}`;
    } else if (jsonData.ValueType2 === 'Attribute') {
      sensorAttributeName = jsonData.ValueName2;
      sensorAttributeValue = jsonData.Value2;
      params += `&sensorAttrName=${jsonData.ValueName2}`.concat(`&sensorAttrValue=${jsonData.Value2}`);
    }
  } else if (jsonData.RuleType2 === 'Vector') {
    if (jsonData.ValueType2 === 'Name') {
      params += `&searchSensor=${jsonData.Value2}`;
    } else {
      params += `&unitCategory=${jsonData.Value2}`;
    }
  }

  // 3
  if (jsonData.RuleType3 === 'Instrument') {
    if (jsonData.ValueType3 === 'Name') {
      params += `&searchInstrument=${jsonData.Value3}`;
      searchInstrumentTerm = jsonData.Value3;
    } else if (jsonData.ValueType3 === 'Attribute') {
      instAttributeName = jsonData.ValueName3;
      instAttributeValue = jsonData.Value3;
      params += `&instrumentAttrName=${jsonData.ValueName3}`.concat(`&instrumentAttrValue=${jsonData.Value3}`);
    }
  } else if (jsonData.RuleType3 === 'Sensor') {
    if (jsonData.ValueType3 === 'Name') {
      params += `&searchSensor=${jsonData.Value3}`;
      searchSensorTerm = jsonData.Value3;
    } else if (jsonData.ValueType3 === 'Unit') {
      searchSensorUnit = jsonData.Value;
      params += `&unitCategory=${jsonData.Value3}`;
    } else if (jsonData.ValueType3 === 'Attribute') {
      sensorAttributeName = jsonData.ValueName3;
      sensorAttributeValue = jsonData.Value3;
      params += `&sensorAttrName=${jsonData.ValueName3}`.concat(`&sensorAttrValue=${jsonData.Value3}`);
    }
  } else if (jsonData.RuleType3 === 'Vector') {
    if (jsonData.ValueType3 === 'Name') {
      params += `&searchSensor=${jsonData.Value3}`;
    } else {
      params += `&unitCategory=${jsonData.Value3}`;
    }
  }

  return {
    params,
    searchInstrumentTerm,
    searchSensorTerm,
    searchSensorUnit,
    instAttributeName,
    instAttributeValue,
    sensorAttributeName,
    sensorAttributeValue,
    sensorCount,
    vectorCount,
  };
}

export default {
  name: 'PlaceholderStack',
  components: {
    PlaceholderDropDown,
  },
  data() {
    return {
      placeholderItems: [],
    };
  },
  computed: {
    ...mapState('dashboardPlaceholders', ['placeholders']),
    ...mapGetters('dashboardPlaceholders', ['defaultName', 'defaultAlias']),
    ...mapGetters('app', ['tenantId', 'accessGroupIds']),

    itemCount: () => 100,
  },
  watch: {
    async accessGroupIds(newVal, oldVal) {
      if (oldVal.join() !== newVal.join()) {
        this.placeholderItems.splice(0, this.placeholderItems.length);
        this.updatePlaceholderItems();
      }
    },
    async placeholders() {
      await this.updatePlaceholderItems();
    },
    async selectedPlaceholderSensors() {
      await this.updatePlaceholderItems();
    },
  },
  async created() {
    await this.updatePlaceholderItems();
  },
  methods: {
    async updatePlaceholderItems() {
      let loadingRequired = false;
      await this.placeholders.forEach((x) => {
        let item = this.placeholderItems.at(x.Order);

        while (item && item.Data.PlaceholderId !== x.PlaceholderId) {
          // Remove deleted placeholder
          this.placeholderItems.splice(x.Order, 1);
          item = this.placeholderItems.at(x.Order);
        }

        if (item) {
          // update
          const jsonData = item.Data.JsonData;
          jsonData.Name = x.JsonData.Name || this.defaultName(x);
          jsonData.Alias = x.JsonData.Alias || this.defaultAlias(true);

          if (
            // DropDownType or DropDownValue
            jsonData.DropdownType !== x.JsonData.DropdownType
            || jsonData.DropdownAttributeName !== x.JsonData.DropdownAttributeName
            // 1
            || jsonData.RuleType !== x.JsonData.RuleType
            || jsonData.ValueType !== x.JsonData.ValueType
            || jsonData.ValueName !== x.JsonData.ValueName
            || jsonData.Value !== x.JsonData.Value
            // 2
            || jsonData.RuleType2 !== x.JsonData.RuleType2
            || jsonData.ValueType2 !== x.JsonData.ValueType2
            || jsonData.ValueName2 !== x.JsonData.ValueName2
            || jsonData.Value2 !== x.JsonData.Value2
            // 3
            || jsonData.RuleType3 !== x.JsonData.RuleType3
            || jsonData.ValueType3 !== x.JsonData.ValueType3
            || jsonData.ValueName3 !== x.JsonData.ValueName3
            || jsonData.Value3 !== x.JsonData.Value3
          ) {
            jsonData.DropdownType = x.JsonData.DropdownType;
            jsonData.DropdownAttributeName = x.JsonData.DropdownAttributeName;
            // 1
            jsonData.RuleType = x.JsonData.RuleType;
            jsonData.ValueType = x.JsonData.ValueType;
            jsonData.ValueName = x.JsonData.ValueName;
            jsonData.Value = x.JsonData.Value;
            // 2
            jsonData.RuleType2 = x.JsonData.RuleType2;
            jsonData.ValueType2 = x.JsonData.ValueType2;
            jsonData.ValueName2 = x.JsonData.ValueName2;
            jsonData.Value2 = x.JsonData.Value2;
            // 3
            jsonData.RuleType3 = x.JsonData.RuleType3;
            jsonData.ValueType3 = x.JsonData.ValueType3;
            jsonData.ValueName3 = x.JsonData.ValueName3;
            jsonData.Value3 = x.JsonData.Value3;
            jsonData.SelectedItem = [];

            item.Items = [{ Name: this.$t('Loading') }];
            loadingRequired = true;
            this.$store.dispatch('dashboardPlaceholders/updateSelectedSensor', {
              placeholderId: item.Data.PlaceholderId,
              selectedItem: null,
            });
          }
        } else {
          // add
          const data = JSON.parse(JSON.stringify(x));
          data.JsonData.Name = x.JsonData.Name || this.defaultName(x);
          data.JsonData.Alias = x.JsonData.Alias || this.defaultAlias(true);

          this.placeholderItems.push({
            Data: data,
            Items: [{ Name: this.$t('Loading') }],
          });
          loadingRequired = true;
        }
      });

      this.placeholderItems.splice(this.placeholders.length);

      if (loadingRequired) {
        this.loadItemList();
      }
    },
    async loadItemList() {
      this.placeholderItems.forEach(async (x) => {
        if (x.Items.length === 1 && !x.Items[0].Id) {
          await this.getPlaceholderItems(x);
        }
      });
    },

    async getPlaceholderItems(item) {
      const placeholder = item;

      const sensorOffset = placeholder.Items.filter((x) => x.T === 'S').length;
      const vectorOffset = placeholder.Items.filter((x) => x.T === 'V').length;

      const jsonData = placeholder.Data.JsonData;

      const {
        params,
        searchInstrumentTerm,
        searchSensorTerm,
        searchSensorUnit,
        instAttributeName,
        instAttributeValue,
        sensorAttributeName,
        sensorAttributeValue,
        sensorCount,
        vectorCount,
      } = filterPlaceholder(jsonData, sensorOffset, vectorOffset, this.itemCount);

      if (jsonData.DropdownType === 'Sensor or Vector') {
        const data = await (await api.get(`SensorList?${params}&sortByInstrument=true`)).data;

        if (placeholder.Items.length === 1 && !placeholder.Items[0].Id) {
          placeholder.Items.splice(0);
        } else if (placeholder.Items[placeholder.Items.length - 1].Id === 'More') {
          placeholder.Items.splice(placeholder.Items.length - 1, 1);
        }

        const dataToAdd = [];
        const dataToAdd2 = [];
        const sensors = data.filter((x) => x.T === 'S');
        if (sensors.length > 0) {
          dataToAdd.push(...sensors);
        }

        const vectors = data.filter((x) => x.T === 'V');
        if (sensors.length < this.itemCount && vectors.length > 0) {
          dataToAdd.push(...vectors);
        }

        if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
          await Promise.all(jsonData.SelectedItem.map(async (selectedInfo) => {
            let selected = dataToAdd.find((x) => this.isSameAsSelected(x, selectedInfo));
            if (placeholder.Items.length === 0) {
              // Ensure the selectedItem is there
              if (!selected) {
                if (selectedInfo.T === 'S') {
                  const dataSensor = await (await api.get(`DevSensorList?SensorId=${selectedInfo.Id}`)).data;
                  if (dataSensor.length > 0) {
                    const sensor = dataSensor[0];
                    selected = {
                      T: 'S',
                      Id: sensor.SensorId,
                      Name: sensor.Name,
                      InstrumentId: sensor.InstrumentId,
                      InstrumentName: sensor.InstrumentName,
                      Unit: sensor.Unit,
                      selected: true,
                    };
                  }
                } else {
                  const dataVector = await (await api.get('DevVectorList?'.concat(`InstrumentId=${selectedInfo.InstrumentId}&Name=${selectedInfo.Name}`))).data;
                  if (dataVector.length > 0) {
                    const vector = dataVector[0];
                    selected = {
                      T: 'V',
                      Id: vector.VectorSensorId,
                      Name: vector.Name,
                      InstrumentId: vector.InstrumentId,
                      InstrumentName: vector.InstrumentName,
                      ReadingUnit: vector.ReadingUnit,
                      selected: true,
                    };
                  }
                }

                if (selected) {
                  dataToAdd.push(selected);
                  // Save the item as tempItem (to remove it when we will get it with the data)
                  placeholder.TempItem = selected;
                } else {
                  // Item not found
                  this.updateSelectedItem(placeholder, null);
                }
              }
            } else if (selected) {
              // Remove the temp selectedItem (last item)
              placeholder.Items.pop();
              delete placeholder.TempItem;
            } else if (placeholder.TempItem) {
              // Move the temp selectedItem at the end
              const tempItem = placeholder.Items.pop();
              dataToAdd.push(tempItem);
            }
          }));
        }

        if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
          jsonData.SelectedItem = jsonData.SelectedItem.map((item2) => {
            const match1 = dataToAdd.find(
              (item1) => item1.T === item2.T
              && item1.Id === item2.Id
              && item1.Name === item2.Name
              && item1.InstrumentId === item2.InstrumentId,
            );

            if (match1) {
              return {
                T: match1.T,
                Id: match1.Id,
                Name: match1.Name,
                InstrumentId: match1.InstrumentId,
                InstrumentName: match1.Name,
                Unit: match1.Unit,
                selected: match1.selected,
              };
            }

            return item2; // If no match, keep the original item2
          });
          this.$store.dispatch('dashboardPlaceholders/updateSelectedSensor', {
            placeholderId: placeholder.Data.PlaceholderId,
            selectedItem: jsonData.SelectedItem,
          });
          dataToAdd.forEach((item2) => {
            const match = jsonData.SelectedItem.some(
              (item1) => item1.T === item2.T
              && item1.Id === item2.Id
              && item1.Name === item2.Name
              && item1.InstrumentId === item2.InstrumentId,
            );

            const newItem = { ...item2, selected: match };
            dataToAdd2.push(newItem);
          });
        } else {
          dataToAdd.forEach((item1) => {
            dataToAdd2.push(item1);
          });
        }

        if ((sensorCount > 0 && sensors.length >= sensorCount)
          || (vectorCount > 0 && vectors.length >= vectorCount)) {
          dataToAdd2.push({ Id: 'More', Name: this.$t('Dashboard.Placeholder.More') });
        }

        placeholder.Items.push(...dataToAdd2);
      } else if (jsonData.DropdownType === 'Instrument') {
        // DO STUFF FOR INSTRUMENT
        const instrumentOffset = 0;
        const instrumentIds = [];

        const data = await (await api.get('InstrumentListForSensor?'
          + `siteInstrumentIds=${instrumentIds}`
          + `&offset=${instrumentOffset}&sc=100&vc=100`
          + `&searchInstrument=${searchInstrumentTerm}`
          + `&searchSensor=${searchSensorTerm}&unitCategory=${searchSensorUnit}`
          + `&searchSensorAttrName=${sensorAttributeName}`
          + `&searchSensorAttrValue=${sensorAttributeValue}`
          + `&searchInstAttrName=${instAttributeName}`
          + `&searchInstAttrValue=${instAttributeValue}`)).data;

        if (placeholder.Items.length === 1 && !placeholder.Items[0].Id) {
          placeholder.Items.splice(0);
        } else if (placeholder.Items[placeholder.Items.length - 1].Id === 'More') {
          placeholder.Items.splice(placeholder.Items.length - 1, 1);
        }

        const dataToAdd = [];
        const dataToAdd2 = [];
        dataToAdd.push(...data);

        const newData = [];
        const oldData = [];

        // CHARGE ALL THE SENSORS FROM THE INSTRUMENT THAT WERE ALREADY SELECTED IN THE DB
        if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
          await Promise.all(jsonData.SelectedItem.map(async (selectedInfo) => {
            const selected = dataToAdd.find(
              (x) => this.isSameAsSelectedInstrument(x, selectedInfo),
            );
            const sensorList = [];
            if (selected) {
              // Selected Sensor -> It was in the DB already and is part of the current Tenant
              const dataSensor = await (await api.get(
                `SensorListForInstrument?instrumentId=${selectedInfo.Id}`
                  + `&vo=0&so=0&sc=${sensorCount}&vc=${vectorCount}`
                  + `&searchInstrument=${searchInstrumentTerm}`
                  + `&searchSensor=${searchSensorTerm}`
                  + `&instrumentAttrName=${instAttributeName}`
                  + `&instrumentAttrValue=${instAttributeValue}`
                  + `&unitCategory=${searchSensorUnit}`
                  + `&sensorAttrName=${sensorAttributeName}`
                  + `&sensorAttrValue=${sensorAttributeValue}`
                ,
              )).data;
              dataSensor.forEach((x) => {
                if (x.InstrumentId === selectedInfo.Id) {
                  sensorList.push(x);
                }
              });
              oldData.push({
                InstrumentId: selectedInfo.Id,
                sensorList,
                selected: true,
              });
              // Need to take out selected from the placeholder.Items
              placeholder.Items.pop();
            } else {
              // The instrument is selected, but not part of the current Tenant
              const dataSensor = await (await api.get(
                `SensorListForInstrument?instrumentId=${selectedInfo.Id}`
                  + `&vo=0&so=0&sc=${sensorCount}&vc=${vectorCount}`
                  + `&searchInstrument=${searchInstrumentTerm}`
                  + `&searchSensor=${searchSensorTerm}`
                  + `&instrumentAttrName=${instAttributeName}`
                  + `&instrumentAttrValue=${instAttributeValue}`
                  + `&unitCategory=${searchSensorUnit}`
                  + `&sensorAttrName=${sensorAttributeName}`
                  + `&sensorAttrValue=${sensorAttributeValue}`
                ,
              )).data;
              dataSensor.forEach((x) => {
                if (x.InstrumentId === selectedInfo.Id) {
                  sensorList.push(x);
                }
              });
              oldData.push({
                InstrumentId: selectedInfo.Id,
                sensorList,
                selected: true,
              });
            }
          }));
        }

        // CHARGE THE INSTRUMENTS THAT ARE NOT IN THE DB
        await Promise.all(dataToAdd.map(async (toBeAddedData) => {
          let alreadyAdded = false;
          if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
            alreadyAdded = jsonData.SelectedItem.find(
              (x) => this.isSameAsSelectedInstrument(x, toBeAddedData),
            );
          }
          if (!alreadyAdded) {
            newData.push({
              InstrumentId: toBeAddedData.Id,
              sensorList: [],
              selected: false,
            });
          }
        }));

        if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
          jsonData.SelectedItem.forEach((item1) => {
            if (oldData.length > 0) {
              const rightData = oldData.find((item2) => item1.Id === item2.InstrumentId);
              if (rightData) {
                if (item1.InstrumentName) {
                  const newElem = {
                    ...item1,
                    sensorList: rightData.sensorList,
                    selected: rightData.selected,
                  };
                  dataToAdd2.push(newElem);
                  const selectedItem = [];
                  selectedItem.push(newElem);
                  this.$store.dispatch('dashboardPlaceholders/updateSelectedSensorFromInstrument', {
                    placeholderId: placeholder.Data.PlaceholderId,
                    selectedItem,
                  });
                } else {
                  const newElem = {
                    ...item1,
                    InstrumentName: item1.Name,
                    DisplayName: item1.Name,
                    sensorList: rightData.sensorList,
                    selected: rightData.selected,
                  };
                  dataToAdd2.push(newElem);
                  const selectedItem = [];
                  selectedItem.push(newElem);
                  this.$store.dispatch('dashboardPlaceholders/updateSelectedSensorFromInstrument', {
                    placeholderId: placeholder.Data.PlaceholderId,
                    selectedItem,
                  });
                }
              }
            }
          });
        }

        dataToAdd.forEach((item1) => {
          if (newData.length > 0) {
            const rightData = newData.find((item2) => item1.Id === item2.InstrumentId);
            if (rightData) {
              // If not here, it was oldData and was already added
              const newElem = {
                ...item1,
                sensorList: rightData.sensorList,
                selected: rightData.selected,
              };
              dataToAdd2.push(newElem);
            }
          }
        });

        placeholder.Items.push(...dataToAdd2);
      } else if (jsonData.DropdownType === 'Instrument attributes') {
        // DO STUFF FOR INSTRUMENT ATTRIBUTE

        const data = await (await api.get('InstrumentAttributeList?'
          + `offset=0&sc=${sensorCount}&vc=${vectorCount}`
          + `&searchInstrument=${searchInstrumentTerm}`
          + `&searchSensor=${searchSensorTerm}`
          + `&sensorAttrName=${sensorAttributeName}`
          + `&sensorAttrValue=${sensorAttributeValue}`
          + `&unitCategory=${searchSensorUnit}`
          + `&instrumentAttrName=${jsonData.DropdownAttributeName}`
          + `&instrumentAttrValue=${instAttributeValue}`)).data;

        if (placeholder.Items.length === 1 && !placeholder.Items[0].Id) {
          placeholder.Items.splice(0);
        } else if (placeholder.Items[placeholder.Items.length - 1].Id === 'More') {
          placeholder.Items.splice(placeholder.Items.length - 1, 1);
        }

        const dataToAdd = [];
        const dataToAdd2 = [];
        dataToAdd.push(...data);

        const newData = [];
        const oldData = [];

        // CHARGE ALL THE SENSORS THAT HAVE THE  WERE ALREADY SELECTED IN THE DB
        if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
          await Promise.all(jsonData.SelectedItem.map(async (selectedInfo) => {
            const selected = dataToAdd.find(
              (x) => this.isSameAsSelectedInstrumentAttribute(x, selectedInfo),
            );
            const sensorList = [];
            if (selected) {
              // Selected Sensor -> It was in the DB already and is part of the current Tenant
              const dataInstrument = await (await api.get('InstrumentListForSensor?'
              + `siteInstrumentIds=${[]}`
              + `&offset=0&sc=${sensorCount}&vc=${vectorCount}`
              + `&searchInstrument=${searchInstrumentTerm}`
              + `&searchSensor=${searchSensorTerm}`
              + `&sensorAttrName=${sensorAttributeName}`
              + `&sensorAttrValue=${sensorAttributeValue}`
              + `&searchInstAttrName=${selectedInfo.Name}`
              + `&searchInstAttrValue=${selectedInfo.Value}`)).data;
              await Promise.all(dataInstrument.map(async (x) => {
                const dataSensor = await (await api.get(
                  `SensorListForInstrument?instrumentId=${x.Id}`
                  + `&vo=0&so=0&sc=${sensorCount}&vc=${vectorCount}`
                  + `&searchInstrument=${searchInstrumentTerm}`
                  + `&searchSensor=${searchSensorTerm}`
                  + `&instrumentAttrName=${instAttributeName}`
                  + `&instrumentAttrValue=${instAttributeValue}`
                  + `&unitCategory=${searchSensorUnit}`
                  + `&sensorAttrName=${sensorAttributeName}`
                  + `&sensorAttrValue=${sensorAttributeValue}`,
                )).data;
                dataSensor.forEach((y) => {
                  if (x.Id === y.InstrumentId) {
                    sensorList.push(y);
                  }
                });
              }));
              oldData.push({
                AttributeValue: selectedInfo.Value,
                sensorList,
                selected: true,
              });
            } else {
              // The value is not part of the tenant
              const dataInstrument = await (await api.get('InstrumentListForSensorNoTenant?'
              + `siteInstrumentIds=${[]}`
              + `&offset=0&sc=${sensorCount}&vc=${vectorCount}`
              + `&searchInstrument=${searchInstrumentTerm}`
              + `&searchSensor=${searchSensorTerm}`
              + `&instrumentAttrName=${selectedInfo.Name}`
              + `&instrumentAttrValue=${selectedInfo.Value}`
              + `&searchSensorAttrName=${sensorAttributeName}`
              + `&searchSensorAttrValue=${sensorAttributeValue}`)).data;
              await Promise.all(dataInstrument.map(async (x) => {
                const dataSensor = await (await api.get(
                  `SensorListForInstrument?instrumentId=${x.Id}`
                  + `&vo=0&so=0&sc=${sensorCount}&vc=${vectorCount}`
                  + `&searchInstrument=${searchInstrumentTerm}`
                  + `&searchSensor=${searchSensorTerm}`
                  + `&unitCategory=${searchSensorUnit}`
                  + `&instrumentAttrName=${selectedInfo.Name}`
                  + `&instrumentAttrValue=${selectedInfo.Value}`
                  + `&sensorAttrName=${sensorAttributeName}`
                  + `&sensorAttrValue=${sensorAttributeValue}`,
                )).data;
                dataSensor.forEach((y) => {
                  if (x.Id === y.InstrumentId) {
                    sensorList.push(y);
                  }
                });
              }));
              oldData.push({
                AttributeValue: selectedInfo.Value,
                sensorList,
                selected: true,
              });
            }
          }));
        }

        // CHARGE THE INSTRUMENTS WITH CORRESPONDING ATTRIBUTE THAT ARE NOT IN THE DB
        await Promise.all(dataToAdd.map(async (toBeAddedData) => {
          let alreadyAdded = false;
          if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
            alreadyAdded = jsonData.SelectedItem.find(
              (x) => this.isSameAsSelectedInstrumentAttribute(x, toBeAddedData),
            );
          }
          const sensorList = [];
          if (!alreadyAdded) {
            newData.push({
              AttributeValue: toBeAddedData.Value,
              sensorList,
              selected: false,
            });
          }
        }));

        if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
          jsonData.SelectedItem.forEach((item1) => {
            if (oldData.length > 0) {
              const rightData = oldData.find((item2) => item1.Value === item2.AttributeValue);
              if (rightData) {
                if (item1.InstrumentName) {
                  const newElem = {
                    ...item1,
                    sensorList: rightData.sensorList,
                    selected: rightData.selected,
                  };
                  dataToAdd2.push(newElem);
                  const selectedItem = [];
                  selectedItem.push(newElem);
                  this.$store.dispatch('dashboardPlaceholders/updateSelectedSensorFromInstrument', {
                    placeholderId: placeholder.Data.PlaceholderId,
                    selectedItem,
                  });
                } else {
                  const newElem = {
                    ...item1,
                    InstrumentName: item1.Name,
                    DisplayName: item1.Name,
                    sensorList: rightData.sensorList,
                    selected: rightData.selected,
                  };
                  dataToAdd2.push(newElem);
                  const selectedItem = [];
                  selectedItem.push(newElem);
                  this.$store.dispatch('dashboardPlaceholders/updateSelectedSensorFromInstAttribute', {
                    placeholderId: placeholder.Data.PlaceholderId,
                    selectedItem,
                  });
                }
              }
            }
          });
        }

        dataToAdd.forEach((item1) => {
          if (newData.length > 0) {
            const rightData = newData.find((item2) => item1.Value === item2.AttributeValue);
            if (rightData) {
              const newElem = {
                ...item1,
                sensorList: rightData.sensorList,
                selected: rightData.selected,
              };
              dataToAdd2.push(newElem);
            }
          }
        });

        placeholder.Items.push(...dataToAdd2);
      } else if (jsonData.DropdownType === 'Sensor attributes') {
        // SENSOR ATTRIBUTES

        const data = await (await api.get('SensorAttributeListForInstrument?'
          + `offset=0&sc=${sensorCount}`
          + `&searchInstrument=${searchInstrumentTerm}`
          + `&searchSensor=${searchSensorTerm}`
          + `&sensorAttrName=${jsonData.DropdownAttributeName}`
          + `&unitCategory=${searchSensorUnit}`
          + `&sensorAttrValue=${sensorAttributeValue}`
          + `&instrumentAttrName=${instAttributeName}`
          + `&instrumentAttrValue=${instAttributeValue}`)).data;

        if (placeholder.Items.length === 1 && !placeholder.Items[0].Id) {
          placeholder.Items.splice(0);
        } else if (placeholder.Items[placeholder.Items.length - 1].Id === 'More') {
          placeholder.Items.splice(placeholder.Items.length - 1, 1);
        }

        const dataToAdd = [];
        const dataToAdd2 = [];
        dataToAdd.push(...data);

        const newData = [];
        const oldData = [];

        // CHARGE ALL THE SENSORS THAT HAVE THE  WERE ALREADY SELECTED IN THE DB
        if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
          await Promise.all(jsonData.SelectedItem.map(async (selectedInfo) => {
            const selected = dataToAdd.find(
              (x) => this.isSameAsSelectedInstrumentAttribute(x, selectedInfo),
            );
            const sensorList = [];
            if (selected) {
              // Selected Sensor -> It was in the DB already and is part of the current Tenant
              const dataInstrument = await (await api.get('InstrumentListForSensor?'
              + `siteInstrumentIds=${[]}`
              + `&offset=0&sc=${sensorCount}&vc=${vectorCount}`
              + `&searchInstrument=${searchInstrumentTerm}`
              + `&searchSensor=${searchSensorTerm}`
              + `&instrumentAttrName=${instAttributeName}`
              + `&instrumentAttrValue=${instAttributeValue}`
              + `&searchSensorAttrName=${selectedInfo.Name}`
              + `&searchSensorAttrValue=${selectedInfo.Value}`)).data;
              await Promise.all(dataInstrument.map(async (x) => {
                const dataSensor = await (await api.get(
                  `SensorListForInstrument?instrumentId=${x.Id}`
                  + `&vo=0&so=0&sc=${sensorCount}&vc=${vectorCount}`
                  + `&searchInstrument=${searchInstrumentTerm}`
                  + `&searchSensor=${searchSensorTerm}`
                  + `&instrumentAttrName=${instAttributeName}`
                  + `&instrumentAttrValue=${instAttributeValue}`
                  + `&sensorAttrName=${selectedInfo.Name}`
                  + `&sensorAttrValue=${selectedInfo.Value}`,
                )).data;
                dataSensor.forEach((y) => {
                  if (x.Id === y.InstrumentId) {
                    sensorList.push(y);
                  }
                });
              }));
              oldData.push({
                AttributeValue: selectedInfo.Value,
                sensorList,
                selected: true,
              });
            } else {
              // The value is not part of the tenant
              const dataInstrument = await (await api.get('InstrumentListForSensorNoTenant?'
              + `siteInstrumentIds=${[]}`
              + `&offset=0&sc=${sensorCount}&vc=${vectorCount}`
              + `&searchInstrument=${searchInstrumentTerm}`
              + `&searchSensor=${searchSensorTerm}`
              + `&instrumentAttrName=${instAttributeName}`
              + `&instrumentAttrValue=${instAttributeValue}`
              + `&searchSensorAttrName=${selectedInfo.Name}`
              + `&searchSensorAttrValue=${selectedInfo.Value}`)).data;
              await Promise.all(dataInstrument.map(async (x) => {
                const dataSensor = await (await api.get(
                  `SensorListForInstrument?instrumentId=${x.Id}`
                  + `&vo=0&so=0&sc=${sensorCount}&vc=${vectorCount}`
                  + `&searchInstrument=${searchInstrumentTerm}`
                  + `&searchSensor=${searchSensorTerm}`
                  + `&instrumentAttrName=${instAttributeName}`
                  + `&instrumentAttrValue=${instAttributeValue}`
                  + `&sensorAttrName=${selectedInfo.Name}`
                  + `&sensorAttrValue=${selectedInfo.Value}`,
                )).data;
                dataSensor.forEach((y) => {
                  if (x.Id === y.InstrumentId) {
                    sensorList.push(y);
                  }
                });
              }));
              oldData.push({
                AttributeValue: selectedInfo.Value,
                sensorList,
                selected: true,
              });
            }
          }));
        }

        // CHARGE THE INSTRUMENTS WITH CORRESPONDING ATTRIBUTE THAT ARE NOT IN THE DB
        await Promise.all(dataToAdd.map(async (toBeAddedData) => {
          let alreadyAdded = false;
          if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
            alreadyAdded = jsonData.SelectedItem.find(
              (x) => this.isSameAsSelectedInstrumentAttribute(x, toBeAddedData),
            );
          }
          const sensorList = [];
          if (!alreadyAdded) {
            newData.push({
              AttributeValue: toBeAddedData.Value,
              sensorList,
              selected: false,
            });
          }
        }));

        if (jsonData.SelectedItem && jsonData.SelectedItem.length > 0) {
          jsonData.SelectedItem.forEach((item1) => {
            if (oldData.length > 0) {
              const rightData = oldData.find((item2) => item1.Value === item2.AttributeValue);
              if (rightData) {
                if (item1.InstrumentName) {
                  const newElem = {
                    ...item1,
                    sensorList: rightData.sensorList,
                    selected: rightData.selected,
                  };
                  dataToAdd2.push(newElem);
                  const selectedItem = [];
                  selectedItem.push(newElem);
                  this.$store.dispatch('dashboardPlaceholders/updateSelectedSensorFromInstAttribute', {
                    placeholderId: placeholder.Data.PlaceholderId,
                    selectedItem,
                  });
                } else {
                  const newElem = {
                    ...item1,
                    InstrumentName: item1.Name,
                    DisplayName: item1.Name,
                    sensorList: rightData.sensorList,
                    selected: rightData.selected,
                  };
                  dataToAdd2.push(newElem);
                  const selectedItem = [];
                  selectedItem.push(newElem);
                  this.$store.dispatch('dashboardPlaceholders/updateSelectedSensorFromInstAttribute', {
                    placeholderId: placeholder.Data.PlaceholderId,
                    selectedItem,
                  });
                }
              }
            }
          });
        }

        dataToAdd.forEach((item1) => {
          if (newData.length > 0) {
            const rightData = newData.find((item2) => item1.Value === item2.AttributeValue);
            if (rightData) {
              const newElem = {
                ...item1,
                sensorList: rightData.sensorList,
                selected: rightData.selected,
              };
              dataToAdd2.push(newElem);
            }
          }
        });

        placeholder.Items.push(...dataToAdd2);
      }
    },
    async updateSelectedItem(placeholder, selectedItem) {
      const data = placeholder.Data;
      if (selectedItem) {
        data.JsonData.SelectedItem = [];
        selectedItem.forEach((item) => {
          data.JsonData.SelectedItem.push(item);
        });
      }

      const dbItem = this.placeholders.find(
        (x) => x.PlaceholderId === placeholder.Data.PlaceholderId,
      );
      if (selectedItem) {
        dbItem.JsonData.SelectedItem = [];
        await selectedItem.reduce(async (previousPromise, item) => {
          await previousPromise;
          const sensorOffset = placeholder.Items.filter((x) => x.T === 'S').length;
          const vectorOffset = placeholder.Items.filter((x) => x.T === 'V').length;

          const jsonData = placeholder.Data.JsonData;

          const {
            // eslint-disable-next-line
            params,
            searchInstrumentTerm,
            searchSensorTerm,
            searchSensorUnit,
            instAttributeName,
            instAttributeValue,
            sensorAttributeName,
            sensorAttributeValue,
            sensorCount,
            vectorCount,
          } = filterPlaceholder(jsonData, sensorOffset, vectorOffset, this.itemCount);

          const sensorList = [];
          if (item.T === 'I') {
            dbItem.JsonData.SelectedItem.push(
              {
                T: item.T,
                Id: item.Id,
                Name: item.Name,
                InstrumentId: item.Id,
              },
            );

            if (item.sensorList.length === 0 && item.selected) {
              const dataSensor = await (await api.get(
                `SensorListForInstrument?instrumentId=${item.Id}`
                  + `&vo=0&so=0&sc=${sensorCount}&vc=${vectorCount}`
                  + `&searchInstrument=${searchInstrumentTerm}`
                  + `&searchSensor=${searchSensorTerm}`
                  + `&instrumentAttrName=${instAttributeName}`
                  + `&instrumentAttrValue=${instAttributeValue}`
                  + `&unitCategory=${searchSensorUnit}`
                  + `&sensorAttrName=${sensorAttributeName}`
                  + `&sensorAttrValue=${sensorAttributeValue}`
                ,
              )).data;
              dataSensor.forEach((x) => {
                sensorList.push(x);
                item.sensorList.push(x);
              });

              const newElem = {
                ...item,
                sensorList,
              };
              const addedItem = [];
              addedItem.push(newElem);
              this.$store.dispatch('dashboardPlaceholders/updateSelectedSensorFromInstrument', {
                placeholderId: placeholder.Data.PlaceholderId,
                selectedItem: addedItem,
              });
            }
          } else if (item.T === 'A') {
            dbItem.JsonData.SelectedItem.push(
              {
                T: item.T,
                Value: item.Value,
                Name: item.Name,
              },
            );

            // Instrument Attribute
            if (jsonData.DropdownType === 'Instrument attributes') {
              if (item.sensorList.length === 0 && item.selected) {
                const dataInstrument = await (await api.get('InstrumentListForSensor?'
                    + `siteInstrumentIds=${[]}`
                    + `&offset=0&sc=${sensorCount}&vc=${vectorCount}`
                    + `&searchInstrument=${searchInstrumentTerm}`
                    + `&searchSensor=${searchSensorTerm}`
                    + `&sensorAttrName=${sensorAttributeName}`
                    + `&sensorAttrValue=${sensorAttributeValue}`
                    + `&searchInstAttrName=${item.Name}`
                    + `&searchInstAttrValue=${item.Value}`)).data;
                if (dataInstrument) {
                  await Promise.all(dataInstrument.map(async (x) => {
                    const dataSensor = await (await api.get(
                      `SensorListForInstrument?instrumentId=${x.Id}`
                      + `&vo=0&so=0&sc=${sensorCount}&vc=${vectorCount}`
                      + `&searchInstrument=${searchInstrumentTerm}`
                      + `&searchSensor=${searchSensorTerm}`
                      + `&unitCategory=${searchSensorUnit}`
                      + `&instrumentAttrName=${item.Name}`
                      + `&instrumentAttrValue=${item.Value}`
                      + `&sensorAttrName=${sensorAttributeName}`
                      + `&sensorAttrValue=${sensorAttributeValue}`,
                    )).data;
                    dataSensor.forEach((y) => {
                      sensorList.push(y);
                      item.sensorList.push(y);
                    });
                  }));
                }

                const newElem = {
                  ...item,
                  sensorList,
                };

                const addedItem = [];
                addedItem.push(newElem);
                this.$store.dispatch('dashboardPlaceholders/updateSelectedSensorFromInstAttribute', {
                  placeholderId: placeholder.Data.PlaceholderId,
                  selectedItem: addedItem,
                });
              }
            } else {
              // Sensor Attribute
              const dataInstrument = await (await api.get('InstrumentListForSensor?'
                    + `siteInstrumentIds=${[]}`
                    + `&offset=0&sc=${sensorCount}&vc=${vectorCount}`
                    + `&searchInstrument=${searchInstrumentTerm}`
                    + `&searchSensor=${searchSensorTerm}`
                    + `&sensorAttrName=${item.Name}`
                    + `&sensorAttrValue=${item.Value}`
                    + `&searchInstAttrName=${instAttributeName}`
                    + `&searchInstAttrValue=${instAttributeValue}`)).data;
              if (dataInstrument) {
                await Promise.all(dataInstrument.map(async (x) => {
                  const dataSensor = await (await api.get(
                    `SensorListForInstrument?instrumentId=${x.Id}`
                    + `&vo=0&so=0&sc=${sensorCount}&vc=${vectorCount}`
                    + `&searchInstrument=${searchInstrumentTerm}`
                    + `&searchSensor=${searchSensorTerm}`
                    + `&unitCategory=${searchSensorUnit}`
                    + `&sensorAttrName=${item.Name}`
                    + `&sensorAttrValue=${item.Value}`
                    + `&instrumentAttrName=${instAttributeName}`
                    + `&instrumentAttrValue=${instAttributeValue}`,
                  )).data;
                  dataSensor.forEach((y) => {
                    sensorList.push(y);
                    item.sensorList.push(y);
                  });
                }));
              }

              const newElem = {
                ...item,
                sensorList,
              };

              const addedItem = [];
              addedItem.push(newElem);
              this.$store.dispatch('dashboardPlaceholders/updateSelectedSensorFromInstAttribute', {
                placeholderId: placeholder.Data.PlaceholderId,
                selectedItem: addedItem,
              });
            }
          } else {
            dbItem.JsonData.SelectedItem.push(
              {
                T: item.T,
                Id: item.Id,
                Name: item.Name,
                InstrumentId: item.InstrumentId,
              },
            );
          }
        }, Promise.resolve());
      } else {
        dbItem.JsonData.SelectedItem = [];
      }

      this.$store.dispatch('dashboardPlaceholders/update', dbItem);

      this.placeholderItems.forEach((item) => {
        if (item.Data.PlaceholderId === placeholder.Data.PlaceholderId) {
          this.$store.dispatch('dashboardPlaceholders/updateSelectedSensor', {
            placeholderId: placeholder.Data.PlaceholderId,
            selectedItem,
          });
        }
      });
    },
    isSameAsSelected(item, selectedItem) {
      return (
        selectedItem
        && item.T === selectedItem.T
        && item.Id === selectedItem.Id
        && item.Name === selectedItem.Name
        && item.InstrumentId === selectedItem.InstrumentId
      );
    },
    isSameAsSelectedInstrument(item, selectedItem) {
      return (
        selectedItem
        && item.T === selectedItem.T
        && item.Id === selectedItem.Id
        && item.Name === selectedItem.Name
      );
    },
    isSameAsSelectedInstrumentAttribute(item, selectedItem) {
      return (
        selectedItem
        && item.T === selectedItem.T
        && item.Name === selectedItem.Name
        && item.Value === selectedItem.Value
      );
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-select {
    ::v-deep {
      .v-select__selections input {
        display: none;
      }
    }
  }
</style>
