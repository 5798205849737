/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsModels extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.Owner'),
          field: 'TenantName',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.StartingDate'),
          field: 'StartingDate',
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.EndingDate'),
          field: 'EndingDate',
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.CreatedOn'),
          field: 'CreationDate',
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.LastModifiedBy'),
          field: 'UserName',
          filter: true,
          sortable: true,
          editable: false,
        },
        {
          headerName: this.vue.$t('Equipments.Equipments.Columns.LastModifiedOn'),
          field: 'LastModifiedDate',
          filter: true,
          sortable: true,
          editable: false,
          cellRenderer: 'ReadonlyDateTimeCellRenderer',
          cellRendererParams: {
          },
        },
      ]);
    });
  }
}
