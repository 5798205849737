<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
        :readonly="readMode"
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="formData.ProviderName"
              :label="$t('Equipments.CellularAccountProviders.Name')"
              :rules="[v => !!v || $t('Validation.Required')]"
              :error-messages="providerMessage"
              :autofocus="true"
              @input="cleanError"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="!readMode"
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      providerMessage: '',
      readMode: !this.$auth.roles.includes('Web.Equipment.CellularAccountProvider.Manage'),
    };
  },
  computed: {
    ...mapState('equipment/cellularaccountproviders', ['cellularAccountProvider', 'providernameAlredyExists']),
  },
  watch: {
    async id() {
      await this.getCellularAccountProvider();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.id && this.id != null) { // Update mode
      await this.getCellularAccountProvider();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    async getCellularAccountProvider() {
      await this.$store.dispatch('equipment/cellularaccountproviders/get', { id: this.id });
      this.formData = this.$clone(this.cellularAccountProvider);
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'Equipment.CellularAccountProviders.List' });
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (await this.NameValidation()) {
          if (this.id) {
            await this.$store.dispatch('equipment/cellularaccountproviders/update', this.formData);
          } else {
            await this.$store.dispatch('equipment/cellularaccountproviders/create', this.formData);
          }
          this.navigateToList();
        }
      }
    },
    async NameValidation() {
      await this.$store.dispatch('equipment/cellularaccountproviders/validate', this.formData);
      if (this.providernameAlredyExists) {
        this.providerMessage = i18n.t('Equipments.CellularAccountProviders.ProviderNameExist');
      } else {
        this.providerMessage = '';
        return true;
      }
      return false;
    },
    cleanError() {
      this.providerMessage = '';
    },
  },
};
</script>
