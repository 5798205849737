<template>
  <div class="col col-8 pr-0">
    <v-card-title style="padding: 0px;">
      {{ $t('WindServer.Sites.LoggerConfiguration.MeasurementPoints.Section') }}
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="createNew();"
          >
            <i class="v-icon mdi mdi-plus" />
          </v-btn>
        </template>
        <span>  {{ $t('New') }} </span>
      </v-tooltip>
      <v-tooltip
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="float-right ml-2"
            v-bind="attrs"
            v-on="on"
            @click="deleteMeasurementPoints()"
          >
            <i class="v-icon mdi mdi-delete-forever" />
          </v-btn>
        </template>
        <span>  {{ $t('WindServer.Sites.LoggerConfiguration.MeasurementPoints.Delete') }} </span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pl-0 pr-0">
      <v-container
        class="pl-0"
        fluid
        style="height: 350px;"
      >
        <ServerSideDataGrid
          ref="gridPoint"
          :grid-source="sources.MeasurementPoints"
          :row-data-init="measurementPoints"
          :pagination="false"
          enable-selection="multiple"
        />
      </v-container>
    </v-card-text>

    <MeasurementPointFormModal
      :id="id"
      v-model="showMeasurementPoint"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import MeasurementPointsList from '../gridsources/gsMeasurementPoints';
import MeasurementPointFormModal from './MeasurementPointFormModal.vue';

export default {
  components: {
    ServerSideDataGrid,
    MeasurementPointFormModal,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      sources: {
        MeasurementPoints: null,
      },
      showMeasurementPoint: false,
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['measurementPoints']),
  },
  created() {
    this.sources.MeasurementPoints = new MeasurementPointsList(this);
  },
  methods: {
    createNew() {
      this.showMeasurementPoint = true;
    },
    async editMeasurementPoints(payload) {
      await this.$store.dispatch('site/loggerConfigurations/measurementPoint', payload);
      this.showMeasurementPoint = true;
    },
    async deleteMeasurementPoints() {
      let selectedRows = [];
      if (this.$refs.gridPoint.gridApi.getSelectedRows().length > 0) {
        selectedRows = this.$refs.gridPoint.gridApi.getSelectedRows();
      }
      if (selectedRows.length > 0) {
        await this.$store.dispatch('site/loggerConfigurations/deleteMeasurementPoints', selectedRows);
      }
    },
  },
};
</script>
