/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsHeights extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$t('Edit'),
          width: 80,
          resizable: true,
          cellRenderer: 'EditButtonCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.editHeights(event.data);
            },
          },
        },
        {
          field: 'Height',
          headerName: this.vue.$t('WindServer.Sites.LoggerConfiguration.Heights.Height'),
          width: 150,
          editable: false,
          filter: true,
          sortable: true,
        },
      ]);
    });
  }
}
