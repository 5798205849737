<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model.number="formData.PeNumber"
              :label="$t('WindServer.Sites.SiteConfigurations.PeNumber')"
              :readonly="editEquipment && !editEquipment.IsNew"
              type="number"
            />
            <v-text-field
              v-model="formData.SerialNumber"
              :label="$t('WindServer.Sites.SiteConfigurations.SerialNumber')"
              @change="onChange"
            />
            <v-select
              v-model="formData.ModelId"
              :label="$t('WindServer.Sites.SiteConfigurations.ModelName')"
              :items="equipmentModel.Towers"
              :rules="[v => !!v || $t('Validation.Required')]"
              :return-object="true"
              item-text="ModelName"
              item-value="ModelId"
              @change="onChange"
            >
              <template
                slot="selection"
                slot-scope="data"
              >
                {{ data.item.ManufacturerName }} {{ data.item.ModelName }}
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                <div
                  v-html="data.item.ManufacturerName +' '+ data.item.ModelName"
                />
              </template>
            </v-select>
            <v-checkbox
              v-model="formData.IsPainted"
              :label="$t('WindServer.Sites.SiteConfigurations.IsPainted')"
              type="checkbox"
              @change="onChange"
            />
            <v-text-field
              v-model="formData.GinPoleOrientation"
              :label="$t('WindServer.Sites.SiteConfigurations.GinPoleOrientation')"
              :rules="[v => !!v || $t('Validation.Required')]"
              type="number"
              @change="onChange"
            />
            <v-text-field
              v-model="formData.InstallerName"
              :label="$t('WindServer.Sites.SiteConfigurations.Installer')"
              :rules="[v => !!v || $t('Validation.Required')]"
              @change="onChange"
            />
            <v-textarea
              v-model="formData.InstallerNote"
              :label="$t('WindServer.Sites.SiteConfigurations.InstallerNotes')"
              rows="3"
              @change="onChange"
            />
            <v-textarea
              v-model="formData.LightningProtectionDetails"
              :label="$t('WindServer.Sites.SiteConfigurations.LightningProtection')"
              rows="3"
              @change="onChange"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="submit"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    ...mapState('site/siteConfigurations', ['equipmentModel', 'editEquipment']),
    show: {
      get() {
        this.setFormData();
        return this.value;
      },
      set(value) {
        this.formData = {};
        this.$store.dispatch('site/siteConfigurations/editEquipment', null);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    setFormData() {
      if (this.editEquipment) {
        this.formData = JSON.parse(JSON.stringify(this.editEquipment));
      } else {
        this.formData = {};
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.editEquipment) {
          await this.$store.dispatch('site/siteConfigurations/editTower', this.formData);
        } else {
          await this.$store.dispatch('site/siteConfigurations/addTower', this.formData);
        }
        this.close();
      }
    },
    onChange() {
      this.formData.IsDirty = true;
    },
    close() {
      this.$emit('input', false);
      this.formData = {};
      this.$store.dispatch('site/siteConfigurations/editEquipment', null);
      this.$refs.form.reset();
    },
  },
};
</script>
