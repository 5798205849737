import api from '@/api/importal';

const state = {
  landOwners: [],
};

const actions = {
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put(`SiteLandOwnersCreate?siteId=${payload.SiteId}`, payload.LandOwnerIds);
    dispatch('list', payload.SiteId);
    commit('app/pleaseWait', false, { root: true });
  },

  async delete({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`SiteLandOwnersDelete?siteId=${payload.SiteId}&landOwnerId=${payload.LandOwnerId}`);
    dispatch('list', payload.SiteId);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`SiteLandOwnersList?siteId=${payload}`);
    commit('landOwners', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  landOwners(state, payload) {
    state.landOwners = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
