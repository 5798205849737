<template>
  <div class="col col-4">
    <v-card-title class="pl-0 pt-0 pb-0">
      {{ $t('WindServer.Sites.LoggerConfiguration.Channels') }}
    </v-card-title>
    <v-card-text class="pl-0 pr-0">
      <v-container
        class="pl-0"
        fluid
        style="height: 350px;"
      >
        <ServerSideDataGrid
          ref="gridChannel"
          :grid-source="sources.Channels"
          :row-data-init="channels"
          :pagination="false"
          enable-selection="multiple"
          @selection-changed="onGridSelectionChanged"
        />
      </v-container>
    </v-card-text>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import ChannelsList from '../gridsources/gsChannels';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        Channels: null,
      },
      channels: [],
    };
  },
  computed: {
    ...mapState('site/loggerConfigurations', ['logger']),
  },
  created() {
    this.sources.Channels = new ChannelsList(this);
    this.channels = this.logger.Channels;
  },
  methods: {
    async onGridSelectionChanged() {
      let selectedRows = [];
      if (this.$refs.gridChannel.gridApi.getSelectedRows().length > 0) {
        selectedRows = this.$refs.gridChannel.gridApi.getSelectedRows();
      }
      await this.$store.dispatch('site/loggerConfigurations/channels', selectedRows);
    },
  },
};
</script>
