import * as monaco from 'monaco-editor';

function initialize() {
  monaco.languages.register({ id: 'FormulaeCompilerLanguage-1.0' });

  function ReadCurrentLineUpToCaret(model, position) {
    return model.getValueInRange({
      startLineNumber: position.lineNumber, startColumn: 0, endLineNumber: position.lineNumber, endColumn: position.column,
    });
  }

  function GetParameterNumber(entry) {
    const lastparenferme = entry.substring(entry.lastIndexOf(')'));
    const poslastpara = lastparenferme.lastIndexOf('(');
    const newentry = entry.substring(poslastpara);
    let value = 0;
    if (newentry.includes(',')) {
      const param = newentry.split(',');
      value = param.length - 1;
    }
    if (newentry.includes('(')) {
      const param = newentry.split('(');
      if (param.length - 1 > 1 && !param[param.length - 1].includes(',')) {
        return 0;
      }
    }
    return value;
  }

  const intervalSuggestions = [
    {
      label: 'SECOND',
      detail: 'Seconds',
      insertText: 'SECOND',
    },
    {
      label: 'MINUTE',
      detail: 'Minutes',
      insertText: 'MINUTE',
    },
    {
      label: 'HOUR',
      detail: 'Hours',
      insertText: 'HOUR',
    },
    {
      label: 'DAY',
      detail: 'Days',
      insertText: 'DAY',
    },
    {
      label: 'WEEK',
      detail: 'Seven Days',
      insertText: 'WEEK',
    },
    {
      label: 'MONTH',
      detail: '31 Days',
      insertText: 'MONTH',
    },
    {
      label: 'YEAR',
      detail: '365 Days',
      insertText: 'YEAR',
    },
  ];

  // Register a completion item provider for the new language
  monaco.languages.registerCompletionItemProvider('FormulaeCompilerLanguage-1.0', {
    triggerCharacters: ['=', ','],
    provideCompletionItems: (model, position) => {
      let suggestions = [
        {
          label: 'SIN',
          detail: 'Sinus Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'SIN',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the sinus of the input',
        },
        {
          label: 'COS',
          detail: 'Cosinus Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'COS',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the cosinus of the input',
        },
        {
          label: 'TAN',
          detail: 'Tangent Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'TAN',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the tangent of the input',
        },
        {
          label: 'ASIN',
          detail: 'Arc Sinus Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ASIN',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the arc sinus of the input \n Will return none if the input not between -1 and 1',
        },
        {
          label: 'ACOS',
          detail: 'Arc Cosinus Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ACOS',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the arc cosinus of the input \n Will return none if the input not between -1 and 1',
        },
        {
          label: 'ATAN',
          detail: 'Arc Tangent Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ATAN',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the arc tangent of the input',
        },
        {
          label: 'ATAN2',
          detail: 'Arc Tangent Function (Full quadrant version)',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ATAN2',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the arc tangent of the input',
        },
        {
          label: 'LN',
          detail: 'Natural Logarithm Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'LN',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the logarithm of the input with e as base \n Will return none if the input is 0 or lower',
        },
        {
          label: 'EXP',
          detail: 'Exponential Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'EXP',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns e raised to the power of the input',
        },
        {
          label: 'LOG',
          detail: 'Base 10 Logarithm Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'LOG',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the logarithm of the input with 10 as base \n Will return none if the input is 0 or lower',
        },
        {
          label: 'SQRT',
          detail: 'Square Root Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'SQRT',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the square root of the input \n Will return none if the input is a negative value',
        },
        {
          label: 'ABS',
          detail: 'Absolute Value Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ABS',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the absolute value of the input \n Will return none if the input is greater than 2^31',
        },
        {
          label: 'FLOOR',
          detail: 'Floor Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'FLOOR',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the greatest integer less than or equal to the input',
        },
        {
          label: 'CEILING',
          detail: 'Ceiling Function',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'CEILING',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the least integer greater than or equal to the input',
        },
        {
          label: 'SIGN',
          detail: 'Verify Sign',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'SIGN',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns 1 if the input is positive, otherwise returns -1',
        },
        {
          label: 'IIF',
          detail: 'Verify a condition',
          kind: monaco.languages.CompletionItemKind.Operator,
          insertText: 'IIF',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the true expression if the condition is true, otherwise returns the false expression',
        },
        {
          label: 'LAG',
          detail: 'Take Previous Value',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'LAG',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the previous value of a variable',
        },
        {
          label: 'Fill',
          detail: 'Fill intermediate values taking the Input Aggregation Period into account.',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'Fill',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the previous value of a variable',
        },
        {
          label: 'Interpolate',
          detail: 'Linearly Interpolate intermediate values taking the Input Aggregation Period into account.',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'Interpolate',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the previous value of a variable',
        },
        {
          label: 'VCumulative',
          detail: 'Calculate the cumulative sum vector',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VCumulative',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Cumulate an array in a specific direction. This returns an arrary with the running sum.',
        },
        {
          label: 'VMaxAt',
          detail: 'Get Maximum Value index',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VMaxAt',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Returns the index of the greatest value in a vector as a scalar',
        },
        {
          label: 'VDimRescale',
          detail: 'Change the sample interval of the vector',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VDimRescale',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Change the sample interval of the vector (index value)',
        },
        {
          label: 'VRealFft',
          detail: 'Compute the single-sided frequency spectrum',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VRealFft',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Compute the single-sided frequency spectrum',
        },
        {
          label: 'VAverage',
          detail: 'Compute the average of a vector',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VAverage',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Compute the average of a vector',
        },
        {
          label: 'VGetAt',
          detail: 'Get the value at a specific point in a vector',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VGetAt',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Get the value at a specific point in a vector',
        },
        {
          label: 'VMin',
          detail: 'Return the minimum value ignoring NONE',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VMin',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Return the minimum value ignoring NONE',
        },
        {
          label: 'VMax',
          detail: 'Return the maximum value ignoring NONE',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VMax',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Return the maximum value ignoring NONE',
        },
        {
          label: 'VFirst',
          detail: 'Return the first value ignoring NONE',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VFirst',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Return the first value ignoring NONE',
        },
        {
          label: 'VReverse',
          detail: 'Reverse the order of a vector',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VReverse',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Reverse the order of a vector',
        },
        {
          label: 'VSort',
          detail: 'Sort the vector V using key K in the direction ASC or DESC.',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VSort',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Sort the vector V using key K in the direction ASC or DESC.',
        },
        {
          label: 'VSum',
          detail: 'Add all non-null values',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VSum',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Add all non-null values',
        },
        {
          label: 'VIntegrate',
          detail: 'Approximate the area under curve.',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'VIntegrate',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Approximate the area under curve. <rule> can be RECTANGULAR, TRAPEZOIDAL, SIMPSON',
        },
        {
          label: 'HistValue',
          detail: 'Lookup a value in history',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'HistValue',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'HistValue(<sensor>,LAST)\nHistValue(<sensor>,FIRST)\nHistValue(<sensor>,AT,"<timestamp>")\nHistValue(<sensor>, <#periods>, <interval>)\n\n<sensor>: external symbol may be physical or virtual sensor but cannot be an expression\n<timestamp>: Date in ISO8601 format between double quotes. Supports degraded modes like YYYY, YYYY-mm, YYYY-mm-dd, etc. Assumes the time is UTC unless the tome zone offset is specified like -05:00<interval>: SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR',
        },
        {
          label: 'AggValue',
          detail: 'Aggregate historical values into a new stream of values',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'AggValue',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'AggValue(<sensor>, <function>,<# periods>,<interval>)\n\nSensor: reference to a sensor\nFunction: SUM, CUMSUM, MIN, MAX, AVG, MED, STD, QRANGE, COUNT\nPeriods: whole number defining the number of periods to considerInterval: one of the following: SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR',
        },
        {
          label: 'ROUND',
          detail: 'Round the number x to the nearest digit n',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ROUND',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'ROUND(x,n)\n\nRound the number x to the nearest digit n\nx: expression\nn:integer, negative values round to the left of the decimal point',
        },
        {
          label: 'FPART',
          detail: 'Return the fractional part',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'FPART',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Return the fractional part',
        },
        {
          label: 'IPART',
          detail: 'Return the integral part',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'IPART',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Return the integral part',
        },
        {
          label: 'AngleDist',
          detail: 'Calculate the absolute distance from an angle on domain',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'AngleDist',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'AngleDist(<expression>,<reference>,<domain>)\n\nexpression: the value to evaluate\nreference: the angle of reference\ndomain: 180.0 for degrees, 3.14159265 for radians',
        },
        {
          label: 'ToDegrees',
          detail: 'Convert a value in radians to degrees',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ToDegrees',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'ToDegrees(x)\n\nConvert a value in radians to degrees',
        },
        {
          label: 'Vector',
          detail: 'Declare a variable to be a vector input with symbol x',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'Vector',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Vector(x)\n\nForce the type of variable x to be a vector. Only valid for input symbols.',
        },
        {
          label: 'Sensor',
          detail: 'Declare a variable to be a sensor input with symbol x',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'Sensor',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'Sensor(x)\n\nForce the type of variable x to be a sensor. Only valid for input symbols.',
        },
        {
          label: 'ToPositiveDegrees',
          detail: 'Convert a value in radians to degrees in the range 0...360',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ToPositiveDegrees',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'ToPositiveDegrees(x)\n\nConvert a value in radians to degrees in the range 0...360',
        },
        {
          label: 'ToRadians',
          detail: 'Convert a value in degrees to radians',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ToRadians',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'ToRadians(x)\n\nConvert a value in degrees to radians',
        },
        {
          label: 'ToPositiveRadians',
          detail: 'Convert a value in degrees to radians in the range 0...2*PI',
          kind: monaco.languages.CompletionItemKind.Function,
          insertText: 'ToPositiveRadians',
          insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
          documentation: 'ToPositiveRadians(x)\n\nConvert a value in degrees to radians in the range 0...2*PI',
        },
      ];

      // Read current line
      const line = ReadCurrentLineUpToCaret(model, position);
      const positionlastparaenthese = line.lastIndexOf('(');
      const afterparenthese = line.substring(positionlastparaenthese);
      const matchFunctionNameIgnoreCase = function matchFunctionNameIgnoreCase(keyword) {
        return line.substring(positionlastparaenthese - keyword.length, positionlastparaenthese).toUpperCase() === keyword.toUpperCase() && !afterparenthese.includes(')');
      };
      const matchFunctionName = function matchFunctionName(keyword) {
        return line.substring(positionlastparaenthese - keyword.length, positionlastparaenthese) === keyword && !afterparenthese.includes(')');
      };
      if (matchFunctionNameIgnoreCase('LAG')) {
        suggestions = [{
        }];
      }
      if (matchFunctionNameIgnoreCase('FILL')) {
        suggestions = [{
        }];
      }
      if (matchFunctionNameIgnoreCase('INTERPOLATE')) {
        suggestions = [{
        }];
      }
      if (matchFunctionName('VDimRescale') && afterparenthese.includes(',')) {
        suggestions = [{
        }];
      }
      if (matchFunctionName('VGetAt') && afterparenthese.includes(',')) {
        suggestions = [{
        }];
      }
      if (matchFunctionName('VCumulative') && !afterparenthese.includes(',')) {
        suggestions = [{
          label: 'ASC',
          detail: 'Ascending',
          insertText: 'ASC',
        },
        {
          label: 'DESC',
          detail: 'Descending',
          insertText: 'DESC',
        }];
      }

      if (matchFunctionName('VSort')) {
        if (GetParameterNumber(afterparenthese) < 1) {
          suggestions = [{
          }];
        } else if (GetParameterNumber(afterparenthese) === 1) {
          suggestions = [{
          }];
        } else if (GetParameterNumber(afterparenthese) === 2) {
          suggestions = [{
            label: 'ASC',
            detail: 'Ascending',
            insertText: 'ASC',
          },
          {
            label: 'DESC',
            detail: 'Descending',
            insertText: 'DESC',
          }];
        }
      }

      if (matchFunctionName('VIntegrate')) {
        if (GetParameterNumber(afterparenthese) < 1) {
          suggestions = [{
          }];
        } else if (GetParameterNumber(afterparenthese) === 1) {
          suggestions = [{
            label: 'RECTANGULAR',
            detail: 'Right handed rectangular rule, ignores the last point in the series.',
            insertText: 'RECTANGULAR',
          },
          {
            label: 'TRAPEZOIDAL',
            detail: 'First order (linear) approximation',
            insertText: 'TRAPEZOIDAL',
          },
          {
            label: 'SIMPSON',
            detail: 'Simpson\'s one-third rule evaluated at n = 2 (three-point).',
            insertText: 'SIMPSON',
          }];
        }
      }

      if (matchFunctionName('HistValue')) {
        if (GetParameterNumber(afterparenthese) < 1) {
          suggestions = [{
          }];
        } else if (GetParameterNumber(afterparenthese) === 1) {
          suggestions = [{
            label: 'FIRST',
            detail: 'First value',
            insertText: 'FIRST',
          },
          {
            label: 'LAST',
            detail: 'Last Value',
            insertText: 'LAST',
          },
          {
            label: 'AT',
            detail: 'Specific date',
            insertText: 'AT',
          },
          {
            label: '1',
            detail: 'Number of periods',
            insertText: '1',
          },
          ];
        } else if (GetParameterNumber(afterparenthese) === 2) {
          if (afterparenthese.replace(' ', '').endsWith('AT,')) {
            suggestions = [
              {
                label: '"2020-01-01 00:00:00"',
                detail: 'TimeStamp',
                insertText: '"2020-01-01 00:00:00"',
              },
            ];
          } else {
            suggestions = intervalSuggestions;
          }
        }
      }

      if (matchFunctionName('AggValue')) {
        if (GetParameterNumber(afterparenthese) < 1) {
          suggestions = [{
          }];
        } else if (GetParameterNumber(afterparenthese) === 1) {
          suggestions = [{
            label: 'SUM',
            detail: 'Sum over the specified window',
            insertText: 'SUM',
          },
          {
            label: 'MIN',
            detail: 'Minimum over the specified window',
            insertText: 'MIN',
          },
          {
            label: 'MAX',
            detail: 'Maximum over the specified window',
            insertText: 'MAX',
          },
          {
            label: 'AVG',
            detail: 'Average over the specified window',
            insertText: 'AVG',
          },
          {
            label: 'MED',
            detail: 'Median over the specified window (approximate)',
            insertText: 'MED',
          },
          {
            label: 'STD',
            detail: 'Standard deviation over the specified window',
            insertText: 'STD',
          },
          {
            label: 'QRANGE',
            detail: 'Interquartile range over the specified window (Q75 - Q25)',
            insertText: 'QRANGE',
          },
          {
            label: 'COUNT',
            detail: 'Count over the specified window',
            insertText: 'COUNT',
          },
          ];
        } else if (GetParameterNumber(afterparenthese) === 2) {
          suggestions = [
            {
              label: '1',
              detail: 'Number of periods',
              insertText: '1',
            },
          ];
        } else if (GetParameterNumber(afterparenthese) === 3) {
          suggestions = intervalSuggestions;
        }
      }

      return { suggestions };
    },
  });

  monaco.languages.registerSignatureHelpProvider('FormulaeCompilerLanguage-1.0', {
    signatureHelpTriggerCharacters: ['(', ',', ')'],
    provideSignatureHelp: (model, position) => {
      let nbparam = 0;
      const line = ReadCurrentLineUpToCaret(model, position);
      const words = line.lastIndexOf(',');
      const lastFunc = line.substring(words + 1);
      const word = lastFunc.replace('\t', ' ').split(' ');
      const activeTyping = word[word.length - 1];
      const doublefunctionlook = activeTyping.split('(');
      const semifinalword = doublefunctionlook[doublefunctionlook.length - 2];
      if (!semifinalword) return undefined;
      const finalwordArray = semifinalword.split('=');
      if (!finalwordArray) return undefined;
      const finalword = finalwordArray[finalwordArray.length - 1];
      const virgulelook = line;
      let result = null;
      let tempresult = null;
      switch (finalword) {
        case 'SIN':
        case 'sin':
          result = [{
            label: 'SIN(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'COS':
        case 'cos':
          result = [{
            label: 'COS(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'TAN':
        case 'tan':
          result = [{
            label: 'TAN(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'ASIN':
        case 'asin':
          result = [{
            label: 'ASIN(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression \n It must be between -1 and 1 to have a return value',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'ATAN':
        case 'atan':
          result = [{
            label: 'ATAN(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'ATAN2':
        case 'atan2':
          result = [{
            label: 'ATAN2(Y,X)',
            parameters: [
              {
                label: 'Y',
                documentation: 'Y Expression of TAN(Y/X)',
              },
              {
                label: 'X',
                documentation: 'X Expression of TAN(Y/X)',
              },
            ],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'ROUND':
        case 'round':
          result = [{
            label: 'ROUND(X,N)',
            parameters: [
              {
                label: 'X',
                documentation: 'Expression to round',
              },
              {
                label: 'N',
                documentation: 'Number of digits (max 15, must be a number)',
              },
            ],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'ACOS':
        case 'acos':
          result = [{
            label: 'ACOS(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression\nIt must be between -1 and 1 to have a return value',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'FPART':
        case 'fpart':
          result = [{
            label: 'FPART(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'IPART':
        case 'ipart':
          result = [{
            label: 'IPART(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'LN':
        case 'ln':
          result = [{
            label: 'LN(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression \n It must be greater than 0 to have a return value',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'EXP':
        case 'exp':
          result = [{
            label: 'EXP(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          tempresult = result;
          nbparam = 0;
          break;
        case 'LOG':
        case 'log':
          result = [{
            label: 'LOG(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression \n It must be greater than 0 to have a return value',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'SQRT':
        case 'sqrt':
          result = [{
            label: 'SQRT(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression \n It must be equal or greater than 0 to have a return value',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'ABS':
        case 'abs':
          result = [{
            label: 'ABS(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression \n It must be lower than 2^31 to have a return value',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'FLOOR':
        case 'floor':
          result = [{
            label: 'FLOOR(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'CEILING':
        case 'ceiling':
          result = [{
            label: 'CEILING(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'SIGN':
        case 'sign':
          result = [{
            label: 'SIGN(X)',
            parameters: [{
              label: 'X',
              documentation: 'Expression',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;

        case 'VMaxAt':
          result = [{
            label: 'VMaxAt(Expression)',
            parameters: [{
              label: 'Expression',
              documentation: 'Vector to get the max value from',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;

        case 'VRealFft':
          result = [{
            label: 'VRealFft(Expression)',
            parameters: [{
              label: 'Expression',
              documentation: 'Vector to get the FFT from',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'VAverage':
          result = [{
            label: 'VAverage(Expression)',
            parameters: [{
              label: 'Expression',
              documentation: 'Vector to get the average of',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'AngleDist':
          result = [{
            label: 'AngleDist(X,Y,D)',
            parameters: [
              {
                label: 'X',
                documentation: 'Expression to relate to Y',
              },
              {
                label: 'Y',
                documentation: 'Reference angle',
              },
              {
                label: 'D',
                documentation: 'Domain of the calculation (modulus)',
              },
            ],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'IIF':
        case 'iif':
          result = [{
            label: 'IIF(condition, True expression, False expression)',
            parameters: [{
              label: 'condition',
              documentation: 'This input must be a condition to verify',
            },
            {
              label: 'True expression',
              documentation: 'This input will be return if the condition is true',
            },
            {
              label: 'False expression',
              documentation: 'This input will be return if the condition is false',
            },
            ],
          }];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;

        case 'LAG':
        case 'lag':
          result = [{
            label: 'LAG(Symbol, Offset)',
            parameters: [{
              label: 'Symbol',
              documentation: 'Variable to get previous value from',
            },
            {
              label: 'Offset',
              documentation: 'Number of step',
            },
            ],
          }];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;

        case 'Fill':
          result = [{
            label: 'Fill(Symbol, Lookback[, Offset])',
            parameters: [{
              label: 'Symbol',
              documentation: 'Variable to get previous value from',
            },
            {
              label: 'Lookback',
              documentation: 'Number of steps to look back for the first record value if not present',
            },
            {
              label: 'Offset',
              documentation: 'Value to take in past steps (like LAG operator)',
            },
            ],
          }];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;

        case 'Interpolate':
          result = [{
            label: 'Interpolate(Symbol, Lookback[, Offset])',
            parameters: [{
              label: 'Symbol',
              documentation: 'Variable to get previous value from',
            },
            {
              label: 'Lookback',
              documentation: 'Number of steps to look back for the first record value if not present',
            },
            {
              label: 'Offset',
              documentation: 'Value to take in past steps (like LAG operator)',
            },
            ],
          }];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;

        case 'Coalesce':
          result = [{
            label: 'Coalesce(Symbol, Value)',
            parameters: [{
              label: 'Symbol',
              documentation: 'External symbol',
            },
            {
              label: 'Value',
              documentation: 'Value to put in if the value is null',
            },
            ],
          }];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;

        case 'VCumulative':
          result = [{
            label: 'VCumulative(DIR, Expression) ',
            parameters: [{
              label: 'DIR',
              documentation: 'Direction of the vector',
            },
            {
              label: 'Expression',
              documentation: 'Vector to sum up',
            },
            ],
          }];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;

        case 'VDimRescale':
          result = [{
            label: 'VDimRescale(Expression, Factor) ',
            parameters: [{
              label: 'Expression',
              documentation: 'Vector to rescale',
            },
            {
              label: 'Factor',
              documentation: 'This input must be a number',
            },
            ],
          }];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;

        case 'VGetAt':
          result = [{
            label: 'VGetAt(Expression, Offset)',
            parameters: [{
              label: 'Expression',
              documentation: 'Vector to get value from',
            },
            {
              label: 'Offset',
              documentation: 'Index where to take the value',
            },
            ],
          }];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;

        case 'HistValue':
          result = [{
            label: 'HistValue(Sensor,LAST)',
            parameters: [{
              label: 'Sensor',
              documentation: 'Input sensor reference',
            },
            {
              label: 'LAST',
              documentation: 'LAST: Return the last known record',
            },
            ],
          },
          {
            label: 'HistValue(Sensor,FIRST)',
            parameters: [{
              label: 'Sensor',
              documentation: 'Input sensor reference',
            },
            {
              label: 'KeywordOrNumber',
              documentation: 'LAST, FIRST, AT, <Num Periods>',
            },
            ],
          },
          {
            label: 'HistValue(Sensor,AT,"TimeStamp")',
            parameters: [{
              label: 'Sensor',
              documentation: 'Input sensor reference',
            },
            {
              label: 'AT',
              documentation: 'AT',
            },
            {
              label: 'TimeStamp',
              documentation: 'ISO8601 time stamp',
            },
            ],
          },
          {
            label: 'HistValue(Sensor,NumPeriods,Interval)',
            parameters: [{
              label: 'Sensor',
              documentation: 'Input sensor reference',
            },
            {
              label: 'NumPeriods',
              documentation: 'Number of periods',
            },
            {
              label: 'Interval',
              documentation: 'SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR',
            },
            ],
          },
          ];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;

        case 'AggValue':
          result = [{
            label: 'AggValue(Sensor,Function,Periods,Interval)',
            parameters: [{
              label: 'Sensor',
              documentation: 'Input sensor reference',
            },
            {
              label: 'Function',
              documentation: 'SUM, MIN, MAX, AVG, MED, STD, QRANGE, COUNT',
            },
            {
              label: 'Periods',
              documentation: 'The number of intervals',
            },
            {
              label: 'Interval',
              documentation: 'SECOND, MINUTE, HOUR, DAY, WEEK, MONTH, YEAR',
            },
            ],
          }];
          tempresult = result;
          nbparam = GetParameterNumber(virgulelook);
          break;
        case 'ToDegrees':
          result = [{
            label: 'ToDegrees(Expression)',
            parameters: [{
              label: 'Expression',
              documentation: 'An expression returning a value in radians',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'Vector':
          result = [{
            label: 'Vector(Input_Symbol)',
            parameters: [{
              label: 'Input_Symbol',
              documentation: 'An input symbol, which is a variable name that is an input of the formula block.',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'Sensor':
          result = [{
            label: 'Sensor(Input_Symbol)',
            parameters: [{
              label: 'Input_Symbol',
              documentation: 'An input symbol, which is a variable name that is an input of the formula block.',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'ToPositiveDegrees':
          result = [{
            label: 'ToPositiveDegrees(Expression)',
            parameters: [{
              label: 'Expression',
              documentation: 'An expression returning a value in radians',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'ToRadians':
          result = [{
            label: 'ToRadians(Expression)',
            parameters: [{
              label: 'Expression',
              documentation: 'An expression returning a value in degrees',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;
        case 'ToPositiveRadians':
          result = [{
            label: 'ToPositiveRadians(Expression)',
            parameters: [{
              label: 'Expression',
              documentation: 'An expression returning a value in degrees',
            }],
          }];
          nbparam = 0;
          tempresult = result;
          break;

        default:
          break;
      }
      const lastparaouvre = virgulelook.substring(virgulelook.lastIndexOf('('));
      if (lastparaouvre.includes(')')) {
        nbparam = 0;
        tempresult = null;
        if (virgulelook.endsWith(')') || virgulelook.endsWith(',')) {
          result = null;
        }
      }
      if (!virgulelook.includes('(')) {
        tempresult = [];
      }
      if (result == null) {
        result = tempresult;
        nbparam = GetParameterNumber(virgulelook);
      }
      return {
        dispose: () => {},
        value: {
          activeParameter: nbparam,
          activeSignature: 0,
          signatures: result,
        },
      };
    },
  });
}

export default {
  initialize,
};
