<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.CellularAccountProviders"
      :show-new-button="false"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="cellularAccountProviders"
    />
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import CellularAccountProvidersList from '../gridsources/gsCellularAccountProviders';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        CellularAccountProviders: null,
      },
      toolbarButtons: [],
      selection: {
        number: [],
      },
      dialog: {
        Message: '',
        NoCancel: false,
        Title: '',
        Show: false,
        acceptCallback: null,
      },
      showReserveModal: false,
    };
  },
  computed: {
    ...mapState('equipment/cellularaccountproviders', ['cellularAccountProviders']),
  },
  created() {
    this.sources.CellularAccountProviders = new CellularAccountProvidersList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.Equipment.CellularAccountProvider.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('New'),
        icon: 'mdi-plus',
        enabled: true,
        click: () => { this.createNew(); },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'Equipment.CellularAccountProviders.Create' });
    },
    refreshList() {
      this.$store.dispatch('equipment/cellularaccountproviders/list');
    },
  },
};
</script>
