<template>
  <v-card-text
    v-if="show"
    class="pb-0 pt-0"
  >
    <v-radio-group
      v-model="form.periodicity"
      dense
      class="ma-0"
    >
      <v-radio
        v-if="showLastN"
        value="last_n"
      >
        <template #label>
          <div>
            {{ $t('Last') }}
          </div>
              &nbsp;
              &nbsp;
          <v-text-field
            v-model="form.lastnPeriod"
            placeholder="1"
            type="number"
            oninput="if (this.value && this.value < 1) this.value = 1;"
            dense
            @click="selectPeriodicity('last_n')"
          />
              &nbsp;
              &nbsp;
          <v-select
            v-model="form.lastnScale"
            placeholder="Years"
            :items="LastPicker"
            dense
            @click="selectPeriodicity('last_n')"
          />
        </template>
      </v-radio>
      <v-radio
        v-if="showRange"
        value="time_range"
      >
        <template #label>
          <div>
            {{ $t('Range') }}
          </div>
              &nbsp;
              &nbsp;
          <datetime-picker
            v-model="form.dateRangeFrom"
            :label="$t('From')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
            @click="selectPeriodicity('time_range')"
          />
              &nbsp;
              &nbsp;
          <datetime-picker
            v-model="form.dateRangeTo"
            :label="$t('To')"
            :text-field-props="pickerTextFieldProps"
            :date-picker-props="pickerDateProps"
            :time-picker-props="pickerTimeProps"
            @click="selectPeriodicity('time_range')"
          />
        </template>
      </v-radio>
      <v-radio
        v-if="showRecord"
        value="time_list"
      >
        <template #label>
          <div>
            {{ $t('Dashboard.VisualizationSetting.TimeList') }}
          </div>
              &nbsp;
              &nbsp;
          <v-dialog
            v-model="timeListDialogActivator"
            persistent
            max-width="600px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="selectPeriodicity('time_list')"
              >
                {{ $t('Dashboard.VisualizationSetting.TimeListAddButton') }}
              </v-btn>
            </template>
            <v-card>
              <!-- Approx date SELECTION STEP -->
              <v-card-title
                v-if="timeList_ShowDaySelection"
              >
                {{ $t('Dashboard.VisualizationSetting.SelectApproximateDay') }}
              </v-card-title>
              <sensor-date-picker
                v-if="timeList_ShowDaySelection"
                v-model="timeList.selectedDate"
                :sensor-list="sensorIdList"
                :vector-list="vectorIdList"
                full-width
                @input="timeList_selectDayOfData"
              />
              <!-- VALUE SELECTION STEP -->
              <v-card-title
                v-if="timeList_ShowDataSelection"
              >
                {{ $t('Dashboard.VisualizationSetting.SelectData') }}
              </v-card-title>
              <ag-grid-vue
                v-if="timeList_ShowDataSelection"
                style="width: 100%; height: 400px;"
                class="ag-theme-balham-dark"
                :column-defs="timeList.columnDefs"
                :row-data="timeList.rowData"
                :row-selection="'multiple'"
                @grid-ready="timeList_onGridReady"
                @selection-changed="timeList_onGridSelectionChanged"
              />
              <v-card-actions>
                <v-btn
                  :disabled="pleaseWait"
                  class="mr-4"
                  @click="timeList_cancel"
                >
                  {{ $t('Close') }}
                </v-btn>
                <v-btn
                  v-if="timeList_showBackButton"
                  :disabled="pleaseWait"
                  @click="timeList_back"
                >
                  {{ $t('Back') }}
                </v-btn>
                <v-btn
                  v-if="timeList_showNextButton"
                  :disabled="pleaseWait || timeList_confirmDisabled"
                  @click="timeList_next"
                >
                  {{ $t('Next') }}
                </v-btn>
                <v-btn
                  v-if="timeList_showSaveButton"
                  :disabled="pleaseWait || timeList_confirmDisabled"
                  @click="timeList_save"
                >
                  {{ $t('Add') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            :disabled="form.dateList.length === 0"
            @click="timeList_RemoveFromList"
          >
            {{ $t('Dashboard.VisualizationSetting.TimeListRemoveButton') }}
            <v-badge
              overlap
              :content="form.dateList.length"
              :value="form.dateList.length"
            />
          </v-btn>
        </template>
      </v-radio>
    </v-radio-group>
  </v-card-text>
</template>

<script>
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters } from 'vuex';
import DatetimePicker from '@/components/DatetimePicker.vue';
import SensorDatePicker from '@/components/SensorDatePicker.vue';
import importal from '@/api/importal';
import {
  // eslint-disable-next-line no-unused-vars
  addDays, formatISO, format, parse,
} from 'date-fns';

export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'form.periodicity', event: 'models:periodicity' },
    { data: 'form.dateRangeFrom', event: 'models:dateRangeFrom' },
    { data: 'form.dateRangeTo', event: 'models:dateRangeTo' },
    { data: 'form.lastnScale', event: 'models:lastnScale' },
    { data: 'form.lastnPeriod', event: 'models:lastnPeriod' },
    { data: 'form.dateList', event: 'models:dateList' },
  ],
  components: {
    AgGridVue,
    DatetimePicker,
    SensorDatePicker,
  },
  props: {
    show: {
      type: Boolean,
      default: () => (false),
      required: true,
    },
    showLastN: {
      type: Boolean,
      default: () => (true),
      required: false,
    },
    showRange: {
      type: Boolean,
      default: () => (true),
      required: false,
    },
    showRecord: {
      type: Boolean,
      default: () => (true),
      required: false,
    },
    sensorIdList: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    vectorIdList: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    selectedSensors: {
      type: Array,
      default: () => ([]),
      required: true,
    },
  },
  data: () => ({
    LastPicker: ['Years', 'Months', 'Days', 'Hours', 'Minutes'],

    timeListDialogActivator: false,
    timeList: {
      menuStepCounter: 0,
      selectedDate: null,
      columnDefs: null,
      rowData: null,
      selectedRecords: [],
    },
    form: {
      periodicity: 'last_n',
      dateRangeFrom: null,
      dateRangeTo: null,
      lastnScale: 'Hours',
      lastnPeriod: 2,
      dateList: [],
    },
    pickerTextFieldProps: {
      dense: true,
      style: 'font-size: 80%;',
    },
    pickerDateProps: {
      locale: 'en-ca',
    },
    pickerTimeProps: {
      format: '24hr',
    },
  }),
  computed: {
    ...mapGetters('app', ['pleaseWait', 'timezone']),

    timeList_confirmDisabled() {
      if (this.timeList_ShowDaySelection) {
        return !this.timeList.selectedDate;
      }

      if (this.timeList_ShowDataSelection) {
        return !this.timeList.selectedRecords.length > 0;
      }

      return true;
    },
    timeList_ShowDaySelection() {
      return this.timeList.menuStepCounter === 0;
    },
    timeList_ShowDataSelection() {
      return this.timeList.menuStepCounter === 1;
    },
    timeList_showNextButton() {
      return this.timeList.menuStepCounter < 1;
    },
    timeList_showBackButton() {
      return this.timeList.menuStepCounter > 0;
    },
    timeList_showSaveButton() {
      return !this.timeList_showNextButton;
    },
    timeList_jsonSettings() {
      return JSON.stringify(this.timeList_buildSettings());
    },
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    'form.periodicity': function w(newVal) { this.$emit('models:periodicity', newVal); },
    'form.dateRangeFrom': function w(newVal) { this.$emit('models:dateRangeFrom', newVal); },
    'form.dateRangeTo': function w(newVal) { this.$emit('models:dateRangeTo', newVal); },
    'form.lastnScale': function w(newVal) { this.$emit('models:lastnScale', newVal); },
    'form.lastnPeriod': function w(newVal) { this.$emit('models:lastnPeriod', newVal); },
    'form.dateList': function w(newVal) { this.$emit('models:dateList', newVal); },
  },
  methods: {
    selectPeriodicity(val) {
      this.form.periodicity = val;
    },

    // TimeList Menu NAV
    timeList_next() {
      this.timeList.menuStepCounter += 1;
    },
    timeList_back() {
      this.timeList.menuStepCounter -= 1;
    },
    timeList_cancel() {
      this.timeListDialogActivator = false;
    },
    timeList_save() {
      this.form.dateList = this.form.dateList
        .concat(
          this.timeList.selectedRecords
            .map((x) => (x.ts)),
        );
    },
    timeList_RemoveFromList() {
      this.form.dateList = [];
    },
    timeList_buildSettings() {
      const formulas = []
        .concat(
          this.selectedSensors
            .filter((x) => x.T === 'S')
            .map((x) => ({
              symbol: `s${x.Id}`,
              formula_text: `=s${x.Id};`,
              output_aggregation: 'ANY',
            })),
        )
        .concat(
          this.selectedSensors
            .filter((x) => x.T === 'V')
            .map((x, i) => ({
              symbol: `v${x.Id}${i}`,
              formula_text: `=v${x.Id}${i};`,
              output_aggregation: 'ANY',
            })),
        );

      // eslint-disable-next-line camelcase
      const sensor_selection = []
        .concat(
          this.selectedSensors
            .filter((x) => x.T === 'S')
            .map((x) => ({
              symbol: `s${x.Id}`,
              sensor_id: x.Id,
              input_aggregation: 'ANY',
            })),
        );

      // eslint-disable-next-line camelcase
      const vector_selection = []
        .concat(
          this.selectedSensors
            .filter((x) => x.T === 'V')
            .map((x, i) => ({
              symbol: `v${x.Id}${i}`,
              vector_sensor_id: x.Id,
              vector_name: x.Name,
              input_aggregation: 'ANY',
            })),
        );

      return {
        formulas,
        input_filter: [{
          time_range: {
            start: parse(this.timeList.selectedDate, 'yyyy-MM-dd', new Date()),
            end: addDays(parse(this.timeList.selectedDate, 'yyyy-MM-dd', new Date()), 1),
          },
        },
        ],
        input_time_axis: {
          round_to: {
            scale: 'NONE',
            multiplier: 1,
            divisor: 1,
          },
        },
        output_time_axis: {
          round_to: {
            scale: 'NONE',
            multiplier: 1,
            divisor: 1,
          },
        },
        sensor_selection,
        vector_selection,
        timezone: this.timezone,
        output_in_local: false,
      };
    },
    async timeList_onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      const data = await (await importal.post('SensorQuery', this.timeList_jsonSettings)).data;

      this.timeList.columnDefs = [];

      data.Columns.forEach((col) => {
        const defChild = {
          field: col.ColumnName,
          headerName: col.ColumnName,
          sortable: true,
          resizable: true,
        };

        if (col.ColumnName === 'ts') {
          defChild.cellRenderer = 'UtcDateTimeCellRenderer';
        }

        this.timeList.columnDefs.push(defChild);
      });

      const thisData = [];
      for (let i = 0; i < data.Rows.length; i += 1) {
        const obj = {};
        for (let j = 0; j < data.Columns.length; j += 1) {
          obj[data.Columns[j].ColumnName] = data.Rows[i][j];
        }
        thisData.push(obj);
      }
      this.timeList.rowData = thisData;

      if (thisData.length === 0) {
        this.timeList.selectedDate = null;
        this.timeList_back();
      }
    },
    timeList_onGridSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.timeList.selectedRecords = selectedRows;
      } else {
        this.timeList.selectedRecords = [];
      }
    },
    timeList_selectDayOfData() {
    },

  },
};
</script>

<style>

</style>
