/* eslint-disable class-methods-use-this */
// grid data source
// this is for use by the ServerSideDataGrid component
import importal from '@/api/importal';
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import i18n from '@/plugins/i18n';

export default class GsRolesGrantRole extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
    this.height = '85%';
  }

  // returns a Promise that resolves to column definitions
  // See: https://www.ag-grid.com/vue-data-grid/column-definitions/
  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          maxWidth: 30,
          resizable: false,
          cellStyle: { padding: 0 },
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            icon: 'mdi-minus-circle-outline',
            action: i18n.t('Revoke'),
            confirm: (event) => (`${i18n.t('Settings.AccessControl.ManageRoles.Role')} "${event.data.RoleName}"`),
            clicked: (event) => this.apiOpUserRevokeRole(event.api, event.data),
          },
        },
        {
          field: 'RoleName', minWidth: 200, headerName: i18n.t('Settings.AccessControl.GrantRevokeRoles.RoleName'),
        },
        {
          field: 'RoleId', headerName: i18n.t('Settings.AccessControl.GrantRevokeRoles.RoleId'),
        },
      ]);
    });
  }

  async apiOpUserRevokeRole(api, data) {
    const resp = await importal.post('OpUserRevokeRole', {
      UserIds: [this.vue.selection.user.UserId],
      RoleIds: [data.RoleId],
    });

    api.setRowData(resp.data[0].Roles);

    this.vue.availableRolesList.push(data);
    this.vue.availableRolesList.sort((a, b) => a.RoleName.localeCompare(b.RoleName));
  }
}
