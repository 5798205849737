import i18n from '@/plugins/i18n';
import api from '@/api/importal';

const state = {
  site: null,
  sites: [],
  tenants: null,
  timezones: null,
  siteTypes: null,
  geodeticDatums: null,
  countries: null,
  provincesStates: null,
  provincesStatesByCountry: null,
  notes: null,
  saveSucceeded: true,
};

const actions = {

  async getFormListsData({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    let response = await api.get('SiteTenantsList');
    commit('tenants', response.data);
    response = await api.get('SiteTimezonesList');
    commit('timezones', response.data);
    response = await api.get('SiteGeodeticDatumsList');
    commit('geodeticDatums', response.data);
    response = await api.get('SiteCountriesList');
    response.data.forEach((country) => {
      // eslint-disable-next-line no-param-reassign
      country.NameI18N = i18n.t('Countries.'.concat(country.CountryId));
    });
    commit('countries', response.data);
    response = await api.get('SiteProvincesStatesList');
    response.data.forEach((provinceState) => {
      // eslint-disable-next-line no-param-reassign
      provinceState.NameI18N = i18n.t('ProvincesStates.'.concat(provinceState.ProvinceStateId));
    });
    commit('provincesStates', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async list({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('SitesList');
    commit('sites', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const newSite = payload;
    newSite.Elevation = Math.round(newSite.Elevation);
    newSite.UTMEasting = Math.round(newSite.UTMEasting);
    newSite.UTMNorthing = Math.round(newSite.UTMNorthing);
    newSite.Latitude = Number(newSite.Latitude);
    newSite.Longitude = Number(newSite.Longitude);
    newSite.MagneticDeclination = Math.round(newSite.MagneticDeclination);
    newSite.DataDeliveryDelay = Math.round(newSite.DataDeliveryDelay);
    newSite.DefaultAirDensity = parseFloat(newSite.DefaultAirDensity).toFixed(2);
    await api.post('SitesCreate', newSite)
      .then(() => {
        dispatch('list');
        commit('app/pleaseWait', false, { root: true });
      })
      .catch(() => {
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    let response = await api.get('SiteProvincesStatesList');
    commit('provincesStates', response.data);
    response = (payload.siteGuid)
      ? await api.get(`SitesGet?siteGuid=${payload.siteGuid}`)
      : await api.get(`SitesGet?id=${payload.siteId}`);

    const selectedSite = response.data;
    if (selectedSite.Municipality === null) {
      selectedSite.Municipality = '';
    }
    if (selectedSite.DefaultAirDensity === null) {
      selectedSite.DefaultAirDensity = '';
    }
    if (selectedSite.ShelterBoxLockCode === null) {
      selectedSite.ShelterBoxLockCode = '';
    }
    commit('site', response.data);
    commit('app/pleaseWait', false, { root: true });
  },

  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const editSite = payload;
    editSite.Owner = payload.Owner;
    editSite.TimeZone = payload.TimeZone;
    editSite.GeodeticDatum = payload.GeodeticDatum;
    editSite.SiteType = payload.SiteType;
    editSite.Country = payload.Country;
    editSite.Province = payload.Province;
    editSite.Elevation = Math.round(editSite.Elevation);
    editSite.UTMEasting = Math.round(editSite.UTMEasting);
    editSite.UTMNorthing = Math.round(editSite.UTMNorthing);
    editSite.Latitude = Number(editSite.Latitude);
    editSite.Longitude = Number(editSite.Longitude);
    editSite.MagneticDeclination = Math.round(editSite.MagneticDeclination);
    editSite.DataDeliveryDelay = Math.round(editSite.DataDeliveryDelay);
    if (editSite.InstallationDate === '') {
      editSite.InstallationDate = null;
    }
    if (editSite.RetirementDate === '') {
      editSite.RetirementDate = null;
    }
    if (editSite.ImportDisabledAsOf === '') {
      editSite.ImportDisabledAsOf = null;
    }
    editSite.DefaultAirDensity = parseFloat(editSite.DefaultAirDensity).toFixed(2);
    await api.put('SitesUpdate', editSite)
      .then(() => {
        dispatch('list');
        commit('saveSucceeded', true);
        commit('app/pleaseWait', false, { root: true });
      })
      .catch(() => {
        commit('saveSucceeded', false);
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async remove({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`SitesRemove?id=${payload.id}`);
    commit('app/pleaseWait', false, { root: true });
    await dispatch('list');
  },

  async exportKML({ commit }, labelMode) {
    commit('app/pleaseWait', true, { root: true });
    await api.get(`SiteExportKML?labelOption=${labelMode}`)
      .then((resp) => {
        const blob = new Blob([resp.data.Content], { type: 'application/kml' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'MAP.kml';
        link.click();
        URL.revokeObjectURL(link.href);
      });
    commit('app/pleaseWait', false, { root: true });
  },

  async getSiteType({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get('SiteTypesList');
    let { data } = response;
    if (state?.site?.SiteType) {
      if (state.site.SiteType === 1 || state.site.SiteType === 2) {
        data = data.filter((siteType) => siteType.SiteTypeId === 1 || siteType.SiteTypeId === 2);
      } else {
        data = data.filter((siteType) => siteType.SiteTypeId === state.site.SiteType);
      }
    }

    data.forEach((siteType) => {
      // eslint-disable-next-line no-param-reassign
      siteType.NameI18N = i18n.t('WindServer.Sites.SiteType.'.concat(siteType.Name));
    });
    commit('app/pleaseWait', false, { root: true });
    commit('siteTypes', data);
  },

  setProvincesStatesByCountry({ commit }, countryId) {
    commit('provincesStatesByCountry', countryId);
  },
  async setSite({ commit }, payload) {
    commit('site', payload);
  },

};

const mutations = {

  sites(state, payload) {
    state.sites = payload;
  },

  site(state, payload) {
    if (payload) {
      // Needed only when site is not null.
      const provinces = state.provincesStates.filter((p) => p.CountryId === payload.Country);
      state.provincesStatesByCountry = provinces;
    }
    state.site = payload;
  },

  notes(state, payload) {
    state.notes = payload;
  },

  tenants(state, payload) {
    state.tenants = payload;
  },

  timezones(state, payload) {
    state.timezones = payload;
  },

  siteTypes(state, payload) {
    state.siteTypes = payload;
  },

  geodeticDatums(state, payload) {
    state.geodeticDatums = payload;
  },

  countries(state, payload) {
    state.countries = payload;
  },

  provincesStates(state, payload) {
    state.provincesStates = payload;
  },

  provincesStatesByCountry(state, countryId) {
    const provinces = state.provincesStates.filter((p) => p.CountryId === countryId);
    state.provincesStatesByCountry = provinces;
  },

  saveSucceeded(state, payload) {
    state.saveSucceeded = payload;
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
