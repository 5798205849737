<template>
  <div>
    <v-card-title>
      <p class="mb-0">
        {{ $t('Dashboard.Visual.New') }}
      </p>
    </v-card-title>
    <v-card-text>
      <v-list dense>
        <v-list-item-group
          v-model="selectedgraph"
          color="primary"
        >
          <v-list-item
            v-for="(graph, i) in graphList"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon>{{ graph.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(graph.text) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="pleaseWait"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        :disabled="pleaseWait"
        @click="next"
      >
        {{ $t('Next') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  mdiCamera,
  mdiCardOutline,
  mdiChartBar, mdiChartMultiline, mdiChartScatterPlot, mdiChartTimelineVariant, mdiEarth, mdiTable,
} from '@mdi/js';

export default {
  data: () => ({
    selectedgraph: 1,
    graphList: [],
  }),
  computed: {
    ...mapGetters('app', ['pleaseWait', 'locale']),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // If you update this list, also update method getWidgetName
      // from 'dashboard\components\VisualizationSettings'
      this.graphList = [
        {
          text: 'Dashboard.Visual.Type.PointTimeSeries',
          icon: mdiChartScatterPlot,
          visualisationType: 'plotly',
          visualisationSubtype: 'sensor+series+point',
          graphType: 'scatter',
          graphStyle: 'markers',
        },
        {
          text: 'Dashboard.Visual.Type.LineTimeSeries',
          icon: mdiChartTimelineVariant,
          visualisationType: 'plotly',
          visualisationSubtype: 'sensor+series+line',
          graphType: 'scatter',
          graphStyle: 'lines',
        },
        {
          text: 'Dashboard.Visual.Type.CrossPlot',
          icon: mdiChartScatterPlot,
          visualisationType: 'crossplotly',
          visualisationSubtype: 'sensor+sbs_vertical+point',
          graphType: 'scatter',
          graphStyle: 'markers',
        },
        {
          text: 'Dashboard.Visual.Type.BarTimeSeries',
          icon: mdiChartBar,
          visualisationType: 'plotly',
          visualisationSubtype: 'sensor+series+bar',
          graphType: 'bar',
          graphStyle: 'markers',
        },
        {
          text: 'Dashboard.Visual.Type.LineVectorPlot',
          icon: mdiChartMultiline,
          visualisationType: 'plotly',
          visualisationSubtype: 'vector+scatter+line',
          graphType: 'scatter',
          graphStyle: 'lines',
        },
        {
          text: 'Dashboard.Visual.Type.SbsVerticalLineVectorPlot',
          icon: mdiChartMultiline,
          visualisationType: 'plotly',
          visualisationSubtype: 'vector+sbs_vertical+scatter+line',
          graphType: 'scatter',
          graphStyle: 'lines',
        },
        {
          text: 'Dashboard.Visual.Type.SensorLastValueCard',
          icon: mdiCardOutline,
          visualisationType: 'card',
          visualisationSubtype: 'sensor+lastvalue',
          graphType: '',
          graphStyle: '',
        },
        {
          text: 'Dashboard.Visual.Type.MapOfInstruments',
          icon: mdiEarth,
          visualisationType: 'leaflet',
          visualisationSubtype: 'generic',
          graphType: '',
          graphStyle: '',
        },
        {
          text: 'Dashboard.Visual.Type.TimeSeries',
          icon: mdiTable,
          visualisationType: 'table+data',
          visualisationSubtype: 'sensor+series',
          graphType: '',
          graphStyle: '',
        },
        {
          text: 'Dashboard.Visual.Type.ShowSelectedInstruments',
          icon: mdiTable,
          visualisationType: 'table+instrument',
          visualisationSubtype: 'generic',
          graphType: '',
          graphStyle: '',
        },
        {
          text: 'Dashboard.Visual.Type.Media',
          icon: mdiCamera,
          visualisationType: 'file+feed+picture',
          visualisationSubtype: 'generic',
          graphType: '',
          graphStyle: '',
        },
        {
          text: 'Dashboard.Visual.Type.DashboardList',
          icon: 'mdi-lan-connect',
          visualisationType: 'list+dashboard',
          visualisationSubtype: 'generic',
          graphType: '',
          graphStyle: '',
        },
      ];
    },
    next() {
      this.$emit('next', this.graphList[this.selectedgraph]);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style>

</style>
