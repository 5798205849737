<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ErrorDialog
      v-model="error.Show"
      :message="error.Message"
      :no-cancel="true"
      :title="error.Title"
    />
    <v-toolbar dense>
      <toolbar-button-with-tooltip
        :tooltip-text="$t('NewFormula')"
        :icon-name="'mdi-math-integral'"
        :disabled="IsReadOnly"
        @click="toolbarNewFormula"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('AggregationSetup')"
        :icon-name="'mdi-clock-check'"
        :disabled="IsReadOnly"
        @click="toolbarEditTimeAlign"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('ValidityRangeSetup')"
        :icon-name="'mdi-calendar-check'"
        :disabled="IsReadOnly"
        @click="toolbarEditValidityRange"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('TryVirtualInstrument')"
        :icon-name="'mdi-eye'"
        @click="toolbarTryOut"
      />
      <toolbar-button-with-tooltip
        :tooltip-text="$t('ReplicationGroup')"
        :icon-name="'mdi-sheep'"
        :color="toolbarReplicationColor"
        :disabled="IsReadOnly"
        @click="toolbarReplicationGroup"
      />
      <v-spacer />
      <v-tooltip
        v-if="info.exec && info.exec.CompilationError !== null && info.exec.CompilationError.length === 0"
        bottom
        color="success"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="green">
              mdi-check-decagram
            </v-icon>
          </v-btn>
        </template>
        <span>Compilation success</span>
      </v-tooltip>
      <v-btn
        v-if="info.exec && info.exec.CompilationError !== null && info.exec.CompilationError.length > 0"
        icon
        @click.stop="showError(info.exec.CompilationError)"
      >
        <v-icon color="red">
          mdi-alert-outline
        </v-icon>
      </v-btn>
    </v-toolbar>
    <div style="height: 100%; width: 100%">
      <virtual-instrument-visual-svg
        :vi-data="info.exec"
        @node-header-icon-click="nodeHeaderIconClick"
        @node-delete="onNodeDelete"
        @node-input-transform="onNodeInputTransform"
        @node-new-sensor-constant="onNodeNewSensorConstant"
        @node-new-sensor-baseline="onNodeNewSensorBaseline"
        @node-new-vector-baseline="onNodeNewVectorBaseline"
      />
    </div>
    <dialog-aggregation-control
      v-if="info.exec"
      v-model="timeEditorShow"
      :attributes="info.exec.attributes"
      @on-accept="onTimeEditorAccept"
    />
    <dialog-validity-range
      v-if="info.meta"
      v-model="validityRangeShow"
      :info="info.meta"
      @on-accept="onValidityRangeAccept"
    />
    <dialog-try-out-virtual-instrument
      v-model="tryOutShow"
      :info="info"
    />
    <dialog-replication-control
      v-if="info.exec"
      v-model="replicationShow"
      :vi-data="info.exec"
      :replication-group-id="info.exec.ReplicationGroup"
      @on-accept="onReplicationAccept"
      @on-close="onReplicationClose"
    />
    <v-dialog
      v-model="editor.show"
      persistent
      transition="dialog-bottom-transition"
      content-class="editor-dialog"
      max-width="600"
    >
      <form-piece-formula-edit
        :init="editor"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onFormulaEditorAccept"
          >
            {{ $t('Compile') }}
          </v-btn>
          <v-btn
            text
            @click.stop="editor.show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </form-piece-formula-edit>
      <form-piece-missing-input
        :init="editor"
        @editor-update="onMissingInputUpdate"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onMissingInputCancel"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </form-piece-missing-input>
      <form-piece-input-sensor-binding
        :init="editor"
        @editor-update="onSensorBindingUpdate"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onMissingInputCancel"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </form-piece-input-sensor-binding>
      <form-piece-missing-output
        :init="editor"
        @editor-update="onMissingOutputUpdate"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="editor.show=false"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </form-piece-missing-output>
      <form-piece-output-sensor-binding
        :init="editor"
        :virtual-instrument-id="VirtualInstrumentId"
        @editor-update="onSensorBindingUpdate"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="editor.show=false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </form-piece-output-sensor-binding>
      <form-piece-input-time-sync
        :init="editor"
        :virtual-instrument-id="VirtualInstrumentId"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onInputTimeSyncSave"
          >
            {{ $t('Save') }}
          </v-btn>
          <v-btn
            text
            @click.stop="editor.show=false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </form-piece-input-time-sync>
      <form-piece-output-time-sync
        :init="editor"
        :virtual-instrument-id="VirtualInstrumentId"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onOutputTimeSyncSave"
          >
            {{ $t('Save') }}
          </v-btn>
          <v-btn
            text
            @click.stop="editor.show=false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </form-piece-output-time-sync>
      <form-piece-input-sensor-constant
        :init="editor"
        :virtual-instrument-id="VirtualInstrumentId"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onInputSensorConstantSave"
          >
            {{ $t('Save') }}
          </v-btn>
          <v-btn
            text
            @click.stop="onMissingInputCancel"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </form-piece-input-sensor-constant>
      <form-piece-input-sensor-baseline
        :init="editor"
        :virtual-instrument-id="VirtualInstrumentId"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onInputSensorBaselineSave"
          >
            {{ $t('Save') }}
          </v-btn>
          <v-btn
            text
            @click.stop="onMissingInputCancel"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </form-piece-input-sensor-baseline>
      <form-piece-input-vector-baseline
        :init="editor"
        :virtual-instrument-id="VirtualInstrumentId"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onInputVectorBaselineSave"
          >
            {{ $t('Save') }}
          </v-btn>
          <v-btn
            text
            @click.stop="onMissingInputCancel"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </form-piece-input-vector-baseline>
      <form-piece-input-sensor-attribute
        :init="editor"
        :virtual-instrument-id="VirtualInstrumentId"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onInputSensorAttributeSave"
          >
            Save
          </v-btn>
          <v-btn
            text
            @click.stop="onMissingInputCancel"
          >
            Close
          </v-btn>
        </v-card-actions>
      </form-piece-input-sensor-attribute>
      <form-piece-input-instrument-attribute
        :init="editor"
        :virtual-instrument-id="VirtualInstrumentId"
      >
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click.stop="onInputInstrumentAttributeSave"
          >
            Save
          </v-btn>
          <v-btn
            text
            @click.stop="onMissingInputCancel"
          >
            Close
          </v-btn>
        </v-card-actions>
      </form-piece-input-instrument-attribute>
    </v-dialog>
  </div>
</template>

<script>
import importal from '@/api/importal';
import ErrorDialog from '@/components/ConfirmDialog.vue';
import ToolbarButtonWithTooltip from '@/components/ToolbarButtonWithTooltip.vue';
import datehandling from '@/components/datehandling';
import VirtualInstrumentVisualSvg from './components/VirtualInstrumentVisualSvg.vue';
import FormPieceFormulaEdit from './components/FormPieceFormulaEdit.vue';
import FormPieceMissingInput from './components/FormPieceMissingInput.vue';
import FormPieceMissingOutput from './components/FormPieceMissingOutput.vue';
import FormPieceInputSensorBinding from './components/FormPieceInputSensorBinding.vue';
import FormPieceOutputSensorBinding from './components/FormPieceOutputSensorBinding.vue';
import FormPieceInputTimeSync from './components/FormPieceInputTimeSync.vue';
import FormPieceOutputTimeSync from './components/FormPieceOutputTimeSync.vue';
import FormPieceInputSensorConstant from './components/FormPieceInputSensorConstant.vue';
import DialogAggregationControl from './components/DialogAggregationControl.vue';
import DialogReplicationControl from './components/DialogReplicationControl.vue';
import DialogValidityRange from './components/DialogValidityRange.vue';
import DialogTryOutVirtualInstrument from './components/DialogTryOutVirtualInstrument.vue';
import FormPieceInputSensorBaseline from './components/FormPieceInputSensorBaseline.vue';
import FormPieceInputVectorBaseline from './components/FormPieceInputVectorBaseline.vue';
import FormPieceInputSensorAttribute from './components/FormPieceInputSensorAttribute.vue';
import FormPieceInputInstrumentAttribute from './components/FormPieceInputInstrumentAttribute.vue';

export default {
  name: 'VirtualInstrument',
  components: {
    FormPieceFormulaEdit,
    FormPieceMissingInput,
    FormPieceMissingOutput,
    VirtualInstrumentVisualSvg,
    ErrorDialog,
    FormPieceInputSensorBinding,
    FormPieceOutputSensorBinding,
    ToolbarButtonWithTooltip,
    FormPieceInputTimeSync,
    FormPieceOutputTimeSync,
    FormPieceInputSensorConstant,
    DialogReplicationControl,
    DialogAggregationControl,
    DialogValidityRange,
    DialogTryOutVirtualInstrument,
    FormPieceInputSensorBaseline,
    FormPieceInputVectorBaseline,
    FormPieceInputSensorAttribute,
    FormPieceInputInstrumentAttribute,
  },
  data: () => ({
    VirtualInstrumentId: null,
    info: {
      meta: null,
      exec: null,
    },
    editor: {
      editorMode: null,
      node: null,
      show: false,
      FormulaText: '',
      OutputSymbol: '',
      OutputSymbolErrors: [],
    },
    timeEditorShow: false,
    validityRangeShow: false,
    tryOutShow: false,
    replicationShow: false,
    toolbarReplicationColor: null,
    error: {
      Message: '',
      Title: 'Error',
      Show: false,
      acceptCallback: null,
    },
  }),
  i18n: {
    messages: {
      en: {
        Cancel: 'Cancel',
        Close: 'Close',
        Error: 'Error',
        Save: 'Save',
        Compile: 'Compile',
        ReplicationGroup: 'Replication Group',
        TryVirtualInstrument: 'Try Virtual Instrument',
        ValidityRangeSetup: 'Validity Range Setup',
        AggregationSetup: 'Aggregation Setup',
        NewFormula: 'New Formula',
      },
      fr: {
        Cancel: 'Annuler',
        Close: 'Fermer',
        Error: 'Erreur',
        Save: 'Enregistrer',
        Compile: 'Compiler',
        ReplicationGroup: 'Groupe de réplication',
        TryVirtualInstrument: 'Essayer un instrument virtuel',
        ValidityRangeSetup: 'Paramètres étendue de validité',
        AggregationSetup: 'Paramètres agrégation',
        NewFormula: 'Nouvelle formule',
      },
    },
  },
  computed: {
    IsReadOnly() {
      if (this.info && this.info.meta && this.info.meta.IsManagedByTemplate === false) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.$store.commit('app/pleaseWait', true);
      this.VirtualInstrumentId = Number(this.$route.params.id);
      await Promise.all([
        importal.get(`ViVirtualInstrumentList?VirtualInstrumentId=${this.VirtualInstrumentId}`)
          .then((resp) => resp.data)
          .then((data) => {
            this.info.meta = data.find((x) => x.VirtualInstrumentId === this.VirtualInstrumentId);
            this.$store.commit('app/pageTitle', { key: 'VI: {InstrumentName}[{Name}]', payload: this.info.meta });
          }),
        this.ImportalGetFormulaApi(`ViVirtualInstrumentInfo?VirtualInstrumentId=${this.VirtualInstrumentId}`),
      ])
        .catch((err) => {
          if (err.response.data.exception) {
            this.showError(err.response.data.exception);
          }
          this.$store.commit('app/pleaseWait', false);
        })
        .then(() => {
          this.$store.commit('app/pleaseWait', false);
        });
    },
    showEditorDialog(editorMode) {
      this.editor.OutputSymbolErrors = [];
      this.editor.editorMode = editorMode;
      this.editor.show = true;
    },
    closeEditorDialog() {
      this.editor.show = false;
    },
    nodeHeaderIconClick(node) {
      switch (node.nodeType) {
        case 'formula':
          this.editor.node = node;
          this.editor.FormulaText = node.FormulaText;
          this.editor.OutputSymbol = node.OutputSymbol;
          this.showEditorDialog('edit');
          break;
        case 'input_sensor_xform':
          this.editor.node = node;
          this.editor.FormulaText = node.InputTransform;
          this.showEditorDialog('edit');
          break;
        case 'missing_input':
          this.editor.node = node;
          this.showEditorDialog('new');
          break;
        case 'missing_output':
          this.editor.node = node;
          this.showEditorDialog('new');
          break;
        case 'input_sensor':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'input_vector':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'output_sensor':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'output_vector':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'input_time_sync':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'output_time_sync':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'sensor_constant':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'sensor_baseline':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'vector_baseline':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'sensor_attribute_input':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        case 'instrument_attribute_input':
          this.editor.node = node;
          this.showEditorDialog('edit');
          break;
        default:
          break;
      }
    },
    async onFormulaEditorAccept() {
      const { node, editorMode } = this.editor;
      if (editorMode === 'edit') {
        if (node.nodeType === 'formula') {
          this.editor.node.FormulaText = this.editor.FormulaText;
          this.editor.node.OutputSymbol = this.editor.OutputSymbol;
          this.closeEditorDialog();
          await this.ViFormulaUpdate(node, true);
        } else if (node.nodeType === 'input_sensor_xform') {
          this.editor.node.InputTransform = this.editor.FormulaText;
          this.closeEditorDialog();
          await this.ViInputSensorUpdate(this.editor.node, true);
        }
      } else if (editorMode === 'new') {
        if (node.nodeType === 'formula') {
          if (this.editor.OutputSymbol.length === 0) {
            this.editor.OutputSymbolErrors = ['Cannot be empty'];
          } else {
            this.editor.node.FormulaText = this.editor.FormulaText;
            this.editor.node.OutputSymbol = this.editor.OutputSymbol;
            this.closeEditorDialog();
            await this.ViFormulaUpdate(node, false);
          }
        }
      }
    },

    onMissingInputCancel() {
      this.editor.node.nodeType = 'missing_input';
      this.editor.node = undefined;
      this.editor.show = false;
    },

    // Called when user selects a target node type
    onMissingInputUpdate(e) {
      if (e.node.nodeType === 'input_sensor') {
        this.editor.node = {
          nodeType: e.node.nodeType,
          Symbol: e.node.Symbol ?? e.node.InputSymbol,
          InputAggregation: 'ANY',
          InputTransform: null,
          SensorId: null,
          TransformXUnit: null,
          TransformYUnit: null,
          SensorName: '',
          InstrumentName: '',
        };
      } else if (e.node.nodeType === 'sensor_constant') {
        this.editor.node = {
          nodeType: e.node.nodeType,
          VirtualInstrumentId: this.VirtualInstrumentId,
          Symbol: e.node.Symbol ?? e.node.InputSymbol,
          ConstantId: null,
          Value: null,
          ValidFromTs: null,
          ValidToTs: null,
          Unit: null,
        };
      } else if (e.node.nodeType === 'sensor_baseline') {
        this.editor.node = {
          nodeType: e.node.nodeType,
          VirtualInstrumentId: this.VirtualInstrumentId,
          Symbol: e.node.Symbol ?? e.node.InputSymbol,
          BaselineId: null,
          Value: null,
          SensorId: null,
          SourceTs: null,
          ValidFromTs: null,
          ValidToTs: null,
          Unit: null,
        };
      } else if (e.node.nodeType === 'input_vector') {
        this.editor.node = {
          nodeType: e.node.nodeType,
          Symbol: e.node.Symbol ?? e.node.InputSymbol,
          InputAggregation: 'ANY',
          InputTransform: null,
          VectorSensorId: null,
          TransformXUnit: null,
          TransformYUnit: null,
          VectorName: '',
          InstrumentName: '',
        };
      } else if (e.node.nodeType === 'vector_baseline') {
        this.editor.node = {
          nodeType: e.node.nodeType,
          VirtualInstrumentId: this.VirtualInstrumentId,
          Symbol: e.node.Symbol ?? e.node.InputSymbol,
          VectorBaselineId: null,
          VectorName: null,
          VectorSensorId: null,
          SourceTs: null,
          ValidFromTs: null,
          ValidToTs: null,
          Value: null,
          SamplingInterval: null,
          SamplingOffset: null,
        };
      } else if (e.node.nodeType === 'sensor_attribute_input') {
        this.editor.node = {
          nodeType: e.node.nodeType,
          VirtualInstrumentId: this.VirtualInstrumentId,
          Symbol: e.node.Symbol ?? e.node.InputSymbol,
          ViSensorAttributeInputId: null,
          SensorId: null,
          DefaultValue: null,
          AttributeName: null,
          Unit: null,
        };
      } else if (e.node.nodeType === 'instrument_attribute_input') {
        this.editor.node = {
          nodeType: e.node.nodeType,
          VirtualInstrumentId: this.VirtualInstrumentId,
          Symbol: e.node.Symbol ?? e.node.InputSymbol,
          ViInstrumentAttributeInputId: null,
          InstrumentId: null,
          DefaultValue: null,
          AttributeName: null,
          Unit: null,
        };
      }
    },

    // Add a new sensor constant node to same input
    onNodeNewSensorConstant(node) {
      this.editor.node = {
        nodeType: node.nodeType,
        VirtualInstrumentId: this.VirtualInstrumentId,
        Symbol: node.Symbol,
        ConstantId: null,
        Value: null,
        SensorId: null,
        SourceTs: null,
        ValidFromTs: null,
        ValidToTs: null,
        Unit: null,
      };
      this.showEditorDialog('new');
    },

    // Add a new sensor constant node to same input
    onNodeNewSensorBaseline(node) {
      this.editor.node = {
        nodeType: node.nodeType,
        VirtualInstrumentId: this.VirtualInstrumentId,
        Symbol: node.Symbol,
        BaselineId: null,
        Value: null,
        ValidFromTs: null,
        ValidToTs: null,
        Unit: null,
      };
      this.showEditorDialog('new');
    },

    onNodeNewVectorBaseline(node) {
      this.editor.node = {
        nodeType: node.nodeType,
        VirtualInstrumentId: this.VirtualInstrumentId,
        Symbol: node.Symbol,
        VectorBaselineId: null,
        VectorName: null,
        VectorSensorId: null,
        SourceTs: null,
        ValidFromTs: null,
        ValidToTs: null,
        Value: null,
        SamplingInterval: null,
        SamplingOffset: null,
      };
      this.showEditorDialog('new');
    },

    onMissingOutputUpdate(e) {
      if (e.node.nodeType === 'output_sensor') {
        this.editor.node = {
          nodeType: e.node.nodeType,
          Symbol: e.node.Symbol,
          SensorId: null,
        };
      } else if (e.node.nodeType === 'output_vector') {
        this.editor.node = {
          nodeType: e.node.nodeType,
          Symbol: e.node.Symbol,
          VectorSensorId: null,
          VectorName: null,
        };
      }
    },

    async onSensorBindingUpdate(e) {
      this.closeEditorDialog();
      if (e.node.nodeType === 'input_sensor') {
        await this.ViInputSensorUpdate(e.node, true);
      } else if (e.node.nodeType === 'output_sensor') {
        await this.ViOutputSensorUpdate(e.node, true);
      } else if (e.node.nodeType === 'input_vector') {
        await this.ViInputVectorUpdate(e.node, true);
      } else if (e.node.nodeType === 'output_vector') {
        await this.ViOutputVectorUpdate(e.node, true);
      }
    },

    async onInputTimeSyncSave() {
      const { node } = this.editor;
      node.$InputNode.InputAggregation = this.editor.Aggregation;
      this.closeEditorDialog();
      if (node.$InputNode.nodeType === 'input_sensor') {
        await this.ViInputSensorUpdate(node.$InputNode, true);
      } else if (node.$InputNode.nodeType === 'input_vector') {
        await this.ViInputVectorUpdate(node.$InputNode, true);
      }
    },

    async onOutputTimeSyncSave() {
      const { node } = this.editor;
      node.$FormulaNode.OutputAggregation = this.editor.Aggregation;
      this.closeEditorDialog();
      await this.ViFormulaUpdate(node.$FormulaNode, true);
    },

    async onInputSensorConstantSave() {
      const { node } = this.editor;
      node.Value = Number(this.editor.Value);
      node.ValidFromTs = this.editor.ValidFromTs;
      node.ValidToTs = this.editor.ValidToTs;
      this.closeEditorDialog();
      await this.ViInputSensorConstantUpdate(node, true);
    },

    async onInputSensorBaselineSave() {
      const { node } = this.editor;
      node.SensorId = Number(this.editor.SensorId);
      node.SourceTs = this.editor.SourceTs;
      node.Value = Number(this.editor.Value);
      node.ValidFromTs = this.editor.ValidFromTs;
      node.ValidToTs = this.editor.ValidToTs;
      this.closeEditorDialog();
      await this.ViInputSensorBaselineUpdate(node, true);
    },

    async onInputVectorBaselineSave() {
      const { node } = this.editor;
      node.VectorSensorId = Number(this.editor.VectorSensorId);
      node.VectorName = this.editor.VectorName;
      node.SamplingInterval = this.editor.SamplingInterval;
      node.SamplingOffset = this.editor.SamplingOffset;
      node.Value = this.editor.Value;
      node.SourceTs = this.editor.SourceTs;
      node.ValidFromTs = this.editor.ValidFromTs;
      node.ValidToTs = this.editor.ValidToTs;
      this.closeEditorDialog();
      await this.ViInputVectorBaselineUpdate(node, true);
    },

    async onInputSensorAttributeSave() {
      const { node } = this.editor;
      node.ViSensorAttributeInputId = Number(this.editor.ViSensorAttributeInputId);
      node.SensorId = Number(this.editor.SensorId);
      node.Symbol = this.editor.Symbol;
      node.DefaultValue = Number(this.editor.DefaultValue);
      node.AttributeName = this.editor.AttributeName;
      node.Unit = this.editor.Unit;
      this.closeEditorDialog();
      await this.InputSensorAttributeUpdate(node, true);
    },

    async onInputInstrumentAttributeSave() {
      const { node } = this.editor;
      node.ViInstrumentAttributeInputId = Number(this.editor.ViInstrumentAttributeInputId);
      node.InstrumentId = Number(this.editor.InstrumentId);
      node.Symbol = this.editor.Symbol;
      node.DefaultValue = Number(this.editor.DefaultValue);
      node.AttributeName = this.editor.AttributeName;
      node.Unit = this.editor.Unit;
      this.closeEditorDialog();
      await this.InputInstrumentAttributeUpdate(node, true);
    },

    async onNodeDelete(node) {
      switch (node.nodeType) {
        case 'formula':
          await this.ImportalPostFormulaApi('ViFormulaRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            OutputSymbol: node.OutputSymbol,
          });
          break;
        case 'input_sensor':
          await this.ImportalPostFormulaApi('ViInputSensorRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            Symbol: node.Symbol,
          });
          break;
        case 'output_sensor':
          await this.ImportalPostFormulaApi('ViOutputSensorRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            OutputSymbol: node.Symbol,
          });
          break;
        case 'input_vector':
          await this.ImportalPostFormulaApi('ViInputVectorRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            Symbol: node.Symbol,
          });
          break;
        case 'output_vector':
          await this.ImportalPostFormulaApi('ViOutputVectorRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            OutputSymbol: node.Symbol,
          });
          break;
        case 'input_sensor_xform':
          // eslint-disable-next-line no-param-reassign
          node.InputTransform = null;
          this.ViInputSensorUpdate(node, true);
          break;
        case 'sensor_constant':
          await this.ImportalPostFormulaApi('ViInputSensorConstantRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            ConstantId: node.ConstantId,
          });
          break;
        case 'sensor_baseline':
          await this.ImportalPostFormulaApi('ViInputSensorBaselineRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            BaselineId: node.BaselineId,
          });
          break;
        case 'vector_baseline':
          await this.ImportalPostFormulaApi('ViInputVectorBaselineRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            VectorBaselineId: node.VectorBaselineId,
          });
          break;
        case 'sensor_attribute_input':
          await this.ImportalPostFormulaApi('ViInputSensorAttributeRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            ViSensorAttributeInputId: node.ViSensorAttributeInputId,
          });
          break;
        case 'instrument_attribute_input':
          await this.ImportalPostFormulaApi('ViInputInstrumentAttributeRemove', {
            VirtualInstrumentId: this.VirtualInstrumentId,
            ViInstrumentAttributeInputId: node.ViInstrumentAttributeInputId,
          });
          break;
        default:
          break;
      }
    },

    onNodeInputTransform(node) {
      if (!node.InputTransform) {
        // eslint-disable-next-line no-param-reassign
        node.InputTransform = '=x;';
        this.ViInputSensorUpdate(node, true);
      }
    },

    showError(msg) {
      this.error.Message = msg;
      this.error.Show = true;
    },

    // #region Toolbar actions
    toolbarNewFormula() {
      this.editor.node = {
        nodeType: 'formula',
        FormulaText: '',
        OutputSymbol: '',
        OutputAggregation: 'ANY',
        Unit: '',
      };
      this.editor.FormulaText = '';
      this.editor.OutputSymbol = '';
      this.showEditorDialog('new');
    },
    toolbarEditTimeAlign() {
      this.timeEditorShow = true;
    },
    async onTimeEditorAccept(timeEditor) {
      this.timeEditorShow = false;
      await this.ImportalPostFormulaApi('ViAttributeUpdate', [
        {
          VirtualInstrumentId: this.VirtualInstrumentId,
          Name: 'input_time_axis.round_to.multiplier',
          Value: String(timeEditor.InputMultiplier),
        },
        {
          VirtualInstrumentId: this.VirtualInstrumentId,
          Name: 'input_time_axis.round_to.divisor',
          Value: String(timeEditor.InputMultiplier),
        },
        {
          VirtualInstrumentId: this.VirtualInstrumentId,
          Name: 'input_time_axis.round_to.offset',
          Value: String(timeEditor.InputOffset),
        },
        {
          VirtualInstrumentId: this.VirtualInstrumentId,
          Name: 'input_time_axis.round_to.scale',
          Value: timeEditor.InputForEvery,
        },
        {
          VirtualInstrumentId: this.VirtualInstrumentId,
          Name: 'output_time_axis.round_to.multiplier',
          Value: String(timeEditor.OutputMultiplier),
        },
        {
          VirtualInstrumentId: this.VirtualInstrumentId,
          Name: 'output_time_axis.round_to.divisor',
          Value: String(timeEditor.OutputMultiplier),
        },
        {
          VirtualInstrumentId: this.VirtualInstrumentId,
          Name: 'output_time_axis.round_to.offset',
          Value: String(timeEditor.OutputOffset),
        },
        {
          VirtualInstrumentId: this.VirtualInstrumentId,
          Name: 'output_time_axis.round_to.scale',
          Value: timeEditor.OutputForEvery,
        },
      ]);
    },
    toolbarEditValidityRange() {
      this.validityRangeShow = true;
    },
    async onValidityRangeAccept(range) {
      this.validityRangeShow = false;
      importal
        .post('ViVirtualInstrumentUpdate', {
          VirtualInstrumentId: this.VirtualInstrumentId,
          Name: this.info.meta.Name,
          ValidFromTs: range.ValidFromTs,
          ValidToTs: range.ValidToTs,
        })
        .then((resp) => resp.data)
        .then((data) => {
          this.info.meta = data.find((x) => x.VirtualInstrumentId === this.VirtualInstrumentId);
          this.$store.commit('app/pageTitle', { key: 'VI: {InstrumentName}[{Name}]', payload: this.info.meta });
        });
    },
    toolbarTryOut() {
      this.tryOutShow = true;
    },
    toolbarReplicationGroup() {
      this.replicationShow = true;
    },
    async onReplicationAccept(ev) {
      this.$store.commit('app/pleaseWait', true);
      this.replicationShow = false;
      await importal
        .post('ViVirtualInstrumentReplicate', {
          VirtualInstrumentId: this.VirtualInstrumentId,
          ReplicationGroup: this.info.exec.ReplicationGroup ?? crypto.randomUUID(),
          ReplicationGroupSize: Number(ev.ReplicationNumber),
        })
        .catch((err) => {
          if (err.response.data.exception) {
            this.showError(err.response.data.exception);
          }
          this.$store.commit('app/pleaseWait', false);
        })
        .then((resp) => resp.data)
        .then((data) => {
          if (data instanceof Array) {
            this.info.exec = data.find((x) => x.VirtualInstrumentId === this.VirtualInstrumentId);
          } else {
            this.info.exec = data;
          }
          if (this.info.exec.ReplicationGroup !== null) {
            this.toolbarReplicationColor = 'green';
          } else {
            this.toolbarReplicationColor = null;
          }
          this.$store.commit('app/pleaseWait', false);
        });
    },
    async onReplicationClose() {
      await this.init(); // Reload VI
    },
    // #endregion

    // #region API calls
    async ViInputSensorUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViInputSensorUpdate', {
        VirtualInstrumentId: this.VirtualInstrumentId,
        SensorId: node.SensorId,
        Symbol: node.Symbol,
        InputAggregation: node.InputAggregation,
        InputTransform: node.InputTransform,
        TransformXUnit: node.TransformXUnit,
        TransformYUnit: node.TransformYUnit,
        CanUpdate: updateFlag,
        AttemptAutoWiring: true,
      });
    },

    async ViInputVectorUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViInputVectorUpdate', {
        VirtualInstrumentId: this.VirtualInstrumentId,
        VectorSensorId: node.VectorSensorId,
        VectorName: node.VectorName,
        Symbol: node.Symbol,
        InputAggregation: node.InputAggregation,
        InputTransform: node.InputTransform,
        TransformXUnit: node.TransformXUnit,
        TransformYUnit: node.TransformYUnit,
        CanUpdate: updateFlag,
        AttemptAutoWiring: true,
      });
    },

    async ViOutputSensorUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViOutputSensorUpdate', {
        VirtualInstrumentId: this.VirtualInstrumentId,
        SensorId: node.SensorId,
        Symbol: node.Symbol,
        CanUpdate: updateFlag,
      });
    },

    async ViOutputVectorUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViOutputVectorUpdate', {
        VirtualInstrumentId: this.VirtualInstrumentId,
        VectorSensorId: node.VectorSensorId,
        VectorName: node.VectorName,
        Symbol: node.Symbol,
        CanUpdate: updateFlag,
      });
    },

    async ViFormulaUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViFormulaUpdate', {
        VirtualInstrumentId: this.VirtualInstrumentId,
        FormulaText: node.FormulaText,
        OutputSymbol: node.OutputSymbol,
        OutputAggregation: node.OutputAggregation,
        Unit: node.Unit,
        CanUpdate: updateFlag,
      });
    },

    async ViInputSensorConstantUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViInputSensorConstantUpdate', {
        ConstantId: node.ConstantId,
        VirtualInstrumentId: this.VirtualInstrumentId,
        Value: node.Value,
        Symbol: node.Symbol,
        ValidFromTs: datehandling.formatForApi(node.ValidFromTs),
        ValidToTs: datehandling.formatForApi(node.ValidToTs),
        Unit: node.Unit,
        CanUpdate: updateFlag,
      });
    },

    async ViInputSensorBaselineUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViInputSensorBaselineUpdate', {
        BaselineId: node.BaselineId,
        VirtualInstrumentId: this.VirtualInstrumentId,
        SensorId: node.SensorId,
        SourceTs: datehandling.formatForApi(node.SourceTs),
        Value: node.Value,
        Symbol: node.Symbol,
        ValidFromTs: datehandling.formatForApi(node.ValidFromTs),
        ValidToTs: datehandling.formatForApi(node.ValidToTs),
        CanUpdate: updateFlag,
      });
    },

    async ViInputVectorBaselineUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViInputVectorBaselineUpdate', {
        VectorBaselineId: node.VectorBaselineId,
        VirtualInstrumentId: this.VirtualInstrumentId,
        VectorSensorId: node.VectorSensorId,
        Name: node.VectorName,
        SourceTs: datehandling.formatForApi(node.SourceTs),
        Value: node.Value,
        SamplingInterval: node.SamplingInterval,
        SamplingOffset: node.SamplingOffset,
        Symbol: node.Symbol,
        ValidFromTs: datehandling.formatForApi(node.ValidFromTs),
        ValidToTs: datehandling.formatForApi(node.ValidToTs),
        CanUpdate: updateFlag,
      });
    },

    /*
            public long? ViSensorAttributeInputId { get; set; }
            public long VirtualInstrumentId { get; set; }
            public long SensorId { get; set; }
            public string Symbol { get; set; }
            public double? DefaultValue { get; set; }
            public string AttributeName { get; set; }
            public string Unit { get; set; }
            public bool CanUpdate { get; set; }
     */
    async InputSensorAttributeUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViInputSensorAttributeUpdate', {
        ViSensorAttributeInputId: node.ViSensorAttributeInputId,
        VirtualInstrumentId: this.VirtualInstrumentId,
        SensorId: node.SensorId,
        Symbol: node.Symbol,
        DefaultValue: node.DefaultValue,
        AttributeName: node.AttributeName,
        Unit: node.Unit,
        CanUpdate: updateFlag,
      });
    },

    async InputInstrumentAttributeUpdate(node, updateFlag) {
      await this.ImportalPostFormulaApi('ViInputInstrumentAttributeUpdate', {
        ViInstrumentAttributeInputId: node.ViInstrumentAttributeInputId,
        VirtualInstrumentId: this.VirtualInstrumentId,
        InstrumentId: node.InstrumentId,
        Symbol: node.Symbol,
        DefaultValue: node.DefaultValue,
        AttributeName: node.AttributeName,
        Unit: node.Unit,
        CanUpdate: updateFlag,
      });
    },

    async ImportalGetFormulaApi(uri) {
      await this.ImportalFormulaApi(importal.get, uri);
    },
    async ImportalPostFormulaApi(uri, payload) {
      await this.ImportalFormulaApi(importal.post, uri, payload);
    },
    async ImportalFormulaApi(func, uri, payload) {
      this.$store.commit('app/pleaseWait', true);
      await func(uri, payload)
        .catch((err) => {
          if (err.response.data.exception) {
            this.showError(err.response.data.exception);
          }
          this.$store.commit('app/pleaseWait', false);
        })
        .then((resp) => resp.data)
        .then((data) => {
          this.info.exec = data;
          if (this.info.exec.ReplicationGroup !== null) {
            this.toolbarReplicationColor = 'green';
          } else {
            this.toolbarReplicationColor = null;
          }
          this.$store.commit('app/pleaseWait', false);
        });
    },
    // #endregion
  },
};
</script>

<style lang="scss" scoped>
  .editor-dialog {
    overflow: visible !important;
  }
</style>
