import Vue from 'vue';
import Vuex from 'vuex';

import worker from '@/worker/worker.store';
import app from './modules/app';
import user from './modules/user';
import windserver from './modules/windserver';
import site from '../site/site.store';
import landOwner from '../landOwner/landOwner.store';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    windserver,
    site,
    landOwner,
    worker,
  },
});

export default store;
