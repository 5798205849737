import CellularAccountProvidersList from './components/CellularAccountProvidersList.vue';
import CellularAccountProvidersFormModal from './components/CellularAccountProvidersFormModal.vue';

export default [
  {
    path: '/Equipment/CellularAccountProviders',
    name: 'Equipment.CellularAccountProviders.List',
    component: CellularAccountProvidersList,
    children: [
      {
        path: '/Equipment/CellularAccountProviders/Create',
        name: 'Equipment.CellularAccountProviders.Create',
        component: CellularAccountProvidersFormModal,
      },
      {
        path: '/Equipment/CellularAccountProviders/:id',
        name: 'Equipment.CellularAccountProviders.Update',
        component: CellularAccountProvidersFormModal,
        props: true,
      },
    ],
  },
];
