<template>
  <v-card
    v-if="editorActive"
  >
    <v-card-title>{{ $t('SelectInputBindingType') }}</v-card-title>
    <v-card-text>
      <v-list dense>
        <v-list-item-group
          v-model="nodeType"
          color="primary"
        >
          <v-list-item
            v-for="item in nodeTypeList"
            :key="item.nodeType"
            :value="item.nodeType"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script>
export default {
  components: {
  },
  props: {
    init: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    nodeTypeList: [
      {
        icon: 'mdi-alpha-s-box',
        text: 'Sensor',
        nodeType: 'input_sensor',
      },
      {
        icon: 'mdi-alpha-c-box',
        text: 'Scalar constant',
        nodeType: 'sensor_constant',
      },
      {
        icon: 'mdi-alpha-b-box',
        text: 'Scalar baseline',
        nodeType: 'sensor_baseline',
      },
      {
        icon: 'mdi-alpha-v-box',
        text: 'Vector',
        nodeType: 'input_vector',
      },
      {
        icon: 'mdi-alpha-b-box',
        text: 'Vector Baseline',
        nodeType: 'vector_baseline',
      },
      /*
      {
        icon: 'mdi-alpha-c-box',
        text: '',
        nodeType: 'vector_constant',
      },
      */
      {
        icon: 'mdi-alpha-a-box',
        text: 'Sensor Attribute',
        nodeType: 'sensor_attribute_input',
      },
      {
        icon: 'mdi-alpha-a-box',
        text: 'Instrument Attribute',
        nodeType: 'instrument_attribute_input',
      },
    ],
    editor: {
      node: {
        nodeType: null,
      },
    },
    nodeType: null,
  }),
  computed: {
    editorActive() {
      return this.editor && this.editor.show && this.editor.node && this.editor.node.nodeType === 'missing_input';
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.initialize();
      }
    },
    nodeType() {
      if (this.editorActive) {
        this.editor.node.nodeType = this.nodeType ?? 'missing_input';
        this.$emit('editor-update', this.editor);
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.editor = this.init;
      if (this.editor.node.nodeType === 'missing_input') {
        this.nodeType = undefined;
      } else {
        this.nodeType = this.editor.node.nodeType;
      }
    },
  },
  i18n: {
    messages: {
      en: {
        SelectInputBindingType: 'Select input binding type',
      },
      fr: {
        SelectInputBindingType: 'S�lectionner le type de liaison en entr�e',
      },
    },
  },
};
</script>

<style>

</style>
