<template>
  <v-dialog
    v-model="showDialog"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card
      v-if="editorActive"
    >
      <v-card-title>{{ $t('Title') }}</v-card-title>
      <v-card-text />
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="showDialog=false"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
  ],
  components: {
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
    showDialog: false,
  }),
  computed: {
    editorActive() {
      return this.show && this.instance;
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },
    showDialog(value) {
      if (!value) {
        this.show = false;
      }
    },

    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) { this.$emit('models:show', newVal); },
  },
  created() {
  },
  methods: {
  },
  i18n: {
    messages: {
      en: {
        Title: '.',
      },
      fr: {
        Title: '.',
      },
    },
  },
};
</script>

<style>

</style>
