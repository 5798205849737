<template>
  <v-expand-x-transition
    v-show="editModeFlag"
  >
    <v-card
      v-show="editModeFlag"
      class="right-sidebar"
      tile
      outlined
      elevation="10"
    >
      <v-form
        ref="form"
      >
        <!-- New vizualisation -->
        <visualisation-settings-new-form
          v-if="isNew && !visualisationType"
          @next="newFormNext"
          @cancel="newFormCancel"
        />
        <!-- Visualization settings for plotly type -->
        <visualisation-settings-card
          v-if="visualisationType === 'card'"
          :visualisation="visualisationSubType"
          :graph-text="graphText"
          @cancel="configFormCancel"
          @create="configFormCreate"
          @save="configFormSave"
        />
        <visualisation-settings-plotly-graph
          v-if="visualisationType === 'plotly'"
          :visualisation="visualisationSubType"
          :graph-type="graphType"
          :graph-style="graphStyle"
          :graph-text="graphText"
          @cancel="configFormCancel"
          @create="configFormCreate"
          @save="configFormSave"
        />
        <visualisation-settings-cross-plotly-graph
          v-if="visualisationType === 'crossplotly'"
          :visualisation="visualisationSubType"
          :graph-type="graphType"
          :graph-style="graphStyle"
          :graph-text="graphText"
          @cancel="configFormCancel"
          @create="configFormCreate"
          @save="configFormSave"
        />
        <visualisation-settings-leaflet
          v-if="visualisationType === 'leaflet'"
          :visualisation="visualisationSubType"
          :graph-type="graphType"
          :graph-style="graphStyle"
          :graph-text="graphText"
          @cancel="configFormCancel"
          @create="configFormCreate"
          @save="configFormSave"
        />
        <instrument-table-visualisation-settings
          v-if="visualisationType === 'table+instrument'"
          :visualisation="visualisationSubType"
          :graph-type="graphType"
          :graph-style="graphStyle"
          :graph-text="graphText"
          @cancel="configFormCancel"
          @create="configFormCreate"
          @save="configFormSave"
        />
        <data-table-visualisation-settings
          v-if="visualisationType === 'table+data'"
          :visualisation="visualisationSubType"
          :graph-type="graphType"
          :graph-style="graphStyle"
          :graph-text="graphText"
          @cancel="configFormCancel"
          @create="configFormCreate"
          @save="configFormSave"
        />
        <media-feed-visualisation-settings
          v-if="visualisationType === 'file+feed+picture'"
          :visualisation="visualisationSubType"
          :graph-type="graphType"
          :graph-style="graphStyle"
          :graph-text="graphText"
          @cancel="configFormCancel"
          @create="configFormCreate"
          @save="configFormSave"
        />
        <dashboard-list-visualisation-settings
          v-if="visualisationType === 'list+dashboard'"
          :visualisation="visualisationSubType"
          :graph-type="graphType"
          :graph-style="graphStyle"
          :graph-text="graphText"
          @cancel="configFormCancel"
          @create="configFormCreate"
          @save="configFormSave"
        />
      </v-form>
    </v-card>
  </v-expand-x-transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import VisualisationSettingsCard from './widgets/CardVisualisationSettings.vue';
import VisualisationSettingsLeaflet from './VisualisationSettingsLeaflet.vue';
import VisualisationSettingsNewForm from './VisualisationSettingsNewForm.vue';
import VisualisationSettingsPlotlyGraph from './VisualisationSettingsPlotlyGraph.vue';
import VisualisationSettingsCrossPlotlyGraph from './VisualisationSettingsCrossPlotlyGraph.vue';
import DataTableVisualisationSettings from './widgets/DataTableVisualisationSettings.vue';
import DashboardListVisualisationSettings from './widgets/DashboardListVisualisationSettings.vue';
import InstrumentTableVisualisationSettings from './widgets/InstrumentTableVisualisationSettings.vue';
import MediaFeedVisualisationSettings from './widgets/MediaFeedVisualisationSettings.vue';

export default {
  name: 'VisualizationSettings',
  components: {
    VisualisationSettingsNewForm,
    VisualisationSettingsPlotlyGraph,
    VisualisationSettingsCrossPlotlyGraph,
    VisualisationSettingsLeaflet,
    InstrumentTableVisualisationSettings,
    DataTableVisualisationSettings,
    VisualisationSettingsCard,
    MediaFeedVisualisationSettings,
    DashboardListVisualisationSettings,
  },
  props: {
    dashboard: {
      type: Object,
      default: () => {},
      required: true,
    },
    editModeFlag: {
      type: Boolean,
      default: () => false,
      required: true,
    },
  },
  data: () => ({
    visualisationType: null,
    visualisationSubType: null,
    graphType: 'scatter',
    graphStyle: 'markers',
    graphText: null,
  }),
  computed: {
    ...mapState('dashboardWidget', ['dashboardWidgets', 'activeWidgetId']),
    ...mapGetters('dashboardWidget', ['activeWidget']),
    isNew() {
      return this.activeWidget === undefined;
    },
  },
  watch: {
    activeWidget() {
      this.init();
    },
    editModeFlag(val) {
      if (!val) {
        this.clearWidgetSettings();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.clearWidgetSettings();
      if (!this.isNew) {
        this.loadWidgetSettings();
      }
    },
    clearWidgetSettings() {
      this.visualisationType = null;
    },
    loadWidgetSettings() {
      const widgetSettings = JSON.parse(this.activeWidget.WidgetSettings);
      this.visualisationType = widgetSettings.user.visualisationType || 'plotly';
      this.visualisationSubType = widgetSettings.user.visualisationSubType || 'sensor+series+point';
      this.graphText = this.getWidgetName(this.visualisationType, this.visualisationSubType);
      if (widgetSettings.visualization) {
        this.graphType = widgetSettings.visualization.graphType || 'scatter';
        this.graphStyle = widgetSettings.visualization.graphStyle || 'markers';
      } else {
        this.graphType = '';
        this.graphStyle = '';
      }
    },

    // New form interface
    newFormNext(event) {
      this.visualisationType = event.visualisationType;
      this.visualisationSubType = event.visualisationSubtype;
      this.graphType = event.graphType;
      this.graphStyle = event.graphStyle;
      this.graphText = event.text;
    },
    newFormCancel() {
      this.$emit('cancel', this.isNew);
    },

    // Settings form interface
    configFormCancel(isNew) {
      this.$emit('cancel', isNew);
    },
    insertLocalParameters(event) {
      const thisEvent = event;
      thisEvent.DashboardId = this.dashboard.dashboardId;
      const widgetSettings = JSON.parse(thisEvent.WidgetSettings);
      widgetSettings.user.visualisationType = this.visualisationType;
      widgetSettings.user.visualisationSubType = this.visualisationSubType;
      thisEvent.WidgetSettings = JSON.stringify(widgetSettings);
      return thisEvent;
    },
    configFormSave(event) {
      this.$emit('save', this.insertLocalParameters(event));
    },
    configFormCreate(event) {
      this.$emit('create', this.insertLocalParameters(event));
    },
    getWidgetName(visualisationType, visualisationSubtype) {
      if (visualisationType === 'plotly') {
        if (visualisationSubtype === 'sensor+series+point') {
          return 'Dashboard.Visual.Type.PointTimeSeries';
        }
        if (visualisationSubtype === 'sensor+series+line') {
          return 'Dashboard.Visual.Type.LineTimeSeries';
        }
        if (visualisationSubtype === 'sensor+series+bar') {
          return 'Dashboard.Visual.Type.BarTimeSeries';
        }
        if (visualisationSubtype === 'vector+scatter+line') {
          return 'Dashboard.Visual.Type.LineVectorPlot';
        }
        if (visualisationSubtype === 'vector+fourier+line') {
          return 'Dashboard.Visual.Type.LineVectorFourier';
        }
        if (visualisationSubtype === 'vector+sbs_vertical+scatter+line') {
          return 'Dashboard.Visual.Type.SbsVerticalLineVectorPlot';
        }
      }
      if (visualisationType === 'card' && visualisationSubtype === 'sensor+lastvalue') {
        return 'Dashboard.Visual.Type.SensorLastValueCard';
      }
      if (visualisationType === 'leaflet' && visualisationSubtype === 'generic') {
        return 'Dashboard.Visual.Type.MapOfInstruments';
      }
      if (visualisationType === 'table+data' && visualisationSubtype === 'sensor+series') {
        return 'Dashboard.Visual.Type.TimeSeries';
      }
      if (visualisationType === 'table+instrument' && visualisationSubtype === 'generic') {
        return 'Dashboard.Visual.Type.ShowSelectedInstruments';
      }
      if (visualisationType === 'file+feed+picture' && visualisationSubtype === 'generic') {
        return 'Dashboard.Visual.Type.Media';
      }
      if (visualisationType === 'crossplotly' && visualisationSubtype === 'sensor+sbs_vertical+point') {
        return 'Dashboard.Visual.Type.CrossPlot';
      }
      if (visualisationType === 'list+dashboard' && visualisationSubtype === 'generic') {
        return 'Dashboard.Visual.Type.DashboardList';
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .right-sidebar {
    position: absolute;
    top: 41px;
    right: 10px;
    width: 450px;
    z-index: 9999;
  }
</style>
