/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsLandOwnerEmails extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$auth.roles.includes('Web.LandOwner.Manage') ? this.vue.$t('Edit') : this.vue.$t('View'),
          width: 80,
          resizable: true,
          cellRenderer: this.vue.$auth.roles.includes('Web.LandOwner.Manage') ? 'EditButtonCellRenderer' : 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: (event) => {
              this.vue.$router.push({
                name: 'LandOwners.Emails.Update',
                params: {
                  emailId: event.data.LandOwnerEmailId,
                },
              });
            },
          },
        },
        {
          field: 'Email',
          headerName: this.vue.$t('Settings.LandOwnerEmails.Columns.Email'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 80,
          resizable: true,
          hide: !this.vue.$auth.roles.includes('Web.LandOwner.Delete'),
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (this.vue.$t('Settings.LandOwnerEmails.DeleteEmail', { Email: event.data.Email })),
            clicked: async (event) => {
              await this.vue.$store.dispatch('landOwner/landOwnerEmails/remove', { id: event.data.LandOwnerEmailId });
            },
          },
        },
      ]);
    });
  }
}
