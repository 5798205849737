import VirtualInstrumentTemplateList from './VirtualInstrumentTemplateList.vue';
import VirtualInstrumentTemplateEdit from './VirtualInstrumentTemplateEdit.vue';
import VirtualInstrumentTemplateViEdit from './VirtualInstrumentTemplateViEdit.vue';
import VirtualInstrumentTemplateRunner from './VirtualInstrumentTemplateRunner.vue';
import VirtualInstrumentTemplateEditConst from './VirtualInstrumentTemplateEditConst.vue';

export default [
  {
    path: '/vitpl',
    name: 'VirtualInstrumentTemplate.List',
    component: VirtualInstrumentTemplateList,
  },
  {
    path: '/vitpl/:id',
    name: 'VirtualInstrumentTemplate.Edit',
    component: VirtualInstrumentTemplateEdit,
  },
  {
    path: '/vitpl/:id/run',
    name: 'VirtualInstrumentTemplate.Run',
    component: VirtualInstrumentTemplateRunner,
  },
  {
    path: '/vitpl/:id/const',
    name: 'VirtualInstrumentTemplate.EditConst',
    component: VirtualInstrumentTemplateEditConst,
  },
  {
    path: '/vitpl/:id/:vi',
    name: 'VirtualInstrumentTemplateVi.Edit',
    component: VirtualInstrumentTemplateViEdit,
  },
];
