<template>
  <v-dialog
    v-model="show"
    persistent
    width="400"
  >
    <v-card tile>
      <v-card-title>
        {{ $t('Instruments.UnitDialog.UpdateUnit') }}
      </v-card-title>
      <v-card-text v-if="unitTypes.length > 1">
        <v-select
          v-model="form.propUnitName"
          :items="unitTypes"
          :label="$t('Instruments.UnitDialog.UnitType')"
          item-text="Text"
          :disabled="sensorType === 'Sensor'"
          return-object
          @change="onUnitTypeChanged()"
        />
      </v-card-text>
      <v-card-text>
        <v-select
          v-model="selectedCategory"
          :items="categoryList"
          :label="$t('Instruments.UnitDialog.Category')"
          item-text="Text"
          return-object
          @change="onCategoryChanged()"
        />
      </v-card-text>
      <v-card-text>
        <v-select
          v-model="form.unit"
          :items="filteredUnits"
          :label="$t('Instruments.Unit')"
          item-text="Symbol"
          :disabled="!selectedCategory || !selectedCategory.Text"
          return-object
        />
      </v-card-text>
      <v-card-text>
        <v-row class="pl-3">
          <multi-select-ellipsis
            v-models:selectedItems="form.sensors"
            :items="filteredSensors"
            :placeholder="$t(`Instruments.${sensorType}`)"
            :width-px="290"
            :item-append-prop="form.propUnitName.Name"
          />
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="selectAllSensor"
              >
                <v-icon>mdi-expand-all</v-icon>
              </v-btn>
            </template>
            <span>{{ $t(`SelectAll`) }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
                @click="unselectAllSensor"
              >
                <v-icon>mdi-collapse-all</v-icon>
              </v-btn>
            </template>
            <span>{{ $t(`UnselectAll`) }}</span>
          </v-tooltip>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              :disabled="!canRemove"
              text
              v-on="on"
              @click="removeClick"
            >
              {{ $t('Delete') }}
            </v-btn>
          </template>
          <span>{{ $t(`Instruments.RemoveUnit`) }}</span>
        </v-tooltip>
        <v-btn
          :disabled="!canSubmit"
          text
          @click="submitClick"
        >
          {{ $t('Submit') }}
        </v-btn>
        <v-btn
          text
          @click="show = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import MultiSelectEllipsis from '@/components/MultiSelectEllipsis.vue';

export default {
  name: 'UnitDialog',
  components: {
    MultiSelectEllipsis,
  },
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
  ],
  props: {
    sensorType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    show: false,
    unitTypes: [],
    selectedCategory: null,
    categoryList: [],
    form: {
      propUnitName: null,
      unit: null,
      sensors: [],
    },
  }),
  computed: {
    ...mapState('devices/instruments/units', ['categories', 'filteredSensors', 'filteredUnits']),

    canSubmit() {
      return this.selectedCategory && this.form.unit && this.form.sensors.length > 0;
    },
    canRemove() {
      return this.form.sensors.length > 0;
    },
  },
  watch: {
    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) {
      this.$emit('models:show', newVal);
    },
  },
  async created() {
    if (this.sensorType === 'Sensor') {
      this.unitTypes = [
        { Name: 'Unit', Text: this.$t('Instruments.Unit') },
      ];
    } else {
      this.unitTypes = [
        { Name: 'ReadingUnit', Text: this.$t('Instruments.ReadingUnit') },
        { Name: 'SamplingUnit', Text: this.$t('Instruments.SamplingUnit') },
      ];
    }
    [this.form.propUnitName] = this.unitTypes;

    await this.$store.dispatch('devices/instruments/units/unitList');
    this.categoryList = this.categories;
    this.categoryList.splice(0, 0, { Name: '', Text: '' });
  },
  methods: {
    async onCategoryChanged() {
      this.form.unit = null;
      const selectedSensors = this.form.sensors;
      await this.$store.dispatch('devices/instruments/units/updateUnitList', this.selectedCategory.Name);
      await this.$store.dispatch('devices/instruments/units/updateSensors', this.form.propUnitName.Name);
      this.form.sensors = selectedSensors.filter((x) => this.filteredSensors.includes(x));
    },
    async onUnitTypeChanged() {
      const { sensors } = this.form;
      await this.$store.dispatch('devices/instruments/units/updateSensors', this.form.propUnitName.Name);
      this.form.sensors = sensors.filter((x) => this.filteredSensors.includes(x));
    },
    submitClick() {
      this.show = false;
      this.setUnit(this.form.unit.Symbol);
    },
    async removeClick() {
      this.show = false;
      this.setUnit(null);
    },
    async setUnit(unit) {
      await this.$store.dispatch(`devices/instruments/units/set${this.form.propUnitName.Name}`, {
        InstrumentId: this.$route.params.id,
        Sensors: this.form.sensors,
        Unit: unit,
      });
    },
    selectAllSensor() {
      this.form.sensors = this.filteredSensors;
    },
    unselectAllSensor() {
      this.form.sensors = [];
    },
  },
};
</script>

<style>
</style>
