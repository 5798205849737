<template>
  <div>
    <div class="font-weight-bold">
      {{ site.TenantName }}:
      {{ site.Name }}
    </div>
    <div>
      {{ site.Municipality }},
      {{ site.ProvinceStateName }},
      {{ site.CountryName }}
    </div>
    <div
      v-if="site.InstallationDate"
      class="mt-2"
    >
      <span v-t="'Customers.Sites.InstallationDate'" />:
      {{ formatDateTime(site.InstallationDate) }}
    </div>
    <div v-if="site.RetirementDate">
      <span v-t="'Customers.Sites.Retired'" />:
      {{ formatDateTime(site.RetirementDate) }}
    </div>
    <div class="mt-2">
      <span v-t="'Customers.Sites.PeriodOfRecord'" />:
      <div v-if="site.DataStartDate && site.DataEndDate">
        {{ formatDateTime(site.DataStartDate) }} - {{ formatDateTime(site.DataEndDate) }}
      </div>
      <div
        v-else
        v-t="'Customers.Sites.NoData'"
      />
    </div>
    <div class="mt-2">
      <span v-t="'Customers.Sites.JumpTo'" />:
      <JumpToButtons :site="site" />
    </div>
  </div>
</template>

<script>
import datehandling from '@/components/datehandling';
import JumpToButtons from './JumpToButtons.vue';

export default {
  components: {
    JumpToButtons,
  },
  data() {
    return {
      site: {},
    };
  },
  beforeMount() {
    this.site = this.params.data;
  },
  methods: {
    formatDateTime(date) {
      return datehandling.formatForDisplay(date);
    },
  },
};
</script>
