// import i18n from '@/plugins/i18n';
import api from '@/api/importal';

async function withWait({ commit }, body) {
  commit('app/pleaseWait', true, { root: true });
  return body()
    .finally(() => { commit('app/pleaseWait', false, { root: true }); });
}

const state = {
  datagateways: [],
  configs: [],
  config: null,
};

const actions = {
  async listgateways({ commit }) {
    const requests = [
      api.get('DevGatewayList')
        .then((resp) => resp.data.sort((a, b) => {
          const nameA = a.Name.toUpperCase();
          const nameB = b.Name.toUpperCase();
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }))
        .then((data) => commit('setGatewayData', data)),
    ];
    return withWait({ commit }, () => Promise.all(requests));
  },
  async list({ commit }, workerid) {
    const requests = [
      api.get(`WorkerApiWorkerConfigurationList?W=${workerid}`)
        .then((resp) => commit('setRowData', resp.data)),
    ];
    return withWait({ commit }, () => Promise.all(requests));
  },
  async create({ commit }, { workerid, payload }) {
    const payloadData = {
      WorkerId: workerid,
      ...payload,
    };
    return withWait({ commit }, () => api.post('WorkerApiWorkerConfigurationCreate', payloadData))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async remove({ commit }, { id }) {
    return withWait({ commit }, () => api.post('WorkerApiWorkerConfigurationRemove', {
      WorkerConfigurationId: id,
    }))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async update({ commit }, { id, payload }) {
    const payloadData = {
      WorkerConfigurationId: id,
      ...payload,
    };
    return withWait({ commit }, () => api.post('WorkerApiWorkerConfigurationUpdate', payloadData))
      .then((resp) => commit('mergeRowData', resp.data));
  },
  async get({ state, commit }, { workerid, id }) {
    if (state.configs.length > 0 && state.datagateways.length > 0) {
      commit('setData', state.configs.find((x) => x.WorkerConfigurationId === id));
      return new Promise((r) => r());
    }

    const requests = [
      api.get('DevGatewayList')
        .then((resp) => commit('setGatewayData', resp.data)),
      api.get(`WorkerApiWorkerConfigurationList?W=${workerid}`)
        .then((resp) => commit('setRowData', resp.data))
        .then(() => commit('setData', state.configs.find((x) => x.WorkerConfigurationId === id))),
    ];
    return withWait({ commit }, () => Promise.all(requests));
  },
};

const mutations = {
  setRowData(state, payload) {
    state.configs = payload;
  },
  mergeRowData(state, payload) {
    state.configs = payload;
  },
  setData(state, payload) {
    state.config = payload;
  },
  setGatewayData(state, payload) {
    state.datagateways = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
