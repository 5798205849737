import QCInspection from './components/QCInspectionList.vue';
import QCInspectionFormModal from './components/QCInspectionFormModal.vue';
import QCInspectionSummary from '../qcInspectionSections/components/QCInspectionSummary.vue';
import QCInspectionTraces from '../qcInspectionSections/components/QCInspectionTraces.vue';
import QCInspectionTurbulenceIntensity from '../qcInspectionSections/components/QCInspectionTurbulenceIntensity.vue';
import QCInspectionStdWnd from '../qcInspectionSections/components/QCInspectionStdWnd.vue';
import QCInspectionTowerShadow from '../qcInspectionSections/components/QCInspectionTowerShadow.vue';
import QCInspectionNoteFormModal from '../qcInspectionSections/components/QCInspectionNoteFormModal.vue';

export default [
  {
    path: '/QualityCheck/QCInspections',
    name: 'QualityCheck.QCInspections.List',
    component: QCInspection,
    children: [
      {
        path: '/QualityCheck/QCInspections/:id',
        name: 'QualityCheck.QCInspections.Details',
        component: QCInspectionFormModal,
        props: true,
        children: [
          {
            path: '/QualityCheck/QCInspections/:id/TowerShadow',
            name: 'QualityCheck.QCInspections.TowerShadow',
            component: QCInspectionTowerShadow,
            props: true,
          },
          {
            path: '/QualityCheck/QCInspections/:id/StdWnd',
            name: 'QualityCheck.QCInspections.StdWnd',
            component: QCInspectionStdWnd,
            props: true,
          },
          {
            path: '/QualityCheck/QCInspections/:id/Traces',
            name: 'QualityCheck.QCInspections.Traces',
            component: QCInspectionTraces,
            props: true,
          },
          {
            path: '/QualityCheck/QCInspections/:id/TurbulenceIntensity',
            name: 'QualityCheck.QCInspections.TurbulenceIntensity',
            component: QCInspectionTurbulenceIntensity,
            props: true,
          },
          {
            path: '/QualityCheck/QCInspections/:id/Summary',
            name: 'QualityCheck.QCInspections.Summary',
            component: QCInspectionSummary,
            props: true,
            children: [
              {
                path: '/QualityCheck/QCInspections/:id/Summary/Notes/Create',
                name: 'QualityCheck.QCInspections.Notes.Create',
                component: QCInspectionNoteFormModal,
                props: true,
              },
              {
                path: '/QualityCheck/QCInspections/:id/Summary/Notes/:noteId',
                name: 'QualityCheck.QCInspections.Notes.Update',
                component: QCInspectionNoteFormModal,
                props: true,
              },
            ],
          },
        ],
      },
    ],
  },
];
