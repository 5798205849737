import QCSchedules from './components/QCSchedules.vue';
import QCAssignationList from './components/QCAssignationList.vue';
import QCTenantList from './components/QCTenantList.vue';
import QCTenantAssignation from './components/QCAssignationFormModal.vue';

export default [
  {
    path: '/QualityCheck/QCSchedules',
    name: 'QualityCheck.QCSchedules',
    component: QCSchedules,
    children: [
      {
        path: '/QualityCheck/QCSchedules/Assignations',
        name: 'QualityCheck.QCSchedules.Assignations',
        component: QCAssignationList,
      },
      {
        path: '/QualityCheck/QCSchedules/Tenants',
        name: 'QualityCheck.QCSchedules.Tenants',
        component: QCTenantList,
        children: [
          {
            path: '/QualityCheck/QCSchedules/Tenants/Assignation/',
            name: 'QualityCheck.QCSchedules.Tenants.Assignation',
            component: QCTenantAssignation,
          },
        ],
      },
    ],
  },
];
