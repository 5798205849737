<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.LandOwners"
      :show-new-button="$auth.roles.includes('Web.LandOwner.Create')"
      :toolbar-buttons="toolbarButtons"
      :row-data-init="landOwners"
    />
    <router-view />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import LandOwnerList from '../gridsources/gsLandOwners';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      sources: {
        LandOwners: null,
      },
      toolbarButtons: [],
    };
  },
  computed: {
    ...mapState('landOwner/landOwners', ['landOwners']),
  },
  created() {
    this.sources.LandOwners = new LandOwnerList(this);
    this.refreshList();
  },
  mounted() {
    this.toolbarButtons = [];

    if (this.$auth.roles.includes('Web.LandOwner.Create')) {
      this.toolbarButtons.push({
        tooltip: this.$t('New'),
        icon: 'mdi-plus',
        enabled: true,
        click: () => { this.createNew(); },
      });
    }

    this.toolbarButtons.push({
      tooltip: this.$t('Refresh'),
      icon: 'mdi-refresh',
      enabled: true,
      click: () => { this.refreshList(); },
    });
  },
  methods: {
    createNew() {
      this.$router.push({ name: 'LandOwners.Create' });
    },
    refreshList() {
      this.$store.dispatch('landOwner/landOwners/list');
    },
  },
};
</script>

<style>
.ag-invisible
{
  width: 0px;
  margin-left: -11px;
}
</style>
