<template>
  <div>
    <!-- COMMON: SELECT ALERT TYPE -->
    <v-card-subtitle
      class="text-subtitle-2"
    >
      {{ $t('Dashboard.SubscribeMenu.SubscribeSettings.SelectAlertType') }}
    </v-card-subtitle>
    <v-card-text>
      <v-list
        dense
        shaped
      >
        <v-list-item-group
          v-model="localSelectedAlertType"
          color="red"
        >
          <v-list-item
            v-for="(item, i) in alertTypeList"
            :key="i"
            :value="item"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="$t('Dashboard.SubscribeMenu.SubscribeSettings.'.concat(item.Name).replace(/\s/g, ''))"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    alertTypeList: {
      type: Array,
      required: true,
    },
    selectedAlertType: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      localSelectedAlertType: this.selectedAlertType,
    };
  },
  watch: {
    localSelectedAlertType(newValue) {
      this.$emit('update:selectedAlertType', newValue);
    },
  },
};
</script>

<style scoped>
</style>
