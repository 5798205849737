<template>
  <v-card
    v-if="editorActive"
  >
    <v-card-title>{{ $t('SelectOutputAggregationType') }}</v-card-title>
    <v-card-text>
      <v-select
        v-model="Aggregation"
        :items="AggregateByItems"
        dense
        class="mr-12"
        style="width: 90px; font-size: 80%;"
      />
    </v-card-text>
    <slot />
  </v-card>
</template>

<script>
export default {
  components: {
  },
  props: {
    init: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data: () => ({
    AggregateByItems: ['Any', 'Average', 'Min', 'Max', 'Sum'],
    AggregateBy2Query: ['ANY', 'AVG', 'MIN', 'MAX', 'SUM'],
    editor: {
      node: {
        Aggregation: null,
      },
      Aggregation: null,
    },
    Aggregation: null,
  }),
  computed: {
    editorActive() {
      return this.editor && this.editor.show && this.editor.node && this.editor.node.nodeType === 'output_time_sync';
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.initialize();
      }
    },
    Aggregation() {
      if (this.editorActive) {
        this.editor.Aggregation = this.AggregateBy2Query[
          this.AggregateByItems.indexOf(this.Aggregation)
        ];
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.editor = this.init;
      this.Aggregation = this.AggregateByItems[
        this.AggregateBy2Query.indexOf(this.editor.node.Aggregation)
      ];
    },
  },
  i18n: {
    messages: {
      en: {
        SelectOutputAggregationType: 'Select output aggregation type',
      },
      fr: {
        SelectOutputAggregationType: 'S�lectionner le type agr�gation de sortie',
      },
    },
  },
};
</script>

<style>

</style>
