export default {

  convertToNestedFolders(folders, parentId) {
    const nestedFolders = [];
    let f;
    folders.forEach((folder) => {
      if (folder.ParentId === parentId) {
        const children = this.convertToNestedFolders(folders, folder.SiteAttachmentId);
        f = {
          id: folder.SiteAttachmentId,
          name: folder.Name,
          expanded: true,
        };
        if (children.length > 0) {
          f.children = children;
        }
        nestedFolders.push(f);
      }
    });
    return nestedFolders;
  },

};
