<template>
  <v-dialog
    persistent
    v-model="show"
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-text>
          <v-container fluid>
            <v-text-field
              v-model="formData.Name"
              :label="$t('QualityCheck.QCSectionQuestions.Name')"
              :rules="this.mandatoryRule"
              :error-messages="errorMessage"
              @input="cleanError"
              :readonly="readMode & questionId!=null"
              id="txtQuestionName"
            />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
          id="btnAddEditQuestion"
            text
            @click="submit"
            v-if="$auth.roles.includes('Web.QCInspectionTemplate.Manage')"
          >
            {{ $t('Accept') }}
          </v-btn>
          <v-btn
            text
            @click.stop="show=false"
            id="btnCloseQuestionForm"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';

export default {
  components: {
  },
  props: {
    questionId: {
      type: Number,
      required: false,
      default: null,
    },
    readMode: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
      mandatoryRule: null,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('qualityCheck/qcSectionQuestions', ['sectionQuestion', 'nameExists']),
  },
  watch: {
    async questionId() {
      await this.getQuestion();
    },
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    if (this.questionId && this.questionId != null) {
      await this.getQuestion();
    } else { // Create mode
      this.show = true;
    }
  },
  methods: {
    async getQuestion() {
      await this.$store.dispatch('qualityCheck/qcSectionQuestions/get', { questionId: this.questionId });
      this.formData = this.$clone(this.sectionQuestion);
      this.show = true;
    },
    navigateToList() {
      this.$router.push({ name: 'QualityCheck.QCInspectionTemplates.Sections.Questions' });
    },
    async submit() {
      this.mandatoryRule = [(v) => !!v || this.$t('Validation.Required')];
      this.$nextTick(async () => {
        if (this.$refs.form.validate()) {
          if (await this.validate()) {
            if (this.questionId) {
              await this.$store.dispatch('qualityCheck/qcSectionQuestions/update', this.formData);
            } else {
              await this.$store.dispatch('qualityCheck/qcSectionQuestions/create', this.formData);
            }

            this.navigateToList();
          }
        }
      });
    },
    cleanError() {
      this.mandatoryRule = '';
      this.errorMessage = '';
    },

    async validate() {
      await this.$store.dispatch('qualityCheck/qcSectionQuestions/validate', this.formData);
      if (this.nameExists) {
        this.errorMessage = i18n.t('QualityCheck.QCSectionQuestions.NameExists');
      } else {
        this.errorMessage = '';
        return true;
      }
      return false;
    },
  },
};
</script>
