<template>
  <v-dialog
    persistent
    scrollable
    v-model="showInspectionGeneration"
    width="750"
  >
    <v-card>
      <v-card-title>{{ $t('QualityCheck.QCInspections.InspectionGeneration.Title') }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-3" style="height: 530px">
        {{ $t('QualityCheck.QCInspections.InspectionGeneration.SiteSelectionMessage') }}
        <ServerSideDataGrid
        :disable="disable"
        ref="sitesGrid"
        :enable-selection="'multiple'"
        :class="[{'grid-disabled': loading}, {'mt-3 mb-3': true} ]"
        style="height: 265px;"
        :grid-source="sources.Sites"
        :row-data-init="sites"
      />
        {{ $t('QualityCheck.QCInspections.InspectionGeneration.WarningMessage1') }}
        <br><br>
        {{ $t('QualityCheck.QCInspections.InspectionGeneration.WarningMessage2') }}
      </v-card-text>
      <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
          :loading="loading"
            text
            @click="generateInspections();"
          >
          {{ $t('QualityCheck.QCInspections.InspectionGeneration.Generate') }}
          </v-btn>
          <v-btn
          v-if="!loading"
            text
            @click="hideInspectionGeneration();"
          >
          {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/plugins/i18n';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import SiteList from '../gridsources/gsSites';

export default {
  components: {
    ServerSideDataGrid,
  },
  data() {
    return {
      dialog: true,
      loading: false,
      sources: {
        Sites: null,
      },
    };
  },
  computed: {
    ...mapState('qualityCheck/qcInspections', ['showInspectionGeneration', 'sites', 'selectedAssignation']),
  },
  watch: {
    showInspectionGeneration(value) {
      if (value) {
        this.sources.Sites = new SiteList(this);
        this.$store.dispatch('qualityCheck/qcInspections/sitesList');
      }
    },
  },
  methods: {
    hideInspectionGeneration() {
      this.loading = false;
      this.$store.dispatch('qualityCheck/qcInspections/setShowInspectionGeneration', false);
    },
    async generateInspections() {
      const selectedSites = this.$refs.sitesGrid.gridApi.getSelectedRows();
      if (selectedSites.length === 0) {
        window.$confirm(i18n.t('QualityCheck.QCInspections.InspectionGeneration.NoSiteSelected'), true, {
          title: i18n.t('ApiError.Title'),
          color: 'red',
        });
      } else {
        const selectedSitesIds = [];
        selectedSites.forEach((site) => {
          selectedSitesIds.push(site.SiteId);
        });
        this.loading = true;
        await this.$store.dispatch('qualityCheck/qcInspections/generateInspections', selectedSitesIds);
        this.hideInspectionGeneration();
        this.$store.dispatch('qualityCheck/qcInspections/getInspections', this.selectedAssignation);
      }
    },
  },
};
</script>

<style>
.grid-disabled{
  pointer-events: none;
  opacity: 0.7;
}
</style>
