<template>
  <v-dialog
    v-model="showDialog"
    persistent
    transition="dialog-bottom-transition"
    content-class="editor-dialog"
    max-width="600"
  >
    <v-card
      v-if="editorActive"
    >
      <v-card-title>Select input binding type.</v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item-group
            v-model="nodeType"
            color="primary"
          >
            <v-list-item
              v-for="item in nodeTypeListView"
              :key="item.nodeType"
              :value="item.nodeType"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click.stop="showDialog=false"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /* This is for v-models (notice the final 's') extension
      - "models:" prefix is mandatory in the event name
  */
  models: [
    { data: 'show', event: 'models:show' },
    { data: 'nodeType', event: 'models:nodeType' },
    { data: 'formulaType', event: 'models:formulaType' },
  ],
  components: {
  },
  data: () => ({
    show: false,
    nodeType: '',
    formulaType: '',
    showDialog: false,

    nodeTypeList: [
      {
        icon: 'mdi-alpha-s-box',
        text: 'Direct Sensor',
        nodeType: 'input_direct_sensor',
      },
      {
        icon: 'mdi-alpha-v-box',
        text: 'Direct Vector',
        nodeType: 'input_direct_vector',
      },
      {
        icon: 'mdi-alpha-s-box',
        text: 'External Sensor',
        nodeType: 'input_external_sensor',
      },
      {
        icon: 'mdi-alpha-v-box',
        text: 'External Vector',
        nodeType: 'input_external_vector',
      },
      {
        icon: 'mdi-alpha-c-box',
        text: 'Constant',
        nodeType: 'input_constant_sensor',
      },
      {
        icon: 'mdi-alpha-a-box',
        text: 'Direct Sensor Attribute',
        nodeType: 'input_sensor_attribute',
      },
      {
        icon: 'mdi-alpha-a-box',
        text: 'External Sensor Attribute',
        nodeType: 'input_external_sensor_attribute',
      },
      {
        icon: 'mdi-alpha-a-box',
        text: 'Direct Instrument Attribute',
        nodeType: 'input_direct_instrument_attribute',
      },
      {
        icon: 'mdi-alpha-a-box',
        text: 'External Instrument Attribute',
        nodeType: 'input_external_instrument_attribute',
      },
      {
        icon: 'mdi-alpha-c-box',
        text: 'Direct Instrument Capture',
        nodeType: 'input_direct_instrument_capture',
      },
      {
        icon: 'mdi-alpha-c-box',
        text: 'External Instrument Capture',
        nodeType: 'input_external_instrument_capture',
      },
      {
        icon: 'mdi-alpha-v-box',
        text: 'Direct Sensor Array',
        nodeType: 'input_direct_sensor_array',
      },
      {
        icon: 'mdi-alpha-v-box',
        text: 'External Sensor Array',
        nodeType: 'input_external_sensor_array',
      },
    ],
  }),
  computed: {
    editorActive() {
      return this.show && this.nodeType === 'missing_input';
    },
    nodeTypeListView() {
      if (this.formulaType === 'ARRAY') {
        return this.nodeTypeList.filter((x) => x.nodeType.includes('vector') || x.nodeType.includes('sensor_array'));
      }
      if (this.formulaType === 'FLOAT') {
        return this.nodeTypeList.filter((x) => !(x.nodeType.includes('vector') || x.nodeType.includes('sensor_array')));
      }
      return this.nodeTypeList;
    },
  },
  watch: {
    editorActive() {
      if (this.editorActive) {
        this.showDialog = true;
      } else {
        if (this.nodeType !== 'missing_input') {
          this.onAccept();
        }
        this.showDialog = false;
      }
    },
    showDialog(value) {
      if (!value) {
        this.show = false;
      }
    },

    /* This is for v-models (notice the final 's') extension
        - "models:" prefix is mandatory in the event name
    */
    show: function w(newVal) { this.$emit('models:show', newVal); },
    nodeType: function w(newVal) { this.$emit('models:nodeType', newVal); },
  },
  created() {
  },
  methods: {
    onAccept() {
      this.showDialog = false;
      this.$emit('on-accept', {
        nodeType: this.nodeType,
      });
    },
  },
};
</script>

<style>

</style>
