import qcProblemTypes from './qcProblemTypes/qcProblemTypes.store';
import qcTaskTypes from './qcTaskTypes/qcTaskTypes.store';
import qcTasks from './qcTasks/qcTasks.store';
import qcInspectionTemplates from './qcInspectionTemplates/qcInspectionTemplates.store';
import qcTemplateSections from './qcTemplateSections/qcTemplateSections.store';
import qcSectionQuestions from './qcSectionQuestions/qcSectionQuestions.store';
import qcSectionMeasurements from './qcSectionMeasurements/qcSectionMeasurements.store';
import qcSchedules from './qcSchedules/qcSchedules.store';
import qcInspections from './qcInspections/qcInspections.store';
import qcInspectionNotes from './qcInspectionSections/qcInspectionNotes.store';

export default {
  namespaced: true,
  modules: {
    qcProblemTypes,
    qcTaskTypes,
    qcTasks,
    qcInspectionTemplates,
    qcTemplateSections,
    qcSectionQuestions,
    qcSectionMeasurements,
    qcSchedules,
    qcInspections,
    qcInspectionNotes,
  },
};
