<template>
  <v-select
    v-model="model"
    :label="label"
    :items="items"
    :item-text="itemText"
    :style="{ width: width }"
    class="cursor-pointer ml-3"
    :disabled="disabled"
    clearable
    hide-details
    dense
    :multiple="multiple"
    return-object
    @change="onChanged(model)"
    @click:clear="onClear"
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="multiple && index !== 0" class="mr-2">,</span>
      <v-icon
        v-if="item.iconName"
        :color="item.iconColor || undefined"
        class="mr-2"
        small
      >
        {{ item.iconName }}
      </v-icon>
      <span>{{ item[itemText] }}</span>
    </template>
    <template v-slot:item="{ item, attrs}">
      <div v-bind="attrs">
        <v-icon v-if="multiple" class="mr-3" >
          {{ model.includes(item)
            ? 'mdi-checkbox-marked'
            : 'mdi-checkbox-blank-outline' }}
        </v-icon>
        <v-icon
          v-if="item.iconName"
          :color="item.iconColor || undefined"
          class="mr-2"
          small
        >
          {{ item.iconName }}
        </v-icon>
        <span >{{ item[itemText] }}</span>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'SelectField',
  props: {
    value: {
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    width: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    onChanged: {
      type: Function,
      default: () => {},
    },
    onClear: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      model: null,
    };
  },
  created() {
    this.model = this.value;
  },
  watch: {
    value() {
      this.model = this.value;
    },
  },
};
</script>
