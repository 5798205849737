<template>
  <li style="font-size: 75%;">
    <span v-if="item.isAdded">
      {{ $t('WindServer.Sites.SiteConfigurations.Details.Added') }}:
    </span>
    <span v-else-if="item.isRemoved">
      {{ $t('WindServer.Sites.SiteConfigurations.Details.Removed') }}:
    </span>
    <span v-else>
      {{ $t('WindServer.Sites.SiteConfigurations.Details.Modified') }}:
    </span>
    {{ getI18NEquipmentType(item.EquipmentType) }}
    <span v-if="item.EquipmentType === 'Anchor'">
      (type {{ item.PeNumber }})
    </span>
    <span v-else>
      {{ $t('WindServer.Sites.SiteConfigurations.Details.PeNumber') }}:
      {{ formattedPeNumber(item.PeNumber) }}
    </span>
    <ChangeProperties
      v-for="(props, i) in item.ChangeProperties"
      :key="i"
      :props="props"
    />
  </li>
</template>

<script>
import i18n from '@/plugins/i18n';
import ChangeProperties from './ChangeProperties.vue';

export default {
  components: {
    ChangeProperties,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    formattedPeNumber(peNumber) {
      return peNumber ? String(peNumber).padStart(5, '0') : '';
    },
    getI18NEquipmentType(type) {
      return i18n.t('Equipments.EquipmentType.'.concat(type));
    },
  },
};
</script>
