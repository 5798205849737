import api from '@/api/importal';

const state = {
  note: null,
  notes: [],
  inspectionId: null,
};

const actions = {
  async create({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.post('QCInspectionNotesCreate', payload);
    commit('app/pleaseWait', false, { root: true });
    dispatch('list', state.inspectionId);
  },
  async update({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.put('QCInspectionNotesUpdate', payload);
    commit('app/pleaseWait', false, { root: true });
    dispatch('list', state.inspectionId);
  },
  async get({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCInspectionNotesGet?noteId=${payload.noteId}`);
    commit('note', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async list({ commit }, inspectionId) {
    commit('inspectionId', inspectionId);
    commit('app/pleaseWait', true, { root: true });
    const response = await api.get(`QCInspectionNotesList?inspectionId=${inspectionId}`);
    commit('notes', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
  async remove({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    await api.delete(`QCInspectionNotesRemove?id=${payload}`);
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {
  inspectionId(state, payload) {
    state.inspectionId = payload;
  },
  note(state, payload) {
    state.note = payload;
  },
  notes(state, payload) {
    state.notes = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
