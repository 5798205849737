<template>
  <div style="height: calc(100vh - 120px); width: 100%">
    <ServerSideDataGrid
      :grid-source="sources.GsValidation"
      :show-new-button="false"
      :row-data-init="rowdata"
      :toolbar-buttons="toolbarButtons"
      enable-selection="multiple"
      @grid-ready="onGridReady"
      @selection-changed="onSelect"
    />
    <ConfirmDialog
      v-model="showDeletePopup"
      :message="$t('Analytics.EraseMessage')"
      :title="$t('ConfirmDeletion')"
      @on-accept="deleteMultipleData()"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GsValidation from './gridsources/gsvalidation';
import ServerSideDataGrid from '@/components/ServerSideDataGrid.vue';
import ConfirmDialog from '../components/ConfirmDialog.vue';

export default {
  name: 'ValidationView',
  components: {
    ServerSideDataGrid,
    ConfirmDialog,
  },
  data() {
    return {
      sources: {
        GsValidation: null,
      },
      columnDefs: [],
      toolbarButtons: [],
      showDeletePopup: false,
      selectedRows: [],
      selectedData: null,
    };
  },
  computed: {
    ...mapState('analytics', ['rowdata', 'table', 'query']),
    ...mapGetters('app', ['timezone', 'tenantId', 'accessGroupIds']),

    setButtonEnabled() {
      return this.selectedRows.length > 0;
    },
  },
  watch: {
    async tenantId() {
      this.$router.push('/analytics/quicksensor');
    },
    async accessGroupIds() {
      this.$router.push('/analytics/quicksensor');
    },
    async timezone() {
      this.$router.push('/analytics/quicksensor');
    },
  },
  async created() {
    this.sources.GsValidation = new GsValidation(this);
    if (this.rowdata.length === 0) {
      this.redirectToQuickSensorPage();
    } else {
      await this.$store.dispatch('analytics/sensorQuery');
      this.init();
    }
  },
  mounted() {
    this.toolbarButtons = [
      {
        tooltip: this.$t('Analytics.Icons.Delete'),
        icon: 'mdi-delete',
        enabled: this.setButtonEnabled,
        click: () => { this.onDeleteData(); },
      },
    ];
  },
  methods: {
    async init() {
      this.columnDefs = [];
      this.table.Columns.forEach((col) => {
        const defChild = {
          field: col.ColumnName,
          headerName: this.findAliasCategory(col.ColumnName)[1],
          sortable: true,
          resizable: true,
        };
        if (col.ColumnName === 'ts') {
          defChild.cellRenderer = 'UtcDateTimeCellRenderer';
        } else {
          defChild.valueFormatter = (params) => params.data[col.ColumnName];
        }
        const coldef = this.columnDefs
          .find((x) => x.headerName === this.findAliasCategory(col.ColumnName)[0]);
        if (coldef) {
          coldef.children.push(defChild);
        } else {
          this.columnDefs.push({
            headerName: this.findAliasCategory(col.ColumnName)[0],
            children: [defChild],
          });
        }
      });
      this.gridApi.setColumnDefs(this.columnDefs);

      const thisData = [];
      for (let i = 0; i < this.table.Rows.length; i += 1) {
        const obj = {};
        for (let j = 0; j < this.table.Columns.length; j += 1) {
          obj[this.table.Columns[j].ColumnName] = this.table.Rows[i][j];
        }
        thisData.push(obj);
      }
      this.gridApi.setRowData(thisData);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    findAliasCategory(symbol) {
      const found = this.query.visualization.alias.find((x) => x[symbol] !== undefined);
      if (found) {
        return found[symbol].split('.');
      }
      return ['', symbol];
    },

    findAlias(symbol) {
      const found = this.query.visualization.alias.find((x) => x[symbol] !== undefined);
      if (found) {
        return found[symbol];
      }
      return symbol;
    },

    onDeleteData() {
      this.showDeletePopup = true;
    },

    async deleteMultipleData() {
      const deleteData = {};
      this.selectedRows.forEach((data) => {
        const timestamp = data.ts;
        Object.keys(data).forEach((key) => {
          if (key !== 'ts') {
            if (!deleteData[key]) {
              deleteData[key] = [];
            }
            deleteData[key].push(timestamp);
          }
        });
      });

      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

      const deletePromises = Object.keys(deleteData).map(
        (key) => this.$store.dispatch('analytics/removeValues', {
          SensorId: parseInt(key.slice(1), 10),
          DateTimes: deleteData[key],
          Timezone: this.timezone,
          TimezoneComputer: timeZone,
        }),
      );

      await Promise.all(deletePromises);

      const thisData = [];
      for (let i = 0; i < this.table.Rows.length; i += 1) {
        const obj = {};
        for (let j = 0; j < this.table.Columns.length; j += 1) {
          obj[this.table.Columns[j].ColumnName] = this.table.Rows[i][j];
        }
        thisData.push(obj);
      }
      this.gridApi.setRowData(thisData);
    },

    onSelect(e) {
      const { api } = e;
      this.selectedRows = api.getSelectedRows();
      [0].forEach((x) => {
        this.toolbarButtons[x].enabled = this.setButtonEnabled;
      });
    },

    redirectToQuickSensorPage() {
      this.$router.push('/analytics/quicksensor');
    },
  },
};
</script>
