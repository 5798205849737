/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';
import datehandling from '@/components/datehandling';

function UTCtoLocalDisplay(UTCDate) {
  return datehandling.formatForDisplay(datehandling.zonedTimeToUtc(UTCDate));
}

export default class GsSiteFiles extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: '',
          headerName: this.vue.$t('WindServer.Sites.Attachments.Download'),
          width: 90,
          resizable: true,
          cellRenderer: 'InfoButtonStringCellRenderer',
          cellRendererParams: {
            noConfirm: true,
            clicked: async (event) => {
              await this.vue.$store.dispatch('site/siteAttachments/download', {
                id: event.data.SiteAttachmentId,
                name: event.data.Name,
              });
            },
          },
        },
        {
          field: 'SiteAttachmentId',
          headerName: this.vue.$t('WindServer.Sites.Attachments.Id'),
          width: 70,
          editable: false,
          filter: true,
          sortable: true,
        },
        // ------------------------------------------------
        // Buttons to lock/unlock files edit mode
        // Locking mechanism disabled after client request
        // ------------------------------------------------
        // {
        //   field: '',
        //   headerName: this.vue.$t('Edit'),
        //   width: 80,
        //   resizable: true,
        //   cellRenderer: 'LockButtonCellRenderer',
        //   cellRendererParams: {
        //     noConfirm: true,
        //     clicked: (event) => {
        //       this.vue.$router.push({
        //         name: 'Sites.Files.Update',
        //         params: {
        //           locked: true,
        //           noteId: event.data.SiteAttachmentId,
        //         },
        //       });
        //     },
        //   },
        // },
        // ------------------------------------------------
        {
          field: 'Name',
          headerName: this.vue.$t('WindServer.Sites.Attachments.FileName'),
          width: 400,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'FileSize',
          headerName: this.vue.$t('WindServer.Sites.Attachments.FileSize'),
          width: 100,
          editable: false,
          filter: true,
          sortable: true,
        },
        // ------------------------------------------------
        // Display the user who locked the file
        // Locking mechanism disabled after client request
        // ------------------------------------------------
        // {
        //   field: 'LockedByUser',
        //   headerName: this.vue.$t('WindServer.Sites.Attachments.LockedBy'),
        //   width: 300,
        //   editable: false,
        //   filter: true,
        //   sortable: true,
        // },
        // ------------------------------------------------
        {
          field: 'CreatedOn',
          headerName: this.vue.$t('CreatedOn'),
          width: 150,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatter,
        },
        {
          field: 'CreatedByUser',
          headerName: this.vue.$t('CreatedBy'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: 'LastModifiedOn',
          headerName: this.vue.$t('LastModifiedOn'),
          width: 150,
          editable: false,
          filter: true,
          sortable: true,
          valueFormatter: this.formatter,
        },
        {
          field: 'LastModifiedByUser',
          headerName: this.vue.$t('LastModifiedBy'),
          width: 300,
          editable: false,
          filter: true,
          sortable: true,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 80,
          resizable: true,
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (this.vue.$t('WindServer.Sites.Attachments.ConfirmFileDeletion', { Name: event.data.Name })),
            clicked: async (event) => {
              await this.vue.$store.dispatch('site/siteAttachments/remove', event.data.SiteAttachmentId);
            },
          },
        },
      ]);
    });
  }

  formatter(params) {
    switch (params.colDef.field) {
      case 'CreatedOn':
        if (params.data.CreatedOn != null) {
          return UTCtoLocalDisplay(params.data.CreatedOn);
        }
        return '';

      case 'LastModifiedOn':
        if (params.data.LastModifiedOn != null) {
          return UTCtoLocalDisplay(params.data.LastModifiedOn);
        }
        return '';

      default:
        return '';
    }
  }
}
