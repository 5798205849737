<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="100%"
    scrollable
  >
    <v-card v-if="configurationDetails">
      <v-form
        ref="form"
        lazy-validation
      >
        <p class="pt-5 pl-5 mb-2">
          {{ $t('WindServer.Sites.SiteConfigurations.SiteName') }}
        </p>
        <p
          class="pl-5"
          style="font-size: 75%;"
        >
          {{ configurationDetails.SiteName }}
        </p>
        <p
          class="pl-5 mb-2"
        >
          {{ $t('WindServer.Sites.SiteConfigurations.Date') }}
        </p>
        <p
          class="pl-5"
          style="font-size: 75%;"
        >
          {{ formattedStartDate }}
        </p>
        <p
          class="pl-5 mb-2"
        >
          {{ $t('WindServer.Sites.SiteConfigurations.Differences') }}
        </p>
        <div class="pl-5">
          <ul>
            <li style="font-size: 75%;">
              {{ $t('WindServer.Sites.SiteConfigurations.Details.SiteId') }}:
              {{ configurationDetails.SiteId }}
            </li>
            <li style="font-size: 75%;">
              {{ $t('WindServer.Sites.SiteConfigurations.Details.PeriodStart') }}:
              {{ formattedStartDate }}
            </li>
            <li
              v-if="configurationDetails.OldPeriod"
              style="font-size: 75%;"
            >
              {{ $t('WindServer.Sites.SiteConfigurations.Details.PeriodChanged') }}
              {{ $t('WindServer.Sites.SiteConfigurations.Details.From') }} [
              {{ formattedDate(configurationDetails.OldPeriod.StartDate) }}
              {{ $t('WindServer.Sites.SiteConfigurations.Details.To') }}
              {{ formattedDate(configurationDetails.OldPeriod.EndDate) }} ]
              {{ $t('WindServer.Sites.SiteConfigurations.Details.To') }} [
              {{ formattedDate(configurationDetails.NewPeriod.StartDate) }}
              {{ $t('WindServer.Sites.SiteConfigurations.Details.To') }}
              {{ formattedDate(configurationDetails.NewPeriod.EndDate) }} ]
            </li>
            <DifferencesList
              v-for="(item, i) in configurationDetails.Differences"
              :key="i"
              :item="item"
            />
          </ul>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="show=false"
          >
            {{ $t('Accept') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import DifferencesList from './DifferencesList.vue';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

export default {
  components: {
    DifferencesList,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    startdate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      show: false,
    };
  },
  computed: {
    ...mapState('site/siteConfigurations', ['siteConfiguration', 'configurationDetails']),
    ...mapState('site/sites', ['site']),
    dateTimeFormat() {
      return `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
    },
    formattedStartDate() {
      return this.configurationDetails ? format(this.configurationDetails.StartDate, this.dateTimeFormat) : '';
    },
  },
  watch: {
    show(newValue) {
      if (!newValue) {
        this.navigateToList();
      }
    },
  },
  async created() {
    await this.$store.dispatch('site/siteConfigurations/getDetails', this.startdate);
    this.show = true;
  },
  methods: {
    async navigateToList() {
      this.$router.push({ name: 'Sites.SiteConfigurations' });
    },
    formattedDate(date) {
      return date ? format(date, this.dateTimeFormat) : '';
    },
  },
};
</script>
