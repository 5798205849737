import VirtualInstrument from './VirtualInstrument.vue';
import VirtualInstrumentList from './VirtualInstrumentList.vue';

export default [
  {
    path: '/vi',
    name: 'VirtualInstrument.List',
    component: VirtualInstrumentList,
  },
  {
    path: '/vi/:id',
    name: 'VirtualInstrument.Edit',
    component: VirtualInstrument,
  },
];
