import { format } from 'date-fns';
import i18n from '@/plugins/i18n';
import api from '@/api/importal';

const state = {
  instruments: [],
  flagSets: [],
  sensors: [],
  selectedSensors: [],
  siteId: null,
  flagPeriodNumber: null,
  flagPeriods: [],
  flagPeriod: null,
  qaCodes: null,
  dateRanges: [],
  parseError: false,
  openFlags: false,
};

const actions = {
  async init({ commit, dispatch }, payload) {
    commit('siteId', payload);
    if (state.qaCodes === null) {
      commit('app/pleaseWait', true, { root: true });
      await api.get('DataTraceQaCodes')
        .then((resp) => {
          resp.data.forEach((code) => {
            // eslint-disable-next-line no-param-reassign
            code.DetailsI18N = i18n.t('WindServer.Sites.DataTrace.QaCodes.'.concat(code.QaCodeId));
          });
          commit('qaCodes', resp.data);
          commit('app/pleaseWait', false, { root: true });
        })
        .catch(() => {
          commit('app/pleaseWait', false, { root: true });
        });
    }
    await dispatch('getInstrumentListForSensor');
    await dispatch('getSensorsList');
    commit('initFlags');
  },

  initFlags({ commit }) {
    commit('initFlags');
  },

  async getInstrumentListForSensor({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    await api.get('DataTraceInstrumentList?'
    + `siteId=${state.siteId}`)
      .then((resp) => {
        commit('instruments', resp.data);
        commit('app/pleaseWait', false, { root: true });
      })
      .catch(() => {
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async getSensorsList({ commit }) {
    commit('app/pleaseWait', true, { root: true });
    await api.get('DataTraceSensorList?'
    + `siteInstrumentIds=${state.instruments}`)
      .then((resp) => {
        commit('sensors', resp.data);
        commit('app/pleaseWait', false, { root: true });
      })
      .catch(() => {
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async setSelectedSensor({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('selectedSensors', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async updateSelectedSensor({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('updateSelectedSensor', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async createFlagPeriod({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const flag = payload;
    flag.FlagPeriodId = state.flagPeriodNumber - 1;
    state.flagPeriods.push(flag);
    commit('flagPeriodNumber', state.flagPeriodNumber - 1);
    commit('app/pleaseWait', false, { root: true });
  },

  async updateFlagPeriod({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const idx = state.flagPeriods.findIndex(
      (x) => x.FlagPeriodId === payload.FlagPeriodId,
    );
    state.flagPeriods.splice(idx, 1, payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async flagPeriod({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('flagPeriod', payload);
    commit('app/pleaseWait', false, { root: true });
  },

  async flagPeriods({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    payload.forEach((x) => {
      const p = x;
      p.FlagPeriodId = state.flagPeriodNumber - 1;
      state.flagPeriods.push(p);
      commit('flagPeriodNumber', state.flagPeriodNumber - 1);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async deleteFlagPeriods({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    payload.forEach((x) => {
      const idx = state.flagPeriods.findIndex(
        (y) => y.FlagPeriodId === x.FlagPeriodId,
      );
      state.flagPeriods.splice(idx, 1);
    });
    commit('app/pleaseWait', false, { root: true });
  },

  async createFlags({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = payload;
    data.SiteId = state.siteId;
    data.QaFlagSetPeriods = state.flagPeriods;
    data.QaFlagSetSensors = state.selectedSensors;
    await api.post('CreateDataTraceFlags', data)
      .then(() => {
        commit('app/pleaseWait', false, { root: true });
      })
      .catch(() => {
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async updateFlags({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const data = payload;
    data.SiteId = state.siteId;
    data.QaFlagSetPeriods = state.flagPeriods;
    data.QaFlagSetSensors = state.selectedSensors;
    await api.post('UpdateDataTraceFlags', data)
      .then(() => {
        commit('app/pleaseWait', false, { root: true });
      })
      .catch(() => {
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async getFlagSets({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    let Uri;
    let dt = null;
    Uri = 'DataTraceGetFlags?'
      + `siteInstrumentIds=${state.instruments}`
      + `&siteId=${state.siteId}`;
    if (payload) {
      if (payload.SelectDate) {
        dt = format(new Date(payload.SelectDate.toISOString().slice(0, -1)), 'yyyyMMddHHmmss');
        Uri += `&selectDate=${dt}`;
      }
      if (payload.SensorId) {
        Uri += `&sensorId=${payload.SensorId}`;
      }
      if (payload.OpenSet) {
        Uri += '&openSets=true';
      } else {
        Uri += '&openSets=false';
      }
    }
    await api.get(Uri)
      .then((resp) => {
        commit('flagSets', resp.data);
        commit('app/pleaseWait', false, { root: true });
      })
      .catch(() => {
        commit('app/pleaseWait', false, { root: true });
      });
  },

  async parseTimestamps({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    if (payload.Timestamps) {
      await api.post('DataTraceParseTimesStamps', payload)
        .then((resp) => {
          commit('dateRanges', resp.data);
          commit('parseError', false);
          commit('app/pleaseWait', false, { root: true });
        })
        .catch(() => {
          commit('parseError', true);
          commit('app/pleaseWait', false, { root: true });
        });
    } else {
      commit('dateRanges', null);
      commit('app/pleaseWait', false, { root: true });
    }
  },

  async intervals({ commit, dispatch }, payload) {
    commit('app/pleaseWait', true, { root: true });
    if (payload) {
      state.dateRanges.forEach((x) => {
        dispatch('createFlagPeriod', x);
      });
    }
    commit('dateRanges', null);
    commit('app/pleaseWait', false, { root: true });
  },

  async shiftFlags({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const s = payload;
    s.InstrumentIds = state.instruments;
    s.SiteId = state.siteId;
    await api.post('DataTraceShiftFlagSets', s);
    commit('app/pleaseWait', false, { root: true });
  },

  async deleteFlags({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    const s = {
      Ids: payload,
      SiteId: state.siteId,
    };
    await api.post('DataTraceDeleteFlagSets', s);
    commit('app/pleaseWait', false, { root: true });
  },

  async checkOpenFlags({ commit }, payload) {
    commit('app/pleaseWait', true, { root: true });
    commit('openFlags', false);
    const response = await api.post('DataTraceCheckOpenFlags', payload);
    commit('openFlags', response.data);
    commit('app/pleaseWait', false, { root: true });
  },
};

const mutations = {

  initFlags(state) {
    state.flagPeriodNumber = 0;
    state.flagPeriods = [];
    state.flagPeriod = null;
  },

  instruments(state, payload) {
    state.instruments = payload;
  },

  flagSets(state, payload) {
    state.flagSets = payload;
  },

  sensors(state, payload) {
    state.sensors = payload;
  },

  selectedSensors(state, payload) {
    state.selectedSensors = [];
    payload.forEach((x) => {
      const s = {
        SensorId: x.Id,
      };
      state.selectedSensors.push(s);
    });
  },

  updateSelectedSensor(state, payload) {
    state.selectedSensors = [];
    payload.forEach((x) => {
      const s = {
        SensorId: x.SensorId,
      };
      state.selectedSensors.push(s);
    });
  },

  siteId(state, payload) {
    state.siteId = payload;
  },

  flagPeriodNumber(state, payload) {
    state.flagPeriodNumber = payload;
  },

  flagPeriod(state, payload) {
    state.flagPeriod = payload;
  },

  qaCodes(state, payload) {
    state.qaCodes = payload;
  },

  dateRanges(state, payload) {
    state.dateRanges = payload;
  },

  parseError(state, payload) {
    state.parseError = payload;
  },

  openFlags(state, payload) {
    state.openFlags = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
