<template>
  <v-toolbar dense class="flex-grow-0">
    <v-btn icon @click="$emit('refresh')">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
    <v-spacer />
    <v-btn-toggle v-model="currentViewMode" dense mandatory>
      <v-btn>
        <v-icon>mdi-view-list-outline</v-icon>
      </v-btn>

      <v-btn>
        <v-icon>mdi-map</v-icon>
      </v-btn>
    </v-btn-toggle>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    viewMode: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentViewMode: this.viewMode,
    };
  },
  watch: {
    currentViewMode(newValue) {
      this.$emit('update:viewMode', newValue);
    },
  },
};
</script>
