import { render, staticRenderFns } from "./TemplateHighLevelSvg.vue?vue&type=template&id=21bb043c&scoped=true"
import script from "./TemplateHighLevelSvg.vue?vue&type=script&lang=js"
export * from "./TemplateHighLevelSvg.vue?vue&type=script&lang=js"
import style0 from "./TemplateHighLevelSvg.vue?vue&type=style&index=0&id=21bb043c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21bb043c",
  null
  
)

export default component.exports