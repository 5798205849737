/* eslint-disable class-methods-use-this */
import ServerSideDataGridGsClientBase from '@/components/ServerSideDataGridGsClientBase';

export default class GsLandOwners extends ServerSideDataGridGsClientBase {
  constructor(vue) {
    super();
    this.vue = vue;
  }

  async columnDefinitions() {
    return new Promise((resolve) => {
      resolve([
        {
          field: 'Fullname',
          headerName: this.vue.$t('WindServer.Sites.LandOwners.Columns.Fullname'),
          editable: false,
          filter: true,
          sortable: true,
          flex: 1,
        },
        {
          field: '',
          headerName: this.vue.$t('Delete'),
          width: 90,
          resizable: true,
          hide: !this.vue.$auth.roles.includes('Web.Site.Manage'),
          cellRenderer: 'DeleteButtonIdCellRenderer',
          cellRendererParams: {
            confirm: (event) => (this.vue.$t('WindServer.Sites.LandOwners.Columns.ConfirmFileDeletion', { Name: event.data.Fullname })),
            clicked: async (event) => {
              await this.vue.remove(event.data);
            },
          },
        },
      ]);
    });
  }
}
